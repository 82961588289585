import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { conciliacionForm } from '../helpers/forms';
import { startLoadCadenas, startLoadEmisores } from '../slices/catalogo/catalgoThunk';
import { deleteDiferencia, getConciliacionManualData, postConciliacionManualData } from '../slices/conciliacion/conciliacionThunk';
import moment from "moment";
import * as XLSX from "xlsx/xlsx.mjs";
import { useModal } from './useModal';
import { useNavigate, useSearchParams } from 'react-router-dom';

export const useConciliacionManualPage = () => {

  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  const dispatch = useDispatch();
  const [isOpenDetails, setIsOpenDetails] = useState(false)
  const [selectedDiference, setSelectedDiference] = useState(null)
  const [selectedOperation, setSelectedOperation] = useState(null)
  const [filtersLoaded, setFiltersLoaded] = useState(false)
  const [comment, setComment] = useState("")
  const [operacionesConciliadas, setOperacionesConciliadas] = useState([])
  const [form, setForm] = useState(conciliacionForm);
  const [isOpenDeleteDialog, setIsOpenDeleteDialog] = useState(false)
  const [isOpenConfirmDialog, setIsOpenConfirmDialog] = useState(false)
  const [selectedItem, setSelectedItem] = useState(0)
  const [motivo, setMotivo] = useState("")
  const [isFormExpanded, setIsFormExpanded] = useState(true)
  const [isDataLoaded, setIsDataLoaded] = useState(false)

  const [operacionesFiltered, setOperacionesFiltered] = useState([])
  const [diferenciasFiltered, setDiferenciasFiltered] = useState([])

  const { operaciones, diferencias } = useSelector((state) => state.conciliacion);
  const { emisores, cadenas } = useSelector((state) => state.catalogo);


  const { setOpenToast } = useModal();

  useEffect(() => {
    if (searchParams && searchParams.get('sArchivo')) {
      setIsFormExpanded(false)
      const sArchivo = searchParams.get('sArchivo')
      const innerForm = {
        ...conciliacionForm,
        sArchivo
      }
      setForm(innerForm)
      getConciliacionManual(innerForm)
    }
  }, [searchParams]);

  useEffect(() => {
    applyFilters()
  }, [operaciones, diferencias, form, operacionesConciliadas])



  const loadInInit = async () => {
    await getFilters()
  }

  const handleCloseDeleteDialog = () => {
    setIsOpenDeleteDialog(false)
  }

  const handleOpenDeleteDialog = (item) => {
    setMotivo("")
    setSelectedItem(item)
    setIsOpenDeleteDialog(true)
  }

  const handleCloseConfirmDialog = () => {
    setIsOpenConfirmDialog(false)
  }

  const handleFormExpanded = (flag) => {
    setIsFormExpanded(flag)
  }

  const handleOpenConfirmDialog = (item) => {
    setSelectedItem(item)
    setIsOpenConfirmDialog(true)
  }

  const deleteDiference = async () => {
    setIsOpenDeleteDialog(false)
    const resp = await dispatch(deleteDiferencia(selectedItem, motivo))
    setOpenToast(resp.nCodigo !== 0, resp.sMensaje)
    if (resp.nCodigo === 0) {
      setSelectedDiference(null)
      await getConciliacionManual()
    }
  }

  const getConciliacionManual = async (innerForm = null) => {
    await dispatch(getConciliacionManualData(innerForm ? innerForm : form)).then(resp => {
      if (!innerForm) {
        setSelectedDiference(null)
        setSelectedOperation(null)
        handleFormExpanded(false)
        navigate(`/conciliacion/manual?sArchivo=${form.sArchivo}`)
      }
      setIsDataLoaded(true)
    });
  }


  const getFilters = async () => {
    if (!filtersLoaded) {
      if (cadenas?.rows?.length === 0) await dispatch(startLoadCadenas());
      if (emisores?.rows?.length === 0) await dispatch(startLoadEmisores());
      setFiltersLoaded(true)
    }
  }

  const onChange = (e, name = null, value = null) => {
    const inputName = name !== null ? name : e.target.name;
    const inputValue = value !== null ? value : e.target.value;

    let valueForm = { ...form, [inputName]: inputValue };

    if (inputName === "nActor" && parseInt(inputValue) === 0) {
      valueForm = { ...valueForm, nIdCadena: 0, nIdEmisor: 0, nIdArchivo: 0, innerCadena: 0, innerEmisor: 0 };
    } else if (["nIdCadena", "nIdEmisor"].includes(inputName)) {
      valueForm = { ...valueForm, nIdArchivo: 0, innerCadena: 0, innerEmisor: 0 };
    }

    setSelectedDiference(null)
    setSelectedOperation(null)
    setForm(valueForm);
  };

  const applyFilters = () => {
    if (operaciones || diferencias) {
      const filteredDiferencias = diferencias.filter(diferencia => (
        !isInOperacionesConciliadas(diferencia)
      ))
      setDiferenciasFiltered(filteredDiferencias)

      const filteredOperaciones = operaciones.filter(operacion => (
        hasSelectedInnerActor(operacion) &&
        hasSelectedInnerDateInterval(operacion) &&
        isValidOperation(operacion) &&
        !isInOperacionesConciliadas(operacion)
      ))
      setOperacionesFiltered(selectedDiference?.nIdDiferencia ? filteredOperaciones : [])
    }
  }

  const isValidOperation = (diferencia) => {
    return (
      selectedDiference?.sReferencia === diferencia?.sReferencia
      && selectedDiference?.nMonto === diferencia?.nMonto
      && selectedDiference?.sAutorizacion === diferencia?.sAutorizacion
    )
  }

  const hasFormArchivo = (diferencia) => {
    return (form.nIdArchivo === 0 || (form.nIdArchivo === diferencia.nIdArchivo))
  }

  const hasFormActor = (diferencia) => {
    return (form.nActor === 0 ||
      (form.nActor === 1 && form.nIdEmisor === 0 && diferencia.nIdEmisor !== null) ||
      (form.nActor === 2 && form.nIdCadena === 0 && diferencia.nIdCadena !== null) ||
      (form.nActor === 1 && form.nIdEmisor === diferencia.nIdEmisor) ||
      (form.nActor === 2 && form.nIdCadena === diferencia.nIdCadena)
    )
  }

  const hasSelectedInnerActor = (operacion) => {
    return ((!form.innerCadena || (form.innerCadena === operacion.nIdCadena && operacion.nOperacionCadena)) && (!form.innerEmisor || (form.innerEmisor === operacion.nIdEmisor && operacion.nOperacionEmisor)))
  }

  const hasSelectedInnerDateInterval = (operacion) => {
    return ((!form.innerFecInicial || moment(operacion.dFecha).isSameOrAfter(moment(form.innerFecInicial))) && (!form.innerFecFinal || moment(operacion.dFecha).isSameOrBefore(moment(form.innerFecFinal))))
  }

  const isInOperacionesConciliadas = (row) => {
    return operacionesConciliadas.some(ops => ops.diferencia.nIdDiferencia == row?.nIdDiferencia || ops.operacion.nIdOperacion == row?.nIdOperacion)
  }

  const handleSelected = (type, row) => {
    if (type === "diferencia") {
      setForm({
        ...form,
        innerCadena: row.nIdCadena,
        innerEmisor: row.nIdEmisor,
        innerFecInicial: moment(row.dFecha).subtract(1, "month").format("YYYY-MM-DD"),
        innerFecFinal: moment(row.dFecha).add(1, "month").format("YYYY-MM-DD"),
      })
      setSelectedDiference(row)
      setSelectedOperation(null)
      setIsOpenDetails(false)
    }
    else {
      setSelectedOperation(row)
    }
  }

  const handleCancel = () => {
    setSelectedDiference(null)
    setSelectedOperation(null)
    setComment("")
    setOperacionesFiltered([])
  }

  const isValueDiferent = (key) => {
    return !((selectedDiference && !selectedOperation) || (selectedDiference && selectedOperation && selectedDiference[key] === selectedOperation[key]))
  }

  const isValueDiferentRow = (diferencia, operacion, key) => {
    return !((diferencia[key] === operacion[key]))
  }

  const formatPrice = (value) => {
    return (value ? value.toLocaleString("en-US", {
      style: "currency",
      currency: "USD",
    }) : "");
  }

  const addOperacionConciliada = () => {
    let innerOperacionesConciliadas = [...operacionesConciliadas]
    innerOperacionesConciliadas.push({
      id: operacionesConciliadas.length ? operacionesConciliadas[operacionesConciliadas.length - 1].id + 1 : 1,
      diferencia: selectedDiference,
      operacion: selectedOperation,
      comentario: comment
    })
    setOperacionesConciliadas(innerOperacionesConciliadas)
    setComment("")
    setSelectedDiference(null)
    setSelectedOperation(null)
    setForm({
      ...form,
      innerCadena: 0,
      innerEmisor: 0,
      innerFecInicial: null,
      innerFecFinal: null
    })
  }

  const deleteOperacionConciliada = (id) => {
    let innerOperacionesConciliadas = operacionesConciliadas.filter(operacion => operacion.id !== id)
    setOperacionesConciliadas(innerOperacionesConciliadas)
  }

  const saveOperacionesConciliadas = async () => {
    setIsOpenConfirmDialog(false)
    const innerData = []
    const valuesToCheck = ["dFecha", "sReferencia", "sAutorizacion", "sClaveProducto", "nMonto", "nComision"]
    operacionesConciliadas.map(row => {
      valuesToCheck.map(value => {
        const isDifferent = isValueDiferentRow(row.diferencia, row.operacion, value)
        if (isDifferent) innerData.push({
          nIdOperacion: row.operacion.nIdOperacion,
          nIdDiferencia: row.diferencia.nIdDiferencia,
          sCampo: value,
          sValorEsperado: row.operacion[value],
          sValorRecibido: row.diferencia[value],
          sComentario: row.comentario
        })
      })
    })
    const resp = await dispatch(postConciliacionManualData(innerData))

    setOpenToast(resp.nCodigo !== 0, resp.sMensaje)
    if (resp.nCodigo === 0) {
      await getConciliacionManual()
      setOperacionesConciliadas([])
    }

  }

  const exportDiferencias = () => {
    const header = [
      {
        name: "Actor",
        render: (row) => (row.nIdCadena ? "Comisionista" : "EFR")
      },
      {
        name: "Identificador",
        render: (row) => (row.nIdCadena ? row.nIdCadena : row.nIdArchivo)
      },
      {
        name: "Nombre comercial",
        key: "sNombreComercial"
      },
      {
        name: "Nombre del archivo",
        key: "sNombre"
      },
      {
        name: "Clave de producto",
        key: "sClaveProducto"
      },
      {
        name: "Nombre de producto",
        key: "sNombreProducto"
      },
      {
        name: "Referencia",
        key: "sReferencia"
      },
      {
        name: "Autorización",
        key: "sAutorizacion"
      },
      {
        name: "Fecha",
        key: "dFecha"
      },
      {
        name: "Hora",
        key: "dHora"
      },
      {
        name: "Fecha conciliación",
        key: "dFechaConciliacion"
      },
      {
        name: "Monto",
        key: "nMonto"
      },
      {
        name: "Comisión",
        key: "nComision"
      },
    ]
    downloadExcel(header, diferenciasFiltered)
  }

  const downloadExcel = (headers, data) => {
    const innerData = []
    const innerHeaders = []

    headers.map(header => innerHeaders.push(header.name))

    innerData.push(innerHeaders)

    data.map(row => {
      let innerRow = []
      headers.map(header => innerRow.push(header.render ? header.render(row) : row[header.key]))
      innerData.push(innerRow)
    })

    const workbook = XLSX.utils.book_new();
    var worksheet1 = XLSX.utils.aoa_to_sheet(innerData);
    XLSX.utils.book_append_sheet(workbook, worksheet1, "Diferencias");
    XLSX.writeFile(workbook, `diferencias.xlsx`);

  }

  const onChangeInput = async (value) => {
    setForm({ ...form, ...value });
  };

  return {
    isOpenDetails,
    selectedDiference,
    selectedOperation,

    setIsOpenDetails,

    loadInInit,
    getFilters,
    getConciliacionManual,
    handleSelected,
    handleCancel,
    formatPrice,

    emisores,
    cadenas,
    form,
    onChange,
    operacionesFiltered,
    diferenciasFiltered,
    isValueDiferent,
    isValueDiferentRow,
    comment,
    setComment,
    operacionesConciliadas,
    addOperacionConciliada,
    deleteOperacionConciliada,
    saveOperacionesConciliadas,
    exportDiferencias,

    handleOpenDeleteDialog,
    handleCloseDeleteDialog,
    isOpenDeleteDialog,
    deleteDiference,
    handleCloseConfirmDialog,
    handleOpenConfirmDialog,
    isOpenConfirmDialog,
    motivo,
    setMotivo,
    onChangeInput,
    isFormExpanded,
    handleFormExpanded,
    isDataLoaded
  };
};
