import { createSlice } from '@reduxjs/toolkit';
import {
  escalonadoForm,
  emailsFactura,
  cuentaContable,
  facturacionForm,
  emailFacutra,
  escCantidadForm,
  contablesCash,
} from '../../helpers/forms';
import { v4 as uuidv4 } from 'uuid';


const initialState = {
  contactos: [],
  cadenaId: null,
  escalonadoForms: [escalonadoForm],
  escCantidadForms: [escCantidadForm],
  direccionSearched: { colonia: '', estado: '', ciudad: '' },
  cuentasContable: [],
  cuentaContableForm: { ...cuentaContable },
  activeCleanLiquida: false,
  bancoSearched: '',
  facturacionForm,
  emailsFactura,
  contablesCash,
  permissions: {
    columns: [],
    rows: []
  },
  ivaOperativoSelect: 0.16
}

export const cadenaSlice = createSlice({
  name: 'cadena',
  initialState,
  reducers: {
    setContactoCadena: (state, action) => {
      state.contactos = [...state.contactos, action.payload]

      if (action?.payload?.proceso?.map(item => item.nIdProceso).includes(4)) {
        const emailsRefs = Object.keys(state.emailsFactura)
        let emailsFactura = { ...state.emailsFactura }
        emailsRefs.map(ref => {
          emailsFactura[ref] = [{
            id: uuidv4(),
            email: action.payload.email,
            isActiveDelete: true,
            isActiveDisable: true,
            isFromContactos: true,
          }, ...emailsFactura[ref]]
        })
        state.emailsFactura = emailsFactura
      }
    },
    deleteContactoCadena: (state, action) => {
      const contacto = state.contactos.find(
        (contacto) => contacto.email === action.payload
      )
      state.contactos = state.contactos.filter(
        (contacto) => contacto.email !== action.payload
      )
      if (contacto?.proceso?.map(item => item.nIdProceso).includes(4)) {
        const emailsRefs = Object.keys(state.emailsFactura)
        let emailsFactura = { ...state.emailsFactura }
        emailsRefs.map(ref => {
          emailsFactura[ref] = [...emailsFactura[ref].filter(mailFactura => mailFactura.email !== contacto.email)]
        })
        state.emailsFactura = emailsFactura
      }
    },
    updateConctactoCadena: (state, { payload }) => {
      const contacto = state.contactos.find(
        (contact) => contact.id?.toString() === payload.id?.toString()
      )
      if (contacto && (contacto.proceso.map(con => parseInt(con.nIdProceso)).includes(4) || payload.proceso.map(con => parseInt(con.nIdProceso)).includes(4))) {
        if (contacto.proceso.map(con => parseInt(con.nIdProceso)).includes(4) && !payload.proceso.map(con => parseInt(con.nIdProceso)).includes(4)) {
          const emailsRefs = Object.keys(state.emailsFactura)
          let emailsFactura = { ...state.emailsFactura }
          emailsRefs.map(ref => {
            emailsFactura[ref] = [...emailsFactura[ref].filter(mailFactura => mailFactura.email !== contacto.email)]
          })
          state.emailsFactura = emailsFactura
        }
        if (!contacto.proceso.map(con => parseInt(con.nIdProceso)).includes(4) && payload.proceso.map(con => parseInt(con.nIdProceso)).includes(4)) {
          const emailsRefs = Object.keys(state.emailsFactura)
          let emailsFactura = { ...state.emailsFactura }
          emailsRefs.map(ref => {
            emailsFactura[ref] = [{
              id: uuidv4(),
              email: payload.email,
              isActiveDelete: true,
              isActiveDisable: true,
              isFromContactos: true,
            }, ...emailsFactura[ref]]
          })
          state.emailsFactura = emailsFactura
        }
      }
      state.contactos = state.contactos.map((contact) =>
        contact.id?.toString() === payload.id?.toString() ? payload : contact
      )
    },
    cleanContactosCadena: (state) => {
      state.contactos = []
    },
    setContactosList: (state, { payload }) => {
      state.contactos = [...payload]
    },
    setCadenaId: (state, action) => {
      state.cadenaId = action.payload
    },
    cleanCadenaId: (state) => {
      state.cadenaId = null
    },
    // *****
    setEscalonadoForm: (state, action) => {
      state.escalonadoForms = [...state.escalonadoForms, action.payload]
    },
    cleanEscalonado: (state) => {
      state.escalonadoForms = [{
        ...escalonadoForm,
        id: uuidv4()
      }];
    },
    updateEscaladonoForm: (state, action) => {
      state.escalonadoForms = state.escalonadoForms.map((escalonado) =>
        escalonado.id.toString() === action.payload.id.toString()
          ? action.payload
          : escalonado
      )
    },
    deleteEsclonadoForm: (state, action) => {
      state.escalonadoForms = state.escalonadoForms.filter(
        (esca) => esca.id.toString() !== action.payload.toString()
      );
    },
    setListEscalonadoForm: (state, { payload }) => {
      state.escalonadoForms = [...payload]
    },
    // ******
    setEscCantidadForm: (state, action) => {
      state.escCantidadForms = [...state.escCantidadForms, action.payload]
    },
    cleanEscCantidadForm: (state) => {
      state.escCantidadForms = [{
        ...escCantidadForm,
        id: uuidv4()
      }];
    },
    updateEscCantidadForm: (state, action) => {
      state.escCantidadForms = state.escCantidadForms.map((escalonado) =>
        escalonado.id.toString() === action.payload.id.toString()
          ? action.payload
          : escalonado
      )
    },
    deleteEscCantidadForm: (state, action) => {
      state.escCantidadForms = state.escCantidadForms.filter(
        (esca) => esca.id.toString() !== action.payload.toString()
      );
    },
    setListEscCantidadForm: (state, { payload }) => {
      state.escCantidadForms = [...payload]
    },
    // ****
    setDireccionSearched: (state, action) => {
      state.direccionSearched = { ...state.direccionSearched, ...action.payload }
    },
    setBancoSearched: (state, action) => {
      state.bancoSearched = action.payload;
    },

    setEmail: (state, { payload }) => {
      state.emailsFactura = {
        ...state.emailsFactura,
        [payload.ref]: [...state.emailsFactura[payload.ref], payload.email]
      }
    },
    updateEmail: (state, { payload }) => {
      state.emailsFactura = {
        ...state.emailsFactura,
        [payload.ref]: state.emailsFactura[payload.ref].map((email) =>
          email.id === payload.email.id ? payload.email : email
        )
      };
    },
    deleteEmail: (state, { payload }) => {
      state.emailsFactura = {
        ...state.emailsFactura,
        [payload.ref]: [...state.emailsFactura[payload.ref].filter(
          (email) => email.id.toString() !== payload.id.toString() || email.email === ""
        )]
      };
    },
    setEmails: (state, { payload }) => {
      state.emailsFactura = { ...state.emailsFactura, ...payload }
    },
    setPermissions: (state, action) => {
      state.permissions = { ...action.payload }
    },
    cleanEmailsByRef: (state, { payload }) => {
      const emailsFacturaContactos = state.contactos.map(contacto => {
        return ({
          id: uuidv4(),
          email: contacto.email,
          isActiveDelete: true,
          isActiveDisable: true
        })
      })
      state.emailsFactura = {
        ...state.emailsFactura,
        [payload.ref]: [...emailsFacturaContactos, { ...emailFacutra, id: uuidv4() }]
      };
    },
    cleanCadena: (state) => {
      state.contactos = [];
      state.cadenaId = null;
      state.escalonadoForms = [{ ...escalonadoForm, id: uuidv4() }];
      state.escCantidadForms = [{ ...escCantidadForm, id: uuidv4() }]
      state.direccionSearched = { colonia: '', estado: '', ciudad: '' };
      state.bancoSearched = '';
      state.cuentaContableForm = { ...cuentaContable };
      state.activeCleanLiquida = true;
      state.facturacionForm = { ...facturacionForm }
      state.contablesCash = { ...contablesCash }
      state.ivaOperativoSelect = 0.16

      const emailsRefs = Object.keys(state.emailsFactura)
      let emailsFactura = { ...state.emailsFactura }
      emailsRefs.map(ref => {
        emailsFactura[ref] = [{ ...emailFacutra, id: uuidv4() }]
      })
      state.emailsFactura = emailsFactura;
    },
    setCuentaContableForm: (state, action) => {
      state.cuentaContableForm = { ...state.cuentaContableForm, ...action.payload }
    },
    setContablesCash: (state, { payload }) => {
      state.contablesCash = {
        ...state.contablesCash,
        [payload.ref]: { ...state.contablesCash[payload.ref], ...payload.obj }
      }
    },
    setCuentaContable: (state, action) => {
      state.cuentasContable = [...action.payload]
    },
    disableCleanLiquidacion: (state) => {
      state.activeCleanLiquida = false;
    },
    setFacturacionForm: (state, { payload }) => {
      state.facturacionForm = { ...state.facturacionForm, ...payload }
    },
    setIvaOperativoSelected: (state, { payload }) => {
      state.ivaOperativoSelect = payload
    },
  },
})

export const {
  setContactoCadena,
  deleteContactoCadena,
  updateConctactoCadena,
  cleanContactosCadena,
  setCadenaId,
  setEscalonadoForm,
  cleanEscalonado,
  updateEscaladonoForm,
  setDireccionSearched,
  setBancoSearched,
  deleteEsclonadoForm,
  setEmail,
  updateEmail,
  deleteEmail,
  setPermissions,
  cleanCadena,
  setCuentaContableForm,
  setCuentaContable,
  disableCleanLiquidacion,
  setFacturacionForm,
  setContactosList,
  cleanEmailsByRef,
  setEscCantidadForm,
  cleanEscCantidadForm,
  updateEscCantidadForm,
  deleteEscCantidadForm,
  setContablesCash,
  setIvaOperativoSelected,
  setListEscalonadoForm,
  setListEscCantidadForm,
  setEmails
} = cadenaSlice.actions;