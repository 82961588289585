import { Auth } from 'aws-amplify';
import { API } from 'aws-amplify';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useModal } from "./useModal";
import { loading } from '../slices/ui/uiSlice';

export const useSetNewPassword = () => {
  const navigate = useNavigate();
  const { password } = useSelector((state) => state.user);
  const tempPassword = password;
  const { 
    setOpenToast
  } = useModal();
  const dispatch = useDispatch();
  
  const newPassword = (newPassword, username) => {
    dispatch(loading(true));
    Auth.signIn(username, tempPassword)
      .then((user) => {
        if (user.challengeName === 'NEW_PASSWORD_REQUIRED') {
          // const { requiredAttributes } = user.challengeParam; // the array of required attributes, e.g ['email', 'phone_number']
          Auth.completeNewPassword(
            user, // the Cognito User Object
            newPassword // the new password
            // OPTIONAL, the required attributes
            // {
            //   email: 'xxxx@example.com',
            //   phone_number: '1234567890'
            // }
          )
            .then(async(user) => {
              // at this time the user is logged in if no MFA required
              const resp = await activateUser(username)

              if(! resp.ok) await activateUser(username)
              navigate('/login');
              setOpenToast(false, 'Cambio de contraseña exitoso');
              console.log(user);
              return user
            })
            .catch((e) => {
              console.log(e);
            });
        } else {
          // other situations
        }
      })
      .catch((e) => {
        console.log(e);
      })
      .finally(() => {
        dispatch(loading(false));
      });
  };

  const activateUser = async (sUsuario) => {
    let res = {
      ok: false,
      msg: "Error inesperado"
    }
    try {
      const user = await Auth.currentAuthenticatedUser();
      console.log(user.signInUserSession.idToken.jwtToken);
      const init = {
        headers: {
          Authorization: user.signInUserSession.idToken.jwtToken
        },
        body: { sUsuario }
      };
      const resp = await API.put('usuarios', '/usuario/activacion', init);
      res = {
        ok: resp?.nCodigo === 0,
        msg: resp?.sMensaje
      }
    } catch (e) {
      console.log('ocurrio un error' + e);
    } finally {
      return res
    }
  };

  return {
    newPassword
  };
};
