import { API, Storage } from "aws-amplify";
import { loading, tableLoading, tableModalLoading } from "../ui/uiSlice";
import { setArchivos, setConexiones, setConexionesSofiPay } from "./conexionesSFTPSlice";



export const getArchivos = ({nIdCadena = 0, nIdEmisor = 0, nIdArchivoSFTP = 0, nIdEstatus = 1}, isEFR) => {
  return async (dispatch) => {
    const params = {
      headers: {},
      response: false,
      queryStringParameters: {nIdCadena: nIdCadena ?? 0, nIdEmisor: nIdEmisor ?? 0, nIdArchivoSFTP, nIdEstatus},
    };
    let data = []
    try {
      dispatch(loading(true));
      const resp = await API.get("conexiones", `/${isEFR ? 'emisor' : 'cadena'}/archivos-conexiones`, params);
      data = [...resp]
      dispatch(
        setArchivos(data)
      );
    } catch (error) {
      console.log("ocurrio un error " + error);
    } finally {
      dispatch(loading(false));
    }
    return data
  };
};

export const getConexiones = ({nIdCadena = 0, nIdEmisor = 0, nIdTipoActor = 0, nIdEstatus = 1}, isEFR) => {
  return async (dispatch) => {
    const params = {
      headers: {},
      response: false,
      queryStringParameters: {nIdCadena: nIdCadena ?? 0, nIdEmisor: nIdEmisor ?? 0, nIdTipoActor, nIdEstatus},
    };
    let data = []
    try {
      dispatch(loading(true));
      const resp = await API.get("conexiones", `/${isEFR ? 'emisor' : 'cadena'}/conexiones`, params);
      data = [...resp]
      dispatch(
        setConexiones(data)
      );
    } catch (error) {
      console.log("ocurrio un error " + error);
    } finally {
      dispatch(loading(false));
    }
    return data
  };
};

export const getConexionesSofiPay = (nIdCfgConexion = 0, nIdEstatus = 1) => {
  return async (dispatch) => {
    const params = {
      headers: {},
      response: false,
      queryStringParameters: {nIdCfgConexion, nIdEstatus},
    };
    let data = []
    try {
      dispatch(loading(true));
      const resp = await API.get("conexiones", "/conexiones/conexiones-sofipay", params);
      data = [...resp]
      dispatch(
        setConexionesSofiPay(data)
      );
    } catch (error) {
      console.log("ocurrio un error " + error);
    } finally {
      dispatch(loading(false));
    }
    return data
  };
};

export const getArchivosBitacora = ({nIdCadena = 0, nIdEmisor = 0, nIdArchivoSFTP = 0, nIdEstatus = -1}, isEFR) => {
  return async (dispatch) => {
    const params = {
      headers: {},
      response: false,
      queryStringParameters: {nIdCadena: nIdCadena ?? 0, nIdEmisor: nIdEmisor ?? 0, nIdArchivoSFTP, nIdEstatus},
    };
    let data = []
    try {
      dispatch(loading(true));
      const resp = await API.get("conexiones", `/${isEFR ? 'emisor' : 'cadena'}/archivos-conexiones-bitacora`, params);
      data = [...resp]
    } catch (error) {
      console.log("ocurrio un error " + error);
    } finally {
      dispatch(loading(false));
    }
    return data
  };
};


export const getConexionesBitacora = ({nIdCfgConexion = 0, nIdCadena = 0, nIdEmisor = 0, nIdEstatus = -1}, isEFR) => {
  return async (dispatch) => {
    const params = {
      headers: {},
      response: false,
      queryStringParameters: {nIdCfgConexion, nIdCadena: nIdCadena ?? 0, nIdEmisor: nIdEmisor ?? 0, nIdEstatus},
    };
    let data = []
    try {
      dispatch(loading(true));
      const resp = await API.get("conexiones", `/${isEFR ? 'emisor' : 'cadena'}/conexiones-bitacora`, params);
      data = [...resp]
    } catch (error) {
      console.log("ocurrio un error " + error);
    } finally {
      dispatch(loading(false));
    }
    return data
  };
};


export const updateArchivo = ({ nIdArchivoSFTP, nIdConexion, nIdCfgConexion, nIdCadena, nIdEmisor, sCarpeta, nIdTipoActor}, isEFR) => {
  return async (dispatch) => {
    const params = {
      headers: {},
      response: false,
      body: {
        nIdArchivoSFTP,
        nIdConexion: nIdConexion ?? 0,
        nIdCfgConexion: nIdCfgConexion ?? 0,
        nIdCadena: nIdCadena ?? 0,
        nIdEmisor: nIdEmisor ?? 0,
        sCarpeta: parseInt(nIdTipoActor) === 1 ? sCarpeta : ""
      },
    };
    let data = {ok: false, msg: "Ocurrió un error inesperado, intente más tarde."}
    try {
      dispatch(loading(true));
      const resp = await API.post("conexiones", `/${isEFR ? 'emisor' : 'cadena'}/archivo-conexion`, params);
      console.log(resp)
      const {
        nCodigo,
        sMensaje
      } = resp[0]
      data = {
        ok: nCodigo === 0,
        msg: sMensaje
      }
    } catch (error) {
      console.log("ocurrio un error " + error);
    } finally {
      dispatch(loading(false));
    }
    return data
  };
};

export const insertConexion= ({ nIdCadena, nIdEmisor, sNombreConexion, sHost, nPort, sUser, sPassword, sComentario}, isEFR) => {
  return async (dispatch) => {
    const params = {
      headers: {},
      response: false,
      body: {
        nIdCadena: nIdCadena ?? 0,
        nIdEmisor: nIdEmisor ?? 0,
        sNombreConexion,
        sHost,
        nPort,
        sUser,
        sPassword,
        sComentario: sComentario ?? ""
      },
    };
    let data = {ok: false, msg: "Ocurrió un error inesperado, intente más tarde."}
    try {
      dispatch(loading(true));
      const resp = await API.post("conexiones", `/${isEFR ? 'emisor' : 'cadena'}/conexion`, params);
      console.log(resp)
      const {
        nCodigo,
        sMensaje,
        nIdCfgConexion
      } = resp[0]
      data = {
        ok: nCodigo === 0,
        msg: sMensaje,
        nIdCfgConexion: nIdCfgConexion
      }
    } catch (error) {
      console.log("ocurrio un error " + error);
    } finally {
      dispatch(loading(false));
    }
    return data
  };
};

export const updateConexion= ({ nIdCfgConexion, sNombreConexion, sHost, nPort, sUser, sPassword, sComentario, isPasswordChanged, hasDecrypted}, isEFR) => {
  return async (dispatch) => {
    const params = {
      headers: {},
      response: false,
      body: {
        nIdCfgConexion,
        sNombreConexion,
        sHost,
        nPort,
        sUser,
        sPassword,
        sComentario: sComentario ?? "",
        isPasswordChanged: hasDecrypted || isPasswordChanged
      },
    };
    let data = {ok: false, msg: "Ocurrió un error inesperado, intente más tarde."}
    try {
      dispatch(loading(true));
      const resp = await API.put("conexiones", `/${isEFR ? 'emisor' : 'cadena'}/conexion`, params);
      console.log(resp)
      const {
        nCodigo,
        sMensaje,
        nIdCfgConexion
      } = resp[0]
      data = {
        ok: nCodigo === 0,
        msg: sMensaje,
        nIdCfgConexion: nIdCfgConexion
      }
    } catch (error) {
      console.log("ocurrio un error " + error);
    } finally {
      dispatch(loading(false));
    }
    return data
  };
};

export const deleteConexion= ({ nIdCfgConexion, sNombreConexion, sHost, nPort, sUser, sPassword, sComentario}, isEFR) => {
  return async (dispatch) => {
    const params = {
      headers: {},
      response: false,
      body: {
        nIdCfgConexion,
        sNombreConexion,
        sHost,
        nPort,
        sUser,
        sPassword,
        sComentario: sComentario ?? ""
      },
    };
    let data = {ok: false, msg: "Ocurrió un error inesperado, intente más tarde."}
    try {
      dispatch(loading(true));
      const resp = await API.del("conexiones", `/${isEFR ? 'emisor' : 'cadena'}/conexion`, params);
      console.log(resp)
      const {
        nCodigo,
        sMensaje,
        nIdCfgConexion
      } = resp[0]
      data = {
        ok: nCodigo === 0,
        msg: sMensaje,
        nIdCfgConexion: nIdCfgConexion
      }
    } catch (error) {
      console.log("ocurrio un error " + error);
    } finally {
      dispatch(loading(false));
    }
    return data
  };
};


export const verPassword = (sPassword, isEFR) => {
  return async (dispatch) => {
    const params = {
      headers: {},
      response: false,
      body: {
        sPassword
      },
    };
    let data = {ok: false, msg: "Ocurrió un error inesperado, intente más tarde.", sPassword: ""}
    try {
      dispatch(loading(true));
      const resp = await API.post("conexiones", `/${isEFR ? 'emisor' : 'cadena'}/ver-password`, params);
      console.log(resp)
      const {
        nCodigo,
        sMensaje,
        sPassword
      } = resp
      data = {
        ok: nCodigo === 0,
        msg: sMensaje,
        sPassword: sPassword
      }
    } catch (error) {
      console.log("ocurrio un error " + error);
    } finally {
      dispatch(loading(false));
    }
    return data
  };
};


export const insertConexionSP = ({ sNombreConexion, sHost, nPort, sUser, sInnerPassword, sComentario }) => {
  return async (dispatch) => {
    const params = {
      headers: {},
      response: false,
      body: {
        sNombreConexion,
        sHost,
        nPort,
        sUser,
        sPassword: sInnerPassword,
        sComentario: sComentario ?? ""
      },
    };
    let data = {ok: false, msg: "Ocurrió un error inesperado, intente más tarde."}
    try {
      dispatch(loading(true));
      const resp = await API.post("conexiones", `/conexiones/conexiones-sofipay`, params);
      console.log(resp)
      const {
        nCodigo,
        sMensaje,
        nIdCfgConexion
      } = resp[0]
      data = {
        ok: nCodigo === 0,
        msg: sMensaje,
        nIdCfgConexion: nIdCfgConexion
      }
    } catch (error) {
      console.log("ocurrio un error " + error);
    } finally {
      dispatch(loading(false));
    }
    return data
  };
};


export const verPasswordSP = (sPassword) => {
  return async (dispatch) => {
    const params = {
      headers: {},
      response: false,
      body: {
        sPassword
      },
    };
    let data = {ok: false, msg: "Ocurrió un error inesperado, intente más tarde.", sPassword: ""}
    try {
      dispatch(loading(true));
      const resp = await API.post("conexiones", `/conexiones/ver-password`, params);
      console.log(resp)
      const {
        nCodigo,
        sMensaje,
        sPassword
      } = resp
      data = {
        ok: nCodigo === 0,
        msg: sMensaje,
        sPassword: sPassword
      }
    } catch (error) {
      console.log("ocurrio un error " + error);
    } finally {
      dispatch(loading(false));
    }
    return data
  };
};

export const updateConexionSP = ({ nIdCfgConexion, sNombreConexion, sHost, nPort, sUser, sInnerPassword, sPassword, sComentario, isPasswordChanged, hasDecrypted}) => {
  return async (dispatch) => {
    const params = {
      headers: {},
      response: false,
      body: {
        nIdCfgConexion,
        sNombreConexion,
        sHost,
        nPort,
        sUser,
        sPassword: sInnerPassword ? sInnerPassword : sPassword,
        sComentario: sComentario ?? "",
        isPasswordChanged: hasDecrypted || isPasswordChanged
      },
    };
    let data = {ok: false, msg: "Ocurrió un error inesperado, intente más tarde."}
    try {
      dispatch(loading(true));
      const resp = await API.put("conexiones", `/conexiones/conexiones-sofipay`, params);
      console.log(resp)
      const {
        nCodigo,
        sMensaje,
        nIdCfgConexion
      } = resp[0]
      data = {
        ok: nCodigo === 0,
        msg: sMensaje,
        nIdCfgConexion: nIdCfgConexion
      }
    } catch (error) {
      console.log("ocurrio un error " + error);
    } finally {
      dispatch(loading(false));
    }
    return data
  };
};

export const deleteConexionSP = ({ nIdCfgConexion, sNombreConexion, sHost, nPort, sUser, sPassword, sComentario}) => {
  return async (dispatch) => {
    const params = {
      headers: {},
      response: false,
      body: {
        nIdCfgConexion,
        sNombreConexion,
        sHost,
        nPort,
        sUser,
        sPassword,
        sComentario: sComentario ?? ""
      },
    };
    let data = {ok: false, msg: "Ocurrió un error inesperado, intente más tarde."}
    try {
      dispatch(loading(true));
      const resp = await API.del("conexiones", `/conexiones/conexiones-sofipay`, params);
      console.log(resp)
      const {
        nCodigo,
        sMensaje,
        nIdCfgConexion
      } = resp[0]
      data = {
        ok: nCodigo === 0,
        msg: sMensaje,
        nIdCfgConexion: nIdCfgConexion
      }
    } catch (error) {
      console.log("ocurrio un error " + error);
    } finally {
      dispatch(loading(false));
    }
    return data
  };
};