import { API } from "aws-amplify";
import moment from "moment";
import { setDataTable } from "../reports/reportsSlice";
import { loading } from "../ui/uiSlice";
import { setCortesCadenas, setCortesEmisores, setCortesEscalonadosEmisores, setOrdenesPagoCadenas, setOrdenesPagoEmisores, setPolizasTable, setRegistrosBancarios, setRegistrosBancariosCadena, setRegistrosBancariosCargosCadena, setRegistrosBancariosCargosEmisor, setRegistrosBancariosEmisor } from "./contabilidadSlice";

export const getPolizasData = (form) => {
  return async (dispatch) => {
    const params = {
      headers: {},
      response: false,
      queryStringParameters: form,
    };

    try {
      dispatch(loading(true));
      const resp = await API.get(
        "contabilidad",
        "/contabilidad/polizas",
        params
      );
      console.log({
        ...resp?.data,
        details: form,
      });
      dispatch(
        setPolizasTable({
          ...resp?.data,
          details: form,
        })
      );
    } catch (error) {
      console.log("ocurrio un error " + error);
    } finally {
      dispatch(loading(false));
    }
  };
};

export const getPolizaData = (nIdPoliza) => {
  return async (dispatch) => {
    const params = {
      headers: {},
      response: false,
      body: {
        nIdPoliza,
      },
    };
    let data = {};
    try {
      dispatch(loading(true));
      const resp = await API.post(
        "contabilidad",
        "/contabilidad/polizas",
        params
      );
      data = resp;
    } catch (error) {
      console.log("ocurrio un error " + error);
    } finally {
      dispatch(loading(false));
      return data;
    }
  };
};

export const getRegistrosBancariosData = (form) => {
  return async (dispatch) => {
    const params = {
      headers: {},
      response: false,
      queryStringParameters: form,
    };

    try {
      dispatch(loading(true));
      const resp = await API.get(
        "contabilidad",
        "/contabilidad/registrosbancarios",
        params
      );
      console.log({
        ...resp.data,
        details: form,
      });
      dispatch(
        setDataTable({
          ...resp.data,
          details: form,
        })
      );
    } catch (error) {
      console.log("ocurrio un error " + error);
    } finally {
      dispatch(loading(false));
    }
  };
};

export const getRegistrosCortesData = (nEstatusRelacion = 0, dFecha = null, nIdEstatus = 1) => {
  return async (dispatch) => {
    const params = {
      headers: {},
      response: false,
      queryStringParameters: {
        nEstatusRelacion,
        dFecha: dFecha ? dFecha : moment().format("YYYY-MM-DD"),
        nIdEstatus
      },
    };
    let data = []
    try {
      dispatch(loading(true));
      const resp = await API.get(
        "contabilidad",
        "/contabilidad/registrosCortes",
        params
      );

      data = { ...resp.data }
      dispatch(setCortesEmisores(JSON.parse(data.cortesEmisor)));
      dispatch(setCortesEscalonadosEmisores(JSON.parse(data.cortesEscalonadoEmisor)));
      dispatch(setCortesCadenas(JSON.parse(data.cortesCadena)));
      dispatch(setOrdenesPagoEmisores(JSON.parse(data.ordenesPagoEmisor)));
      dispatch(setOrdenesPagoCadenas(JSON.parse(data.ordenesPagoCadena)));
      dispatch(setRegistrosBancariosCadena(JSON.parse(data.registrosBancariosCadena)));
      dispatch(setRegistrosBancariosEmisor(JSON.parse(data.registrosBancariosEmisor)));
      dispatch(setRegistrosBancariosCargosCadena(JSON.parse(data.registrosBancariosCargosCadena)));
      dispatch(setRegistrosBancariosCargosEmisor(JSON.parse(data.registrosBancariosCargosEmisor)));
    } catch (error) {
      console.log("ocurrio un error " + error);
    } finally {
      dispatch(loading(false));
      return data
    }
  };
};

export const postRegistrosCortesData = (sRegistros, isRegistrosOrdenes = false) => {
  return async (dispatch) => {
    const params = {
      headers: {},
      response: false,
      body: {
        sRegistros,
        isRegistrosOrdenes
      },
    };
    let data = []
    try {
      dispatch(loading(true));
      const resp = await API.post(
        "contabilidad",
        "/contabilidad/registrosCortes",
        params
      );
      console.log(resp)
      data = { ...resp }
    } catch (error) {
      console.log("ocurrio un error " + error);
    } finally {
      dispatch(loading(false));
      return data
    }
  };
};

export const insertRegistrosBancarios = (nInicio, sRegistros, isAbono = true) => {
  return async (dispatch) => {
    const params = {
      headers: {},
      response: false,
      body: {
        nInicio,
        isAbono,
        sRegistros: sRegistros.map((registro) => {
          let reg = registro.data;
          return {
            dFechaHora: reg.dFechaHora,
            sConcepto: reg.sConcepto,
            sReferencia: reg.sReferencia,
            sDescripcion: reg.sDescripcion,
            sCuenta: reg.sCuenta,
            nImporte: reg.nImporte,
            dFecha: reg.dFecha,
            dHora: reg.dHora,
          };
        }),
        //sRegistros
      },
    };
    let data = [];
    try {
      await API.post(
        "contabilidad",
        "/contabilidad/registrosbancarios",
        params
      ).then((resp) => {
        data = [...JSON.parse(resp.sDetalleRespuesta)];
        console.log(data);
      });
    } catch (error) {
      console.log("ocurrio un error " + error);
    } finally {
      return data;
    }
  };
};
