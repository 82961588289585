import { Box, Button, Grid, Typography } from "@mui/material";
import { useModal } from "../../hooks/useModal";
import { ModalBase } from "./ModalBase";
import { useEffect, useState } from "react";
import { useAuthPage } from "../../hooks/useAuthPage";
import { useDispatch, useSelector } from "react-redux";
import {
  startLoadEquivalencias,
  startLoadEquivalenciasBitacora,
  putEquivalenciaProductos,
} from "../../slices/catalogo/catalgoThunk";
import WorkspacesTwoToneIcon from "@mui/icons-material/WorkspacesTwoTone";
import Edit from "@mui/icons-material/Edit";
import { TextInput } from "../form/TextInput";
import { useCadenasTable } from "../../hooks/useCadenasTable";
import { ConfirmDialog } from "./ConfirmDialog";
import { History } from "@mui/icons-material";
import { ModalBitacora } from "./ModalBitacora";

export const ModalEquivalencias = (props) => {
  const { entity } = props;

  const [isAllowdEdition, setIsAllowdEdition] = useState(false);
  const [form, setForm] = useState([]);
  const [isEditing, setIsEditing] = useState(false);
  const [showConfirmDialog, setShowConfirmDialog] = useState(false);
  const [localEntity, setLocalEntity] = useState(entity);

  const {
    isOpenEquivalences,
    closeModalEquivalences,
    setOpenToast,
    openModalBitacora,
    isOpenBitacora,
  } = useModal();
  const { setAllowComponent } = useAuthPage();
  const dispatch = useDispatch();
  const { equivalencias } = useSelector((state) => state.catalogo);
  const { openProductsModal } = useCadenasTable();

  useEffect(() => {
    if (isOpenEquivalences) {
      loadInInit();
      setIsAllowdEdition(setAllowComponent("editarProductosEquivalencia"));
    } else {
      setForm([]);
    }
  }, [isOpenEquivalences]);

  useEffect(() => {
    setForm(equivalencias);
  }, [equivalencias]);

  useEffect(() => {
    if (entity && Object.keys(entity).length > 0) {
      setLocalEntity(entity);
    }
  }, [entity]);

  const loadInInit = async () => {
    dispatch(startLoadEquivalencias(localEntity.nIdCadena));
  };

  const onChangeInput = (value, sClaveProducto) => {
    setForm((prevForm) =>
      prevForm.map((item) =>
        item.sClaveProducto === sClaveProducto ? { ...item, ...value } : item
      )
    );
  };

  const isValidForm = () =>
    form.every(({ sClaveProductoCadena }) => sClaveProductoCadena);

  const onSubmit = async () => {
    setShowConfirmDialog(false);
    if (isValidForm()) {
      const response = await dispatch(
        putEquivalenciaProductos({ sProductos: form })
      );
      if (response.ok) {
        setOpenToast(false, "Información guardada correctamente");
        setForm([]);
        onCloseModalEquivalences();
      }
    } else {
      setOpenToast(true, "Favor de llenar todos los campos");
    }
  };

  const onCloseModalEquivalences = () => {
    openProductsModal();
    closeModalEquivalences();
  };

  const onClickOpenModalBitacora = () => {
    openModalBitacora();
  };

  return (
    <>
      <ConfirmDialog
        isOpenDialog={showConfirmDialog}
        onCloseDialog={() => setShowConfirmDialog(false)}
        onConfimDialog={onSubmit}
        message={"¿Estas seguro/a de guardar las equivalencias?"}
      />
      {isOpenBitacora && (
        <ModalBitacora
          title="Bitácora Equivalencias"
          subtitle={localEntity.sNombreComercial}
          download
          rowId={"nIdProductoCadenaBitacora"}
          getDataFn={startLoadEquivalenciasBitacora}
          getDataParams={{ nIdCadena: localEntity.nIdCadena }}
        />
      )}
      <ModalBase open={isOpenEquivalences} style={{ width: "700px" }}>
        <Box
          sx={{
            width: "100%",
            height: "100%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
          }}
        >
          <Grid
            container
            style={{
              alignItems: "left",
              justifyContent: "left",
            }}
          >
            <Grid container item sm={7}>
              <Grid
                item
                style={{
                  color: "#00CD68",
                  padding: 15,
                  fontWeight: "bold",
                }}
              >
                <WorkspacesTwoToneIcon fontSize={"large"} />
              </Grid>
              <Grid item>
                <Typography variant="p" color={"neutral"}>
                  {localEntity.sNombreComercial}
                </Typography>
                <Typography variant="h5" color={"primary"}>
                  Equivalencia de productos
                </Typography>
              </Grid>
            </Grid>
            <Grid
              container
              item
              sm={5}
              justifyContent={"flex-end"}
              alignItems={"center"}
            >
              <Button
                variant="contained"
                startIcon={<History />}
                onClick={onClickOpenModalBitacora}
              >
                Bitácora
              </Button>
              {isAllowdEdition && (
                <Button
                  variant="contained"
                  startIcon={isEditing ? <></> : <Edit />}
                  onClick={() => setIsEditing(!isEditing)}
                  sx={{ ml: 1 }}
                >
                  {isEditing ? "Cancelar" : "Editar"}
                </Button>
              )}
            </Grid>
            <Grid container spacing={2} style={{ padding: "20px 10px" }}>
              <table style={{ width: "100%", borderCollapse: "collapse" }}>
                <thead>
                  <tr style={{ height: 50 }}>
                    <th
                      style={{
                        borderBottom: "1px solid #f1f1f1",
                        width: "50%",
                      }}
                    >
                      Producto
                    </th>
                    <th
                      style={{
                        borderBottom: "1px solid #f1f1f1",
                        width: "50%",
                      }}
                    >
                      Equivalencia
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {form.map((equivalencia) => {
                    return (
                      <tr
                        key={equivalencia.sClaveProducto}
                        style={{ height: 50 }}
                      >
                        <td style={{ borderBottom: "1px solid #f1f1f1" }}>
                          ({equivalencia.sClaveProducto}){" "}
                          {equivalencia.sDescripcion}
                        </td>
                        <td style={{ border: "5px" }}>
                          <TextInput
                            id="sClaveProductoCadena"
                            name="sClaveProductoCadena"
                            value={equivalencia.sClaveProductoCadena}
                            onChangeRoot={(e) =>
                              onChangeInput(e, equivalencia.sClaveProducto)
                            }
                            require
                            size="small"
                            disabled={!isEditing}
                          />
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </Grid>
          </Grid>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              width: "100%",
              justifyContent: "end",
            }}
          >
            <Button
              onClick={onCloseModalEquivalences}
              sx={{ color: "#00609C" }}
            >
              Cerrar
            </Button>
            {isAllowdEdition && isEditing ? (
              <Button
                variant="contained"
                onClick={() => setShowConfirmDialog(true)}
              >
                Guardar
              </Button>
            ) : (
              <></>
            )}
          </div>
        </Box>
      </ModalBase>
    </>
  );
};
