import { useEffect, useState } from "react";

import {
  Box,
  Button,
  Collapse,
  InputAdornment,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import {
  AccountBalance,
  AddCard,
  AssignmentInd,
  CreditScore,
  DriveFolderUpload,
  LocationOn,
  Person,
  PersonAddAlt1,
  Receipt,
  RemoveRedEye,
  Settings,
} from "@mui/icons-material";
import {
  BlueSwitch,
  buttonContactStyle,
  buttonFileStyle,
  buttonViewPDF,
} from "../helpers/stylesMaterial";

import { AddButton } from "../components/AddButton";
import { Card } from "../components/Card";
import { EscalonadoForms } from "../components/cadena/escalonado/EscalonadoForms";
import { Liquidacion } from "../components/cadena/Liquidacion";
import { regexEmail, regexNumChart, regexNums, regexRFC, regexTelefono } from "../helpers/regexs";
import { SelectInput } from "../components/form/SelectInput";
import { TextInput } from "../components/form/TextInput";
import { useFormPage } from "../hooks/useFormPage";
import { useMenu } from "../hooks/useMenu";
import { typesSave } from "../helpers/types";

// import { CuentaContable } from '../components/CuentaContable';
// import { SearchInput } from '../components/form/SearchInput';
import { IvaInput } from "../components/form/IvaInput";
import { ivas } from "../helpers/data-iva";
import { FacturasDetalle } from "../components/FacturasDetalle";
import { EscCantidadForms } from "../components/escalonadoCantidad/EscCantidadForms";
import { CuentaContableCash } from "../components/cuentaContables/CuentaContableCash";
import "../styles/cadenas.css";
import {
  DatePicker,
  DesktopDatePicker,
  LocalizationProvider,
} from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { SearchInput } from "../components/form/SearchInput";
import { startLoadEmisores } from "../slices/catalogo/catalgoThunk";
import { ConfirmDialog } from "../components/modal/ConfirmDialog";

const tipos = [
  { value: "1", text: "Fiscal" },
  /* { value: "2", text: "Oficina" },
  { value: "3", text: "Sucursal" }, */
];

const gridSpan = (span = 2) => ({
  gridColumn: `span ${span}`,
});

export const EFRPage = () => {
  const { setNameSection } = useMenu();

  const {
    bancos = [],
    bancoSearched,
    cardBlock,
    ciudades = [],
    colonias = [],
    contactos,
    direccionSearched,
    documentos = [],
    estados = [],
    form,
    // giros = [],
    errorInputs,
    emisores,
    integraciones,
    retenciones,
    identificadores,
    clickAddContact,
    clickOpenListContact,
    clikcOpenViewPdf,
    // loadIninit,
    onChange,
    onChangeInput,
    onSubmitEFR,
    isDataCorrectEFR,
    setDireccion,
    setBanco,
    startLoadTiposLiquidacion,
    dispatch,
    onLoadDatosOperativos,
    startLoadDocumentos,
    setFormCadena,
    onChageDate,
    cleanForms,
    startLoadCuentasContablesCadena,
    loadBancos,
    onBlur,
    onBlurIdentificador,
    loadProcesos,
  } = useFormPage({ saveIn: typesSave.EFR });

  const [isOpenConfirmDialog, setIsOpenConfirmDialog] = useState(false)

  const handleOpenConfirmDialog = async () => {
    setIsOpenConfirmDialog(await isDataCorrectEFR());
  }

  const handleCloseConfirmDialog = () => {
    setIsOpenConfirmDialog(false);
  }

  const handleSubmit = async () => {
    setIsOpenConfirmDialog(false);
    await onSubmitEFR()
  }

  useEffect(() => {
    setNameSection("Entidades Financieras Reguladas");
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    // loadIninit();
    dispatch(startLoadEmisores());
    return () => {
      cleanForms();
    };
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    setDireccion();
    // eslint-disable-next-line
  }, [direccionSearched.colonia]);

  useEffect(() => {
    setBanco();
    // eslint-disable-next-line
  }, [bancoSearched]);

  const identificadorCNBV = identificadores.find(
    (ident) => ident.nIdIdentificador == form.nIdIdentificador
  );
  return (
    <div style={{ width: "100%", margin: "0px auto" }}>
      <ConfirmDialog
        isOpenDialog={isOpenConfirmDialog}
        onCloseDialog={handleCloseConfirmDialog}
        onConfimDialog={handleSubmit}
        message="¿Seguro que deseas dar de alta a la EFR?"
        textButtonConfirm="Sí, estoy seguro"
      />
      <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={"es-mx"}>
        <AddButton onSubmit={() => handleOpenConfirmDialog()} />
        {/* ========================== === DATOS GENERALES === ========================= */}
        <Card
          title="datos generales"
          icon={<AssignmentInd sx={{ color: "white", fontSize: "23px" }} />}
          openCollapse={true}
          block={cardBlock.datosGenerales}
          idElement="datosGenerales-efr"
        >
          <div className="cadenas__inputs-container cadenas__grid-2">
            <div style={{ ...gridSpan() }}>
              <TextField
                id="razonSocial"
                name="razonSocial"
                label="Razón Social"
                value={form.razonSocial}
                onChange={onChange}
                variant="outlined"
                onBlur={onBlurIdentificador}
                error={errorInputs.razonSocial}
                helperText={errorInputs.razonSocial ? "Requerido" : ""}
                fullWidth
              />
            </div>

            <TextInput
              id="nombreComercial"
              name="nombreComercial"
              label="Nombre Comercial"
              value={form.nombreComercial}
              onChangeRoot={onChangeInput}
              require
            />

            <TextInput
              id="rfc"
              name="rfc"
              label="RFC"
              value={form.rfc.toUpperCase()}
              onChangeRoot={onChangeInput}
              require
              expresionRegular={regexRFC}
              caracteres="13"
              erroMsg="RFC inválido"
            />

            <TextInput
              id="regimenSocietario"
              name="regimenSocietario"
              label="Régimen de capital"
              value={form.regimenSocietario}
              onChangeRoot={onChangeInput}
              require
            />

            <TextInput
              id="sActividadEconomica"
              name="sActividadEconomica"
              label="Actividad económica"
              value={form.sActividadEconomica}
              onChangeRoot={onChangeInput}
              require
            />

            <TextInput
              id="sMail"
              name="sMail"
              label="Correo"
              value={form.sMail}
              onChangeRoot={onChangeInput}
              expresionRegular={regexEmail}
              erroMsg="Correo inválido"
              require
            />

            <TextInput
              id="sTelefono"
              name="sTelefono"
              label="Teléfono"
              value={form.sTelefono}
              onChangeRoot={onChangeInput}
              caracteres="10"
              expresionRegular={regexTelefono}
              erroMsg="Teléfono inválido"
              require
            />

            <div style={{ ...gridSpan("2") }}>
              <SearchInput
                options={identificadores.map(ident => {
                  return {
                    ...ident,
                    name: `${ident.sDescripcion} (${ident.sIdentificador})`
                  }
                })}
                value={form.nIdIdentificador}
                name="nIdIdentificador"
                label="Identificador CNBV"
                getOptionLabel="name"
                getIndexLabel="value"
                onChange={onChange}
              />
              {form.nIdIdentificador && identificadores ? (
                <p
                  style={{
                    marginBottom: 5,
                  }}
                >
                  Estado del identificador:{" "}
                  <b
                    style={{
                      color:
                        identificadorCNBV?.sEstado === "Revocada"
                          ? "red"
                          : identificadorCNBV?.sEstado === "En Operación"
                            ? "#00CD68"
                            : "#eed63b",
                    }}
                  >
                    {identificadorCNBV?.sEstado}
                  </b>
                </p>
              ) : (
                <></>
              )}

            </div>
          </div>
          <div className="cadenas__blue-switch" style={{ marginTop: "15px" }}>
            <p>¿Es indirecto?</p>
            <BlueSwitch
              onChange={onChange}
              inputProps={{ "aria-label": "controlled" }}
              name="isIndirecto"
              checked={form.isIndirecto}
              value={form.isIndirecto}
            />
            <p>{form.isIndirecto ? "Sí" : "No"}</p>
          </div>
          <Collapse in={form.isIndirecto}>
            <div className="cadenas__inputs-container cadenas__grid-2">
              <SearchInput
                options={emisores.rows}
                value={form.nIdIntegrador}
                name="nIdIntegrador"
                label="Integrador"
                getOptionLabel="sNombreComercial"
                getIndexLabel="nIdEmisor"
                onChange={onChange}
                onChangeInnerInput={onChange}
              />
              <TextInput
                id="nPorcentajeComision"
                name="nPorcentajeComision"
                label="Comisión de integrador"
                value={form.nPorcentajeComision}
                onChangeRoot={onChangeInput}
                type="number"
                require
                endAdornment={
                  <InputAdornment position="start">%</InputAdornment>
                }
              />
            </div>
          </Collapse>
        </Card>
        {/* ========================== === DIRECCION === ========================= */}
        <Card
          title="dirección fiscal y contactos"
          icon={<LocationOn sx={{ color: "white", fontSize: "23px" }} />}
          openCollapse={cardBlock.direcionContacto ? false : true}
          idElement="direccion-efr"
          loadEvent={async () => await loadProcesos()}
        >
          <div className="cadenas__inputs-container">
            <TextInput
              id="calle"
              name="calle"
              label="Calle"
              value={form.calle}
              onChangeRoot={onChangeInput}
              require
            />

            <TextInput
              id="noExterior"
              name="noExterior"
              label="Número Exterior"
              value={form.noExterior}
              onChangeRoot={onChangeInput}
              expresionRegular={regexNums}
              erroMsg="Número inválido"
              caracteres="6"
              require
            />

            <TextInput
              id="noInterior"
              name="noInterior"
              label="Número Interior"
              value={form.noInterior}
              onChangeRoot={onChangeInput}
              expresionRegular={regexNumChart}
              caracteres="6"
            />

            <TextInput
              id="codigoPostal"
              name="codigoPostal"
              label="Código Postal"
              value={form.codigoPostal}
              onChangeRoot={onChangeInput}
              expresionRegular={regexNums}
              require
              caracteres="5"
            />

            <SelectInput
              id="estado"
              name="estado"
              onChange={onChange}
              label="Estado"
              data={estados}
              value={form.estado}
            />

            <SelectInput
              id="ciudad"
              name="ciudad"
              onChange={onChange}
              label="Municipio"
              data={ciudades}
              value={form.ciudad}
            />

            <SelectInput
              id="colonia"
              name="colonia"
              onChange={onChange}
              label="Colonia"
              data={colonias}
              value={form.colonia}
            />

            <SelectInput
              id="tipo"
              name="tipo"
              onChange={onChange}
              label="Tipo"
              data={tipos}
              value={form.tipo}
            />
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
              }}
            >
              <div style={{ width: "48%" }}>
                <Tooltip title="Contactos" placement="bottom" arrow>
                  <Button
                    variant="outlined"
                    size="large"
                    sx={buttonContactStyle}
                    onClick={clickOpenListContact}
                  >
                    <Person />
                    {contactos.length > 0 && (
                      <p className="cadenas__button-index">
                        {contactos.length <= 10 ? contactos.length : "10+"}
                      </p>
                    )}
                  </Button>
                </Tooltip>
              </div>
              <div style={{ width: "48%" }}>
                <Tooltip title="Nuevo Contacto" placement="bottom" arrow>
                  <Button
                    variant="outlined"
                    size="large"
                    sx={buttonContactStyle}
                    onClick={clickAddContact}
                  >
                    <PersonAddAlt1 />
                  </Button>
                </Tooltip>
              </div>
            </div>
          </div>
        </Card>
        {/* ========================== === DATOS BANCARIOS === ========================= */}
        <Card
          title="Datos Bancarios"
          icon={<AccountBalance sx={{ color: "white", fontSize: "23px" }} />}
          // block={cardBlock.datosBancarios}
          openCollapse={cardBlock.datosBancarios ? false : true}
          loadEvent={async () => await loadBancos()}
          idElement="datosBancarios-efr"
        >
          <div
            className="cadenas__inputs-container"
            style={{ gridTemplateColumns: "repeat(2, 1fr)" }}
          >
            <div style={{ ...gridSpan() }}>
              <TextInput
                id="beneficiario"
                name="beneficiario"
                label="Beneficiario"
                value={form.beneficiario}
                onChangeRoot={onChangeInput}
                require
              />
            </div>

            <TextField
              id="clabeInterBanc"
              name="clabeInterBanc"
              label="CLABE Interbancaria para depósitos de pagos"
              value={form.clabeInterBanc}
              onChange={onChange}
              variant="outlined"
              onBlur={onBlur}
              error={errorInputs.clabeInterBanc}
              helperText={
                errorInputs.clabeInterBanc ? "CLABE Interbancaria inválida" : ""
              }
            />

            <SelectInput
              id="banco"
              name="banco"
              onChange={onChange}
              label="Bancos"
              data={bancos}
              value={form.banco ? form.banco : ""}
            // require
            />

            <TextInput
              id="refNumerica"
              name="refNumerica"
              label="Referencia numérica"
              value={form.refNumerica}
              onChangeRoot={onChangeInput}
              type='number'
              error={errorInputs.refNumerica}
              require
              helperText={
                errorInputs.refNumerica ? "Longitud máxima: 10 carácteres" : ""
              }
            />

            <TextInput
              id="refAlfaNumerica"
              name="refAlfaNumerica"
              label="Referencia alfanumérica"
              value={form.refAlfaNumerica}
              onChangeRoot={onChangeInput}
              require
              error={errorInputs.refAlfaNumerica}
              helperText={
                errorInputs.refAlfaNumerica ? "Longitud máxima: 15 carácteres" : ""
              }
            />
          </div>
        </Card>
        {/* ========================== === DATOS OPERATIVOS === ========================= */}
        <Card
          title="datos operativos"
          icon={<Settings sx={{ color: "white", fontSize: "23px" }} />}
          // block={cardBlock.datosOperativos}
          openCollapse={cardBlock.datosOperativos ? false : true}
          loadEvent={async () => await onLoadDatosOperativos()}
          idElement="datosOperativos-efr"
        >
          <div className="cadenas__inputs-container">
            <SelectInput
              id="retencion"
              name="retencion"
              onChange={onChange}
              label="Retención"
              data={retenciones}
              value={form.retencion}
            />

            {/* <TextInput
            id="montoRecepcion"
            name="montoRecepcion"
            label="Monto máximo de recepción"
            value={form.montoRecepcion}
            onChangeRoot={onChangeInput}
            type="number"
            require
          /> */}

            <SelectInput
              id="ingragracion"
              name="ingragracion"
              onChange={onChange}
              label="Integración"
              data={integraciones}
              value={form.ingragracion}
            />

            <SelectInput
              id="ivaOperativo"
              name="ivaOperativo"
              onChange={(e) => onChange(e)}
              label="IVA"
              data={ivas}
              value={form.ivaOperativo}
            />

            <IvaInput
              id="costoTransferencia"
              name="costoTransferencia"
              label="Costo por transferencia"
              value={form.costoTransferencia}
              onChangeRoot={onChangeInput}
              variant="outlined"
              fullWidth
              type="number"
              size="medium"
              ivaValue={
                form.ivaOperativo !== "" ? form.ivaOperativoVal * 100 : 0.16
              }
              startAdornment={
                <InputAdornment position="start">$</InputAdornment>
              }
            />

            <TextInput
              id="facturacionMinima"
              name="facturacionMinima"
              label="Facturación mínima"
              value={form.facturacionMinima}
              onChangeRoot={onChangeInput}
              type="number"
              require
              startAdornment={
                <InputAdornment position="start">$</InputAdornment>
              }
            />

            <DesktopDatePicker
              disablePast
              label="Fecha de inicio de operaciones"
              inputFormat="YYYY-MM-DD"
              renderInput={(params) => <TextField {...params} />}
              value={form.dInicioOperaciones}
              onChange={(e) => onChageDate("dInicioOperaciones", e["$d"])}
            />
          </div>
          <div className="cadenas__blue-switch" style={{ marginTop: "15px" }}>
            <BlueSwitch
              onChange={onChange}
              inputProps={{ "aria-label": "controlled" }}
              name="isActiveMGracia"
              checked={form.isActiveMGracia}
              value={form.isActiveMGracia}
            />
            <p>¿Se activará periodo de gracia?</p>
          </div>
          <Collapse in={form.isActiveMGracia}>
            <div
              className="cadenas__inputs-container"
              style={{ marginTop: "15px" }}
            >
              <DatePicker
                disablePast
                label="Periodo de gracia"
                openTo="year"
                views={["year", "month"]}
                value={form.nMesesGracia}
                onChange={(newValue) => onChageDate("nMesesGracia", newValue)}
                renderInput={(params) => <TextField {...params} fullWidth />}
              />
            </div>
          </Collapse>
          <div className="cadenas__blue-switch" style={{ marginTop: "15px" }}>
            <BlueSwitch
              onChange={onChange}
              inputProps={{ "aria-label": "controlled" }}
              name="isEscCantidad"
              disabled
              checked={form.isEscCantidad}
              value={form.isEscCantidad}
            />
            <p>¿Se pagará escalonado por operaciones?</p>
          </div>
          <Collapse in={form.isEscCantidad}>
            <EscCantidadForms saveIn={typesSave.CADENA} />
          </Collapse>
          {/* <div className="cadenas__blue-switch" style={{ marginTop: "15px" }}>
            <BlueSwitch
              onChange={onChange}
              inputProps={{ "aria-label": "controlled" }}
              name="isEscaloando"
              checked={form.isEscaloando}
              value={form.isEscaloando}
            />
            <p>¿Se pagará escalonado por ticket?</p>
          </div>
          <Collapse in={form.isEscaloando}>
            <EscalonadoForms saveIn={typesSave.CADENA} />
          </Collapse> */}
        </Card>
        {/* ========================== === LIQUIDACION CASH IN === ========================= */}
        <Card
          title="Programación liquidación (CASH IN)"
          icon={<CreditScore sx={{ color: "white", fontSize: "23px" }} />}
          // block={cardBlock.liquidacion}
          openCollapse={cardBlock.liquidacion ? false : true}
          loadEvent={async () => await dispatch(startLoadTiposLiquidacion())}
          idElement="cashIn-efr"
        >
          <div className="cadenas__blue-switch" style={{ marginTop: "15px" }}>
            <p>¿Deshabilitar CASH IN?</p>
            <BlueSwitch
              onChange={onChange}
              inputProps={{ "aria-label": "controlled" }}
              name="isActiveCashIn"
              checked={form.isActiveCashIn}
              value={form.isActiveCashIn}
            />
            <p>{form.isActiveCashIn ? "Si" : "No"}</p>
          </div>
          <Collapse in={!form.isActiveCashIn}>
            <Liquidacion
              onCadenaChange={setFormCadena}
              cadena={form}
              tipoMovimiento="liquidacionCashIn"
              key="liquidacionCashInEfr"
            />
          </Collapse>
        </Card>
        {/* ========================== === LIQUIDACION CASH OUT === ========================= */}
        <Card
          title="Programación liquidación (CASH OUT)"
          icon={<AddCard sx={{ color: "white", fontSize: "23px" }} />}
          // block={cardBlock.liquidacion}
          openCollapse={cardBlock.liquidacion ? false : true}
          loadEvent={async () => await dispatch(startLoadTiposLiquidacion())}
          idElement="cashOut-efr"
        >
          <div className="cadenas__blue-switch" style={{ marginTop: "15px" }}>
            <p>¿Deshabilitar CASH OUT?</p>
            <BlueSwitch
              onChange={onChange}
              inputProps={{ "aria-label": "controlled" }}
              name="isActiveCashOut"
              checked={form.isActiveCashOut}
              value={form.isActiveCashOut}
            />
            <p>{form.isActiveCashOut ? "Si" : "No"}</p>
          </div>
          <Collapse in={!form.isActiveCashOut}>
            <Liquidacion
              onCadenaChange={setFormCadena}
              cadena={form}
              tipoMovimiento="liquidacionCashOut"
              key="liquidacionCashOutEfr"
            />
          </Collapse>
        </Card>
        {/* ========================== === DOCUMENTOS === ========================= */}
        <Card
          title="Documentos"
          icon={<DriveFolderUpload sx={{ color: "white", fontSize: "23px" }} />}
          // block={cardBlock.documentos}
          openCollapse={cardBlock.documentos ? false : true}
          loadEvent={async () => await dispatch(startLoadDocumentos(0, 1))}
          idElement="documentos-efr"
        >
          {documentos.map((doc) => (
            <RenderDocumento
              key={doc.value}
              clickViewPdf={() => clikcOpenViewPdf(`${doc.value}`)}
              form={form}
              onChange={onChange}
              documento={doc.text}
              name={`${doc.value}`}
            />
          ))}
        </Card>
        {/* ========================== === FACTURA === ========================= */}
        <FacturasDetalle idElement="facturaciones-efr" />

        {/* ========================== === TICKET === ========================= */}
        <Card
          title="Ticket"
          icon={<Receipt sx={{ color: "white", fontSize: "23px" }} />}
          // block={cardBlock.documentos}
          openCollapse={cardBlock.ticket}
          idElement="ticket-efr"
        >
          <div className="cadenas__inputs-container cadenas__grid-2">
            <div style={{ ...gridSpan() }}>
              <TextInput
                id="sMensajeTicket"
                name="sMensajeTicket"
                label="Leyenda ticket"
                value={form.sMensajeTicket}
                onChangeRoot={onChangeInput}
                minRows={3}
                multiline
              />
            </div>

          </div>
        </Card>
        {/* ===================== === CUENTA CONTABLE CASH - IN === ==================== */}
        {!form.isActiveCashIn && (
          <CuentaContableCash
            idElement="cuentaContableCashIn-efr"
            title="Cuentas contables (CASH IN)"
            referencia="cashIn"
            retencion={form.retencion}
            nFlujo={1}
            loadCuentas={() => dispatch(startLoadCuentasContablesCadena(1))}
            key={"cashInCuentaContable-1"}
          />
        )}
        {/* ===================== === CUENTA CONTABLE CASH - OUT === ==================== */}
        {!form.isActiveCashOut && (
          <CuentaContableCash
            idElement="cuentaContableCashOut-efr"
            title="Cuentas contables (CASH OUT)"
            referencia="cashOut"
            retencion={form.retencion}
            nFlujo={0}
            loadCuentas={() => dispatch(startLoadCuentasContablesCadena(1))}
            key={"cashOutCuentaContable-1"}
          />
        )}
      </LocalizationProvider>
    </div>
  );
};

const RenderButtonPDF = ({ openPdf, value }) => {
  const onClick = async () => {
    await openPdf();
  };
  return (
    <>
      {value && value !== "" && (
        <div className="flex-row center">
          <Button
            variant="outlined"
            component="label"
            onClick={onClick}
            sx={buttonViewPDF}
          >
            <RemoveRedEye />
          </Button>
        </div>
      )}
    </>
  );
};

const RenderDocumento = ({ documento, onChange, name, form, clickViewPdf }) => {
  return (
    <div className="flex-row space-between">
      <div
        className="cadenas__inputs-container cadenas__inputs-container-doc"
        style={{ width: "100%", marginBottom: "10px" }}
      >
        <Button
          variant="contained"
          component="label"
          sx={buttonFileStyle}
          className="card__btn-file"
        >
          {documento}
          <input
            hidden
            accept="application/pdf"
            multiple
            type="file"
            name={name}
            onChange={onChange}
          />
        </Button>
        <Typography variant="p" component="p" sx={{ textAlign: "center" }}>
          {form.documentos[name] &&
            form.documentos[name] !== "" &&
            form.documentos[name].name}
        </Typography>
        <RenderButtonPDF openPdf={clickViewPdf} value={form.documentos[name]} />
      </div>
    </div>
  );
};
