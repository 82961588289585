import React, { useEffect, useState } from 'react';
import ReactDataGrid, {
    textEditor
} from 'react-data-grid';
import 'react-data-grid/lib/styles.css';
import '../../../styles/spreadsheet.css';
import { SelectInput } from '../../form/SelectInput';


const CustomSpreadsheet = ({ data, rowIdx, handleData }) => {
    const [rows, setRows] = useState([]);
    const [columns, setColumns] = useState([]);

    useEffect(() => {
        const {
            rows = [],
            columns = []
        } = data;
        setRows(rows)
        setColumns(columns.filter(col => !col.notShowing))
    }, [data])


    const getColumnFormatter = (column) => {
        if (column.type === 'select' && !column.disableColumn) {
            return (p) => (
                <>
                    <SelectInput
                        fullWidth
                        sxSelect={{ fontSize: 14, pa: 0, width: "100%" }}
                        size={"small"}
                        data={[...(column?.options ? column.options(p.row) : []).map(op => ({ value: String(op).toUpperCase(), text: String(op).toUpperCase() }))]}
                        value={String(p.row[column.name]).toUpperCase()}
                        onChange={(e) => p.onRowChange({ ...p.row, [column.name]: e.target.value }, true)}
                        autoFocus
                    />
                </>
            )
        }

        return column.disableColumn ? undefined : textEditor;
    };

    const handleChangeRows = (newRows, changes) => {
        setRows(newRows)
        handleData(newRows, changes)
    }

    const renderCell = ({ row }, column) => {
        const value = row[column.name];
        return (
            <div style={{ background: column.disableColumn ? "#F9F9F9" : row.sErrorKeys ? String(row.sErrorKeys).split(",").find((key => key === column.name)) ? "#FFE74C50" : "white" : "white", paddingInline: "8px", width: "100%", height: "100%" }}>
                {value}
            </div>
        );
    }

    return (
        <div style={{ width: "100%" }}>
            <ReactDataGrid
                columns={columns.map((column, key) => ({
                    ...column,
                    key: column.name,
                    name: column.value,
                    renderEditCell: getColumnFormatter(column),
                    //frozen: key < 3,
                    disableColumn: column.disableColumn,
                    renderCell: (props) => renderCell(props, column),
                }))}
                /* defaultColumnOptions={{
                    resizable: true
                }} */
                rows={rows}
                rowKeyGetter={(row) => row[rowIdx]}
                onRowsChange={handleChangeRows}
                style={{ blockSize: '550px' }}
                className='rdg-light'
            />
        </div>
    );
};

export default CustomSpreadsheet;
