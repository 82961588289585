import { useEffect } from 'react';

import { ReportTable } from '../components/table/ReportTable';
import { useMenu } from '../hooks/useMenu';
import { useAuthPage } from '../hooks/useAuthPage';
import { Card, Typography } from '@mui/material';
import { Button, createTheme, ThemeProvider } from '@mui/material';
import { ReportForm } from '../components/ReportForm';
import { useReportsPage } from '../hooks/useReportsPage';
import { useModal } from '../hooks/useModal';
import { Summarize } from '@mui/icons-material';
import { ModalOpsReferencia } from '../components/modal/ModalOpsReferencia';

const iconTheme = createTheme({
  status: {
    danger: '#e53e3e'
  },
  palette: {
    primary: {
      main: '#00609C',
      dark: '#053e85'
    },
    neutral: {
      main: '#00CD68',
      light: '#053e85',
      dark: '#053e85',
      contrastText: '#fff'
    }
  }
});

export const ReporteOperacionesPage = () => {

  const { loadInInit } = useReportsPage();
  const { setNameSection } = useMenu();
  const { isAllowed, setAllowComponent } = useAuthPage();
  const { openModalOpsReferencia } = useModal();

  useEffect(() => {
    loadInInit();
  }, []);
  if (isAllowed) {
    return (
      <ThemeProvider theme={iconTheme}>
        {setAllowComponent('consultarReferencia') && <ModalOpsReferencia />}
        <ReportForm
          extraButtons={
            setAllowComponent('consultarReferencia') && <Button
              startIcon={<Summarize />}
              style={{ background: "white" }}
              onClick={openModalOpsReferencia}
            >
              Consulta por referencia
            </Button>
          }
        />
        <ReportTable />
      </ThemeProvider>
    );
  } else {
    setNameSection('');
    return (
      <Card>
        <Typography variant="h3">Contenido no disponible</Typography>
      </Card>
    );
  }
};
