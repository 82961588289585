import { useDispatch, useSelector } from "react-redux";
import {
  setConfigId,
  setConfigTipo,
} from "../slices/configConciliacion/configConciliacionSlice";
import {
  openContact,
  closeContact,
  openListContact,
  closeListContact,
  openViewPdf,
  closeViewPdf,
  openToast,
  closeToast,
  openListRoute,
  closeListRoute,
  openRoute,
  closeRoute,
  openConciliacion,
  closeConciliacion,
  openPermission,
  closePermission,
  openListPermission,
  closeListPermission,
  openConfirmation,
  closeConfirmation,
  openFacturacion,
  closeFacturacion,
  openDialogHelp,
  closeDialogHelp,
  openDelete,
  closeDelete,
  openProducts,
  closeProducts,
  openConfirmProductsDialog,
  closeConfirmProductsDialog,
  openRegistrosCortes,
  closeRegistrosCortes,
  openRegistrosOrdenes,
  closeRegistrosOrdenes,
  openFacturasOrdenes,
  closeFacturasOrdenes,
  openImportConciliacion,
  closeImportConciliacion,
  closeViewDoc,
  openViewDoc,
  disableListContactButtons,
  enableListContactButtons,
  loading,
  openComisionistas,
  closeComisionistas,
  openEnvioEmail,
  closeEnvioEmail,
  openComisionesEscalonadas,
  closeComisionesEscalonadas,
  openArchivoConciliacion,
  closeArchivoConciliacion,
  openEnvioOperaciones,
  closeEnvioOperaciones,
  openOpsReferencia,
  closeOpsReferencia,
  openAccesoR26,
  closeAccesoR26,
  openConexionesSFTP,
  closeConexionesSFTP,
  openBitacoraConciliacion,
  closeBitacoraConciliacion,
  openEquivalences,
  closeEquivalences,
  openBitacora,
  closeBitacora,
} from "../slices/ui/uiSlice";
import { nIdTipoLiquidacion24Hrs } from "../helpers/forms";

export const useModal = () => {
  const {
    isOpenContact,
    isOpenListContact,
    isOpenViewPdf,
    isOpenViewDoc,
    isOpenToast,
    isOpenConciliacion,
    isOpenRoute,
    isOpenListRoute,
    isOpenPermission,
    isOpenListPermission,
    isOpenConfirmation,
    isOpenFacturacion,
    isOpenDialogHelp,
    isOpenDelete,
    isOpenProducts,
    isOpenConfirmProductsDialog,
    isOpenRegistrosCortes,
    isOpenRegistrosOrdenes,
    isOpenFacturasOrdenes,
    isOpenImportConciliacion,
    isOpenListContactButtons,
    isTableLoading,
    isTableModalLoading,
    isOpenComisionistas,
    isOpenEnvioEmail,
    isOpenComisionesEscalonadas,
    isOpenArchivoConciliacion,
    isOpenEnvioOperaciones,
    isOpenOpsReferencia,
    isOpenAccesoR26,
    isOpenConexionesSFTP,
    isOpenBitacoraConciliacion,
    isOpenEquivalences,
    isOpenBitacora,
  } = useSelector((state) => state.ui);
  const dispatch = useDispatch();
  // ==================== MODAL ENVIO EMAIL ===================

  const openModalEnvioEmail = () => {
    dispatch(openEnvioEmail());
  };

  const closeModalEnvioEmail = () => {
    dispatch(closeEnvioEmail());
  };
  // ==================== MODAL OPEN CONTACT ===================

  const openModalContact = ({ edit = false, contact = null }) => {
    dispatch(openContact({ edit, contact }));
  };

  const closeModalContact = () => {
    dispatch(closeContact());
  };

  // ==================== MODAL OPEN CONTACT ===================

  const openModalEnvioOperaciones = ({ nIdActor = 0, type = "" }) => {
    dispatch(openEnvioOperaciones({ nIdActor, type }));
  };

  const closeModalEnvioOperaciones = () => {
    dispatch(closeEnvioOperaciones());
  };
  // ==================== MODAL OPEN COMISIONISTAS ===================

  const openModalComisionistas = () => {
    dispatch(openComisionistas());
  };

  const closeModalComisionistas = () => {
    dispatch(closeComisionistas());
  };
  // ==================== MODAL OPEN LIST CONTACT ===================

  const openModalListContact = () => {
    dispatch(openListContact());
  };

  const closeModalListContact = () => {
    dispatch(closeListContact());
  };
  // ==================== MODAL OPEN LIST CONTACT BUTTONS ===================

  const enableModalListContactButtons = () => {
    dispatch(enableListContactButtons());
  };

  const disableModalListContactButtons = () => {
    dispatch(disableListContactButtons());
  };
  // ==================== MODAL OPEN ROUTE ===================

  const openModalRoute = () => {
    dispatch(openRoute());
  };

  const closeModalRoute = () => {
    dispatch(closeRoute());
  };
  // ==================== MODAL OPEN LIST ROUTES ===================

  const openModalListRoute = () => {
    dispatch(openListRoute());
  };

  const closeModalListRoute = () => {
    dispatch(closeListRoute());
  };
  // ==================== MODAL OPEN IMPORT CONCILIACION ===================

  const openModalImportConciliacion = () => {
    dispatch(openImportConciliacion());
  };

  const closeModalImportConciliacion = () => {
    dispatch(closeImportConciliacion());
  };

  // ==================== MODAL OPEN REGISTROS CORTES ===================

  const openModalRegistrosCortes = () => {
    dispatch(openRegistrosCortes());
  };

  const closeModalRegistrosCortes = () => {
    dispatch(closeRegistrosCortes());
  };

  // ==================== MODAL OPEN REGISTROS ORDENES ===================

  const openModalRegistrosOrdenes = () => {
    dispatch(openRegistrosOrdenes());
  };

  const closeModalRegistrosOrdenes = () => {
    dispatch(closeRegistrosOrdenes());
  };

  // ==================== MODAL OPEN FACTURAS ORDENES ===================

  const openModalFacturasOrdenes = () => {
    dispatch(openFacturasOrdenes());
  };

  const closeModalFacturasOrdenes = () => {
    dispatch(closeFacturasOrdenes());
  };

  // ==================== MODAL OPEN PERMISSION ===================

  const openModalPermission = () => {
    dispatch(openPermission());
  };

  const closeModalPermission = () => {
    dispatch(closePermission());
  };
  // ==================== MODAL OPEN LIST PERMISSIONS ===================

  const openModalListPermission = () => {
    dispatch(openListPermission());
  };

  const closeModalListPermission = () => {
    dispatch(closeListPermission());
  };
  // ==================== MODAL VIEW PDF ===================
  const openMoldaViewPdf = (file, name = "") => {
    if (name !== "") name = name.includes(".") ? name : `${name}.pdf`;
    dispatch(openViewPdf({ file, name }));
  };
  const closeModalViewPdf = () => {
    dispatch(closeViewPdf());
  };
  // ==================== MODAL VIEW DOC ===================
  const openModalViewDoc = (path, fileType, fileName = "") => {
    dispatch(openViewDoc({ path, fileType, fileName }));
  };
  const closeModalViewDoc = () => {
    dispatch(closeViewDoc());
  };
  // ==================== MODAL CONCILIACION ================
  const openModalConciliacion = (value) => {
    dispatch(openConciliacion());
  };
  const closeModalConciliacion = () => {
    dispatch(closeConciliacion());
    dispatch(setConfigId(0));
    dispatch(setConfigTipo(""));
  };
  //=================== TOAST ===============================
  const setOpenToast = (error = false, message) => {
    dispatch(openToast({ error, message }));
  };

  const setCloseToast = () => {
    dispatch(closeToast());
  };
  // ================ CONFIRMACION ===========================
  const openModalConfirmacion = (idCadena) => {
    dispatch(openConfirmation(idCadena));
  };

  const closeModalConfirmacion = () => {
    dispatch(closeConfirmation());
  };

  // ================ PRODUCTS CONFIRM DIALOG ===========================
  const openProductsDialog = () => {
    dispatch(openConfirmProductsDialog());
  };

  const closeProductsDialog = () => {
    dispatch(closeConfirmProductsDialog());
  };

  // ================ FACTURACION ===========================
  const openModalFacturacion = ({
    title,
    refForm,
    iva = "16%",
    type = "Agregar",
  }) => {
    dispatch(openFacturacion({ title, refForm, iva, type }));
  };

  const closeModalFacturacion = () => {
    dispatch(closeFacturacion());
  };
  // ================ DIALOG ===========================
  const openHelpDialog = ({ title, text }) => {
    dispatch(openDialogHelp({ title, text }));
  };

  const closeHelpDialog = () => {
    dispatch(closeDialogHelp());
  };
  // ================ DELETE ===========================

  const openModalDelte = ({ id, type, title }) => {
    dispatch(openDelete({ id, type, title }));
  };

  const closeModalDelete = () => {
    dispatch(closeDelete());
  };

  // ==================== MODAL PRODUCTS ===================

  const openModalProducts = () => {
    dispatch(openProducts());
  };

  const closeModalProducts = () => {
    dispatch(closeProducts());
  };

  // ==================== LOADING ===================

  const isLoading = (flag) => {
    dispatch(loading(flag));
  };

  // ==================== MODAL COMISIONES ESCALONADAS ===================

  const openModalComisionesEscalonadas = (emisor, escalonadas) => {
    let { oLiquidacionCashIn = "[]", oLiquidacionCashOut = "[]" } = emisor;

    oLiquidacionCashIn = JSON.parse(oLiquidacionCashIn);
    oLiquidacionCashOut = JSON.parse(oLiquidacionCashOut);

    const nIdTipoLiquidacionCI = oLiquidacionCashIn.length
      ? oLiquidacionCashIn[0].nIdTipoLiquidacion
      : 0;
    const nIdTipoLiquidacionCO = oLiquidacionCashOut.length
      ? oLiquidacionCashOut[0].nIdTipoLiquidacion
      : 0;

    dispatch(
      openComisionesEscalonadas({
        nIdEmisor: emisor.nIdEmisor,
        sNombreComercial: emisor.sNombreComercial,
        has24Horas: [nIdTipoLiquidacionCI, nIdTipoLiquidacionCO].includes(
          nIdTipoLiquidacion24Hrs
        ),
      })
    );
  };

  const closeModalComisionesEscalonadas = () => {
    dispatch(closeComisionesEscalonadas());
  };

  // ==================== MODAL ACCESO R26 ===================

  const openModalAccesoR26 = (emisor) => {
    dispatch(openAccesoR26({ emisor }));
  };

  const closeModalAccesoR26 = () => {
    dispatch(closeAccesoR26());
  };

  // ==================== MODAL DETALLE ARCHIVO CONCILIACION ===================

  const openModalArchivoConciliacion = (archivo) => {
    dispatch(openArchivoConciliacion({ archivo }));
  };

  const closeModalArchivoConciliacion = () => {
    dispatch(closeArchivoConciliacion());
  };

  // ==================== MODAL OPS REFERENCIA ===================

  const openModalOpsReferencia = (transaccion) => {
    dispatch(openOpsReferencia({ transaccion }));
  };

  const closeModalOpsReferencia = () => {
    dispatch(closeOpsReferencia());
  };

  // ==================== MODAL OPS REFERENCIA ===================

  const openModalConexionesSFTP = ({ nIdEmisor = null, nIdCadena = null }) => {
    dispatch(openConexionesSFTP({ nIdEmisor, nIdCadena }));
  };

  const closeModalConexionesSFTP = () => {
    dispatch(closeConexionesSFTP());
  };

  // ==================== MODAL BITACORA CONCILIACION ===================

  const openModalBitacoraConciliacion = (
    nIdEmisor = null,
    nIdCadena = null,
    sNombreComercial,
    dFechaConciliacion,
    sClaveProducto = null
  ) => {
    dispatch(
      openBitacoraConciliacion({
        nIdEmisor,
        nIdCadena,
        sNombreComercial,
        dFechaConciliacion,
        sClaveProducto,
      })
    );
  };

  const closeModalBitacoraConciliacion = () => {
    dispatch(closeBitacoraConciliacion());
  };

  // ==================== MODAL EQUIVALENCIAS ===================

  const openModalEquivalences = () => {
    dispatch(openEquivalences());
  };

  const closeModalEquivalences = () => {
    dispatch(closeEquivalences());
  };

  // ==================== MODAL BITACORA ===================

  const openModalBitacora = () => {
    dispatch(openBitacora());
  };

  const closeModalBitacora = () => {
    dispatch(closeBitacora());
  };

  return {
    isOpenContact,
    isOpenDelete,
    isOpenListContact,
    isOpenViewPdf,
    isOpenViewDoc,
    isOpenToast,
    isOpenRoute,
    isOpenListRoute,
    isOpenConciliacion,
    isOpenPermission,
    isOpenListPermission,
    isOpenConfirmation,
    isOpenFacturacion,
    isOpenDialogHelp,
    isOpenProducts,
    isOpenConfirmProductsDialog,
    isOpenRegistrosCortes,
    isOpenRegistrosOrdenes,
    isOpenFacturasOrdenes,
    isOpenImportConciliacion,
    isOpenListContactButtons,
    isTableLoading,
    isOpenComisionistas,
    isOpenEnvioEmail,
    isOpenComisionesEscalonadas,
    isOpenArchivoConciliacion,
    isOpenEnvioOperaciones,
    isTableModalLoading,
    isOpenOpsReferencia,
    isOpenAccesoR26,
    isOpenConexionesSFTP,
    isOpenBitacoraConciliacion,
    isOpenEquivalences,
    isOpenBitacora,

    closeModalContact,
    closeModalDelete,
    closeModalFacturacion,
    closeModalListContact,
    closeModalRoute,
    closeModalListRoute,
    closeModalViewPdf,
    closeModalViewDoc,
    closeModalPermission,
    closeModalListPermission,
    closeModalConfirmacion,
    closeHelpDialog,
    closeModalProducts,
    closeProductsDialog,
    closeModalRegistrosCortes,
    closeModalRegistrosOrdenes,
    closeModalFacturasOrdenes,
    closeModalConciliacion,
    closeModalImportConciliacion,
    closeModalComisionistas,
    closeModalEnvioEmail,
    closeModalComisionesEscalonadas,
    closeModalArchivoConciliacion,
    closeModalEnvioOperaciones,
    closeModalOpsReferencia,
    closeModalAccesoR26,
    closeModalConexionesSFTP,
    closeModalBitacoraConciliacion,
    closeModalEquivalences,
    closeModalBitacora,

    openModalContact,
    openModalDelte,
    openModalFacturacion,
    openModalListContact,
    openModalRoute,
    openModalListRoute,
    openMoldaViewPdf,
    openModalViewDoc,
    openModalListPermission,
    openModalPermission,
    openModalProducts,
    openProductsDialog,
    openModalConciliacion,
    openModalConfirmacion,
    openHelpDialog,
    openModalRegistrosCortes,
    openModalRegistrosOrdenes,
    openModalFacturasOrdenes,
    openModalImportConciliacion,
    openModalComisionistas,
    openModalEnvioEmail,
    openModalComisionesEscalonadas,
    openModalArchivoConciliacion,
    openModalEnvioOperaciones,
    openModalOpsReferencia,
    openModalAccesoR26,
    openModalConexionesSFTP,
    openModalBitacoraConciliacion,
    openModalEquivalences,
    openModalBitacora,

    setOpenToast,
    setCloseToast,
    disableModalListContactButtons,
    enableModalListContactButtons,
    isLoading,
  };
};
