import { useEffect, useState } from "react";
import { useMenu } from "../hooks/useMenu";
import { useAuthPage } from "../hooks/useAuthPage";
import {
  Button,
  createTheme,
  Grid,
  IconButton,
  Paper,
  ThemeProvider,
  Tooltip,
  Typography,
} from "@mui/material";
import {
  Visibility,
  Delete
} from "@mui/icons-material";
import { useModal } from "../hooks/useModal";

import EnhancedTable from "../components/table/EnhancedTable";
import { ConfirmDialog } from "../components/modal/ConfirmDialog";
import KeyboardDoubleArrowLeftIcon from '@mui/icons-material/KeyboardDoubleArrowLeft';
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';
import { useCatalogos } from "../hooks/useCatalogos";
import { ModalCatalogo } from "../components/modal/ModalCatalogo";
import ListIcon from '@mui/icons-material/List';

const iconTheme = createTheme({
  status: {
    danger: "#e53e3e",
  },
  palette: {
    primary: {
      main: "#00609C",
      dark: "#053e85",
    },
    neutral: {
      main: "#00CD68",
      light: "#053e85",
      dark: "#053e85",
      contrastText: "#fff",
    },
    gray: {
      main: "rgba(0, 0, 0, 0.5)",
      dark: "rgba(0, 0, 0, 0.5)",
    },
  },
});


export const VerCatalogosPage = () => {
  const { setNameSection } = useMenu();
  const { setAllowComponent } = useAuthPage();
  const { isTableLoading } = useModal();

  const colorsTab = ["#E7B10A", "#00CD68", "#e53e3e", "#053e85"]
  const {
    dataTable,
    selectedCatalogo,
    hideMenu,
    catalogoItems,
    openModal,
    form,

    handleSelectedCatalogo,
    handleHideMenu,
    handleSubmit,
    handleCloseModal,
    handleOpenModal,
    enableEdit,
    onChange,
    disableEdit,
    loadInInit
  } = useCatalogos();

  const [showConfirmDialog, setShowConfirmDialog] = useState(false)


  const triggerDeleteDialog = (row) => {
    setShowConfirmDialog(row);
  };

  const handleConfirmDialog = async () => {
    setShowConfirmDialog(false)
    await handleSubmit('delete', showConfirmDialog)
  }

  useEffect(() => {
    setNameSection("catalogos");
  }, [setNameSection]);

  useEffect(() => {
    loadInInit();
  }, []);

  return (
    <ThemeProvider theme={iconTheme}>
      <ConfirmDialog
        isOpenDialog={!!showConfirmDialog}
        onCloseDialog={() => setShowConfirmDialog(false)}
        onConfimDialog={handleConfirmDialog}
        message={`¿Estas seguro/a de deshabilitar el registro?`}
        textButtonConfirm="Sí, si estoy seguro/a."
      />
      <ModalCatalogo
        isOpenModal={openModal.open}
        modalType={openModal.type}
        selectedCatalogo={selectedCatalogo}
        onChange={onChange}
        form={form}
        handleCloseModal={handleCloseModal}
        handleSubmit={handleSubmit}
        enableEdit={enableEdit}
        disableEdit={disableEdit}
      />
      <Grid container spacing={8}>
        <Grid item sx={{ width: { xs: "100%", sm: `${hideMenu ? 100 : 270}px` } }}>
          <Paper elevation={3} sx={{ width: '100%', py: 3, px: 2, mt: 2 }}>
            <Grid container justifyContent={"space-between"} alignItems={"center"}>
              {!hideMenu &&
                <Typography variant="h6" color={"primary"}>
                  <ListIcon color="primary" sx={{
                    mr: 1, position: "relative",
                    top: 5
                  }} />Catálogos</Typography>
              }
              <IconButton onClick={() => handleHideMenu(!hideMenu)} color="primary">{hideMenu ? <KeyboardDoubleArrowRightIcon /> : <KeyboardDoubleArrowLeftIcon />}</IconButton>
            </Grid>
            {catalogoItems.map((item, key) => {
              return (
                <>
                  {key ? <hr></hr> : <br></br>}
                  {hideMenu ?
                    <Tooltip title={item.name} placement="right">
                      <IconButton key={item.id} color={selectedCatalogo?.id === item.id ? "neutral" : "gray"} onClick={() => handleSelectedCatalogo(item)}>{item.icon}</IconButton>
                    </Tooltip>
                    : <Button startIcon={item.icon} key={item.id} color={selectedCatalogo?.id === item.id ? "neutral" : "gray"} onClick={() => handleSelectedCatalogo(item)}>{item.name}</Button>}
                </>
              )
            })}
            <br></br>
            <br></br>
          </Paper>

        </Grid>
        <Grid item sx={{ width: { xs: "100%", sm: `calc(100% - ${hideMenu ? 120 : 290}px)` } }}>
          <EnhancedTable
            table={{
              rows: dataTable,
              columns: selectedCatalogo?.columns ?? []
            }}
            add={setAllowComponent(selectedCatalogo?.id, "agregarRegistroCatalogo")}
            onAddFunction={() => handleOpenModal()}
            buttons={[
              {
                label: "Ver",
                icon: <Visibility fontSize={"small"} />,
                onClick: (id, row) => {
                  console.log(row)
                  handleOpenModal("view", row)
                },
                showButton: setAllowComponent(selectedCatalogo?.id, 'verCatalogo')
              },
              {
                label: "Eliminar",
                icon: <Delete fontSize={"small"} />,
                onClick: (id,row) => triggerDeleteDialog(row),
                showButton: setAllowComponent(selectedCatalogo?.id, 'eliminarRegistroCatalogo')
              }
            ]}
            loading={isTableLoading}
            rowId={selectedCatalogo?.key}
            disablePathParameters
          />
        </Grid>
      </Grid>
    </ThemeProvider>
  );
};
