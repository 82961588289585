import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  dataTable:  {
    columns: [],
    rows   : []
  },
  dataTableBitacora:  {
    columns: [],
    rows   : []
  },
};

export const suspensionesSlice = createSlice({
  name: 'suspensiones',
  initialState,
  reducers: {
    setDataTable: (state, action) => {
      state.dataTable = {...action.payload};
    },
    setBitacoraDataTable: (state, action) => {
      state.dataTableBitacora = {...action.payload};
    },
  }
});

export const {
  setDataTable,
  setBitacoraDataTable,
} = suspensionesSlice.actions;
