import { useEffect, useState } from "react";
import { useMenu } from "../hooks/useMenu";
import { useAuthPage } from "../hooks/useAuthPage";
import {
  Button,
  createTheme,
  Grid,
  IconButton,
  ThemeProvider,
  Tooltip,
} from "@mui/material";
import { Download } from "@mui/icons-material";
import { useModal } from "../hooks/useModal";
import { useSucursalesTable } from "../hooks/useSucursalesTable";
import { ModalSucursal } from "../components/modal/ModalSucursal";
import { ModalImportSucursales } from "../components/modal/ModalImportSucursales";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import VisibilityIcon from "@mui/icons-material/Visibility";
import DeleteIcon from "@mui/icons-material/Delete";
import { SearchInput } from "../components/form/SearchInput";

import { FilterAltOutlined } from "@mui/icons-material";
import { ModalDeleteEntity } from "../components/modal/ModalDeleteEntity";
import { startLoadMotivosBaja } from "../slices/catalogo/catalgoThunk";
import { useDispatch } from "react-redux";
import { nIdEstatusForm } from "../helpers/forms";
import moment from "moment";
import PaginatedTable from "../components/table/PaginatedTable";
const iconTheme = createTheme({
  status: {
    danger: "#e53e3e",
  },
  palette: {
    primary: {
      main: "#00609C",
      dark: "#053e85",
    },
    neutral: {
      main: "#00CD68",
      light: "#053e85",
      dark: "#053e85",
      contrastText: "#fff",
    },
  },
});

export const VerSucursalesPage = () => {
  const { setNameSection } = useMenu();
  const { setAllowComponent } = useAuthPage();
  const { isTableLoading } = useModal();

  const dispatch = useDispatch();

  const colorsTab = ["#E7B10A", "#00CD68", "#e53e3e", "#053e85", "#6200EE"];
  const {
    loadInInit,
    onClickAdd,
    showSucursalModal,
    form,
    onChangeInput,
    handleCloseModal,
    handleSubmit,
    openSucursalModal,
    enableEditModal,
    disableEditModal,
    handleSubmitChangeEstatus,
    handleOpenImportSucursales,
    handleCloseImportSucursales,
    showImportSucursales,
    loadCadenas,
    selectedCadena,
    setSelectedCadena,
    cadenas,
    motivosBaja,
    handleDownload,
    formatDate,
    getSucursalesPaginated,
    sucursales,
  } = useSucursalesTable();

  const nextNotificationDate = moment().add(1, "M").startOf("month");

  const [showConfirmDialog, setShowConfirmDialog] = useState(false);
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const [selectedSucursal, setSelectedSucursal] = useState({});

  const triggerDeleteDialog = async (sucursal) => {
    if (sucursal) {
      setSelectedSucursal({
        ...sucursal,
        sNombreComercial: sucursal.sNombre,
      });

      if (!motivosBaja.length) await dispatch(startLoadMotivosBaja());
      setShowDeleteDialog(true);
    }
  };

  const handleConfirmDialog = async (innerForm) => {
    await handleSubmitChangeEstatus(innerForm, true);
    setShowConfirmDialog(false);
  };

  useEffect(() => {
    setNameSection("sucursales");
  }, [setNameSection]);

  useEffect(() => {
    loadInInit();
  }, []);

  return (
    <ThemeProvider theme={iconTheme}>
      <ModalDeleteEntity
        entidad={selectedSucursal}
        showDeleteDialog={showDeleteDialog}
        setShowDeleteDialog={setShowDeleteDialog}
        motivosBaja={motivosBaja}
        deleteFunction={handleConfirmDialog}
        rowId={"nIdSucursal"}
      />
      <ModalImportSucursales
        isOpenImport={showImportSucursales}
        closeImport={handleCloseImportSucursales}
        openImport={handleOpenImportSucursales}
      />
      <ModalSucursal
        isOpenModal={showSucursalModal.open}
        modalType={showSucursalModal.type}
        form={form}
        onChange={onChangeInput}
        handleSubmit={handleSubmit}
        handleCloseModal={handleCloseModal}
        enableEditModal={enableEditModal}
        disableEditModal={disableEditModal}
        handleSubmitChangeEstatus={handleSubmitChangeEstatus}
        handleOpenImport={handleOpenImportSucursales}
      />
      <PaginatedTable
        table={{
          ...sucursales,
          columns: [
            ...(sucursales?.columns ?? []),
            {
              field: "sEstatus",
              headerName: "Estatus",
              type: "render",
              flex: 1,
              renderFunction: (row) => (
                <p
                  style={{
                    display: "inline-block",
                    color: colorsTab[row.nIdSuspension ? 4 : row.nIdEstatus],
                    background: `${
                      colorsTab[row.nIdSuspension ? 4 : row.nIdEstatus]
                    }15`,
                    padding: "4px 8px",
                    borderRadius: "4px",
                    fontWeight: 500,
                    margin: 0,
                    fontSize: 13,
                  }}
                >
                  {row.nIdSuspension
                    ? `Suspendido - ID: ${row.nIdSuspension}`
                    : row.sEstatus}
                </p>
              ),
            },
            {
              field: "dFecNotificacion",
              headerName: "Notificación",
              type: "render",
              flex: 1,
              disableTooltip: true,
              renderFunction: (row) => (
                <Tooltip
                  title={
                    row.dFecNotificacion === null ? (
                      `Notificación en: ${nextNotificationDate.format(
                        "YYYY-MM-DD"
                      )}`
                    ) : (
                      <span>
                        {formatDate(row.dFecNotificacion).format(
                          "YYYY-MM-DD HH:mm:ss"
                        )}
                        <br></br>{" "}
                        {row.nTipoNotificacion === 1
                          ? "Alta"
                          : row.nTipoNotificacion === 2
                          ? "Baja"
                          : "Actualización de datos"}{" "}
                        notificada a {row.sNombreEmisorNotificacion}
                      </span>
                    )
                  }
                >
                  <p>
                    {row.dFecNotificacion === null ? (
                      <>
                        Próxima notificación <br></br>
                        {nextNotificationDate.locale("es").fromNow()}
                      </>
                    ) : (
                      <>
                        Última notificación <br></br>
                        {formatDate(row.dFecNotificacion)
                          .locale("es")
                          .fromNow()}
                      </>
                    )}
                  </p>
                </Tooltip>
              ),
            },
            {
              field: "dFecBaja",
              headerName: "Fecha de baja",
              type: "render",
              flex: 1,
              renderFunction: (row) =>
                row.dFecBaja
                  ? formatDate(row.dFecBaja).format("YYYY-MM-DD")
                  : "",
            },
          ],
        }}
        add={setAllowComponent("altaSucursales")}
        onAddFunction={onClickAdd}
        getDataFn={getSucursalesPaginated}
        extraRowButtons={(id, row) => {
          return (
            <>
              {setAllowComponent("verSucursales") && (
                <Tooltip title="Ver" placement="top">
                  <IconButton
                    size="small"
                    onClick={() => openSucursalModal("view", row)}
                  >
                    <VisibilityIcon fontSize={"small"} />
                  </IconButton>
                </Tooltip>
              )}
              {setAllowComponent("bajaSucursales") && (
                <Tooltip title="Eliminar" placement="top">
                  <IconButton
                    size="small"
                    onClick={() => triggerDeleteDialog(row)}
                    disabled={[
                      nIdEstatusForm.baja,
                      nIdEstatusForm.pendiente,
                    ].includes(row.nIdEstatus)}
                  >
                    <DeleteIcon fontSize={"small"} />
                  </IconButton>
                </Tooltip>
              )}
            </>
          );
        }}
        loading={isTableLoading}
        rowId={"nIdSucursal"}
        getFilters={loadCadenas}
        extraButtons={
          <>
            <Button
              variant="contained"
              onClick={handleDownload}
              startIcon={<Download />}
            >
              Descargar sucursales
            </Button>
            {(setAllowComponent("importarBajaSucursales") ||
              setAllowComponent("importarAltaSucursales")) && (
              <>
                <Button
                  variant="contained"
                  onClick={handleOpenImportSucursales}
                  startIcon={<UploadFileIcon />}
                  sx={{ ml: 2 }}
                >
                  Cargar archivo
                </Button>
              </>
            )}
          </>
        }
        extraFilters={(resetPagination) => (
          <Grid
            container
            justifyContent={"space-between"}
            alignItems={"center"}
            sx={{ padding: 2 }}
          >
            <Grid item sm={1} xs={12}>
              Filtros:
            </Grid>
            <Grid item sm={9} xs={12}>
              <Grid container spacing={2} alignItems={"center"}>
                <Grid item xs={12} sm={4}>
                  <SearchInput
                    options={[
                      { nIdCadena: 0, sNombreComercial: "Todos" },
                      ...cadenas?.rows,
                    ]}
                    value={selectedCadena}
                    name={"nIdCadena"}
                    label={"Comisionista"}
                    getOptionLabel={"sNombreComercial"}
                    getIndexLabel={"nIdCadena"}
                    onChange={(e) => setSelectedCadena(e.target.value)}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item sm={2} xs={12} style={{ textAlign: "right" }}>
              <Button
                variant="contained"
                startIcon={<FilterAltOutlined />}
                onClick={() => {
                  resetPagination();
                  getSucursalesPaginated();
                }}
              >
                Filtrar
              </Button>
            </Grid>
          </Grid>
        )}
      />
    </ThemeProvider>
  );
};
