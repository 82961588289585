import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  passwordError: false,
  userError: false,
  helperText: '',
  error: false,
  errorMessage: ''
};

export const loginErrorSlice = createSlice({
  name: 'login',
  initialState,
  reducers: {
    setPasswordError: (state, action) => {
      state.passwordError = action.payload;
    },
    setUserError: (state, action) => {
      state.userError = action.payload;
    },
    setError: (state, action) => {
      state.error = action.payload;
    },
    setHelperText: (state, action) => {
      state.helperText = action.payload;
    },
    setErrorMessage: (state, action) => {
      state.errorMessage = action.payload;
    }
  }
});

export const {
  setPasswordError,
  setUserError,
  setError,
  setHelperText,
  setErrorMessage
} = loginErrorSlice.actions;
