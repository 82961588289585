import { TextField } from '@mui/material';
import { useEffect, useState } from 'react';
import { regexStrageChart } from '../../helpers/regexs';

const initalValues = { error: false, msg: '' };

const getNormizeWord = ( word = '' ) => {
  const normilize = word.normalize('NFD').replace(/[\u0300-\u036f]/g, "")
  return normilize.replace(regexStrageChart, '');
}

const minValue = ( type ) => {
  return type === 'number' ? { min: 0 } : {}
}

export const TextInput = ({
  caracteres = '',
  disabled = false,
  erroMsg = '',
  error = false,
  id,
  name,
  label,
  onChangeRoot,
  size = 'standard',
  style = {},
  type = 'text',
  value= '',
  variant = 'outlined',
  fullWidth = true,
  require = false,
  expresionRegular = '',
  startAdornment = false,
  endAdornment = false,
  normlizeWord = false,
  InputProps = {},
  required = false,
  autoComplete = "off",
  multiline = false,
  minRows = 1
}) => {
  const [innerError, setError] = useState(initalValues);

  useEffect(() => {
    setError({ error: error, msg: erroMsg })
  }, [error, erroMsg])
  

  const onChange = ( e ) => {
    const inputValue = e.target.value;
    const inputName = e.target.name;

    if ( caracteres !== '' && inputValue.length > parseInt(caracteres)) return;

    onChangeRoot({
      [inputName]: !normlizeWord ? inputValue : getNormizeWord(inputValue)
    });
  }

  const validateInput = ( inputValue ) => {

    if ( require && inputValue === '' ) {
      setError({ ...innerError, msg: 'Requerido', error: true });
      return;
    }

    if (
      expresionRegular !== '' &&
      inputValue !== '' &&
      !expresionRegular.test(inputValue)
    ) {
      setError({ ...innerError, msg: erroMsg, error: true });
      return;
    }

    setError(initalValues);
  }

  return (
    <TextField
      id={id}
      name={name}
      label={label}
      variant={variant}
      size={size}
      value={value === "" && disabled ? "---" : value}
      style={style}
      type={type}
      onChange={onChange}
      fullWidth={fullWidth}
      autoComplete={autoComplete}
      disabled={disabled}
      error={innerError.error && !disabled}
      multiline={multiline}
      helperText={innerError.error && !disabled ? innerError.msg : ''}
      onKeyUp={(e) => validateInput(value)}
      onBlur={(e) => validateInput(value)}
      required={required}
      minRows={minRows}
      InputProps={{
        startAdornment,
        endAdornment,
        inputProps: { ...minValue(type) },
        ...InputProps
      }}
    />
  );
};
