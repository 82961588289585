import { Button, Grid, IconButton } from "@mui/material";
import { useSelector } from "react-redux";
import { DashboardTable } from "./table/DashboardTable";
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Add, Delete, Edit, NightShelter } from "@mui/icons-material";
import { useState } from "react";
import { TextInput } from "./form/TextInput";
import { SelectInput } from "./form/SelectInput";
import { BlueSwitch } from "../helpers/stylesMaterial";
import { useModal } from "../hooks/useModal";

const StyledTableCell = styled(TableCell)(({ theme }) => ({

    paddingTop: 12,
    paddingBottom: 12,
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: theme.palette.primary.dark,
        color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 13,
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));

const initialForm = {
    nIdCampo: 0,
    nPosicionInicial: 0,
    nPosicionFinal: 0,
    sValorComparar: "",
    sFormatoFecha: "",
    bFiltrar: false
}

export const CamposConciliacionForm = (props) => {
    const {
        campos,
        onChange,
        nTipo,
        bRango,
        isEdit,
        openModalFecha
    } = props;

    const [form, setForm] = useState(initialForm)
    const [selectedCampo, setSelectedCampo] = useState({})
    const { setOpenToast } = useModal();

    const {
        camposConciliacion
    } = useSelector((state) => state.catalogo);

    const onChangeForm = (innerForm) => {
        setForm({
            ...form,
            ...innerForm
        })
    }

    const getCamposConciliacion = () => {
        const onlyIdsCampos = campos.map(campo => campo.nIdCampo)
        return camposConciliacion
            .filter(campo => campo.nTipoCampo === nTipo)
            .map(campo => {
                const exists = onlyIdsCampos.includes(campo.nIdCampo)
                return {
                    ...campo,
                    editing: exists,
                    text: `${campo.sNombreCampo} ${!exists ? "" : "(Editar)"}`
                }
            })
    }

    const handleSelectedCampo = (nIdCampo) => {
        const c = getCamposConciliacion().find(campo => campo.nIdCampo === nIdCampo)
        setSelectedCampo(c)
        console.log(c)
        if (c.editing) {
            const campoFinded = campos.find(campo => campo.nIdCampo === c.nIdCampo)
            onChangeForm(campoFinded)
        } else {
            onChangeForm({ ...initialForm, nIdCampo })
        }
    }

    const handleSubmit = () => {
        const innerForm = {
            ...form,
            ...selectedCampo,
            nPosicionFinal: bRango ? form.nPosicionFinal : form.nPosicionInicial
        }

        if (form.nPosicionInicial === "") {
            setOpenToast(true, "Ingrese la posición inicial")
            return
        }

        if (!bRango) {

            const nPosicionIsAlreadyTaken = campos.find(campo => (parseInt(campo.nPosicionInicial) === parseInt(innerForm.nPosicionInicial) && campo.nIdCampo !== innerForm.nIdCampo) && campo.nTipoCampo === nTipo)
            console.log(innerForm)
            if (nPosicionIsAlreadyTaken) {
                setOpenToast(true, "Ya existe un campo con la misma posición")
                return
            }
        } else {
            if (form.nPosicionFinal === "") {
                setOpenToast(true, "Ingrese la posición final")
                return
            }
            if (form.nPosicionInicial > form.nPosicionFinal) {
                setOpenToast(true, "La posición final debe ser mayor a la inicial")
                return
            }
        }

        let actualCampos = campos
        const index = actualCampos.findIndex(campo => campo.nIdCampo === innerForm.nIdCampo)

        if (index !== -1) {
            actualCampos[index] = { ...actualCampos[index], ...innerForm }
        } else {
            actualCampos.push(innerForm)
        }
        onChange(actualCampos)
        setForm(initialForm)
        setSelectedCampo({})
    }

    const handleDelete = (nIdCampo) => {
        const actualCampos = campos.filter(campo => campo.nIdCampo !== nIdCampo)
        onChange(actualCampos)
    }
    return (
        <Grid container spacing={2} mt={1}>
            {isEdit ?
                <Grid item xs={12} container spacing={2}>
                    <Grid item xs={12} sm={4}>
                        <SelectInput
                            id="nIdCampo"
                            name="nIdCampo"
                            onChange={(e) => handleSelectedCampo(e.target.value)}
                            label="Campo"
                            data={getCamposConciliacion()}
                            value={form.nIdCampo}
                            disabled={!isEdit}
                            text="text"
                            idOption={"nIdCampo"}
                        />
                    </Grid>
                    <Grid item xs={12} sm={bRango ? 2 : 4}>
                        <TextInput
                            disabled={!isEdit}
                            id="nPosicionInicial"
                            name="nPosicionInicial"
                            label={bRango ? "Posicion Inicial" : "Posición"}
                            value={`${form.nPosicionInicial}`}
                            onChangeRoot={onChangeForm}
                            fullWidth
                            require
                        />
                    </Grid>
                    {bRango ? <Grid item xs={12} sm={2}>
                        <TextInput
                            disabled={!isEdit}
                            id="nPosicionFinal"
                            name="nPosicionFinal"
                            label="Posición final"
                            value={`${form.nPosicionFinal}`}
                            onChangeRoot={onChangeForm}
                            fullWidth
                            require
                        />
                    </Grid> : <></>}
                    {selectedCampo.bFecha ?
                        <Grid item xs={12} sm={4}>
                            <TextInput
                                disabled={!isEdit}
                                id="sFormatoFecha"
                                name="sFormatoFecha"
                                label="Formato fecha"
                                value={`${form.sFormatoFecha}`}
                                onChangeRoot={onChangeForm}
                                fullWidth
                            />
                        </Grid>
                        : <>
                            <Grid item xs={12} sm={4}>
                                <TextInput
                                    disabled={!isEdit}
                                    id="sValorComparar"
                                    name="sValorComparar"
                                    label="Valor a comparar"
                                    value={`${form.sValorComparar}`}
                                    onChangeRoot={onChangeForm}
                                    fullWidth
                                />
                            </Grid>
                        </>}
                    <Grid item xs={12} container justifyContent={"space-between"} alignItems={"center"}>

                        {selectedCampo.bFecha ?
                            <Grid item xs={12} sm={6}>
                                <Button color="primary" onClick={openModalFecha}>VER FORMATOS DE FECHA</Button>
                            </Grid>
                            :
                            (nTipo === 1 ? <Grid item xs={12} sm={6}>
                                <div className="cadenas__blue-switch" style={{ marginTop: "0" }}>
                                    <BlueSwitch
                                        onChange={(e) => onChangeForm({ bFiltrar: e.target.checked ? 1 : 0 })}
                                        inputProps={{ 'aria-label': 'controlled' }}
                                        name="bFiltrar"
                                        checked={form.bFiltrar}
                                        value={form.bFiltrar}
                                        disabled={!isEdit}
                                    />
                                    <p>Filtrar valor a comparar</p>
                                </div>

                            </Grid> : <Grid item xs={12} sm={6}></Grid>)

                        }
                        <Grid item xs={12} sm={6} container justifyContent={"flex-end"} spacing={1}>
                            {selectedCampo.editing ? <Button onClick={() => {
                                setForm(initialForm)
                                setSelectedCampo({})
                            }} >Cancelar</Button> : <></>}
                            <Button variant="contained" color="primary" onClick={() => handleSubmit()} startIcon={selectedCampo.editing ? <Edit /> : <Add />}>{selectedCampo.editing ? "Editar" : "Agregar"}</Button>
                        </Grid>
                    </Grid>
                </Grid>
                : <></>}

            <Grid item xs={12}>
                <TableContainer component={Paper} sx={{ maxHeight: 440 }}>
                    <Table sx={{ minWidth: 700 }} stickyHeader size="small" aria-label="customized table">
                        <TableHead>
                            <TableRow>
                                <StyledTableCell>Campo</StyledTableCell>
                                <StyledTableCell>Campo clase</StyledTableCell>
                                {bRango ? <>
                                    <StyledTableCell align="center">Posición inicial</StyledTableCell>
                                    <StyledTableCell align="center">Posición final</StyledTableCell>
                                </> : <>
                                    <StyledTableCell align="center">Posición</StyledTableCell>
                                </>}
                                <StyledTableCell>Valor a comparar</StyledTableCell>
                                <StyledTableCell>Formato fecha</StyledTableCell>
                                <StyledTableCell align="center">Filtrar</StyledTableCell>
                                {isEdit ?
                                    <StyledTableCell align="center"></StyledTableCell> : <></>}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {campos?.filter(campo => campo.nTipoCampo === nTipo).sort((a, b) => a.nPosicionInicial - b.nPosicionInicial).map((row) => (
                                <StyledTableRow key={row.sCampoClase}>
                                    <StyledTableCell component="th" scope="row">
                                        {row.sNombreCampo}
                                    </StyledTableCell>
                                    <StyledTableCell>{row.sCampoClase}</StyledTableCell>
                                    {bRango ? <>
                                        <StyledTableCell align="center">{row.nPosicionInicial}</StyledTableCell>
                                        <StyledTableCell align="center">{row.nPosicionFinal}</StyledTableCell>
                                    </> : <>
                                        <StyledTableCell align="center">{row.nPosicionInicial}</StyledTableCell>
                                    </>}
                                    <StyledTableCell>{row.sValorComparar}</StyledTableCell>
                                    <StyledTableCell>{row.sFormatoFecha}</StyledTableCell>
                                    <StyledTableCell align="center">{row.bFiltrar ? "Sí" : ""}</StyledTableCell>
                                    {isEdit ?
                                        <StyledTableCell align="right">
                                            <Grid container spacing={1} justifyContent={"flex-end"}>
                                                <IconButton size="small" aria-label="delete">
                                                    <Edit onClick={() => handleSelectedCampo(row.nIdCampo)} disabled={selectedCampo.editing} />
                                                </IconButton>
                                                <IconButton size="small" aria-label="delete">
                                                    <Delete onClick={() => handleDelete(row.nIdCampo)} disabled={selectedCampo.editing} />
                                                </IconButton>
                                            </Grid>
                                        </StyledTableCell> : <></>}

                                </StyledTableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>

            </Grid>
        </Grid>
    );
}