import { useEffect } from 'react';
import { useMenu } from '../hooks/useMenu';
import { useAuthPage } from '../hooks/useAuthPage';
import { ReportesPage } from '../pages/ReportesPage';
import { NotAllowPage } from '../pages/NotAllowPage';
import {
  useParams
} from "react-router-dom";
import { OrdenesPagoPage } from '../pages/OrdenesPagoPage';
import { ComisionesIntegradorPage } from '../pages/ComisionesIntegradorPage';
import { ReporteOperacionesPage } from '../pages/ReporteOperacionesPage';
import { ReporteTransaccionesPage } from '../pages/ReporteTransaccionesPage';

export const ReportesModule = () => {
  const { type } = useParams();
  const { setNameSection } = useMenu();
  const { setNewPage, isAllowed } = useAuthPage();

  useEffect(() => {
    switch (type) {
      case 'operaciones':
        setNewPage('reporteOperaciones');
        setNameSection('reporteOperaciones');
        break;
      case 'transacciones':
        setNewPage('reporteTransacciones');
        setNameSection('reporteTransacciones');
        break;
      case 'ordenes-pago':
        setNewPage('reporteOrdenesPago');
        setNameSection('reporteOrdenesPago');
        break;
      case 'escalonado':
        setNewPage('reporteEscalonado');
        setNameSection('reporteEscalonado');
        break;
      case '24-horas':
        setNewPage('reporte24Horas');
        setNameSection('reporte24Horas');
        break;
      case 'pagos-cobros':
        setNewPage('reportePagosCobro');
        setNameSection('reportePagosCobro');
        break;
      case 'estado-cuenta':
        setNewPage('reporteEstadoCuenta');
        setNameSection('reporteEstadoCuenta');
        break;
      case 'comisiones-integrador':
        setNewPage('reporteComisionesIntegrador');
        setNameSection('reporteComisionesIntegrador');
        break;
      case 'geolocalizacion':
        setNewPage('reporteGeolocalizacion');
        setNameSection('reporteGeolocalizacion');
        break;
      case 'sucursales-no-registradas':
        setNewPage('reporteSucursalesNoRegistradas');
        setNameSection('reporteSucursalesNoRegistradas');
        break;
      case 'cortes':
        setNewPage('reporteCortes');
        setNameSection('reporteCortes');
          break;
      default:
        setNewPage('');
        setNameSection('');
        break;
    }
  }, [type]);


  if (isAllowed) {
    switch (type) {
      case 'operaciones':
        return <ReportesPage />;
      case 'transacciones':
        return <ReporteTransaccionesPage />;
      case 'ordenes-pago':
        return <OrdenesPagoPage />;
      case 'escalonado':
        return <ReportesPage />;
      case '24-horas':
        return <ReportesPage />;
      case 'cortes':
          return <ReportesPage />;
      case 'pagos-cobros':
        return <ReportesPage />;
      case 'estado-cuenta':
        return <ReportesPage />;
      case 'comisiones-integrador':
        return <ComisionesIntegradorPage />;
      case 'geolocalizacion':
        return <ReportesPage />;
      case 'sucursales-no-registradas':
        return <ReportesPage />;
      default:
        return <NotAllowPage />;
    }
  } else {
    return <NotAllowPage />;
  }
};
