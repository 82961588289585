import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import moment from "moment";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { Box } from "@mui/system";
import { ModalBase } from "./ModalBase";
import { SearchInput } from "../form/SearchInput";
import { Button, Checkbox, Grid, IconButton, Skeleton, TextField, Tooltip, Typography } from "@mui/material";
import { useModal } from "../../hooks/useModal";
import { Add, Cancel, Done, Download, Edit, FilterAlt, HistoryOutlined, HourglassBottom, Percent, PictureAsPdf, Save, Schedule, Summarize, Visibility } from "@mui/icons-material";
import { useModalComisionesEscalonadas } from "../../hooks/useModalComisionesEscalonadas";
import EnhancedTable from "../table/EnhancedTable";
import ButtonDropdown from "../ButtonDropdown";
import { EscCantidadForms } from "../escalonadoCantidad/EscCantidadForms";
import CustomizedTabs from "../CustomizedTabs";
import { EscalonadoModalForm } from "./modalDetail/EscalonadoModalForm";
import { View } from "@aws-amplify/ui-react";
import { useAuthPage } from "../../hooks/useAuthPage";
import { ConfirmDialog } from "./ConfirmDialog";
import { EscCantidadEnhancedForms } from "../escalonadoCantidad/EscCantidadEnhancedForms";

export const ModalComisionesEscalonadas = () => {
  const {
    modalLoading,
    isOpenComisionesEscalonadas,
    closeModalComisionesEscalonadas,
    screen,
    openScreen,
    goBack,
    comisionesEscalonadas,
    bitacora = {},
    vencidas = {},
    onChange,
    bitacoraForm,
    filterBitacora,
    exportBitacoraData,
    handleSelectedTab,
    selectedTab,
    proxima,
    detalle,
    openDetails,
    enableEdit,
    handleEdit,
    handleSubmit,
    validateEditVigente,
    closeConfirmDialog,
    confirmDialog,
    onChangeProxima,
    validateProgramacion,
    rescheduleProgramacion,
    escCantidadForms,
    validateChangeEstatusProgramacion,
  } = useModalComisionesEscalonadas();
  const { setAllowComponent } = useAuthPage();

  const showBodyModal = () => {
    switch (screen) {
      case "bitacora":
        return (<Grid container item sm={12}>
          <EnhancedTable
            isModalTable
            modalFilters={<Grid container spacing={1}>
              <Grid item xs={3}>
                <DesktopDatePicker
                  label="Fecha inicial"
                  inputFormat="YYYY-MM-DD"
                  renderInput={(params) => <TextField {...params} fullWidth size={"small"} />}
                  disableFuture
                  value={bitacoraForm.dFecInicial}
                  onChange={(e) => onChange(null, 'dFecInicial', moment(e['$d']).format('YYYY-MM-DD'))}
                />
              </Grid>
              <Grid item xs={3}>
                <DesktopDatePicker
                  label="Fecha final"
                  inputFormat="YYYY-MM-DD"
                  renderInput={(params) => <TextField {...params} fullWidth size={"small"} />}
                  disableFuture
                  value={bitacoraForm.dFecFinal}
                  onChange={(e) => onChange(null, 'dFecFinal', moment(e['$d']).format('YYYY-MM-DD'))}
                />
              </Grid>
              <Grid item xs={2}>

                <Button variant="contained" startIcon={<FilterAlt />} onClick={filterBitacora}>
                  Filtrar
                </Button>
              </Grid>
            </Grid>}
            table={bitacora}
            disableButtons
            rowId={"nIdBitacora"}
            disablePathParameters
          />
        </Grid>)
      case "vencidos":
        return (<Grid container item sm={12}>
          <EnhancedTable
            isModalTable
            icon={<HourglassBottom fontSize={"large"} />}
            subtitle={"Programaciones vencidas"}
            title={isOpenComisionesEscalonadas.sNombreComercial}
            table={vencidas}
            buttons={[
              {
                label: "Ver",
                icon: <Visibility fontSize={"small"} />,
                onClick: (id, row) => openDetails(row),
                showButton: true
              }
            ]}
            rowId={"nIdEmisorComEscProg"}
            disablePathParameters
          />
        </Grid>)
      case "detalle":
        return (<Grid container item sm={12} padding={2}>
          <EscalonadoModalForm
            form={detalle}
            onlyView={true}
            hasProxima={!!proxima.nIdEmisorComEscProg}
            showButtons={
              <>
                {parseInt(detalle.nEstatusAutorizacion) > 1 && detalle.nIdEmisorComEscProg && setAllowComponent("agregarProgramacionEscalonada") && !proxima.nIdEmisorComEscProg && <>
                  <Button variant="contained" startIcon={<Schedule />} onClick={rescheduleProgramacion}>
                    Reprogramar
                  </Button>
                </>}
              </>
            }
          />
        </Grid>)
      default:
        return (<Grid container >
          <Grid item xs={12} container justifyContent={"space-between"} paddingLeft={1}>
            <Grid item xs={8}>
              <CustomizedTabs
                tabs={[
                  { label: "Comisiones vigentes", value: "vigentes" },
                  ...(proxima.nIdEmisorComEscProg && !proxima.isReprogramada ?
                    [{ label: proxima.sNombre, value: "programacion", hasNotification: !parseInt(proxima.nEstatusAutorizacion) && setAllowComponent("editarProgramacionEscalonada") }]
                    : (setAllowComponent("agregarProgramacionEscalonada") ? [{ label: "Agregar programación", value: "programacion", icon: <Add /> }] : []))

                ]}
                value={selectedTab}
                onChange={handleSelectedTab}
              />
            </Grid>
            <Grid item xs={4} container justifyContent={"flex-end"}>
              <Button onClick={() => openScreen("vencidos")}>
                Programaciones vencidas
              </Button>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Grid container padding={2} sx={{ background: "#f9f9f9", borderRadius: 2 }}>
              {
                selectedTab === "vigentes" ?
                  <>
                    <Grid container>
                      <Grid item xs={12} container justifyContent={"space-between"} mb={2}>
                        <Typography variant="h6" color={"primary"}>Escalones</Typography>
                        {!modalLoading && setAllowComponent("editarEscalonadaVigente") && !enableEdit.vigentes &&
                          <Button variant="contained" startIcon={<Edit />} onClick={() => handleEdit(true, "vigentes")}>
                            Editar
                          </Button>}
                        {!modalLoading && setAllowComponent("editarEscalonadaVigente") && enableEdit.vigentes &&
                          <div>
                            <Button onClick={() => handleEdit(false, "vigentes")}>
                              Cancelar
                            </Button>
                            <Button variant="contained" startIcon={<Save />} onClick={validateEditVigente}>
                              Guardar
                            </Button>
                          </div>}
                      </Grid>
                      <Grid item xs={12}>
                        {modalLoading ?
                          <>
                            <Skeleton variant="rounded" width={"100%"} height={50} sx={{ mb: 2 }} />
                            <Skeleton variant="rounded" width={"100%"} height={50} sx={{ mb: 2 }} />
                            <Skeleton variant="rounded" width={"100%"} height={50} sx={{ mb: 2 }} />
                          </> :
                          <EscCantidadEnhancedForms isComisionesVigentes={true} onlyView={!enableEdit.vigentes} />}
                      </Grid>
                    </Grid>
                  </>
                  :
                  <>
                    <EscalonadoModalForm
                      form={proxima}
                      onlyView={proxima.nIdEmisorComEscProg && !enableEdit.programacion}
                      onChange={onChangeProxima}
                      has24Horas={isOpenComisionesEscalonadas.has24Horas}
                      showButtons={
                        <>
                          {parseInt(proxima.nEstatusAutorizacion) === 0 && proxima.nIdEmisorComEscProg && !enableEdit.programacion && <>
                            {setAllowComponent("editarProgramacionEscalonada") && <Button variant="contained" startIcon={<Edit />} onClick={() => handleEdit(true, "programacion")}>
                              Editar
                            </Button>}
                            {setAllowComponent("cambiarEstatusProgramacionEscalonada") && <>
                              <Button variant="contained" startIcon={<Done />} sx={{ ml: 1 }} onClick={()=>validateChangeEstatusProgramacion(true)}>
                                Autorizar
                              </Button>
                              <Button variant="contained" startIcon={<Cancel />} sx={{ ml: 1 }} onClick={()=>validateChangeEstatusProgramacion(false)}>
                                Rechazar
                              </Button>
                            </>}
                          </>}
                          {
                            enableEdit.programacion &&
                            <Button onClick={() => handleEdit(false, "programacion")}>
                              Cancelar
                            </Button>
                          }
                          {(!proxima.nIdEmisorComEscProg || enableEdit.programacion) && setAllowComponent("agregarProgramacionEscalonada") && <>
                            <Button variant="contained" startIcon={<Save />} onClick={()=>validateProgramacion(enableEdit.programacion)} disabled={escCantidadForms.find(esc => esc.isEditing)}>
                              Guardar
                            </Button>
                          </>}
                        </>
                      }
                      handleEdit={(isEditing) => handleEdit(isEditing, "programacion")}
                    />
                  </>}
            </Grid>
          </Grid>
        </Grid>);
    }
  }

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={"es-mx"}>
      <ConfirmDialog

        isOpenDialog={confirmDialog.open}
        onCloseDialog={closeConfirmDialog}
        onConfimDialog={handleSubmit}
        message={confirmDialog.message}
        textButtonConfirm="Sí, si estoy seguro/a."
      />
      <ModalBase open={isOpenComisionesEscalonadas.open} style={{ width: "100%", maxWidth: !["inicio", "detalle"].includes(screen) ? 1000 : 800 }}>
        <Box
          sx={{
            width: "100%",
            height: "100%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
          }}
        >
          <ModalTitle
            type={screen}
            sNombreComercial={isOpenComisionesEscalonadas.sNombreComercial}
            buttons={<>
              {screen == 'inicio' &&
                <Button onClick={() => openScreen("bitacora")} variant="contained" startIcon={<HistoryOutlined />}>
                  Bitácora
                </Button>
              }
              {screen == 'bitacora' &&
                <ButtonDropdown
                  startIcon={<Download />}
                  label="Exportar"
                  options={[
                    { startIcon: <PictureAsPdf />, label: "PDF", action: () => exportBitacoraData("pdf") },
                    { startIcon: <Summarize />, label: "Excel", action: () => exportBitacoraData("excel") },
                  ]}
                />
              }
            </>}
          />
          <Grid container>
            {showBodyModal()}
          </Grid>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              width: "100%",
              justifyContent: "end",
              marginTop: 20
            }}
          >
            <Button onClick={screen != 'inicio' ? goBack : closeModalComisionesEscalonadas} sx={{ color: "#00609C" }}>
              {screen != 'inicio' ? "Atrás" : "Cerrar"}
            </Button>
          </div>

        </Box>
      </ModalBase>

    </LocalizationProvider>
  );
};

const ModalTitle = ({ type = "inicio", sNombreComercial, buttons }) => {

  const titleValues = {
    inicio: {
      title: "Comisiones Escalonadas",
      icon: <Percent fontSize={"large"} />,
    },
    bitacora: {
      title: "Bitácora comisiones escalonadas",
      icon: <HistoryOutlined fontSize={"large"} />,
    },
    detalle: {
      title: "Programaciones vencidas",
      icon: <HourglassBottom fontSize={"large"} />,
    },
  }

  return (Object.keys(titleValues).includes(type) ? <Grid container alignItems={"center"} justifyContent={"space-between"} sx={{ mb: 2 }}>
    <Grid container item sm={8} alignItems={"center"}>
      <Grid
        item
        style={{
          color: "#00CD68",
          padding: 15,
          fontWeight: "bold",
        }}
      >
        {titleValues[type].icon}
      </Grid>
      <Grid item>
        <Typography variant="p" color={"neutral"}>
          {titleValues[type].title}
        </Typography>
        <Typography variant="h5" color={"primary"}>
          {sNombreComercial}
        </Typography>
      </Grid>
    </Grid>
    <Grid item>
      {buttons}
    </Grid>

  </Grid> : <></>)
}
