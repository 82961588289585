import { API } from "aws-amplify";
import { loading, tableLoading } from "../ui/uiSlice";
import { setDataTable, setFacturasBadge, setFacturasCadenas, setOrdenesPagoCadenas } from "./facturacionSlice";

export const getFacturas = (form) => {
  return async (dispatch) => {
    const params = {
      headers: {},
      response: false,
      queryStringParameters: form,
    };

    try {
      dispatch(tableLoading(true));
      const resp = await API.get("facturacion", "/facturacion/listado", params);
      console.log({
        ...resp.data,
        details: form,
      });
      const dataTable = {
        ...resp.data,
        rows: resp.data.rows.map(row => {
          return ({
            ...row,
            disableCheckbox: row.nIdEstatusFacturacion === 2
          })
        })
      }
        dispatch(
          setDataTable({
            ...dataTable,
            details: form,
          })
        );
    } catch (error) {
      console.log("ocurrio un error " + error);
    } finally {
      dispatch(tableLoading(false));
    }
  };
};

export const getFacturasValidas = (form) => {
  return async (dispatch) => {
    const params = {
      headers: {},
      response: false,
      queryStringParameters: form,
    };
    let dataTable = {
      columns: [],
      rows: []
    }
    try {
      dispatch(tableLoading(true));
      const resp = await API.get("facturacion", "/facturacion/facturas-validas", params);
      console.log({
        ...resp.data,
        details: form,
      });
      dataTable = {
        ...resp.data,
        rows: resp.data.rows.map(row => {
          return ({
            ...row
          })
        })
      }
        dispatch(
          setDataTable({
            ...dataTable,
            details: form,
          })
        );
    } catch (error) {
      console.log("ocurrio un error " + error);
    } finally {
      dispatch(tableLoading(false));
      return (dataTable)
    }
  };
};

export const getFacturasOrdenes = () => {
  return async (dispatch) => {
    const params = {
      headers: {},
      response: false,
      queryStringParameters: {
        nIdEstatus: 1
      },
    };
    try {
      dispatch(loading(true));
      const resp = await API.get("facturacion", "/facturacion/ordenes-pago-facturas", params);
      const facturasValidasCadena = JSON.parse(resp.data.facturasValidasCadena)
      const ordenesPagoCadena = JSON.parse(resp.data.ordenesPagoCadena) 

      dispatch(setFacturasCadenas(facturasValidasCadena ? facturasValidasCadena : []))
      dispatch(setFacturasBadge(facturasValidasCadena ? facturasValidasCadena.length : 0))
      dispatch(setOrdenesPagoCadenas(ordenesPagoCadena ? ordenesPagoCadena : []))
    } catch (error) {
      console.log("ocurrio un error " + error);
    } finally {
      dispatch(loading(false));
    }
  };
};

export const postFacturas = (sFacturasArray) => {
  return async (dispatch) => {
    const params = {
      headers: {},
      response: false,
      body: {
        sFacturasArray
      },
    };

    try {
      dispatch(loading(true));
      const resp = await API.post("facturacion", "/facturacion/alta", params);
      return(resp);
    } catch (error) {
      console.log("ocurrio un error " + error);
    } finally {
      dispatch(loading(false));
    }
  };
};

export const insertFacturasOrdenes = (sFacturasArray) => {
  return async (dispatch) => {
    const params = {
      headers: {},
      response: false,
      body: {
        sFacturasArray
      },
    };

    try {
      dispatch(loading(true));
      const resp = await API.post("facturacion", "/facturacion/ordenes-pago-facturas", params);
      return(resp);
    } catch (error) {
      console.log("ocurrio un error " + error);
    } finally {
      dispatch(loading(false));
    }
  };
};


export const validarFacturas = (sFacturasArray) => {
  return async (dispatch) => {
    const params = {
      headers: {},
      response: false,
      body: {
        sFacturasArray
      },
    };
    let data = []
    try {
      const resp = await API.post("facturacion", "/facturacion/validacion", params);
      console.log(resp)
      data = JSON.parse(resp.data);
      console.log(data)
    } catch (error) {
      console.log("ocurrio un error " + error);
    } finally {
      return(data);
    }
  };
};