import { Grid, Button } from "@mui/material";
import { styled, alpha } from "@mui/material/styles";
import InputBase from "@mui/material/InputBase";
import SearchIcon from "@mui/icons-material/Search";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import SummarizeIcon from "@mui/icons-material/Summarize";
import "../../styles/reportTable.css";
import React, { useEffect, useRef, useState } from "react";
import { useDownloadExcel } from "react-export-table-to-excel";
import jsPDF from "jspdf";
import autoTable from "jspdf-autotable";
import { useReportsPage } from "../../hooks/useReportsPage";
import moment from "moment";
import { useParams } from "react-router-dom";

var doc = new jsPDF();

const Search = styled("div")(({ theme }) => ({
  position: "relative",
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  "&:hover": {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  marginLeft: 0,
  width: "100%",
  [theme.breakpoints.up("sm")]: {
    marginLeft: theme.spacing(1),
    width: "auto",
  },
  border: "1px solid #f1f1f1",
}));

const SearchIconWrapper = styled("div")(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: "100%",
  position: "absolute",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "inherit",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      width: "12ch",
      "&:focus": {
        width: "20ch",
      },
    },
  },
}));

export const ReportTable = (props) => {
  const {
    showButtons = null, //funcion
    extraButtons
  } = props
  const { dataTable, cadenas, emisores, estatusPago, productos } = useReportsPage();
  const [table, setTable] = useState({
    columns: [],
    rows: [],
    numColums: [],
  });
  const [search, setSearch] = useState("");
  const [printPdf, setPrintPdf] = useState(false);
  const tableRef = useRef();

  useEffect(() => {
    var innerData = [];
    if (search) {
      dataTable.rows.map((row) =>
        dataTable.columns.map((column) => {
          if (column.type === "text") {
            if (
              row[column.field]
                .toLocaleString()
                .toLowerCase()
                .includes(search.toLowerCase())
            ) {
              if (!innerData.includes(row)) {
                innerData.push(row);
              }
            }
          }
        })
      );
      setTable({
        ...table,
        rows: innerData,
      });
    } else {
      setTable(dataTable);
    }
  }, [search]);

  useEffect(() => {
    setTable(dataTable);
  }, [dataTable]);

  const getTextAlign = (type) => {
    let textAlign = '';
    switch (type) {
      case 'number':
        textAlign = 'center'
        break
      case 'price':
      case 'percentage':
        textAlign = 'right'
        break
      case 'text':
        textAlign = 'left'
        break
    }
    return textAlign;
  }
  const getColumnTotal = (key) => {
    let total = 0;
    table.rows?.map((row) => (total += parseFloat(row[key])));
    return total;
  };

  const { onDownload } = useDownloadExcel({
    currentTableRef: tableRef.current,
    filename: !["Escalonado", "Comisiones a integrador", "Geolocalización", "Sucursales no registradas","Transacciones"].includes(dataTable.title) || dataTable.details.nFiltradoFechas ? `Reporte ${table.title} (${table.details?.dFecInicial} - ${table.details?.dFecFinal})` : `Reporte ${table.title} (${moment().format("YYYY-MM-DD")})`,
    sheet: "Reporte",
  });

  const downloadXls = () => {
    setPrintPdf(false);
    onDownload();
  };

  const downloadPdf = () => {
    doc = new jsPDF("landscape");

    setPrintPdf(true);
    setTimeout(() => {
      doc.setFont("helvetica");
      doc.setFontSize(9);
      autoTable(doc, {
        html: "#reportTable",
        margin: { top: 28 },
        styles: { fontSize: 8 },
        didDrawPage: header,
      });

      addFooters(doc)
      let docName = `Reporte ${table.title}`
      if (!["Escalonado", "Comisiones a integrador", "Geolocalización", "Sucursales no registradas","Transacciones"].includes(dataTable.title) || dataTable.details.nFiltradoFechas) {
        docName = `${docName} (${table.details?.dFecInicial} - ${table.details?.dFecFinal})`
      } else {
        docName = `${docName} (${moment().format('YYYY-MM-DD')})`
      }
      doc.save(`${docName}.pdf`);
    }, 0);
  };

  var header = function (data) {
    doc.setFontSize(5);
    doc.addImage(
      "/assets/SofipayLogo.png",
      "JPEG",
      data.settings.margin.left,
      10,
      38,
      10
    );
    doc.setFont("helvetica", "bold");
    doc.setFontSize(11);
    doc.text(
      "Reporte de " + dataTable.title.toLowerCase(),
      doc.internal.pageSize.width - 15,
      10,
      {
        align: "right",
      }
    );

    doc.setFont("helvetica", "normal");
    doc.setFontSize(11);
    if (!["Escalonado", "Comisiones a integrador", "Geolocalización", "Sucursales no registradas","Transacciones"].includes(dataTable.title) || dataTable.details.nFiltradoFechas) {
      doc.text(
        dataTable.details.dFecInicial + " a " + dataTable.details.dFecFinal,
        doc.internal.pageSize.width - 15,
        15,
        {
          align: "right",
        }
      );
    }


    if (getFilter()) {
      doc.setFont("helvetica", "normal");
      doc.setFontSize(11);
      doc.text(
        getFilter().name + getFilter().value,
        doc.internal.pageSize.width - 15,
        20,
        {
          align: "right",
        }
      );
    }
    doc.setFontSize(8);
    doc.text(
      "Red Efectiva SA de CV",
      55,
      11,
      {
        align: "left",
      }
    );
    doc.text(
      "Blvd. Antonio L. Rdz. 3058 Suite 201-A",
      55,
      14,
      {
        align: "left",
      }
    );
    doc.text(
      "Colonia Santa Maria",
      55,
      17,
      {
        align: "left",
      }
    );
    doc.text(
      "Monterrey, N.L. C.P. 64650",
      55,
      20,
      {
        align: "left",
      }
    );
  };

  const addFooters = doc => {
    const pageCount = doc.internal.getNumberOfPages()

    doc.setFont('helvetica', 'italic')
    doc.setFontSize(8)
    for (var i = 1; i <= pageCount; i++) {
      doc.setPage(i)
      console.log('Página ' + String(i) + ' de ' + String(pageCount))
      doc.text('Página ' + String(i) + ' de ' + String(pageCount), doc.internal.pageSize.width - 10, 200, {
        align: 'right'
      })
    }
  }

  const printTd = (value, type) => {
    switch (type) {
      case "price":
        return value?.toLocaleString("en-US", {
          style: "currency",
          currency: "USD",
        });
      case "date":
        return value === "0000-00-00 00:00:00" || value === null ? "" : moment(value[value.length - 1] === "Z" ? value.slice(0, -1) : value).format("YYYY-MM-DD");
        case "datetime":
          return value === null ? "" : moment(value[value.length - 1] === "Z" ? value.slice(0, -1) : value).format("YYYY-MM-DD HH:mm:ss");
      case 'percentage':
        return (value + "%")
      default:
        return value;
    }
  };

  const getFilter = () => {
    if (dataTable.details.nIdCadena || dataTable.details.nIdEmisor) {
      if (dataTable.details.nIdCadena) {
        let cadenaItem = cadenas.rows.find(item => item.nIdCadena === dataTable.details.nIdCadena)
        console.log(cadenaItem)
        if (cadenaItem) {
          return ({
            name: 'Comisionista: ',
            value: cadenaItem.sNombreComercial
          })
        }
      }
      if (dataTable.details.nIdEmisor) {
        let emisorItem = emisores.rows.find(item => item.nIdEmisor === dataTable.details.nIdEmisor)
        if (emisorItem) {
          return ({
            name: 'Entidad financiera: ',
            value: emisorItem.sNombreComercial
          })
        }
      }
    }

    return false;
  }

  const getFiltersString = () => {
    if (dataTable.title === 'Registros bancarios') {
      return (
        <>
          {dataTable.details.sConcepto ? <><br /> Concepto: <b>{dataTable.details.sConcepto}</b></> : <></>}
          {dataTable.details.sReferencia ? <><br /> Referencia: <b>{dataTable.details.sReferencia}</b></> : <></>}
        </>
      )
    } else if (dataTable.title === 'Pagos y cobros') {
      return (
        <>
          {dataTable.details.productType !== 2 ? <><br /> Flujo: <b>{dataTable.details.productType === 0 ? 'Pago' : 'Cobro'}</b></> : <></>}
          {<><br /> Periodo: <b>{dataTable.details.nPeriodo === 0 ? 'Diario' : 'Mensual'}</b></>}
          {dataTable.details.dMonth !== 0 && dataTable.details.nPeriodo === 1 ? <><br /> Mes: <b>{months.find(month => month.id === dataTable.details.dMonth)?.name}</b></> : <></>}
          {dataTable.details.dYear !== 0 && dataTable.details.nPeriodo === 1 ? <><br /> Año: <b>{dataTable.details.dYear}</b></> : <></>}
        </>
      )
    } else if (dataTable.title === 'Conciliación de operaciones' || dataTable.title === 'General de conciliación') {
      return (<></>)
    } else {
      return (
        <>
          {dataTable.details.productType !== 2 ? <><br /> Tipo de producto: <b>{dataTable.details.productType === 0 ? 'CASH OUT' : 'CASH IN'}</b></> : <></>}
          {dataTable.details.sClaveProducto !== 0 ? <><br /> Producto: <b>{productos.find(producto => producto.sClaveProducto === dataTable.details.sClaveProducto)?.sDescripcion}</b></> : <></>}
          {dataTable.details.nIdEstatusPago !== 0 ? <><br /> Estatus de pago: <b>{estatusPago.find(estatus => estatus.sClaveProducto === dataTable.details.nIdEstatusPago)?.sDescripcion}</b></> : <></>}
          {dataTable.details.dMonth !== 0 ? <><br /> Mes: <b>{months.find(month => month.id === dataTable.details.dMonth)?.name}</b></> : <></>}
          {dataTable.details.dYear !== 0 && ["Escalonado", "Comisiones a integrador", "Geolocalización"].includes(dataTable.title) ? <><br /> Año: <b>{dataTable.details.dYear}</b></> : <></>}
        </>
      )
    }

  }

  const getRowHeader = (numColums, columns) => {
    if (numColums === 1) {
      return (<tr>
        {columns.map((column) => (
          <th style={{ width: column.width, textAlign: 'center' }} key={column.headerName}>{column.headerName}</th>
        ))}
        {showButtons !== null &&
          <th>
          </th>
        }
      </tr>)
    } else {
      return columns.map((columns) => (
        <tr>
          {columns.map((column) => {
            console.log(column);
            return (<th style={{ width: column.width, textAlign: (column?.colspan ? "center" : "inherit"), }} key={column.headerName} colSpan={column?.colspan}>{column.headerName}</th>)
          })}
          {showButtons !== null &&
            <th>
            </th>
          }
        </tr>
      ))
    }
  }

  const tableComponent = () => {
    if (dataTable?.columns?.length) {
      return (
        <Grid container style={{ background: 'white', padding: 20, borderRadius: 5 }}>
          <Grid style={{ display: "none" }}>{printTable()}</Grid>
          <Grid container justifyContent={"space-between"} style={{ marginBottom: 20 }} spacing={2}>
            <Grid item xs={12} sm={8}>
              <Button
                variant="contained"
                startIcon={<PictureAsPdfIcon />}
                onClick={downloadPdf}
              >
                PDF
              </Button>
              <Button
                variant="contained"
                startIcon={<SummarizeIcon />}
                onClick={downloadXls}
                style={{ marginLeft: 10 }}
              >
                Excel
              </Button>
              {extraButtons ? extraButtons : <></>}
            </Grid>
            <Grid item xs={12} sm={4}>
              <Search>
                <SearchIconWrapper>
                  <SearchIcon />
                </SearchIconWrapper>
                <StyledInputBase
                  value={search}
                  placeholder="Buscar..."
                  onChange={(e) => setSearch(e.target.value)}
                />
              </Search>
            </Grid>
          </Grid>
          <br></br>
          <div
            className="table-container"
            style={{ width: "100%", overflowX: "auto" }}
          >
            <table className="report-table" ref={tableRef}>
              <thead>
                <tr>
                  <th
                    style={{
                      textAlign: "center",
                    }}
                    colSpan={(table.numColums > 1 ? table.columns[table.numColums - 1].length : table.columns.length) + (showButtons !== null ? 1 : 0)}
                  >
                    {getFilter() ? getFilter().name + getFilter().value : <>
                      Reporte{" "}
                      {dataTable.details.type === 0
                        ? ""
                        : dataTable.details.type === 1
                          ? "entidades financieras"
                          : "comisionistas"}

                    </>}
                  </th>
                </tr>
                <tr>
                  <td colSpan={Math.floor(((table.numColums > 1 ? table.columns[table.numColums - 1].length : table.columns.length) + (showButtons !== null ? 1 : 0)) / 2)}>
                    <b>
                      REPORTE{" "}
                      {dataTable.details.nDetalle || ["Transacciones"].includes(dataTable.title) ? "DETALLADO" : "CONDENSADO"}{" "}
                      DE {dataTable.title.toUpperCase()}
                    </b>
                    {(!["Escalonado", "Comisiones a integrador", "Geolocalización", "Sucursales no registradas", "Transacciones"].includes(dataTable.title) && dataTable.details.nPeriodo === 0) || dataTable.details.nFiltradoFechas ? <><br />Periodo de <b>{dataTable.details.dFecInicial}</b> a{" "}
                      <b>{dataTable.details.dFecFinal}</b></> : <></>}
                    {getFiltersString()}
                    {search ? (
                      <>
                        <br /> Búsqueda por: "<b>{search}</b>"
                      </>
                    ) : (
                      <></>
                    )}

                  </td>
                  <td
                    colSpan={Math.ceil(((table.numColums > 1 ? table.columns[table.numColums - 1].length : table.columns.length) + (showButtons !== null ? 1 : 0)) / 2)}
                    style={{ textAlign: "right" }}
                  >
                    <b>Red Efectiva SA de CV</b>
                    <br />
                    Blvd. Antonio L. Rdz. 3058 Suite 201-A <br />
                    Colonia Santa Maria <br />
                    Monterrey, N.L. C.P. 64650
                  </td>
                </tr>
                <tr>
                  <th
                    style={{
                      textAlign: "center",
                    }}
                    colSpan={(table.numColums > 1 ? table.columns[table.numColums - 1].length : table.columns.length) + (showButtons !== null ? 1 : 0)}
                  >
                    Reporte de {dataTable.title.toLowerCase()}
                  </th>
                </tr>
                {getRowHeader((table.numColums ? table.numColums : 1), table.columns)}
                {/*<tr>
                  {table.columns.map((column) => (
                    <th style={{ width: column.width }} key={column.headerName}>{column.headerName}</th>
                  ))}

                  {showButtons !== null &&
                    <th>
                    </th>
                  }
                </tr>*/}
              </thead>
              <tbody>
                {table.rows?.length ? (
                  table.rows.map((row, key) => (
                    <tr key={"report_table_"+key}>
                      {(table.numColums > 1 ? table.columns[table.numColums - 1] : table.columns)?.map((column) => (
                        <td
                          style={{ width: column.width, textAlign: getTextAlign(column.type) }} key={column.field+"_"+key}>
                          {printTd(row[column.field], column.type)}
                        </td>
                      ))}
                      {showButtons !== null &&
                        <td>
                          {showButtons(row)}
                        </td>
                      }
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td
                      colSpan={(table.numColums > 1 ? table.columns[table.numColums - 1]?.length : table.columns.length) + (showButtons !== null ? 1 : 0)}
                      style={{
                        textAlign: "center",
                      }}
                    >
                      No se encontraron resultados
                    </td>
                  </tr>
                )}
              </tbody>
              <tfoot>
                <tr>
                  {(table.numColums > 1 ? table.columns[table.numColums - 1] : table.columns)?.map((column) =>
                    column.type === "number" || column.type === "price" ? (
                      <td key={column.headerName}
                        style={{ textAlign: getTextAlign(column.type) }}>
                        {printTd(getColumnTotal(column.field), column.type)}
                      </td>
                    ) : (
                      <td></td>
                    )
                  )}
                  {showButtons !== null &&
                    <td>
                    </td>
                  }
                </tr>
              </tfoot>
            </table>
          </div>
        </Grid>
      );
    }
  };

  const printTable = () => {
    return (
      <table id="reportTable" className="report-table">
        <thead>
          <tr>
            {(table.numColums > 1 ? table.columns[table.numColums - 1] : table.columns).map((column) => (
              <th style={{ width: column.width }}>{column.headerName}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {table.rows?.length > 0 ? (
            table.rows.map((row, key) => (
              <tr key={"print_table_"+key}>
                {(table.numColums > 1 ? table.columns[table.numColums - 1] : table.columns).map((column) => (
                  <td style={{ width: column.width }}  key={"print"+column.field+"_"+key}>
                    {printTd(row[column.field], column.type)}
                  </td>
                ))}
              </tr>
            ))
          ) : (
            <tr>
              <td
                colSpan={(table.numColums > 1 ? table.columns[table.numColums - 1] : table.columns).length}
                style={{
                  textAlign: "center",
                }}
              >
                No se encontraron resultados
              </td>
            </tr>
          )}
          <tr>
            {(table.numColums > 1 ? table.columns[table.numColums - 1] : table.columns).map((column) =>
              column.type === "number" || column.type === "price" ? (
                <td>{printTd(getColumnTotal(column.field), column.type)}</td>
              ) : (
                <td></td>
              )
            )}
          </tr>
        </tbody>
      </table>
    );
  };
  return tableComponent();
};

const months = [
  {
    id: 0,
    name: "Todos",
  },
  {
    id: 1,
    name: "Enero",
  },
  {
    id: 2,
    name: "Febrero",
  },
  {
    id: 3,
    name: "Marzo",
  },
  {
    id: 4,
    name: "Abril",
  },
  {
    id: 5,
    name: "Mayo",
  },
  {
    id: 6,
    name: "Junio",
  },
  {
    id: 7,
    name: "Julio",
  },
  {
    id: 8,
    name: "Agosto",
  },
  {
    id: 9,
    name: "Septiembre",
  },
  {
    id: 10,
    name: "Octubre",
  },
  {
    id: 11,
    name: "Noviembre",
  },
  {
    id: 12,
    name: "Diciembre",
  },
]