const EFR = 'EFR_RENDER';
const CADENA = 'CADENA_RENDER';

export const typesSave = {
  EFR,
  CADENA
}

export const localStorageKeys = {
  factura: 'facturacion'
}
