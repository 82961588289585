import { Routes, Route } from 'react-router-dom';

import { routes } from './routes';

import { LoginPage } from '../pages/LoginPage';
import { ForgotPasswordPage } from '../pages/ForgotPasswordPage';

import DrawerMenuResponsive from '../components/GeneralLayout/DrawerMenuResponsive';

import { CadenasModule } from '../modules/CadenasModule';
import { DashboardModule } from '../modules/DashboardModule';
import { ReportesModule } from '../modules/ReportesModule';
import { VerCadenasModule } from '../modules/VerCadenasModule';
import { EFRModule } from '../modules/EFRModule';

import { Error404Page } from '../pages/Error404Page';
import { NewPasswordPage } from '../pages/NewPasswordPage';
import { VerEFRModule } from '../modules/VerEFRModule';

import { Loading } from '../components/Loading';
import { useSelector } from 'react-redux';
import { Toast } from '../components/GeneralLayout/components/Toast';
import { ModalViewPdf } from '../components/modal/ModalViewPdf';
import { ModalListContact } from '../components/modal/ModalListContact';
import { typesSave } from '../helpers/types';
import { DialogHelp } from '../components/modal/DialogHelp';
import { ModalContact } from '../components/modal/ModalContact';

import { ConciliacionModule } from '../modules/ConciliacionModule';
import { FacturasValidacionModule } from '../modules/FacturasValidacionModule';
import { FacturasModule } from '../modules/FacturasModule';
import { PolizasModule } from '../modules/PolizasModule';
import { RegistrosBancariosModule } from '../modules/RegistrosBancariosModule';
import { VerUsuariosModule } from '../modules/VerUsuariosModule';
import { PolizasPage } from '../pages/PolizasPage';
import { VerSucursalesPage } from '../pages/VerSucursalesPage';
import { VerSucursalesModule } from '../modules/VerSucursalesModule';
import { VerCatalogosModule } from '../modules/VerCatalogosModule';
import { VerUsuariosExternosModule } from '../modules/VerUsuariosExternosModule';
import { GetCredentialsPage } from '../pages/GetCredentialsPage';
import DrawerUsuarioExterno from '../components/GeneralLayout/DrawerUsuarioExterno';
import Redirect from '../components/GeneralLayout/Redirect';
import { GetCredentialsModule } from '../modules/GetCredentialsModule';
import { VerSuspensionesModule } from '../modules/VerSuspensionesModule';
import { VerPerfilesUsuariosModule } from '../modules/VerPerfilesUsuariosModule';
import { MonitorERPModule } from '../modules/MonitorERPModule';

export const DashboardRoutes = () => {
  const { isLoading } = useSelector((state) => state.ui);
  const { user } = useSelector((state) => state.user);
  return (
    <>
      <div>
        {isLoading && <Loading />}
        <Toast />
        <ModalViewPdf />
        <ModalListContact saveIn={typesSave.CADENA} />
        <ModalContact saveIn={typesSave.CADENA} />
        <DialogHelp />
        <Routes>
          {/* public */}
          <Route path="/login" element={<LoginPage />} />
          <Route path="/new-password" element={<NewPasswordPage />} />
          <Route path="/forgot-password" element={<ForgotPasswordPage />} />
          {/* protected */}
          {user ? <>
            {user?.nExterno ?
              <Route path="/" element={<DrawerUsuarioExterno />}>
                <Route index element={<GetCredentialsPage />} />
              </Route>
              :
              <Route path="/" element={<DrawerMenuResponsive />}>
                <Route index element={<DashboardModule />} />
                <Route path={routes.Dashboard} element={<DashboardModule />} />
                <Route path={routes.Cadenas} element={<CadenasModule />} />
                <Route path={routes.VerCadenas} element={<VerCadenasModule />} />
                <Route path={routes.EFR} element={<EFRModule />} />
                <Route path={routes.VerEFR} element={<VerEFRModule />} />
                <Route path={routes.Reporte} element={<ReportesModule />} />
                <Route path={routes.Polizas} element={<PolizasPage />} />
                <Route path={routes.Conciliacion} element={<ConciliacionModule />} />
                <Route path={routes.Polizas} element={<PolizasModule />} />
                <Route path={routes.registrosBancarios} element={<RegistrosBancariosModule />} />
                <Route path={routes.facturas} element={<FacturasModule />} />
                <Route path={routes.facturasValidacion} element={<FacturasValidacionModule />} />
                <Route path={routes.usuarios} element={<VerUsuariosModule />} />
                <Route path={routes.usuariosExternos} element={<VerUsuariosExternosModule />} />
                <Route path={routes.Sucursales} element={<VerSucursalesModule />} />
                <Route path={routes.catalogos} element={<VerCatalogosModule />} />
                <Route path={routes.suspensiones} element={<VerSuspensionesModule />} />
                <Route path={routes.perfilesUsuarios} element={<VerPerfilesUsuariosModule />} />
                <Route path={routes.monitorERP} element={<MonitorERPModule />} />
                <Route path="*" element={<Error404Page />} />
              </Route>
            }
          </> : <>
            <Route path="/" element={<Redirect />} />
          </>}


        </Routes>
      </div>
    </>
  );
};
