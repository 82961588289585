import { useEffect } from 'react';
import { useMenu } from '../hooks/useMenu';
import { useAuthPage } from '../hooks/useAuthPage';
import { NotAllowPage } from '../pages/NotAllowPage';
import { VerUsuariosExternosPage } from '../pages/VerUsuariosExternosPage';

export const VerUsuariosExternosModule = () => {
  let name = '';
  const { setNameSection } = useMenu();
  const { setNewPage, isAllowed } = useAuthPage();
  useEffect(() => {
    setNewPage('usuariosExternos');
    setNameSection(name);
  }, [setNewPage, setNameSection, name]);

  if (isAllowed) {
    name = 'usuariosExternos';
    return <VerUsuariosExternosPage />;
  } else {
    name = 'NO DISPONIBLE';
    return <NotAllowPage />;
  }
};