import { Box } from "@mui/system";
import { useModal } from "../../hooks/useModal";
import { useAuthPage } from "../../hooks/useAuthPage";
import { ModalBase } from "./ModalBase";
import {
  Button,
  Grid,
  InputAdornment,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import moment from "moment";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { SearchInput } from "../form/SearchInput";
import { TextInput } from "../form/TextInput";
import RouteIcon from "@mui/icons-material/Route";
import { IvaInput } from "../form/IvaInput";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

export const ModalRoute = (props) => {
  const {
    form,
    onChange,
    productos,
    onChangeInput,
    emisores = [],
    saveRoute,
    productosEmisor
  } = props;
  const { isOpenRoute, closeModalRoute, setOpenToast } = useModal();
  const { setAllowComponent } = useAuthPage();
  const [emisor, setEmisor] = useState({});
  const [expanded, setExpanded] = useState(false);
  const [innerRutas, setInnerRutas] = useState([]);
  const [initialDescription, setInitialDescription] = useState({
    nIdRuta: 0,
    sClaveProducto: "",
    sDescripcion: "",
    changed: false,
  });
  const [dateIntervals, setDateIntervals] = useState([])
  const [loaded, setLoaded] = useState(false)

  const { rutas } = useSelector((state) => state.emisor);

  useEffect(() => {
    if (emisores.length) {
      let innerEmisor = emisores.filter(
        (item) => item.nIdEmisor === form.nIdEmisor
      )[0];
      if (innerEmisor) {
        setEmisor({ ...emisor, ...innerEmisor });
        if (initialDescription.sClaveProducto !== "")
          handleDescription(form.sClaveProducto);
      }
    }
  }, [form.nIdEmisor]);

  useEffect(() => {
    if (initialDescription.sClaveProducto !== "" || form.modalType === "add")
      handleDescription(form.sClaveProducto);
    let data = []
    if (rutas.rows) {
      rutas.rows.map((ruta) => {
        if (ruta.sClaveProducto === form.sClaveProducto && ruta.nIdEmisor === form.nIdEmisor) data.push({
          id: ruta.nIdRuta,
          name: `(${moment(ruta.dFEVRuta).format('YYYY-MM-DD')}) ${ruta.sDescripcion}`
        })
      })
    }
    setInnerRutas(data)
    let innerProducto = productos?.find((prod) => prod.sClaveProducto === form.sClaveProducto);
    if (innerProducto) {
      if (form.modalType !== "view") updateDateIntervals(innerProducto)
    }
  }, [form.sClaveProducto]);

  useEffect(() => {
    if (isOpenRoute) {
      setInitialDescription({
        nIdRuta: form.nIdRuta,
        sClaveProducto: form.sClaveProducto,
        sDescripcion: form.sDescripcion,
        changed: false,
      });
      setDateIntervals([])
      let innerProducto = productos?.find((prod) => prod.sClaveProducto === form.sClaveProducto);
      if (innerProducto) {
        if (form.modalType !== "view") updateDateIntervals(innerProducto)
      }
    } else {
      setLoaded(false)
      setInitialDescription({
        nIdRuta: 0,
        sClaveProducto: "",
        sDescripcion: "",
        changed: false,
      });
      setInnerRutas([])
    }
  }, [form.nIdRuta]);

  const updateDateIntervals = (producto) => {
    let data = []
    if (producto) {
      producto = producto.sClaveProducto
      console.log(producto)
      //validacion de otros permisos (que no se empate con las fechas de otros permisos con el mismo producto)
      if (form.modalType === "add") {
        if (rutas.rows) rutas.rows.map(per => {
          if (per.sClaveProducto === producto) {
            data.push({
              id: 1,
              dFecInicial: new Date(getCorrectDate(per.dFEVRuta))
            })
          }
        })
      } else {
        const currentDate = new Date(getCorrectDate(form.dFEVRuta))
        if (rutas.rows) rutas.rows.map(per => {
          if (per.sClaveProducto === producto && per.nIdRuta !== form.nIdRuta) {
            let perDate = new Date(getCorrectDate(per.dFEVRuta))
            data.push({
              id: perDate > currentDate ? 3 : 1,
              dFecInicial: perDate,
              dFecFinal: perDate
            })
          }
        })
      }

      //validacion con las fechas de la cadena producto de la ruta 
      let fechaBaja = null
      const productosEmisorFiltered = productosEmisor?.filter(pe => pe.sClaveProducto === producto).sort(function (a, b) { return new Date(a.dFecAlta) - new Date(b.dFecAlta) })
      if (productosEmisorFiltered) productosEmisorFiltered.map((pe, key) => {
        if (fechaBaja) {
          data.push({
            id: 2,
            dFecInicial: new Date(getCorrectDate(fechaBaja)),
            dFecFinal: new Date(getCorrectDate(pe.dFecAlta))
          })
        } else {
          data.push({
            id: 1,
            dFecInicial: new Date(getCorrectDate(pe.dFecAlta))
          })
        }
        fechaBaja = pe.dFecBaja ? pe.dFecBaja : null
        if (!productosEmisorFiltered[key + 1] && fechaBaja) {
          data.push({
            id: 3,
            dFecFinal: new Date(getCorrectDate(fechaBaja))
          })
        }
      })
    }
    setLoaded(true)
    console.log(data)
    setDateIntervals(data)
  }

  const getCorrectDate = (date) => {
    return date[date.length - 1] === "Z" ? date.slice(0, -1) : date
  }

  function disableDate(date) {

    let flag = false

    if (dateIntervals) {
      const currentDate = new Date(date)

      dateIntervals.map(di => {
        if (!flag) {
          //1. dFecInicial < value    2. (Intervalo a deshabilitar) dFecInicial < value < dFecFinal    3. value < dFecFinal
          if (di.id === 1) flag = currentDate < di.dFecInicial
          if (di.id === 2) flag = di.dFecInicial < currentDate && currentDate < di.dFecFinal
          if (di.id === 3) flag = currentDate >= di.dFecFinal

        }
      })

    }
    return flag
  }

  const getPermissionModal = () => {
    if (form.modalType === "add" && setAllowComponent("agregarRutas"))
      return true;
    if (form.modalType === "edit" && setAllowComponent("editarRutas"))
      return true;
    if (form.modalType === "view" && setAllowComponent("verRutas")) return true;

    return false;
  };

  const handleDescription = (producto) => {
    const innerProducto = productos.find((p) => p.sClaveProducto === producto);
    if (innerProducto && emisor.sNombreComercial) {
      const innerDescription =
        emisor.sNombreComercial + " - " + innerProducto.sDescripcion;
      if (
        (form.sClaveProducto !== initialDescription.sClaveProducto &&
          initialDescription.nIdRuta === form.nIdRuta) ||
        (form.sClaveProducto === initialDescription.sClaveProducto &&
          initialDescription.changed &&
          initialDescription.nIdRuta === form.nIdRuta) ||
        form.modalType === "add"
      ) {
        console.log(producto, initialDescription);
        onChange(null, "sDescripcion", innerDescription);
        setInitialDescription({
          ...initialDescription,
          changed: true,
        });
      }
    }
  };

  const handleSubmit = () => {
    if (!form.sClaveProducto) {
      setOpenToast(true, "Seleccione un producto para continuar")
      return
    }

    if (form.nImpMinRuta > form.nImpMaxRuta) {
      setOpenToast(true, "Importe mínimo tiene que ser menor o igual al importe máximo")
      return
    }

    if (!form.sDescripcion) {
      setOpenToast(true, "Ingrese una descripción para continuar")
      return
    }

    if (disableDate(getCorrectDate(moment(form.dFEVRuta).add(2,"day").format("YYYY-MM-DD")))) {
      setOpenToast(true, "La fecha de entrada en vigor seleccionada esta deshabilitada. Seleccione otra.")
      return
    }

    saveRoute()
  }

  return getPermissionModal() ? (
    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={"es-mx"}>
      <ModalBase open={isOpenRoute} style={{ width: "500px", height: expanded ? "700px" : "650px" }}>
        <Box
          sx={{
            width: "100%",
            height: "100%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
          }}
        >
          <Grid container style={{ alignItems: "space-between" }}>
            <Grid container item sm={8} style={{ alignItems: "center" }}>
              <Grid
                item
                style={{
                  color: "#00CD68",
                  padding: 15,
                  fontWeight: "bold",
                }}
              >
                <RouteIcon fontSize={"large"} />
              </Grid>
              <Grid item>
                <Typography variant="p" color={"neutral"}>
                  {form.modalType === "add"
                    ? "Agregar"
                    : form.modalType === "edit"
                      ? "Editar"
                      : "Ver"}{" "}
                  ruta
                </Typography>
                <Typography variant="h5" color={"primary"}>
                  {emisor && emisor.sNombreComercial}
                </Typography>
              </Grid>
            </Grid>
            <Grid
              container
              item
              sm={4}
              style={{ justifyContent: "flex-end", alignItems: "center" }}
            >
              <Grid item style={{ textAlign: "right" }}>
                <Typography variant="p" color={"neutral"}>
                  IVA
                </Typography>
                <Typography variant="h5" color={"primary"}>
                  {emisor && emisor.nIVA * 100}%
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item sm={12}>
              <SearchInput
                options={productos}
                value={form.sClaveProducto}
                name={"sClaveProducto"}
                label={"Producto"}
                getOptionLabel={"sDescripcion"}
                getIndexLabel={"sClaveProducto"}
                onChangeInnerInput={onChange}
                onChange={onChange}
                disabled={form.modalType === "view"}
              />
            </Grid>
            <Grid item sm={12}>
              <TextInput
                id="sDescripcion"
                name="sDescripcion"
                label="Descripción"
                value={form.sDescripcion}
                onChangeRoot={onChangeInput}
                variant="outlined"
                fullWidth
                size="medium"
                disabled={form.modalType === "view"}
              />
            </Grid>
            <Grid item xs={12} sm={12}>
              <IvaInput
                id="nImpMinRuta"
                name="nImpMinRuta"
                label="Importe minimo operativo"
                value={form.nImpMinRuta}
                onChangeRoot={onChangeInput}
                variant="outlined"
                fullWidth
                type="number"
                size="medium"
                ivaValue={emisor ? emisor.nIVA * 100 : 0}
                disabled={form.modalType === "view"}
                startAdornment={
                  <InputAdornment position="start">$</InputAdornment>
                }
              />
            </Grid>
            <Grid item xs={12} sm={12}>
              <IvaInput
                id="nImpMaxRuta"
                name="nImpMaxRuta"
                label="Importe máximo operativo"
                value={form.nImpMaxRuta}
                onChangeRoot={onChangeInput}
                variant="outlined"
                fullWidth
                type="number"
                size="medium"
                ivaValue={emisor ? emisor.nIVA * 100 : 0}
                disabled={form.modalType === "view"}
                startAdornment={
                  <InputAdornment position="start">$</InputAdornment>
                }
              />
            </Grid>
            <Grid item xs={12} sm={12}>
              <IvaInput
                id="nImpComProducto"
                name="nImpComProducto"
                label="Comisión cliente final"
                value={form.nImpComProducto}
                onChangeRoot={onChangeInput}
                variant="outlined"
                fullWidth
                type="number"
                size="medium"
                ivaValue={emisor ? emisor.nIVA * 100 : 0}
                disabled={form.modalType === "view"}
                startAdornment={
                  <InputAdornment position="start">$</InputAdornment>
                }
              />
            </Grid>
            {/* <Grid item xs={12} sm={6}>
              <IvaInput
                id="nPerComProducto"
                name="nPerComProducto"
                label="Porcentaje comisión"
                value={form.nPerComProducto}
                onChangeRoot={onChangeInput}
                variant="outlined"
                fullWidth
                type="number"
                size="medium"
                ivaValue={emisor ? emisor.nIVA * 100 : 0}
                disabled={form.modalType === "view"}
                endAdornment={<InputAdornment position="end">%</InputAdornment>}
              />
            </Grid> */}<Grid item xs={12}>
              <DesktopDatePicker
                label="Entrada en vigor"
                inputFormat="YYYY-MM-DD"
                renderInput={(params) => (
                  <TextField {...params} style={{ width: "100%" }} />
                )}
                value={
                  form.dFEVRuta[form.dFEVRuta.length - 1] === "Z"
                    ? form.dFEVRuta.slice(0, -1)
                    : form.dFEVRuta
                }
                disablePast={form.modalType === "add"}
                shouldDisableDate={disableDate}
                disabled={form.modalType === "view"}
                onChange={(e) =>
                  onChange(
                    null,
                    "dFEVRuta",
                    moment(e["$d"]).format("YYYY-MM-DD")
                  )
                }
              />
            </Grid>
            {/* <Grid item xs={6}>
              <DesktopDatePicker
                label="Salida en vigor"
                inputFormat="YYYY-MM-DD"
                renderInput={(params) => (
                  <TextField {...params} style={{ width: "100%" }} />
                )}
                value={form.dFSVRuta}
                disabled={form.modalType === "view"}
                onChange={(e) =>
                  onChange(
                    null,
                    "dFSVRuta",
                    moment(e["$d"]).format("YYYY-MM-DD")
                  )
                }
              />
            </Grid> */}
            {/* 
            {innerRutas.length !== 0 && form.modalType === "add"
            ?
            <Grid item xs={12} style={{paddingTop: 0}}>
            <Accordion
              expanded={expanded}
            >
              <AccordionSummary
                aria-controls="panel1d-content"
                id="panel1d-header"
              >
                <Grid container style={{ alignItems: "center",display : 'flex'}}>
                  <BlueSwitch
                  onChange={() => setExpanded(!expanded)}
                  inputProps={{ 'aria-label': 'controlled' }}
                  checked={expanded} 
                  value={expanded}
                />
                    <p>Agregar permisos de una ruta anterior</p>
                  </Grid>
              </AccordionSummary>
              <AccordionDetails>
                <Grid container spacing={2} style={{width: '100%'}}>
                  <Grid item sm={12}>
                  <SearchInput
                    options={innerRutas}
                    value={form.addPermissionsRoute}
                    name={"addPermissionsRoute"}
                    label={"Rutas anteriores"}
                    getOptionLabel={"name"}
                    getIndexLabel={"id"}
                    onChange={onChange}
                  />
                  </Grid>
                </Grid>
              </AccordionDetails>
            </Accordion>
            <br />
            </Grid>
            :
            <></>
            }
             */}
          </Grid>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              width: "100%",
              justifyContent: "end",
            }}
          >
            <Button onClick={closeModalRoute} sx={{ color: "#00609C" }}>
              cancelar
            </Button>
            {form.modalType !== "view" ? (
              <Button onClick={handleSubmit} variant="contained">
                Guardar
              </Button>
            ) : (
              <></>
            )}
          </div>
        </Box>
      </ModalBase>
    </LocalizationProvider>
  ) : (
    <></>
  );
};
