import * as React from 'react';
import IconButton from '@mui/material/IconButton';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';

import { Outlet } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useEffect } from 'react';
import MenuDrawer from './components/MenuDrawer';
import { useNavigate } from 'react-router-dom';

import { createTheme, Grid, ThemeProvider, Tooltip } from '@mui/material';
import '../../styles/drawerMenuResponsive.css';
import { useAuthUser } from '../../hooks/useAuthUser';
import { useAuthPage } from '../../hooks/useAuthPage';
import { LogoutTwoTone } from '@mui/icons-material';

const iconTheme = createTheme({
  status: {
    danger: '#e53e3e'
  },
  palette: {
    primary: {
      main: '#00609C',
      darker: '#053e85'
    },
    neutral: {
      main: '#00CD68',
      contrastText: '#fff'
    }
  }
});


function DrawerUsuarioExterno(props) {
  const navigate = useNavigate();
  const { user } = useSelector((state) => state.user);
  // const [loaded, setLoaded] = useState(false)
  const { api } = useAuthUser();
  const { loaded } = useSelector((state) => state.catalogo);
  const { setLoaded } = useAuthPage();

  const { signOut } = useAuthUser();


  // startPages();
  useEffect(() => {
    if (!loaded) {
      let data = localStorage.getItem('userAuth');
      if (data) {
        data = JSON.parse(data);
        api(data.username, data.email, data.token);
      }
      setLoaded(true);
    } else {
      if (!user?.token) {
        navigate('/login');
      }
    }
    // eslint-disable-next-line
  }, [user, navigate, loaded]);


  const { window } = props;
  const [mobileOpen, setMobileOpen] = React.useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const onLogOut = () => {
    signOut();
  };

  return (
    <ThemeProvider theme={iconTheme}>
      <Grid container sx={{
        width: '100%',
        padding: 4,
        position: "fixed",
        top: 0,
        left: 0,
      }}
        spacing={2}>
        <Grid item container xs={12} justifyContent={"space-between"} alignItems={"center"}>
          <Grid item xs={2}>
            <img
              src="/assets/SofipayLogo.png"
              alt="LogoSofipay"
              width={'150px'}
            />
          </Grid>
          <Grid item xs={8} container justifyContent={"flex-end"} alignItems={"center"}>
            <Typography color='primary' variant='p'><b>{ user.name }</b></Typography>
            <Tooltip title="Cerrar sesión">
              <IconButton sx={{ marginLeft: 1 }} color='primary' onClick={onLogOut}><LogoutTwoTone /></IconButton>
            </Tooltip>
          </Grid>
        </Grid>
      </Grid>


      <Outlet />


      <Grid container sx={{
        width: '100%',
        padding: 4,
        position: "fixed",
        bottom: 0,
        left: 0,
      }}>
        <Grid item container xs={12} justifyContent={"center"} alignItems={"center"}>
          <Typography color='#979797' variant='p' fontSize={12} >Red Efectiva SA de CV,  Blvd. Antonio L. Rdz. 3058 Suite 201-A Colonia Santa Maria, Monterrey, N.L. C.P. 64650</Typography>
        </Grid>
      </Grid>
    </ThemeProvider>
  );
}

export default DrawerUsuarioExterno;
