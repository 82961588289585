import {  Badge } from '@mui/icons-material';
import {  Button, Checkbox, FormControlLabel, Grid, Typography } from '@mui/material';
import { Box } from '@mui/system';
import { TextInput } from '../form/TextInput';
import { ModalBase } from './ModalBase';
import { useEffect, useState } from 'react';
import { useAuthPage } from '../../hooks/useAuthPage';
import { ConfirmDialog } from './ConfirmDialog';
import EditIcon from '@mui/icons-material/Edit';

export const ModalPerfilUsuario = (props) => {

  const {
    isOpenModal = false,
    modalType = "add",
    form = {},
    onChange,
    handleSubmit,
    handleSubmitChangeEstatus,
    handleCloseModal,
    opciones = [],
    secciones = [],
    handlePermisos,
    enableEditModal,
    disableEditModal,
  } = props;

  const [showConfirmDialog, setShowConfirmDialog] = useState(false)
  const { setAllowComponent } = useAuthPage();

  const showTitleButton = () => {
    if (modalType === 'view' && setAllowComponent("editarPerfilUsuario") && form.nIdPerfil != 1) return <Button variant='contained' onClick={enableEditModal}
      startIcon={<EditIcon />}>Editar</Button>;
  }

  const handleConfirmDialog = async () => {
    setShowConfirmDialog(false)
    await handleSubmit()
  }

  const showSectionPermisos = (nIdSeccion) => {
    let innerOpciones = opciones.filter(opcion => opcion.nIdSeccion === nIdSeccion)
    return (
      <div style={{ borderLeft: "2px solid #00CD6850", paddingLeft: 20, marginLeft: 10 }}>{
        innerOpciones.sort((a, b) => (a.nOrden < b.nOrden ? -1 : 1)).map(opcion => {
          const isChecked = form.sPermisos.length > 0 ? form.sPermisos.some(op => op.nIdOpcion === opcion.nIdOpcion) : false
          let children = [...opcion.children];
          return (<>
            <FormControlLabel
              control={<Checkbox />}
              checked={isChecked}
              disabled={modalType === 'view'}
              label={`${opcion.sTitulo} (${opcion.sDescripcion})`}
              onChange={(e) => handlePermisos(e.target.checked, opcion)}
            />
            <br></br>
            {children.length > 0 && <div style={{ borderLeft: "2px solid #00609C40", paddingLeft: 25, marginLeft: 10 }}>
              {
                children.sort((a, b) => (a.nOrden < b.nOrden ? -1 : 1)).map(child => {

                  const isCheckedChild = form.sPermisos.length > 0 ? form.sPermisos.some(op => op.nIdOpcion === child.nIdOpcion) : false
                  const subChildren = JSON.parse(child.children);

                  return (<>
                    <FormControlLabel
                      control={<Checkbox />}
                      checked={isCheckedChild}
                      disabled={modalType === 'view'}
                      label={`${child.sTitulo} (${child.sDescripcion})`}
                      onChange={(e) => handlePermisos(e.target.checked, child)}
                    />
                    <br></br>
                    {subChildren.length > 0 && <div style={{ borderLeft: "2px solid #B7B7B780", paddingLeft: 30, marginLeft: 10 }}>
                      {
                        subChildren.sort((a, b) => (a.nOrden < b.nOrden ? -1 : 1)).map(subchild => {

                          const isCheckedSubChild = form.sPermisos.length > 0 ? form.sPermisos.some(op => op.nIdOpcion === subchild.nIdOpcion) : false

                          return (<>
                            <FormControlLabel
                              control={<Checkbox />}
                              checked={isCheckedSubChild}
                              disabled={modalType === 'view'}
                              label={`${subchild.sTitulo} (${subchild.sDescripcion})`}
                              onChange={(e) => handlePermisos(e.target.checked, subchild)}
                            />
                            <br></br>
                          </>)
                        })
                      }
                    </div>}
                  </>)
                })
              }
            </div>}
          </>)
        })
      }</div>)
  }

  return (<>
    <ConfirmDialog
      isOpenDialog={showConfirmDialog}
      onCloseDialog={() => setShowConfirmDialog(false)}
      onConfimDialog={handleConfirmDialog}
      message={`¿Estas seguro/a de editar el perfil de usuario? Estos cambios afectarán a ${form.nNumUsuarios} usuarios.`}
      textButtonConfirm="Sí, si estoy seguro/a."
    />
    <ModalBase open={isOpenModal} title="" style={{ width: '1050px', height: 'auto' }}>
      <Box
        sx={{ width: '100%', height: '100%', display: 'flex', flexDirection: 'column' }}
      >
        <TitleModal title={modalType === "add" ? "Perfil de usuario" : form.sNombre} typeOperation={modalType} extraButton={showTitleButton()} />

        <Grid container spacing={2} sx={{ mt: 1, mb: 2, maxHeight: "calc(100vh - 250px)", overflow: 'auto' }}>
          <Grid item xs={12}>
            <Typography variant="h6" color={"primary"}>Datos del perfil</Typography>
          </Grid>
          <Grid item xs={12} sm={12}>
            <TextInput
              id="sNombre"
              name="sNombre"
              label="Nombre"
              value={form.sNombre}
              onChangeRoot={onChange}
              disabled={modalType === 'view'}
              require
            />
          </Grid>
          <Grid item xs={12} sm={12}>
            <TextInput
              id="sDescripcion"
              name="sDescripcion"
              label="Descripción"
              value={form.sDescripcion}
              onChangeRoot={onChange}
              disabled={modalType === 'view'}
              require
            />
          </Grid>

          <Grid item xs={12}>
            <Typography variant="h6" color={"primary"}>Permisos</Typography>
          </Grid>
          <Grid item xs={12} container sx={{ background: "#f7f7f7", borderRadius: 2, margin: 2, padding: 2 }}><Grid item xs={12} container alignItems={"center"} sx={{ mb: 2 }}>
            <Grid item sm={7} xs={12}>
              <Typography variant="h6" color={"primary"} fontSize={19}>Permisos BackOffice</Typography></Grid>

          </Grid>
            <Grid item xs={12}>
              {
                secciones.filter(seccion => seccion.nServicio === 0).map(seccion => {
                  return (
                    <>
                      <Typography variant="h6" fontSize={17} color={"#00CD68"}>{seccion.sNombre}</Typography>
                      {showSectionPermisos(seccion.nIdSeccion)}
                    </>)
                })
              }
            </Grid>
            <Grid item xs={12} container alignItems={"center"} sx={{ mb: 2, mt: 4 }}>
              <Typography variant="h6" color={"primary"} fontSize={19}>Permisos Webservices</Typography>
            </Grid>
            <Grid item xs={12}>
              {
                secciones.filter(seccion => seccion.nServicio === 1).map(seccion => {
                  return (
                    <>
                      <Typography variant="h6" fontSize={17} color={"#00CD68"}>{seccion.sNombre}</Typography>

                      {showSectionPermisos(seccion.nIdSeccion)}
                    </>)
                })
              }
            </Grid>
          </Grid>
        </Grid>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            width: '100%',
            justifyContent: 'end'
          }}
        >
          <Button onClick={modalType === "edit" ? disableEditModal : handleCloseModal} color="primary">
            {modalType === "edit" ? "Cancelar" : "Cerrar"}
          </Button>
          {modalType !== 'view' &&
            <Button
              onClick={modalType === "add" || form.nNumUsuarios === 0 ? handleSubmit : () => setShowConfirmDialog(true)}
              color="primary"
              variant='contained'
            >
              Guardar
            </Button>
          }
        </div>
      </Box>
    </ModalBase>
  </>
  );
};

const TitleModal = ({ title, typeOperation, extraButton }) => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        width: '100%',
        marginBottom: '10px',
        justifyContent: 'space-between'
      }}
    >
      <div style={{ display: 'flex', justifyContent: "space-between" }}>
        <Badge fontSize={'large'} sx={{ color: '#00CD68', fontSize: '45px' }} />

        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <Typography
            variant="p"
            sx={{
              marginLeft: '15px',
              fontSize: '14px',
              marginBottom: '4px'
            }}
          >
            {typeOperation === 'add' ? 'Agregar' : typeOperation === 'edit' ? 'Editar perfil' : 'Ver perfil'}
          </Typography>
          <Typography
            variant="p"
            sx={{
              marginLeft: '15px',
              color: '#00609C',
              fontSize: '20px'
            }}
          >
            {title}
          </Typography>
        </div>
      </div>
      <div>
        {extraButton}
      </div>
    </Box>
  );
};

