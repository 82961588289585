import { Box } from "@mui/system";
import { useAuthPage } from "../../hooks/useAuthPage";
import { ModalBase } from "./ModalBase";
import { Button, Grid, TextField, Typography } from "@mui/material";
import { useReportsPage } from "../../hooks/useReportsPage";
import CloseIcon from "@mui/icons-material/Close";
import DoneIcon from "@mui/icons-material/Done";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import "dayjs/locale/es-mx";
import moment from "moment";
import { TextInput } from "../form/TextInput";
import { useRef } from "react";
import { BlueSwitch } from "../../helpers/stylesMaterial";

export const ModalOrdenPago = () => {
  const ordenPagoForm = useRef()
  const {
    isOpenOrdenPago,
    closeOrdenPagoModal,
    selectedRows,
    dataTable,
    ordenPagoType,
    onChange,
    onChangeRows,
    form,
    changeOrdenesPagoEstatus,
    onChangeTextInput,
    unificar,
    setUnificar
  } = useReportsPage();
  const { setAllowComponent } = useAuthPage();

  const handleSubmit =(e)=>{
    e.preventDefault()
    changeOrdenesPagoEstatus()
  }

  const rowsData = selectedRows.map((ordenPago, key) => {
    return (
      <tr id={"ordenPago"+key} style={{
        cursor: "pointer",
        height: "70px"
      }}>
        <td style={{maxWidth: 190}}>
          {ordenPago.sBeneficiario}
        </td>
        <td>
          {ordenPago.nTotal?.toLocaleString("en-US", {
            style: "currency",
            currency: "USD",
          })}
        </td>
        {ordenPagoType === "pagado" ? (
        <>
        <td>
          <TextInput
            label={
              <Typography variant="caption">Número de transacción</Typography>
            }
            name={"sCodTransaccion"}
            value={ordenPago.sCodTransaccion}
            fullWidth
            size="small"
            onChangeRoot={(e) => {
              onChangeTextInput(key,e, "sCodTransaccion")
            }}
            require
            erroMsg="El campo es requerido"
            required
            InputProps={{
              style: { fontSize: 13 },
            }}
          />
        </td>
        <td>
          <DesktopDatePicker
            //label="Fecha de pago en el banco"
            label={
              <Typography variant="caption">Fecha de pago en el banco</Typography>
            }
            inputFormat="YYYY-MM-DD"
            renderInput={(params) => 
              <TextField fullWidth
                size="small"
                InputProps={{
                  style: { fontSize: 13 },
                }}
                required {...params}
              />}
            disableFuture={true}
            value={ordenPago.dFechaPago}
            onChange={(e) =>
              onChangeRows(
                key,
                null,
                "dFechaPago",
                moment(e["$d"]).format("YYYY-MM-DD")
              )
            }
            require
          />
        </td>
        </>
        ) : (
        <td>
          <TextField
            label={
              <Typography variant="caption" style={{fontSize:16}}>Motivo de cancelación</Typography>
            }
            multiline
            fullWidth
            rows={2}
            name={"sMotivoCancelacion"}
            value={ordenPago.sMotivoCancelacion}
            onChange={(e) =>
              onChangeRows(
                key,
                null,
                "sMotivoCancelacion",
                e.target.value
            )}
            required
            InputProps={{
              style: { fontSize: 13 },
            }}
          />
        </td>
        )}
      </tr>
    )
  });

  return (
    <ModalBase
      open={isOpenOrdenPago}
      style={{ width: "850px" }}
    >
      <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={"es-mx"}>
        <Box
          sx={{
            width: "100%",
            height: "100%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
          }}
        >
          <Grid container style={{ alignItems: "space-between" }}>
            <Grid container item sm={6} style={{ alignItems: "center" }}>
              <Grid
                item
                style={{
                  color: "#00CD68",
                  padding: 15,
                  fontWeight: "bold",
                }}
              >
                {ordenPagoType === "pagado" ? (
                  <DoneIcon fontSize={"large"} />
                ) : (
                  <CloseIcon fontSize={"large"} color={"error"} />
                )}
              </Grid>
              <Grid item>
                <Typography variant="p" color={"neutral"}>
                  Cambiar estados de ordenes a:
                </Typography>
                <Typography variant="h5" color={"primary"}>
                  {ordenPagoType.charAt(0).toUpperCase() +
                    ordenPagoType.slice(1)}
                </Typography>
              </Grid>
            </Grid>
            {ordenPagoType !== "pagado" && (
            <Grid item sm={6}>
              <Grid container style={{ alignItems: "center" }} justifyContent={'flex-end'}>
                <BlueSwitch
                  onChange={(e) => setUnificar(e.target.checked)}
                  inputProps={{ 'aria-label': 'controlled' }}
                  name={"nDetalle"}
                  checked={unificar} // TODO: Verificar si es un tipo numerico o booleano
                  value={unificar}
                />
                <p>Unificar motivo cancelación</p>
              </Grid>
            </Grid>
            )}
          </Grid>
          <form onSubmit={(e)=>handleSubmit(e)} style={{marginBottom: 15}}>
            <Grid container item sm={12} style={{ alignItems: "center" }}>
              <div className="table-container" style={{ width: "100%", maxHeight: 450, overflow: "auto" }}>
                <table id="ordenPagoMD" className="report-table" style={{ width: "100%" }}>
                  <thead>
                    <tr>
                      <th className="beneficiario" >Beneficiario</th>
                      <th className="pagoTotal">Pago Total</th>
                      {ordenPagoType === "pagado" ? (
                        <>
                        <th className="numeroTransaccion">Número de transacción</th>
                        <th className="fechaPago">Fecha de pago en el banco</th>
                        </>
                      ) : (
                        <th className="numeroTransaccion">Número de transacción</th>
                      )}
                    </tr>
                  </thead>
                  <tbody>
                    {rowsData}
                  </tbody>
                </table>
              </div>
            </Grid>
            <button style={{display:'none'}} ref={ordenPagoForm}>
              Enviar
            </button>
          </form>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              width: "100%",
              justifyContent: "end",
            }}
          >
            <Button onClick={closeOrdenPagoModal} sx={{ color: "#00609C" }} style={{marginRight: 5}}>
              cerrar
            </Button>
            <Button onClick={()=>ordenPagoForm.current.click()} variant={"contained"}>
              Guardar
            </Button>
          </div>
        </Box>
      </LocalizationProvider>
    </ModalBase>
  );
};
