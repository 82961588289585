import { useEffect, useState } from "react";
import { useMenu } from "../hooks/useMenu";
import { useAuthPage } from "../hooks/useAuthPage";
import { Button, createTheme, Grid, ThemeProvider } from "@mui/material";
import { Visibility, Delete, History } from "@mui/icons-material";
import { useModal } from "../hooks/useModal";

import EnhancedTable from "../components/table/EnhancedTable";
import { ConfirmDialog } from "../components/modal/ConfirmDialog";
import { ModalSuspension } from "../components/modal/ModalSuspension";
import { useSuspensionesTable } from "../hooks/useSuspensionesTable";
import { TextInput } from "../components/form/TextInput";
import { ModalBitacoraSuspension } from "../components/modal/ModalBitacoraSuspension";

const iconTheme = createTheme({
  status: {
    danger: "#e53e3e",
  },
  palette: {
    primary: {
      main: "#00609C",
      dark: "#053e85",
    },
    neutral: {
      main: "#00CD68",
      light: "#053e85",
      dark: "#053e85",
      contrastText: "#fff",
    },
  },
});

export const VerSuspensionesPage = () => {
  const { setNameSection } = useMenu();
  const { setAllowComponent } = useAuthPage();
  const { isTableLoading } = useModal();

  const {
    loadInInit,
    dataTable,
    onClickAdd,
    showModal,
    form,
    handleCloseModal,
    handleSubmit,
    openModal,
    enableEditModal,
    disableEditModal,
    handleSubmitChangeEstatus,
    onChange,
    cadenas,
    productos,
    sucursales,
    handleBitacoraOpen,
    isBitacoraOpen,
    dataTableBitacora = {},
    downloadBitacora,
  } = useSuspensionesTable();

  const [showConfirmDialog, setShowConfirmDialog] = useState(false);
  const [comentarioBaja, setComentarioBaja] = useState("");
  const triggerDeleteDialog = (row) => {
    setShowConfirmDialog(row);
  };

  const handleConfirmDialog = async () => {
    const resp = await handleSubmitChangeEstatus({
      ...showConfirmDialog,
      sComentario: comentarioBaja,
    });
    if (resp.ok) {
      setShowConfirmDialog(false);
      setComentarioBaja("");
    }
  };

  useEffect(() => {
    setNameSection("Suspensiones");
  }, [setNameSection]);

  useEffect(() => {
    loadInInit();
  }, []);

  return (
    <ThemeProvider theme={iconTheme}>
      <ConfirmDialog
        isOpenDialog={!!showConfirmDialog}
        onCloseDialog={() => {
          setShowConfirmDialog(false);
          setComentarioBaja("");
        }}
        onConfimDialog={handleConfirmDialog}
        message={`¿Estas seguro/a de dar de baja la suspensión? Se cambiará el estatus de las sucursales y/o productos a "Activo". `}
        textButtonConfirm="Sí, si estoy seguro/a."
        body={
          <Grid container pt={1}>
            <TextInput
              id="comentarioBaja"
              name="comentarioBaja"
              label="Motivo o comentarios para la baja de suspensión"
              value={comentarioBaja}
              onChangeRoot={(e) => setComentarioBaja(e.comentarioBaja)}
              caracteres={45}
              require
              multiline
              minRows={2}
            />
          </Grid>
        }
      />
      <ModalSuspension
        isOpenModal={showModal.open}
        modalType={showModal.type}
        form={form}
        onChange={onChange}
        handleSubmit={handleSubmit}
        handleCloseModal={handleCloseModal}
        enableEditModal={enableEditModal}
        disableEditModal={disableEditModal}
        cadenas={cadenas}
        productos={productos}
        sucursales={sucursales}
      />
      <ModalBitacoraSuspension
        isOpenModal={isBitacoraOpen}
        handleOpenModal={handleBitacoraOpen}
        dataTable={dataTableBitacora}
        downloadBitacora={downloadBitacora}
      />
      <EnhancedTable
        table={{
          ...dataTable,
          columns: [
            ...dataTable.columns,
            {
              field: "nTodosProductos",
              headerName: "Productos suspendidos",
              type: "render",
              flex: 2,
              renderFunction: (row) =>
                row.nTodosProductos
                  ? "Todos los productos"
                  : `${JSON.parse(row.sProductos).length} producto(s)`,
            },
            {
              field: "nTodasSucursales",
              headerName: "Sucursales suspendidas",
              type: "render",
              flex: 2,
              renderFunction: (row) =>
                row.nTodasSucursales
                  ? "Todas las sucursales"
                  : `${JSON.parse(row.sSucursales).length} sucursal(es)`,
            },
          ],
        }}
        add={setAllowComponent("agregarSuspension")}
        onAddFunction={onClickAdd}
        buttons={[
          {
            label: "Ver",
            icon: <Visibility fontSize={"small"} />,
            onClick: (id, row) => openModal("view", row),
            showButton: setAllowComponent("verSuspension"),
          },
          {
            label: "Eliminar",
            icon: <Delete fontSize={"small"} />,
            onClick: (id, row) => triggerDeleteDialog(row),
            showButton: setAllowComponent("eliminarSuspension"),
          },
        ]}
        loading={isTableLoading}
        rowId={"nIdSuspension"}
        extraButtons={
          <Button
            variant="contained"
            onClick={() => handleBitacoraOpen(true)}
            startIcon={<History />}
            sx={{ ml: 1 }}
          >
            Bitacora
          </Button>
        }
      />
    </ThemeProvider>
  );
};
