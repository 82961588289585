import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  sendCodeDisplay: 'flex',
  changePasswordDisplay: 'none',
  usernameError: false,
  codeError: false,
  helperText: ''
};

export const forgotPasswordSlice = createSlice({
  name: 'forgotPassword',
  initialState,
  reducers: {
    sentCode: (state) => {
      state.sendCodeDisplay = 'none';
      state.changePasswordDisplay = 'flex';
    },
    setUsernameError: (state, action) => {
      state.usernameError = true;
      state.helperText = action.payload;
    },
    setCodeError: (state, action) => {
      state.codeError = true;
      state.helperText = action.payload;
    },
    reset: () => initialState
  }
});

export const { sentCode, setUsernameError, setCodeError, reset } =
  forgotPasswordSlice.actions;
