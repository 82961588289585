import {
  DataGrid,
  gridPageCountSelector,
  gridPageSelector,
  GridToolbarContainer,
  GridToolbarQuickFilter,
  useGridApiContext,
  useGridSelector,
  esES,
} from "@mui/x-data-grid";
import {
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  Grid,
  IconButton,
  Paper,
  Tooltip,
  Typography,
} from "@mui/material";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { Pagination } from "@mui/material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/DeleteOutlined";
import RouteIcon from "@mui/icons-material/Route";
import AddIcon from "@mui/icons-material/Add";
import AttachEmailIcon from "@mui/icons-material/AttachEmail";
import FactCheckOutlinedIcon from "@mui/icons-material/FactCheckOutlined";
import DownloadIcon from "@mui/icons-material/Download";
import WorkspacesIcon from '@mui/icons-material/Workspaces';
import { useState } from "react";
import { Stack } from "@mui/system";
import moment from "moment";

const iconTheme = createTheme(
  {
    status: {
      danger: "#e53e3e",
    },
    palette: {
      primary: {
        main: "#00609C",
        dark: "#053e85",
      },
      neutral: {
        main: "#00CD68",
        light: "#053e85",
        dark: "#053e85",
        contrastText: "#fff",
      },
    },
  },
  esES
);
export const Table = (props) => {
  const {
    table,
    title,
    subtitle,
    icon,
    hideActions = false,
    extraFilters = <></>,
  } = props;
  const [openDialog, setOpenDialog] = useState(false);
  const [item, setItem] = useState(0);

  const triggerDeleteDialog = (value) => {
    setItem(value);
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setItem(0);
    setOpenDialog(false);
  };

  const deleteItem = (innerItem) => {
    props.onDeleteFunction(innerItem ? innerItem : item);
    handleCloseDialog();
  };

  const CustomToolbar = () => {
    return (
      <GridToolbarContainer container sx={{ justifyContent: "space-between" }}>
        <GridToolbarContainer>
          {icon ? (
            <Grid
              item
              style={{
                color: "#00CD68",
                paddingRight: 15,
                fontWeight: "bold",
              }}
            >
              {icon}
            </Grid>
          ) : (
            <></>
          )}
          <Stack>
            {subtitle ? (
              <Typography variant="p" color={"neutral"}>
                {subtitle}
              </Typography>
            ) : (
              <></>
            )}

            <Typography variant="h6" color="primary" fontSize={24}>
              {title ? title : table.title}
            </Typography>
          </Stack>
        </GridToolbarContainer>

        <GridToolbarContainer sx={{ justifyContent: "flex-end" }}>
          <GridToolbarContainer>
            <Grid style={{ width: "200px" }}>{extraFilters}</Grid>
          </GridToolbarContainer>

          <GridToolbarContainer>
            <GridToolbarQuickFilter
              placeholder="Buscar..."
              variant="outlined"
              size="small"
            />
            {props.add ? (
              <Button
                variant="contained"
                startIcon={<AddIcon />}
                sx={{ marginLeft: 5 }}
                onClick={props.onAddFunction}
              >
                Agregar
              </Button>
            ) : (
              <></>
            )}
          </GridToolbarContainer>
        </GridToolbarContainer>
      </GridToolbarContainer>
    );
  };

  const buttons = {
    field: "crud",
    type: "actions",
    headerName: "Acciones",
    flex: 0.8,
    getActions: ({ id }) => {
      return getButtons(id);
    },
  };

  const getButtons = (id) => {
    let arrayButtons = [];

    if (props.view)
      arrayButtons.push(
        <Tooltip title="Ver" placement="top">
          <IconButton size="small" onClick={() => props.onViewFunction(id)}>
            <VisibilityIcon fontSize={"small"} />
          </IconButton>
        </Tooltip>
      );
    if (props.edit)
      arrayButtons.push(
        <Tooltip title="Editar" placement="top">
          <IconButton size="small" onClick={() => props.onEditFunction(id)}>
            <EditIcon fontSize={"small"} />
          </IconButton>
        </Tooltip>
      );
    if (props.delete)
      arrayButtons.push(
        <Tooltip title="Eliminar" placement="top">
          <IconButton
            size="small"
            onClick={() =>
              props.showDeleteAlert ? triggerDeleteDialog(id) : deleteItem(id)
            }
          >
            <DeleteIcon fontSize={"small"} />
          </IconButton>
        </Tooltip>
      );
    if (props.productos)
      arrayButtons.push(
        <Tooltip title="Productos" placement="top">
          <IconButton size="small" onClick={() => props.onProductosFunction(id)}>
            <WorkspacesIcon fontSize={"small"} />
          </IconButton>
        </Tooltip>
      );
    if (props.rutas)
      arrayButtons.push(
        <Tooltip title="Rutas" placement="top">
          <IconButton size="small" onClick={() => props.onRutasFunction(id)}>
            <RouteIcon fontSize={"small"} />
          </IconButton>
        </Tooltip>
      );
    if (props.conciliacion)
      arrayButtons.push(
        <Tooltip title="Conciliacion" placement="top">
          <IconButton
            size="small"
            onClick={() => props.onConciliacionFunction(id)}
          >
            <AttachEmailIcon fontSize={"small"} />
          </IconButton>
        </Tooltip>
      );
    if (props.permisos)
      arrayButtons.push(
        <Tooltip title="Permisos" placement="top">
          <IconButton
            size="small"
            onClick={() => props.onPermissionFunction(id)}
          >
            <FactCheckOutlinedIcon fontSize={"small"} />
          </IconButton>
        </Tooltip>
      );
    if (props.download)
      arrayButtons.push(
        <Tooltip title="Descargar" placement="top">
          <IconButton size="small" onClick={() => props.onDownloadFunction(id)}>
            <DownloadIcon fontSize={"small"} />
          </IconButton>
        </Tooltip>
      );

    return arrayButtons;
  };
  const CustomPagination = () => {
    const apiRef = useGridApiContext();
    const page = useGridSelector(apiRef, gridPageSelector);
    const pageCount = useGridSelector(apiRef, gridPageCountSelector);

    return (
      <Pagination
        color="neutral"
        count={pageCount}
        page={page + 1}
        onChange={(event, value) => apiRef.current.setPage(value - 1)}
      />
    );
  };
  let columnsArray = [];
  table.columns.map((column) => columnsArray.push(column));
  if (!hideActions) {
    columnsArray.push(buttons);
  }

  const formatData = (table) => {
    let rows = [];
    let obj = {};
    if (table.rows) {
      table.rows.map((row) => {
        obj = row;
        table.columns.map((column) => {
          obj = {
            ...obj,
            [column.field]: getFormatedValue(row[column.field], column.format),
          };
        });
        rows.push(obj);
      });
    }

    return rows;
  };

  const getFormatedValue = (value, type) => {
    if (type) {
      switch (type) {
        case "date":
          return moment(value.slice(0, -1)).format("YYYY-MM-DD");
        case "datetime":
          return moment(value.slice(0, -1)).format("YYYY-MM-DD hh:mm:ss");
        case "price":
          return value?.toLocaleString("en-US", {
            style: "currency",
            currency: "USD",
          });
      }
    }
    return value;
  };

  return (
    <>
      <ThemeProvider theme={iconTheme}>
        <Dialog
          open={openDialog}
          onClose={handleCloseDialog}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            {"¿Estas seguro/a de eliminar este registro? "}
          </DialogTitle>
          <DialogActions>
            <Button onClick={handleCloseDialog}>Cancelar</Button>
            <Button onClick={deleteItem} variant="contained">
              Quiero eliminar este registro
            </Button>
          </DialogActions>
        </Dialog>
        <Paper
          elevation={props.noShadow ? 0 : 3}
          style={{
            marginBottom: 10,
            background: "#ffffff",
            overflow: "hidden",
            height: "calc(100% - 50px)",
            padding: 10,
          }}
        >
          {table.columns.length ? (
            <DataGrid
              sx={{ border: "none" }}
              getRowId={(row) => row[props.rowId]}
              rows={formatData(table)}
              columns={columnsArray}
              pageSize={10}
              rowsPerPageOptions={[10]}
              components={{
                Toolbar: CustomToolbar,
                Pagination: CustomPagination,
              }}
              disableColumnMenu
              b
              checkboxSelection={props.checkboxSelection}
              onSelectionModelChange={props.onSelectionModelChange}
            />
          ) : (
            <></>
          )}
        </Paper>
      </ThemeProvider>
    </>
  );
};
