import { Close, PersonAdd, Person, Visibility, VisibilityOff, KeyboardArrowDown, KeyboardArrowUp, Info, RemoveRedEye } from '@mui/icons-material';
import { Alert, Button, Checkbox, Collapse, FormControlLabel, Grid, IconButton, TextField, Tooltip, Typography } from '@mui/material';
import { Box } from '@mui/system';
import { TextInput } from '../form/TextInput';
import { ModalBase } from './ModalBase';
import { regexEmail, regexPassword, regexTelefono } from '../../helpers/regexs';
import { SelectInput } from '../form/SelectInput';

import { useEffect, useState } from 'react';
import { BlueSwitch } from '../../helpers/stylesMaterial';
import { useAuthPage } from '../../hooks/useAuthPage';
import { ConfirmDialog } from './ConfirmDialog';
import EditIcon from '@mui/icons-material/Edit';
import { SearchInput } from '../form/SearchInput';
import { useSelector } from 'react-redux';

export const ModalCatalogo = (props) => {

  const {
    isOpenModal = false,
    modalType = "add",
    form = {},
    onChange,
    handleSubmit,
    handleCloseModal,
    enableEdit,
    disableEdit,
    selectedCatalogo
  } = props;

  const { sectoresCNBV, estatusCNBV, tareas, carpetasSFTP } = useSelector((state) => state.catalogo);

  const [showConfirmDialog, setShowConfirmDialog] = useState(false)
  const { setAllowComponent } = useAuthPage();


  const showTitleButton = () => {
    if (modalType === 'view' && setAllowComponent(selectedCatalogo.id, "editarCatalogo"))
      return <Button variant='contained' onClick={enableEdit} startIcon={<EditIcon />}>Editar</Button>;
  }

  const handleConfirmDialog = async () => {
    setShowConfirmDialog(false)
    //await handleSubmitChangeEstatus()
  }

  const showForm = () => {
    switch (selectedCatalogo?.id) {
      case "catalogoBancos":
        return <>
          <Grid item xs={12} sm={12}>
            <TextInput
              id="nIdBanco"
              name="nIdBanco"
              label="Identificador de banco"
              value={form.nIdBanco}
              onChangeRoot={onChange}
              disabled={modalType !== 'add'}
              type="number"
              require
            />
          </Grid>
          <Grid item xs={12} sm={12}>
            <TextInput
              id="sNombre"
              name="sNombre"
              label="Nombre del banco"
              value={form.sNombre}
              onChangeRoot={onChange}
              disabled={modalType === 'view'}
              require
            />
          </Grid>
          <Grid item xs={12} sm={12}>
            <TextInput
              id="sDescripcion"
              name="sDescripcion"
              label="Descripción"
              value={form.sDescripcion}
              onChangeRoot={onChange}
              disabled={modalType === 'view'}
              require
            />
          </Grid>
          <Grid item xs={12} sm={12}>
            <TextInput
              id="sClave"
              name="sClave"
              label="Clave"
              value={form.sClave}
              onChangeRoot={onChange}
              disabled={modalType === 'view'}
              require
            />
          </Grid>
          <Grid item xs={12} sm={12}>
            <div className="cadenas__blue-switch" >
              <p>¿Es corresponsal?</p>
              <BlueSwitch
                onChange={onChange}
                inputProps={{ "aria-label": "controlled" }}
                name="bCorresponsalia"
                checked={form.bCorresponsalia}
                value={form.bCorresponsalia}
                disabled={modalType === 'view'}
              />
              <p>{form.bCorresponsalia ? "Sí" : "No"}</p>
            </div>
          </Grid>
        </>
      case "catalogoGiros":
        return <>
          <Grid item xs={12} sm={12}>
            <TextInput
              id="sDescripcion"
              name="sDescripcion"
              label="Descripción"
              value={form.sDescripcion}
              onChangeRoot={onChange}
              disabled={modalType === 'view'}
              require
            />
          </Grid>
        </>
      case "catalogoIdentificadorCNBV":
        return <>
          <Grid item xs={12} sm={12}>
            <SearchInput
              options={sectoresCNBV ?? []}
              value={form.nIdSector}
              name={"nIdSector"}
              label={"Sector"}
              getOptionLabel={"sDescripcion"}
              getIndexLabel={"nIdSector"}
              onChange={onChange}
              disabled={modalType === 'view'}
            />
          </Grid>
          <Grid item xs={12} sm={12}>
            <TextInput
              id="sClave"
              name="sClave"
              label="Clave"
              type="number"
              value={form.sClave}
              onChangeRoot={onChange}
              disabled={modalType === 'view'}
              caracteres={3}
              require
            />
          </Grid>
          <Grid item xs={12} sm={12}>
            <TextInput
              id="sDescripcion"
              name="sDescripcion"
              label="Descripción"
              value={form.sDescripcion}
              onChangeRoot={onChange}
              disabled={modalType === 'view'}
              caracteres={150}
              require
            />
          </Grid>
          <Grid item xs={12} sm={12}>
            <TextInput
              id="sIdentificador"
              name="sIdentificador"
              label="Abreviación"
              value={form.sIdentificador}
              onChangeRoot={onChange}
              disabled={modalType === 'view'}
              caracteres={90}
              require
            />
          </Grid>
          <Grid item xs={12} sm={12}>
            <SearchInput
              options={estatusCNBV ?? []}
              value={form.nIdEstatusCNBV}
              name={"nIdEstatusCNBV"}
              label={"Estatus"}
              getOptionLabel={"sDescripcion"}
              getIndexLabel={"nIdEstatusCNBV"}
              onChange={onChange}
              disabled={modalType === 'view'}
            />
          </Grid>
        </>
      case "catalogoSectoresCNBV":
        return <>
          <Grid item xs={12} sm={12}>
            <TextInput
              id="nIdSector"
              name="nIdSector"
              label="Identificador del sector"
              value={form.nIdSector}
              onChangeRoot={onChange}
              disabled={modalType !== 'add'}
              type="number"
              require
            />
          </Grid>
          <Grid item xs={12} sm={12}>
            <TextInput
              id="sDescripcion"
              name="sDescripcion"
              label="Descripción"
              value={form.sDescripcion}
              onChangeRoot={onChange}
              disabled={modalType === 'view'}
              caracteres={90}
              require
            />
          </Grid>
        </>
      case "catalogoCuentasContables":
        return <>
          <Grid item xs={12} sm={12}>
            <TextInput
              id="sNombre"
              name="sNombre"
              label="Nombre de la cuenta contable"
              value={form.sNombre}
              onChangeRoot={onChange}
              disabled={modalType === 'view'}
              require
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <SearchInput
              options={[{ text: "Ambos", value: 2 }, { text: "Comisionista", value: 0 }, { text: "EFR", value: 1 }]}
              value={form.nTipo}
              name={"nTipo"}
              label={"Tipo de cuenta"}
              getOptionLabel={"text"}
              getIndexLabel={"value"}
              onChange={onChange}
              disabled={modalType === 'view'}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <SearchInput
              options={[{ text: "Cash In", value: 1 }, { text: "Cash Out", value: 0 }]}
              value={form.nFlujoImporte}
              name={"nFlujoImporte"}
              label={"Flujo del importe"}
              getOptionLabel={"text"}
              getIndexLabel={"value"}
              onChange={onChange}
              disabled={modalType === 'view'}
            />
          </Grid>

          <Grid item xs={12} sm={12}>
            <TextInput
              id="nCuenta"
              name="nCuenta"
              label="Cuenta contable"
              value={form.nCuenta}
              onChangeRoot={onChange}
              disabled={modalType === 'view'}
              caracteres={12}
              type='number'
              require
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextInput
              id="nInicio"
              name="nInicio"
              label="Número de inicio"
              value={form.nInicio}
              onChangeRoot={onChange}
              disabled={modalType === 'view'}
              require
              type='number'
              description="Número en el que se inicia la cuenta contable"
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <div className="cadenas__blue-switch" >
              <p>¿Es dinámica?
                <Tooltip title={"Si es dinámica, se le concatena el número contable de la EFR o comisionista."}>
                  <Info fontSize='13px' color='primary' />
                </Tooltip>
              </p>
              <BlueSwitch
                onChange={onChange}
                inputProps={{ "aria-label": "controlled" }}
                name="nDinamica"
                checked={form.nDinamica}
                value={form.nDinamica}
                disabled={modalType === 'view'}
              />
              <p>{form.nDinamica ? "Sí" : "No"}</p>
            </div>
          </Grid>
        </>
      case "catalogoDocumentos":
        return <>
          <Grid item xs={12} sm={12}>
            <TextInput
              id="sNombreDocumento"
              name="sNombreDocumento"
              label="Nombre del documento"
              value={form.sNombreDocumento}
              onChangeRoot={onChange}
              disabled={modalType === 'view'}
              require
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextInput
              id="sAbrevDoc"
              name="sAbrevDoc"
              label="Abreviación"
              value={form.sAbrevDoc}
              onChangeRoot={onChange}
              disabled={modalType === 'view'}
              require
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <SearchInput
              options={[{ text: "Ambos", value: 0 }, { text: "Comisionista", value: 1 }, { text: "EFR", value: 2 }]}
              value={form.nTipo}
              name={"nTipo"}
              label={"Tipo de documento"}
              getOptionLabel={"text"}
              getIndexLabel={"value"}
              onChange={onChange}
              disabled={modalType === 'view'}
            />
          </Grid>
        </>
      case "catalogoMotivosBaja":
        return <>
          <Grid item xs={12} sm={12}>
            <TextInput
              id="sDescripcion"
              name="sDescripcion"
              label="Descripción del motivo de baja"
              value={form.sDescripcion}
              onChangeRoot={onChange}
              disabled={modalType === 'view'}
              require
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextInput
              id="nIdCatComisionistaBaja"
              name="nIdCatComisionistaBaja"
              label="Identificador"
              value={form.nIdCatComisionistaBaja}
              onChangeRoot={onChange}
              disabled={modalType === 'view'}
              type='number'
              require
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextInput
              id="nIdCatComisionistaBajaIFPE"
              name="nIdCatComisionistaBajaIFPE"
              label="Identificador IFPE"
              value={form.nIdCatComisionistaBajaIFPE}
              onChangeRoot={onChange}
              disabled={modalType === 'view'}
              caracteres={3}
              require
            />
          </Grid>
        </>
      case "catalogoProcesos":
        return <>
          <Grid item xs={12} sm={12}>
            <TextInput
              id="sDescripcion"
              name="sDescripcion"
              label="Nombre"
              value={form.sDescripcion}
              onChangeRoot={onChange}
              disabled={modalType === 'view'}
              require
            />
          </Grid>
          <Grid item xs={12}>
            <SearchInput
              options={[{ text: "Contactos y usuarios interno", value: 0 }, { text: "Contactos", value: 1 }, { text: "Usuario interno", value: 2 }]}
              value={form.nTipo}
              name={"nTipo"}
              label={"Tipo de proceso"}
              getOptionLabel={"text"}
              getIndexLabel={"value"}
              onChange={onChange}
              disabled={modalType === 'view'}
            />
          </Grid>
          <Grid item xs={12}>
            <SearchInput
              options={[...tareas ?? []].filter(t => t.nNotificacion)}
              value={form.sTareas}
              name={"sTareas"}
              label={"Tarea(s) asignada(s)"}
              getOptionLabel={"sNombre"}
              getExtraData={"sDescripcion"}
              getIndexLabel={"nIdTarea"}
              disabled={modalType === 'view'}
              onChange={onChange}
              multiple
            />
          </Grid>

          <Grid item xs={12} container alignItems={"center"}>
            {form.nTipo !== 2 && <>
              <p>¿Es obligatorio el proceso en el formulario de contactos?</p>
              <BlueSwitch
                onChange={(e) => onChange({ nRequerido: e.target.checked ? 1 : 0 })}
                inputProps={{ "aria-label": "controlled" }}
                name="nRequerido"
                checked={form.nRequerido}
                value={form.nRequerido}
              />
              <p>{form.nRequerido ? "Sí" : "No"}</p>
            </>}
          </Grid>
        </>
      case "catalogoArchivosSFTP":
        return <>
          <Grid item xs={12} sm={12}>
            <TextInput
              id="sNombreArchivo"
              name="sNombreArchivo"
              label="Nombre del archivo"
              value={form.sNombreArchivo}
              onChangeRoot={onChange}
              disabled={modalType === 'view'}
              require
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <SearchInput
              options={[{ text: "EFR", value: 1 }, { text: "Comisionista", value: 2 }]}
              value={form.nIdEntidadParticipante}
              name={"nIdEntidadParticipante"}
              label={"Entidad participante"}
              getOptionLabel={"text"}
              getIndexLabel={"value"}
              onChange={onChange}
              disabled={modalType === 'view'}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <SearchInput
              options={[...tareas ?? []]}
              value={form.nIdTarea}
              name={"nIdTarea"}
              label={"Tarea que deposita o recupera el archivo"}
              getOptionLabel={"sNombre"}
              getExtraData={"sDescripcion"}
              getIndexLabel={"nIdTarea"}
              disabled={modalType === 'view'}
              onChange={onChange}
            />
          </Grid>
          <Grid item xs={12}>
            <SearchInput
              options={[{ nIdCarpetaSFTP: 0, sCarpeta: "Agregar carpeta..." }, ...carpetasSFTP ?? []]}
              value={form.nIdCarpetaSFTP}
              name={"nIdCarpetaSFTP"}
              label={"Carpeta en la que se deposita"}
              getOptionLabel={"sCarpeta"}
              getIndexLabel={"nIdCarpetaSFTP"}
              disabled={modalType === 'view'}
              onChange={onChange}
            />
          </Grid>
          {form.nIdCarpetaSFTP === 0 ? <>
            <Grid item xs={12}>
              <TextInput
                id="sCarpeta"
                name="sCarpeta"
                label="Carpeta"
                value={form.sCarpeta}
                onChangeRoot={onChange}
                disabled={modalType === 'view'}
                require
              />
            </Grid>
            <Grid item xs={12} sm={6} container alignItems={"center"}>
              <p>Permisos de lectura</p>
              <BlueSwitch
                onChange={(e) => onChange({ bLectura: e.target.checked ? 1 : 0 })}
                inputProps={{ "aria-label": "controlled" }}
                name="bLectura"
                checked={form.bLectura}
                value={form.bLectura}
              />
              <p>{form.bLectura ? "Sí" : "No"}</p>
            </Grid>
            <Grid item xs={12} sm={6} container alignItems={"center"}>
              <p>Permisos de escritura</p>
              <BlueSwitch
                onChange={(e) => onChange({ bEscritura: e.target.checked ? 1 : 0 })}
                inputProps={{ "aria-label": "controlled" }}
                name="bEscritura"
                checked={form.bEscritura}
                value={form.bEscritura}
              />
              <p>{form.bEscritura ? "Sí" : "No"}</p>
            </Grid>
          </> : <></>}

          <Grid item xs={12} sm={12}>
            <TextInput
              id="sComentario"
              name="sComentario"
              label="Comentario"
              value={form.sComentario}
              onChangeRoot={onChange}
              disabled={modalType === 'view'}
              require
            />
          </Grid>
          <Grid item xs={12} sm={6} container alignItems={"center"}>
            <p>¿Es generado por SofiPay?</p>
            <BlueSwitch
              onChange={(e) => onChange({ bGeneradoPorSofiPay: e.target.checked ? 1 : 0 })}
              inputProps={{ "aria-label": "controlled" }}
              name="bGeneradoPorSofiPay"
              checked={form.bGeneradoPorSofiPay}
              value={form.bGeneradoPorSofiPay}
            />
            <p>{form.bGeneradoPorSofiPay ? "Sí" : "No"}</p>
          </Grid>
          <Grid item xs={12} sm={6} container alignItems={"center"}>
            <p>¿El archivo es obligatorio?</p>
            <BlueSwitch
              onChange={(e) => onChange({ bObligado: e.target.checked ? 1 : 0 })}
              inputProps={{ "aria-label": "controlled" }}
              name="bObligado"
              checked={form.bObligado}
              value={form.bObligado}
            />
            <p>{form.bObligado ? "Sí" : "No"}</p>
          </Grid>
        </>
      case "catalogoConexionesSP":
        return <>
          {modalType === 'edit' ?
            <Grid item xs={12}>
              <Alert severity="warning">Cualquier ajuste en las conexiones SofiPay debe de ser coordinado con el equipo de infraestructura</Alert>
            </Grid>
            : <></>
          }
          <Grid item xs={12}>
            <TextInput
              id="sNombreConexion"
              name="sNombreConexion"
              label="Nombre de la conexión"
              value={form.sNombreConexion}
              onChangeRoot={onChange}
              disabled={modalType === 'view'}
              require
            />
          </Grid>
          <Grid item sm={6} xs={12}>
            <TextInput
              id="sHost"
              name="sHost"
              label="Host"
              value={form.sHost}
              onChangeRoot={onChange}
              disabled={modalType === 'view'}
              require
            />
          </Grid>
          <Grid item sm={6} xs={12}>
            <TextInput
              id="nPort"
              name="nPort"
              label="Puerto"
              value={form.nPort}
              type='number'
              onChangeRoot={onChange}
              disabled={modalType === 'view'}
              require
            />
          </Grid>
          <Grid item sm={6} xs={12}>
            <TextInput
              id="sUser"
              name="sUser"
              label="Usuario"
              value={form.sUser}
              onChangeRoot={onChange}
              disabled={modalType === 'view'}
              require
            />
          </Grid>
          <Grid item sm={6} xs={12} container alignItems={"center"}>
            <Grid item xs={12}>
              <TextInput
                id="sPassword"
                name={modalType === 'view' ? "sPassword" : "sInnerPassword"}
                label="Contraseña"
                value={modalType === 'view' ? form.sPassword : form.sInnerPassword}
                type={form.showPassword && modalType === 'view' ? "text" : "password"}
                onChangeRoot={onChange}
                disabled={modalType === 'view'}
                InputProps={{
                  endAdornment: (setAllowComponent(selectedCatalogo.id, "verPassword") && modalType === 'view' ?
                    <IconButton
                      onClick={() => onChange({ showPassword: !form.showPassword })}
                    >
                      {form.showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                    : <></>)
                }}
              />
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <TextInput
              id="sComentario"
              name="sComentario"
              label="Comentarios"
              value={form.sComentario}
              onChangeRoot={onChange}
              disabled={modalType === 'view'}
              require
            />
          </Grid>
        </>
      case "catalogoTiposActor":
        return <>
          <Grid item xs={12} sm={12}>
            <TextInput
              id="sNombre"
              name="sNombre"
              label="Nombre"
              value={form.sNombre}
              onChangeRoot={onChange}
              disabled={modalType === 'view'}
              require
            />
          </Grid>
          <Grid item xs={12} sm={12}>
            <TextInput
              id="sDescripcion"
              name="sDescripcion"
              label="Descripción"
              value={form.sDescripcion}
              onChangeRoot={onChange}
              disabled={modalType === 'view'}
              require
            />
          </Grid>
        </>
    }
  }


  return (<>
    <ConfirmDialog
      isOpenDialog={showConfirmDialog}
      onCloseDialog={() => setShowConfirmDialog(false)}
      onConfimDialog={handleConfirmDialog}
      message={`¿Estas seguro/a de ${form.nIdEstatus === 1 ? 'deshabilitar' : 'habilitar'} al usuario?`}
      textButtonConfirm="Sí, si estoy seguro/a."
    />
    <ModalBase open={isOpenModal} title="" style={{ width: '100%', maxWidth: "600px", height: 'auto' }}>
      <Box
        sx={{ width: '100%', height: '100%', display: 'flex', flexDirection: 'column' }}
      >
        <TitleModal icon={selectedCatalogo.iconTitle} title={selectedCatalogo.name} typeOperation={modalType} extraButton={showTitleButton()} />

        <Grid container spacing={2} sx={{ mt: 1, mb: 2, maxHeight: "calc(100vh - 250px)", overflow: 'auto' }}>
          {showForm()}
        </Grid>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            width: '100%',
            justifyContent: 'end'
          }}
        >
          <Button onClick={modalType === "edit" ? disableEdit : handleCloseModal} color="primary">
            {modalType === "edit" ? "Cancelar" : "Cerrar"}
          </Button>
          {modalType !== 'view' &&
            <Button
              onClick={() => handleSubmit()}
              color="primary"
              variant='contained'
            >
              Guardar
            </Button>
          }
        </div>
      </Box>
    </ModalBase>
  </>
  );
};

const TitleModal = ({ icon, title, typeOperation, extraButton }) => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        width: '100%',
        marginBottom: '10px',
        justifyContent: 'space-between'
      }}
    >
      <div style={{ display: 'flex', justifyContent: "space-between" }}>
        {icon}

        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <Typography
            variant="p"
            sx={{
              marginLeft: '15px',
              fontSize: '14px',
              marginBottom: '4px'
            }}
          >
            {typeOperation === 'add' ? 'Agregar registro' : typeOperation === 'edit' ? 'Editar registro' : 'Ver registro'}
          </Typography>
          <Typography
            variant="p"
            sx={{
              marginLeft: '15px',
              color: '#00609C',
              fontSize: '20px'
            }}
          >
            {title}
          </Typography>
        </div>
      </div>
      <div>
        {extraButton}
      </div>
    </Box>
  );
};

