import { Grid, TextField } from "@mui/material";

import { styled } from "@mui/material/styles";
import { useEffect, useState } from "react";

const initalValues = { error: false, msg: "" };

const ValueTextField = styled(TextField)(({ theme }) => ({
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderRight: "none",
    },
    "&:hover fieldset": {
      borderColor: "rgba(0, 0, 0, 0.23)",
      borderRight: "none",
    },
    "&.Mui-focused fieldset": {
      borderColor: "rgba(0, 0, 0, 0.23)",
      borderRight: "none",
    },
  },
}));

const IvaTextField = styled(TextField)(({ theme }) => ({
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderRight: "none",
      borderLeft: "none",
    },
    "&:hover fieldset": {
      borderColor: "rgba(0, 0, 0, 0.23)",
      borderRight: "none",
      borderLeft: "none",
    },
    "&.Mui-focused fieldset": {
      borderColor: "rgba(0, 0, 0, 0.23)",
      borderRight: "none",
      borderLeft: "none",
    },
  },
}));

const SubtotalTextField = styled(TextField)(({ theme }) => ({
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderLeft: "none",
    },
    "&:hover fieldset": {
      borderColor: "rgba(0, 0, 0, 0.23)",
      borderLeft: "none",
    },
    "&.Mui-focused fieldset": {
      borderColor: "rgba(0, 0, 0, 0.23)",
      borderLeft: "none",
    },
  },
}));

export const IvaInput = ({
  caracteres = "",
  disabled = false,
  erroMsg = "",
  id,
  name,
  label,
  onChangeRoot,
  size = "standard",
  style = {},
  type = "number",
  value = "",
  variant = "outlined",
  require = false,
  expresionRegular = "",
  startAdornment = false,
  endAdornment = false,
  ivaValue = 0,
  disableLabels = false
}) => {
  const [error, setError] = useState(initalValues);
  const [innerValue, setInnerValue] = useState(value);
  const [iva, setIva] = useState(value * 0.16);
  const [subtotal, setSubtotal] = useState(value * 0.84);

  useEffect(()=>{
    const ivaDecimal = ivaValue / 100;

    setInnerValue(value)
    setIva(roundNumber(value - (value / (1 + ivaDecimal))))
    setSubtotal(roundNumber(value / (1 + ivaDecimal)))
  },[value, ivaValue])

  const onChange = (e, type) => {
    const inputValue = e.target.value;

    if (caracteres !== "" && inputValue.length > parseInt(caracteres)) return;
    let value = 0;
    const ivaDecimal = ivaValue / 100;
    switch (type) {
      case "value":
        value = parseFloat(inputValue);
        setInnerValue(value);
        setIva(roundNumber(inputValue - (inputValue / (1 + ivaDecimal))));
        setSubtotal(roundNumber(inputValue / (1 + ivaDecimal)));
        break;

      case "iva":
        value = roundNumber((inputValue / ivaDecimal)+ parseFloat(inputValue));
        setInnerValue(value);
        setIva(parseFloat(inputValue));
        setSubtotal(roundNumber(inputValue / ivaDecimal));
        break;

      case "subtotal":
        value = roundNumber(inputValue * (1 + ivaDecimal));
        setInnerValue(value);
        setIva(roundNumber(value - inputValue));
        setSubtotal(parseFloat(inputValue));
        break;
      default:
        break;
    }
    onChangeRoot({ [name]: value });
  };

  const roundNumber = (number) =>{
    return(Number((number ? parseFloat(number) : 0).toFixed(2)))
  }

  const validateInput = (inputValue) => {
    if (require && inputValue === "") {
      setError({ ...error, msg: "Requerido", error: true });
      return;
    }

    if (inputValue < 0) {
      setError({ ...error, msg: "Valor negativo", error: true });
      return;
    }

    if (
      expresionRegular !== "" &&
      inputValue !== "" &&
      !expresionRegular.test(inputValue)
    ) {
      setError({ ...error, msg: erroMsg, error: true });
      return;
    }

    setError(initalValues);
  };

  return (
    <Grid container sx={{ width: "100%" }}>
      <Grid item xs={4}>
        <ValueTextField
          id={id}
          name={name}
          label={disableLabels ? ""  :label}
          variant={variant}
          size={size}
          value={innerValue}
          style={style}
          type={type}
          onChange={(e) => onChange(e, "value")}
          autoComplete="off"
          disabled={disabled}
          error={error.error}
          helperText={error.error ? error.msg : ""}
          onKeyUp={(e) => validateInput(value)}
          onBlur={(e) => validateInput(value)}
          InputProps={{
            inputProps: { min: 0 },
            startAdornment,
            endAdornment,
            style: {
              borderTopRightRadius: 0,
              borderBottomRightRadius: 0,
              borderRight: "none",
              paddingRight: 0,
            },
          }}
        />
      </Grid>
      <Grid item xs={4}>
        <IvaTextField
          id={id}
          name={name}
          label={disableLabels ? ""  :"IVA"}
          variant={variant}
          size={size}
          value={iva}
          style={style}
          type={type}
          onChange={(e) => onChange(e, "iva")}
          autoComplete="off"
          disabled={disabled}
          error={error.error}
          helperText={error.error ? error.msg : ""}
          onKeyUp={(e) => validateInput(value)}
          onBlur={(e) => validateInput(value)}
          InputProps={{
            inputProps: { min: 0 },
            startAdornment,
            endAdornment,
            style: {
              borderRadius: 0,
              borderRight: "none",
              borderLeft: "none",
              paddingRight: 0,
            },
          }}
        />
      </Grid>
      <Grid item xs={4}>
        <SubtotalTextField
          id={id}
          name={name}
          label={disableLabels ? ""  :"Subtotal"}
          variant={variant}
          size={size}
          value={subtotal}
          style={style}
          type={type}
          onChange={(e) => onChange(e, "subtotal")}
          autoComplete="off"
          disabled={disabled}
          error={error.error}
          helperText={error.error ? error.msg : ""}
          onKeyUp={(e) => validateInput(value)}
          onBlur={(e) => validateInput(value)}
          InputProps={{
            inputProps: { min: 0 },
            startAdornment,
            endAdornment,
            style: {
              borderTopLeftRadius: 0,
              borderBottomLeftRadius: 0,
              borderLeft: "none",
              paddingRight: 0,
            },
          }}
        />
      </Grid>
    </Grid>
  );
};
