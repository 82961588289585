import { API } from 'aws-amplify';
import { useDispatch } from 'react-redux';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { openConciliacion } from '../slices/ui/uiSlice';
import {
  setConfigId,
  setConfigTipo
} from '../slices/configConciliacion/configConciliacionSlice';
import { useModal } from './useModal';
import { useAuthPage } from './useAuthPage';
//import { startConfigConexion } from '../slices/configConciliacion/conciliacionThunk';
import { loading } from '../slices/ui/uiSlice';
import { startLoadCadenas, startLoadEmisores } from '../slices/catalogo/catalgoThunk';

let initialForm = {
  nIdEmisor: 0,
  nIdCadena: 0,
  nIdConfConexion: 0,
  metodoEntrega: '1',
  sHost: '',
  nPort: '',
  sPassword: '',
  sFolder: '',
  sUser: '',
  isActiveEmail: false,
  sCorreo: ''
};

let Buttons = {
  saveButton: true,
  editButton: false
};

export const useModalConciliacion = () => {
  const { setLoaded } = useAuthPage();
  const { cadenas, emisores } = useSelector((state) => state.catalogo);
  const { nIdConfConexion, tipoConciliacion } = useSelector(
    (state) => state.configConciliacion
  );
  const { closeModalConciliacion, setOpenToast } = useModal();
  const dispatch = useDispatch();

  const [form, setForm] = useState(initialForm);
  const [display, setDisplay] = useState(Buttons);
  const [selectedEntity, setSelectedEntity] = useState({})
  
  const openModalConciliacion = (id, tipo) => {
    dispatch(setConfigId(id));
    dispatch(setConfigTipo(tipo));
    dispatch(openConciliacion());
  };
  const searchConciliacion = async () => {
    switch (tipoConciliacion) {
      case 'cadena':
        const dataCadena = cadenas.rows.find(
          (cadena) => cadena.nIdCadena === nIdConfConexion
        );
        setSelectedEntity(dataCadena)
        if (dataCadena.nIdConfConexion > 0) {
          setConciliacionData(dataCadena);
          setDisplayEdit();
        } else {
          setForm({ ...form, nIdCadena: nIdConfConexion });
        }
        break;
      case 'emisor':
        const dataEmisor = emisores.rows.find(
          (emisor) => emisor.nIdEmisor === nIdConfConexion
        );
        setSelectedEntity(dataEmisor)
        if (dataEmisor.nIdConfConexion > 0) {
          setConciliacionData(dataEmisor);
          setDisplayEdit();
        } else {
          setForm({ ...form, nIdEmisor: nIdConfConexion });
        }
        break;
      default:
        break;
    }
  };

  const onChange = async (e) => {
    const inputName = e.target.name;
    const inputValue = e.target.value;
    setForm({ ...form, [inputName]: inputValue });
  };

  const onSubmit = () => {
    console.log(form);
    switch (tipoConciliacion) {
      case 'cadena':
        apiCadenas();
        break;
      case 'emisor':
        apiEmisores();
        break;
      default:
        break;
    }
  };

  const setConciliacionData = (dataCadena) => {
    switch (tipoConciliacion) {
      case 'cadena':
        const cadenaForm = {
          nIdCadena: nIdConfConexion,
          nIdConfConexion: dataCadena.nIdConfConexion,
          metodoEntrega: 1,
          sHost: dataCadena.sHost,
          nPort: dataCadena.nPort,
          sPassword: dataCadena.sPassword,
          sFolder: dataCadena.sFolder,
          sUser: dataCadena.sUser
        };
        setForm(cadenaForm);
        break;
      case 'emisor':
        const emisorForm = {
          nIdEmisor: nIdConfConexion,
          nIdConfConexion: dataCadena.nIdConfConexion,
          metodoEntrega: 1,
          sHost: dataCadena.sHost,
          nPort: dataCadena.nPort,
          sPassword: dataCadena.sPassword,
          sFolder: dataCadena.sFolder,
          sUser: dataCadena.sUser
        };
        setForm(emisorForm);
        break;
      default:
        break;
    }
  };

  const setDisplayEdit = () => {
    setDisplay({
      saveButton: false,
      editButton: true
    });
  };

  const onEdit = () => {
    setDisplay({
      saveButton: true,
      editButton: false
    });
  };

  const apiCadenas = async () => {
    dispatch(loading(true));
    const apiName = 'cadenas';
    const path = '/cadenas/conexion';
    const myInit = {
      headers: {},
      response: false, //OPTIONAL
      body: {
        nIdConfConexion: form.nIdConfConexion,
        sHost: form.sHost,
        nPort: form.nPort,
        sUser: form.sUser,
        sPassword: form.sPassword,
        sFolder: form.sFolder,
        nIdCadena: form.nIdCadena
      }
    };
    try {
      const response = form.nIdConfConexion ? await API.put(apiName, path, myInit) : await API.post(apiName, path, myInit);
      const res = response?.data?.length ? response.data[0] : {}
      if (res?.nCodigo === 0) {
        closeModalConciliacion();
        await dispatch(startLoadCadenas())
      }
      setOpenToast(res?.nCodigo !== 0,  res?.sMensaje ? res.sMensaje: "Hubo un error, intente de nuevo más tarde.");
    } catch (error) {
      console.log(error);
    } finally {
      dispatch(loading(false));
    }
  };

  const apiEmisores = async () => {
    dispatch(loading(true));
    const apiName = 'emisores';
    const path = '/emisores/conexion';
    const myInit = {
      headers: {},
      response: false, //OPTIONAL
      body: {
        nIdConfConexion: form.nIdConfConexion,
        sHost: form.sHost,
        nPort: form.nPort,
        sUser: form.sUser,
        sPassword: form.sPassword,
        sFolder: form.sFolder,
        nIdEmisor: form.nIdEmisor
      }
    };
    try {
      const response = form.nIdConfConexion ? await API.put(apiName, path, myInit) : await API.post(apiName, path, myInit);
      if (response?.nCodigo === 0) {
        closeModalConciliacion();
        await dispatch(startLoadEmisores())
      }
      setOpenToast(response?.nCodigo !== 0,  response?.sMensaje ? response.sMensaje: "Hubo un error, intente de nuevo más tarde.");
    } catch (error) {
      console.log(error);
    } finally {
      dispatch(loading(false));
    }
  };

  return {
    openModalConciliacion,
    form,
    setForm,
    searchConciliacion,
    nIdConfConexion,
    onChange,
    onSubmit,
    display,
    onEdit,
    selectedEntity
  };
};
