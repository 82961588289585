import { useDispatch } from 'react-redux';
import { setActiveSection } from '../slices/menu/menuSlice';
import { useSelector } from 'react-redux';
import { useEffect } from 'react';

export const useMenu = () => {
  const dispatch = useDispatch();
  const { permisos } = useSelector((state) => state.user);
  const { titulos } = useSelector((state) => state.user);

  const { page } = useSelector((state) => state.authPage);

  useEffect(() => {
    setNameSection(page)
  }, [page])
  

  const setNameSection = (name) => {
    permisos.forEach((permiso, index) => {
      if (name === permiso.nombre) {
        dispatch(setActiveSection(permiso.titulo));
      }
    });
  };

  return {
    setNameSection
  };
};
