import { Box, Button, Typography } from '@mui/material';
// import { BlueSwitch } from '../helpers/stylesMaterial';
import { SelectInput } from './form/SelectInput';
import { TextInput } from './form/TextInput';
import { useModalConciliacion } from '../hooks/useModalConciliacion';
import { useModal } from '../hooks/useModal';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { CircularProgress } from '@mui/material';
import { ModalBase } from './modal/ModalBase';
import '../styles/cadenas.css';
import { CompareArrows } from '@mui/icons-material';
import { useAuthPage } from '../hooks/useAuthPage';

// const gridSpan = (span = 2) => ({
//   gridColumn: `span ${span}`
// });

export const FormConciliacion = () => {
  const { isLoading } = useSelector((state) => state.ui);
  const { form, setForm, searchConciliacion, onChange, onSubmit, display, onEdit, selectedEntity } =
    useModalConciliacion();
  const { closeModalConciliacion } = useModal();
  const [isAllowdEdition, setIsAllowdEdition] = useState(false)

  const { setAllowComponent } = useAuthPage();


  const onChangeInput = async (value) => {
    setForm({ ...form, ...value });
  };

  useEffect(() => {
    setIsAllowdEdition(setAllowComponent("guardarConciliacion"))
    searchConciliacion();
    // eslint-disable-next-line
  }, []);

  const isActiveSave = () => {
    if (
      (form.metodoEntrega &&
        form.sHost &&
        form.nPort &&
        form.sUser &&
        form.sPassword &&
        form.sFolder)
    ) {
      return false;
    } else {
      return true;
    }
  };

  return (
    <>
      <TitleModal title={selectedEntity?.sNombreComercial} />
      <div
        className="cadenas__inputs-container cadenas__grid-2"
        style={{ marginTop: '15px' }}
      >
        <SelectInput
          disabled={display.editButton || !isAllowdEdition}
          id="metodoEntrega"
          name="metodoEntrega"
          onChange={onChange}
          label="Método de entrega"
          data={[
            {
              value: '1',
              text: 'SFTP'
            }
          ]}
          value={'1'}
        />
        <TextInput
          disabled={display.editButton || !isAllowdEdition}
          id="sHost"
          name="sHost"
          label="Host"
          value={form.sHost}
          onChangeRoot={onChangeInput}
          fullWidth
          require
        />
        <TextInput
          disabled={display.editButton || !isAllowdEdition}
          id="nPort"
          name="nPort"
          label="Puerto"
          value={`${form.nPort}`}
          onChangeRoot={onChangeInput || !isAllowdEdition}
          fullWidth
          require
        />
        <TextInput
          disabled={display.editButton || !isAllowdEdition}
          id="sUser"
          name="sUser"
          label="Usuario"
          value={form.sUser}
          onChangeRoot={onChangeInput}
          fullWidth
          require
        />
        <TextInput
          disabled={display.editButton || !isAllowdEdition}
          id="sPassword"
          name="sPassword"
          label="Contraseña"
          value={form.sPassword}
          type="password"
          onChangeRoot={onChangeInput}
          fullWidth
          require
        />
        <TextInput
          disabled={display.editButton || !isAllowdEdition}
          id="sFolder"
          name="sFolder"
          label="Dirección del folder remoto"
          value={form.sFolder}
          onChangeRoot={onChangeInput}
          fullWidth
          require
        />
      </div>
      {/* <div className="cadenas__blue-switch" style={{ marginTop: '15px' }}>
        <BlueSwitch
          onChange={onChange}
          inputProps={{ 'aria-label': 'controlled' }}
          name="isActiveEmail"
          checked={form.isActiveEmail}
          value={form.isActiveEmail}
        />
        <p>¿Agregar correo?</p>
      </div> */}
      {/* <Collapse in={form.isActiveEmail}>
        <div
          className="cadenas__inputs-container cadenas__grid-2"
          style={{ marginTop: '15px' }}
        >
          <div style={{ ...gridSpan() }}>
            <TextInput
              disabled={display.editButton}
              size="small"
              id="sCorreo"
              name="sCorreo"
              label="Correo"
              value={form.sCorreo}
              onChangeRoot={onChangeInput}
              fullWidth
              expresionRegular={regexEmail}
              erroMsg="Correo inválido"
            />
          </div>
        </div>
      </Collapse> */}
      <Box
        sx={{
          textAlign: 'end',
          marginTop: '15px'
        }}
      >
        <Button
          color="neutral"
          onClick={() => closeModalConciliacion()}
          sx={{ marginRight: '10px', display: '' }}
        >
          cancelar
        </Button>
        {isAllowdEdition ? <><Button
          disabled={false}
          variant="contained"
          color="neutral"
          onClick={onEdit}
          sx={{ marginRight: '10px', display: display.editButton ? '' : 'none' }}
        >
          Editar
        </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={onSubmit}
            sx={{ display: display.editButton ? 'none' : '' }}
            disabled={isActiveSave()}
          >
            Guardar
          </Button>
        </> : <></>}

      </Box>
    </>
  );
};


const TitleModal = ({ title, typeOperation, extraButton }) => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        width: '100%',
        marginBottom: '10px',
        justifyContent: 'space-between', mb: 4
      }}
    >
      <div style={{ display: 'flex', justifyContent: "space-between" }}>

        <CompareArrows fontSize={'large'} sx={{ color: '#00CD68', fontSize: '45px' }} />
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <Typography
            variant="p"
            sx={{
              marginLeft: '15px',
              fontSize: '14px',
              marginBottom: '4px'
            }}
          >
            {'Configuración de conciliación'}
          </Typography>
          <Typography
            variant="p"
            sx={{
              marginLeft: '15px',
              color: '#00609C',
              fontSize: '20px'
            }}
          >
            {title}
          </Typography>
        </div>
      </div>
      <div>
      </div>
    </Box>
  );
};