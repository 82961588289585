import { useSelector, useDispatch } from "react-redux";
import { useEffect, useState } from "react";
import { usuarioForm } from "../helpers/forms";
import { getUsuariosData, insertUsuario, updateUsuario, updateUsuarioBaja, updateUsuarioEstatus } from "../slices/usuarios/usuariosThunk";
import { startLoadPermisosData, startLoadProcesos } from "../slices/catalogo/catalgoThunk";
import { useModal } from "./useModal";

export const useUsuariosTable = () => {
  const [form, setForm] = useState(usuarioForm);
  const { dataTable } = useSelector((state) => state.usuarios);
  const { perfiles, opciones, secciones, procesos } = useSelector((state) => state.catalogo);
  const dispatch = useDispatch();
  const [showUsuarioModal, setShowUsuarioModal] = useState({
    open: false,
    type: "add"
  })
  const [innerPerfiles, setInnerPerfiles] = useState([])
  const { setOpenToast } = useModal();
  const userAuth = localStorage.getItem('userAuth') ? JSON.parse(localStorage.getItem('userAuth')) : {}
  const { nIdUsuario: currentUser } = userAuth;
  const [isLoadedProcesos, setIsLoadedProcesos] = useState(false)

  useEffect(() => {
    addCustomProfile()
  }, [perfiles])

  const addCustomProfile = (nIdPerfil = null) => {
    if (perfiles.length > 0) {
      setInnerPerfiles([...perfiles])
    }
  }

  const onClickAdd = async () => {
    await openUsuarioModal("add", usuarioForm)
  }

  const openUsuarioModal = async (type, form = {}) => {
    if (!perfiles.length || !opciones.length || !secciones.length) {
      await dispatch(startLoadPermisosData());
    }
    if (!isLoadedProcesos) {
      await dispatch(startLoadProcesos(0,2));
      setIsLoadedProcesos(true)
    }
    
    setShowUsuarioModal({
      open: true,
      type
    })
    addCustomProfile(form.nPersonalizado ? form.nIdPerfil : null)
    setForm({ ...usuarioForm, ...form, sProcesos: form.sProcesos instanceof Object ? form.sProcesos : JSON.parse(form.sProcesos), sPermisos: form.sPermisos instanceof Object ? form.sPermisos : JSON.parse(form.sPermisos) })
  }

  const enableEditModal = () => {
    setShowUsuarioModal({
      open: true,
      type: "edit"
    })
  }

  const disableEditModal = () => {
    setShowUsuarioModal({
      open: true,
      type: "view"
    })
  }

  const loadInInit = async () => {
    await dispatch(getUsuariosData());
  };

  const onChangeInput = async (value) => {
    const inputName = Object.keys(value)[0];

    if (inputName === "nIdPerfil") {
      const selectedProfile = perfiles.find(perfil => parseInt(perfil.nIdPerfil) === parseInt(value[inputName]))
      setForm({ ...form, ...value, sPermisos: selectedProfile ? selectedProfile.sPermisos : [], nPersonalizado: selectedProfile ? selectedProfile.nPersonalizado : 1, nIdPerfilPlantilla: 0 });
    } else if (inputName === "nIdPerfilPlantilla") {
      const selectedProfile = perfiles.find(perfil => parseInt(perfil.nIdPerfil) === parseInt(value[inputName]))
      setForm({ ...form, ...value, sPermisos: selectedProfile ? selectedProfile.sPermisos : [] });
    } else if (inputName === "savePerfil") {
      setForm({ ...form, ...value, sNombrePerfil: "", sDescripcionPerfil: "" });
    } else if (inputName === "sCorreo") {
      setForm({ ...form, ...value, isEmailChanged: true });
    } else {

      setForm({ ...form, ...value });
    }

  };

  const onChange = (e, name = null, value = null) => {
    const inputName = name ?? e.target.name;
    const inputValue = value ?? e.target.value;
    console.log({
      [inputName]: inputValue,
    })
    setForm({
      ...form,
      [inputName]: inputValue,
    });
  }

  const handlePermisos = (checked, opcion) => {
    let innerPermisos = [...form.sPermisos]
    if (checked) innerPermisos.push(opcion);
    else innerPermisos = innerPermisos.filter(permiso => parseInt(permiso.nIdOpcion) !== parseInt(opcion.nIdOpcion))
    setForm({ ...form, sPermisos: innerPermisos })
  }

  const handleSubmit = async () => {
    const validatedData = validateData()
    if (validatedData.ok) {
      const resp = await dispatch(showUsuarioModal.type === "add" ? insertUsuario(form) : updateUsuario(form))
      setOpenToast(!resp.ok, resp.msg);
      if (resp.ok) {
        handleCloseModal()
        await dispatch(getUsuariosData());
        if (form.savePerfil) await dispatch(startLoadPermisosData());
      }
    } else {
      setOpenToast(true, validatedData.error);
    }
  }

  const handleSubmitChangeEstatus = async (inrForm = false, isBaja = false) => {
    const innerForm = inrForm ? inrForm : form
    const newEstatus = innerForm.nIdEstatus === 1 ? 0 : 1;
    console.log(innerForm)
    const resp = await dispatch(isBaja ? updateUsuarioBaja(innerForm.nIdUsuario, innerForm.sUsuario) : updateUsuarioEstatus(innerForm.nIdUsuario, innerForm.sUsuario, newEstatus))
    setOpenToast(!resp.ok, resp.msg);
    if (resp.ok) {
      handleCloseModal()
      await dispatch(getUsuariosData());
      if (innerForm.savePerfil) await dispatch(startLoadPermisosData());
    }
  }

  const handleCloseModal = () => {
    setShowUsuarioModal({
      open: false,
      type: showUsuarioModal.type
    })
  }

  const validateData = () => {
    if (form.sNombre === '')
      return { ok: false, error: 'Ingrese el nombre del usuario' }
    if (form.sAPaterno === '')
      return { ok: false, error: 'Ingrese el apellido paterno del usuario' }
    if (form.sCorreo === '')
      return { ok: false, error: 'Ingrese el apellido paterno del usuario' }
    if (form.nIdPerfil === '')
      return { ok: false, error: 'Seleccione un perfil para el usuario' }

    if (showUsuarioModal.type === "add") {
      if (form.sUsuario === '')
        return { ok: false, error: 'Ingrese un usuario' }
    }

    if (form.savePerfil) {
      if (form.sNombrePerfil === '')
        return { ok: false, error: 'Ingrese un nombre para el perfil' }
      if (form.sDescripcionPerfil === '')
        return { ok: false, error: 'Ingrese una descripción para el perfil' }
    }

    return { ok: true, error: '' }
  }


  return {
    dataTable,
    loadInInit,
    onClickAdd,
    showUsuarioModal,
    handleCloseModal,
    handleSubmit,
    form,
    onChangeInput,
    perfiles,
    innerPerfiles,
    opciones,
    handlePermisos,
    openUsuarioModal,
    enableEditModal,
    handleSubmitChangeEstatus,
    disableEditModal,
    currentUser,
    secciones,
    procesos,
    onChange
  };
};
