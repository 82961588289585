import { useEffect, useState } from "react";
import { useMenu } from "../hooks/useMenu";
import { useAuthPage } from "../hooks/useAuthPage";
import { Card, Typography } from "@mui/material";
import { createTheme, ThemeProvider } from "@mui/material";
import { esES } from "@mui/material/locale";
import { PolizasForm } from "../components/PolizasForm";
import { usePolizasPage } from "../hooks/usePolizasPage";
import { Table } from "../components/table/Table";
import * as XLSX from "xlsx/xlsx.mjs";
import moment from "moment";

const iconTheme = createTheme(
  {
    status: {
      danger: "#e53e3e",
    },
    palette: {
      primary: {
        main: "#00609C",
        dark: "#053e85",
      },
      neutral: {
        main: "#00CD68",
        light: "#053e85",
        dark: "#053e85",
        contrastText: "#fff",
      },
    },
  },
  esES
);

export const PolizasPage = () => {
  const { loadInInit, polizasTable, getPoliza } = usePolizasPage();
  const { setNameSection } = useMenu();
  const { setNewPage, isAllowed } = useAuthPage();
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    if (!loaded) {
      loadInInit();
      setNewPage("polizas");
      setNameSection("polizas");
      setLoaded(true);
    }
  }, []);

  useEffect(() => {
    console.log(polizasTable);
  }, [polizasTable]);

  const downloadPoliza = async (nIdPoliza) => {

    let filename = polizasTable.rows.find(poliza => poliza.nIdPoliza === nIdPoliza);

    filename = filename ? filename.sConcepto : "Poliza #"+nIdPoliza

    await getPoliza(nIdPoliza).then((resp) => {
      console.log(resp)
      let data = formatData(resp);
      const workbook = XLSX.utils.book_new();
      var worksheet1 = XLSX.utils.aoa_to_sheet(data);
      XLSX.utils.book_append_sheet(workbook, worksheet1, "Datos");
      XLSX.writeFile(workbook, `${filename}.xlsx`);
    });
  };

  const formatData = (data) => {
    let order = [
      "poliza",
      "fecha",
      "tipo",
      "folio",
      "clase",
      "diario",
      "concepto",
      "sis",
      "impresa",
      "ajuste",
      "vacio",
    ];
    let finalData = [];
    finalData = [...finalData, ...data?.headers];

    data?.data.map((row) => {
      let item = [];
      order.map((key) => {
        item.push(row[key]);
      });
      finalData.push(item);
    });
    return finalData;
  };

  if (isAllowed) {
    return (
      <ThemeProvider theme={iconTheme}>
        <PolizasForm />
        {polizasTable.columns.length ? (
          <Table
            table={polizasTable}
            download={true}
            onDownloadFunction={(value) => downloadPoliza(value)}
            rowId={"nIdPoliza"}
          />
        ) : (
          <></>
        )}
      </ThemeProvider>
    );
  } else {
    setNameSection("");
    return (
      <Card>
        <Typography variant="h3">Contenido no disponible</Typography>
      </Card>
    );
  }
};
