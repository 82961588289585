import { Badge, Button, Grid, Paper, TextField } from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { useEffect, useState } from "react";
import RequestQuoteOutlinedIcon from "@mui/icons-material/RequestQuoteOutlined";
import "dayjs/locale/es-mx";
import { SearchInput } from "./form/SearchInput";
import moment from "moment";
import { useModal } from "../hooks/useModal";
import { TextInput } from "./form/TextInput";
import { usePolizasPage } from "../hooks/usePolizasPage";
import PublishIcon from "@mui/icons-material/Publish";
import { useRegistrosBancariosPage } from "../hooks/useRegistrosBancariosPage";
import CompareArrowsIcon from '@mui/icons-material/CompareArrows';
import { BlueSwitch } from "../helpers/stylesMaterial";
import { useAuthPage } from "../hooks/useAuthPage";

export const RegistrosBancariosForm = () => {
  const {
    onChange,
    form,
    registrosBancariosTable,
    onChangeInput,
    isOpenImport,
    openImportModal,
    closeImport,
    loadInInit,
    getRegistrosBancarios,
    emisores,
    cadenas,
    registrosBancariosBadge,
    registrosBancariosCargosBadge,
    openModalRegistrosCortes,
    openModalRegistrosOrdenes
  } = useRegistrosBancariosPage();

  const { setOpenToast } = useModal();

  const [showFilters, setShowFilters] = useState(false)

  const { setAllowComponent } = useAuthPage();

  useEffect(() => {
    onChangeInput({
      sConcepto: "",
      sReferencia: ""
    })
  }, [showFilters])


  const handleSubmit = () => {
    if (moment(form.dFecFinal).diff(moment(form.dFecInicial)) >= 0) {
      getRegistrosBancarios();
    } else {
      setOpenToast(
        true,
        "La fecha inicial debe de ser menor o igual a la fecha final."
      );
    }
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={"es-mx"}>
      <Paper
        elevation={3}
        style={{
          marginBottom: 30,
          overflow: "hidden",
        }}
      >
        <Grid container>
          <Grid
            item
            container
            width={"50px"}
            alignItems={"center"}
            style={{
              background: "#00CD68",
              padding: "5px 15px 0 15px",
              color: "white",
              fontWeight: "bold",
              marginBottom: 15,
            }}
          >
            <RequestQuoteOutlinedIcon />
          </Grid>
          <Grid
            container
            item
            justifyContent={"space-between"}
            alignItems={"center"}
            style={{
              width: "calc(100% - 50px)",
              background: "#00609C",
              padding: 15,
              color: "white",
              fontWeight: "bold",
              marginBottom: 15,
            }}
          >
            <span>
              Consulta de registros bancarios
            </span>
            <Grid
              item
            >
              {setAllowComponent("importarRegistrosBancarios") && (
                <Button
                  style={{ background: "white" }}
                  onClick={openImportModal}
                  startIcon={<PublishIcon />}
                >
                  Importar
                </Button>
              )}
              {setAllowComponent("asociarRegistrosBancarios") && (<>
                <Badge badgeContent={registrosBancariosBadge} color="neutral">
                  <Button
                    style={{ background: "white", marginLeft: 10 }}
                    onClick={openModalRegistrosCortes}
                    startIcon={<CompareArrowsIcon />}
                  >
                    Asociar abonos a cortes
                  </Button>

                </Badge>
                <Badge badgeContent={registrosBancariosCargosBadge} color="neutral">
                  <Button
                    style={{ background: "white", marginLeft: 10 }}
                    onClick={openModalRegistrosOrdenes}
                    startIcon={<CompareArrowsIcon />}
                  >
                    Asociar cargos a ordenes de pago
                  </Button>
                </Badge>
              </>)}
            </Grid>
          </Grid>
        </Grid>
        <Grid container spacing={2} style={{ padding: 20 }} alignItems={"center"}>

          <Grid item xs={12} sm={2}>
            <SearchInput
              options={[
                {
                  id: 2,
                  name: "Todos",
                },
                {
                  id: 1,
                  name: "Abonos",
                },
                {
                  id: 0,
                  name: "Cargos",
                },
              ]}
              value={form.nTipo}
              name={"nTipo"}
              label={"Tipo de registro"}
              getOptionLabel={"name"}
              getIndexLabel={"id"}
              onChange={onChange}
            />
          </Grid>
          <Grid item xs={12} sm={2}>
            <SearchInput
              options={[
                {
                  id: 1,
                  name: "EFR",
                },
                {
                  id: 2,
                  name: "Comisionista",
                },
              ]}
              value={form.type}
              name={"type"}
              label={"Entidad"}
              getOptionLabel={"name"}
              getIndexLabel={"id"}
              onChange={onChange}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            {parseInt(form.type) === 1 ? (
              <SearchInput
                options={[
                  { nIdEmisor: 0, sNombreComercial: "Todos" },
                  ...emisores.rows,
                ]}
                value={form.nIdEmisor}
                name={"nIdEmisor"}
                label={"Entidad financiera"}
                getOptionLabel={"sNombreComercial"}
                getIndexLabel={"nIdEmisor"}
                onChange={onChange}
              />
            ) : (
              <SearchInput
                options={[
                  { nIdCadena: 0, sNombreComercial: "Todos" },
                  ...cadenas.rows,
                ]}
                value={form.nIdCadena}
                name={"nIdCadena"}
                label={"Comisionista"}
                getOptionLabel={"sNombreComercial"}
                getIndexLabel={"nIdCadena"}
                onChange={onChange}
              />
            )}
          </Grid>
          <Grid item xs={12} sm={2}>
            <DesktopDatePicker
              label="Fecha inicial"
              inputFormat="YYYY-MM-DD"
              renderInput={(params) => <TextField {...params} fullWidth />}
              value={form.dFecInicial}
              onChange={(e) =>
                onChange(
                  null,
                  "dFecInicial",
                  moment(e["$d"]).format("YYYY-MM-DD")
                )
              }
            />
          </Grid>
          <Grid item xs={12} sm={2}>
            <DesktopDatePicker
              label="Fecha final"
              inputFormat="YYYY-MM-DD"
              renderInput={(params) => <TextField {...params} fullWidth />}
              value={form.dFecFinal}
              onChange={(e) =>
                onChange(
                  null,
                  "dFecFinal",
                  moment(e["$d"]).format("YYYY-MM-DD")
                )
              }
            />
          </Grid>

          <Grid
            container
            justifyContent={"space-between"}
            alignItems={"center"}
            spacing={2}
            style={{ padding: 20 }}
          >
            <Grid item xs={12} sm={showFilters ? 2 : 6}>
              <Grid container style={{ alignItems: "center" }}>
                <BlueSwitch
                  onChange={() => setShowFilters(!showFilters)}
                  inputProps={{ 'aria-label': 'controlled' }}
                  checked={showFilters}
                  value={showFilters}
                />
                <p>Filtros Avanzados</p>
              </Grid>
            </Grid>
            {showFilters
              &&
              <>
                <Grid item xs={12} sm={2}>
                  <TextInput
                    id="sConcepto"
                    name="sConcepto"
                    label="Concepto"
                    value={form.sConcepto}
                    onChangeRoot={onChangeInput}
                  />
                </Grid>
                <Grid item xs={12} sm={2}>
                  <TextInput
                    id="sReferencia"
                    name="sReferencia"
                    label="Referencia"
                    value={form.sReferencia}
                    onChangeRoot={onChangeInput}
                  />
                </Grid>
              </>
            }
            <Grid item xs={12} sm={6} style={{ textAlign: "right" }}>
              <Button variant="contained" onClick={handleSubmit}>
                Buscar
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Paper>
    </LocalizationProvider>
  );
};
