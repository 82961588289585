import axios from 'axios';

const API_URL = '/api/users/'; //endpoint base

//registrar usuario
const register = async (userData) => {
  const response = await axios.post(API_URL, userData);

  if (response.data) {
    localStorage.setItem('user', JSON.stringify(response.data));
  }

  return response.data;
};

//iniciar sesion
const logIn = async (userData) => {
  const response = await axios.post(API_URL + 'login', userData);

  if (response.data) {
    localStorage.setItem('user', JSON.stringify(response.data));
  }

  return response.data;
};

//cerrar sesion
const logOut = () => {
  localStorage.removeItem('user');
};

const authService = {
  register,
  logOut,
  logIn
};

export default authService;
