import { useDispatch } from "react-redux";
import { useEffect, useState } from "react";
import { useModal } from "./useModal";
import moment from "moment";
import { useExportFile } from "./useExportFile";
import { Grid, Tooltip } from "@mui/material";
import { getOperacionesArchivosConciliacion } from "../slices/conciliacion/conciliacionThunk";

const formatDate = (date) => {
  if (date && date[date.length - 1] === "Z") {
    return date.slice(0, -1);
  } else {
    return date;
  }
};

const columns = {
  operacionesConciliadas: [
    {
      field: "nIdOperacion",
      headerName: "Id Op.",
      format: "text",
      flex: 2,
      style: { width: 20 },
    },
    {
      field: "dFecha",
      headerName: "Fecha",
      format: "date",
      flex: 1,
      style: { width: 70 },
    },
    {
      field: "dHora",
      headerName: "Hora",
      format: "time",
      flex: 1,
      style: { width: 50 },
    },
    { field: "sProducto", headerName: "Producto", format: "text", flex: 3 },
    { field: "sReferencia", headerName: "Referencia", format: "text", flex: 6 },
    {
      field: "sAutorizacion",
      headerName: "Autorización",
      format: "text",
      flex: 1,
    },
    {
      field: "sDescripcion",
      headerName: "Estatus",
      format: "render",
      flex: 4,
      disableTooltip: true,
      renderFunction: (row) => (
        <Tooltip
          title={
            row.nConciliadoPosterior
              ? `Conciliado en ${moment(
                  formatDate(row.dFechaConciliacionPosterior)
                ).format("YYYY-MM-DD")}`
              : ""
          }
        >
          <Grid style={{ whiteSpace: "break-spaces", margin: 0 }}>
            <p
              style={{
                display: "inline-block",
                padding: 4,
                color: row.nConciliadoPosterior ? "#00CD68" : "black",
                background: row.nConciliadoPosterior ? "#00CD6815" : "none",
                borderRadius: "4px",
                margin: 0,
                fontSize: 13,
                fontWeight: row.nConciliadoPosterior ? 500 : 400,
              }}
            >
              {row.sDescripcion}
            </p>
          </Grid>
        </Tooltip>
      ),
    },
    { field: "nMonto", headerName: "Monto", format: "price", flex: 1 },
  ],
  operacionesNoConciliadas: [
    {
      field: "nIdOperacion",
      headerName: "Id Op.",
      format: "text",
      flex: 2,
      style: { width: 20 },
    },
    {
      field: "dFecha",
      headerName: "Fecha",
      format: "date",
      flex: 1,
      style: { width: 70 },
    },
    {
      field: "dHora",
      headerName: "Hora",
      format: "time",
      flex: 1,
      style: { width: 50 },
    },
    { field: "sProducto", headerName: "Producto", format: "text", flex: 3 },
    { field: "sReferencia", headerName: "Referencia", format: "text", flex: 6 },
    {
      field: "sAutorizacion",
      headerName: "Autorización",
      format: "text",
      flex: 1,
    },
    {
      field: "sDescripcion",
      headerName: "Estatus",
      format: "render",
      flex: 4,
      disableTooltip: true,
      renderFunction: (row) => (
        <Tooltip
          title={
            row.nConciliadoPosterior
              ? `Conciliado en ${moment(
                  formatDate(row.dFechaConciliacionPosterior)
                ).format("YYYY-MM-DD")}`
              : ""
          }
        >
          <Grid style={{ whiteSpace: "break-spaces", margin: 0 }}>
            <p
              style={{
                display: "inline-block",
                padding: 4,
                color: row.nConciliadoPosterior ? "#00CD68" : "black",
                background: row.nConciliadoPosterior ? "#00CD6815" : "none",
                borderRadius: "4px",
                margin: 0,
                fontSize: 13,
                fontWeight: row.nConciliadoPosterior ? 500 : 400,
              }}
            >
              {row.sDescripcion}
            </p>
          </Grid>
        </Tooltip>
      ),
    },
    { field: "nMonto", headerName: "Monto", format: "price", flex: 1 },
  ],
};

export const useModalArchivoConciliacion = () => {
  const { isOpenArchivoConciliacion } = useModal();
  const { downloadFile } = useExportFile();
  const dispatch = useDispatch();

  const [archivo, setArchivo] = useState(isOpenArchivoConciliacion.archivo);
  const [selectedTab, setSelectedTab] = useState("resumen");
  const [operacionesConciliadas, setOperacionesConciliadas] = useState({
    columns: columns.operacionesConciliadas,
    pages: {},
  });
  const [operacionesNoConciliadas, setOperacionesNoConciliadas] = useState({
    columns: columns.operacionesNoConciliadas,
    pages: {},
  });

  const [form, setForm] = useState({
    nTipoConciliacion: 0,
    nEstatusConciliacion: 0,
  });

  useEffect(() => {
    if (!isOpenArchivoConciliacion.open) {
      setSelectedTab("resumen");
      setForm({
        nTipoConciliacion: 0,
        nEstatusConciliacion: 0,
      });
      setOperacionesConciliadas({
        columns: columns.operacionesConciliadas,
        pages: {},
      });
      setOperacionesNoConciliadas({
        columns: columns.operacionesNoConciliadas,
        pages: {},
      });
    } else {
      const archivo = isOpenArchivoConciliacion.archivo;
      setArchivo(archivo);
    }
  }, [isOpenArchivoConciliacion]);

  useEffect(() => {
    let data = {
      ...(selectedTab === "conciliadas"
        ? operacionesConciliadas
        : operacionesNoConciliadas),
    };

    const { nPage, nRowsPerPage, sSearch, sOrder, sOrderBy } = data;

    getData(nPage, nRowsPerPage, sSearch, sOrder, sOrderBy);
  }, [form]);

  const onChange = (e, name = null, value = null) => {
    setForm({
      ...form,
      [e?.target?.name ?? name]: e?.target?.value ?? value,
    });
  };

  const handleSelectedTab = (tab) => {
    setForm({
      nTipoConciliacion: 0,
      nEstatusConciliacion: 0,
    });
    setSelectedTab(tab);
  };

  const getData = async (
    page = 0,
    rowsPerPage = 10,
    search = "",
    order = "",
    orderBy = ""
  ) => {
    if (["conciliadas", "noConciliadas"].includes(selectedTab)) {
      let data = {
        ...(selectedTab === "conciliadas"
          ? operacionesConciliadas
          : operacionesNoConciliadas),
      };

      const response = await dispatch(
        getOperacionesArchivosConciliacion({
          nIdArchivo: archivo.nIdArchivo,
          nDiferencias: selectedTab === "noConciliadas" ? 1 : 0,
          nTipoConciliacion: form.nTipoConciliacion,
          nEstatusConciliacion: form.nEstatusConciliacion,
          nPage: page,
          nRowsPerPage: rowsPerPage,
          sSearch: search,
          sOrder: order,
          sOrderBy: orderBy,
        })
      );

      if (
        parseInt(data.nRowsPerPage) === parseInt(rowsPerPage) &&
        data.sSearch == search &&
        data.sOrder == order &&
        data.sOrderBy == orderBy
      ) {
        data = {
          ...data,
          ...response,
          pages: {
            ...data.pages,
            [`page_${response.nPage}`]: response.aData,
          },
        };
      } else {
        data = {
          ...data,
          ...response,
          pages: {
            [`page_${response.nPage}`]: response.aData,
          },
        };
      }

      if (selectedTab === "conciliadas") {
        setOperacionesConciliadas(data);
      } else {
        setOperacionesNoConciliadas(data);
      }
    }
  };

  const exportData = (format) => {
    const dFecha = moment(archivo.dFechaConciliacion).format("YYYY-MM-DD");
    const sNombreComercial = archivo.sNombreComercial;

    const fileName = `${
      selectedTab === "conciliadas"
        ? "Operaciones conciliadas"
        : "Operaciones no conciliadas"
    } - ${sNombreComercial} (${dFecha})`;
    const title = fileName;
    const subtitle =
      form.nTipoConciliacion || form.nEstatusConciliacion
        ? `${form.nTipoConciliacion ? "Tipo de conciliación" : "Estatus"}: ${
            form.nTipoConciliacion
              ? form.nTipoConciliacion
              : form.nEstatusConciliacion
          }`
        : "";

    const table =
      selectedTab === "conciliadas"
        ? operacionesConciliadas
        : operacionesNoConciliadas;
    downloadFile(
      table.pages[`page_${table.nPage}`],
      table.columns,
      format,
      fileName,
      title,
      subtitle,
      true
    );
  };

  return {
    archivo,
    selectedTab,
    operacionesConciliadas,
    operacionesNoConciliadas,
    form,
    handleSelectedTab,
    exportData,
    onChange,
    getData,
  };
};
