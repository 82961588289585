import { Box, Card, Typography } from '@mui/material';

export const Error404Page = () => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
        width: '100%',
        height: '100 vh',
        alignItems: 'center',
        justifyContent: 'center',
        verticalAlign: 'center'
      }}
    >
      <Card
        className="card-login"
        elevation={24}
        sx={{
          height: '70%',
          textAlign: 'center',
          verticalAlign: 'center'
        }}
      >
        <img
          src="/assets/SofipayLogo.png"
          alt="LogoSofipay - Deny Access"
          style={{ paddingTop: '20px', paddingBottom: '20px' }}
        />
        <Typography variant="h4" sx={{ fontWeight: 'bold' }}>
          Hubo un problema
        </Typography>
        <Box
          sx={{
            display: 'flex',
            width: '100%',
            justifyContent: 'center',
            paddingTop: '20px',
            paddingBottom: '20px'
          }}
        >
          <section>
            <Typography>No encontramos la página que buscas.</Typography>
          </section>
        </Box>
      </Card>
    </Box>
  );
};
