import { useSelector } from "react-redux"
import { EmailFacturaCadena } from "./EmailFacturaCadena";

export const EmailsFacturaCadena = ({ reference, sizeSmall = false }) => {
  const { emailsFactura } = useSelector((state) => state.cadena);
  
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        gap: '10px',
        marginTop: '10px',
        width: '100%'
      }}
    >
      {emailsFactura[reference]?.map((email, i) => (
        <EmailFacturaCadena
          emailForm={email}
          key={reference+ email.id}
          isFirst={i === 0 ? true : false}
          reference={reference}
          sizeSmall={sizeSmall}
        />
      ))}
    </div>
  );
}
