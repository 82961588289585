import { useSelector } from "react-redux";
import { useModal } from "../../hooks/useModal";
import { FormConciliacion } from "../FormConciliacion";
import { ModalBase } from "./ModalBase";
import { useModalConciliacion } from "../../hooks/useModalConciliacion";
import { Edit, ReceiptLong } from "@mui/icons-material";
import { Box, Typography, Button, Grid, FormControlLabel, Radio, Alert, AlertTitle } from "@mui/material";
import { TextInput } from "../form/TextInput";
import { SelectInput } from "../form/SelectInput";
import { BlueSwitch } from "../../helpers/stylesMaterial";
import { styled } from '@mui/material/styles';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import { useModalEnvioOperaciones } from "../../hooks/useModalEnvioOperaciones";
import { useEffect } from "react";
import { useAuthPage } from "../../hooks/useAuthPage";

const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  '&:not(:last-child)': {
    borderBottom: 0,
  },
  '&:before': {
    display: 'none',
  },
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    {...props}
  />
))(({ theme }) => ({
  border: "1px solid rgba(0, 0, 0, .125)",
  backgroundColor:
    theme.palette.mode === 'dark'
      ? 'rgba(255, 255, 255, .05)'
      : 'rgba(0, 0, 0, .03)',
  flexDirection: 'row-reverse',
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(90deg)',
  },
  '& .MuiAccordionSummary-content': {
    marginLeft: theme.spacing(1),
  },
  '&.Mui-disabled': {
    backgroundColor: "#f1f1f1"
  }
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  paddingLeft: "40px",
  border: "1px solid rgba(0, 0, 0, .125)"
}));


export const ModalEnvioOperaciones = () => {
  const { isOpenEnvioOperaciones, closeModalEnvioOperaciones } = useModal();
  const { isLoading } = useSelector((state) => state.ui);
  const {
    form,
    setForm,
    onChange,
    onSubmit,
    selectedEntity,
    type,
    handleEdit,
    getContactos,
    cgfSFTP
  } = useModalEnvioOperaciones();

  useEffect(() => {
    console.log(form.nTipoEnvio)
  }, [form])


  const { setAllowComponent } = useAuthPage();
  const onChangeInput = async (value) => {
    setForm({ ...form, ...value });
  };

  const handleExpanded = (value) => {
    setForm({ ...form, nTipoEnvio: value });
  }

  const isActiveSave = () => {
    if (form.nIdCfgEnvioOperaciones !== 0 && !form.nHabilitarEnvio) return false;
    if (form.nHabilitarEnvio && form.nTipoEnvio === 1 && form.nSFTPSofipay) return false
    if (
      (form.nHabilitarEnvio && form.nTipoEnvio === 1 && !form.nSFTPSofipay &&
        form.sHostEnvio &&
        form.nPortEnvio &&
        form.sUserEnvio &&
        form.sPasswordEnvio &&
        form.sFolderEnvio)
    ) return false;
    if (form.nHabilitarEnvio && form.nTipoEnvio === 2) return false
    if (form.nHabilitarEnvio && form.nTipoEnvio === 3) return false

    return true
  };

  const showTitleButton = () => {
    if (type === 'view' && setAllowComponent("guardarEnvioOperacionesDiarias")) return <Button variant='contained' onClick={() => handleEdit(true)}
      startIcon={<Edit />}>Editar</Button>;

    return <></>
  }

  return (
    <ModalBase open={isOpenEnvioOperaciones.open} onClose={closeModalEnvioOperaciones}>
      <TitleModal title={selectedEntity?.sNombreComercial} extraButton={showTitleButton()} />
      <div className="cadenas__blue-switch" style={{ marginTop: '15px' }}>
        <BlueSwitch
          onChange={onChange}
          inputProps={{ 'aria-label': 'controlled' }}
          name="nHabilitarEnvio"
          checked={form.nHabilitarEnvio}
          value={form.nHabilitarEnvio}
          disabled={type === "view" || !setAllowComponent("guardarEnvioOperacionesDiarias")}
        />
        <p>Habilitar el envío de operaciones diarias</p>
      </div>
      {form.nHabilitarEnvio ?
        <>
          <Accordion expanded={form.nTipoEnvio === 1} onChange={() => handleExpanded(1)} disabled={!form.nHabilitarEnvio || type === "view"}>
            <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
              <Typography><FormControlLabel control={<Radio checked={form.nTipoEnvio === 1} />} label="Depósito de archivo en SFTP" /></Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <div className="cadenas__blue-switch">
                    <BlueSwitch
                      onChange={onChange}
                      inputProps={{ 'aria-label': 'controlled' }}
                      name="nSFTPSofipay"
                      checked={form.nSFTPSofipay}
                      value={form.nSFTPSofipay}
                      disabled={type === "view"}
                    />
                    <p>Depósito en SFTP de SofiPay</p>
                  </div>
                </Grid>

                {!form.nSFTPSofipay ? <>
                  <Grid item xs={12} sm={6}>
                    <TextInput
                      disabled={type === "view"}
                      id="sHostEnvio"
                      name="sHostEnvio"
                      label="Host"
                      value={form.sHostEnvio}
                      onChangeRoot={onChangeInput}
                      fullWidth
                      require
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextInput
                      disabled={type === "view"}
                      id="nPortEnvio"
                      name="nPortEnvio"
                      label="Puerto"
                      type="number"
                      value={`${form.nPortEnvio}`}
                      onChangeRoot={onChangeInput}
                      fullWidth
                      require
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextInput
                      disabled={type === "view"}
                      id="sUserEnvio"
                      name="sUserEnvio"
                      label="Usuario"
                      value={form.sUserEnvio}
                      onChangeRoot={onChangeInput}
                      fullWidth
                      require
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>

                    <TextInput
                      disabled={type === "view"}
                      id="sPasswordEnvio"
                      name="sPasswordEnvio"
                      label="Contraseña"
                      value={form.sPasswordEnvio}
                      type="password"
                      onChangeRoot={onChangeInput}
                      fullWidth
                      require
                    />
                  </Grid>
                  <Grid item xs={12}>

                    <TextInput
                      disabled={type === "view"}
                      id="sFolderEnvio"
                      name="sFolderEnvio"
                      label="Dirección del folder remoto"
                      value={form.sFolderEnvio}
                      onChangeRoot={onChangeInput}
                      fullWidth
                      require
                    />
                  </Grid>
                </> : <>
                  <Grid item xs={12} sm={6}>
                    <TextInput
                      disabled
                      id="sHostEnvio"
                      name="sHostEnvio"
                      label="Host"
                      value={cgfSFTP.sHost}
                      fullWidth
                      require
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextInput
                      disabled
                      id="nPortEnvio"
                      name="nPortEnvio"
                      label="Puerto"
                      type="number"
                      value={cgfSFTP.nPort}
                      fullWidth
                      require
                    />
                  </Grid>
                  <Grid item xs={12}>

                    <TextInput
                      disabled
                      id="sFolderEnvio"
                      name="sFolderEnvio"
                      label="Dirección del folder remoto"
                      value={cgfSFTP.sFolder + `/SofiPay/${isOpenEnvioOperaciones.type === "emisor" ? "EFR" : "Comisionistas"}/${isOpenEnvioOperaciones.type === "emisor" ? form.nIdCNBV : form.nIdCadena}`}
                      fullWidth
                      require
                    />
                  </Grid>
                </>}


              </Grid>
            </AccordionDetails>
          </Accordion>
          <Accordion expanded={form.nTipoEnvio === 2} onChange={() => handleExpanded(2)} disabled={!form.nHabilitarEnvio || type === "view"}>
            <AccordionSummary aria-controls="panel2d-content" id="panel2d-header">
              <Typography><FormControlLabel control={<Radio checked={form.nTipoEnvio === 2} />} label="Envío de archivo por correo" /></Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                Se enviará el archivo a los contactos de la entidad asignados al proceso de "Operaciones". Los contactos asignados actualmente son:
                {getContactos().length ?
                  <ul>
                    {getContactos().map(contacto => {
                      return <li>{contacto.sNombre} {contacto.sPaterno} {contacto.sMaterno}- ({contacto.sMail})</li>
                    })}
                  </ul>
                  :
                  <Alert severity="warning" sx={{ my: 2 }}>
                    <AlertTitle>Sin contactos asignados a proceso de "Operaciones"</AlertTitle>
                  </Alert>
                }

                Si se agregan o eliminan contactos asociados a "Operaciones" la lista se actualizará.
              </Typography>
            </AccordionDetails>
          </Accordion>
          {/* <Accordion expanded={form.nTipoEnvio === 3} onChange={() => handleExpanded(3)} disabled={!form.nHabilitarEnvio || type === "view"} >
            <AccordionSummary aria-controls="panel3d-content" id="panel3d-header">
              <Typography><FormControlLabel control={<Radio checked={form.nTipoEnvio === 3} />} label="Habilitación de API" /></Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse
                malesuada lacus ex, sit amet blandit leo lobortis eget. Lorem ipsum dolor
                sit amet, consectetur adipiscing elit. Suspendisse malesuada lacus ex,
                sit amet blandit leo lobortis eget.
              </Typography>
            </AccordionDetails>
          </Accordion> */}
        </> : <>
        </>}



      <Box
        sx={{
          textAlign: 'end',
          marginTop: '15px'
        }}
      >
        <Button
          onClick={() => closeModalEnvioOperaciones()}
          sx={{ marginRight: '10px', display: '' }}
        >
          cerrar
        </Button>
        {setAllowComponent("guardarEnvioOperacionesDiarias") ?
          <Button
            variant="contained"
            color="primary"
            onClick={onSubmit}
            sx={{ display: type === "view" ? 'none' : '' }}
            disabled={isActiveSave()}
          >
            Guardar
          </Button>
          : <></>}

      </Box>
    </ModalBase >
  );
};


const TitleModal = ({ title, typeOperation, extraButton }) => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        width: '100%',
        marginBottom: '10px',
        justifyContent: 'space-between', mb: 4
      }}
    >
      <div style={{ display: 'flex', justifyContent: "space-between" }}>

        <ReceiptLong fontSize={'large'} sx={{ color: '#00CD68', fontSize: '45px' }} />
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <Typography
            variant="p"
            sx={{
              marginLeft: '15px',
              fontSize: '14px',
              marginBottom: '4px'
            }}
          >
            {'Configuración de envío de operaciones diarias'}
          </Typography>
          <Typography
            variant="p"
            sx={{
              marginLeft: '15px',
              color: '#00609C',
              fontSize: '20px'
            }}
          >
            {title}
          </Typography>
        </div>
      </div>
      <div>
        {extraButton}
      </div>
    </Box>
  );
};