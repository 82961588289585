import { Alert, Button, Dialog, DialogActions, DialogTitle, Grid, TextField, Typography } from "@mui/material";
import { SearchInput } from "../../form/SearchInput";
import moment from "moment";
import { DatePicker } from "@mui/x-date-pickers";
import { EscCantidadForms } from "../../escalonadoCantidad/EscCantidadForms";
import { Cancel, Done, Edit, Save, Schedule } from "@mui/icons-material";
import { useAuthPage } from "../../../hooks/useAuthPage";
import { EscCantidadEnhancedForms } from "../../escalonadoCantidad/EscCantidadEnhancedForms";

export const EscalonadoModalForm = ({ form = {}, onChange, onlyView, showButtons, has24Horas }) => {

    const { setAllowComponent } = useAuthPage();

    const formatDate = (date) => {
        if (date && date[date.length - 1] === "Z") {
            return date.slice(0, -1);
        } else {
            return date;
        }
    };

    return (
        <Grid container spacing={2}>
            <Grid item xs={12} container justifyContent={"space-between"} alignItems={"center"}>
                <Grid item xs={4}>
                    <DatePicker
                        disablePast={!(moment(formatDate(form.dFechaVigencia)).isBefore(moment()))}
                        label="Mes entrada en vigor"
                        openTo="year"
                        views={["year", "month"]}
                        value={form.dFechaVigencia}
                        disabled={onlyView}
                        onChange={(e) =>
                            onChange({
                                target: {
                                    name: "dFechaVigencia",
                                    value: moment(e['$d']).format('YYYY-MM-DD')
                                }
                            })
                        }
                        renderInput={(params) => (
                            <TextField {...params} fullWidth />
                        )}
                    />
                </Grid>
                <Grid item xs={8} container justifyContent={"end"}>
                    {showButtons}
                </Grid>
            </Grid>
            <Grid item xs={12}>
                <Grid container justifyContent={"space-between"} alignItems={"center"}>

                    <Typography variant="h6" color={"primary"}>Escalones</Typography>
                    {has24Horas && <Alert severity="info">El tipo de liquidación "24 horas" sólo admite 1 escalón.</Alert>}
                    
                </Grid>
                <EscCantidadEnhancedForms onlyView={onlyView} disableAddEscalon={has24Horas} />
            </Grid>
            {form.nIdEmisorComEscProg &&
                <Grid item xs={12} sx={{ textAlign: "right" }}>
                    <div>
                        <Typography variant="p" fontSize={14} >Programación creada el día {moment(form.dFecRegistro).format("YYYY-MM-DD")} por <b>{form.sUsuarioCrea}</b></Typography>
                    </div>
                    {parseInt(form.nEstatusAutorizacion) === 1 && <div>
                        <Typography variant="p" fontSize={14}>Programación autorizada el día {moment(form.dFecMovimiento).format("YYYY-MM-DD")} por <b>{form.sUsuarioAutoriza}</b></Typography>
                    </div>}
                </Grid>
            }
        </Grid>
    )
}