import { API, Auth} from "aws-amplify";
import { useDispatch } from "react-redux";
import {
  login,
  setUser,
  setTemporalPassword,
  setPassword,
  setPermisos,
  setTitulos,
  setIdUsuario,
} from "../slices/user/userSlice";
import { loading } from "../slices/ui/uiSlice";
import {
  setPasswordError,
  setUserError,
  setError,
  setHelperText,
  setErrorMessage,
} from "../slices/loginError/loginErrorSlice";
import { useNavigate } from "react-router-dom";
import { reset } from "../slices/authPage/authPageSlice";
import { useState } from "react";

export const useAuthUser = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [refreshTokenGenerated, setRefreshTokenGenerated] = useState(false);
  //Funcion para iniciar sesion
  const signIn = async (username, password) => {
    username = username.trim()
    password = password.trim()
    try {
      dispatch(loading(true));
      const user = await Auth.signIn(username, password);
      if (user.challengeName) {
        dispatch(setTemporalPassword(true));
        dispatch(setUser({ username: username }));
        dispatch(login(false));
        dispatch(setPassword(password));
        navigate("/new-password");
      } else if (user.signInUserSession.idToken.jwtToken) {
        const userData = {
          username: username,
          token: user.signInUserSession.idToken.jwtToken,
          email: user.attributes.email,
        };
        dispatch(setTemporalPassword(false));
        dispatch(login(true));
        dispatch(setUserError(false));
        dispatch(setPasswordError(false));
        dispatch(setError(false));
        await api(userData.username,userData.email,userData.token);
      }
    } catch (error) {
      //NotAuthorizedException: User is disabled.
      //UserNotFoundException: User does not exist.
      //NotAuthorizedException: Incorrect username or password.
      switch (error.toString()) {
        case "UserNotFoundException: User does not exist.":
          dispatch(setUserError(true));
          dispatch(setPasswordError(false));
          dispatch(setError(false));
          dispatch(setHelperText("Usuario no encontrado"));
          break;
        case "NotAuthorizedException: User is disabled.":
          dispatch(setUserError(true));
          dispatch(setPasswordError(false));
          dispatch(setError(false));
          dispatch(setHelperText("Usuario no autorizado"));
          break;
        case "NotAuthorizedException: Incorrect username or password.":
          dispatch(setPasswordError(true));
          dispatch(setUserError(false));
          dispatch(setError(false));
          dispatch(setHelperText("Contraseña incorrecta"));
          break;
        default:
          dispatch(setError(true));
          dispatch(setErrorMessage(error));
          dispatch(setUserError(false));
          dispatch(setPasswordError(false));
          break;
      }
      console.log("Error al iniciar sesion: ", error);
    } finally {
      dispatch(loading(false));
    }
  };

  const signOut = async () => {
    try {
      await Auth.signOut();
      dispatch(login(false));
      dispatch(setUser(null));
      localStorage.removeItem("userAuth");
      navigate("/login");
      console.log("Succes SignOut");
    } catch (error) {
      console.log("Error al cerrar sesion: ", error);
    } finally {
      dispatch(reset());
      console.log("reset");
    }
  };

  const api = async (username, email,token, retry = 0) => {
    dispatch(loading(true));
    try {
      const init = {
        headers: {
          Authorization: token,
        },
        queryStringParameters: {username, email},
      };
      const response = await API.get("usuarios", "/usuarios/perfil", init);
      if(response && response.data && response.data.permisos){
        setArrayPermisos(response.data.permisos);
        const {
          username,
          email,
          nExterno,
          nIdCadena,
          nIdUsuario,
          nombre:name
        } = response.data;

        const userData = {
          token,
          username,
          email,
          name,
          nIdUsuario,
          ...(nExterno ? {
            nExterno,
            nIdCadena
          } : {})
        }
        dispatch(setUser(userData));
        localStorage.setItem(
          'userAuth',
          JSON.stringify(userData)
        );
        dispatch(setIdUsuario(response.data.nIdUsuario));
        setRefreshTokenGenerated(false);
      }else{
        await refreshToken(retry)
      }

    } catch (e) {
      console.log("ocurrio un error" + e);
      await refreshToken(retry)
    } finally {
      dispatch(loading(false));
    }
  };

  const refreshToken = async (retry) => {
    if(refreshTokenGenerated){
      signOut()
    } else {
      if(retry >= 2){
        return;
      }
      retry++;
      
      dispatch(loading(true));
      try {
        const cognitoUser = await Auth.currentAuthenticatedUser();
        const currentSession = await Auth.currentSession();
  
        cognitoUser.refreshSession(
          currentSession.refreshToken,
          async (err, session) => {
            let data = localStorage.getItem('userAuth')
            if(data){
              data = JSON.parse(data)
              setRefreshTokenGenerated(true);
              await api(null, null, session.idToken.jwtToken, retry)
              
            }
          }
        );
      } catch (e) {
        setRefreshTokenGenerated(true);
        signOut()
        console.log("ocurrio un error" + e);
        
      } finally {
        dispatch(loading(false));
       
      }
    }
    
  };

  const setArrayPermisos = async (cadenaPermisos) => {
    dispatch(setPermisos(cadenaPermisos));
  };

  return {
    signIn,
    signOut,
    api,
  };
};