import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Add, Delete } from '@mui/icons-material';
import { Button, Tooltip } from '@mui/material';

import { v4 as uuidv4 } from 'uuid';

import { buttonContactStyle, buttonContactStyleError } from '../../../helpers/stylesMaterial';
import { emailFacutra } from '../../../helpers/forms';
import { validEmailFacutra } from '../../../helpers/forms-validate';
import { regexEmail } from '../../../helpers/regexs';
import { TextInput } from '../../form/TextInput';
import { updateEmail, setEmail as setEmailRedux, deleteEmail } from '../../../slices/cadena/cadenaSlice';
import { useModal } from '../../../hooks/useModal';


export const EmailFacturaCadena = ({ emailForm, isFirst, reference, sizeSmall = false }) => {
  const [email, setEmail] = useState({ ...emailForm });
  const [isActiveDelete, setIsActiveDelete] = useState(emailForm.isActiveDelete);
  const [isActiveDisable, setIsActiveDisable] = useState(emailForm.isActiveDisable);
  const { emailsFactura = [] } = useSelector((state) => state.cadena);

  useEffect(() => {
    setEmail({ ...emailForm });
    setIsActiveDelete(emailForm.isActiveDelete);
    setIsActiveDisable(emailForm.isActiveDisable);
  }, [emailForm])
  
  const dispatch = useDispatch();
  const { setOpenToast } = useModal();

  const onChange = (value) => {
    setEmail({ ...email, ...value });
    dispatch(
      updateEmail({
        ref: reference,
        email: { ...email, ...value }
      })
    );
  };

  const onSave = () => {
    const { ok, error } = validEmailFacutra(email, emailsFactura[reference]);
    if (!ok) {
      setOpenToast(true, error);
      return;
    }
    dispatch(
      updateEmail({
        ref: reference,
        email : { ...email, isActiveDelete: true }
      })
    );

    
    if (emailsFactura[reference].filter(emil => emil.email !== '' && !emil.isFromContactos).length < 3) {
      dispatch(
        setEmailRedux({
          ref: reference,
          email: { ...emailFacutra, id: uuidv4() }
        })
      )
    }


  }

  const onDeleteForm = () => {
    
    if (emailsFactura[reference].filter(emil => emil.email !== '' && !emil.isFromContactos).length === 3) {
      console.log(emailsFactura[reference].filter(emil => emil.email !== ''))
      dispatch(
        setEmailRedux({
          ref: reference,
          email: { ...emailFacutra, id: uuidv4() }
        })
      )
    }
    
    dispatch(deleteEmail({ ref: reference, id: email.id }));
    
  }

  return (
    <div className="flex-row space-between">
      <div style={{ width: '78%' }}>
        <TextInput
          id="email"
          name="email"
          label="Correo a enviar la factura"
          value={email.email}
          onChangeRoot={onChange}
          expresionRegular={regexEmail}
          disabled={isActiveDelete || isActiveDisable}
          erroMsg="Correo inválido"
          size={sizeSmall ? 'small' : 'standard'}
        />
      </div>
      <div style={{ width: '20%' }}>
        {!isActiveDelete ? (
          <Tooltip title="Agregar" placement="bottom" arrow>
            <Button
              variant="outlined"
              size="large"
              sx={{
                ...buttonContactStyle,
                height: sizeSmall ? '90' : buttonContactStyle.height
              }}
              onClick={onSave}
              disabled={isActiveDisable}
            >
              <Add />
            </Button>
          </Tooltip>
        ) : (
          <Tooltip title="Eliminar" placement="bottom" arrow>
            <Button
              variant="outlined"
              size="large"
              sx={{
                ...buttonContactStyleError,
                height: sizeSmall ? '90' : buttonContactStyle.height
              }}
              onClick={onDeleteForm}
              disabled={isActiveDisable}
            >
              <Delete />
            </Button>
          </Tooltip>
        )}
      </div>
    </div>
  );
};