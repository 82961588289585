import {
  AccountBalanceSharp,
  ArrowBackIos,
  ArrowForwardIos,
  StoreMallDirectory
} from '@mui/icons-material';
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from '@mui/material';
import { useRef, useState } from 'react';
import { cardList as cardsItems } from '../../../helpers/getData';
import { CardConfirmIcon } from './CardConfirmIcon';
import UnpublishedIcon from '@mui/icons-material/Unpublished';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { useAuthPage } from '../../../hooks/useAuthPage';
export const ConfirmationTitle = ({
  title,
  cardList = cardsItems,
  typeView = 'EFR',
  id,
  entity = null,
  changeStatusFunction = null
}) => {
  const scrollRef = useRef(null);
  const [scrollX, setScrollX] = useState(0);
  const [scrollEnd, setScrollEnd] = useState(false);
  const [showConfirmDialog, setShowConfirmDialog] = useState(false);

  const { setAllowComponent } = useAuthPage();

  const handleSubmitDialog = () => {
    setShowConfirmDialog(false)
    changeStatusFunction({ section: typeView === 'EFR' ? "estatus" : "estatusCadena", saveSection: true })
  }

  const handleCloseConfirmDialog = () => {
    setShowConfirmDialog(false)
  }

  const slide = (shift) => {
    scrollRef.current.scrollLeft += shift;
    setScrollX(scrollX + shift);

    if (
      Math.floor(scrollRef.current.scrollWidth - scrollRef.current.scrollLeft) <=
      scrollRef.current.offsetWidth
    ) {
      setScrollEnd(true);
    } else {
      setScrollEnd(false);
    }
  };

  const scrollCheck = () => {
    setScrollX(scrollRef.current.scrollLeft);
    if (
      Math.floor(scrollRef.current.scrollWidth - scrollRef.current.scrollLeft) <=
      scrollRef.current.offsetWidth
    ) {
      setScrollEnd(true);
    } else {
      setScrollEnd(false);
    }
  };

  const getStatus = () => {
    return entity.nIdEmisor ? entity.nIdEstatusEFR : entity.nIdEstatusCadena
  }

  return (
    <>
      {entity &&
        <Dialog
          open={showConfirmDialog}
          onClose={handleCloseConfirmDialog}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            ¿Estas seguro/a de {getStatus() !== 1 ? 'activar' : 'desactivar'} {entity.nIdEmisor ? "la EFR" : "el comisionista"}?
          </DialogTitle>
          <DialogActions>
            <Button onClick={handleCloseConfirmDialog}>Cancelar</Button>
            <Button onClick={handleSubmitDialog} variant="contained">
              Sí, quiero {getStatus() !== 1 ? 'activar' : 'desactivar'} {entity.nIdEmisor ? "la EFR" : "el comisionista"}
            </Button>
          </DialogActions>
        </Dialog>
      }
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          width: '99%',
          marginBottom: '10px',
          justifyContent: 'space-between'
        }}
      >
        <div style={{ display: 'flex', flexDirection: 'row' }}>
          {typeView === 'EFR' ? (
            <AccountBalanceSharp
              fontSize={'large'}
              sx={{ color: '#00CD68', fontSize: '45px' }}
            />
          ) : (
            <StoreMallDirectory
              fontSize={'large'}
              sx={{ color: '#00CD68', fontSize: '45px' }}
            />
          )}
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <Typography
              variant="p"
              sx={{
                marginLeft: '15px',
                fontSize: '14px',
                marginBottom: '4px'
              }}
            >
              Ver
            </Typography>
            <Typography
              variant="p"
              sx={{
                marginLeft: '15px',
                color: '#00609C',
                fontSize: '22px'
              }}
            >
              {title} <span style={{ fontSize: 15, fontWeight: 500 }}>(ID: {id})</span>
            </Typography>
          </div>
        </div>
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          {(typeView === 'Cadenas' && [0,1].includes(getStatus()) && setAllowComponent("editarCadenas","cambiarEstatus")) && entity.nIdCadena && (
            <Button
              onClick={() => setShowConfirmDialog(true)}
              size="small"
              variant={"contained"}
              color={getStatus() !== 1 ? "primary" : "error"}
              startIcon={getStatus() !== 1 ? <CheckCircleIcon /> : <UnpublishedIcon />}
            >
              {getStatus() !== 1 ? 'Activar comisionista' : 'Desactivar comisionista'}
            </Button>
          )}
          {(typeView === 'EFR' && [0,1].includes(getStatus()) && setAllowComponent("editarEFR","cambiarEstatus")) && entity.nIdEmisor && (
            <Button
              onClick={() => setShowConfirmDialog(true)}
              size="small"
              variant={"contained"}
              color={getStatus() !== 1 ? "primary" : "error"}
              startIcon={getStatus() !== 1 ? <CheckCircleIcon /> : <UnpublishedIcon />}
            >
              {getStatus() !== 1 ? 'Activar EFR' : 'Desactivar EFR'}
            </Button>
          )}
        </div>
      </Box>
      <div style={{ display: 'flex', flexDirection: 'row', width: '100%' }}>
        <div
          className="card_confirmacion-arrow-container"
          onClick={() => {
            scrollX !== 0 && slide(-50);
          }}
        >
          <ArrowBackIos sx={{ color: '#828282' }} />
        </div>
        <div
          className="card_confirmacion-container-icon"
          id="card_confirmacion-container-icon"
          ref={scrollRef}
          onScroll={scrollCheck}
        >
          {cardList.map((item, i) => (
            <CardConfirmIcon
              key={i + item.id + 'confir'}
              icon={item.icon}
              title={item.title}
              id={item.id + '-confirmation'}
            />
          ))}
        </div>
        <div
          className="card_confirmacion-arrow-container"
          onClick={() => {
            !scrollEnd && slide(50);
          }}
        >
          <ArrowForwardIos sx={{ color: '#828282' }} />
        </div>
      </div>
    </>
  );
};
