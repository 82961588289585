import { Box, Tab, Tabs } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useCadenasTable } from '../hooks/useCadenasTable';
import { ModalBase } from './modal/ModalBase';
import { useModal } from '../hooks/useModal';
import { FormConciliacion } from './FormConciliacion';
import { useAuthPage } from '../hooks/useAuthPage';
import { CadenasConciliacionTable } from './CadenasConciliacionTable';

function allyProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`
  };
}

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

export const ConciliacionSearch = () => {
  const { loadInInit } = useCadenasTable();
  const { isOpenConciliacion, closeModalConciliacion } = useModal();
  const [value, setValue] = useState(0);
  const handleChange = (e, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    loadInInit();
    // eslint-disable-next-line
  }, []);

  return (
    <Box sx={{ backgroundColor: 'white', height: 'calc(100vh - 100px)' }}>
      <ModalBase open={isOpenConciliacion} onClose={closeModalConciliacion}>
        <FormConciliacion />
      </ModalBase>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs value={value} onChange={handleChange}>
          <Tab label="Emisores" {...allyProps(0)} />
          <Tab label="Cadenas" {...allyProps(1)} />
        </Tabs>
      </Box>
      <TabPanel value={value} index={0}>
        <Box sx={{ border: 1, width: '100%', height: 'calc(100vh - 200px)' }}>
          Tabla de Emisores
        </Box>
      </TabPanel>
      <TabPanel value={value} index={1}>
        <Box sx={{ width: '100%', height: 'calc(100vh - 200px)' }}>
          <CadenasConciliacionTable />
        </Box>
      </TabPanel>
    </Box>
  );
};
