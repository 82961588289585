import DeleteIcon from "@mui/icons-material/DeleteTwoTone";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import moment from "moment";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { Box } from "@mui/system";
import { ModalBase } from "./ModalBase";
import { SearchInput } from "../form/SearchInput";
import { useEffect, useState } from "react";
import { Button, Dialog, DialogActions, DialogTitle, Grid, TextField, Typography } from "@mui/material";
import { useModal } from "../../hooks/useModal";

export const ModalDeleteEntity = (props) => {
  const {
    entidad,
    showDeleteDialog,
    setShowDeleteDialog,
    motivosBaja,
    deleteFunction,
    rowId = null
  } = props

  const [showConfirmDeleteDialog, setShowConfirmDeleteDialog] = useState(false)
  const { setOpenToast } = useModal()
  const [bajaForm, setBajaForm] = useState({
    nIdMotivoBaja: null,
    dFecBaja: moment().format('YYYY-MM-DD')
  })
  const todaysDate = moment().format('YYYY-MM-DD')

  useEffect(() => {
    if (entidad.nIdBaja) {
      setBajaForm({
        nIdMotivoBaja: entidad.nIdMotivoBaja,
        dFecBaja: entidad.dFecBaja
      })
    } else {
      setBajaForm({
        nIdMotivoBaja: null,
        dFecBaja: moment().format('YYYY-MM-DD')
      })
    }
  }, [entidad])


  const openConfirmDeleteDialog = () => {
    if (bajaForm.nIdMotivoBaja && moment(bajaForm.dFecBaja).isSameOrAfter(moment(todaysDate))) {
      setShowDeleteDialog(false)
      setShowConfirmDeleteDialog(true)
    } else {
      if (!bajaForm.nIdMotivoBaja) setOpenToast(true, "El motivo de la baja es obligatorio")
      if (!moment(bajaForm.dFecBaja).isSameOrAfter(moment(todaysDate))) setOpenToast(true, "La fecha de la baja debe de ser una fecha mayor o igual al dia de hoy")
    }
  };

  const closeConfirmDeleteDialog = () => {
    setShowConfirmDeleteDialog(false)
    setShowDeleteDialog(true)
  };

  const onDeleteEmisor = async () => {
    setShowConfirmDeleteDialog(false)
    setShowDeleteDialog(false)
    const keyId = rowId ?? (entidad.nIdEmisor ? 'nIdEmisor' : 'nIdCadena')
    deleteFunction({
      ...bajaForm,
      [keyId]: entidad[keyId]
    })
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={"es-mx"}>
      <ModalBase open={showDeleteDialog} style={{ width: "500px", height: "350px" }}>
        <Box
          sx={{
            width: "100%",
            height: "100%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
          }}
        >
          <Grid container style={{ alignItems: "space-between" }}>
            <Grid container item sm={8} style={{ alignItems: "center" }}>
              <Grid
                item
                style={{
                  color: "#d32f2f",
                  padding: 15,
                  fontWeight: "bold",
                }}
              >
                <DeleteIcon fontSize={"large"} />
              </Grid>
              <Grid item>
                <Typography variant="p" color={"neutral"}>
                  {entidad.sNombreComercial}
                </Typography>
                <Typography variant="h5" color={"primary"}>
                  {entidad.nIdBaja ? "Solicitud de baja creada" : "Crear solicitud de baja"}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid container spacing={2} style={{ padding: "20px 10px" }}>
            <Grid item xs={12}>
              <SearchInput
                options={motivosBaja}
                required
                value={bajaForm.nIdMotivoBaja}
                name={"nIdMotivoBaja"}
                label={"Motivo de la baja"}
                getOptionLabel={"sDescripcion"}
                getIndexLabel={"nIdMotivoBaja"}
                onChange={(e) => setBajaForm({
                  ...bajaForm,
                  [e.target.name]: e.target.value
                })}
                disabled={entidad.nIdBaja}
              />
            </Grid>
            <Grid item xs={12}>
              <DesktopDatePicker
                label="Fecha de baja"
                inputFormat="YYYY-MM-DD"
                required
                disablePast
                renderInput={(params) => (
                  <TextField {...params} style={{ width: "100%" }} />
                )}
                value={bajaForm.dFecBaja}
                disabled={entidad.nIdBaja}
                onChange={(e) => {
                  setBajaForm({
                    ...bajaForm,
                    dFecBaja: moment(e["$d"]).format("YYYY-MM-DD")
                  })
                }}
              />
            </Grid>
          </Grid>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              width: "100%",
              justifyContent: "end",
            }}
          >
            <Button onClick={() => setShowDeleteDialog(false)} sx={{ color: "#00609C" }}>
              cancelar
            </Button>
            {!entidad.nIdBaja ? (
              <Button onClick={openConfirmDeleteDialog} variant="contained">
                Guardar
              </Button>
            ) : (
              <></>
            )}
          </div>

        </Box>
      </ModalBase>
      <Dialog
        open={showConfirmDeleteDialog}
        onClose={closeConfirmDeleteDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"¿Estas seguro/a de dar de baja este registro? "}
        </DialogTitle>
        <DialogActions>
          <Button onClick={closeConfirmDeleteDialog}>Cancelar</Button>
          <Button onClick={onDeleteEmisor} variant="contained">
            Quiero dar de baja este registro
          </Button>
        </DialogActions>
      </Dialog>

    </LocalizationProvider>
  );
};
