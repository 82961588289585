import { Auth } from 'aws-amplify';
import { useNavigate } from 'react-router-dom';
import { loading } from '../slices/ui/uiSlice';
import {
  sentCode,
  reset
} from '../slices/forgotPassword/forgotPasswordSlice';
import { useDispatch, useSelector } from 'react-redux';

import { useModal } from './useModal';
import { regexFormatPassword } from '../helpers/regexs';

export const useForgotPassword = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { sendCodeDisplay, changePasswordDisplay } = useSelector(
    (state) => state.forgotPassword
  );
  const { setOpenToast } = useModal();

  const sendConfirmationCode = (username) => {
    dispatch(loading(true));
    Auth.forgotPassword(username)
      .then((data) => {
        console.log(data);
        dispatch(sentCode());
        dispatch(loading(false));
      })
      .catch((err) => {
        dispatch(loading(false));
        console.log(err);
      });
  };

  const setNewPassword = (username, code, newPassword) => {
    if (!regexFormatPassword.test(newPassword)) {
      setOpenToast(true, "Formato incorrecto de contraseña.")
      return
    }

    dispatch(loading(true));
    Auth.forgotPasswordSubmit(username, code, newPassword)
      .then((data) => {
        console.log('Se redireccion a login: ', data);
        setOpenToast(false, 'Cambio de contraseña exitoso');
        navigate('/login');
      })
      .catch((err) => {
        console.log(err.message);
        setOpenToast(true, 'Hubo un error revise los datos o intente más tarde.');
      })
      .finally(() => {
        dispatch(loading(false));
      });

  };

  const resetState = () => dispatch(reset());

  return {
    sendConfirmationCode,
    setNewPassword,
    sendCodeDisplay,
    changePasswordDisplay,
    resetState
  };
};
