
import { useState } from 'react';
import { ThemeProvider } from 'styled-components';
import { TablePagination } from '@mui/material';



export const usePaginacion = () => {

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const getPaginacionTable = ( length ) => {
    return (
        <TablePagination
          rowsPerPageOptions={[10, 25, 50, 100]}
          component="div"
          count={length}
          rowsPerPage={rowsPerPage}
          labelRowsPerPage=""
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          labelDisplayedRows={
            ({ from, to, count }) => {
              return '' + from + '-' + to + ' de ' + count
            }
          }
          sx={{ background: '#ffffff' }}
        />
    );
  }

  return {
    page,
    rowsPerPage,
    getPaginacionTable
  }
};