import { API } from "aws-amplify";
import { loading } from "../ui/uiSlice";

export const insertPublicKey = (publicKey) => {
    return async (dispatch) => {
        const params = {
          headers: {},
          response: false,
          body: {
            clientPubKey: publicKey
          },
        };
        let res = {
          ok: false,
          msg: "Error inesperado, intente más tarde."
        }
        try {
          dispatch(loading(true));
          const resp = await API.post("SofiPay", "/v1/middleman/public-keys", params);
          console.log(resp)
          res = {
            ok: resp?.nCodigo === 0,
            msg: resp?.sMensaje,
            data: {
              keyId: resp?.data?.keyId,
              secret: resp?.data?.Secret,
            }
          }
        } catch (error) {
          console.log("ocurrio un error " + error);
        } finally {
          dispatch(loading(false));
        }
      
        return res;
    };
};

export const getCadenaCredentials = () => {
  return async (dispatch) => {
      const params = {
        headers: {},
        response: false,
      };
      let res = {
        ok: false,
        msg: "Error inesperado, intente más tarde."
      }
      try {
        dispatch(loading(true));
        const resp = await API.get("usuarios", "/usuarios/credenciales", params);
        res = {
          ok: resp?.nCodigo === 0,
          msg: resp?.sMensaje,
          data: {
            keyId: resp?.data?.nKeyId,
            secret: resp?.data?.sSecret,
            clientId: resp?.data?.clientId,
          }
        }
      } catch (error) {
        console.log("ocurrio un error " + error);
      } finally {
        dispatch(loading(false));
      }
    
      return res;
  };
};