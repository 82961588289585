import { useEffect, useState } from "react";
import { useMenu } from "../hooks/useMenu";
import { useAuthPage } from "../hooks/useAuthPage";
import { Button, Card, Grid, InputAdornment, TextField, Tooltip, Typography } from "@mui/material";
import { createTheme, ThemeProvider } from "@mui/material";
import { esES } from "@mui/material/locale";
import moment from "moment";
import EnhancedTable from "../components/table/EnhancedTable";
import { useFacturasPage } from "../hooks/useFacturasPage";
import { SearchInput } from "../components/form/SearchInput";
import { SelectInput } from "../components/form/SelectInput";
import { Add, Delete, Edit, Check, FilterAltOutlined, Send } from '@mui/icons-material';
import DescriptionIcon from '@mui/icons-material/Description';
import { regexEmail } from "../helpers/regexs";
import { TextInput } from "../components/form/TextInput";
import { useModal } from "../hooks/useModal";
import { useSelector } from "react-redux";
import { ExpandedItemFacturas } from "../components/table/expandableItems/ExpandedItemFacturas";


const iconTheme = createTheme(
  {
    status: {
      danger: "#e53e3e",
    },
    palette: {
      primary: {
        main: "#00609C",
        dark: "#053e85",
      },
      neutral: {
        main: "#00CD68",
        light: "#053e85",
        dark: "#053e85",
        contrastText: "#fff",
      },
    },
  },
  esES
);

export const FacturasPage = () => {
  const {
    cfdis,
    formasPago,
    metodosPago,
    regimensFiscal,
    unidades,
    productoServicios,
    loadInInit,
    dataTable,
    authorizeItem,
    form,
    onChange,
    getFacturasData,
    emisores,
    cadenas,
    estatusFacturacion,
    getFilters,
    authorizeSelected,
    saveFacturas
  } = useFacturasPage();
  const { isTableLoading } = useSelector((state) => state.ui);
  const maxAuthorizedItems = 3
  const { setNameSection } = useMenu();
  const { setNewPage, isAllowed, setAllowComponent } = useAuthPage();
  const [loaded, setLoaded] = useState(false);
  const [expandableItem, setExpandableItem] = useState(0);
  const [selected, setSelected] = useState([]);
  const [authorizedItemsNum, setAuthorizedItemsNum] = useState(0)

  useEffect(() => {
    if (!loaded) {
      loadInInit();
      setNewPage("facturas");
      setNameSection("facturas");
      setLoaded(true);
    }
  }, []);

  useEffect(() => {
    setAuthorizedItemsNum(parseInt(dataTable.rows?.filter(row => row.authorized).length))
    if (parseInt(dataTable.rows?.filter(row => row.authorized).length) >= maxAuthorizedItems) {
      setSelected([])
    }
  }, [dataTable])


  const innerAuthorizeItem = (row) => {
    setExpandableItem(0)
    authorizeItem(row)
  }

  const getStatusColor = (nIdEstatus) => {
    switch (nIdEstatus) {
      case 1: return ({
        background: "#FFE74C",
        color: "#060606"
      })
      case 2: return({
        background: "#00CD68",
        color: "#FFFFFF"
      })
      case 3: return({
        background: "#009782",
        color: "#FFFFFF"
      })
      default: return({
        background: "#00609C",
        color: "#FFFFFF"
      })
    }
  }

  if (isAllowed) {
    return (
      <ThemeProvider theme={iconTheme}>
        <EnhancedTable
          table={{
            ...dataTable,
            columns: [
              ...dataTable?.columns,
              { field: 'sEstatusFacturacion', headerName: 'Estatus factura', type: 'render', flex: 1, renderFunction: (row) => {
                return <Grid style={{ display: "inline-block", background: getStatusColor(row.nIdEstatusFacturacion).background, padding: "4px 8px", borderRadius: "4px", color: getStatusColor(row.nIdEstatusFacturacion).color }}>{row.sEstatusFacturacion}</Grid>
              }
              },
              {
                field: 'authorized', headerName: 'Autorizado', type: 'render', flex: 0, renderFunction: (row) => {
                  return row.authorized ?
                    <Check color="neutral"/> : <></>
                }
              },
            ]
          }}
          rowId={'nId'}
          disableButtons={true}
          showExpandableTable={true}
          expandableItem={expandableItem}
          setExpandableItem={setExpandableItem}
          selected={selected}
          maxSelected={maxAuthorizedItems - authorizedItemsNum}
          setSelected={setSelected}
          selectedItemsButtons={
            <Button
              variant="contained"
              startIcon={<Check />}
              disabled={maxAuthorizedItems <= authorizedItemsNum}
              onClick={() => {
                authorizeSelected(selected)
                setSelected([])
              }}
            >
              Autorizar
            </Button>
          }
          loading={isTableLoading}
          getFilters={getFilters}
          extraFilters={(resetPagination)=>(
            <Grid container justifyContent={"space-between"} alignItems={"center"} sx={{ padding: 2 }}>
              <Grid item sm={1} xs={12}>
                Filtros:
              </Grid>
              <Grid item sm={9} xs={12}>
                <Grid container spacing={2} alignItems={"center"}>
                  <Grid item xs={12} sm={3}>
                    <SearchInput
                      options={[
                        {
                          id: 0,
                          name: "Todos",
                        },
                        {
                          id: 1,
                          name: "EFR",
                        },
                        {
                          id: 2,
                          name: "Comisionista",
                        },
                      ]}
                      value={form.nActor}
                      name={"nActor"}
                      label={"Entidad"}
                      getOptionLabel={"name"}
                      getIndexLabel={"id"}
                      onChange={onChange}
                    />
                  </Grid>
                  {form.nActor !== 0 &&
                    <Grid item xs={12} sm={4}>
                      {
                        form.nActor === 1 || form.nActor === '1' ?
                          <SearchInput
                            options={[{ nIdEmisor: 0, sNombreComercial: 'Todos' }, ...emisores?.rows]}
                            value={form.nIdEmisor}
                            name={"nIdEmisor"}
                            label={form.nActor ? "Entidad financiera" : ""}
                            getOptionLabel={"sNombreComercial"}
                            getIndexLabel={"nIdEmisor"}
                            onChange={onChange}
                          />
                          :
                          <SearchInput
                            options={[{ nIdCadena: 0, sNombreComercial: 'Todos' }, ...cadenas?.rows]}
                            value={form.nIdCadena}
                            name={"nIdCadena"}
                            label={form.nActor ? "Comisionista" : ""}
                            getOptionLabel={"sNombreComercial"}
                            getIndexLabel={"nIdCadena"}
                            onChange={onChange}
                          />
                      }

                    </Grid>
                  }
                  <Grid item xs={12} sm={4}>
                    <SearchInput
                      options={[{ nIdEstatusFacturacion: 0, sDescripcion: 'Todos' }, ...estatusFacturacion]}
                      value={form.nIdEstatusFacturacion}
                      name={"nIdEstatusFacturacion"}
                      label={"Estado de la factura"}
                      getOptionLabel={"sDescripcion"}
                      getIndexLabel={"nIdEstatusFacturacion"}
                      onChange={onChange}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item sm={2} xs={12} style={{ textAlign: "right" }}>
                <Button
                  variant="contained"
                  startIcon={<FilterAltOutlined />}
                  onClick={() => {
                    getFacturasData()
                    resetPagination()
                  }}
                >
                  Filtrar
                </Button>
              </Grid>
            </Grid>)
          }
          extraButtons={
            setAllowComponent("enviarFacturas") &&
            <>
              <Button
                variant="contained"
                startIcon={<Send />}
                disabled={authorizedItemsNum < 1}
                onClick={() => {
                  saveFacturas()
                  setSelected([])
                }}
              >
                Enviar {authorizedItemsNum} factura{authorizedItemsNum > 1 ? "s" : ""}
              </Button>
            </>
          }
          showCheckboxes={setAllowComponent("enviarFacturas") && (maxAuthorizedItems > authorizedItemsNum)}
          expandedItems={(id, row) => {
            return (
              <ExpandedItemFacturas
                disableAuthorizeButton={(maxAuthorizedItems <= authorizedItemsNum)}
                row={row}
                id={id}
                cfdis={cfdis}
                formasPago={formasPago}
                metodosPago={metodosPago}
                regimensFiscal={regimensFiscal}
                unidades={unidades}
                productoServicios={productoServicios}
                authorizeItem={innerAuthorizeItem}
                allowedEditAuthorize = {setAllowComponent("enviarFacturas")}
              />
            )
          }}
        />
      </ThemeProvider>
    );
  } else {
    setNameSection("");
    return (
      <Card>
        <Typography variant="h3">Contenido no disponible</Typography>
      </Card>
    );
  }
};

