import { Box, Paper, Typography } from "@mui/material";
import { useState } from "react";
import { moveScroll } from "../../../helpers/forms";


export const CardConfirmIcon = ({ icon, title, id }) => {
  const [elevation, setElevation] = useState(1);
  return (
    <Paper
      elevation={elevation}
      style={{
        padding: '10px',
        borderRadius: '7px',
        minWidth: '140px',
        width: '140px',
        height: '100px'
      }}
      onMouseOver={() => setElevation(4)}
      onMouseLeave={() => setElevation(1)}
      className="card_confirmation-icon"
      onClick={() => moveScroll(id)}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          width: '100%',
          height: '100%'
        }}
      >
        <Box
          sx={{
            height: '75%',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'center'
          }}
        >
          {icon}
        </Box>
        <Box
          sx={{
            height: '25%',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap'
          }}
        >
          <Typography
            variant="h6"
            color="#00609C"
            style={{
              fontWeight: '500',
              fontSize: '14.5px',
              borderTop: '1px solid #eaeaea',
              textAlign: 'center',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap'
            }}
          >
            {title}
          </Typography>
        </Box>
      </Box>
    </Paper>
  );
};
