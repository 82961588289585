import { useEffect, useState } from 'react';

import { ReportTable } from '../components/table/ReportTable';
import { useMenu } from '../hooks/useMenu';
import { useAuthPage } from '../hooks/useAuthPage';
import { Card, Typography } from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material';
import { ReportForm } from '../components/ReportForm';
import { useReportsPage } from '../hooks/useReportsPage';

const iconTheme = createTheme({
  status: {
    danger: '#e53e3e'
  },
  palette: {
    primary: {
      main: '#00609C',
      dark: '#053e85'
    },
    neutral: {
      main: '#00CD68',
      light: '#053e85',
      dark: '#053e85',
      contrastText: '#fff'
    }
  }
});

export const ReportesPage = () => {

  const { loadInInit } = useReportsPage();
  const { setNameSection } = useMenu();
  const { isAllowed } = useAuthPage();
  
  useEffect(() => {
      loadInInit();
  }, []);

  if (isAllowed) {
    return (
      <ThemeProvider theme={iconTheme}>
        <ReportForm />
        <ReportTable />
      </ThemeProvider>
    );
  } else {
    setNameSection('');
    return (
      <Card>
        <Typography variant="h3">Contenido no disponible</Typography>
      </Card>
    );
  }
};
