import { Box } from "@mui/system";
import { useModal } from "../../hooks/useModal";
import { ModalBase } from "./ModalBase";
import {
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  Tab,
  Tabs,
  TextField,
  Typography,
} from "@mui/material";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import moment from "moment";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { SearchInput } from "../form/SearchInput";
import CompareArrowsIcon from '@mui/icons-material/CompareArrows';
import { useEffect, useState } from "react";
import { useRegistrosBancariosPage } from "../../hooks/useRegistrosBancariosPage";
import { BlueSwitch } from "../../helpers/stylesMaterial";
import { alpha } from '@mui/material/styles';

export const ModalRegistrosOrdenes = (props) => {
  const {
    ordenesPagoCadenas,
    ordenesPagoEmisores,
    cortesEscalonadosEmisores,
    registrosBancariosCargosCadena,
    registrosBancariosCargosEmisor,
    cadenas,
    emisores,
    postRegistrosCorte
  } = useRegistrosBancariosPage();
  const { isOpenRegistrosOrdenes, closeModalRegistrosOrdenes } = useModal();
  const [form, setForm] = useState({
    nIdActor: 0,
    nIdActorRegistros: 0,
    isEmisor: 0,
    isEmpate: 0,
    isEscalonado: 0,
    onlyEscalonado: 0,
    dFecInicial: moment().format("YYYY-MM-DD"),
    dFecFinal: moment().format("YYYY-MM-DD"),
  })
  const [selectedOrdenPago, setSelectedOrdenPago] = useState({
    registrosBancarios: []
  })

  const [minDate, setMinDate] = useState("")
  const [filteredOrdenesPagoCadenas, setFilteredOrdenesPagoCadenas] = useState([])
  const [filteredOrdenesPagoEmisores, setFilteredOrdenesPagoEmisores] = useState([])
  const [filteredRegistrosBancariosCargosCadena, setFilteredRegistrosBancariosCargosCadena] = useState([])
  const [filteredRegistrosBancariosCargosEmisor, setFilteredRegistrosBancariosCargosEmisor] = useState([])

  const [cambios, setCambios] = useState([])

  useEffect(() => {
    if (isOpenRegistrosOrdenes) {
      applyFilters()
    } else {
      setForm({
        nIdActor: 0,
        nIdActorRegistros: 0,
        isEmisor: 0,
        isEmpate: 0,
        isEscalonado: 0,
        onlyEscalonado: 0,
        dFecInicial: moment().format("YYYY-MM-DD"),
        dFecFinal: moment().format("YYYY-MM-DD"),
      })
      setSelectedOrdenPago({
        registrosBancarios: []
      })
      setCambios([])

    }
  }, [isOpenRegistrosOrdenes])


  useEffect(() => {
    if (isOpenRegistrosOrdenes) {
      const minDateCadena = new Date(Math.min.apply(null, registrosBancariosCargosCadena.map(function (e) {
        return new Date(getCorrectDate(e.dFecha));
      })));
      const minDateEmisor = new Date(Math.min.apply(null, registrosBancariosCargosEmisor.map(function (e) {
        return new Date(getCorrectDate(e.dFecha));
      })));
      let innerMinDate = moment().startOf('month').format("YYYY-MM-DD")

      if (moment(minDateCadena).isValid() && moment(minDateEmisor).isValid()) {
        innerMinDate = minDateCadena > minDateEmisor ? moment(minDateEmisor).format("YYYY-MM-DD") : moment(minDateCadena).format("YYYY-MM-DD")
      } else {
        if (moment(minDateCadena).isValid()) innerMinDate = moment(minDateCadena).format("YYYY-MM-DD")
        if (moment(minDateEmisor).isValid()) innerMinDate = moment(minDateEmisor).format("YYYY-MM-DD")
      }
      setMinDate(innerMinDate)
      setForm({
        ...form,
        dFecInicial: innerMinDate
      })
    }
  }, [registrosBancariosCargosCadena, registrosBancariosCargosEmisor, isOpenRegistrosOrdenes])

  useEffect(() => {
    applyFilters()
  }, [form])

  useEffect(() => {
    if (isOpenRegistrosOrdenes) {
      setForm({
        ...form,
        nIdActor: 0,
        nIdActorRegistros: 0,
        dFecInicial: minDate,
        dFecFinal: moment().format("YYYY-MM-DD"),
      })
      setSelectedOrdenPago({
        registrosBancarios: []
      })
    }
  }, [form.isEmisor, form.isEmpate])

  useEffect(() => {
    if (isOpenRegistrosOrdenes) {
      if (form.isEscalonado) {
        setForm({
          ...form,
          nIdActor: 0,
          onlyEscalonado: 0,
          nIdActorRegistros: 0,
          isEmisor: 1,
          isEmpate: 0,
          dFecInicial: minDate ? minDate : moment().subtract(3, 'month').format("YYYY-MM-DD"),
          dFecFinal: moment().format("YYYY-MM-DD"),
        })
      } else {
        setForm({
          ...form,
          nIdActor: 0,
          onlyEscalonado: 0,
          nIdActorRegistros: 0,
          isEmisor: 0,
          isEmpate: 0,
          dFecInicial: minDate ? minDate : moment().subtract(3, 'month').format("YYYY-MM-DD"),
          dFecFinal: moment().format("YYYY-MM-DD"),
        })
      }

      setSelectedOrdenPago({
        registrosBancarios: []
      })
    }
  }, [form.isEscalonado])

  const addCambio = () => {
    let isInCambios = false
    let innerCambios = [...cambios];
    if (selectedOrdenPago.registrosBancarios.length > 0) {
      innerCambios = cambios.map(cambio => {
        if (cambio.nIdOrdenPago === selectedOrdenPago.nIdOrdenPago) {
          isInCambios = true
          return selectedOrdenPago
        } else { return cambio }
      })
      if (!isInCambios) {
        innerCambios = [
          ...innerCambios,
          {
            ...selectedOrdenPago,
            isEscalonado: form.isEscalonado,
          }
        ]
      }
    } else {
      innerCambios = innerCambios.filter(cambio => cambio.nIdOrdenPago !== selectedOrdenPago.nIdOrdenPago)
    }
    setCambios(innerCambios)

    setSelectedOrdenPago({
      registrosBancarios: []
    })
    setForm({
      ...form,
      nIdActor: 0,
      nIdActorRegistros: 0,
      dFecInicial: minDate ? minDate : moment().subtract(3, 'month').format("YYYY-MM-DD"),
      dFecFinal: moment().format("YYYY-MM-DD"),
    })
    applyFilters()
  }

  const applyFilters = () => {
    if (form.isEmisor) {
      setFilteredOrdenesPagoEmisores([...ordenesPagoEmisores.filter(ordenPago => (
        !isInCambios(form.isEmisor, true, ordenPago.nIdOrdenPago) &&
        ((form.isEmpate === 0 && ordenPago.nEmpate !== 0) || (form.isEmpate === 1 && ordenPago.nEmpate === 0)) &&
        (form.nIdActor === 0 || parseInt(ordenPago.nIdEmisor) === parseInt(form.nIdActor))
      )), ...cambios.filter(cambio => ((form.isEmpate === 1 && cambio.nEmpate === 0) && cambio.nActor === 1 && !cambio.isEscalonado))])

      setFilteredRegistrosBancariosCargosEmisor(
        [...(selectedOrdenPago.nActor === 1 && form.isEmpate ? selectedOrdenPago.registrosBancarios : []),
        ...registrosBancariosCargosEmisor.filter(registro => (
          (form.nIdActor === 0 || parseInt(registro.nIdEmisor) === parseInt(form.nIdActor)) &&
          (form.nIdActorRegistros === 0 || parseInt(registro.nIdEmisor) === parseInt(form.nIdActorRegistros))
          && moment(registro.dFecha).isSameOrAfter(moment(form.dFecInicial))
          && moment(registro.dFecha).isSameOrBefore(moment(form.dFecFinal))
          && !isInCambios(form.isEmisor, false, registro.nIdRegistroBancario)
        )
        )
        ]
      )
    } else {
      setFilteredOrdenesPagoCadenas([...ordenesPagoCadenas.filter(ordenPago => (
        !isInCambios(form.isEmisor, true, ordenPago.nIdOrdenPago) &&
        ((form.isEmpate === 0 && ordenPago.nEmpate !== 0) || (form.isEmpate === 1 && ordenPago.nEmpate === 0)) &&
        (form.nIdActor === 0 || parseInt(ordenPago.nIdCadena) === parseInt(form.nIdActor))
      )), ...cambios.filter(cambio => ((form.isEmpate === 1 && cambio.nEmpate === 0) && cambio.nActor === 0))])
      setFilteredRegistrosBancariosCargosCadena([...(selectedOrdenPago.nActor === 0 && form.isEmpate ? selectedOrdenPago.registrosBancarios : []),
      ...registrosBancariosCargosCadena.filter(registro => (
        (form.nIdActor === 0 || parseInt(registro.nIdCadena) === parseInt(form.nIdActor)) &&
        (form.nIdActorRegistros === 0 || parseInt(registro.nIdCadena) === parseInt(form.nIdActorRegistros)) &&
        moment(registro.dFecha).isSameOrAfter(moment(form.dFecInicial)) &&
        moment(registro.dFecha).isSameOrBefore(moment(form.dFecFinal)) &&
        !isInCambios(form.isEmisor, false, registro.nIdRegistroBancario)
      )
      )])
    }
  }

  const isInCambios = (isEmisor, isCorte, nId) => {
    let flag = false
    if (isCorte) {
      cambios.map(cambio => {
        if ((cambio.nIdOrdenPago === nId && cambio.nActor === isEmisor && (!isEmisor || (form.isEscalonado === cambio.isEscalonado)))) flag = true
      })
    } else {
      cambios.map(cambio => {
        cambio.registrosBancarios.map(registro => {
          if ((registro.nIdRegistroBancario === nId && cambio.nActor === isEmisor)) flag = true
        })
      })
    }
    return flag
  }

  const getCorrectDate = (date) => {
    return date[date?.length - 1] === "Z" ? date.slice(0, -1) : date
  }

  const handleRadio = (ordenPago) => {
    setSelectedOrdenPago({
      ...ordenPago,
      dFecha: moment(getCorrectDate(ordenPago.dFecha)).format("YYYY-MM-DD"),
      nActor: form.isEmisor,
      registrosBancarios: typeof ordenPago.registrosBancarios === 'string' ? JSON.parse(ordenPago.registrosBancarios) : ordenPago.registrosBancarios
    })
    setForm({
      ...form,
      nIdActorRegistros: form.isEmisor ? ordenPago.nIdEmisor : ordenPago.nIdCadena,
      dFecInicial: moment(getCorrectDate(ordenPago.dFecha)).format("YYYY-MM-DD")
    })
  }

  const handleCheckBox = (checked, registro) => {
    let registrosBancarios = selectedOrdenPago.registrosBancarios
    console.log(registrosBancarios, registro)
    if (checked) registrosBancarios.push({
      ...registro,
      created: true
    })
    else registrosBancarios = registrosBancarios.filter(rb => rb.nIdRegistroBancario !== registro.nIdRegistroBancario)

    let sum = 0

    registrosBancarios.map(registro => {
      sum += registro.nImporte
    })

    setSelectedOrdenPago({
      ...selectedOrdenPago,
      nImpDiferencia: Math.abs(selectedOrdenPago.nTotalPago - sum),
      nEmpate: sum === selectedOrdenPago.nTotalPago ? 1 : 0,
      registrosBancarios
    })
  }

  const onChange = (e, name = null, value = null) => {
    const inputName = name !== null ? name : e.target.name;
    const inputValue = value !== null ? value : e.target.value;
    const switchOptions = ["onlyEscalonado"];

    if (switchOptions.includes(inputName)) {
      setForm({ ...form, [inputName]: e.target.checked });
    } else {
      let valueForm = { ...form, [inputName]: inputValue };
      setForm(valueForm);
    }
  };

  const getRegistrosBancariosSum = () => {
    let sum = 0
    selectedOrdenPago.registrosBancarios.map(registro => {
      sum += registro.nImporte
    })
    return (sum)
  }

  const handleSumit = async () => {
    let data = []

    cambios.map(cambio => {
      cambio.registrosBancarios.map(registro => {
        if (registro.created) {
          data.push({
            nIdOrdenPago: cambio.nIdOrdenPago,
            nIdRegistroBancario: registro.nIdRegistroBancario,
            nActor: cambio.nActor ? 1 : 2
          })
        }
      })
    })

    await postRegistrosCorte(data, true)
  }

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={"es-mx"}>
      <ModalBase open={isOpenRegistrosOrdenes} style={{ width: "1300px", height: "820px" }}>
        <Box
          sx={{
            width: "100%",
            height: "100%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
          }}
        >
          <Grid container style={{ alignItems: "space-between" }} spacing={2}>
            <Grid container item sm={9} style={{ alignItems: "center" }}>
              <Grid
                item
                style={{
                  color: "#00CD68",
                  padding: 15,
                  fontWeight: "bold",
                }}
              >
                <CompareArrowsIcon fontSize={"large"} />
              </Grid>
              <Grid item>
                <Typography variant="p" color={"neutral"}>
                  Registros bancarios
                </Typography>
                <Typography variant="h5" color={"primary"}>
                  Asociar a ordenes de pago
                </Typography>
              </Grid>
            </Grid>

            <Grid
              item
              sm={3}
            >
              <SearchInput
                options={[
                  { id: 0, name: "Ordenes sin registros bancarios" },
                  { id: 1, name: "Ordenes con registros bancarios asociados" },
                ]}
                value={form.isEmpate}
                size="small"
                name={"isEmpate"}
                label={"Mostrar"}
                getOptionLabel={"name"}
                getIndexLabel={"id"}
                onChange={onChange}
              />
            </Grid>
          </Grid>
          <Grid container spacing={5}>
            <Grid item xs={12} sm={6} >

              <Grid container spacing={2} style={{ padding: 0 }}>
                <Grid item xs={12} sm={7}>
                  <Tabs
                    value={form.isEmisor}
                    onChange={(event, newValue) => setForm({ ...form, isEmisor: newValue })}
                  >
                    {!form.isEscalonado &&
                      <Tab
                        value={0}
                        label={
                          "Comisionistas"
                        }
                      />
                    }
                    <Tab
                      value={1}
                      label={
                        "EFR"
                      }
                    />
                  </Tabs>

                </Grid>
                <Grid item xs={12} sm={5}>
                  {form.isEmisor ? (
                    <SearchInput
                      options={[
                        { nIdEmisor: 0, sNombreComercial: "Todos" },
                        ...emisores.rows,
                      ]}
                      value={form.nIdActor}
                      name={"nIdActor"}
                      size="small"
                      label={"Entidad financiera"}
                      getOptionLabel={"sNombreComercial"}
                      getIndexLabel={"nIdEmisor"}
                      onChange={onChange}
                    />
                  ) : (
                    <SearchInput
                      options={[
                        { nIdCadena: 0, sNombreComercial: "Todos" },
                        ...cadenas.rows,
                      ]}
                      value={form.nIdActor}
                      size="small"
                      name={"nIdActor"}
                      label={"Comisionista"}
                      getOptionLabel={"sNombreComercial"}
                      getIndexLabel={"nIdCadena"}
                      onChange={onChange}
                    />
                  )}

                </Grid>
                <Grid item container justifyContent={"space-between"} alignItems={"center"} xs={12} sm={12}>
                  <h3 style={{ margin: 0, padding: 5 }}>Ordenes de pago <span style={{ fontSize: 14 }}>({form.isEmisor ? filteredOrdenesPagoEmisores.length : filteredOrdenesPagoCadenas.length})</span></h3>
                  {form.isEmpate ? <b>Monto total / Monto faltante </b> : <></>}
                </Grid>
              </Grid>
              <FormControl style={{ width: "100%", height: 500, overflow: "auto" }}>
                <RadioGroup
                >
                  {(form.isEmisor ? filteredOrdenesPagoEmisores : filteredOrdenesPagoCadenas).length > 0 ?
                    <table style={{ width: "100%", borderCollapse: "collapse" }}>
                      <tbody>
                        {
                          (form.isEmisor ? filteredOrdenesPagoEmisores : filteredOrdenesPagoCadenas).map(ordenPago => {
                            const checked = selectedOrdenPago.nIdOrdenPago === ordenPago.nIdOrdenPago
                            return (
                              <tr style={{ cursor: "pointer" }} onClick={() => handleRadio(ordenPago)}>
                                <td style={{ width: 40, padding: "0px 8px", background: checked ? "#f1f1f1" : "none", borderTopLeftRadius: 6, borderBottomLeftRadius: 6 }}><FormControlLabel
                                  control={<Radio checked={checked} />}
                                /></td>

                                <td style={{ background: checked ? "#f1f1f1" : "none" }}> <Grid container alignItems={"center"}> {
                                  cambios.filter(cambio => cambio.nIdOrdenPago === ordenPago.nIdOrdenPago && cambio.nActor === form.isEmisor && ((form.isEmpate === 0 && ordenPago.nEmpate !== 0) || (form.isEmpate === 1 && ordenPago.nEmpate === 0)) && form.isEscalonado === cambio.isEscalonado).length
                                    ? <div style={{ width: "10px", height: "10px", borderRadius: "50%", background: "#00cd68", display: "block", marginRight: 5 }}>.</div>
                                    : <></>
                                } ({form.isEscalonado ? (moment(ordenPago.dFecha).locale('es').format("MMMM YYYY").toUpperCase()) : (moment(ordenPago.dFecha).format("YYYY-MM-DD"))}) {ordenPago.sNombreComercial}</Grid></td>
                                <td style={{
                                  textAlign: "right",
                                  padding: "0px 8px",
                                  background: checked ? "#f1f1f1" : "none",
                                  borderTopRightRadius: 6,
                                  borderBottomRightRadius: 6
                                }}>
                                  <b> {ordenPago.nTotalPago.toLocaleString("en-US", {
                                    style: "currency",
                                    currency: "USD",
                                  })}</b>
                                  {form.isEmpate ? <b> / {ordenPago.nImpDiferencia?.toLocaleString("en-US", {
                                    style: "currency",
                                    currency: "USD",
                                  })} </b> : <></>}
                                </td>
                              </tr>
                            )
                          })
                        }
                      </tbody>
                    </table>
                    :
                    <Grid sx={{
                      mt: 2,
                      borderRadius: "8px",
                      padding: 5,
                      border: 1,
                      borderColor: 'primary.main',
                      color: 'primary.main',
                      textAlign: "center",
                      borderColor: 'primary.main',
                      bgcolor: (theme) =>
                        alpha(theme.palette.primary.main, theme.palette.action.activatedOpacity)
                    }}>Sin registros de ordenes de pago</Grid>
                  }
                </RadioGroup>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6} >
              <Grid container spacing={2} justifyContent={"flex-end"} style={{ padding: 0 }}>
                <Grid item xs={12} sm={4} style={{ alignItems: "center" }}>
                  {form.isEscalonado ?
                    <Grid container alignItems={"center"}>
                      <BlueSwitch
                        onChange={(e) => onChange(e, "onlyEscalonado")}
                        inputProps={{ 'aria-label': 'controlled' }}
                        name={"onlyEscalonado"}
                        checked={Boolean(form.onlyEscalonado)}
                        value={form.onlyEscalonado}
                      />
                      <p>Sólo escalonado</p>
                    </Grid>
                    : <></>
                  }
                </Grid>
                <Grid item xs={12} sm={4}>
                  <DesktopDatePicker
                    label="Fecha Inicial"
                    inputFormat="YYYY-MM-DD"
                    renderInput={(params) => <TextField {...params} size="small" />}
                    value={form.dFecInicial}
                    onChange={(e) =>
                      onChange(
                        null,
                        "dFecInicial",
                        moment(e["$d"]).format("YYYY-MM-DD")
                      )
                    }
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <DesktopDatePicker
                    label="Fecha final"
                    inputFormat="YYYY-MM-DD"
                    renderInput={(params) => <TextField {...params} size="small" />}
                    value={form.dFecFinal}
                    onChange={(e) =>
                      onChange(
                        null,
                        "dFecFinal",
                        moment(e["$d"]).format("YYYY-MM-DD")
                      )
                    }
                  />
                </Grid>
                <Grid item container justifyContent={"space-between"} alignItems={"center"} xs={12} sm={12} style={{ marginBottom: 8 }}>
                  <h3 style={{ margin: 0, padding: 5 }}>Registros bancarios  <span style={{ fontSize: 14 }}>({form.isEmisor ? filteredRegistrosBancariosCargosEmisor.length : filteredRegistrosBancariosCargosCadena.length})</span></h3>
                  <span style={{
                    padding: "8px 10px",
                    borderRadius: 6,
                    background: getRegistrosBancariosSum() === 0 && !selectedOrdenPago.nTotalPago ? "#f1f1f1" : getRegistrosBancariosSum() === selectedOrdenPago.nTotalPago ? "#00cd68" : getRegistrosBancariosSum() > selectedOrdenPago.nTotalPago ? "red" : "#FFE74C",
                    color: getRegistrosBancariosSum() === 0 && !selectedOrdenPago.nTotalPago ? "black" : getRegistrosBancariosSum() === selectedOrdenPago.nTotalPago ? "white" : getRegistrosBancariosSum() > selectedOrdenPago.nTotalPago ? "white" : "black"
                  }}>Suma: <b>{getRegistrosBancariosSum().toLocaleString("en-US", {
                    style: "currency",
                    currency: "USD",
                  })}</b></span>
                </Grid>
              </Grid>
              <div className="table-container" style={{ width: "100%", height: 500, overflow: "auto" }}>
                {(form.isEmisor ? filteredRegistrosBancariosCargosEmisor : filteredRegistrosBancariosCargosCadena).length > 0 ?
                  <table style={{ width: "100%", borderCollapse: "collapse" }}>
                    <tbody>
                      {
                        (form.isEmisor ? filteredRegistrosBancariosCargosEmisor : filteredRegistrosBancariosCargosCadena).map(registro => {
                          const innerRegistro = selectedOrdenPago.registrosBancarios.find(rb => rb.nIdRegistroBancario === registro.nIdRegistroBancario)
                          return (
                            <tr style={{
                              cursor: "pointer"
                            }}>
                              <td style={{ width: 40, background: innerRegistro ? "#f1f1f1" : "none", borderTopLeftRadius: 6, borderBottomLeftRadius: 6 }}>
                                <Checkbox checked={innerRegistro ? true : false} onChange={(e) => handleCheckBox(e.target.checked, registro)} disabled={(innerRegistro && !innerRegistro.created)} />
                              </td>

                              <td style={{ background: innerRegistro ? "#f1f1f1" : "none" }}>({registro.dFecha}) {registro.sNombreComercial}
                                <span style={{ background: "#00609C", padding: 5, borderRadius: 5, fontSize: 11, fontWeight: "bold", color: "white", marginLeft: 5, display: registro.nEscalonado ? "inline" : "none" }}>Escalonado</span></td>
                              <td style={{
                                padding: "0px 10px",
                                textAlign: "right",
                                background: innerRegistro ? "#f1f1f1" : "none",
                                borderTopRightRadius: 6,
                                borderBottomRightRadius: 6
                              }}>
                                <b>{registro.nImporte.toLocaleString("en-US", {
                                  style: "currency",
                                  currency: "USD",
                                })}</b></td>
                            </tr>
                          )
                        })
                      }
                    </tbody>
                  </table>
                  :
                  <Grid sx={{
                    mt: 2,
                    borderRadius: "8px",
                    padding: 5,
                    border: 1,
                    borderColor: 'primary.main',
                    color: 'primary.main',
                    textAlign: "center",
                    borderColor: 'primary.main',
                    bgcolor: (theme) =>
                      alpha(theme.palette.primary.main, theme.palette.action.activatedOpacity)
                  }}>Sin registros bancarios</Grid>
                }
              </div>
            </Grid>
          </Grid>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              width: "100%",
              justifyContent: "end",
              alignItems: "center"
            }}
          >
            <Button onClick={closeModalRegistrosOrdenes} sx={{ color: "#00609C" }}>
              cancelar
            </Button>
            <Button onClick={addCambio} variant="contained" color="neutral" disabled={!((selectedOrdenPago.nIdOrdenPago && selectedOrdenPago.registrosBancarios.some(registro => registro.created)) || cambios.some(cambio => selectedOrdenPago.nIdOrdenPago === cambio.nIdOrdenPago))}>
              {cambios.some(cambio => selectedOrdenPago.nIdOrdenPago === cambio.nIdOrdenPago) ? "Modificar" : "Agregar cambio"}
            </Button>
            <div style={{ height: 40, width: 2, background: "#f1f1f1", margin: "0 15px" }}></div>
            <Button onClick={handleSumit} variant="contained" disabled={!cambios.length}>
              Guardar {cambios.length ? cambios.length : ""} {cambios.length > 1 ? "cambios" : "cambio"}
            </Button>
          </div>
        </Box>
      </ModalBase>
    </LocalizationProvider>
  );
};
