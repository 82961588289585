import { alpha, Button, Collapse, Dialog, DialogActions, DialogContent, DialogTitle, FormControlLabel, Grid, IconButton, Paper, Radio, TextField, Tooltip, Typography } from "@mui/material";

import { FilterList, Delete, Save, FileDownload, ListAlt, FilterAltOutlined } from '@mui/icons-material';
import { useState } from "react";
import { useConciliacionManualPage } from "../hooks/useConciliacionManualPage";
import { SearchInput } from "./form/SearchInput";
import { ConciliacionManualForm } from "./ConciliacionManualForm"
import moment from "moment";
import { useAuthPage } from "../hooks/useAuthPage";

export const ConciliacionManual = () => {
    const {
        isOpenDetails,
        selectedDiference,
        selectedOperation,
        setIsOpenDetails,
        emisores,
        cadenas,
        form,
        onChange,
        onChangeInput,
        operacionesFiltered,
        diferenciasFiltered,
        handleSelected,
        handleCancel,
        isValueDiferent,
        formatPrice,
        comment,
        setComment,
        operacionesConciliadas,
        addOperacionConciliada,
        saveOperacionesConciliadas,
        isValueDiferentRow,
        deleteOperacionConciliada,
        exportDiferencias,
        handleCloseDeleteDialog,
        isOpenDeleteDialog,
        deleteDiference,
        handleOpenDeleteDialog,

        handleCloseConfirmDialog,
        handleOpenConfirmDialog,
        isOpenConfirmDialog,

        motivo,
        setMotivo,
        getConciliacionManual,
        isFormExpanded,
        handleFormExpanded,
        isDataLoaded
    } = useConciliacionManualPage();

    const { setAllowComponent } = useAuthPage();

    const isOpenDetailsOperation = (selectedDiference || selectedOperation)
    return (
        <>
            <Dialog
                open={isOpenDeleteDialog}
                onClose={handleCloseDeleteDialog}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    {"¿Estas seguro/a de eliminar este registro? "}
                </DialogTitle>
                <DialogContent>
                    <TextField
                        label="Motivo de eliminación"
                        multiline
                        fullWidth
                        rows={2}
                        sx={{ mt: 2 }}
                        value={motivo}
                        onChange={(e) =>
                            setMotivo(e.target.value)}
                        required
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseDeleteDialog}>Cancelar</Button>
                    <Button onClick={deleteDiference} disabled={!motivo} variant="contained">
                        Quiero eliminar este registro
                    </Button>
                </DialogActions>
            </Dialog>
            <Dialog
                open={isOpenConfirmDialog}
                onClose={handleCloseConfirmDialog}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    {`Se conciliarán ${operacionesConciliadas.length} operacion(es) ¿Estas seguro/a? `}
                </DialogTitle>
                <DialogActions>
                    <Button onClick={handleCloseConfirmDialog}>Cancelar</Button>
                    <Button onClick={saveOperacionesConciliadas} variant="contained">
                        Si, estoy seguro/a
                    </Button>
                </DialogActions>
            </Dialog>
            <ConciliacionManualForm
                emisores={emisores}
                cadenas={cadenas}
                form={form}
                onChange={onChange}
                onChangeInput={onChangeInput}
                getConciliacionManual={getConciliacionManual}
                isFormExpanded={isFormExpanded}
                handleFormExpanded={handleFormExpanded}
            />
            {
                isDataLoaded && <>
                    <Grid container xs={12} spacing={2} /* sx={{ maxHeight: "calc(100vh - 110px)", overflowY: "auto", pb: 3, width: "100%" }} */>
                        <Grid item xs={6}>
                            <Grid container justifyContent={"space-between"}>
                                <Typography variant="h6" fontWeight={600}>Diferencias <span style={{ fontSize: 14 }}>({diferenciasFiltered.length})</span></Typography>
                                <Button variant="contained" size="small" startIcon={<FileDownload />} onClick={exportDiferencias}>Exportar</Button>
                            </Grid>
                            <Grid sx={{ background: "white", borderRadius: 2, padding: 2, mt: 1, maxHeight: `calc(100vh - ${isOpenDetailsOperation ? 550 : 300}px)`, overflowY: "auto" }}>
                                {diferenciasFiltered.length ? <table style={{ width: "100%", borderCollapse: "collapse" }}>
                                    <tbody>
                                        {
                                            diferenciasFiltered.map(diferencia => {
                                                const checked = diferencia.nIdDiferencia === selectedDiference?.nIdDiferencia
                                                return (
                                                    <tr style={{ cursor: "pointer" }} onClick={() => {
                                                        if(!setAllowComponent("guardarCambios")) return
                                                        handleSelected("diferencia", diferencia)
                                                    }}>
                                                        {setAllowComponent("guardarCambios") ? <td style={{ width: 40, padding: "0px 8px", background: checked ? "#f1f1f1" : "none", borderTopLeftRadius: 6, borderBottomLeftRadius: 6 }}><FormControlLabel
                                                            control={<Radio checked={checked} />}
                                                        /></td> : <></>}
                                                        <td style={{ background: checked ? "#f1f1f1" : "none", padding: "5px 0" }}>{diferencia.sNombreComercial} ({moment(diferencia.dFecha).format("YYYY-MM-DD")}) <br></br> <span style={{ fontSize: 14 }}>Ref: {diferencia.sReferencia}</span></td>
                                                        <td style={{
                                                            textAlign: "right",
                                                            background: checked ? "#f1f1f1" : "none",
                                                        }}>
                                                            <b> {formatPrice(diferencia.nMonto)}</b>
                                                        </td>
                                                        <td style={{
                                                            width: 40, textAlign: "right",
                                                            padding: "0px 8px",
                                                            background: checked ? "#f1f1f1" : "none",
                                                            borderTopRightRadius: 6,
                                                            borderBottomRightRadius: 6
                                                        }}>
                                                            <IconButton onClick={() => handleOpenDeleteDialog(diferencia.nIdDiferencia)}>
                                                                <Delete />
                                                            </IconButton>
                                                        </td>
                                                    </tr>
                                                )
                                            })
                                        }
                                    </tbody>
                                </table> :
                                    <Grid sx={{
                                        mt: 2,
                                        borderRadius: "8px",
                                        padding: 5,
                                        border: 1,
                                        borderColor: 'primary.main',
                                        color: 'primary.main',
                                        textAlign: "center",
                                        borderColor: 'primary.main',
                                        bgcolor: (theme) =>
                                            alpha(theme.palette.primary.main, theme.palette.action.activatedOpacity)
                                    }}>Sin registros de diferencias</Grid>}
                            </Grid>
                        </Grid>
                        <Grid item xs={6}>
                            <Typography variant="h6" fontWeight={600}>Operaciones <span style={{ fontSize: 14 }}>({operacionesFiltered.length})</span></Typography>
                            <Grid sx={{ background: "white", borderRadius: 2, padding: 2, mt: 1, maxHeight: `calc(100vh - ${isOpenDetailsOperation ? 550 : 300}px)`, overflowY: "auto" }}>
                                {operacionesFiltered.length ? <table style={{ width: "100%", borderCollapse: "collapse" }}>
                                    <tbody>
                                        {
                                            operacionesFiltered.map(operacion => {
                                                const checked = operacion.nIdOperacion === selectedOperation?.nIdOperacion
                                                return (
                                                    <tr style={{ cursor: "pointer" }} onClick={() => {
                                                        if(!setAllowComponent("guardarCambios")) return
                                                        handleSelected("operacion", operacion)
                                                    }}>
                                                        {setAllowComponent("guardarCambios") ? <td style={{ width: 40, padding: "0px 8px", background: checked ? "#f1f1f1" : "none", borderTopLeftRadius: 6, borderBottomLeftRadius: 6 }}><FormControlLabel
                                                            control={<Radio checked={checked} /> }
                                                        /></td> : <></>}
                                                        <td style={{ background: checked ? "#f1f1f1" : "none", padding: "5px 0" }}>{operacion[selectedDiference?.nIdEmisor ? "sNombreComercialEmisor" : "sNombreComercialCadena"]} ({moment(operacion.dFecha).format("YYYY-MM-DD")}) <br></br> <span style={{ fontSize: 14 }}>Ref: {operacion.sReferencia}</span></td>
                                                        <td style={{
                                                            width: 40, textAlign: "right",
                                                            padding: "0px 8px",
                                                            background: checked ? "#f1f1f1" : "none",
                                                            borderTopRightRadius: 6,
                                                            borderBottomRightRadius: 6
                                                        }}>
                                                            <b> {formatPrice(operacion.nMonto)}</b>
                                                        </td>
                                                    </tr>
                                                )
                                            })
                                        }
                                    </tbody>
                                </table>
                                    :
                                    <Grid sx={{
                                        mt: 2,
                                        borderRadius: "8px",
                                        padding: 5,
                                        border: 1,
                                        borderColor: 'primary.main',
                                        color: 'primary.main',
                                        textAlign: "center",
                                        borderColor: 'primary.main',
                                        bgcolor: (theme) =>
                                            alpha(theme.palette.primary.main, theme.palette.action.activatedOpacity)
                                    }}>{selectedDiference ? "Sin registros de operaciones" : "Seleccione una diferencia para mostrar operaciones"}</Grid>}
                            </Grid>
                        </Grid>
                    </Grid>
                    {(isOpenDetailsOperation || operacionesConciliadas.length) && setAllowComponent("guardarCambios") ?
                        <Grid container sx={{ position: "fixed", width: `calc(100vw - 230px)`, bottom: 30, right: 25 }}>
                            <Paper elevation={4} style={{ width: "100%", padding: "20px 15px" }}>
                                <Collapse in={isOpenDetails} timeout="auto" unmountOnExit sx={{ width: "100%" }}>
                                    <Grid container justifyContent={"space-between"} sx={{ padding: 1 }}>
                                        <Typography variant="h6" color="primary">Operaciones conciliadas  <span style={{ fontSize: 14 }}>({operacionesConciliadas.length})</span></Typography>
                                        <Button onClick={() => setIsOpenDetails(!isOpenDetails)} size={"small"}>Ocultar</Button>
                                    </Grid>
                                    <Grid container justifyContent={"space-between"} sx={{ maxHeight: 400, overflowY: "auto" }}>
                                        <table className="conciliacion-table" style={{ width: "100%", borderCollapse: "collapse" }}>
                                            <thead>
                                                <tr>
                                                    <th>Diferencias</th>
                                                    <th>Operaciones</th>
                                                    <th>Comentario</th>
                                                    <th></th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    operacionesConciliadas.map(item => {
                                                        const {
                                                            id,
                                                            operacion,
                                                            diferencia,
                                                            comentario
                                                        } = item
                                                        return (
                                                            <>
                                                                <tr>
                                                                    <td>
                                                                        <p style={{ margin: 0, fontSize: 14, color: "black" }}>Nombre comercial: <b>{diferencia.sNombreComercial}</b></p>
                                                                        <p style={{ margin: 0, fontSize: 14, color: isValueDiferentRow(diferencia, operacion, "dFecha") ? "red" : "black" }}>Fecha: <b>{diferencia.dFecha}</b></p>
                                                                        <p style={{ margin: 0, fontSize: 14, color: isValueDiferentRow(diferencia, operacion, "sReferencia") ? "red" : "black" }}>Referencia: <b>{diferencia.sReferencia}</b></p>
                                                                        <p style={{ margin: 0, fontSize: 14, color: isValueDiferentRow(diferencia, operacion, "sAutorizacion") ? "red" : "black" }}>Autorización: <b>{diferencia.sAutorizacion}</b></p>
                                                                        <p style={{ margin: 0, fontSize: 14, color: isValueDiferentRow(diferencia, operacion, "sNombreProducto") ? "red" : "black" }}>Producto: <b>{diferencia.sNombreProducto}</b></p>
                                                                        <p style={{ margin: 0, fontSize: 14, color: isValueDiferentRow(diferencia, operacion, "nMonto") ? "red" : "black" }}>Monto: <b>{formatPrice(diferencia.nMonto)}</b></p>
                                                                        <p style={{ margin: 0, fontSize: 14, color: isValueDiferentRow(diferencia, operacion, "nComision") ? "red" : "black" }}>Comisión: <b>{formatPrice(diferencia.nComision)}</b></p>
                                                                    </td>
                                                                    <td>
                                                                        <p style={{ margin: 0, fontSize: 14, color: "black" }}>Nombre EFR: <b>{operacion.sNombreComercialEmisor}</b></p>
                                                                        <p style={{ margin: 0, fontSize: 14, color: "black" }}>Nombre Comisionista: <b>{operacion.sNombreComercialCadena}</b></p>
                                                                        <p style={{ margin: 0, fontSize: 14, color: isValueDiferentRow(diferencia, operacion, "dFecha") ? "red" : "black" }}>Fecha: <b>{operacion.dFecha}</b></p>
                                                                        <p style={{ margin: 0, fontSize: 14, color: isValueDiferentRow(diferencia, operacion, "sReferencia") ? "red" : "black" }}>Referencia: <b>{operacion.sReferencia}</b></p>
                                                                        <p style={{ margin: 0, fontSize: 14, color: isValueDiferentRow(diferencia, operacion, "sAutorizacion") ? "red" : "black" }}>Autorización: <b>{operacion.sAutorizacion}</b></p>
                                                                        <p style={{ margin: 0, fontSize: 14, color: isValueDiferentRow(diferencia, operacion, "sNombreProducto") ? "red" : "black" }}>Producto: <b>{operacion.sNombreProducto}</b></p>
                                                                        <p style={{ margin: 0, fontSize: 14, color: isValueDiferentRow(diferencia, operacion, "nMonto") ? "red" : "black" }}>Monto: <b>{formatPrice(operacion.nMonto)}</b></p>
                                                                        <p style={{ margin: 0, fontSize: 14, color: isValueDiferentRow(diferencia, operacion, "nComision") ? "red" : "black" }}>Comisión: <b>{formatPrice(operacion.nComision)}</b></p>
                                                                    </td>
                                                                    <td>
                                                                        {comentario}
                                                                    </td>
                                                                    <td><IconButton onClick={() => deleteOperacionConciliada(id)}>
                                                                        <Delete />
                                                                    </IconButton></td>
                                                                </tr>
                                                            </>
                                                        )
                                                    })
                                                }
                                            </tbody>
                                        </table>
                                    </Grid>
                                </Collapse>
                                {isOpenDetailsOperation &&
                                    <>
                                        <Grid container justifyContent={"space-between"} alignItems={"center"} sx={{ padding: 2, borderTop: isOpenDetails ? "1px solid #00000010" : "none", paddingTop: isOpenDetails ? 2 : 0 }}>
                                            <Grid item sm={4}>
                                                <Typography variant="h6" color={"primary"}>Diferencia</Typography>
                                                <p style={{ margin: 0, fontSize: 14, color: "black" }}>Nombre del archivo: <b>{selectedDiference.sNombre}</b></p>
                                                <p style={{ margin: 0, fontSize: 14, color: "black" }}>Nombre comercial: <b>{selectedDiference.sNombreComercial}</b></p>
                                                <p style={{ margin: 0, fontSize: 14, color: isValueDiferent("dFecha") ? "red" : "black" }}>Fecha: <b>{selectedDiference.dFecha}</b></p>
                                                <p style={{ margin: 0, fontSize: 14, color: isValueDiferent("sReferencia") ? "red" : "black" }}>Referencia: <b>{selectedDiference.sReferencia}</b></p>
                                                <p style={{ margin: 0, fontSize: 14, color: isValueDiferent("sAutorizacion") ? "red" : "black" }}>Autorización: <b>{selectedDiference.sAutorizacion}</b></p>
                                                <p style={{ margin: 0, fontSize: 14, color: isValueDiferent("sNombreProducto") ? "red" : "black" }}>Producto: <b>{selectedDiference.sNombreProducto}</b></p>
                                                <p style={{ margin: 0, fontSize: 14, color: isValueDiferent("nMonto") ? "red" : "black" }}>Monto: <b>{formatPrice(selectedDiference.nMonto)}</b></p>
                                                <p style={{ margin: 0, fontSize: 14, color: isValueDiferent("nComision") ? "red" : "black" }}>Comisión: <b>{formatPrice(selectedDiference.nComision)}</b></p>
                                            </Grid>
                                            <Grid item sm={4}>
                                                <Typography variant="h6" color={"primary"}>Operación</Typography>
                                                <p style={{ margin: 0, fontSize: 14, color: "black" }}>Nombre EFR: <b>{selectedOperation?.sNombreComercialEmisor}</b></p>
                                                <p style={{ margin: 0, fontSize: 14, color: "black" }}>Nombre Comisionista: <b>{selectedOperation?.sNombreComercialCadena}</b></p>
                                                <p style={{ margin: 0, fontSize: 14, color: isValueDiferent("dFecha") ? "red" : "black" }}>Fecha: <b>{selectedOperation?.dFecha}</b></p>
                                                <p style={{ margin: 0, fontSize: 14, color: isValueDiferent("sReferencia") ? "red" : "black" }}>Referencia: <b>{selectedOperation?.sReferencia}</b></p>
                                                <p style={{ margin: 0, fontSize: 14, color: isValueDiferent("sAutorizacion") ? "red" : "black" }}>Autorización: <b>{selectedOperation?.sAutorizacion}</b></p>
                                                <p style={{ margin: 0, fontSize: 14, color: isValueDiferent("sNombreProducto") ? "red" : "black" }}>Producto: <b>{selectedOperation?.sNombreProducto}</b></p>
                                                <p style={{ margin: 0, fontSize: 14, color: isValueDiferent("nMonto") ? "red" : "black" }}>Monto: <b>{formatPrice(selectedOperation?.nMonto)}</b></p>
                                                <p style={{ margin: 0, fontSize: 14, color: isValueDiferent("nComision") ? "red" : "black" }}>Comisión: <b>{formatPrice(selectedOperation?.nComision)}</b></p>
                                            </Grid>
                                            <Grid item sm={4}>
                                                <TextField
                                                    label={"Comentario"}
                                                    multiline
                                                    fullWidth
                                                    rows={3}
                                                    name={"sMotivoCancelacion"}
                                                    value={comment}
                                                    onChange={(e) => setComment(e.target.value)}
                                                    required
                                                />
                                            </Grid>
                                        </Grid>
                                    </>
                                }
                                <Grid container justifyContent={"space-between"} alignItems={"center"} sx={{ borderTop: isOpenDetails || (selectedDiference || selectedOperation) ? "1px solid #00000010" : "none", paddingTop: isOpenDetails || (selectedDiference || selectedOperation) ? 2 : 0 }}>
                                    {isOpenDetailsOperation ?
                                        <Grid item></Grid>
                                        : <Grid item>{operacionesConciliadas.length > 1 ? `${operacionesConciliadas.length} operaciones conciliadas` : `1 operación conciliada`}<Button onClick={() => setIsOpenDetails(!isOpenDetails)} size={"small"}>Ver detalles</Button></Grid>}
                                    <Grid item>
                                        {isOpenDetailsOperation && <Button onClick={handleCancel}>Cancelar</Button>}
                                        <Button
                                            variant="contained"
                                            startIcon={<Save />}
                                            disabled={isOpenDetailsOperation && !(comment && selectedDiference && selectedOperation)}
                                            onClick={() => {
                                                if (isOpenDetailsOperation) addOperacionConciliada()
                                                else handleOpenConfirmDialog()
                                            }}
                                        >
                                            Guardar {isOpenDetailsOperation || operacionesConciliadas.length === 1 ? "operacion" : "operaciones"}
                                        </Button>
                                    </Grid>
                                </Grid>
                            </Paper>
                        </Grid>
                        : <></>}
                </>}
        </>
    );
};