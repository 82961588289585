import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { setIncorrectFormat, setMatchPassword } from '../slices/newPassword/newPasswordSlice';
import { useSetNewPassword } from './useSetNewPassword';
import { regexFormatPassword } from '../helpers/regexs';

export const useNewPassword = () => {
  const dispatch = useDispatch();
  const { notMatchPassword, incorrectFormat } = useSelector((state) => state.newPassword);
  const { user } = useSelector((state) => state.user);
  const { newPassword } = useSetNewPassword();

  const matchPassword = (password1, password2) => {
    if (!regexFormatPassword.test(password1)) {
      dispatch(setIncorrectFormat(true));
      return
    }
    
    if (password1 === password2) {
      dispatch(setMatchPassword(false));
      dispatch(setIncorrectFormat(false));
      newPassword(password1, user.username);
      console.log(notMatchPassword);
    } else {
      dispatch(setMatchPassword(true));
      console.log(notMatchPassword);
    }
  };

  return {
    matchPassword,
    notMatchPassword,
    incorrectFormat
  };
};
