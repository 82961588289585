import * as React from "react";
import MobileStepper from "@mui/material/MobileStepper";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { Box } from "@mui/system";
import { ModalBase } from "./ModalBase";
import {
  Button,
  Grid,
  Typography,
  Dialog,
  DialogActions,
  DialogTitle,
  styled,
  Paper,
  TextField,
  Alert,
  AlertTitle,
} from "@mui/material";
import { DropFileInput } from "../form/DropFileInput";
import LinearProgress, {
  linearProgressClasses,
} from "@mui/material/LinearProgress";
import AssignmentTurnedInIcon from '@mui/icons-material/AssignmentTurnedIn';
import StorefrontRoundedIcon from '@mui/icons-material/StorefrontRounded';
import { useImportSucursalesPage } from "../../hooks/useImportSucursalesPage";
import { SearchInput } from "../form/SearchInput";
import moment from "moment";
import { DesktopDatePicker, DesktopDateTimePicker, LocalizationProvider } from "@mui/x-date-pickers";
import "dayjs/locale/es-mx";
import { useSelector } from "react-redux";
import Spreadsheet from "react-spreadsheet";
import { useEffect } from "react";
import { Add, Delete, Download, PlusOne } from "@mui/icons-material";
import CustomSpreadsheet from "./modalDetail/CustomSpreadsheet";

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 20,
  borderRadius: 10,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: "#f8f8f8",
    border: "1px solid #979797",
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 10,
    backgroundColor: "#00CD68",
  },
}));

export const ModalImportSucursales = (props) => {
  const {
    isOpenImport,
    closeImport
  } = props
  const {
    isOpenConfirmDialog,
    setIsOpenConfirmDialog,
    importMethod,
    activeStep,
    handleDrop,
    progress,
    log,
    scrollDownRef,
    handleBack,
    confirmDelete,
    handleNext,
    processMessage,
    selectedFile,
    setSelectedFile,
    fileData,
    setFileData,
    filteredData,
    downloadPlantilla,
    form,
    onChange,
    setShowErrorsView,
    showErrorsView,
    filterXlsData,
    setFilterXlsData,
    xlsData,
    setXlsData,
    showErrors,
    downloadErrorsFile,
    processErrors,
    resetData,
    isBaja,
    motivosBaja,
    cadenas,
    cadenaName,
    setAllowComponent,
    customSpreadsheetData,
    handleSpreadsheetData
  } = useImportSucursalesPage();

  useEffect(() => {
    if (isOpenImport) {
      resetData()
    }
  }, [isOpenImport])



  return (
    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={"es-mx"}>
      <Dialog
        open={isOpenConfirmDialog}
        onClose={() => setIsOpenConfirmDialog(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {isBaja ? "Se darán de baja" : "Se importarán"} {filteredData.length} sucursal
          {filteredData.length > 1 ? "es" : ""} {cadenaName && `del comisionista ${cadenaName}`}. ¿Estas seguro/a?
        </DialogTitle>
        <DialogActions>
          <Button onClick={() => setIsOpenConfirmDialog(false)}>
            Cancelar
          </Button>
          <Button onClick={() => importMethod()} variant="contained">
            Estoy seguro/a
          </Button>
        </DialogActions>
      </Dialog>
      <ModalBase
        open={isOpenImport}
        disableLoading={!showErrorsView}
        style={{ width: "1150px" /*, height: "450px" */ }}
      >
        <Box
          sx={{
            width: "100%",
            height: "100%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
          }}
        >
          <Grid container style={{ alignItems: "space-between" }}>
            <Grid container item sm={6} style={{ alignItems: "center" }}>
              <Grid
                item
                style={{
                  color: "#00CD68",
                  padding: 15,
                  fontWeight: "bold",
                }}
              >
                <StorefrontRoundedIcon fontSize={"large"} />
              </Grid>
              <Grid item>
                <Typography variant="p" color={"neutral"}>
                  Importar
                </Typography>
                <Typography variant="h5" color={"primary"}>
                  Sucursales
                </Typography>
              </Grid>
            </Grid>
            <Grid
              item
              sm={6}
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-end",
              }}
            >
              {activeStep === 0 && (
                <>
                  {setAllowComponent("importarAltaSucursales") &&
                    <Button
                      onClick={() => downloadPlantilla()}
                      variant="contained"
                      size="small"
                      startIcon={<Download />}
                    >
                      Descargar plantilla de alta
                    </Button>
                  }
                  {setAllowComponent("importarBajaSucursales") &&
                    <Button
                      onClick={() => downloadPlantilla("baja")}
                      variant="contained"
                      size="small"
                      sx={{ ml: 1 }}
                      startIcon={<Download />}
                    >
                      Descargar plantilla de baja
                    </Button>
                  }
                </>
              )}
            </Grid>
          </Grid>
          {activeStep === 0 && (
            <DropFileInput
              handleDrop={handleDrop}
              selectedFile={selectedFile}
              setSelectedFile={setSelectedFile}
              setFileData={setFileData}
            />
          )}
          {activeStep === 1 && (
            <Grid container spacing={2} style={{ padding: 20 }}>
              <Grid
                item
                container
                xs={12}
                sm={12}
                alignItems={"center"}
                justifyContent={"space-between"}
              >
                <Typography variant="h6" color={"primary"}>
                  Configuración
                </Typography>
                <Typography variant="p" color={"primary"}>
                  {isBaja ? "Se darán de baja" : "Se importarán"}{" "}
                  <b>
                    {filteredData.length} sucursal
                    {filteredData.length > 1 ? "es" : ""}
                  </b>
                </Typography>
              </Grid>
              {isBaja &&
                <Grid item xs={12} sm={12}>
                  <Alert severity="warning">
                    <AlertTitle>Se darán de baja las sucursales del archivo</AlertTitle>
                    Se validaron que los encabezados son los requeridos para la baja de sucursales, si no es lo que desea revise los encabezados del archivo.
                  </Alert>
                </Grid>
              }
              <Grid item xs={12} sm={6}>
                <SearchInput
                  options={cadenas.rows}
                  value={form.nIdCadena}
                  name={"nIdCadena"}
                  label={"Comisionista"}
                  getOptionLabel={"sNombreComercial"}
                  getIndexLabel={"nIdCadena"}
                  onChange={onChange}
                />
              </Grid>
              {isBaja &&
                <Grid item xs={12} sm={6}>
                  <SearchInput
                    options={motivosBaja}
                    value={form.sMotivoBaja}
                    name={"sMotivoBaja"}
                    label={"Motivo de la baja (si no se define en archivo)"}
                    getOptionLabel={"sDescripcion"}
                    getIndexLabel={"sDescripcion"}
                    onChange={onChange}
                  />
                </Grid>
              }
              <Grid item xs={12} sm={6}>
                <DesktopDatePicker
                  label={isBaja ? "Fecha de baja (si no se define en archivo)" : "Fecha de inicio de operaciones (si no se define en archivo)"}
                  inputFormat="YYYY-MM-DD"
                  renderInput={(params) => (
                    <TextField {...params} fullWidth />
                  )}
                  disablePast
                  value={form.dFecInicioOperaciones}
                  onChange={(e) =>
                    onChange({
                      dFecInicioOperaciones: moment(e['$d']).format('YYYY-MM-DD'),
                    })
                  }
                />
              </Grid>
            </Grid>
          )}
          {activeStep === 2 && (
            <Grid
              container
              justifyContent={"center"}
              flexDirection={"column"}
              spacing={2}
              style={{ padding: 20, width: "100%", textAlign: "center" }}
            >
              <Grid item xs={12} sm={12}>
                <Typography variant="p" color={"primary"}>
                  Procesando archivo ({`${Math.round(progress)}%`})...
                </Typography>
              </Grid>
              <Grid item xs={12} sm={12}>
                <BorderLinearProgress
                  variant="determinate"
                  value={progress}
                />
              </Grid>
              <Grid item xs={12} sm={12} style={{ textAlign: "right" }}>
                <Typography variant="p" color={"primary"}>
                  {log.length}/{fileData?.filter(file => !file.nError).length} sucursales
                </Typography>
              </Grid>
              <Grid item xs={12} sm={12} style={{ textAlign: "left" }}>
                <Typography variant="p" color={"primary"}>
                  {log.length} sucursales:{" "}
                  <span style={{ color: "#00CD68" }}>
                    {log.filter((l) => l.nCodigo === 0).length} exitosas
                  </span>{" "}
                  /{" "}
                  <span style={{ color: "#DD0000" }}>
                    {log.filter((l) => l.nCodigo !== 0).length} con error
                  </span>
                </Typography>
              </Grid>
              <Grid
                item
                xs={12}
                style={{
                  backgroundColor: "#f8f8f8",
                  border: "1px solid #979797",
                  padding: "10px 15px 25px",
                  borderRadius: 8,
                  maxHeight: "200px",
                  overflowY: "auto",
                  textAlign: "left",
                }}
              >
                {log
                  .sort((a, b) => parseFloat(a.sUUID) - parseFloat(b.nFila))
                  .map((l) => (
                    <p
                      style={{
                        color: l.nCodigo === 0 ? "#00609C" : "#DD0000",
                        padding: 0,
                        margin: "0 0 5px 0",
                      }}
                    >
                      Fila [{parseInt(l.nFila) + 2}]: {l.sMensaje}
                    </p>
                  ))}
                <div
                  style={{ float: "left", clear: "both" }}
                  ref={scrollDownRef}
                ></div>
              </Grid>
              <Grid item xs={12} sm={12}></Grid>
            </Grid>
          )}
          {activeStep === 3 &&
            (showErrorsView ? (
              <>
                <Grid
                  container
                  justifyContent={"space-between"}
                  alignItems={"center"}
                >
                  <Grid item xs={4}>
                    <SearchInput
                      options={[
                        { id: 0, sNombre: "Registros con error" },
                        { id: 1, sNombre: "Todos" },
                        { id: 2, sNombre: "Registros exitosos" },
                      ]}
                      label={"Filtro"}
                      getOptionLabel={"sNombre"}
                      getIndexLabel={"id"}
                      value={filterXlsData}
                      style={{ width: 500 }}
                      onChange={(e) => setFilterXlsData(e.target.value)}
                    />
                  </Grid>

                  <Button onClick={downloadErrorsFile} variant="contained">
                    Descargar Excel
                  </Button>
                </Grid>
                <Grid
                  container
                  style={{
                    padding: 10,
                    width: "100%",
                    height: "580px"
                  }}
                >
                {/* <Spreadsheet data={xlsData} onChange={setXlsData} /> */}
                 <CustomSpreadsheet data={customSpreadsheetData} rowIdx={"nFila"} handleData={handleSpreadsheetData}/> 
                </Grid>
              </>
            ) : (<Grid
              container
              flexDirection={"column"}
              spacing={2}
              style={{ padding: 20, width: "100%" }}
            >
              <Grid item xs={12} sm={12}>
                <Typography variant="h6" color={"primary"}>
                  Sucursales procesadas
                </Typography>
              </Grid>
              <Grid item xs={12} sm={12} style={{ textAlign: "left" }}>
                <Typography variant="p" color={"primary"}>
                  {log.length} sucursales:{" "}
                  <span style={{ color: "#00CD68" }}>
                    {log.filter((l) => l.nCodigo === 0).length} exitosas
                  </span>{" "}
                  /{" "}
                  <span style={{ color: "#DD0000" }}>
                    {log.filter((l) => l.nCodigo !== 0).length} con error
                  </span>
                </Typography>
              </Grid>
              <Grid
                item
                xs={12}
                style={{
                  backgroundColor: "#f8f8f8",
                  border: "1px solid #979797",
                  padding: "10px 15px 15px",
                  borderRadius: 8,
                  maxHeight: "250px",
                  overflowY: "auto",
                  textAlign: "left",
                }}
              >
                {log
                  .sort((a, b) => parseFloat(a.nFila) - parseFloat(b.nFila))
                  .map((l) => (
                    <p
                      style={{
                        color: l.nCodigo === 0 ? "#00609C" : "#DD0000",
                        padding: 0,
                        margin: "0 0 5px 0",
                      }}
                    >
                      Fila [{parseInt(l.nFila) + 2}]: {l.sMensaje}
                    </p>
                  ))}
              </Grid>
              <Grid
                item
                container
                xs={12}
                sm={12}
                justifyContent={"flex-end"}
              >
                {log.filter((l) => l.nCodigo !== 0).length ? (

                  <Button onClick={showErrors} variant="contained">
                    Resolver errores
                  </Button>
                ) : (
                  <></>
                )}

              </Grid>
            </Grid>))
          }
          <MobileStepper
            variant="dots"
            steps={4}
            position="static"
            activeStep={activeStep}
            nextButton={<div style={{ width: 1, height: 1 }}></div>}
            backButton={<div style={{ width: 1, height: 1 }}></div>}
            sx={{
              "& .MuiMobileStepper-dot": {
                backgroundColor: "#00CD68",
                width: 12,
                height: 12,
              },
              "& .MuiMobileStepper-dotActive ~ .MuiMobileStepper-dot": {
                backgroundColor: "#D8D8D8",
                width: 12,
                height: 12,
              },
            }}
          />
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              width: "100%",
              justifyContent: "end",
            }}
          >
            {activeStep !== 3 && (
              <Button
                onClick={activeStep === 0 ? () => closeImport(false) : handleBack}
                sx={{ color: "#00609C" }}
              >
                {[0, 2].includes(activeStep) ? "Cancelar" : "Anterior"}
              </Button>
            )}
            {![3, 2].includes(activeStep) && (
              <Button
                onClick={activeStep === 1 ? confirmDelete : handleNext}
                variant="contained"
                disabled={activeStep === 0 && !selectedFile || activeStep === 1 && !form.nIdCadena}
              >
                Siguiente
              </Button>
            )}
            {activeStep === 3 && showErrorsView && (
              <Button
                onClick={() => setShowErrorsView(false)}
                sx={{ color: "#00609C" }}
              >
                Cancelar
              </Button>
            )}
            {activeStep === 3 && (
              <Button
                onClick={showErrorsView ? processErrors : closeImport}
                variant="contained"
              >
                {showErrorsView ? "Procesar" : "Finalizar"}
              </Button>
            )}
          </div>
        </Box>
      </ModalBase>

    </LocalizationProvider>
  );
};
