import { createSlice } from '@reduxjs/toolkit';
import { escalonadoForm, emailsFactura } from '../../helpers/forms';
import { v4 as uuidv4 } from 'uuid';

const initialState = {
  contactos        : [],
  cadenaId         : null,
  escalonadoForms  : [ { ...escalonadoForm, id: uuidv4() } ],
  direccionSearched: { colonia: '', estado: '', ciudad: '' },
  bancoSearched    : '',
  deleteInitList   : {},
  emailsFactura
}

export const efrSlice = createSlice({
  name: 'efr',
  initialState,
  reducers: {
    setContacto: ( state, action ) => {
      state.contactos = [...state.contactos, action.payload]
    },
    deleteContacto: ( state, action ) => {
      state.contactos = state.contactos.filter(
        (contacto) => contacto.email !== action.payload
      )
    },
    cleanContactosCadena: ( state ) => {
      state.contactos = []
    },
    setCadenaId: ( state, action ) => {
      state.cadenaId = action.payload
    },
    cleanCadenaId: ( state ) => {
      state.cadenaId = null
    },
    setEscalonadoForm: ( state, action ) => {
      state.escalonadoForms = [...state.escalonadoForms, action.payload]
    },
    cleanEscalonado: ( state ) => {
      state.escalonadoForms = [{
        ...escalonadoForm,
        id: uuidv4()
      }];
    },
    updateEscaladonoForm: ( state, action ) => {
      state.escalonadoForms = state.escalonadoForms.map((escalonado) => 
        escalonado.id.toString() === action.payload.id.toString() 
          ? action.payload 
          : escalonado
      )
    },
    deleteEsclonadoForm: ( state, action ) => {
      state.escalonadoForms = state.escalonadoForms.filter(
        (esca) => esca.id.toString() !== action.payload.toString()
      );
    },
    setDireccionSearched: ( state, action ) => {
      state.direccionSearched = { ...state.direccionSearched, ...action.payload }
    },
    setBancoSearched: ( state, action ) => {
      state.bancoSearched = action.payload;
    },

    setEmail: ( state, { payload } ) => {
      state.emailsFactura = { 
        ...state.emailsFactura, 
        [ payload.ref ]: [ ...state.emailsFactura[payload.ref], payload.email ]
      }
    },
    updateEmail: ( state, { payload } ) => {
      state.emailsFactura = {
        ...state.emailsFactura,
        [ payload.ref ]: state.emailsFactura[payload.ref].map((email) =>
          email.id === payload.email.id ? payload.email : email
        )
      };

    },
    deleteEmail: ( state, { payload } ) => {
      state.emailsFactura = {
        ...state.emailsFactura,
        [ payload.ref ]: state.emailsFactura[payload.ref].filter(
          (email) => email.id.toString() !== payload.id.toString()
        )
      };
    },
  },
})

export const { 
  setContacto,
  deleteContacto,
  cleanContactosCadena,
  setCadenaId,
  cleanCadenaId,
  setEscalonadoForm,
  cleanEscalonado,
  updateEscaladonoForm,
  deleteEsclonadoForm,
  setDireccionSearched,
  setBancoSearched,
  setEmail,
  updateEmail,
  deleteEmail,
} = efrSlice.actions;