import moment from "moment";
import jsPDF from "jspdf";
import autoTable from "jspdf-autotable";
import * as XLSX from "xlsx/xlsx.mjs";
var doc = new jsPDF();

export const useExportFile = () => {
    const downloadFile = (rows = [], columns = [], format = 'pdf', fileName, title, subtitle, addSum = false) => {

        let sum = {}
        columns.map(column => {
            sum = {
                ...sum,
                [column.field]: 0
            }
        })

        const file = {
            head: [columns.map(row => row.headerName)],
            body: [...rows.map(row => columns.map(column => {
                if (["price", "number"].includes(column.format)) {
                    sum[column.field] += row[column.field]
                }
                return getFormatedValue(row[column.field], column, row)
            })
            )]
        }

        if (addSum) file.body.push(columns.map(column => (["price", "number"].includes(column.format) ? getFormatedValue(sum[column.field], column.format) : "")))

        if (format === 'pdf') savePDFFile(file, fileName, title, subtitle)
        if (format === 'excel') saveXLSFile(file, fileName)
    }

    const getFormatedValue = (value, column, row) => {
        switch (column.format) {
            case "price":
                return value?.toLocaleString("en-US", {
                    style: "currency",
                    currency: "USD",
                });
            case 'percentage':
                return (value + "%")
            case "date":
                return value === "0000-00-00 00:00:00" || !value ? "" : moment(value[value?.length - 1] === "Z" ? value.slice(0, -1) : value).format("YYYY-MM-DD");
            case "datetime":
                return value === "0000-00-00 00:00:00" || !value ? "" : moment(value[value?.length - 1] === "Z" ? value.slice(0, -1) : value).format("YYYY-MM-DD HH:mm:ss");
            case "time":
                return value === "0000-00-00 00:00:00" || !value ? "" : moment(value[value?.length - 1] === "Z" ? value.slice(0, -1) : value).format("HH:mm:ss");
            case "render":
                return column.renderFunction(row)
            default:
                return String(value);
        }
    };

    const saveXLSFile = ({ head = [], body = [] }, name) => {
        const workbook = XLSX.utils.book_new();
        var worksheet1 = XLSX.utils.aoa_to_sheet([...head, ...body]);
        XLSX.utils.book_append_sheet(workbook, worksheet1, "Datos");
        XLSX.writeFile(workbook, `${name}.xlsx`);
    };

    const savePDFFile = async (file, name, title, subtitle) => {

        doc = new jsPDF("landscape");
        doc.setFont("helvetica");
        doc.setFontSize(9);
        autoTable(doc, {
            ...file,
            margin: { top: 28 },
            styles: { fontSize: 8 },
            didDrawPage: (data) => header(data, title, subtitle),
        });

        addFooters(doc)
        doc.save(`${name}.pdf`);
    }

    var header = function (data, title, subtitle) {
        doc.setFontSize(5);
        doc.addImage(
            "/assets/SofipayLogo.png",
            "JPEG",
            data.settings.margin.left,
            10,
            38,
            10
        );
        doc.setFont("helvetica", "bold");
        doc.setFontSize(11);
        doc.text(
            title,
            doc.internal.pageSize.width - 15,
            10,
            {
                align: "right",
            }
        );
        doc.setFont("helvetica", "normal");
        doc.setFontSize(11);
        doc.text(
            subtitle,
            doc.internal.pageSize.width - 15,
            15,
            {
                align: "right",
            }
        );

        doc.setFontSize(8);
        doc.text(
            "Red Efectiva SA de CV",
            55,
            11,
            {
                align: "left",
            }
        );
        doc.text(
            "Blvd. Antonio L. Rdz. 3058 Suite 201-A",
            55,
            14,
            {
                align: "left",
            }
        );
        doc.text(
            "Colonia Santa Maria",
            55,
            17,
            {
                align: "left",
            }
        );
        doc.text(
            "Monterrey, N.L. C.P. 64650",
            55,
            20,
            {
                align: "left",
            }
        );
    };

    const addFooters = doc => {
        const pageCount = doc.internal.getNumberOfPages()

        doc.setFont('helvetica', 'italic')
        doc.setFontSize(8)
        for (var i = 1; i <= pageCount; i++) {
            doc.setPage(i)
            doc.text('Página ' + String(i) + ' de ' + String(pageCount), doc.internal.pageSize.width - 10, 200, {
                align: 'right'
            })
        }
    }

    return {
        downloadFile
    }
}