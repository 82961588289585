import { ModalBase } from "./ModalBase";
import {
  Button,
  Grid,
  Typography,
} from "@mui/material";
import moment from "moment";
import { useModal } from "../../hooks/useModal";
import { Summarize } from "@mui/icons-material";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { useEffect, useState } from "react";


export const ModalOpsReferencia = () => {


  const { isOpenOpsReferencia, closeModalOpsReferencia } = useModal();
  const [selectedOperation, setSelectedOperation] = useState({})

  useEffect(() => {
    if (isOpenOpsReferencia.open) {
      setSelectedOperation(isOpenOpsReferencia.transaccion)
    } else {
      setSelectedOperation({})
    }
  }, [isOpenOpsReferencia])


  const formatDate = (date) => {
    if (date && date[date.length - 1] === "Z") {
      return date.slice(0, -1);
    } else {
      return date;
    }
  };



  const showModalBody = () => {
    return (
      <>
        {/* <Grid item xs={12} pl={1}>
          <CustomizedTabs
            tabs={operations.map(ops => ({ value: ops.nId, label: `${moment(formatDate(ops.dFecha)).format("YYYY-MM-DD")} ${ops.dHora}` }))}
            value={selectedOperation.nId}
            onChange={handleSelectedTab}
          />
        </Grid> */}
        <Grid item xs={12}>
          <Grid container padding={2} sx={{ background: "#f9f9f9", borderRadius: 2 }} spacing={1}>
            <DataRow text1={"Estado de la operación"} text2={`${selectedOperation.sTipoTransaccionTexto} (${selectedOperation.nTipoTransaccion})`} />
            <DataRow text1={"Fecha"} text2={moment(formatDate(selectedOperation.dFecha)).format("YYYY-MM-DD")} />
            <DataRow text1={"Hora"} text2={selectedOperation.dHora} />
            <DataRow text1={"ID transacción"} text2={selectedOperation.sTicket} />
            <DataRow text1={"Autorización"} text2={selectedOperation.sAutorizacion} />
            <DataRow text1={"Comisionista"} text2={selectedOperation.sNombreComercialCadena} />
            <DataRow text1={"EFR"} text2={selectedOperation.sNombreComercialEmisor} />
            <DataRow text1={"Caja"} text2={selectedOperation.sClaveEquipo} />
            <DataRow text1={"Cajero"} text2={selectedOperation.sClaveOperador} />
            <DataRow text1={"Producto"} text2={selectedOperation.sDescripcionProducto} />
            <DataRow text1={"Tipo de producto"} text2={selectedOperation.sTipoProducto} />
            <DataRow text1={"Clave de la sucursal"} text2={selectedOperation.sClaveSucursal} />
            <DataRow text1={"Nombre de la sucursal"} text2={selectedOperation.sNombreSucursal} />
            <DataRow text1={"Dirección de la sucursal"} text2={selectedOperation?.sDireccionSucursal} />
            <DataRow text1={"Comisión al cliente"} text2={selectedOperation?.nComisionCliente?.toLocaleString("en-US", {
              style: "currency",
              currency: "USD",
            })} />
            <DataRow text1={"Importe total"} text2={selectedOperation?.nImporte?.toLocaleString("en-US", {
              style: "currency",
              currency: "USD",
            })} />
          </Grid>
        </Grid>
      </>
    )
  }

  return (<>
    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={"es-mx"}>
      <ModalBase open={isOpenOpsReferencia.open} style={{ width: "100%", maxWidth: "850px" }}>
        <Grid container>
          <Grid container item sm={12} style={{ alignItems: "center" }}>
            <Grid
              item
              style={{
                color: "#00CD68",
                padding: 15,
                fontWeight: "bold",
              }}
            >
              <Summarize fontSize={"large"} />
            </Grid>
            <Grid item>
              <Typography variant="p" color={"neutral"}>
                Reporte de transacciones
              </Typography>
              <Typography variant="h5" color={"primary"}>
                Consultar transacción
              </Typography>
            </Grid>
          </Grid>
          <Grid container item sm={12} sx={{ mt: 1, mb: 2 }} spacing={0}>
            {showModalBody()}
          </Grid>

          <Grid container item sm={12} justifyContent={"flex-end"}>
            <Button onClick={closeModalOpsReferencia} sx={{ color: "#00609C" }}>
              cerrar
            </Button>
          </Grid>

        </Grid>
      </ModalBase>
    </LocalizationProvider>
  </>);
};


const DataRow = ({ text1, text2 }) => {
  return <>
    <Grid item xs={4} className="" sx={{ textAlign: "right", fontWeight: 600 }}>{text1}:</Grid><Grid item xs={8} className="">{text2}</Grid>
  </>
}
