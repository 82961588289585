import { Download, History, PictureAsPdf, Summarize } from '@mui/icons-material';
import { Button, Grid, Typography } from '@mui/material';
import { Box } from '@mui/system';
import { ModalBase } from './ModalBase';
import EnhancedTable from '../table/EnhancedTable';
import { useDispatch, useSelector } from 'react-redux';
import ButtonDropdown from '../ButtonDropdown';
import { useExportFile } from '../../hooks/useExportFile';
import { useEffect, useState } from 'react';
import { getEnvioCredencialesBitacora } from '../../slices/efr/efrThunk';
import moment from 'moment';
import { getArchivosBitacora, getConexionesBitacora } from '../../slices/conexionesSFTP/conexionesSFTPThunk';

const columns = {
  archivos: {
    nId: 'nIdArchivoConexionBitacora',
    columns: [
      { field: 'nIdArchivoConexionBitacora', headerName: 'ID', flex: 1, style: {width: 30} },
      { field: 'dFecRegistro', headerName: 'Fecha registro', flex: 4, type: "datetime", style: {width: 140} },
      { field: 'sUsuario', headerName: 'Usuario', flex: 2 },
      { field: 'sTipoBitacora', headerName: 'Tipo de bitacora', flex: 3 },
      { field: 'sNombreArchivo', headerName: 'Nombre del archivo', flex: 6 },
      { field: 'sNombreConexion', headerName: 'Nombre de la conexión', flex: 6 },
      { field: 'sHost', headerName: 'Host', flex: 5 },
      { field: 'nPort', headerName: 'Puerto', flex: 1 },
      { field: 'sUser', headerName: 'Usuario', flex: 4 },
      { field: 'sCarpeta', headerName: 'Carpeta', flex: 5 },
    ]
  },
  conexiones: {
    nId: 'nIdConfConexionBitacora',
    columns: [
      { field: 'nIdConfConexionBitacora', headerName: 'ID', flex: 1, style: {width: 30}},
      { field: 'dFecMovimiento', headerName: 'Fecha registro', flex: 2, type: "datetime", style: {width: 140} },
      { field: 'sUsuario', headerName: 'Usuario', flex: 2 },
      { field: 'sTipoBitacora', headerName: 'Tipo de bitacora', flex: 3 },
      { field: 'sNombreConexion', headerName: 'Nombre de la conexión', flex: 6 },
      { field: 'sHost', headerName: 'Host', flex: 5 },
      { field: 'nPort', headerName: 'Puerto', flex: 1 },
      { field: 'sUser', headerName: 'Usuario', flex: 4 },
      { field: 'sComentario', headerName: 'Comentario', flex: 5 },
    ]
  },
  conexionesSofiPay: {
    nId: 'nIdBitacora',
    columns: [
      { field: 'sNombreConexion', headerName: 'Nombre de la conexión', flex: 2 },
      { field: 'dFecRegistro', headerName: 'Fecha envío', flex: 2, type: "datetime", style: {width: 140} },
      { field: 'sNombreContacto', headerName: 'Nombre del contacto', flex: 3 },
      { field: 'sMail', headerName: 'Email', flex: 3 },
      { field: 'sNombreUsuarioBitacora', headerName: 'Nombre del usuario', flex: 3 }
    ]
  }
}


export const ModalBitacoraConexionesSFTP = (props) => {

  const {
    isOpen,
    onClose,
    typeBitacora,
    selectedEntity
  } = props;


  const { isTableModalLoading } = useSelector((state) => state.ui);
  const dispatch = useDispatch();
  const { downloadFile } = useExportFile()

  const [dataTable, setDataTable] = useState({
    rows: [],
    columns: []
  })

  useEffect(() => {
    if (isOpen) {
      getData()
    } else {
      setDataTable({
        rows: [],
        columns: []
      })
    }
  }, [isOpen])

  const getData = async () => {
    let data = []

    switch (typeBitacora) {
      case "archivos":
        data = await dispatch(getArchivosBitacora({ nIdCadena: selectedEntity.nIdCadena, nIdEmisor: selectedEntity.nIdEmisor }))
        break;
      case "conexiones":
        data = await dispatch(getConexionesBitacora({ nIdCadena: selectedEntity.nIdCadena, nIdEmisor: selectedEntity.nIdEmisor }))
        break;
      case "conexionesSofiPay":
        data = await dispatch(getEnvioCredencialesBitacora(selectedEntity.nIdCredencialSFTP))
        break;
      default:
        break;
    }

    setDataTable({
      rows: data,
      columns: columns[typeBitacora].columns,
      nId: columns[typeBitacora].nId
    })
  }

  const downloadBitacora = (format) => {
    let {
      rows = [],
      columns = [],
      nId
    } = dataTable
    const dFecha = moment().format("YYYY-MM-DD")
    const sNombreBitacora = getNameBitacora()
    downloadFile(
      rows.sort((a, b) => parseFloat(a[nId]) - parseFloat(b[nId])),
      columns.map(c => ({ ...c, format: c.type })),
      format,
      `Bitácora de ${sNombreBitacora} (${selectedEntity.sNombreComercial}, ${dFecha})`,
      `Bitácora de ${sNombreBitacora} (${selectedEntity.sNombreComercial})`,
      `Fecha: ${dFecha}`)
  }

  const getNameBitacora = () => {
    switch (typeBitacora) {
      case "archivos":
        return "archivos SFTP"
      case "conexiones":
        return "conexiones SFTP"
      case "conexionesSofiPay":
        return "envío de credenciales"
      default:
        return "";
    }
  }


  return (<>
    <ModalBase open={isOpen} title="" style={{ width: "1050px", height: 'auto' }}>
      <Box
        sx={{ width: '100%', height: '100%', display: 'flex', flexDirection: 'column' }}
      >
        <EnhancedTable
          rowId={dataTable.nId}
          table={dataTable}
          disableButtons
          isModalTable
          disablePathParameters
          isLoading={isTableModalLoading}
          icon={<History fontSize={'large'} sx={{ color: '#00CD68', fontSize: '45px' }} />}
          subtitle={`Bitácora de ${getNameBitacora()}`}
          title={selectedEntity?.sNombreComercial}
          extraButtons={<ButtonDropdown
            startIcon={<Download />} 
            label="Exportar"
            options={[
              { startIcon: <PictureAsPdf />, label: "PDF", action: () => downloadBitacora("pdf") },
              { startIcon: <Summarize />, label: "Excel", action: () => downloadBitacora("excel") },
            ]}
          />}
        />
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            width: '100%',
            justifyContent: 'end'
          }}
        >
          <Button onClick={onClose} color="primary">
            Volver
          </Button>
        </div>
      </Box>
    </ModalBase>
  </>
  );
};

