import { v4 as uuidv4 } from 'uuid';
import {
  Button,
  Grid,
  Paper,
  TextField,
} from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { useEffect } from "react";
import SearchIcon from "@mui/icons-material/Search";
import "dayjs/locale/es-mx";
import { useReportsPage } from "../hooks/useReportsPage";
import { SearchInput } from "./form/SearchInput";
import moment from "moment";
import { BlueSwitch } from "../helpers/stylesMaterial";
import { useModal } from "../hooks/useModal";
import {
  useParams
} from "react-router-dom";
import { TextInput } from "./form/TextInput";

export const ReportForm = (props) => {
  const { extraButtons } = props
  const { type } = useParams();

  const {
    cadenas,
    productos,
    emisores,
    form,
    onChange,
    getOperaciones,
    getOrdenesPago,
    getEscalonado,
    get24Horas,
    getCortes,
    estatusPago,
    getPagosCobros,
    getReporteOperaciones,
    getReporteGeneral,
    onChangeInput,
    getComisionesIntegrador,
    integradores,
    getReporteGeolocalizacion,
    getReporteSucursalesNoRegistradas,
    getTransacciones,
  } = useReportsPage();

  const { setOpenToast } = useModal();
  const currentDate = moment()

  const isFormCompleted = () => {
    switch (type) {
      case 'operaciones':
      case 'ordenes-pago':
      case 'escalonado':
      case '24-horas':
      case 'cortes':
      case 'pagos-cobros':
        return true;
      case 'reporte-operaciones':
      case 'reporte-general':
        return form.type !== 0;
      case 'comisiones-integrador':
        return !!form.nIdIntegrador
      case 'transacciones':
        return form.sReferencia && form.sReferencia.length === 22
      default:
        return true;
    }
  }
  const handleSubmit = () => {
    if (moment(form.dFecFinal).isBefore(moment(form.dFecInicial))) {
      setOpenToast(true, "La fecha inicial debe de ser menor o igual a la fecha final.")
      return
    }
    if (moment(form.dFecInicial).isAfter(moment())) {
      setOpenToast(true, "La fecha inicial debe de ser menor o igual a la fecha de hoy.")
      return
    }
    switch (type) {
      case 'operaciones':
        getOperaciones()
        break;
      case 'transacciones':
        getTransacciones()
        break;
      case 'ordenes-pago':
        getOrdenesPago()
        break;
      case 'escalonado':
        getEscalonado()
        break;
      case '24-horas':
        get24Horas()
        break;
      case 'cortes':
          getCortes()
          break;
      case 'pagos-cobros':
        getPagosCobros()
        break;
      case 'reporte-operaciones':
        getReporteOperaciones();
        break;
      case 'reporte-general':
        getReporteGeneral()
        break;
      case 'comisiones-integrador':
        getComisionesIntegrador()
        break;
      case 'geolocalizacion':
        getReporteGeolocalizacion()
        break;
      case 'sucursales-no-registradas':
        getReporteSucursalesNoRegistradas()
        break;
      default:
        return ('')
    }
  }

  const getName = () => {
    switch (type) {
      case 'operaciones':
        return ('Reporte de operaciones')
      case 'transacciones':
        return ('Reporte de transacciones')
      case 'ordenes-pago':
        return ('Reporte de ordenes de pago')
      case 'escalonado':
        return ('Reporte de comisiones escalonadas')
      case '24-horas':
        return ('Reporte 24 horas')
      case 'cortes':
          return ('Reporte cortes')
      case 'pagos-cobros':
        return ('Reporte de pagos y cobros')
      case 'reporte-operaciones':
        return ('Conciliación reporte de operaciones')
      case 'reporte-general':
        return ('Conciliación reporte general')
      case 'comisiones-integrador':
        return ('Reporte de comisiones a integrador')
      case 'geolocalizacion':
        return ('Reporte geolocalización')
      case 'sucursales-no-registradas':
        return ('Reporte sucursales no registradas')
      default:
        return ('')
    }
  }

  const showInput = (inputName) => {
    let operacionesArray = ["type", "actor", "productType", "sClaveProducto", "dFecInicial", "dFecFinal", "details"]
    let ordenesPagoArray = ["type", "actor", "nIdEstatusPago", "dFecInicial", "dFecFinal"]
    let ordenesPagoArray2 = ["type", "actor", "nIdEstatusPago", "nIdTipoPago", "dFecInicial", "dFecFinal"]
    let escalonadoArray = ["nIdEmisor", "dMonth", "dYear"]
    let veinticuatroHorasArray = ["nIdEmisor", "dFecInicial", "dFecFinal"]
    let cortessArray = ["type", "actor", "dFecInicial", "dFecFinal"]
    let pagosCobrosArray = ["type", "actor", "nTipoPago", "nPeriodo", ...(form.nPeriodo ? ["dMonth", "dYear"] : ["dFecInicial", "dFecFinal"])]
    let reporteOperaciones = ["type", "actor","sClaveProducto", "dFecInicial", "dFecFinal", "sArchivo"]
    let reporteGeneral = ["type", "actor", "dFecInicial", "dFecFinal"]
    let reporteComisiones = ["integradores", "dMonth", "dYear"]
    let reporteGeolocalizacion = ["nIdEmisor", "dMonth", "dYear"]
    let reporteSucursalesNoRegistradas = ["nIdCadena"]
    let reporteTransacciones = ["sReferencia", "sTicket", "sAutorizacion", "nFiltradoFechas"]
    switch (type) {
      case 'operaciones':
        return (operacionesArray.includes(inputName))
      case 'transacciones':
        return (reporteTransacciones.includes(inputName))
      case 'ordenes-pago':
        return ((parseInt(form.type) === 1 ? ordenesPagoArray : ordenesPagoArray2).includes(inputName))
      case 'escalonado':
        return (escalonadoArray.includes(inputName))
      case '24-horas':
        return (veinticuatroHorasArray.includes(inputName))
      case 'cortes':
        return (cortessArray.includes(inputName))
      case 'pagos-cobros':
        return (pagosCobrosArray.includes(inputName))
      case 'reporte-operaciones':
        return (reporteOperaciones.includes(inputName))
      case 'reporte-general':
        return (reporteGeneral.includes(inputName))
      case 'comisiones-integrador':
        return (reporteComisiones.includes(inputName))
      case 'geolocalizacion':
        return (reporteGeolocalizacion.includes(inputName))
      case 'sucursales-no-registradas':
        return (reporteSucursalesNoRegistradas.includes(inputName))
      default:
        return (false)
    }
  }

  const getYearsArray = () => {

    let initialYear = 2022
    let finalYear = parseInt(moment().format('YYYY'))

    let options = [
      {
        id: initialYear,
        name: String(initialYear)
      },
    ]

    while (initialYear < finalYear) {
      initialYear++
      options.push({
        id: initialYear,
        name: String(initialYear)
      })
    }

    return options
  }
  const getOptionsTypeReport = (type = '') =>{
    const allOptions = ['operaciones','cortes'].includes(type);
    return [
      allOptions && {
        id: 0,
        name: "Todos",
      },
      {
        id: 1,
        name: "EFR",
      },
      {
        id: 2,
        name: "Comisionista",
      },
    ];
  }

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={"es-mx"}>
      <Paper
        elevation={3}
        style={{
          marginBottom: 30,
          /*background: "#00609C",*/ overflow: "hidden",
        }}
      >
        <Grid container>
          <Grid
            item
            width={"50px"}
            alignItems={"center"}
            justifyContent={"center"}
            style={{
              background: "#00CD68",
              padding: extraButtons ? "20px 15px 0 15px" : 15,
              color: "white",
              fontWeight: "bold",
              marginBottom: 15,
            }}
          >
            <SearchIcon />
          </Grid>
          <Grid
            container
            item
            alignItems={"center"}
            justifyContent={"space-between"}
            style={{
              width: "calc(100% - 50px)",
              background: "#00609C",
              padding: 15,
              color: "white",
              fontWeight: "bold",
              marginBottom: 15,
            }}
          >
            <span>
              {getName()}
            </span>
            {extraButtons}
          </Grid>

        </Grid>
        <Grid container spacing={2} style={{ padding: 20 }} >
          <Grid item xs={12} sm={4} style={{ display: showInput("integradores") ? 'block' : 'none' }}>
            <SearchInput
              options={integradores}
              value={form.nIdIntegrador}
              name={"nIdIntegrador"}
              label={"Integrador"}
              getOptionLabel={"sNombreComercial"}
              getIndexLabel={"nIdEmisor"}
              onChange={onChange}
            />
          </Grid>
          <Grid item xs={12} sm={2} style={{ display: showInput("type") ? 'block' : 'none' }}>
            <SearchInput
              options={ getOptionsTypeReport(type) }
              value={form.type}
              name={"type"}
              label={"Tipo de reporte"}
              getOptionLabel={"name"}
              getIndexLabel={"id"}
              onChange={onChange}
            />
          </Grid>
          <Grid item xs={12} sm={2} style={{ display: showInput("actor") ? 'block' : 'none' }}>
            <SearchInput
              options={form.type === 1 ? [{ nIdEmisor: 0, sNombreComercial: 'Todos' }, ...emisores.rows] : [{ nIdCadena: 0, sNombreComercial: 'Todos' }, ...cadenas.rows]}
              value={form.type === 1 ? form.nIdEmisor : form.nIdCadena}
              name={form.type === 1 ? "nIdEmisor" : "nIdCadena"}
              label={form.type === 1 ? "Entidad financiera" : (form.type === 2 ? "Comisionista" : 'Socio comercial')}
              getOptionLabel={"sNombreComercial"}
              getIndexLabel={form.type === 1 ? "nIdEmisor" : "nIdCadena"}
              disabled={!form.type}
              onChange={onChange}
            />

          </Grid>
          <Grid item xs={12} sm={4} style={{ display: showInput("nIdEmisor") ? 'block' : 'none' }}>
            <SearchInput
              options={[{
                nIdEmisor: 0,
                sNombreComercial: "Todos",
              }, ...emisores.rows]}
              value={form.nIdEmisor}
              name={"nIdEmisor"}
              label={"Entidad financiera"}
              getOptionLabel={"sNombreComercial"}
              getIndexLabel={"nIdEmisor"}
              onChange={onChange}
            />
          </Grid>

          <Grid item xs={12} sm={4} style={{ display: showInput("nIdCadena") ? 'block' : 'none' }}>
            <SearchInput
              options={[{
                nIdCadena: 0,
                sNombreComercial: "Todos",
              }, ...cadenas.rows]}
              value={form.nIdCadena}
              name={"nIdCadena"}
              label={"Comisionista"}
              getOptionLabel={"sNombreComercial"}
              getIndexLabel={"nIdCadena"}
              onChange={onChange}
            />
          </Grid>
          <Grid item xs={12} sm={parseInt(form.type) === 1 ? 4 : 2} style={{ display: showInput("nIdEstatusPago") ? 'block' : 'none' }}>
            <SearchInput
              options={[{ nIdEstatusPago: 0, sDescripcion: 'Todos' }, ...estatusPago]}
              value={form.nIdEstatusPago}
              name={"nIdEstatusPago"}
              label={"Estatus"}
              getOptionLabel={"sDescripcion"}
              getIndexLabel={"nIdEstatusPago"}
              onChange={onChange}
            />
          </Grid>
          <Grid item xs={12} sm={parseInt(form.type) === 1 ? 4 : 2} style={{ display: showInput("nIdTipoPago") ? 'block' : 'none' }}>
            <SearchInput
              options={[{ nId: 0, sDescripcion: 'Todos' }, { nId: 1, sDescripcion: "Ordenes de pago" }, { nId: 2, sDescripcion: "Ordenes de pago (Comisión adicional)" }]}
              value={form.nIdTipoPago}
              name={"nIdTipoPago"}
              label={"Tipo de orden"}
              getOptionLabel={"sDescripcion"}
              getIndexLabel={"nId"}
              onChange={onChange}
            />
          </Grid>
          <Grid item xs={12} sm={2} style={{ display: showInput("nTipoPago") ? 'block' : 'none' }}>
            <SearchInput
              options={[
                {
                  id: 2,
                  name: "Ambos",
                },
                {
                  id: 1,
                  name: "Cobros",
                },
                {
                  id: 0,
                  name: "Pagos",
                },
              ]}
              value={form.nTipoPago}
              name={"nTipoPago"}
              label={"Flujo"}
              getOptionLabel={"name"}
              getIndexLabel={"id"}
              onChange={onChange}
            />
          </Grid>
          <Grid item xs={12} sm={2} style={{ display: showInput("nPeriodo") ? 'block' : 'none' }}>
            <SearchInput
              options={[
                {
                  id: 0,
                  name: "Diario",
                },
                {
                  id: 1,
                  name: "Mensual",
                },
              ]}
              value={form.nPeriodo}
              name={"nPeriodo"}
              label={"Periodo"}
              getOptionLabel={"name"}
              getIndexLabel={"id"}
              onChange={onChange}
            />
          </Grid>
          <Grid item xs={12} sm={2} style={{ display: showInput("productType") ? 'block' : 'none' }}>
            <SearchInput
              options={[
                {
                  id: 2,
                  name: "Todos",
                },
                {
                  id: 0,
                  name: "CASH OUT",
                },
                {
                  id: 1,
                  name: "CASH IN",
                },
              ]}
              value={form.productType}
              name={"productType"}
              label={"Tipo de producto"}
              getOptionLabel={"name"}
              getIndexLabel={"id"}
              onChange={onChange}
            />
          </Grid>
          <Grid item xs={12} sm={2} style={{ display: showInput("sClaveProducto") ? 'block' : 'none' }}>
            <SearchInput
              options={[{ sClaveProducto: 0, sDescripcion: 'Todos' }, ...productos]}
              value={form.sClaveProducto}
              name={"sClaveProducto"}
              label={"Producto"}
              getOptionLabel={"sDescripcion"}
              getIndexLabel={"sClaveProducto"}
              onChange={onChange}
            />
          </Grid>
          <Grid item xs={12} sm={2} style={{ display: showInput("dFecInicial") ? 'block' : 'none' }}>
            <DesktopDatePicker
              label="Fecha inicial"
              inputFormat="YYYY-MM-DD"
              renderInput={(params) => <TextField {...params} fullWidth />}
              disableFuture
              value={form.dFecInicial}
              onChange={(e) => onChange(null, 'dFecInicial', moment(e['$d']).format('YYYY-MM-DD'))}
            />
          </Grid>
          <Grid item xs={12} sm={2} style={{ display: showInput("dFecFinal") ? 'block' : 'none' }}>
            <DesktopDatePicker
              label="Fecha final"
              inputFormat="YYYY-MM-DD"
              renderInput={(params) => <TextField {...params} fullWidth />}
              value={form.dFecFinal}
              onChange={(e) => onChange(null, 'dFecFinal', moment(e['$d']).format('YYYY-MM-DD'))}
              disableFuture
            />
          </Grid>
          <Grid item xs={12} sm={2} style={{ display: showInput("dMonth") ? 'block' : 'none' }}>
            <SearchInput
              options={[
                {
                  id: 0,
                  name: "Todos",
                },
                {
                  id: 1,
                  name: "Enero",
                },
                {
                  id: 2,
                  name: "Febrero",
                },
                {
                  id: 3,
                  name: "Marzo",
                },
                {
                  id: 4,
                  name: "Abril",
                },
                {
                  id: 5,
                  name: "Mayo",
                },
                {
                  id: 6,
                  name: "Junio",
                },
                {
                  id: 7,
                  name: "Julio",
                },
                {
                  id: 8,
                  name: "Agosto",
                },
                {
                  id: 9,
                  name: "Septiembre",
                },
                {
                  id: 10,
                  name: "Octubre",
                },
                {
                  id: 11,
                  name: "Noviembre",
                },
                {
                  id: 12,
                  name: "Diciembre",
                },
              ].filter(mes => form.dYear == currentDate.format("YYYY") ? (mes.id <= parseInt(currentDate.format("M"))) : true)}
              value={form.dMonth}
              name={"dMonth"}
              label={"Mes"}
              getOptionLabel={"name"}
              getIndexLabel={"id"}
              onChange={onChange}
            />
          </Grid>
          <Grid item xs={12} sm={2} style={{ display: showInput("dYear") ? 'block' : 'none' }}>
            <SearchInput
              options={getYearsArray()}
              value={form.dYear}
              name={"dYear"}
              label={"Año"}
              getOptionLabel={"name"}
              getIndexLabel={"id"}
              onChange={onChange}
            />
          </Grid>
          <Grid item xs={12} sm={2} style={{ display: showInput("sArchivo") ? 'block' : 'none' }}>
            <TextInput
              id="sArchivo"
              name="sArchivo"
              label="Nombre del archivo"
              value={form.sArchivo}
              onChangeRoot={onChangeInput}
            />
          </Grid>
          <Grid item xs={12} sm={4} style={{ display: showInput("sReferencia") ? 'block' : 'none' }}>
            <TextInput
              id="sReferencia"
              name="sReferencia"
              label="Referencia"
              value={form.sReferencia}
              onChangeRoot={onChangeInput}
              type="number"
              caracteres={22}
            />
          </Grid>
          <Grid item xs={12} sm={4} style={{ display: showInput("sTicket") ? 'block' : 'none' }}>
            <TextInput
              id="sTicket"
              name="sTicket"
              label="ID de la transacción"
              value={form.sTicket}
              onChangeRoot={onChangeInput}
            />
          </Grid>
          <Grid item xs={12} sm={4} style={{ display: showInput("sAutorizacion") ? 'block' : 'none' }}>
            <TextInput
              id="sAutorizacion"
              name="sAutorizacion"
              label="Autorización"
              value={form.sAutorizacion}
              onChangeRoot={onChangeInput}
            />
          </Grid>
        </Grid>
        <Grid
          container
          justifyContent={"space-between"}
          spacing={2}
          style={{ padding: 20 }}
        >
          <Grid container item xs={12} sm={8}  >
            <Grid container item xs={3} style={{ alignItems: "center", display: showInput("details") ? 'flex' : 'none' }}>
              <BlueSwitch
                onChange={(e) => onChange(e, "nDetalle")}
                inputProps={{ 'aria-label': 'controlled' }}
                name={"nDetalle"}
                checked={Boolean(form.nDetalle)}
                value={form.nDetalle}
              />
              <p>Mostrar detalles</p>
            </Grid>
            <Grid container item xs={3} style={{ alignItems: "center", display: showInput("details") && !form.nDetalle ? 'flex' : 'none' }}>
              <BlueSwitch
                onChange={(e) => onChange(e, "nPersonalizarAgrupacion")}
                inputProps={{ 'aria-label': 'controlled' }}
                name={"nPersonalizarAgrupacion"}
                checked={Boolean(form.nPersonalizarAgrupacion)}
                value={form.nPersonalizarAgrupacion}
              />
              <p>Cambiar agrupación</p>
            </Grid>
            <Grid container item xs={4} style={{ alignItems: "center", display: showInput("details") && !form.nDetalle && form.nPersonalizarAgrupacion ? 'flex' : 'none' }}>
              <SearchInput
                options={[{ value: 0, text: 'Por defecto' }, { value: 1, text: "EFRs" }, { value: 2, text: "Productos" }]}
                value={form.nTipoAgrupacion}
                name={"nTipoAgrupacion"}
                label={"Tipo de agrupación"}
                getOptionLabel={"text"}
                getIndexLabel={"value"}
                onChange={onChange}
                sx={{ minWidth: 200 }}
              />
            </Grid>
            <Grid container item xs={3} style={{ alignItems: "center", display: showInput("nFiltradoFechas") ? 'flex' : 'none' }}>
              <BlueSwitch
                onChange={(e) => onChange(e, "nFiltradoFechas")}
                inputProps={{ 'aria-label': 'controlled' }}
                name={"nFiltradoFechas"}
                checked={Boolean(form.nFiltradoFechas)}
                value={form.nFiltradoFechas}
              />
              <p>Filtrar por fecha</p>
            </Grid>
            <Grid item xs={12} sm={4} style={{ display: showInput("nFiltradoFechas") && form.nFiltradoFechas ? 'block' : 'none' }}>
              <DesktopDatePicker
                label="Fecha inicial"
                inputFormat="YYYY-MM-DD"
                renderInput={(params) => <TextField {...params} fullWidth />}
                disableFuture
                value={form.dFecInicial}
                onChange={(e) => onChange(null, 'dFecInicial', moment(e['$d']).format('YYYY-MM-DD'))}
              />
            </Grid>
            <Grid item xs={12} sm={4} style={{ display: showInput("nFiltradoFechas") && form.nFiltradoFechas ? 'block' : 'none' }}>
              <DesktopDatePicker
                label="Fecha final"
                inputFormat="YYYY-MM-DD"
                renderInput={(params) => <TextField {...params} fullWidth />}
                value={form.dFecFinal}
                onChange={(e) => onChange(null, 'dFecFinal', moment(e['$d']).format('YYYY-MM-DD'))}
                disableFuture
              />
            </Grid>
          </Grid>
          <Grid item xs={12} sm={4} style={{ textAlign: "right" }}>
            <Button variant="contained" disabled={!isFormCompleted()} onClick={handleSubmit}>
              Buscar
            </Button>
          </Grid>
        </Grid>
      </Paper>
    </LocalizationProvider>
  );
};
