import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  page: '',
  isAllowed: false
};

export const authPageSlice = createSlice({
  name: 'authPage',
  initialState,
  reducers: {
    setPage: (state, action) => {
      state.page = action.payload;
    },
    allowPage: (state) => {
      state.isAllowed = true;
    },
    denyPage: (state) => {
      state.isAllowed = false;
    },
    reset: (state) => {
      state = initialState;
    }
  }
});

export const { setPage, allowPage, denyPage, reset } = authPageSlice.actions;
