import { Box } from "@mui/system";
import { useSelector } from "react-redux";
import { useModal } from "../../hooks/useModal";
import { useAuthPage } from "../../hooks/useAuthPage";
import { ModalBase } from "./ModalBase";
import { Button, Tooltip } from "@mui/material";
import { useEffect, useState } from "react";
import StoreMallDirectoryIcon from "@mui/icons-material/StoreMallDirectory";
import { SearchInput } from "../form/SearchInput";
import EnhancedTable from "../table/EnhancedTable";

const colorsTab = ["#E7B10A","#00CD68", "#e53e3e", "#053e85"]

export const ModalComisionistas = (props) => {
  const { openModalComisionistas, emisores, form } = props;
  const { isOpenComisionistas, closeModalComisionistas } = useModal();
  const { setAllowComponent } = useAuthPage();
  const { comisionistas } = useSelector((state) => state.emisor);
  const { productos } = useSelector((state) => state.catalogo);

  const [emisor, setEmisor] = useState({});

  useEffect(() => {
    if (emisores.length) {
      let innerEmisor = emisores.filter(
        (item) => item.nIdEmisor === form.nIdEmisor
      )[0];
      console.log(innerEmisor);
      if (innerEmisor) {
        setEmisor({ ...emisor, ...innerEmisor });
      }
    }
  }, [form.nIdEmisor]);

  const truncate = (str, n) => {
    if (str.length <= n) return str; 
    if (n <= 1) return "…";
    let dot = str.lastIndexOf("."); 
    
    let after = dot < 0 ? 1 : Math.max(1, Math.min(n - 2, str.length - dot + 2));
    
    let before = n - after - 1; 
    return str.slice(0, before) + "…";
  }

  const getColumns = () => {
    return productos.map(producto => {
      return {
        field: producto.sClaveProducto, headerName: producto.sDescripcion, type: 'render', flex: 1, disableTooltip: true, renderFunction: (row) => {
          const productoFinded = JSON.parse(row.oProductos ?? "[]").find(item => item.sClaveProducto == producto.sClaveProducto)

          return (productoFinded ?
            <Tooltip title={<span>{productoFinded.sDescripcion} <br></br>{[1,3].includes(productoFinded.nIdEstatus) ? `Fecha de alta: ${productoFinded.dFecAlta}`: `Fecha de baja : ${productoFinded.dFecBaja}`}</span>}>
              <p style={{ display: "inline-block", color: colorsTab[productoFinded.nIdEstatus], background: `${colorsTab[productoFinded.nIdEstatus]}15`, padding: "4px 8px", borderRadius: "4px", fontWeight: 500, margin: "0 5px 0 0 ", fontSize: 12 }} >{truncate(productoFinded.sEstatus, 18)}</p>
            </Tooltip> : <></>)
        }
      }
    })
  }

  return (
    <>
      <ModalBase
        open={isOpenComisionistas}
        style={{ width: "1350px", maxHeight: "700px" }}
      >
        <Box
          sx={{
            width: "100%",
            height: "100%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
          }}
        >
          <EnhancedTable
            isModalTable
            icon={<StoreMallDirectoryIcon fontSize={"large"} />}
            subtitle={"Listado de comisionistas"}
            title={emisor?.sNombreComercial}
            table={{
              ...comisionistas,
              columns: [
                ...comisionistas.columns,
                ...getColumns()
              ]
            }}
            rowId={"nIdCadena"}
            disableButtons
          />
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              width: "100%",
              justifyContent: "end",
            }}
          >
            <Button onClick={closeModalComisionistas} sx={{ color: "#00609C" }}>
              cerrar
            </Button>
          </div>
        </Box>
      </ModalBase>

    </>
  );
};
