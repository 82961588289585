import { regexNums } from "./regexs";

export const onFormatCuenta = ( text ) => {
  if ( text.length === 1 ) return text + '-'; // 1-
  if ( text.length === 3 ) return text + '-'; // 1-1-
  if ( text.length === 6 ) return text + '-'; // 1-2-22-
  if ( text.length === 11 ) return text + '-'; // 1-
  return text;
}

export const onFormatCuentaInit = ( text = '' ) => {
  let newText = '';
  const postions = [1, 3, 6, 11];

  for (let i = 0; i < text?.length; i++) {
    const letter = text[i];
    
    if ( postions.includes(newText.length) ) {
      newText += '-'
    }

    newText += letter;
  }
  return onFormatCuenta(newText);
}

export const validText = ( text = '' ) => {
  return regexNums.test(text.replaceAll('-', ''))

}

export const getSizeItem = ( formAccount = {}, searched ) => {
  return formAccount[searched]?.length ?? 0;
}