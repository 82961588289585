import {
  Box,
  Typography,
  FormGroup,
  TextField,
  Button,
  ThemeProvider,
  IconButton,
  CardActions,
  Grid,
  Paper,
  Tooltip,
  Stepper,
  Step,
  StepLabel,
  Alert,
  Skeleton
} from '@mui/material';
import { CopyAllRounded, FileCopy, LogoutTwoTone, Visibility, VisibilityOff, VpnKeyOffTwoTone, VpnKeyTwoTone } from '@mui/icons-material';
import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import '../styles/login.css';
import { DropFileInput } from '../components/form/DropFileInput';
import { useGetCredentialsPage } from '../hooks/useGetCredentialsPage';
import { TextInput } from '../components/form/TextInput';

export const GetCredentialsPage = () => {
  const {
    step,
    handleChangeStep,
    handleDrop,
    fileData,
    setFileData,
    selectedFile,
    setSelectedFile,
    credentials,
    error,
    saveClipboard,
    showSecret,
    showClient,
    handleShowClient,
    handleShowSecret,
    loaded,
    loadInInit
  } = useGetCredentialsPage();

  useEffect(() => {
    loadInInit();
    // eslint-disable-next-line
  }, []);

  const showStep = () => {
    switch (step) {
      case 0:
        return <>
          <Grid item xs={12} sx={{ textAlign: "center" }}>
            {loaded ?
              <>
                <Typography variant='h6' color={"primary"} textAlign={"center"}>Suba la llave pública</Typography>
                <Typography variant='p' textAlign={"center"} >Es necesaria para poder identificar las peticiones y respuestas del comisionista.</Typography>
              </>
              :
              <>
                <Skeleton variant="text" sx={{ fontSize: '2rem' }} />
                <Skeleton variant="text" sx={{ fontSize: '1rem' }} />
              </>
            }

          </Grid>
          {error && <Grid item xs={12}>
            <Alert severity="error">{error}</Alert>
          </Grid>}
          {
            loaded ?
              <Grid item xs={12} container paddingLeft={2}>
                <DropFileInput
                  handleDrop={handleDrop}
                  fileData={fileData}
                  setFileData={setFileData}
                  selectedFile={selectedFile}
                  setSelectedFile={setSelectedFile}
                  acceptedFormat={".pem"}
                />
              </Grid>
              :
              <Skeleton variant="rounded" width={"100%"} height={300} sx={{ml:2, mt: 2}} />
          }
        </>
      case 1:
        return <>
          <Grid item xs={12} sx={{ textAlign: "center" }}>
            <Typography variant='h6' color={"primary"} textAlign={"center"}>Guarde las credenciales</Typography>
            <Typography variant='p' textAlign={"center"} >Se requieren para usar los servicios SofiPay.</Typography>
          </Grid>

          <Grid item xs={12} container alignItems={'center'}>
            <Grid item xs={12} container justifyContent={"space-between"} alignItems={"center"}>
              <Typography variant='p' sx={{ ml: 2, color: "rgba(0, 0, 0, 0.6)", fontWeight: 500 }}>Client ID</Typography>
              <Tooltip title={"Copiar"}>
                <Button onClick={() => saveClipboard("clientId")} startIcon={<FileCopy />}>Copiar</Button>
              </Tooltip>
            </Grid>
            <Grid item xs={12}>
              <TextInput
                id="clientId"
                name="clientId"
                value={credentials.clientId}
                fullWidth
                disabled
                multiline
              />
            </Grid>
          </Grid>
          <Grid item xs={12} container alignItems={'center'}>
            
            <Grid item xs={12} container justifyContent={"space-between"} alignItems={"center"}>
              <Typography variant='p' sx={{ ml: 2, color: "rgba(0, 0, 0, 0.6)", fontWeight: 500 }}>Key ID</Typography>
              {/* <Tooltip title={"Copiar"}>
                <Button onClick={() => saveClipboard("keyId")} startIcon={<FileCopy />}>Copiar</Button>
              </Tooltip> */}
              <Grid item xs={6} container justifyContent={"flex-end"}>
                <Button onClick={handleShowClient} startIcon={showClient ? <VisibilityOff /> : <Visibility />}>{showClient ? 'Ocultar' : 'Mostrar'}</Button>
                <Button onClick={() => saveClipboard("keyId")} startIcon={<FileCopy />}>Copiar</Button>
              </Grid>
            </Grid>
            <Grid item xs={12}>

              <TextInput
                id="keyId"
                name="keyId"
                value={credentials.keyId}
                fullWidth
                disabled
                multiline={showClient}
                type={showClient ? 'text' : 'password'}
              />
            </Grid>
          </Grid>
          <Grid item xs={12} container alignItems={'center'}>
            <Grid item xs={12} container justifyContent={"space-between"} alignItems={"center"}>
              <Typography variant='p' sx={{ ml: 2, color: "rgba(0, 0, 0, 0.6)", fontWeight: 500 }}>Secret</Typography>
              <Grid item xs={6} container justifyContent={"flex-end"}>

                <Button onClick={handleShowSecret} startIcon={showSecret ? <VisibilityOff /> : <Visibility />}>{showSecret ? 'Ocultar' : 'Mostrar'}</Button>
                <Button onClick={() => saveClipboard("secret")} startIcon={<FileCopy />}>Copiar</Button>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <TextInput
                id="secret"
                name="secret"
                type={showSecret ? 'text' : 'password'}
                value={credentials.secret}
                fullWidth
                disabled
                multiline={showSecret}
              />
            </Grid>
          </Grid>
        </>
      default:
        return <></>
    }
  }

  return (loaded ? 
    <Grid container justifyContent={"center"} alignItems={"center"} sx={{ padding: 5, height: "100vh" }}>
      <Grid item xs={12} sm={6} sx={{ maxWidth: "500px" }}>

        <Grid container item xs={12} sx={{ mb: 2 }} alignItems={"center"}>
          <Grid container item xs={12} sm={6} alignItems={"center"}>
            <Grid
              item
              style={{
                color: "#00CD68",
                padding: 15,
                fontWeight: "bold",
              }}
            >
              <VpnKeyTwoTone fontSize={"large"} />
            </Grid>
            <Grid item>
              <Typography variant="p" color={"neutral"}>
                Obtener
              </Typography>
              <Typography variant="h5" color={"primary"}>
                Credenciales
              </Typography>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={6} container justifyContent={"flex-end"}>
            {/* <Typography variant='h5'>{step + 1}/2</Typography> */}
            {loaded ?
              <Stepper activeStep={step}>
                {[1, 2].map((label, key) => {
                  return (
                    <Step key={key}>
                      <StepLabel></StepLabel>
                    </Step>
                  );
                })}
              </Stepper>
              : [1, 2].map((label, key) => {
                return (
                  <Skeleton variant="circular" width={24} height={24} sx={{ ml: "8px", mr: "16px" }} key={key}/>
                );
              })
            }

          </Grid>
        </Grid>

        <Paper>
          <Grid container spacing={2} sx={{ padding: 2, pb: 4, marginLeft: 0, width: "100%" }}>
            {showStep()}
          </Grid>
        </Paper>

        <Grid container item xs={12} sx={{ mt: 2 }} alignItems={"center"} justifyContent={"space-between"}>
          <Grid item sm={4}>
            {false && <Button onClick={() => handleChangeStep('prev')}>Atrás</Button>}
          </Grid>
          <Grid item sm={4} container justifyContent={"flex-end"}>
            {step === 0 && <Button variant='contained' disabled={!fileData} onClick={() => handleChangeStep('next')}>Siguiente</Button>}
          </Grid>
        </Grid>

      </Grid>
    </Grid>
  : <></>);
};
