import {
  startLoadEmisores,
  startLoadMotivosBaja,
  startLoadProductos,
} from "../slices/catalogo/catalgoThunk";
import {
  getComisionistas,
  getProducts,
  getRoutesData,
  insertBaja,
  insertRoute,
  postProducts,
  updateRoute,
} from "../slices/emisor/emisorThunk";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { rutaForm } from "../helpers/forms";
import {
  openListRoute,
  closeListRoute,
  openRoute,
  closeRoute,
  openProducts,
  closeProducts,
  openConfirmProductsDialog,
  closeConfirmProductsDialog,
  closeComisionistas,
  openComisionistas,
} from "../slices/ui/uiSlice";
import { useModal } from "./useModal";

export const useEFRTable = () => {
  const [form, setForm] = useState(rutaForm);
  const [efrProductos, setEfrProductos] = useState({
    data: [],
    entity: 0
  });
  const { emisores, productos, motivosBaja } = useSelector((state) => state.catalogo);
  const { rutas } = useSelector((state) => state.emisor);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { setOpenToast, closeModalProducts } = useModal();
  const [productosEmisor, setProductosEmisor] = useState([])
  const onClickAdd = () => {
    navigate("/altas-efr");
  };

  const getRoutes = async (emisor, ruta = 0, estatus = 1) => {
    onChange(null, "nIdEmisor", emisor);

    await dispatch(getProducts(emisor, 0)).then(async (productos) => {
      if (productos.length) {
        await dispatch(getRoutesData(ruta, emisor, estatus));

        setProductosEmisor(productos)
      } else {
        dispatch(openConfirmProductsDialog());
      }
    })
  };

  const loadInInit = async () => {
    await dispatch(startLoadEmisores(0, -1));
  };

  const onChange = (e, name = null, value = null) => {
    console.log(e, name, value);
    const inputName = name !== null ? name : e.target.name;
    const inputValue = value !== null ? value : e.target.value;
    const switchOptions = ["nImpComProducto", "nImpMinRuta", "nImpMaxRuta"];

    if (switchOptions.includes(inputName)) {
      setForm({ ...form, [inputName]: e.target.checked });
    } else {
      setForm({ ...form, [inputName]: inputValue });
    }
  };

  const onChangeInput = async (value) => {
    const keys = Object.keys(value)
    const switchOptions = ["nImpComProducto", "nImpMinRuta", "nImpMaxRuta"];
    if (switchOptions.includes(keys[0]) && (!value[keys[0]] || value[keys[0]] < 0)) {
      setForm({ ...form, [keys[0]]: 0 });
    } else {
      setForm({ ...form, ...value });

    }
  };

  const openModalRoute = async (modalType = "add", ruta = 0) => {
    dispatch(closeListRoute());
    if (modalType === "edit" || modalType === "view") {
      await dispatch(getRoutesData(ruta, form.nIdEmisor, 1, true)).then(
        (result) => setForm({ ...form, ...result.rows[0], modalType })
      );
      await dispatch(openRoute());
    } else {
      setForm({ ...rutaForm, nIdEmisor: form.nIdEmisor });
      dispatch(openRoute());
    }
  };

  const openModalComisionistas = async (nIdEmisor = 0) => {
    dispatch(closeComisionistas());
    await dispatch(startLoadProductos());
    await dispatch(getComisionistas(form.dFecComisionistas, nIdEmisor));
    setForm({ ...rutaForm, nIdEmisor });
    await dispatch(openComisionistas());
  };

  const deleteRoute = async (ruta) => {
    await dispatch(updateRoute({
      ...form,
      ...(await dispatch(getRoutesData(ruta, form.nIdEmisor, 1, true))).rows[0],
    }, 'delete')).then((resp) => openToast(resp));
  }

  const saveRoute = async () => {
    switch (form.modalType) {
      case "add":
        await dispatch(insertRoute(form)).then((resp) => openToast(resp));
        break;
      case "edit":
        await dispatch(updateRoute(form, 'edit')).then((resp) => openToast(resp));
        break;
      default:
        break;
    }
  };

  const deleteEmisor = async (form) => {
    await dispatch(insertBaja(form)).then(async (resp) => {
      setOpenToast(!resp.ok, resp.msg)
      await dispatch(startLoadEmisores())
    });

  };

  const openToast = (resp) => {
    console.log(resp);
    if (!resp.ok) {
      setOpenToast(true, resp.msg);
      return;
    } else {
      setOpenToast(false, resp.msg);
      dispatch(getRoutesData(0, form.nIdEmisor, 1));
      dispatch(closeRoute());
    }
  };


  const openProductsModal = async (value = null) => {
    if (!value) dispatch(closeConfirmProductsDialog());

    await dispatch(startLoadProductos());
    await dispatch(getProducts(value ? value : form.nIdEmisor)).then((resp) => {
      setEfrProductos({
        data: resp,
        entity: value ? value : form.nIdEmisor
      })
    });
    dispatch(openProducts());
  }

  const submitProducts = async (arr) => {
    await dispatch(postProducts(arr)).then(async (resp) => {
      if (resp.ok) {
        await dispatch(getProducts(efrProductos.entity)).then((resp) => {
          setEfrProductos({
            ...efrProductos,
            data: resp,
          })
        });
        setOpenToast(false, resp.msg)

        dispatch(closeModalProducts())
      } else {
        setOpenToast(true, resp.msg)
      }
    });
    dispatch(openProducts());
  }


  return {
    loadInInit,
    emisores,
    onClickAdd,
    getRoutes,
    rutas,
    onChange,
    openModalRoute,
    form,
    productos,
    motivosBaja,
    onChangeInput,
    saveRoute,
    deleteRoute,
    deleteEmisor,
    openProductsModal,
    efrProductos,
    submitProducts,
    productosEmisor,
    openModalComisionistas
  };
};
