/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_cloud_logic_custom": [
        {
            "name": "SofiPay",
            "endpoint": "https://02mknjvana.execute-api.us-east-1.amazonaws.com/test",
            "region": "us-east-1"
        },
        {
            "name": "cadenas",
            "endpoint": "https://9ntqjzgex7.execute-api.us-east-1.amazonaws.com/test",
            "region": "us-east-1"
        },
        {
            "name": "catalogos",
            "endpoint": "https://9e0g11d1y0.execute-api.us-east-1.amazonaws.com/test",
            "region": "us-east-1"
        },
        {
            "name": "conciliacion",
            "endpoint": "https://8pwz5w5q70.execute-api.us-east-1.amazonaws.com/test",
            "region": "us-east-1"
        },
        {
            "name": "conexiones",
            "endpoint": "https://a2nhhrdrw4.execute-api.us-east-1.amazonaws.com/test",
            "region": "us-east-1"
        },
        {
            "name": "contabilidad",
            "endpoint": "https://i3crtuao7l.execute-api.us-east-1.amazonaws.com/test",
            "region": "us-east-1"
        },
        {
            "name": "emisores",
            "endpoint": "https://2atale086i.execute-api.us-east-1.amazonaws.com/test",
            "region": "us-east-1"
        },
        {
            "name": "facturacion",
            "endpoint": "https://9fpbft5s9j.execute-api.us-east-1.amazonaws.com/test",
            "region": "us-east-1"
        },
        {
            "name": "reportes",
            "endpoint": "https://0vr494fw1a.execute-api.us-east-1.amazonaws.com/test",
            "region": "us-east-1"
        },
        {
            "name": "sucursales",
            "endpoint": "https://k5pbfrpw1i.execute-api.us-east-1.amazonaws.com/test",
            "region": "us-east-1"
        },
        {
            "name": "suspensiones",
            "endpoint": "https://n1dwkbbpkb.execute-api.us-east-1.amazonaws.com/test",
            "region": "us-east-1"
        },
        {
            "name": "usuarios",
            "endpoint": "https://xsyh33fts9.execute-api.us-east-1.amazonaws.com/test",
            "region": "us-east-1"
        }
    ],
    "aws_cognito_identity_pool_id": "us-east-1:4081df39-17e7-4558-b2eb-3c22a129f15d",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_SuhHCsEf2",
    "aws_user_pools_web_client_id": "6f140j8mrchjva7eclniug46qj",
    "oauth": {},
    "aws_cognito_username_attributes": [],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": [
            "REQUIRES_LOWERCASE",
            "REQUIRES_UPPERCASE",
            "REQUIRES_NUMBERS",
            "REQUIRES_SYMBOL"
        ]
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_user_files_s3_bucket": "documentossofipay85420-test",
    "aws_user_files_s3_bucket_region": "us-east-1"
};


export default awsmobile;
