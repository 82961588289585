import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  monitoreoERP:  [],
};

export const monitoreoSlice = createSlice({
  name: 'monitoreo',
  initialState,
  reducers: {
    setMonitoreoERP: (state, action) => {
      state.monitoreoERP = [...action.payload];
    },
  }
});

export const {
  setMonitoreoERP,
} = monitoreoSlice.actions;
