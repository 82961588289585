import { ModalBase } from "./ModalBase";
import {
  Alert,
  AlertTitle,
  Button,
  Grid,
  Typography,
} from "@mui/material";
import { SearchInput } from "../form/SearchInput";
import EmailIcon from '@mui/icons-material/Email';
import DownloadIcon from '@mui/icons-material/Download';
import { useModalEnvioEmail } from "../../hooks/useModalEnvioEmail";
import { ConfirmDialog } from "./ConfirmDialog";
import moment from "moment";


export const ModalEnvioEmail = (props) => {
  const {
    emisor,
    isOpenEnvioEmail,
    onChange,
    meses,
    form,
    handleClose,
    saveFile,

    usuarios,
    contactos,
    isSent,

    showConfirmDialog,
    handleCloseConfirmDialog,
    handleOpenConfirmDialog,
    handleConfirmDialog
  } = useModalEnvioEmail()

  return (<>
    <ConfirmDialog
      isOpenDialog={showConfirmDialog}
      onCloseDialog={handleCloseConfirmDialog}
      onConfimDialog={handleConfirmDialog}
      message={`Se enviará el reporte a ${form.sDestinatariosInternos.length + form.sDestinatariosNegocio.length} destinarario/s ¿Estas seguro/a?`}
      textButtonConfirm="Sí, si estoy seguro/a."
    />
    <ModalBase open={isOpenEnvioEmail} style={{ width: "100%", maxWidth: "650px" }}>
      <Grid container>
        <Grid container item sm={12} style={{ alignItems: "center" }}>
          <Grid
            item
            style={{
              color: "#00CD68",
              padding: 15,
              fontWeight: "bold",
            }}
          >
            <EmailIcon fontSize={"large"} />
          </Grid>
          <Grid item>
            <Typography variant="p" color={"neutral"}>
              Envío de correo
            </Typography>
            <Typography variant="h5" color={"primary"}>
              {emisor ? emisor.sNombreComercial : "Nombre de emisor"}
            </Typography>
          </Grid>
        </Grid>
        <Grid container spacing={2} item sm={12} sx={{ mt: 1, mb: 2 }}>
          {meses?.length === 0 ?
            <Grid item sm={12}>
              <Alert severity="warning" sx={{ ml: 2 }}>
                <AlertTitle>No se puede enviar reporte por correo</AlertTitle>
                Se requiere que en el reporte se considere al menos un mes completo.
              </Alert>
            </Grid>
            : <>
              <Grid item sm={12}>
                <Grid container sx={{ pr: 2 }} alignItems={"center"}>
                  <Grid item sm={10}>
                    {meses?.length > 1 ?
                      <SearchInput
                        options={meses}
                        value={form.nPeriodo}
                        name={"nPeriodo"}
                        label={"Periodo del reporte"}
                        getOptionLabel={"text"}
                        getIndexLabel={"value"}
                        onChange={onChange}
                      />
                      : <>
                        <Typography variant="p" color={"neutral"} sx={{ ml: 2 }}>
                          Periodo de reporte
                        </Typography>
                        <Typography variant="h6" color={"neutral"} sx={{ ml: 2 }}>
                          {meses?.length ? meses[0].text : ""}
                        </Typography>
                      </>}
                  </Grid>

                  <Grid item sm={2}>
                    <Button onClick={() => saveFile()} startIcon={<DownloadIcon />} disabled={!form.nPeriodo}>
                      Descargar
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
              {isSent.sent &&
                <Grid item sm={12}>
                  <Alert severity="warning">
                    <AlertTitle>Reporte enviado</AlertTitle>
                    El reporte para el periodo seleccionado ya fue enviado el día {moment(isSent.email.dFecEnvio).format("YYYY-MM-DD")} por <b>{isSent.email.sUsuario}</b>.
                  </Alert>
                </Grid>}
              <Grid item sm={12}>
                <SearchInput
                  multiple
                  options={contactos}
                  name={"sDestinatariosNegocio"}
                  label={"Destinatarios de negocio"}
                  getOptionLabel={"sTexto"}
                  getIndexLabel={"nIdContacto"}
                  onChange={onChange}
                  value={form.sDestinatariosNegocio}
                  disabled={isSent.sent}
                />
              </Grid>
              <Grid item sm={12}>
                <SearchInput
                  multiple
                  options={usuarios}
                  name={"sDestinatariosInternos"}
                  label={"Destinatarios internos"}
                  getOptionLabel={"sTexto"}
                  getIndexLabel={"nIdUsuario"}
                  onChange={onChange}
                  value={form.sDestinatariosInternos}
                  disabled={isSent.sent}
                />
              </Grid>
            </>}
        </Grid>
        <Grid container item sm={12} justifyContent={"flex-end"}>
          <Button onClick={() => handleClose()} sx={{ color: "#00609C" }}>
            cancelar
          </Button>
          <Button onClick={() => handleOpenConfirmDialog()} variant="contained" disabled={!(form.nPeriodo && form.sDestinatariosNegocio?.length && !isSent.sent)}>
            Enviar
          </Button>
        </Grid>
      </Grid>
    </ModalBase>
  </>);
};
