import { useSelector, useDispatch } from "react-redux";
import { registrosBancariosForm } from "../helpers/forms";
import { useEffect, useState} from "react";
import { setRegistrosBancarios, setRegistrosBancariosBadge, setRegistrosBancariosCargosBadge } from "../slices/contabilidad/contabilidadSlice";
import { useLocation } from "react-router-dom";
import {
  openImport,
  closeImport,
  openRegistrosCortes,
  closeRegistrosCortes,
  openRegistrosOrdenes,
  closeRegistrosOrdenes
} from '../slices/ui/uiSlice';
import { startLoadCadenas, startLoadEmisores } from "../slices/catalogo/catalgoThunk";
import { getRegistrosBancariosData, getRegistrosCortesData, insertRegistrosBancarios, postRegistrosCortesData } from "../slices/contabilidad/contabilidadThunk";
import { useModal } from "./useModal";

export const useRegistrosBancariosPage = () => {
  let location = useLocation();
  const { 
    registrosBancariosTable, 
    cortesCadenas,
    cortesEmisores,
    ordenesPagoCadenas,
    ordenesPagoEmisores,
    cortesEscalonadosEmisores,
    registrosBancariosCadena,
    registrosBancariosEmisor,
    registrosBancariosBadge, 
    registrosBancariosCargosCadena,
    registrosBancariosCargosEmisor,
    registrosBancariosCargosBadge, 
  } = useSelector((state) => state.contabilidad);
  const { isOpenImport } = useSelector((state) => state.ui);

  const { setOpenToast } = useModal();
  const [form, setForm] = useState(registrosBancariosForm);
  const dispatch = useDispatch();

  const { cadenas, emisores} = useSelector(
    (state) => state.catalogo
  );

  useEffect(() => {
    dispatch(setRegistrosBancarios({
      columns: [],
      rows: []
    }));
  }, [location]);

  const loadInInit = async () => {
    await dispatch(startLoadCadenas());
    await dispatch(startLoadEmisores());
    await getRegistrosSinCorte();
  };

  const getRegistrosSinCorte = async () =>{
    await dispatch(getRegistrosCortesData()).then(resp => {
      dispatch(setRegistrosBancariosBadge(JSON.parse(resp.registrosBancariosCadena).length + JSON.parse(resp.registrosBancariosEmisor).length))
      dispatch(setRegistrosBancariosCargosBadge(JSON.parse(resp.registrosBancariosCargosCadena).length + JSON.parse(resp.registrosBancariosCargosEmisor).length))
    })
  }

  const postRegistrosCorte = async (data, isRegistrosOrdenes = false) =>{
    await dispatch(postRegistrosCortesData(data,isRegistrosOrdenes)).then(async(resp) => {
      console.log(resp)
      if(resp.nCodigo === 0){
        setOpenToast(false, resp.sMensaje);
        dispatch(isRegistrosOrdenes ? closeRegistrosOrdenes():closeRegistrosCortes());
        await getRegistrosSinCorte();
      }else{
        setOpenToast(true, resp.sMensaje);
      }
    })
  }

  const openModalRegistrosCortes = () => {
    dispatch(openRegistrosCortes());
  };

  const openModalRegistrosOrdenes = () => {
    dispatch(openRegistrosOrdenes());
  };

  const openImportModal = () =>{
    dispatch(openImport())
  }

  const closeImportModal =  async() =>{
    dispatch(closeImport())
    await getRegistrosSinCorte();
  }

  const openModalRegistrosCortesFromImport = async() => {
    dispatch(closeImport())
    await getRegistrosSinCorte();
    dispatch(openRegistrosCortes());
  };

  const importRegistrosBancarios = async(nInicio,sRegistros,isAbono = true) =>{
    return (await dispatch(insertRegistrosBancarios(nInicio,sRegistros,isAbono)))
  }

  const getRegistrosBancarios = async() =>{
    return (await dispatch(getRegistrosBancariosData(form)))
  }

  const onChange = (e, name = null, value = null) => {
    const inputName = name !== null ? name : e.target.name;
    const inputValue = value !== null ? value : e.target.value;
    const switchOptions = ["nDetalle"];

    if (switchOptions.includes(inputName)) {
      setForm({ ...form, [inputName]: e.target.checked });
    } else {
      let valueForm = { ...form, [inputName]: inputValue };
      setForm(valueForm);
    }
  };

  const onChangeInput = async (value) => {
    setForm({ ...form, ...value });
  };

  return {
    onChange,
    form,
    registrosBancariosTable,
    onChangeInput,
    isOpenImport, 
    openImportModal,
    closeImportModal,
    loadInInit,
    emisores,
    cadenas,
    importRegistrosBancarios,
    getRegistrosBancarios,
    cortesCadenas,
    cortesEmisores,
    ordenesPagoCadenas,
    ordenesPagoEmisores,
    cortesEscalonadosEmisores,
    registrosBancariosCadena,
    registrosBancariosEmisor,
    registrosBancariosBadge,
    registrosBancariosCargosCadena,
    registrosBancariosCargosEmisor,
    registrosBancariosCargosBadge, 
    openModalRegistrosCortes,
    postRegistrosCorte,
    openModalRegistrosCortesFromImport,
    openModalRegistrosOrdenes
  };
};
