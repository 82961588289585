import { API } from "aws-amplify";
import { closeOrdenPago, loading, openToast, tableLoading } from "../ui/uiSlice";
import { setDataTable } from "./usuariosSlice";

export const getUsuariosData = () => {
  return async (dispatch) => {
    const params = {
      headers: {},
      response: false,
      queryStringParameters: {},
    };

    try {
      dispatch(tableLoading(true));
      const resp = await API.get("usuarios", "/usuarios/listado", params);
        dispatch(
          setDataTable({
            ...resp,
          })
        );
    } catch (error) {
      console.log("ocurrio un error " + error);
    } finally {
      dispatch(tableLoading(false));
    }
  };
};

export const getUsuariosExternosData = () => {
  return async (dispatch) => {
    const params = {
      headers: {},
      response: false,
      queryStringParameters: {},
    };

    try {
      dispatch(tableLoading(true));
      const resp = await API.get("usuarios", "/usuarios/externos", params);
        dispatch(
          setDataTable({
            ...resp,
          })
        );
    } catch (error) {
      console.log("ocurrio un error " + error);
    } finally {
      dispatch(tableLoading(false));
    }
  };
};

export const insertUsuario = (body) => {
  return async (dispatch) => {
    const params = {
      headers: {},
      response: false,
      body: {
        ...body,
        sPermisos: body.sPermisos?.map(permiso => ({nIdOpcion: permiso.nIdOpcion})),
        sProcesos: body.sProcesos?.map(proceso => ({nIdProceso: proceso.nIdProceso}))
      },
    };
    let res = {
      ok: false,
      msg: "Error inesperado, intente más tarde."
    }
    try {
      dispatch(loading(true));
      const resp = await API.post("usuarios", "/usuarios/alta", params);
      res = {
        ok: resp?.nCodigo === 0,
        msg: resp?.sMensaje
      }
    } catch (error) {
      console.log("ocurrio un error " + error);
    } finally {
      dispatch(loading(false));
      return res;
    }
  };
};

export const insertUsuarioExterno = (body) => {
  return async (dispatch) => {
    const params = {
      headers: {},
      response: false,
      body,
    };
    let res = {
      ok: false,
      msg: "Error inesperado, intente más tarde."
    }
    try {
      dispatch(loading(true));
      const resp = await API.post("usuarios", "/usuarios/externo", params);
      res = {
        ok: resp?.nCodigo === 0,
        msg: resp?.sMensaje
      }
    } catch (error) {
      console.log("ocurrio un error " + error);
    } finally {
      dispatch(loading(false));
      return res;
    }
  };
};

export const updateUsuario = (body) => {
  return async (dispatch) => {
    const params = {
      headers: {},
      response: false,
      body: {
        ...body,
        sPermisos: body.sPermisos?.map(permiso => ({nIdOpcion: permiso.nIdOpcion})),
        sProcesos: body.sProcesos?.map(proceso => ({nIdProceso: proceso.nIdProceso}))
      },
    };
    let res = {
      ok: false,
      msg: "Error inesperado, intente más tarde."
    }
    try {
      dispatch(loading(true));
      const resp = await API.put("usuarios", "/usuarios/usuario", params);
      res = {
        ok: resp?.nCodigo === 0,
        msg: resp?.sMensaje
      }
    } catch (error) {
      console.log("ocurrio un error " + error);
    } finally {
      dispatch(loading(false));
      return res;
    }
  };
};

export const updateUsuarioExterno = (body) => {
  return async (dispatch) => {
    const params = {
      headers: {},
      response: false,
      body,
    };
    let res = {
      ok: false,
      msg: "Error inesperado, intente más tarde."
    }
    try {
      dispatch(loading(true));
      const resp = await API.put("usuarios", "/usuarios/externo", params);
      res = {
        ok: resp?.nCodigo === 0,
        msg: resp?.sMensaje
      }
    } catch (error) {
      console.log("ocurrio un error " + error);
    } finally {
      dispatch(loading(false));
      return res;
    }
  };
};

export const updateUsuarioEstatus = (nIdUsuario, sUsuario, nIdEstatus) => {
  return async (dispatch) => {
    const params = {
      headers: {},
      response: false,
      body: {nIdUsuario, sUsuario, nIdEstatus},
    };
    let res = {
      ok: false,
      msg: "Error inesperado, intente más tarde."
    }
    try {
      dispatch(loading(true));
      const resp = await API.put("usuarios", "/usuarios/estatus", params);
      res = {
        ok: resp?.nCodigo === 0,
        msg: resp?.sMensaje
      }
    } catch (error) {
      console.log("ocurrio un error " + error);
    } finally {
      dispatch(loading(false));
      return res;
    }
  };
};

export const updateUsuarioBaja = (nIdUsuario, sUsuario) => {
  return async (dispatch) => {
    const params = {
      headers: {},
      response: false,
      body: {nIdUsuario, sUsuario},
    };
    let res = {
      ok: false,
      msg: "Error inesperado, intente más tarde."
    }
    try {
      dispatch(loading(true));
      const resp = await API.put("usuarios", "/usuarios/baja", params);
      res = {
        ok: resp?.nCodigo === 0,
        msg: resp?.sMensaje
      }
    } catch (error) {
      console.log("ocurrio un error " + error);
    } finally {
      dispatch(loading(false));
      return res;
    }
  };
};


export const insertPerfilUsuario = (body) => {
  return async (dispatch) => {
    const params = {
      headers: {},
      response: false,
      body: {
        ...body,
        sPermisos: body.sPermisos?.map(permiso => ({nIdOpcion: permiso.nIdOpcion})),
      },
    };
    let res = {
      ok: false,
      msg: "Error inesperado, intente más tarde."
    }
    try {
      dispatch(loading(true));
      const resp = await API.post("usuarios", "/usuarios/perfil", params);
      res = {
        ok: resp?.nCodigo === 0,
        msg: resp?.sMensaje
      }
    } catch (error) {
      console.log("ocurrio un error " + error);
    } finally {
      dispatch(loading(false));
    }
    return res;
  };
};

export const updatePerfilUsuario = (body) => {
  return async (dispatch) => {
    const params = {
      headers: {},
      response: false,
      body
    };
    let res = {
      ok: false,
      msg: "Error inesperado, intente más tarde."
    }
    try {
      dispatch(loading(true));
      const resp = await API.put("usuarios", "/usuarios/perfil", params);
      res = {
        ok: resp?.nCodigo === 0,
        msg: resp?.sMensaje
      }
    } catch (error) {
      console.log("ocurrio un error " + error);
    } finally {
      dispatch(loading(false));
    }
    return res;
  };
};

export const deletePerfilUsuario = (body) => {
  return async (dispatch) => {
    const params = {
      headers: {},
      response: false,
      body
    };
    let res = {
      ok: false,
      msg: "Error inesperado, intente más tarde."
    }
    try {
      dispatch(loading(true));
      const resp = await API.del("usuarios", "/usuarios/perfil", params);
      res = {
        ok: resp?.nCodigo === 0,
        msg: resp?.sMensaje
      }
    } catch (error) {
      console.log("ocurrio un error " + error);
    } finally {
      dispatch(loading(false));
    }
    return res;
  };
};