import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';

import PropTypes from 'prop-types';
import '../../styles/modal.css'
import { useSelector } from 'react-redux';
import { Loading } from '../Loading';


const styleModal = {
  position : 'absolute',
  top      : '50%',
  left     : '50%',
  transform: 'translate(-50%, -50%)',
  width    : 650,
  bgcolor  : 'background.paper',
  boxShadow: '0px 8px 12 rgba(0, 0, 0, 0.25)',
  p        : 2,
};

export const ModalBase = ({ open, title, style, children, onClose, disableLoading= false}) => {

  const { isLoading } = useSelector((state) => state.ui);
  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={open}
      onClose={onClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500
      }}
    >
      <Fade in={open}>
        <Box
          sx={style ? { ...styleModal, ...style } : { ...styleModal }}
          style={{ outline: 'none', border: 'none' }}
          className="modal__reponsive"
        >

          {isLoading && !disableLoading && <Loading inModal/>}
          <div
            className="modal__title"
            style={{ width: '98%', margin: '0px auto' }}
          >
            <p>{title}</p>
          </div>
          <div
            style={{
              width: '98%',
              height: '87%',
              margin: '10px auto',
              padding: '5px',
            }}
          >
            {children}
          </div>
        </Box>
      </Fade>
    </Modal>
  );
}


ModalBase.propTypes = {
  open: PropTypes.bool.isRequired,
  disableLoading: PropTypes.bool,
  title   : PropTypes.string,
  style   : PropTypes.object,
  children: PropTypes.node,
  onClose : PropTypes.func
};