import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  dataTable:  {
    columns: [],
    rows   : []
  },
};

export const sucursalesSlice = createSlice({
  name: 'sucursales',
  initialState,
  reducers: {
    setDataTable: (state, action) => {
      state.dataTable = {...action.payload};
    },
  }
});

export const {
  setDataTable,
} = sucursalesSlice.actions;
