import { Grid, Typography } from "@mui/material";
import { useDispatch } from "react-redux";
import { getToBase64 } from "../../../helpers/base64";
import { useModal } from "../../../hooks/useModal";
import { setContactosList } from "../../../slices/cadena/cadenaSlice";
import { signedURL } from "../../../slices/cadena/cadenaThunk";

export const InfoText = ({
  title,
  text,
  md = 4,
  sm = 4,
  xs = 6,
  keyURL = '',
  contacts = [],
  display = 'column',
  escalonados = false,
  openEscalonados = undefined,
  onClickFunction = null,
  customText = null,
  noHideText = false
}) => {
  const cursor = keyURL !== '' || contacts.length > 0 || escalonados ? 'pointer' : '';
  const { openMoldaViewPdf, setOpenToast, isLoading } = useModal();
  const dispatch = useDispatch();

  const openURL = async () => {
    if (keyURL !== '') {
      const { ok, msj, url } = await dispatch(signedURL(keyURL));
      let name = keyURL.split('/')
      name = name[name.length - 1]
      if (ok) {
        isLoading(true)
        fetch(url)
          .then(res => res.blob())
          .then(async (blob) => {
            await getToBase64(blob).then((resp) => {
              openMoldaViewPdf(resp, name);
            })
          })

        isLoading(false)
      } else {
        setOpenToast(!ok, msj);
      }
    }
  };

  const openContact = () => {
    dispatch(setContactosList(contacts));
    onClickFunction();
  };

  const onClick = async () => {
    if (keyURL !== '') {
      await openURL();
    } else if (contacts.length > 0) {
      onClickFunction && openContact()

    } else if (escalonados && openEscalonados) {
      openEscalonados();
    }
  };

  return (
    <Grid item md={md} sm={sm} xs={xs} onClick={onClick}>
      <div
        style={{
          width: '100%',
          display: 'flex',
          flexDirection: display,
          gap: '3px',
          cursor: cursor
        }}
      >
        <Typography
          variant="p"
          color="#00609C"
          style={{
            fontSize: '16px'
          }}
        >
          {title}
        </Typography>
        <Typography
          variant="p"
          color="#828282"
          className={`info-text_p ${noHideText ? 'noHidden' : ''}`}
        >
          {noHideText ?
            String(customText ? customText : text).split('\n').map((str, key) => <>{key ? <br></br> : <></>}{str} </>)
          : <>
              {(customText ? customText : text) || "---"}
            </>}
        </Typography>
      </div>
    </Grid>
  );
};