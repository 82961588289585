import { Grid, Button, Checkbox, Tooltip, tooltipClasses } from "@mui/material";
import { styled, alpha } from "@mui/material/styles";
import InputBase from "@mui/material/InputBase";
import SearchIcon from "@mui/icons-material/Search";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import SummarizeIcon from "@mui/icons-material/Summarize";
import "../../styles/reportTable.css";
import React, { useEffect, useRef, useState } from "react";
import { useDownloadExcel } from "react-export-table-to-excel";
import jsPDF from "jspdf";
import autoTable from "jspdf-autotable";
import { useReportsPage } from "../../hooks/useReportsPage";
import moment from "moment";
import CloseIcon from '@mui/icons-material/Close';
import DoneIcon from '@mui/icons-material/Done';

var doc = new jsPDF();

const BootstrapTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.common.black,
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.black,
  },
}));


const Search = styled("div")(({ theme }) => ({
  position: "relative",
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  "&:hover": {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  marginLeft: 0,
  width: "100%",
  [theme.breakpoints.up("sm")]: {
    marginLeft: theme.spacing(1),
    width: "auto",
  },
  border: "1px solid #f1f1f1",
}));

const SearchIconWrapper = styled("div")(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: "100%",
  position: "absolute",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "inherit",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      width: "12ch",
      "&:focus": {
        width: "20ch",
      },
    },
  },
}));

const getTextAlign = (type)=>{
  let textAlign = '';
  switch(type){
    case 'number':
      textAlign = 'center'
      break
    case 'price':
      textAlign = 'right'
      break
    case 'text':
      textAlign = 'left'
      break
  }
  return textAlign;
}

export const ReportOrdenesPagoTable = () => {
  const { dataTable, cadenas, emisores, addRow, deleteRow, setRows, selectedRows, openOrdenPagoModal, estatusPago } = useReportsPage();
  const [table, setTable] = useState({
    columns: [],
    rows: [],
  });
  const [search, setSearch] = useState("");
  const [printPdf, setPrintPdf] = useState(false);
  const tableRef = useRef();


  

  useEffect(() => {
    var innerData = [];
    if (search) {
      dataTable.rows.map((row) =>
        dataTable.columns.map((column) => {
          if (column.type === "text") {
            if (
              row[column.field]
                .toLocaleString()
                .toLowerCase()
                .includes(search.toLowerCase())
            ) {
              if (!innerData.includes(row)) {
                innerData.push(row);
              }
            }
          }
        })
      );
      setTable({
        ...table,
        rows: innerData,
      });
    } else {
      setTable(dataTable);
    }
  }, [search]);

  useEffect(() => {
    setTable(dataTable);
  }, [dataTable]);

  const handleAllCheckboxes = (event, rows) =>{
    if(event.target.checked){
      setRows(rows)
    }else{
      setRows([])
    }
  }

  const handleCheckbox = (event, row) =>{
    if(event.target.checked){
      addRow(row)
    }else{
      deleteRow(row)
    }
  }

  const getColumnTotal = (key) => {
    var total = 0;
    table.rows.map((row) => (total += parseFloat(row[key])));
    return total;
  };

  const { onDownload } = useDownloadExcel({
    currentTableRef: tableRef.current,
    filename: `Reporte ${table.title} (${table.details?.dFecInicial} - ${table.details?.dFecFinal})`,
    sheet: "Datos",
  });

  const downloadXls = () => {
    setPrintPdf(false);
    onDownload();
  };

  const downloadPdf = () => {
    doc = new jsPDF("landscape");

    setPrintPdf(true);
    setTimeout(() => {
      doc.setFont("helvetica");
      doc.setFontSize(9);
      autoTable(doc, {
        html: "#reportTable",
        margin: { top: 28 },
        styles: { fontSize: 8 },
        didDrawPage: header,
      });
      doc.save(`Reporte ${table.title} (${table.details?.dFecInicial} - ${table.details?.dFecFinal}).pdf`);
    }, 0);
  };

  var header = function (data) {
    doc.setFontSize(5);
    doc.addImage(
      "/assets/SofipayLogo.png",
      "JPEG",
      data.settings.margin.left,
      10,
      38,
      10
    );
    doc.setFont("helvetica", "bold");
    doc.setFontSize(11);
    doc.text(
      "Reporte de " + dataTable.title.toLowerCase(),
      doc.internal.pageSize.width - 15,
      10,
      {
        align: "right",
      }
    );

    doc.setFont("helvetica", "normal");
    doc.setFontSize(11);
    doc.text(
      dataTable.details.dFecInicial + " a " + dataTable.details.dFecFinal,
      doc.internal.pageSize.width - 15,
      15,
      {
        align: "right",
      }
    );
      
    console.log(getFilter())
    if(getFilter()){
        doc.setFont("helvetica", "normal");
        doc.setFontSize(11);
        doc.text(
          getFilter().name + getFilter().value,
          doc.internal.pageSize.width - 15,
          20,
          {
            align: "right",
          }
        );
    }
    doc.setFontSize(8);
    doc.text(
      "Red Efectiva SA de CV",
      55,
      11,
      {
        align: "left",
      }
    );
    doc.text(
      "Blvd. Antonio L. Rdz. 3058 Suite 201-A",
      55,
      14,
      {
        align: "left",
      }
    );
    doc.text(
      "Colonia Santa Maria",
      55,
      17,
      {
        align: "left",
      }
    );
    doc.text(
      "Monterrey, N.L. C.P. 64650",
      55,
      20,
      {
        align: "left",
      }
    );

    //FOOTER
    const pageCount = doc.internal.getNumberOfPages();

    for (var i = 1; i <= pageCount; i++) {
      doc.setPage(i);
      doc.setFont("helvetica");
      doc.setFontSize(8);
      doc.text(
        "Página " + String(i) + " de " + String(pageCount),
        doc.internal.pageSize.width - 10,
        200,
        {
          align: "right",
        }
      );
    }
  };

  const printTd = (value, type) => {
    switch (type) {
      case "price":
        return value?.toLocaleString("en-US", {
          style: "currency",
          currency: "USD",
        });
      case "date":
        return value==="0000-00-00 00:00:00"  || value===null ? "":  moment(value).format("YYYY-MM-DD");

      default:
        return value;
    }
  };

  const getFilter = () => {
    /*console.log(dataTable)*/
    if(dataTable.details.nIdCadena || dataTable.details.nIdEmisor){
      if(dataTable.details.nIdCadena){
        let cadenaItem = cadenas.rows.find(item=> item.nIdCadena === dataTable.details.nIdCadena)
        console.log(cadenaItem)
        if(cadenaItem){
          return({
            name: 'Comisionista: ',
            value: cadenaItem.sNombreComercial
          })
        }
      }
      if(dataTable.details.nIdEmisor){
        let emisorItem = emisores.rows.find(item=> item.nIdEmisor === dataTable.details.nIdEmisor)
        console.log(emisorItem)
        if(emisorItem){
          return({
            name: 'Entidad financiera: ',
            value: emisorItem.sNombreComercial
          })
        }
      }
    }
  
    return false;
  }

  const getFiltersString = () => {
    return (
      <>
        {dataTable.details.nIdEstatusPago !== 0 ? <><br /> Estatus de pago: <b>{estatusPago.find(estatus => estatus.nIdEstatusPago === dataTable.details.nIdEstatusPago)?.sDescripcion}</b></> : <></>}
        {dataTable.details.nIdTipoPago !== 0 ? <><br /> Tipo de ordenes de pago: <b>{dataTable.details.nIdTipoPago === 1 ? "Ordenes de pago" :"Comisión adicional"}</b></> : <></>}
      </>
    )
}

  const tableComponent = () => {
    if (dataTable.columns.length) {
      return (
        <Grid container style={{background: 'white', padding: 20, borderRadius: 5}}>
          <Grid style={{ display: "none" }}>{printTable()}</Grid>
          <Grid container justifyContent={"space-between"} style={{marginBottom: 20}} spacing={2}>
            <Grid item xs={12} sm={6}>
              <Button
                variant="contained"
                startIcon={<PictureAsPdfIcon />}
                onClick={downloadPdf}
              >
                PDF
              </Button>
              <Button
                variant="contained"
                startIcon={<SummarizeIcon />}
                onClick={downloadXls}
                style={{ marginLeft: 10 }}
              >
                Excel
              </Button>
            </Grid>
            <Grid item xs={12} sm={4}>
              <Search>
                <SearchIconWrapper>
                  <SearchIcon />
                </SearchIconWrapper>
                <StyledInputBase
                  value={search}
                  placeholder="Buscar..."
                  onChange={(e) => setSearch(e.target.value)}
                />
              </Search>
            </Grid>
          </Grid>
          <br></br>
          <div
            className="table-container"
            style={{ width: "100%", overflowX: "auto" }}
          >
            <table className="report-table" ref={tableRef}>
              <thead>
                <tr>
                  <th
                    style={{
                      textAlign: "center",
                    }}
                    colSpan={table.columns.length +  1}
                  >
                    {getFilter() ? getFilter().name+getFilter().value : <>
                    Reporte{" "}
                    {dataTable.details.type === 0
                      ? ""
                      : dataTable.details.type === 1
                      ? "entidades financieras"
                      : "comisionistas"}
                    
                    </>}
                  </th>
                </tr>
                <tr>
                  <td colSpan={Math.floor((table.columns.length +1)/ 2)}>
                    <b>
                      REPORTE{" "}
                      {dataTable.details.nDetalle ? "DETALLADO" : "CONDENSADO"}{" "}
                      DE {dataTable.title.toUpperCase()}
                    </b>
                    <br />
                    Periodo de <b>{dataTable.details.dFecInicial}</b> a{" "}
                    <b>{dataTable.details.dFecFinal}</b>
                    {search ? (
                      <>
                        <br /> Búsqueda por: "<b>{search}</b>"
                      </>
                    ) : (
                      <></>
                    )}
                    {getFiltersString()}
                  </td>
                  <td
                    colSpan={Math.ceil((table.columns.length +1) / 2)}
                    style={{ textAlign: "right" }}
                  >
                    <b>Red Efectiva SA de CV</b>
                    <br />
                    Blvd. Antonio L. Rdz. 3058 Suite 201-A <br />
                    Colonia Santa Maria <br />
                    Monterrey, N.L. C.P. 64650
                  </td>
                </tr>
                <tr>
                  <th
                    style={{
                      textAlign: "center",
                    }}
                    colSpan={table.columns.length + 1}
                  >
                    Reporte de {dataTable.title.toLowerCase()}
                  </th>
                </tr>
                <tr>
                  {table.columns.map((column) => (
                    <th style={{ width: column.width, textAlign: 'center' }} key={column.headerName}>{column.headerName}</th>
                  ))}
                  <th key={"checkboxes-container"}>
                    <Checkbox style={{margin: 0, padding: 0}} onChange={(e)=>handleAllCheckboxes(e, table.rows.filter(row => row.nIdEstatusPago === 2))}/>
                  </th>
                </tr>
              </thead>
              <tbody>
                {table.rows.length ? (
                  table.rows.map((row) => (
                    <tr key={row.id}>
                      {table.columns.map((column) => (
                        column.hover && [3,4].includes(row.nIdEstatusPago) ? 
                        <td style={{ width: column.width , textAlign : getTextAlign(column.type) }} key={column.headerName}>
                          <BootstrapTooltip title={<>{row.nIdEstatusPago === 3 ? "Fecha de pago: "+moment(row.dFechaPago).format('YYYY-MM-DD') : "Motivo de cancelación: "+row.sMotivoCancelacion+" ("+moment(row.dFechaCancelacion).format('YYYY-MM-DD')+")"}</>} placement="top">
                              <span style={{padding: 5, background: "rgba(0, 96, 156, 0.15)", display: 'inline-block', borderRadius: 5}}>{printTd(row[column.field], column.type)}</span>
                          </BootstrapTooltip>
                        </td>
                        :
                        <td style={{ width: column.width, textAlign : getTextAlign(column.type)}} key={column.headerName}>
                          {printTd(row[column.field], column.type)}
                        </td>
                      ))}
                      <td key={"checkbox-container"}>
                        {row.nIdEstatusPago === 2 ?<Checkbox style={{margin: 0, padding: 0}} checked={selectedRows.some(selectedRow=> selectedRow.nIdOrdenPago === row.nIdOrdenPago )} onChange={(e)=>handleCheckbox(e, row)}/> : <></>}
                        
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td
                      colSpan={table.columns.length + 1}
                      style={{
                        textAlign: "center",
                      }}
                    >
                      No se encontraron resultados
                    </td>
                  </tr>
                )}
              </tbody>
              <tfoot>
                <tr>
                  {table.columns.map((column) =>
                    column.type === "number" || column.type === "price" ? (
                      <td key={column.headerName} style={{textAlign: column.type === "number" ? 'center' : 'right' }}>
                        {printTd(getColumnTotal(column.field), column.type)}
                      </td>
                    ) : (
                      <td></td>
                    )
                  )}
                  <td></td>
                </tr>
              </tfoot>
            </table>
          </div>
          {selectedRows.length ? 
          <Grid container justifyContent={"flex-end"} style={{marginBottom: 10, marginTop: 20}} spacing={2}>
              <Button variant="contained" startIcon={<CloseIcon />} color="error" onClick={()=>openOrdenPagoModal('rechazado')}>Rechazado</Button>
              <Button variant="contained" startIcon={<DoneIcon />} color="success" onClick={()=>openOrdenPagoModal('pagado')} style={{marginLeft: 5}}>Pagado</Button>
          </Grid> : <></>
          }
        </Grid>
      );
    }
  };

  const printTable = () => {
    return (
      <table id="reportTable" className="report-table">
        <thead>
          <tr>
            {table.columns.map((column) => (
              <th style={{ width: column.width }}>{column.headerName}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {table.rows.length ? (
            table.rows.map((row) => (
              <tr key={row.id}>
                {table.columns.map((column) => (
                  <td style={{ width: column.width }}>
                    {printTd(row[column.field], column.type)}
                  </td>
                ))}
              </tr>
            ))
          ) : (
            <tr>
              <td
                colSpan={table.columns.length}
                style={{
                  textAlign: "center",
                }}
              >
                No se encontraron resultados
              </td>
            </tr>
          )}
          <tr>
            {table.columns.map((column) =>
              column.type === "number" || column.type === "price" ? (
                <td>{printTd(getColumnTotal(column.field), column.type)}</td>
              ) : (
                <td></td>
              )
            )}
          </tr>
        </tbody>
      </table>
    );
  };
  return tableComponent();
};
