export const formatMoney = ( value, currency = 'MXN', fourDecimal = false ) => {
  try {
    const formatter = new Intl.NumberFormat('es-MX', {
      style: 'currency',
      currency,
      minimumFractionDigits: fourDecimal ? 4 : 2
    })
    return formatter.format(value).toString();
  } catch (error) {
    return `${value}.00`;
  }
  
}