import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  todos: []
}

export const todosSlice = createSlice({
  name: 'todo',
  initialState,
  reducers: {
    setTodos: (state, action) => {
      state.todos = action.payload;
    }
  },
})

export const { setTodos} = todosSlice.actions