import { styled, alpha } from '@mui/material/styles';
import { Switch } from '@mui/material';

const BlueSwitch = styled(Switch)(({ theme }) => ({
  '& .MuiSwitch-switchBase.Mui-checked': {
    color    : '#00609C',
    '&:hover': {
      backgroundColor: alpha('#879ACC', theme.palette.action.hoverOpacity)
    }
  },
  '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
    backgroundColor: '#879ACC'
  }
}));

const buttonContactStyle = {
  height: '55px',
  width : '100%',
  border: '1px solid #00609C',
  color : '#00609C'
};

const buttonSmallContactStyle = {
  height: '40px',
  width : '100%',
  border: '1px solid #00609C',
  color : '#00609C'
};

const bttnFactStyle = {
  height: '55px',
  width : '100%',
  border: '1px solid #00CD68',
  color : '#00CD68',
  '&:hover' : {
    background: '#EFF7F3',
    border    : '1px solid #00CD68'
  }
};

const bttnFactStyleSave = {
  border: '1px solid #00CD68',
  color : '#00CD68',
  transition: '0.3s ease all',
  marginLeft: '5px',
  '&:hover' : {
    background: '#EFF7F3',
    border    : '1px solid #00CD68'
  }
};

const bttnSmallFactStyle = {
  height: '40px',
  width : '100%',
  border: '1px solid #00CD68',
  color : '#00CD68',
  '&:hover' : {
    background: '#EFF7F3',
    border    : '1px solid #00CD68'
  }
};

const buttonContactStyleError = {
  height: '55px',
  width : '100%',
  border: '1px solid #EF4236',
  color : '#EF4236',
  '&:hover' : {
    background: '#FFF2F2',
    border    : '1px solid #EF4236'
  }
};

const buttonFileStyle = {
  width     : '80%',
  background: '#00609C',
  '&:hover' : {
    background: '#00609C'
  }
};

const buttonViewPDF = {
  background: 'transparent',
  border    : '1px solid transparent',
  color     : '#00609C',
  '&:hover' : {
    background: 'transparent',
    border    : '1px solid transparent'
  }
};

export {
  BlueSwitch,
  buttonContactStyle,
  buttonFileStyle,
  buttonViewPDF,
  buttonContactStyleError,
  bttnFactStyle,
  buttonSmallContactStyle,
  bttnSmallFactStyle,
  bttnFactStyleSave
}