import { useSelector, useDispatch } from "react-redux";
import { useEffect, useState } from "react";
import { deleteCatalogoArchivoSFTP, deleteCatalogoBancos, deleteCatalogoCuentasContables, deleteCatalogoDocumentos, deleteCatalogoGiros, deleteCatalogoIdentificadorCNBV, deleteCatalogoMotivosBaja, deleteCatalogoProcesos, deleteCatalogoSectoresCNBV, deleteCatalogoTiposActor, postCatalogoArchivoSFTP, postCatalogoBancos, postCatalogoDocumentos, postCatalogoGiros, postCatalogoIdentificadorCNBV, postCatalogoMotivosBaja, postCatalogoProcesos, postCatalogoSectoresCNBV, postCatalogoTiposActor, putCatalogoArchivoSFTP, putCatalogoBancos, putCatalogoCuentasContables, putCatalogoDocumentos, putCatalogoGiros, putCatalogoIdentificadorCNBV, putCatalogoMotivosBaja, putCatalogoProcesos, putCatalogoSectoresCNBV, putCatalogoTiposActor, startLoadArchivosSFTP, startLoadBancos, startLoadCarpetasSFTP, startLoadCuentasContables, startLoadDocumentos, startLoadEstatusCNBV, startLoadGiros, startLoadIdentificadorCNBV, startLoadMotivosBaja, startLoadProcesos, startLoadSectoresCNBV, startLoadTareas, startLoadTiposActor } from "../slices/catalogo/catalgoThunk";
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import TopicIcon from '@mui/icons-material/Topic';
import { useModal } from "./useModal";
import { archivoSFTPForm, catalogoBancoForm, catalogoDocumentosForm, catalogoGiroForm, catalogoIdentificadorForm, catalogoMotivosBajaForm, catalogoProcesoForm, catalogoSectorForm, conexionSPForm, tipoActorForm } from "../helpers/forms";
import { useAuthPage } from "./useAuthPage";
import BadgeIcon from '@mui/icons-material/Badge';
import BusinessIcon from '@mui/icons-material/Business';
import { loading, tableLoading } from "../slices/ui/uiSlice";
import { AccountBalanceWallet, Cable, Delete, Description, Folder, Group, Settings, UploadFileRounded } from "@mui/icons-material";
import { deleteConexionSP, getConexionesSofiPay, insertConexionSP, updateConexionSP, verPassword, verPasswordSP } from "../slices/conexionesSFTP/conexionesSFTPThunk";


const catalogos = [
  {
    id: "catalogoBancos",
    icon: <AccountBalanceIcon />,
    iconTitle: <AccountBalanceIcon fontSize={'large'} sx={{ color: '#00CD68', fontSize: '45px' }} />,
    name: "Bancos",
    key: "nIdBanco",
    columns: [
      { field: 'nIdBanco', headerName: 'ID', flex: 0.3 },
      { field: 'sNombre', headerName: 'Nombre', flex: 1 },
      { field: 'sDescripcion', headerName: 'Descripción', flex: 3 },
      { field: 'sClave', headerName: 'Clave', flex: 0.5 },
      { field: 'bCorresponsalia', headerName: 'Corresponsalia', flex: 0.3, type: "render", renderFunction: (row) => row.bCorresponsalia ? "Si" : "No" },
    ],
    form: catalogoBancoForm,
    submitFunctions: {
      add: (item) => postCatalogoBancos(item),
      edit: (item) => putCatalogoBancos(item),
      delete: (item) => deleteCatalogoBancos(item)
    }
  },
  {
    id: "catalogoGiros",
    icon: <TopicIcon />,
    iconTitle: <TopicIcon fontSize={'large'} sx={{ color: '#00CD68', fontSize: '45px' }} />,
    name: "Giros",
    key: "nIdGiro",
    columns: [
      { field: 'nIdGiro', headerName: 'ID', flex: 0.3 },
      { field: 'sDescripcion', headerName: 'Descripción', flex: 6 },
    ],
    form: catalogoGiroForm,
    submitFunctions: {
      add: (item) => postCatalogoGiros(item),
      edit: (item) => putCatalogoGiros(item),
      delete: (item) => deleteCatalogoGiros(item)
    }
  },
  {
    id: "catalogoIdentificadorCNBV",
    icon: <BadgeIcon />,
    iconTitle: <BadgeIcon fontSize={'large'} sx={{ color: '#00CD68', fontSize: '45px' }} />,
    name: "Identificadores CNBV",
    key: "nIdIdentificador",
    columns: [
      { field: 'nIdIdentificador', headerName: 'ID', flex: 0.3 },
      { field: 'nIdSector', headerName: 'Sector', flex: 0.3 },
      { field: 'sClave', headerName: 'Clave', flex: 1 },
      { field: 'sDescripcion', headerName: 'Descripción', flex: 8 },
      { field: 'sEstado', headerName: 'Estado', flex: 2 },
    ],
    form: catalogoIdentificadorForm,
    submitFunctions: {
      add: (item) => postCatalogoIdentificadorCNBV(item),
      edit: (item) => putCatalogoIdentificadorCNBV(item),
      delete: (item) => deleteCatalogoIdentificadorCNBV(item)
    }
  },
  {
    id: "catalogoSectoresCNBV",
    icon: <BusinessIcon />,
    iconTitle: <BusinessIcon fontSize={'large'} sx={{ color: '#00CD68', fontSize: '45px' }} />,
    name: "Sectores CNBV",
    key: "nIdSector",
    columns: [
      { field: 'nIdSector', headerName: 'ID', flex: 0.3 },
      { field: 'sDescripcion', headerName: 'Descripción', flex: 8 }
    ],
    form: catalogoSectorForm,
    submitFunctions: {
      add: (item) => postCatalogoSectoresCNBV(item),
      edit: (item) => putCatalogoSectoresCNBV(item),
      delete: (item) => deleteCatalogoSectoresCNBV(item)
    }
  },
  {
    id: "catalogoCuentasContables",
    icon: <AccountBalanceWallet />,
    iconTitle: <AccountBalanceWallet fontSize={'large'} sx={{ color: '#00CD68', fontSize: '45px' }} />,
    name: "Cuentas contables",
    key: "nIdCuenta",
    columns: [
      { field: 'nIdCuenta', headerName: 'ID', flex: 0.3 },
      { field: 'sNombre', headerName: 'Nombre', flex: 3 },
      { field: 'nCuenta', headerName: 'Cuenta', flex: 3 },
      {
        field: 'nTipo', headerName: 'Tipo', flex: 1, type: "render", renderFunction: (row) => {
          if (row.nTipo === 1) return "EFR"
          if (row.nTipo === 0) return "Comisionista"
          return "Ambos"
        }
      },
      { field: 'nFlujoImporte', headerName: 'Flujo Importe', flex: 1, type: "render", renderFunction: (row) => row.nFlujoImporte ? "Cash In" : "Cash Out" },
    ],
    form: catalogoSectorForm,
    submitFunctions: {
      edit: (item) => putCatalogoCuentasContables(item),
      delete: (item) => deleteCatalogoCuentasContables(item)
    }
  },
  {
    id: "catalogoDocumentos",
    icon: <Description />,
    iconTitle: <Description fontSize={'large'} sx={{ color: '#00CD68', fontSize: '45px' }} />,
    name: "Documentos",
    key: "nIdDocumento",
    columns: [
      { field: 'nIdDocumento', headerName: 'ID', flex: 0.3 },
      { field: 'sNombreDocumento', headerName: 'Descripción', flex: 8 },
      { field: 'sAbrevDoc', headerName: 'Abreviación', flex: 1 },
      {
        field: 'nTipo', headerName: 'Tipo', flex: 1, type: "render", renderFunction: (row) => {
          if (row.nTipo === 2) return "EFR"
          if (row.nTipo === 1) return "Comisionista"
          return "Ambos"
        }
      },
    ],
    form: catalogoDocumentosForm,
    submitFunctions: {
      add: (item) => postCatalogoDocumentos(item),
      edit: (item) => putCatalogoDocumentos(item),
      delete: (item) => deleteCatalogoDocumentos(item)
    }
  },
  {
    id: "catalogoMotivosBaja",
    icon: <Delete />,
    iconTitle: <Delete fontSize={'large'} sx={{ color: '#00CD68', fontSize: '45px' }} />,
    name: "Motivos baja",
    key: "nIdMotivoBaja",
    columns: [
      { field: 'nIdMotivoBaja', headerName: 'ID', flex: 0.3 },
      { field: 'sDescripcion', headerName: 'Descripción', flex: 8 },
      { field: 'nIdCatComisionistaBaja', headerName: 'ID Motivo Baja', flex: 1 },
      { field: 'nIdCatComisionistaBajaIFPE', headerName: 'ID Motivo Baja IFPE', flex: 1 }
    ],
    form: catalogoMotivosBajaForm,
    submitFunctions: {
      add: (item) => postCatalogoMotivosBaja(item),
      edit: (item) => putCatalogoMotivosBaja(item),
      delete: (item) => deleteCatalogoMotivosBaja(item)
    }
  },
  {
    id: "catalogoProcesos",
    icon: <Settings />,
    iconTitle: <Settings fontSize={'large'} sx={{ color: '#00CD68', fontSize: '45px' }} />,
    name: "Procesos",
    key: "nIdProceso",
    columns: [
      { field: 'nIdProceso', headerName: 'ID', flex: 0.3 },
      { field: 'sDescripcion', headerName: 'Nombre', flex: 4 },
      {
        field: 'nTipo', headerName: 'Tipo', flex: 2, format: "render", renderFunction: (row) => {
          switch (row.nTipo) {
            case 1:
              return "Contactos"
            case 2:
              return "Usuarios internos"
            default:
              return "Contactos y usuarios internos"
          }
        }
      },
      { field: 'nRequerido', headerName: 'Requerido', flex: 1, format: "render", renderFunction: (row) => row.nRequerido ? "Sí" : "No" },
      { field: 'sEventoNotificacion', headerName: 'Eventos notificación', flex: 8, format: "render", renderFunction: (row) => row.sEventoNotificacion || "--" }
    ],
    form: catalogoProcesoForm,
    submitFunctions: {
      add: (item) => postCatalogoProcesos(item),
      edit: (item) => putCatalogoProcesos(item),
      delete: (item) => deleteCatalogoProcesos(item)
    }
  },
  {
    id: "catalogoArchivosSFTP",
    icon: <UploadFileRounded />,
    iconTitle: <UploadFileRounded fontSize={'large'} sx={{ color: '#00CD68', fontSize: '45px' }} />,
    name: "Archivos SFTP",
    key: "nIdArchivoSFTP",
    columns: [
      { field: 'nIdArchivoSFTP', headerName: 'ID', flex: 0.3, style: { width: 25 } },
      { field: 'sNombreArchivo', headerName: 'Nombre', flex: 4 },
      {
        field: 'nObligado', headerName: 'Obligado', flex: 2, format: "render", renderFunction: (row) => parseInt(row.nObligado) ? "Sí" : "No", style: { width: 25 }
      },
      {
        field: 'nIdEntidadParticipante', headerName: 'Actor', flex: 2, format: "render", renderFunction: (row) => {
          switch (row.nIdEntidadParticipante) {
            case 1:
              return "EFR"
            case 2:
              return "Comisionistas"
            default:
              return ""
          }
        }
      },
      { field: 'sCarpeta', headerName: 'Requerido', flex: 1 },
      { field: 'sComentario', headerName: 'Comentario', flex: 4 },
    ],
    form: archivoSFTPForm,
    submitFunctions: {
      add: (item) => postCatalogoArchivoSFTP(item),
      edit: (item) => putCatalogoArchivoSFTP(item),
      delete: (item) => deleteCatalogoArchivoSFTP(item)
    }
  },
  {
    id: "catalogoConexionesSP",
    icon: <Cable />,
    iconTitle: <Cable fontSize={'large'} sx={{ color: '#00CD68', fontSize: '45px' }} />,
    name: "Conexiones SofiPay",
    key: "nIdCfgConexion",
    columns: [
      { field: 'nIdCfgConexion', headerName: 'ID', flex: 0.3, style: { width: 25 } },
      { field: 'sNombreConexion', headerName: 'Nombre', flex: 4 },
      { field: 'sHost', headerName: 'Host', flex: 3 },
      { field: 'nPort', headerName: 'Puerto', flex: 1 },
      { field: 'sUser', headerName: 'Usuario', flex: 3 },
      { field: 'sComentario', headerName: 'Comentario', flex: 4 },
    ],
    form: conexionSPForm,
    submitFunctions: {
      add: (item) => insertConexionSP(item),
      edit: (item) => updateConexionSP(item),
      delete: (item) => deleteConexionSP(item)
    }
  },
  {
    id: "catalogoTiposActor",
    icon: <Group />,
    iconTitle: <Group fontSize={'large'} sx={{ color: '#00CD68', fontSize: '45px' }} />,
    name: "Tipos de actor",
    key: "nIdTipoActor",
    columns: [
      { field: 'nIdTipoActor', headerName: 'ID', flex: 0.3, style: { width: 25 } },
      { field: 'sNombre', headerName: 'Nombre', flex: 4 },
      { field: 'sDescripcion', headerName: 'Descripción', flex: 8 },
    ],
    form: tipoActorForm,
    submitFunctions: {
      add: (item) => postCatalogoTiposActor(item),
      edit: (item) => putCatalogoTiposActor(item),
      delete: (item) => deleteCatalogoTiposActor(item)
    }
  },
]

export const useCatalogos = () => {

  const dispatch = useDispatch();

  const { bancos, giros, documentos, motivosBaja, identificadores, sectoresCNBV, estatusCNBV, cuentasContables, procesos, tareas, archivosSFTP, carpetasSFTP, tiposActor } = useSelector((state) => state.catalogo);
  const { conexionesSofiPay } = useSelector((state) => state.conexionesSFTP);

  const [selectedCatalogo, setSelectedCatalogo] = useState({})
  const [hideMenu, setHideMenu] = useState(false)
  const [catalogoItems, setCatalogoItems] = useState([])
  const [dataTable, setDataTable] = useState([])
  const [form, setForm] = useState({})
  const [backupForm, setBackupForm] = useState({})
  const { setOpenToast } = useModal();
  const [openModal, setOpenModal] = useState({ open: false, type: "" })
  const { getChildrenPermissions, page } = useAuthPage();

  useEffect(() => {
    loadInInit()
  }, [page])

  const loadInInit = () => {
    if (!selectedCatalogo?.id || !catalogoItems.length) {
      const permisos = getChildrenPermissions().reduce((arr, item) => {
        arr.push(item.nombre)
        return arr
      }, [])

      const innerCatalogos = catalogos.filter(cat => permisos.includes(cat.id))
      setCatalogoItems(innerCatalogos)
      if (innerCatalogos.length) {
        setSelectedCatalogo(innerCatalogos[0])
        getCatalogoData(innerCatalogos[0])
      }
    }
  }

  const getCatalogoData = async (selected, forceReload = false) => {
    let data = []
    switch (selected?.id) {
      case "catalogoBancos":
        if (!bancos.length || forceReload) data = await dispatch(startLoadBancos("", true))
        else data = bancos
        break;
      case "catalogoGiros":
        if (!giros.length || forceReload) data = await dispatch(startLoadGiros(0, true))
        else data = giros
        break;
      case "catalogoIdentificadorCNBV":
        if (!identificadores.length || forceReload) {
          const { ok, data: dataResp } = await dispatch(startLoadIdentificadorCNBV({ nIdSector: 0 }, true))
          data = ok ? dataResp : []
        } else {
          data = identificadores
        }
        break;
      case "catalogoSectoresCNBV":
        if (!sectoresCNBV.length || forceReload) data = await dispatch(startLoadSectoresCNBV(0, true))
        else data = sectoresCNBV
        break;
      case "catalogoCuentasContables":
        if (!cuentasContables.length || forceReload) data = await dispatch(startLoadCuentasContables(0, 1, true))
        else data = cuentasContables
        break;
      case "catalogoDocumentos":
        if (!documentos.length || forceReload) data = await dispatch(startLoadDocumentos(0, 0, true))
        else data = documentos
        console.log(data, documentos, forceReload)
        break;
      case "catalogoMotivosBaja":
        if (!motivosBaja.length || forceReload) data = await dispatch(startLoadMotivosBaja(0, 1, true))
        else data = motivosBaja
        break;
      case "catalogoProcesos":
        if (!procesos.length || forceReload) data = await dispatch(startLoadProcesos(0, 1, true))
        else data = procesos
        break;
      case "catalogoArchivosSFTP":
        if (!archivosSFTP.length || forceReload) data = await dispatch(startLoadArchivosSFTP(0, 1, true))
        else data = archivosSFTP
        if (forceReload) await dispatch(startLoadCarpetasSFTP())
        break;
      case "catalogoConexionesSP":
        if (!conexionesSofiPay.length || forceReload) data = await dispatch(getConexionesSofiPay())
        else data = conexionesSofiPay
        break;
      case "catalogoTiposActor":
        if (!tiposActor.length || forceReload) data = await dispatch(startLoadTiposActor())
        else data = tiposActor
        break;
      default:
        break;
    }

    setDataTable(data)
    dispatch(tableLoading(false))
  }

  const handleSubmit = async (type = null, item = null) => {
    const innerType = type ?? openModal.type
    const { ok, error } = validateData(innerType, item ?? form)

    if (ok) {
      console.log(selectedCatalogo)
      if (Object.keys(selectedCatalogo.submitFunctions).includes(innerType)) {
        console.log(item ?? form)
        const resp = await dispatch(selectedCatalogo.submitFunctions[innerType](item ?? form))

        setOpenToast(!resp.ok, resp.msg)
        if (resp.ok) {
          if (["add", "edit"].includes(innerType)) {
            handleCloseModal()
          }
          await getCatalogoData(selectedCatalogo, true)
        }

      }
    } else {
      setOpenToast(true, error)
    }
  }

  const onChange = async (e) => {
    const switchInputsName = ["bCorresponsalia"]

    const inputName = Object.keys(e).includes("target") ? e.target.name : Object.keys(e)[0]

    let innerForm = {
      ...form,
      ...Object.keys(e).includes("target") ?
        { [e.target.name]: switchInputsName.includes(e.target.name) ? e.target.checked : e.target.value } :
        e
    }

    if (inputName === "showPassword" && !form.hasDecrypted) {
      const {
        ok,
        msg,
        sPassword
      } = await dispatch(verPasswordSP(form.sPassword))

      if (!ok) {
        setOpenToast(true, msg)
        return
      }

      innerForm = {
        ...innerForm,
        hasDecrypted: true,
        sPassword
      }
    }

    if (inputName === "sInnerPassword") {
      innerForm = {
        ...innerForm,
        isPasswordChanged: true
      }
    }

    setForm(innerForm);
  };

  const handleCloseModal = () => {
    setOpenModal({ open: false, type: "" })
    setForm({})
    setBackupForm({})
  }

  const handleOpenModal = async (type = 'add', innerForm = {}) => {
    await handleStartLoadOpenModal()
    let form = type === 'add' ? selectedCatalogo.form : { ...innerForm, sTareas: innerForm.sTareas ? JSON.parse(innerForm.sTareas) : [] };
    setForm(form)
    setBackupForm(form)
    setOpenModal({ open: true, type })
  }

  const handleStartLoadOpenModal = async () => {
    switch (selectedCatalogo?.id) {
      case "catalogoIdentificadorCNBV":
        if (!estatusCNBV.length) await dispatch(startLoadEstatusCNBV())
        if (!sectoresCNBV.length) await dispatch(startLoadSectoresCNBV())
        break;
      case "catalogoProcesos":
        if (!tareas.length) await dispatch(startLoadTareas())
        break;
      case "catalogoArchivosSFTP":
        if (!tareas.length) await dispatch(startLoadTareas())
        if (!carpetasSFTP.length) await dispatch(startLoadCarpetasSFTP())
        break;
      default:
        break;
    }
  }

  const enableEdit = () => {
    setOpenModal({ ...openModal, type: "edit" })
    setForm({ ...form, sInnerPassword: form.sPassword })
  }

  const disableEdit = () => {
    setOpenModal({ ...openModal, type: "view" })
    setForm(backupForm)
  }

  const handleSelectedCatalogo = (selected) => {
    setSelectedCatalogo(selected)
    getCatalogoData(selected)
  }

  const handleHideMenu = (hide) => {
    setHideMenu(hide)
  }

  const validateData = (type, form) => {
    switch (selectedCatalogo?.id) {
      case "catalogoBancos":
        if (["add", "edit"].includes(type)) {
          if (form.sNombre === '') return getStatus('Ingrese el nombre del banco');
          if (form.sDescripcion === '') return getStatus('Ingrese la descripción');
          if (form.sClave === '') return getStatus('Ingrese la clave');
        }
        if (!form.nIdBanco) return getStatus('Ingrese el identificador del banco');
        return getStatus('', true);
      case "catalogoGiros":
        if (["add", "edit"].includes(type)) {
          if (form.sDescripcion === '') return getStatus('Ingrese la descripción');
        }
        if (["edit", "delete"].includes(type)) {
          if (!form.nIdGiro) return getStatus('Ingrese el identificador del giro');
        }
        return getStatus('', true);
      case "catalogoIdentificadorCNBV":
        if (["add", "edit"].includes(type)) {
          if (form.nIdSector === 0) return getStatus('Seleccione un sector');
          if (form.sClave === '') return getStatus('Ingrese la clave');
          if (form.sDescripcion === '') return getStatus('Ingrese la descripción');
          if (form.sIdentificador === '') return getStatus('Ingrese la abreviación');
          if (form.nIdEstatusCNBV === 0) return getStatus('Seleccione un estatus');
        }
        if (["edit", "delete"].includes(type)) {
          if (!form.nIdIdentificador) return getStatus('Ingrese el id del identificador');
        }
        return getStatus('', true);
      case "catalogoSectoresCNBV":
        if (["add", "edit"].includes(type)) {
          if (form.sDescripcion === '') return getStatus('Ingrese la descripción');
        }
        if (["edit", "delete"].includes(type)) {
          if (!form.nIdSector) return getStatus('Ingrese el identificador del sector');
        }
        return getStatus('', true);
      case "catalogoCuentasContables":
        if (["edit", "delete"].includes(type)) {
          if (!form.nIdCuenta) return getStatus('Ingrese el identificador de la cuenta');
          if (!form.sNombre) return getStatus('Ingrese el nombre de la cuenta');
          if (!form.nCuenta) return getStatus('Ingrese la cuenta contable');
        }
        return getStatus('', true);
      case "catalogoDocumentos":
        if (["add", "edit"].includes(type)) {
          if (form.sNombreDocumento === '') return getStatus('Ingrese el nombre del documento');
          if (form.sAbrevDoc === '') return getStatus('Ingrese la abreviatura del documento');
        }
        if (["edit", "delete"].includes(type)) {
          if (!form.nIdDocumento) return getStatus('Ingrese el id del documento');
        }
        return getStatus('', true);
      case "catalogoMotivosBaja":
        if (["add", "edit"].includes(type)) {
          if (form.sDescripcion === '') return getStatus('Ingrese el nombre de la descripción');
          if (form.nIdCatComisionistaBajaIFPE === '') return getStatus('Ingrese el identificador del motivo de baja');
          if (!form.nIdCatComisionistaBaja) return getStatus('Ingrese el identificador IFPE del motivo de baja');
        }
        if (["edit", "delete"].includes(type)) {
          if (!form.nIdMotivoBaja) return getStatus('Ingrese el id del motivo de la baja');
        }
        return getStatus('', true);

      case "catalogoProcesos":
        if (["add", "edit"].includes(type)) {
          if (form.sDescripcion === '') return getStatus('Ingrese el nombre del proceso');
        }
        if (["edit", "delete"].includes(type)) {
          if (!form.nIdProceso) return getStatus('Ingrese el id del proceso');
        }
        return getStatus('', true);
      case "catalogoArchivosSFTP":
        if (["add", "edit"].includes(type)) {
          if (form.sNombreArchivo === '') return getStatus('Ingrese el nombre del archivo');
          if (!form.nIdEntidadParticipante) return getStatus('Ingrese la entidad participante');
          if (form.nIdCarpetaSFTP === '') return getStatus('Seleccione la carpeta');
          if (form.nIdCarpetaSFTP === 0 && form.sCarpeta === '') return getStatus('Ingrese la carpeta');
        }
        if (["edit", "delete"].includes(type)) {
          if (!form.nIdArchivoSFTP) return getStatus('Ingrese el id del archivo');
        }
        return getStatus('', true);
      case "catalogoConexionesSP":
        if (["add", "edit"].includes(type)) {
          if (form.sNombreConexion === '') return getStatus('Ingrese el nombre de la conexión');
          if (form.sHost === '') return getStatus('Ingrese el host');
          if (form.nPort === '') return getStatus('Ingrese el puerto');
          if (form.sUser === '') return getStatus('Ingrese el usuario');
          if (form.sInnerPassword === '') return getStatus('Ingrese la contraseña');
        }
        if (["edit", "delete"].includes(type)) {
          if (!form.nIdCfgConexion) return getStatus('Ingrese el id de la conexión');
        }
        return getStatus('', true);
      case "catalogoTiposActor":
        if (["add", "edit"].includes(type)) {
          if (form.sNombre === '') return getStatus('Ingrese el nombre');
          if (form.sDescripcion === '') return getStatus('Ingrese la descripción');
        }
        if (["edit", "delete"].includes(type)) {
          if (!form.nIdTipoActor) return getStatus('Ingrese el id del actor');
        }
        return getStatus('', true);
    }
  }

  const getStatus = (error, ok = false, idElement = '') => ({ ok, error, idElement });

  return {
    dataTable,
    selectedCatalogo,
    hideMenu,
    catalogoItems,
    openModal,
    form,

    getCatalogoData,
    handleSelectedCatalogo,
    handleHideMenu,
    handleSubmit,
    handleCloseModal,
    handleOpenModal,
    enableEdit,
    onChange,
    disableEdit,
    loadInInit
  };
};

const tab = (text, color) => {
  return <p style={{ display: "inline-block", color, background: `${color}15`, padding: "4px 8px", borderRadius: "4px", fontWeight: 500, margin: 0, fontSize: 13 }} >{text}</p>
}