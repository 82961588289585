import {
  Button,
  Grid,
  Paper,
  TextField,
} from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { useEffect, useState } from "react";
import SummarizeOutlinedIcon from '@mui/icons-material/SummarizeOutlined';
import "dayjs/locale/es-mx";
import { useReportsPage } from "../hooks/useReportsPage";
import { SearchInput } from "./form/SearchInput";
import moment from "moment";
import { useModal } from "../hooks/useModal";
import {
  useParams
} from "react-router-dom";
import { TextInput } from "./form/TextInput";
import { usePolizasPage } from "../hooks/usePolizasPage";

export const PolizasForm = () => {


  const { form, onChange, escenariosContables, onChangeInput, getPolizas, emisores, cadenas } = usePolizasPage();
  const { setOpenToast } = useModal();

  const [escenarioContable, setEscenarioContable] = useState({})

  useEffect(() => {
    if (escenariosContables.length) {
      const innerEscenarioContable = escenariosContables.find(ec => ec.nIdEscenario === parseInt(form.nIdEscenario))
      setEscenarioContable(innerEscenarioContable ? innerEscenarioContable : {})
    }
  }, [form.nIdEscenario])


  const handleSubmit = () => {
    if (moment(form.dFecFinal).diff(moment(form.dFecInicial)) >= 0) {
      getPolizas()
    } else {
      setOpenToast(true, "La fecha inicial debe de ser menor o igual a la fecha final.")
    }
  }



  return (
    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={"es-mx"}>
      <Paper
        elevation={3}
        style={{
          marginBottom: 30,
          overflow: "hidden",
        }}
      >
        <Grid container>
          <Grid
            item
            width={"50px"}
            style={{
              background: "#00CD68",
              padding: 15,
              color: "white",
              fontWeight: "bold",
              marginBottom: 15,
            }}
          >
            <SummarizeOutlinedIcon />
          </Grid>
          <Grid
            item
            container
            alignItems={"center"}
            style={{
              width: "calc(100% - 50px)",
              background: "#00609C",
              padding: 15,
              color: "white",
              fontWeight: "bold",
              marginBottom: 15,
            }}
          >
            <span>
              Consulta y Descarga de Pólizas
            </span>
          </Grid>
        </Grid>
        <Grid container spacing={2} style={{ padding: 20 }} >
          <Grid item xs={12} sm={2}>
            <TextInput
              id="sFolio"
              name="sFolio"
              label="Folio"
              value={form.sFolio}
              onChangeRoot={onChangeInput}
            />
          </Grid>
          <Grid item xs={12} sm={3}>
            <SearchInput
              options={[{ nIdEscenario: 0, sNombre: 'Todos' }, ...escenariosContables]}
              value={form.nIdEscenario}
              name={"nIdEscenario"}
              label={"Escenario contable"}
              getOptionLabel={"sNombre"}
              getIndexLabel={"nIdEscenario"}
              onChange={onChange}
            />
          </Grid>
          {
            escenarioContable.nDinamica ?
              <Grid item xs={12} sm={3}>
                {
                  escenarioContable.nTipoActor !== 0 ?
                    <SearchInput
                      options={[{ nIdEmisor: 0, sNombreComercial: 'Todos' }, ...emisores.rows]}
                      value={form.nIdActor}
                      name={"nIdActor"}
                      label={"Entidad financiera"}
                      getOptionLabel={"sNombreComercial"}
                      getIndexLabel={"nIdEmisor"}
                      onChange={onChange}
                    />
                    :
                    <SearchInput
                      options={[{ nIdCadena: 0, sNombreComercial: 'Todos' }, ...cadenas.rows]}
                      value={form.nIdActor}
                      name={"nIdActor"}
                      label={"Comisionista"}
                      getOptionLabel={"sNombreComercial"}
                      getIndexLabel={"nIdCadena"}
                      onChange={onChange}
                    />
                }
              </Grid>
              :
              <></>
          }
          <Grid item xs={12} sm={2}>
            <DesktopDatePicker
              label="Fecha inicial"
              inputFormat="YYYY-MM-DD"
              renderInput={(params) => <TextField {...params} />}
              value={form.dFecInicial}
              onChange={(e) => onChange(null, 'dFecInicial', moment(e['$d']).format('YYYY-MM-DD'))}
            />
          </Grid>
          <Grid item xs={12} sm={2}>
            <DesktopDatePicker
              label="Fecha final"
              inputFormat="YYYY-MM-DD"
              renderInput={(params) => <TextField {...params} />}
              value={form.dFecFinal}
              onChange={(e) => onChange(null, 'dFecFinal', moment(e['$d']).format('YYYY-MM-DD'))}
            />
          </Grid>
        </Grid>
        <Grid
          container
          justifyContent={"space-between"}
          spacing={2}
          style={{ padding: 20 }}
        >
          <Grid item xs={12} sm={6} >
          </Grid>
          <Grid item xs={12} sm={6} style={{ textAlign: "right" }}>
            <Button variant="contained" onClick={handleSubmit}>
              Buscar
            </Button>
          </Grid>
        </Grid>
      </Paper>
    </LocalizationProvider>
  );
};
