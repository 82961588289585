import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { closeEnvioEmail } from '../slices/ui/uiSlice';
import moment from "moment";
import jsPDF from "jspdf";
import autoTable from "jspdf-autotable";
import { envioEmailForm } from '../helpers/forms';
import { enviarReporte, getContactosReporte } from '../slices/reports/reportsThunk';
import { saveAs } from 'file-saver';
import { signedURL } from '../slices/cadena/cadenaThunk';
import { useModal } from './useModal';
import { subirArchivoS3 } from '../slices/conciliacion/conciliacionThunk';
var doc = new jsPDF();

export const useModalEnvioEmail = () => {
  const { isOpenEnvioEmail } = useSelector((state) => state.ui);
  const { dataTable } = useSelector((state) => state.reports);
  const { integradores } = useSelector((state) => state.catalogo);

  const dispatch = useDispatch();
  const { setOpenToast, closeModalEnvioEmail } = useModal();

  const [meses, setMeses] = useState([])
  const [emisor, setEmisor] = useState({})
  const [fileData, setFileData] = useState({})
  const [form, setForm] = useState(envioEmailForm)
  const [envioData, setEnvioData] = useState({})
  const [contactos, setContactos] = useState([])
  const [usuarios, setUsuarios] = useState([])
  const [emailEnviados, setEmailEnviados] = useState([])
  const [isSent, setIsSent] = useState({
    sent: false,
    email: {}
  })
  const [showConfirmDialog, setShowConfirmDialog] = useState(false)

  useEffect(() => {
    if (isOpenEnvioEmail) {
      let {
        dMonth = 0,
        dYear = 0
      } = dataTable.details

      dYear = !!dYear ? dYear : moment().format("YYYY")

      let dFecha = moment(`${dYear}-${String(parseInt(dMonth) ? dMonth : 1).padStart(2, '0')}-01`)
      let sMeses = []
      const dFecInicial = dFecha.clone()
      const dFecFinal = !parseInt(dMonth) || moment().startOf('month').isSame(dFecha, 'day') ? moment() : dFecha.clone().endOf('month');
      if (dFecha.isSameOrBefore(moment())) {
        while (dFecha.isSameOrBefore(dFecFinal)) {
          if (dFecha.format("YYYY") != dYear) break;
          const startDate = dFecha.clone().startOf('month')
          const endDate = dFecha.clone().endOf('month')

          if (dFecInicial.isSameOrBefore(startDate, 'day') && dFecFinal.isSameOrAfter(endDate, 'day')) {
            const dMonth = dFecha.locale("es").format("MMMM")
            const nYear = dFecha.format("YYYY")
            sMeses.push({
              value: dFecha.format("YYYYMM"),
              text: dMonth.charAt(0).toUpperCase() + dMonth.slice(1) + " " + nYear,
              nMonth: dFecha.format("M"),
              nYear,
              startDate: startDate.format("YYYYMMDD"),
              endDate: endDate.format("YYYYMMDD")
            })
          }

          dFecha.add(1, 'month')
        }
      }

      setMeses(sMeses)
      setEmisor(integradores.find(integrador => integrador.nIdEmisor == dataTable.details.nIdIntegrador))
      let nPeriodo = null;
      if (sMeses.length == 1) {
        nPeriodo = sMeses[0].value
      }

      //obtener contactos
      if (sMeses.length > 0) getContactos(nPeriodo)

    } else {
      setContactos([])
      setUsuarios([])
      setEmailEnviados([])
      setMeses([])
      setEmisor({})
      setFileData({})
      setForm(envioEmailForm)
      setIsSent({
        sent: false,
        email: {}
      })
    }
  }, [isOpenEnvioEmail])

  useEffect(() => {
    if (form.nPeriodo) {
      const mes = meses.find(mes => mes.value == form.nPeriodo)
      const correoEnviado = emailEnviados.find(correo => parseInt(correo.dMonth) === parseInt(mes.nMonth) && parseInt(correo.dYear) === parseInt(mes.nYear))
      let sContactos = []
      let sUsuarios = []

      if (correoEnviado) {
        setIsSent({
          sent: true,
          email: correoEnviado
        })
        sContactos = JSON.parse(correoEnviado.sContactos)
        sUsuarios = JSON.parse(correoEnviado.sUsuarios)
      } else {
        setIsSent({
          sent: false,
          email: {}
        })
        sContactos = envioData.sContactos
        sUsuarios = envioData.sUsuarios
        generateFile(mes)
      }

      setForm({
        ...form,
        sDestinatariosNegocio: sContactos,
        sDestinatariosInternos: sUsuarios
      })
      setContactos(sContactos)
      setUsuarios(sUsuarios)
    }
  }, [form.nPeriodo])

  const getContactos = async (nPeriodo = null) => {
    const resp = await dispatch(getContactosReporte({
      nIdIntegrador: dataTable.details.nIdIntegrador,
      dMonth: dataTable.details.dMonth,
      dYear: dataTable.details.dYear,
    }))
    console.log(resp)
    const {
      sContactos = [],
      sUsuarios = [],
      sCorreosEnviados = []
    } = resp
    setEnvioData(resp)
    setContactos(sContactos)
    setUsuarios(sUsuarios)
    setEmailEnviados(sCorreosEnviados)
    setForm({
      ...form,
      nPeriodo: nPeriodo ?? form.nPeriodo,
      sDestinatariosNegocio: sContactos,
      sDestinatariosInternos: sUsuarios
    })
  }

  const generateFile = (mes) => {

    let {
      columns = [],
      rows = []
    } = dataTable;
    let sum = {}

    columns = columns.filter(col => !["sMonth", "nYear"].includes(col.field))

    dataTable.columns.map(column => {
      sum = {
        ...sum,
        [column.field]: 0
      }
    })

    if (mes) {
      const file = {
        head: [columns.map(row => row.headerName)],
        body: [...rows.filter(row => parseInt(row.nMonth) === parseInt(mes.nMonth) && parseInt(row.nYear) === parseInt(mes.nYear))
          .map(row => columns.map(column => {
            if (["price", "number"].includes(column.type)) {
              sum[column.field] += row[column.field]
            }
            return getFormatedValue(row[column.field], column.type)
          })
          ),
        columns.map(column => (["price", "number"].includes(column.type) ? getFormatedValue(sum[column.field], column.type) : ""))]
      }
      console.log(file)
      setFileData(file)
    }
  }

  const getFormatedValue = (value, type) => {
    switch (type) {
      case "price":
        return value?.toLocaleString("en-US", {
          style: "currency",
          currency: "USD",
        });
      case 'percentage':
        return (value + "%")
      default:
        return String(value);
    }
  };

  const handleConfirmDialog = async () => {

    handleCloseConfirmDialog()
    const { bufferFile, filename } = await saveFile(false)

    const respUpload = await uploadDocument(bufferFile, filename)

    if (!respUpload.nError) {
      const mes = meses.find(mes => mes.value == form.nPeriodo)

      const formData = {
        nIdEmisor: emisor.nIdEmisor,
        sNombreComercial: emisor.sNombreComercial,
        mes,
        sRutaArchivo: respUpload.sRuta,
        sDestinatariosNegocio: form.sDestinatariosNegocio,
        sDestinatariosInternos: form.sDestinatariosInternos
      }
      const resp = await dispatch(enviarReporte(formData))
      if (resp.ok) {
        closeModalEnvioEmail()
      }
      setOpenToast(!resp.ok, resp.msg)
    } else {
      setOpenToast(true, respUpload.sMensaje)
    }
  }

  const uploadDocument = async (file, fileName) => {
    return new Promise(async (resolve, reject) => {
      await dispatch(subirArchivoS3(file, `reportes/${emisor.nIdCNBV}/${form.nPeriodo}`, fileName)).then(resp => {
        resolve({
          nError: resp.ok ? 0 : 1,
          sMensaje: resp.msg,
          sRuta: resp.key
        })
      })
    })
  }

  const saveFile = async (download = true) => {
    const mes = meses.find(mes => mes.value == form.nPeriodo)
    const filename = `Comisiones operaciones tercerizadas ${mes.startDate}-${mes.endDate}.pdf`

    if (isSent.sent) {
      const res = await dispatch(signedURL(isSent.email.sRutaArchivo))
      if (res.ok) {
        fetch(res.url)
          .then(res => res.blob())
          .then((blob) => {
            saveAs(blob, filename);
          })
      } else {
        setOpenToast(true, res.msg)
      }
    } else {
      doc = new jsPDF("landscape");
      doc.setFont("helvetica");
      doc.setFontSize(9);
      autoTable(doc, {
        ...fileData,
        margin: { top: 28 },
        styles: { fontSize: 8 },
        didDrawPage: header,
      });

      addFooters(doc)
      if (!download) {
        const bufferFile = doc.output('blob', { filename })
        console.log(bufferFile)
        return { bufferFile, filename }
      }
      doc.save(filename);

    }
    return {}
  }

  var header = function (data) {
    const mes = meses.find(mes => mes.value == form.nPeriodo)
    doc.setFontSize(5);
    doc.addImage(
      "/assets/SofipayLogo.png",
      "JPEG",
      data.settings.margin.left,
      10,
      38,
      10
    );
    doc.setFont("helvetica", "bold");
    doc.setFontSize(11);
    doc.text(
      "Reporte de " + dataTable.title.toLowerCase(),
      doc.internal.pageSize.width - 15,
      10,
      {
        align: "right",
      }
    );
    doc.setFont("helvetica", "normal");
    doc.setFontSize(11);
    doc.text(
      mes ? "Periodo: " + mes.text : "",
      doc.internal.pageSize.width - 15,
      15,
      {
        align: "right",
      }
    );


    if (getFilter()) {
      doc.setFont("helvetica", "normal");
      doc.setFontSize(11);
      doc.text(
        getFilter().name + getFilter().value,
        doc.internal.pageSize.width - 15,
        20,
        {
          align: "right",
        }
      );
    }
    doc.setFontSize(8);
    doc.text(
      "Red Efectiva SA de CV",
      55,
      11,
      {
        align: "left",
      }
    );
    doc.text(
      "Blvd. Antonio L. Rdz. 3058 Suite 201-A",
      55,
      14,
      {
        align: "left",
      }
    );
    doc.text(
      "Colonia Santa Maria",
      55,
      17,
      {
        align: "left",
      }
    );
    doc.text(
      "Monterrey, N.L. C.P. 64650",
      55,
      20,
      {
        align: "left",
      }
    );
  };

  const addFooters = doc => {
    const pageCount = doc.internal.getNumberOfPages()

    doc.setFont('helvetica', 'italic')
    doc.setFontSize(8)
    for (var i = 1; i <= pageCount; i++) {
      doc.setPage(i)
      doc.text('Página ' + String(i) + ' de ' + String(pageCount), doc.internal.pageSize.width - 10, 200, {
        align: 'right'
      })
    }
  }

  const getFilter = () => {
    if (emisor) {
      return ({
        name: 'Entidad financiera: ',
        value: emisor.sNombreComercial
      })
    }

    return false;
  }

  const onChange = (e, name = null, value = null) => {
    const inputName = name ?? e.target.name;
    const inputValue = value ?? e.target.value;
    setForm({
      ...form,
      [inputName]: inputValue,
    });
  }

  const handleClose = () => {
    dispatch(closeEnvioEmail())
  }

  const handleCloseConfirmDialog = () => {
    setShowConfirmDialog(false)
  }

  const handleOpenConfirmDialog = () => {
    setShowConfirmDialog(true)
  }

  return {
    isOpenEnvioEmail,
    handleClose,
    onChange,
    meses,
    form,
    emisor,
    saveFile,
    usuarios,
    contactos,
    isSent,
    showConfirmDialog,
    handleCloseConfirmDialog,
    handleOpenConfirmDialog,
    handleConfirmDialog
  };
};
