import { useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getToBase64 } from '../helpers/base64';
import { getDatosFacturacion, initalStateCadenaDetail } from '../helpers/forms';
import { validateAddressContactEdit, validateCashInEdit, validateCashOutEdit, validateDataBankEdit, validateDataGeneralEdit, validateDocuments, validateFacturaTransferenciaEdit, validateOperationDataEdit } from '../helpers/forms-validate';
import { getDaysOfWeekPayList, getFormatEmailFactura, getFormatEscalonados, periodicidades, validateEmpty as validateLiqEmpty } from '../helpers/getData';
import {
  getEmailsStrFormat,
  getFormatContactosEdit,
  getFormatLiquidacion,
  getLiquidacionJSON,
  liquidacionSwitch,
  parseEscalonadoCadnea,
  parseEscTicketToForm,
  parseJsonLiquidacion,
  parseLiquidacion,
  searchItemById
} from '../helpers/modalConfirmHelper';
import { regexNums } from '../helpers/regexs';
import {
  cleanEscalonado,
  setBancoSearched,
  setContactosList,
  setEmails,
  setIvaOperativoSelected,
  setListEscalonadoForm
} from '../slices/cadena/cadenaSlice';
import { signedURL, startLoadEditarCadena } from '../slices/cadena/cadenaThunk';
import {
  startLoadBancos,
  startLoadCadenas,
  startLoadColonias,
  startLoadDatosFacturacion,
  startLoadDocumentos,
  startLoadGiros,
  startLoadIntegracion,
  startLoadProcesos,
  startLoadRetenciones,
  startLoadTiposLiquidacion
} from '../slices/catalogo/catalgoThunk';
import { loading } from '../slices/ui/uiSlice';
import { useModal } from './useModal';
import { useUploadDocuments } from './useUploadDocuments';

const initialValuesEditSection = {
  datosGenerales: false,
  direccion: false,
  datosBancarios: false,
  datosOperativos: false,
  cashIn: false,
  cashOut: false,
  documentos: false,
  datosFacturacion: false,
  cuentaContableIn: false,
  cuentaContableOut: false,
  estatusCadena: false,
};

const initialValuesConfirmSection = {
  datosGenerales: false,
  direccion: false,
  datosBancarios: false,
  datosOperativos: false,
  cashIn: false,
  cashOut: false,
  documentos: false,
  datosFacturacion: false,
  cuentaContableIn: false,
  cuentaContableOut: false,
};

const initialValuesErroInput = {
  sClabe: false
}

const direccionKeys = ['nIdColonia', 'nIdMunicipio', 'nIdEntidad'];

export const useModalConfirmacion = () => {
  const dispatch = useDispatch();

  const {
    isOpenConfirmation,
    isOpenContact,
    isOpenListContact,

    closeModalConfirmacion,
    setOpenToast,
    openMoldaViewPdf,
    openModalContact,
    openModalListContact,
    disableModalListContactButtons,
  } = useModal();

  const { open, idCadena } = isOpenConfirmation;

  const {
    cadenas: cadenasList,
    documentos,
    bancos = [],
    giros,
    retenciones,
    integraciones,
    ciudades = [],
    estados = [],
    colonias = [],
    tiposLiquidacion = [],
    cfdis,
    formasPago,
    metodosPago,
    regimensFiscal,
    unidades,
    productoServicios,
    procesos
  } = useSelector((state) => state.catalogo);

  const [openEscalonado, setOpenEscalonado] = useState(false);
  const [isOnEditSection, setIsOnEditSection] = useState(initialValuesEditSection);
  const [isConfirmed, setIsConfirmed] = useState(initialValuesConfirmSection);
  const [isDocumentosLoaded, setIsDocumentosLoaded] = useState(false)

  // Subir docuementos
  const { uploadDocument } = useUploadDocuments();

  // modulos que cambiaron
  const [modulesChanged, setModulesChanged] = useState([]);

  const bancoEncontrado = useRef();
  const documentosModify = useRef();

  // Formularios
  const [cadena, setCadena] = useState(initalStateCadenaDetail);
  const [cadenaBackup, setCadenaBackup] = useState(initalStateCadenaDetail);
  const [errorInputs, setErrorInputs] = useState(initialValuesErroInput);

  const {
    direccionSearched,
    bancoSearched,
    escalonadoForms,
    // escCantidadForms,
    emailsFactura,
    // facturacionForm,
    contactos: contactosStore
  } = useSelector((state) => state.cadena);


  const { isLoading } = useSelector((state) => state.ui);

  const searchCadena = async () => {
    dispatch(loading(true));
    let innerDocumentos = documentos

    if (!isDocumentosLoaded) {
      innerDocumentos = await dispatch(startLoadDocumentos(0, 0, false, -1));
    }


    const facturacionData = await onLoadDatosFact();
    console.log(facturacionData)

    const searchedCadena = cadenasList.rows.find(
      (val) => val.nIdCadena.toString() === idCadena?.toString()
    );
    const documentosTemp = searchedCadena.oDocumentos
      ? JSON.parse(searchedCadena.oDocumentos)
      : [];

    const contactosTemp = searchedCadena.oContactos
      ? JSON.parse(searchedCadena.oContactos)
      : [];

    const liquidacionInTemp = searchedCadena.oLiquidacionCashIn
      ? JSON.parse(searchedCadena.oLiquidacionCashIn)
      : [];

    const oCuentasContables = searchedCadena.oCuentasContables
      ? JSON.parse(searchedCadena.oCuentasContables)
      : []

    const liquidacionOutTemp = searchedCadena.oLiquidacionCashOut
      ? JSON.parse(searchedCadena.oLiquidacionCashOut)
      : [];

    const tempEscalonados = searchedCadena.oEscalonadas
      ? JSON.parse(searchedCadena.oEscalonadas).sort((a, b) => a.nMontoMin - b.nMontoMin)
      : [];

    const oDocumentos = documentosTemp.map((doc) => {
      const documentType = searchItemById(doc.id, innerDocumentos);
      console.log(documentType, innerDocumentos);
      return {
        ...doc,
        sNombreDocumento: documentType ? documentType.text : '',
        sAbrevDocumento: documentType ? documentType.sAbrevDoc : ''
      };
    });

    const oContactos = contactosTemp.map((contacto, i) => {
      return {
        ...contacto,
        nombre: contacto.sNombre,
        apellido: contacto.sPaterno,
        segundoApellido: contacto.sMaterno,
        telefono: contacto.sTelefono,
        email: contacto.sMail,
        proceso: JSON.parse(contacto.sProcesos),
        area: contacto.sArea,
        puesto: contacto.sPuesto,
        comentarios: contacto.sComentario,
        id: i
      };
    });

    dispatch(setContactosList(oContactos));

    const oLiquidacionCashIn = parseLiquidacion(liquidacionInTemp);
    const oLiquidacionCashOut = parseLiquidacion(liquidacionOutTemp);

    const liquidacionCashIn = getLiquidacionJSON(oLiquidacionCashIn, tiposLiquidacion);
    const liquidacionCashOut = getLiquidacionJSON(oLiquidacionCashOut, tiposLiquidacion);

    const oEscalonadas = parseEscTicketToForm(tempEscalonados);

    dispatch(setIvaOperativoSelected(searchedCadena.nIVA));

    oEscalonadas.length > 0 && dispatch(setListEscalonadoForm(oEscalonadas));

    const emailsStrFactura = getEmailsStrFormat(searchedCadena.sCorreoDestino);

    dispatch(setEmails({
      cadena: emailsStrFactura,
    }));

    const fact = getDataFacturacion({
      idCdfi: searchedCadena.sUsoCFDI,
      idFormaPago: searchedCadena.sFormaPago,
      idMetodoPago: searchedCadena.sMetodoPago,
      idRegimen: searchedCadena.sRegimenFiscal,
      idClaveProd: searchedCadena.sClaveProductoServicio,
      idUnidad: searchedCadena.sUnidad,
      idPeriodicidad: searchedCadena.nPeriodoFacturacion
    });

    const cadenaTemp = {
      ...initalStateCadenaDetail,
      ...searchedCadena,
      ...fact,
      oDocumentos,
      oContactos,
      oLiquidacionCashIn,
      oLiquidacionCashOut,
      oEscalonadas,
      liquidacionCashIn,
      liquidacionCashOut,
      oCuentasContables,
      documentos: {},
      oDocsUpdated: [],
      isActiveCashIn: oLiquidacionCashIn.length > 0 ? false : true,
      isActiveCashOut: oLiquidacionCashOut.length > 0 ? false : true,
      isActiveMGracia: searchedCadena.dPeriodoGracia !== '' && searchedCadena.dPeriodoGracia !== '0000-00-00',
      isActiveEscalonado: oEscalonadas.length > 0
    };

    console.log(cadenaTemp)
    setCadena(cadenaTemp);
    setCadenaBackup(cadenaTemp);

    dispatch(loading(false));
  };

  const getDataFacturacion = ({
    idCdfi,
    idFormaPago,
    idMetodoPago,
    idRegimen,
    idClaveProd,
    idUnidad,
    idPeriodicidad
  }) => {
    const datosFacturacion = getDatosFacturacion();

    const cdfi = searchItemById(idCdfi, datosFacturacion.cdfis);
    const formaPago = searchItemById(idFormaPago, datosFacturacion.formasPago);
    const metodoPago = searchItemById(idMetodoPago, datosFacturacion.metodosPago);
    const regimenFiscal = searchItemById(idRegimen, datosFacturacion.regimensFiscal);
    const productoServ = searchItemById(idClaveProd, datosFacturacion.productoServicios);
    const unidad = searchItemById(idUnidad, datosFacturacion.unidades);
    const periodicidad = searchItemById(idPeriodicidad, periodicidades);

    return {
      sNombreRegimenFiscal: regimenFiscal ? regimenFiscal.text : '',
      sNombreUsoCFDI: cdfi ? cdfi.text : '',
      sNombreFormaPago: formaPago ? formaPago.text : '',
      sNombreMetodoPago: metodoPago ? metodoPago.text : '',
      sNombreClaveProductoServicio: productoServ ? productoServ.text : '',
      sNombreUnidad: unidad ? unidad.value + '-' + unidad.strNombre : '',
      sNombrePeriodoFacturacion: periodicidad ? periodicidad.text : ''
    };
  };

  // * --- --- --- --- --- ---- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --
  // * --- --- --- --- --- ----
  // * --- ACTIVA EL EDITAR ---
  // * --- --- --- --- --- ----

  const editSection = async ({ section, saveSection = false }) => {
    console.log(section, saveSection)
    if (section === 'datosGenerales' && saveSection) {
      const { error, ok } = validateDataGeneralEdit(cadena);
      if (!ok) {
        setOpenToast(true, error)
        return;
      }

    } else if (section === 'direccion' && saveSection) {

      const { error, ok } = validateAddressContactEdit({ ...cadena, contactos: contactosStore }, procesos);
      console.log(procesos)
      if (!ok) {
        setOpenToast(true, error)
        return;
      }

    } else if (section === 'datosBancarios' && saveSection) {
      const { error, ok } = validateDataBankEdit(cadena);
      if (!ok) {
        setOpenToast(true, error)
        return;
      }

    } else if (section === 'datosOperativos' && saveSection) {
      const { ok, error } = validateOperationDataEdit({
        ...cadena,
        escalonadoForms: cadena.isActiveEscalonado ? escalonadoForms : []
      });
      if (!ok) {
        setOpenToast(true, error)
        return;
      }
      setCadena({
        ...cadena,
        oEscalonadas: cadena.isActiveEscalonado
          ? parseEscalonadoCadnea(escalonadoForms)
          : []
      });

    } else if (section === 'cashIn' && saveSection) {

      const { ok, error } = validateCashInEdit(cadena);
      if (!ok) {
        setOpenToast(true, error);
        return
      }

      const isEmpty = cadena.isActiveCashIn || validateLiqEmpty(cadena.liquidacionCashIn);
      let liquidaciones = [];

      if (!isEmpty) {

        if (cadena.oLiquidacionCashIn.length === 0) {
          const daysLiq = getDaysOfWeekPayList(cadena.liquidacionCashIn);
          const liqsTemp = parseLiquidacion(daysLiq);
          liquidaciones = parseJsonLiquidacion(liqsTemp, cadena.liquidacionCashIn);

        } else {
          liquidaciones = parseJsonLiquidacion(
            cadena.oLiquidacionCashIn,
            cadena.liquidacionCashIn
          );

        }
      }

      setCadena({
        ...cadena,
        oLiquidacionCashIn: liquidaciones
      });

    } else if (section === 'cashOut' && saveSection) {

      const { ok, error } = validateCashOutEdit(cadena);
      if (!ok) {
        setOpenToast(true, error);
        return
      }

      const isEmpty = cadena.isActiveCashOut || validateLiqEmpty(cadena.liquidacionCashOut);
      let liquidaciones = [];

      if (!isEmpty) {

        if (cadena.oLiquidacionCashOut.length === 0) {
          const daysLiq = getDaysOfWeekPayList(cadena.liquidacionCashOut);
          const liqsTemp = parseLiquidacion(daysLiq)
          liquidaciones = parseJsonLiquidacion(
            liqsTemp,
            cadena.liquidacionCashOut
          );

        } else {
          liquidaciones = parseJsonLiquidacion(
            cadena.oLiquidacionCashOut,
            cadena.liquidacionCashOut
          );
        }
      }

      setCadena({
        ...cadena,
        oLiquidacionCashOut: liquidaciones
      });


    } else if (section === 'documentos' && saveSection) {

      if (cadena.oDocumentos.length === 0) {
        const { ok, error } = validateDocuments(cadena, documentos);
        if (!ok) {
          setOpenToast(true, error);
          setIsOnEditSection({ ...isOnEditSection, [section]: !saveSection });
          return;
        }
      }

      const respUpload = await uploadDocument(cadena.documentos, cadena.sRFC, documentos);

      if (respUpload.ok && Object.keys(cadena.documentos).length > 0) {


        const newOdocuments = cadena.oDocumentos.map((doc) => {
          const docSearch = respUpload.documents.find(
            (docRes) => docRes.id.toString() === doc.id.toString()
          );
          return {
            ...doc,
            key: docSearch ? docSearch.key : doc.key,
            name: docSearch ? docSearch.name : doc.name,
          }
        });
        const temporal = {
          ...cadena,
          oDocumentos: newOdocuments,
          oDocsUpdated: respUpload.documents, // Enviar este al backend 
          documentos: {}
        }
        setCadena(temporal);
        documentosModify.current = respUpload.documents;
        console.log(respUpload)
      };

    } else if (section === 'datosFacturacion' && saveSection) {
      const { ok, error } = validateFacturaTransferenciaEdit({
        ...cadena,
        emails: emailsFactura['cadena']
      });

      if (!ok) {
        setOpenToast(true, error);
        return
      }

      const fact = getDataFacturacion({
        idCdfi: cadena.sUsoCFDI,
        idFormaPago: cadena.sFormaPago,
        idMetodoPago: cadena.sMetodoPago,
        idRegimen: cadena.sRegimenFiscal,
        idClaveProd: cadena.sClaveProductoServicio,
        idUnidad: cadena.sUnidad,
        idPeriodicidad: cadena.nPeriodoFacturacion
      });
      setCadena({ ...cadena, ...fact });
    }

    if (!saveSection) {
      await loadInitialValues(section)
    }
    setIsOnEditSection({ ...isOnEditSection, [section]: !saveSection });
    addNewModuleChanged(section);

    if (saveSection) {
      await onSaveEdit(section);
    }
  };

  const loadInitialValues = async (section) => {
    switch (section) {
      case 'direccion':
        if (cadena.nCodigoPostal !== '') await dispatch(startLoadColonias(`${cadena.nCodigoPostal}`, cadena.nIdColonia));
        await dispatch(startLoadProcesos(0,1));
        break;
      case 'datosGenerales':
        await onLoadGiros();
        break;
      case 'datosFacturacion':
        await onLoadDatosFact();
        break;
      case 'datosBancarios':
        await loadBancos();
        break;
      case 'datosOperativos':
        await onLoadDatosOperativos();
        break;
      case 'cashIn':
      case 'cashOut':
        await onLoadTiposLiquidacion();
        break;
      default:
        break;
    }
  }

  // * --- --- --- --- --- ----
  // * --- ON SUBMIT       ----
  // * --- --- --- --- --- ----

  const onSaveEdit = async (section) => {
    let formResult = {
      nIdCadena: cadena.nIdCadena
    };
    const innerModules = [...modulesChanged, section]
    innerModules.forEach((module) => {
      if (section === module && module === 'datosGenerales') {
        // **** DATOS GENERALES ****
        formResult = {
          ...formResult,
          datosGenerales: {
            nIdGiro: cadena.nIdGiro,
            sNombreComercial: cadena.sNombreComercial,
            sRazonSocial: cadena.sRazonSocial,
            sRegimenSocietario: cadena.sRegimenSocietario,
            sRFC: cadena.sRFC,
            sActividadEconomica: cadena.sActividadEconomica,
            sMail: cadena.sMail,
            sTelefono: cadena.sTelefono
          }
        };
      } else if (section === module && module === 'direccion') {
        // **** DIRECCION Y CONTACTOS ****
        formResult = {
          ...formResult,
          direccion: {
            nIdDireccion: cadena.nIdDireccion,
            sCalle: cadena.sCalle,
            sNumInterno: cadena.sNumInterno,
            nNumExterno: cadena.nNumExterno,
            nCodigoPostal: cadena.nCodigoPostal,
            nIdColonia: cadena.nIdColonia,
            nIdTipoDireccion: cadena.nIdTipoDireccion,
            contactos: getFormatContactosEdit(contactosStore),
          }
        }

      } else if (section === module && module === 'datosBancarios') {
        // **** BANCARIOS ****
        formResult = {
          ...formResult,
          datosBancarios: {
            nIdCuenta: cadena.nIdCuentaBanco,
            nIdBanco: cadena.nIdBanco,
            sBeneficiario: cadena.sBeneficiario,
            sClabe: cadena.sClabe,
            sRefNumerica: cadena.sRefNumerica,
            sRefAlfaNumerica: cadena.sRefAlfanumerica, // obligatorio
          }
        }

      } else if (section === module && module === 'datosOperativos') {
        // **** OPERATIVOS ****
        formResult = {
          ...formResult,
          datosOperativos: {
            nIdConfiguracion: cadena.nIdConfiguracion,
            nIdRetencion: cadena.nIdRetencion,
            nIdIntegracion: cadena.nIdIntegracion,
            nIVAOperacion: cadena.nIdIVA,
            nCostoTransferencia: cadena.nCostoTransferencia,
            dPeriodoGracia: cadena.isActiveMGracia ? cadena.dPeriodoGracia : null,
            dInicioOperaciones: cadena.dInicioOperaciones,
            nMontoMaximo: cadena.nMontoMaximo,
            escalonadoTicket: getFormatEscalonados(
              cadena.isActiveEscalonado ? escalonadoForms : []
            )
          }
        }

      } else if (section === module && module === 'cashIn') {
        // **** LIQUIDACION CASH IN ****

        formResult = {
          ...formResult,
          liquidacionCashIn: {
            nIdTipo: parseInt(cadena.liquidacionCashIn.tipo),
            nDia: cadena.liquidacionCashIn?.tipoSelected?.nPorDia === 1
              ? parseInt(cadena.liquidacionCashIn.pagoMes)
              : 0,
            semana: cadena.liquidacionCashIn?.tipoSelected?.nPorDia === 0
              ? getFormatLiquidacion(
                getDaysOfWeekPayList(cadena.liquidacionCashIn),
                cadena.oLiquidacionCashIn
              ) // Enviar el id de la semana
              : [],
          }

        }

      } else if (section === module && module === 'cashOut') {
        // **** LIQUIDACION CASH OUT ****

        formResult = {
          ...formResult,
          liquidacionCashOut: {
            nIdTipo: parseInt(cadena.liquidacionCashOut.tipo),
            nDia: cadena.liquidacionCashOut?.tipoSelected?.nPorDia === 1
              ? parseInt(cadena.liquidacionCashOut.pagoMes)
              : 0,
            semana: cadena.liquidacionCashOut?.tipoSelected.nPorDia === 0
              ? getFormatLiquidacion(
                getDaysOfWeekPayList(cadena.liquidacionCashOut),
                cadena.oLiquidacionCashOut
              )
              : [],
          }

        }

      } else if (section === module && module === 'documentos') {
        // **** DOCUMENTACION ****
        formResult = {
          ...formResult,
          documentos: documentosModify.current
        }

      } else if (section === module && module === 'datosFacturacion') {
        // **** FACTURACION ****
        formResult = {
          ...formResult,
          datosFacturacion: {
            nIVA: cadena.nIdIVA,
            sRegimenFiscal: cadena.sRegimenFiscal,
            facturacion: [{
              nIdFacturaCadena: cadena.nIdFacturaCadena,
              nTipoFactura: '2',
              sUsoCFDI: cadena.sUsoCFDI,
              sClaveProducto: cadena.sClaveProductoServicio,
              sClaveUnidad: cadena.sUnidad,
              nPeriodoFactura: cadena.nPeriodoFacturacion,
              sFormaPago: cadena.sFormaPago,
              sMetodoPago: cadena.sMetodoPago,
              sDiasLiquidacion: cadena.nDiaPago,
              correos: getFormatEmailFactura(emailsFactura['cadena'])
            }]
          }
        }
      } else if (section === module && module === 'estatusCadena') {
        // **** ESTATUS CADENA ****
        formResult = {
          ...formResult,
          nIdEstatusCadena: getEstatusCadena(cadena.nIdEstatusCadena)
        }
      }
    });

    deleteModuleChanged(section);
    let sectionTemp = section;

    if (section === 'cashIn') {
      sectionTemp = 'liquidacionCashIn';

    } else if (section === 'cashOut') {
      sectionTemp = 'liquidacionCashOut';

    }
    const resp = await dispatch(startLoadEditarCadena(formResult, sectionTemp));
    setOpenToast(!resp.ok, resp.msg);

    documentosModify.current = null;
    if (resp.ok) {
      if (section === 'estatusCadena') {
        setCadenaBackup({
          ...cadena,
          nIdEstatusCadena: getEstatusCadena(cadena.nIdEstatusCadena)
        });
        setCadena({
          ...cadena,
          nIdEstatusCadena: getEstatusCadena(cadena.nIdEstatusCadena)
        })
        closeModal()
      } else {
        const oDocumentos = formResult.documentos ?
          cadena.oDocumentos.map(document => {
            const doc = formResult.documentos.find(d => parseInt(d.id) === parseInt(document.id))
            return {
              ...document,
              ...(doc ? doc : {})
            }
          })
          : cadena.oDocumentos;

        const oLiquidacionCashIn = parseJsonLiquidacion(
          cadena.oLiquidacionCashIn,
          cadena.liquidacionCashIn
        );
        let oLiquidacionCashOut = []
        if (cadena.oLiquidacionCashOut.length === 0) {
          const daysLiq = getDaysOfWeekPayList(cadena.liquidacionCashOut);
          const liqsTemp = parseLiquidacion(daysLiq)
          oLiquidacionCashOut = parseJsonLiquidacion(
            liqsTemp,
            cadena.liquidacionCashOut
          );

        } else {
          oLiquidacionCashOut = parseJsonLiquidacion(
            cadena.oLiquidacionCashOut,
            cadena.liquidacionCashOut
          );
        }
        setCadenaBackup({
          ...cadena,
          oLiquidacionCashIn,
          oLiquidacionCashOut,
          oContactos: contactosStore,
          oDocumentos,
          documentos: {}
        });
        setCadena({
          ...cadena,
          oLiquidacionCashIn,
          oLiquidacionCashOut,
          oContactos: contactosStore,
          oDocumentos,
          documentos: {}
        })
      }

      await dispatch(startLoadCadenas());
    } else {
      setCadena(cadenaBackup)
    }


  }

  const getEstatusCadena = (id) => {
    let estatusCadena = id;
    switch (id) {
      case 1:
        estatusCadena = 0
        break;
      case 0:
      case 3:
        estatusCadena = 1
        break;
      case 2:
        estatusCadena = 3
        break;
    }
    return estatusCadena;
  }

  const addNewModuleChanged = (section) => {
    const sectionSearched = modulesChanged.find((sec) => sec === section);
    if (sectionSearched) return;
    setModulesChanged([...modulesChanged, section]);
  };

  const deleteModuleChanged = (section) => {
    setModulesChanged(modulesChanged.filter((sec) => section !== sec));
  };

  const onChange = async (e) => {
    const inputName = e.target.name;
    const inputValue = e.target.value;

    if (inputName === 'nIdGiro') {

      const giroSearched = giros.find((giro) => giro.value.toString() === inputValue);

      setCadena({
        ...cadena,
        [inputName]: inputValue,
        sGiro: giroSearched ? giroSearched.text : cadena.sGiro,
      });


    } else if (direccionKeys.includes(inputName)) {

      let colonia;
      let ciudad;
      let estado;

      if (inputName === 'nIdColonia') {
        const colSearcehd = colonias.find(
          (col) => col.value === `${inputValue}`
        );
        colonia = colSearcehd ? colSearcehd.text : undefined

      } else if (inputName === 'nIdMunicipio') {
        const ciudaSearched = ciudades.find((city) => city.value === `${inputValue}`);
        ciudad = ciudaSearched ? ciudaSearched.text : undefined

      } else if (inputName === 'nIdEntidad') {
        const estadoSearched = estados.find((est) => est.value === `${inputValue}`);
        estado = estadoSearched ? estadoSearched.text : undefined
      }

      setCadena({
        ...cadena,
        [inputName]: inputValue,
        sNombreColonia: colonia ?? cadena.sNombreColonia,
        sNombreEntidad: estado ?? cadena.sNombreEntidad,
        sNombreMunicipio: ciudad ?? cadena.sNombreMunicipio,
      });


    } else if (inputName === 'sClabe') {

      if (inputValue.length > 18) return;
      if (inputValue !== '' && !regexNums.test(inputValue)) return;
      const clabe = inputValue.length >= 3 ? inputValue.substring(0, 3) : ''
      if (clabe !== '') {
        setBankSearch(clabe);
      }

      setCadena({ ...cadena, [inputName]: inputValue });

      setErrorInputs({
        ...errorInputs,
        sClabe: inputValue.length !== 18 ? true : false
      });

    } else if (inputName === 'isActiveEscalonado') {
      setCadena({
        ...cadena,
        [inputName]: e.target.checked
      })
      if (!e.target.checked) dispatch(cleanEscalonado());

    } else if (liquidacionSwitch.includes(inputName)) {

      if (
        cadena.isActiveCashOut &&
        inputName === 'isActiveCashIn' &&
        e.target.checked
      ) {
        setOpenToast(true, 'Se requiere alguna programación de liquidación');
        return;

      }
      if (
        cadena.isActiveCashIn &&
        inputName === 'isActiveCashOut' &&
        e.target.checked
      ) {
        setOpenToast(true, 'Se requiere alguna programación de liquidación');
        return;

      }

      setCadena({ ...cadena, [inputName]: e.target.checked });

    } else if (!!documentos.find((doc) => doc.value === inputName)) {

      if (e.target.files && e.target.files[0].type !== 'application/pdf') return;
      setCadena({
        ...cadena,
        documentos: {
          ...cadena.documentos,
          [inputName]: e.target.files ? e.target.files[0] : ''
        }
      });

    } else {
      setCadena({ ...cadena, [inputName]: inputValue });

    }

  };

  const onChangeInput = async (value) => {

    if ('nCodigoPostal' in value) {
      const inputValue = value.nCodigoPostal;
      if (inputValue !== '' && !regexNums.test(inputValue)) return;
      setCadena({ ...cadena, ...value });

      if (inputValue !== '' && inputValue.length === 5) {
        await dispatch(startLoadColonias(inputValue));
      }

    } else {

      setCadena({ ...cadena, ...value });
    }
  };

  const setDireccionSearched = () => {
    console.log('setDireccionSearched')
    const colSearcehd = colonias.find(
      (col) => col.value === `${direccionSearched.colonia}`
    );
    setCadena({
      ...cadena,
      nIdColonia: direccionSearched.colonia,
      nIdEntidad: direccionSearched.estado,
      sNombreEntidad: direccionSearched.estado,
      nIdMunicipio: direccionSearched.ciudad,
      sNombreMunicipio: direccionSearched.ciudad,
      sNombreColonia: colSearcehd ? colSearcehd.text : ''
    });

  }

  const closeModal = () => {
    setIsOnEditSection(initialValuesEditSection);
    setIsConfirmed(initialValuesConfirmSection);
    closeModalConfirmacion();
  };

  const onSubmitCancelEdit = async () => {
    setModulesChanged([]);
    setIsOnEditSection(initialValuesEditSection);
    setIsConfirmed(initialValuesConfirmSection);
    setCadena(cadenaBackup);
  };

  const onBlur = (e) => {
    const inputName = e.target.name;
    const inputValue = e.target.value;

    if (inputName === 'sClabe') {

      if (inputValue.length > 18) return;
      const clabe = inputValue.length >= 3 ? inputValue.substring(0, 3) : ''
      if (clabe !== '') {
        setBankSearch(clabe)
      }

      setErrorInputs({
        ...errorInputs,
        sClabe: inputValue.length !== 18 ? true : false
      });

    }
  }

  const setBankSearch = (idBanco) => {
    const bancoSearch = bancos.find(
      (banco) => banco.nIdBanco === parseInt(idBanco.toString())
    );

    dispatch(setBancoSearched(
      bancos.length > 0 && bancoSearch ? bancoSearch.value : '',
    ));

    if (!!!bancoSearch) {
      setOpenToast(true, 'No se encontro banco');
      return;
    }
    bancoEncontrado.current = bancoSearch ? bancoSearch.text : '';
    setBanco();


  }

  const setBanco = () => {
    setCadena({
      ...cadena,
      nIdBanco: bancoSearched,
      sNombreBanco: bancoEncontrado.current
    })
  }


  const clikcOpenViewPdf = async (objectValue) => {
    console.log(objectValue);
    if (objectValue !== '' && objectValue instanceof Blob) {
      const data = await getToBase64(objectValue);
      openMoldaViewPdf(data);
    } else {
      const { ok, msj, url } = await dispatch(signedURL(objectValue));
      let name = objectValue.split('/')
      name = name[name.length - 1]
      if (ok) {
        fetch(url)
          .then(res => res.blob())
          .then(async (blob) => {
            await getToBase64(blob).then((resp) => {
              openMoldaViewPdf(resp, name);
            })
          })
      } else {
        setOpenToast(!ok, msj);
      }
    }
  };

  const onLoadDatosFact = async () => {
    if (!cfdis.length || !formasPago.length || !metodosPago.length || !regimensFiscal.length || !productoServicios.length || !unidades.length) await dispatch(startLoadDatosFacturacion({}));
  };

  const onLoadTiposLiquidacion = async () => {
    if (tiposLiquidacion.length === 0) await dispatch(startLoadTiposLiquidacion());
  };

  const onLoadDatosOperativos = async () => {
    if (retenciones.length === 0) await dispatch(startLoadRetenciones());
    if (integraciones.length === 0) await dispatch(startLoadIntegracion());
  };

  const onLoadGiros = async () => {
    giros.length === 0 && (await dispatch(startLoadGiros()));
  };

  const loadBancos = async (idBanco = '000') => {
    if (bancos.length > 0) return;
    await dispatch(startLoadBancos(idBanco));
  }

  const clickAddContact = async () => {
    openModalContact({});
  };

  const clickOpenListContact = async () => {
    openModalListContact();
    if (!isOnEditSection.direccion) {
      disableModalListContactButtons()
    }
  };

  const isConfirmedAllSections = () => {
    const keys = Object.keys(isConfirmed)
    console.log('las keys son', keys)
    console.log(cadena.isActiveCashIn);
    console.log(cadena.isActiveCashOut);
    console.log(isConfirmed, 'es confirmado');
    return keys.every(key => {
      if (cadena.isActiveCashIn && ["cashIn", "cuentaContableIn"].includes(key)) return true
      if (cadena.isActiveCashOut && ["cashOut", "cuentaContableOut"].includes(key)) return true
      return (isConfirmed[key])
    })
  }

  const handleConfirmSection = (section) => {
    setIsConfirmed({
      ...isConfirmed,
      [section]: !isConfirmed[section]
    })
  }

  return {
    bancos,
    bancoSearched,
    cadena,
    cfdis,
    ciudades,
    colonias,
    direccionSearched,
    documentos,
    errorInputs,
    estados,
    formasPago,
    giros,
    initialValuesEditSection,
    initialValuesErroInput,
    integraciones,
    isLoading,
    isOnEditSection,
    metodosPago,
    modulesChanged,
    open,
    openEscalonado,
    regimensFiscal,
    retenciones,
    unidades,
    productoServicios,
    isOpenListContact,
    isOpenContact,
    contactosStore,

    clikcOpenViewPdf,
    closeModal,
    dispatch,
    editSection,
    loadBancos,
    onBlur,
    onChange,
    onChangeInput,
    onLoadDatosFact,
    onLoadDatosOperativos,
    onLoadGiros,
    onLoadTiposLiquidacion,
    onSubmitCancelEdit,
    searchCadena,
    setBanco,
    setCadena,
    setDireccionSearched,
    setErrorInputs,
    setIsOnEditSection,
    setModulesChanged,
    setOpenEscalonado,
    startLoadDocumentos,
    clickAddContact,
    clickOpenListContact,
    isConfirmedAllSections,
    handleConfirmSection,
    isConfirmed,
    cadenaBackup
  };
};
