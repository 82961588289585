import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  dataTable:  {
    columns: [],
    rows   : []
  },
  selectedRows: [],
  facturasCadenas: [],
  ordenesPagoCadenas: [],
  facturasBadge: 0,
};

export const facturacionSlice = createSlice({
  name: 'facturacion',
  initialState,
  reducers: {
    setDataTable: (state, action) => {
      state.dataTable = {...action.payload};
    },
    setSelectedRows: (state, action) => {
      state.selectedRows = [...action.payload];
    },
    addSelectedRow: (state, action) => {
      state.selectedRows = [...state.selectedRows, action.payload];
    },
    deleteSelectedRow: (state, action) => {
      state.selectedRows = state.selectedRows.filter(row => row.nIdOrdenPago !== action.payload.nIdOrdenPago);
    },
    setFacturasCadenas: (state, action) => {
      state.facturasCadenas = [...action.payload];
    },
    setOrdenesPagoCadenas: (state, action) => {
      state.ordenesPagoCadenas = [...action.payload];
    },
    setFacturasBadge: (state, action) => {
      state.facturasBadge = action.payload;
    },
  }
});

export const {
  setDataTable,
  setSelectedRows,
  addSelectedRow,
  deleteSelectedRow,
  setFacturasCadenas,
  setOrdenesPagoCadenas,
  setFacturasBadge,
} = facturacionSlice.actions;
