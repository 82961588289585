import { useEffect, useState } from "react";
import { useMenu } from "../hooks/useMenu";
import { useAuthPage } from "../hooks/useAuthPage";
import {
  createTheme,
  Grid,
  IconButton,
  TextField,
  ThemeProvider,
  Tooltip,
} from "@mui/material";
import {
  Visibility,
  Delete,
  Help,
  Upload,
  Publish,
  CloudUpload
} from "@mui/icons-material";
import { useModal } from "../hooks/useModal";

import EnhancedTable from "../components/table/EnhancedTable";
import { useUsuariosTable } from "../hooks/useUsuariosTable";
import { ModalUsuario } from "../components/modal/ModalUsuario";
import { ConfirmDialog } from "../components/modal/ConfirmDialog";
import { usePerfilesUsuariosTable } from "../hooks/usePerfilesUsuariosTable";
import { ModalPerfilUsuario } from "../components/modal/ModalPerfilUsuario";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import moment from "moment";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { useMonitoreoERP } from "../hooks/useMonitoreoERP";
import ModalMonitoreoERP from "../components/modal/ModalMonitoreoERP";
import { ModalBitacoraInterfazERP } from "../components/modal/ModalBitacoraInterfazERP";

const iconTheme = createTheme({
  status: {
    danger: "#e53e3e",
  },
  palette: {
    primary: {
      main: "#00609C",
      dark: "#053e85",
    },
    neutral: {
      main: "#00CD68",
      light: "#053e85",
      dark: "#053e85",
      contrastText: "#fff",
    },
  },
});


export const MonitorERPPage = () => {
  const { setNameSection } = useMenu();
  const { setAllowComponent } = useAuthPage();
  const { isTableLoading } = useModal();

  const colorsTab = ["#053e85", "#00CD68", "#E7B10A", "#e53e3e"]
  const {
    monitoreoERP,
    loadInInit,
    showModal,
    handleCloseModal,
    openModal,
    handleFecha,
    fecha,
    handleChangeTab,
    runInterfazERP,

    showModalBitacora,
    handleOpenModalBitacora,
    handleCloseModalBitacora
  } = useMonitoreoERP();


  useEffect(() => {
    setNameSection("monitorERP");
  }, [setNameSection]);

  useEffect(() => {
    loadInInit();
  }, []);

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={"es-mx"}>
      <ThemeProvider theme={iconTheme}>
        <ModalMonitoreoERP
          isOpen={showModal.open}
          data={showModal.data}
          selectedTab={showModal.tab}
          handleChangeTab={handleChangeTab}
          runInterfazERP={runInterfazERP}
          fecha={fecha}
          closeModal={handleCloseModal}
          handleOpenModalBitacora={handleOpenModalBitacora}
        />
        <ModalBitacoraInterfazERP
          isOpen={showModalBitacora.open}
          onClose={handleCloseModalBitacora}
          data={showModalBitacora.data}
        />
        <EnhancedTable
          table={{
            rows: monitoreoERP,
            columns: [
              { field: 'sTipoInterfaz', headerName: 'Tipo de interfaz', flex: 4 },
              {
                field: 'sEstatusInterfaz', headerName: 'Estatus', type: 'render', flex: 3, disableTooltip: true, renderFunction: (row) => (

                    <Grid style={{ whiteSpace: "break-spaces" }}>
                      <p style={{ display: "inline-block", color: colorsTab[row.nIdEstatusInterfaz], background: `${colorsTab[row.nIdEstatusInterfaz]}15`, padding: "4px 8px", borderRadius: "4px", fontWeight: 500, margin: 0, fontSize: 13 }} >{row.sEstatusInterfaz}</p>
                      <Tooltip title={row.sDescripcionEstatusInterfaz} placement="right" arrow>
                        <Help fontSize="14" sx={{color: colorsTab[row.nIdEstatusInterfaz]}} />
                      </Tooltip>
                    </Grid>
                )
              },
              { field: 'sComentario', headerName: 'Comentario', flex: 7 },
              { field: 'nRegistrosDisponibles', headerName: 'Registros disponibles', flex: 1 },
              { field: 'nRegistrosInterfazados', headerName: 'Registros interfazados', flex: 1 },
              { field: 'dHoraInterfaz', headerName: 'Última interfaz al ERP', flex: 2 },
            ]
          }}
          buttons={[
            {
              label: "Ver",
              icon: <Visibility fontSize={"small"} />,
              onClick: (id, row) => openModal(row),
              showButton: true,
            },
            {
              label: "Interfazar registros",
              icon: <CloudUpload fontSize={"small"} />,
              onClick: (id, row) => runInterfazERP(row.nIdTipoInterfaz),
              showButton: setAllowComponent("interfazarRegistros") && moment().isSame(moment(fecha), "date"),
              //disabled: (id, row) => (row.nIdEstatusInterfaz === 1)
            },
          ]}
          extraButtons={<>
            <DesktopDatePicker
              label="Fecha"
              inputFormat="YYYY-MM-DD"
              renderInput={(params) => <TextField {...params} />}
              value={fecha}
              onChange={(e) =>
                handleFecha(moment(e["$d"]).format("YYYY-MM-DD"))
              }
              disableFuture
            />
          </>}
          loading={isTableLoading}
          rowId={"nIdTipoInterfaz"}
        />
      </ThemeProvider>
    </LocalizationProvider>
  );
};
