import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormHelperText from '@mui/material/FormHelperText';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

import PropTypes from 'prop-types';
import { Grid } from '@mui/material';

export const SelectInput = ({
  variant,
  sxFormControl,
  fullWidth,
  size,
  label,
  id,
  name,
  onChange,
  sxSelect,
  value,
  data,
  error,
  errorMgs,
  disabled,
  text = null,
  idOption = null,
  extraData = null,
  autoFocus = false,
  disableNone = false
}) => {

  return (
    <FormControl
      variant={variant} // filled, standard
      sx={sxFormControl} // {}
      fullWidth={fullWidth} // boolean true false
      size={size} // medium, 'small'
      disabled={disabled}
      error={error}
    >
      <InputLabel id={`lable-${id}`}>{label}</InputLabel>
      <Select
        labelId={`lable-${id}`}
        id={id}
        name={name}
        value={value}
        label={label}
        onChange={onChange}
        sx={sxSelect}
        autoFocus={autoFocus}
        fullWidth={fullWidth}
        size={size}
      >
        {!disableNone ?

          <MenuItem value="" disabled sx={{ whiteSpace: 'normal' }}>
            <em>Ninguno</em>
          </MenuItem>
          : <></>}
        {data.map((v, i) => (
          <MenuItem key={i} value={idOption ? v[idOption] : v.value} sx={{ whiteSpace: 'normal' }}>
            <Grid column>
              {text ? v[text] : v.text}
              {extraData && <>
                <br></br>
                <span style={{ fontSize: 12 }}>{v[extraData]}</span>
              </>}
            </Grid>
          </MenuItem>
        ))}
      </Select>
      {error && <FormHelperText>{errorMgs || 'Requerido'}</FormHelperText>}
    </FormControl>
  );
};

SelectInput.defaultProps = {
  variant: 'outlined',
  sxFormControl: {},
  fullWidth: true,
  size: 'standard',
  sxSelect: {},
  error: false,
  errorMgs: '',
  disabled: false
};


SelectInput.propTypes = {
  label: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired,
  data: PropTypes.array.isRequired,
  variant: PropTypes.string,
  sxFormControl: PropTypes.object,
  fullWidth: PropTypes.bool,
  size: PropTypes.string,
  sxSelect: PropTypes.object,
  error: PropTypes.bool,
  errorMgs: PropTypes.string,
  disabled: PropTypes.bool,
};