import { useEffect, useState } from 'react';

import { ReportTable } from '../components/table/ReportTable';
import { useMenu } from '../hooks/useMenu';
import { useAuthPage } from '../hooks/useAuthPage';
import { Box, Button, Card, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tooltip, Typography } from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material';
import VisibilityIcon from "@mui/icons-material/Visibility";
import ApprovalOutlinedIcon from '@mui/icons-material/ApprovalOutlined';
import { ConciliacionSearch } from '../components/ConciliacionSearch';
/*import { ConciliacionForm } from '../components/ConciliacionForm';*/
import { ReportForm } from '../components/ReportForm';
import { useReportsPage } from '../hooks/useReportsPage';
import {
  json,
  useParams
} from "react-router-dom";

const iconTheme = createTheme({
  status: {
    danger: '#e53e3e'
  },
  palette: {
    primary: {
      main: '#00609C',
      dark: '#053e85'
    },
    neutral: {
      main: '#00CD68',
      light: '#053e85',
      dark: '#053e85',
      contrastText: '#fff'
    }
  }
});

export const ConciliacionPage = () => {

  const { type } = useParams();
  const { loadInInit } = useReportsPage();
  const { setNameSection } = useMenu();
  const { isAllowed } = useAuthPage();
  const [selectedRow, setSelectedRow] = useState({})
  const [showDetailsDialog, setShowDetailsDialog] = useState(false)

  useEffect(() => {
    loadInInit();
  }, []);

  useEffect(() => {
    setSelectedRow({})
  }, [type])
  

  const handleDetails = (row) => {
    setSelectedRow(row)
    setShowDetailsDialog(true)
  }



  if (isAllowed) {
    return (
      <ThemeProvider theme={iconTheme}>
        <Dialog
          open={showDetailsDialog}
          fullWidth={true}
          maxWidth='lg'
          onClose={() => setShowDetailsDialog(false)}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <TitleModal title={ type === "reporte-operaciones" ? (selectedRow?.nIdOperacion ? selectedRow?.nIdOperacion + " - " : "") + selectedRow?.sNombreComercial : "Operaciones con diferencias"} />
          
          <DialogContent>
          {showDetailsDialog && <>
            <TableContainer component={Paper}>
              <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    {type === "reporte-operaciones" ?
                      (<><TableCell>Fecha del movimiento</TableCell>
                        <TableCell align="right">Referencia</TableCell>
                        <TableCell align="center">Monto<br/><small><span style={{ color: '#00CD68' }}>Reportado</span> - <span>Registrado</span></small></TableCell>
                        <TableCell align="center">Comisión<br/><small><span style={{ color: '#00CD68' }}>Reportado</span> - <span>Registrado</span></small></TableCell>
                        <TableCell align="right">Número de autorización</TableCell>
                        <TableCell align="center">Información adicional</TableCell></>) :
                      (<><TableCell>Nombre del archivo</TableCell>
                        <TableCell>Identificador de la operación</TableCell>
                        <TableCell>Valores esperados en la operación</TableCell>
                        <TableCell>Valores recibidos en la operación</TableCell></>)
                    }
                  </TableRow>
                </TableHead>
                <TableBody>
                  {type === "reporte-operaciones" ?
                    <TableRow
                      key={selectedRow?.nIdOperacion}
                      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                    >
                      <TableCell component="th" scope="row">
                        {selectedRow.detalles?.dFecha === null ? selectedRow?.dFecha : selectedRow.detalles?.dFecha}
                      </TableCell>
                      <TableCell align="right">{selectedRow.detalles?.sReferencia}</TableCell>
                      <TableCell align="center">
                        <span style={{ color: '#00CD68' }}>{selectedRow.nMonto?.toLocaleString("en-US", { style: "currency", currency: "USD", })}</span> - <span>{selectedRow.detalles?.nMonto?.toLocaleString("en-US", { style: "currency", currency: "USD", })}</span>
                      </TableCell>
                      <TableCell align="center">
                        <span style={{ color: '#00CD68' }}>{selectedRow?.detalles?.nComision?.toLocaleString("en-US", { style: "currency", currency: "USD", })}</span> - <span>{selectedRow.detalles?.nComisionOO?.toLocaleString("en-US", { style: "currency", currency: "USD", })}</span>
                      </TableCell>
                      <TableCell align="right">{selectedRow.detalles?.sAutorizacion}</TableCell>
                      <TableCell align="center">{selectedRow.detalles?.sObservacion}</TableCell>
                    </TableRow> :
                    JSON.parse(selectedRow.detalles ? selectedRow.detalles : '[]')?.map((detalle, key) => (
                      <TableRow
                        key={key}
                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                      >
                        <TableCell component="th" scope="row">{detalle.sNombre}</TableCell>
                        <TableCell component="th" scope="row">{detalle.nIdOperacion}</TableCell>
                        <TableCell component="th" scope="row">
                          <p style={{ margin: 0, color: detalle.nReferenciaOO !== detalle.nReferencia ? "red" : "black" }}>Referencia: <b>{detalle.nReferenciaOO === null ? '----' : detalle.nReferenciaOO}</b></p>
                          <p style={{ margin: 0, color: detalle.sAutorizacionOO !== detalle.sAutorizacion ? "red" : "black" }}>Autorización: <b>{detalle.sAutorizacionOO === null ? '----' : detalle.sAutorizacionOO}</b></p>
                          <p style={{ margin: 0, color: detalle.nMontoOO !== detalle.nMonto ? "red" : "black" }}>Monto: <b>{detalle.nMontoOO === null ? '----' : detalle.nMontoOO.toLocaleString("en-US", { style: "currency", currency: "USD", })}</b></p>
                          <p style={{ margin: 0, color: detalle.nComisionOO !== detalle.nComision ? "red" : "black" }}>Comisión: <b>{detalle.nComisionOO === null ? '----' : detalle.nComisionOO.toLocaleString("en-US", { style: "currency", currency: "USD", })}</b></p>
                          <p style={{ margin: 0, color: detalle.dFechaOO !== detalle.dFecha ? "red" : "black" }}>Fecha: <b>{detalle.dFechaOO === null ? '----' : detalle.dFecha}</b></p>
                        </TableCell>
                        <TableCell component="th" scope="row">
                          <p style={{ margin: 0, color: detalle.nReferenciaOO !== detalle.nReferencia ? "red" : "black" }}>Referencia: <b>{detalle.nReferencia === null ? '----' : detalle.nReferencia}</b></p>
                          <p style={{ margin: 0, color: detalle.sAutorizacionOO !== detalle.sAutorizacion ? "red" : "black" }}>Autorización: <b>{detalle.sAutorizacion === null ? '----' : detalle.sAutorizacion}</b></p>
                          <p style={{ margin: 0, color: detalle.nMontoOO !== detalle.nMonto ? "red" : "black" }}>Monto: <b>{detalle.nMonto === null ? '----' : detalle.nMonto.toLocaleString("en-US", { style: "currency", currency: "USD", })}</b></p>
                          <p style={{ margin: 0, color: detalle.nComisionOO !== detalle.nComision ? "red" : "black" }}>Comisión: <b>{detalle.nComision === null ? '----' : detalle.nComision.toLocaleString("en-US", { style: "currency", currency: "USD", })}</b></p>
                          <p style={{ margin: 0, color: detalle.dFechaOO !== detalle.dFecha ? "red" : "black" }}>Fecha: <b>{detalle.dFecha === null ? '----' : detalle.dFecha}</b></p>
                        </TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </TableContainer>
          </>}
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setShowDetailsDialog(false)}>Cerrar</Button>
          </DialogActions>
        </Dialog>
        {type === 'pendientes-autorizar' ? ('') : (<ReportForm />)}
        <ReportTable
          showButtons={type === "reporte-operaciones" ? (row) => {
            return ( (row.sObservacion || row.nDiferencia) ? <>
              <Tooltip title="Ver detalles" placement="top">
                <IconButton size="small" disabled={row.detalles === '[]'} onClick={() => handleDetails(row)}>
                  <VisibilityIcon fontSize={"small"} />
                </IconButton>
              </Tooltip>
            </>: <></>)
          } : null}
        />
      </ThemeProvider>
    );
  } else {
    setNameSection('');
    return (
      <Card>
        <Typography variant="h3">Contenido no disponible</Typography>
      </Card>
    );
  }
};


const TitleModal = ({ title, extraButton }) => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        width: '100%',
        marginBottom: '10px',
        justifyContent: 'space-between',
        pt: 3,
        px: 3
      }}
    >
      <div style={{ display: 'flex', justifyContent: "space-between" }}>
        <ApprovalOutlinedIcon fontSize={'large'} sx={{ color: '#00CD68', fontSize: '45px' }} />

        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <Typography
            variant="p"
            sx={{
              marginLeft: '15px',
              fontSize: '14px',
              marginBottom: '4px'
            }}
          >
            Detalle
          </Typography>
          <Typography
            variant="p"
            sx={{
              marginLeft: '15px',
              color: '#00609C',
              fontSize: '20px'
            }}
          >
            {title}
          </Typography>
        </div>
      </div>
      <div>
        {extraButton}
      </div>
    </Box>
  );
};
