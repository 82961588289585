import { API } from "aws-amplify";
import { Auth } from "aws-amplify";
import { loading } from "../ui/uiSlice";

export const getDashboardData = (fecha) => {
  return async (dispatch) => {

    const params = {
      headers: {},
      response: false,
      queryStringParameters: {
        dFecha: fecha,
      },
    };
    try {
      dispatch(loading(true));
      const resp = await API.get("reportes", "/reporte/dashboard", params);
      console.log(resp.data);
      if (resp && resp.data) return resp.data;
      else return false;
    } catch (error) {
      console.log("ocurrio un error " + error);
      return false;
    } finally {
      dispatch(loading(false));
    }
  };
};
