export const plantillaAlta = [
    [
        "Clave de la sucursal",
        "Nombre",
        "Calle",
        "Núm. exterior",
        "Núm. interior",
        "Código postal",
        "Colonia",
        "Latitud",
        "Longitud",
        "Fecha inicio operaciones"
    ],
    [
        "6550762907",
        "Galerias",
        "Gonzalitos",
        "253",
        "A23",
        "64000",
        "Centro",
        "25.742946",
        "-100.281487",
        "2023-06-01"
    ],
    [
        "6550762909",
        "Patriota",
        "Alarcón",
        "345",
        "",
        "44130",
        "Arcos",
        "27.945148",
        "-88.048516",
        ""
    ],
    [
        "65507645645",
        "Puentes",
        "Babilon",
        "S/N",
        "",
        "66895",
        "County",
        "23.944348",
        "-79.0483416",
        ""
    ],
];

export const plantillaBaja = [
    [
        "Clave de la sucursal",
        "Motivo de baja",
        "Fecha de baja"
    ],
    [
        "6550762907",
        "Cierre de operaciones",
        "2023-06-01"
    ],
    [
        "6550762909",
        "Término del contrato",
        ""
    ],
    [
        "6550762919",
        "",
        "2023-06-15"
    ],
    [
        "6550762940",
        "Término del contrato",
        "2023-06-01"
    ],
];

export const especificacionesAlta = [
    [
        "Nombre del valor",
        "Requerido",
        "Tipo",
        "Longitud",
        "Ejemplo",
    ],
    [
        "Clave de la sucursal",
        "Si",
        "Alfanúmerico",
        "30",
        "1404",
    ],
    [
        "Nombre",
        "Si",
        "Alfanúmerico",
        "150",
        "SORIANA GONZALITOS",
    ],
    [
        "Calle",
        "Si",
        "Alfanúmerico",
        "100",
        "BOULEVARD COSTERO MIGUEL",
    ],
    [
        "Núm. exterior",
        "Si",
        "Alfanúmerico",
        "30",
        "S/N"
    ],
    [
        "Núm. interior",
        "No",
        "Alfanúmerico",
        "10",
        "LOCAL B"
    ],
    [
        "Código postal",
        "Si",
        "Númerico",
        "5",
        "28218"
    ],
    [
        "Colonia",
        "Si",
        "Alfanúmerico",
        "100",
        "LAS BRISAS"
    ],
    [
        "Latitud",
        "Si",
        "Decimal",
        "3,6",
        "19.102015"
    ],
    [
        "Longitud",
        "Si",
        "Decimal",
        "3,6",
        "-104.326922"
    ],
    [
        "Fecha inicio operaciones",
        "No",
        "Alfanúmerico",
        "10",
        "2024-08-02"
    ]
]

export const especificacionesBaja = [
    [
        "Nombre del valor",
        "Requerido",
        "Tipo",
        "Longitud",
        "Ejemplo",
    ],
    [
        "Clave de la sucursal",
        "Si",
        "Alfanúmerico",
        "30",
        "6550762940"
    ],
    [
        "Motivo de la baja",
        "No",
        "Alfanúmerico",
        "90",
        "Término del contrato"
    ],
    [
        "Fecha de baja",
        "No",
        "Alfanúmerico",
        "10",
        "2024-08-02"
    ]
]