import { useEffect, useState } from "react";
import { useMenu } from "../hooks/useMenu";
import { useAuthPage } from "../hooks/useAuthPage";
import { Card, Typography } from "@mui/material";
import { createTheme, ThemeProvider } from "@mui/material";
import { esES } from "@mui/material/locale";
import { ConciliacionManual } from "../components/ConciliacionManual";
import { useConciliacionManualPage } from "../hooks/useConciliacionManualPage";

const iconTheme = createTheme(
  {
    status: {
      danger: "#e53e3e",
    },
    palette: {
      primary: {
        main: "#00609C",
        dark: "#053e85",
      },
      neutral: {
        main: "#00CD68",
        light: "#053e85",
        dark: "#053e85",
        contrastText: "#fff",
      },
    },
  },
  esES
);

export const ConciliacionManualPage = () => {
  const {
    loadInInit
} = useConciliacionManualPage();

  const { setNameSection } = useMenu();
  const { setNewPage, isAllowed } = useAuthPage();
  const [loaded, setLoaded] = useState(false);


  useEffect(() => {
    if (!loaded) {
      loadInInit();
      setNewPage("conciliacionManual");
      setNameSection("conciliacionManual");
      setLoaded(true);
    }
  }, []);

  if (isAllowed) {
    return (
      <ThemeProvider theme={iconTheme}>
        <ConciliacionManual />
      </ThemeProvider>
    );
  } else {
    setNameSection("");
    return (
      <Card>
        <Typography variant="h3">Contenido no disponible</Typography>
      </Card>
    );
  }
};
