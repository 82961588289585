import { Download, History, PictureAsPdf, Summarize } from '@mui/icons-material';
import { Button, Grid, Typography } from '@mui/material';
import { Box } from '@mui/system';
import { ModalBase } from './ModalBase';
import EnhancedTable from '../table/EnhancedTable';
import { useSelector } from 'react-redux';
import ButtonDropdown from '../ButtonDropdown';

export const ModalBitacoraSuspension = (props) => {

  const {
    isOpenModal = false,
    dataTable = {},
    handleOpenModal,
    downloadBitacora
  } = props;


  const { isTableModalLoading } = useSelector((state) => state.ui);

  return (<>
    <ModalBase open={isOpenModal} title="" style={{ width: "1050px", height: 'auto' }}>
      <Box
        sx={{ width: '100%', height: '100%', display: 'flex', flexDirection: 'column' }}
      >
        <TitleModal
          title={"Bitácora"}
          subtitle={'Ver detalles'}
          extraButton={<ButtonDropdown
            startIcon={<Download />}
            label="Exportar"
            options={[
              { startIcon: <PictureAsPdf />, label: "PDF", action: () => downloadBitacora("pdf") },
              { startIcon: <Summarize />, label: "Excel", action: () => downloadBitacora("excel") },
            ]}
          />}
        />
        <EnhancedTable
          table={{
            ...dataTable,
            columns: [
              ...dataTable.columns,
              { field: 'nTodosProductos', headerName: 'Productos suspendidos', type: 'render', flex: 2, renderFunction: (row) => row.nTodosProductos ? "Todos los productos" : `${JSON.parse(row.sProductos).length} producto(s)` },
              { field: 'nTodasSucursales', headerName: 'Sucursales suspendidas', type: 'render', flex: 2, renderFunction: (row) => row.nTodasSucursales ? "Todas las sucursales" : `${JSON.parse(row.sSucursales).length} sucursal(es)` }

            ]
          }}
          disableButtons
          isModalTable
          disablePathParameters
          showExpandableTable
          rowId={"nIdSuspensionBitacora"}
          isLoading={isTableModalLoading}
          expandedItems={(id, row) => {
            let {
              sSucursales = "[]",
              sProductos = "[]",
              nTodosProductos,
              nTodasSucursales
            } = row

            sSucursales = JSON.parse(sSucursales)
            sProductos = JSON.parse(sProductos)
            return (
              <Grid container spacing={2}>
                <Grid item xs={4}>
                  <Typography variant="h6" color={"primary"} fontSize={15}>Productos suspendidos:</Typography>
                  <Grid container>
                    {nTodosProductos ?
                      <TagItem text={"Todos los productos"} />
                      :
                      sProductos.map((item) => <TagItem text={`(${item.sClaveProducto}) ${item.sDescripcion}`} />)
                    }
                  </Grid>
                </Grid>
                <Grid item xs={4}>
                  <Typography variant="h6" color={"primary"} fontSize={15}>Sucursales suspendidas:</Typography>
                  <Grid container>
                    {nTodasSucursales ?
                      <TagItem text={"Todas las sucursales"} />
                      :
                      sSucursales.map((item) => <TagItem text={`(${item.sClaveSucursal}) ${item.sNombre}`} />)
                    }
                  </Grid>
                </Grid>
                <Grid item xs={4}>
                  <Typography variant="h6" color={"primary"} fontSize={15}>Comentario</Typography>
                  <Grid container>
                    <TagItem text={row.sComentario} />
                  </Grid>
                </Grid>
              </Grid>
            )
          }}
        />
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            width: '100%',
            justifyContent: 'end'
          }}
        >
          <Button onClick={() => handleOpenModal(false)} color="primary">
            Volver
          </Button>
        </div>
      </Box>
    </ModalBase>
  </>
  );
};

const TagItem = ({ text }) => {
  return <span style={{ padding: "3px 10px", background: "#f0f0f0", borderRadius: 8, margin: "3px", fontWeight: 500, fontSize: 13 }}>{text}</span>
}

const TitleModal = ({ title, subtitle, extraButton }) => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        width: '100%',
        marginBottom: '10px',
        justifyContent: 'space-between'
      }}
    >
      <div style={{ display: 'flex', justifyContent: "space-between" }}>
        <History fontSize={'large'} sx={{ color: '#00CD68', fontSize: '45px' }} />

        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <Typography
            variant="p"
            sx={{
              marginLeft: '15px',
              fontSize: '14px',
              marginBottom: '4px'
            }}
          >
            {subtitle}
          </Typography>
          <Typography
            variant="p"
            sx={{
              marginLeft: '15px',
              color: '#00609C',
              fontSize: '20px'
            }}
          >
            {title}
          </Typography>
        </div>
      </div>
      <div>
        {extraButton}
      </div>
    </Box>
  );
};

