import { Close, PersonAdd, Person, Visibility, VisibilityOff, KeyboardArrowDown, KeyboardArrowUp } from '@mui/icons-material';
import { Alert, Button, Checkbox, Collapse, FormControlLabel, Grid, IconButton, TextField, Typography } from '@mui/material';
import { Box } from '@mui/system';
import { TextInput } from '../form/TextInput';
import { ModalBase } from './ModalBase';
import { regexEmail, regexPassword, regexTelefono } from '../../helpers/regexs';
import { SelectInput } from '../form/SelectInput';

import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { BlueSwitch } from '../../helpers/stylesMaterial';
import { useAuthPage } from '../../hooks/useAuthPage';
import { ConfirmDialog } from './ConfirmDialog';
import UnpublishedIcon from '@mui/icons-material/Unpublished';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import EditIcon from '@mui/icons-material/Edit';
import { SearchInput } from '../form/SearchInput';

export const ModalUsuarioExterno = (props) => {

  const {
    isOpenModal = false,
    modalType = "add",
    form = {},
    onChange,
    handleSubmit,
    handleSubmitChangeEstatus,
    handleCloseModal,
    cadenas = [],
    enableEditModal,
    disableEditModal,
    onChangeSearch
  } = props;

  const [showConfirmDialog, setShowConfirmDialog] = useState(false)
  const { setAllowComponent } = useAuthPage();

  const estatus = {
    inactivo: 0,
    activo: 1,
    baja: 2,
    pendiente: 3
  }

  const showTitleButton = () => {
    if (modalType === 'view' && setAllowComponent("editarUsuarioExterno") && [estatus.inactivo, estatus.activo].includes(form.nIdEstatus)) return <Button variant='contained' onClick={enableEditModal}
      startIcon={<EditIcon />}>Editar</Button>;
    if (modalType === 'edit' && setAllowComponent("cambiarEstatusUsuarioExterno") && [estatus.inactivo, estatus.activo].includes(form.nIdEstatus)) {
      return <Button
        onClick={() => setShowConfirmDialog(true)}
        variant={"contained"}
        color={form.nIdEstatus !== 1 ? "neutral" : "error"}
        startIcon={form.nIdEstatus !== 1 ? <CheckCircleIcon /> : <UnpublishedIcon />}
      >
        {form.nIdEstatus !== 1 ? 'Habilitar usuario' : 'Deshabilitar usuario'}
      </Button>;
    }
  }

  const handleConfirmDialog = async () => {
    setShowConfirmDialog(false)
    await handleSubmitChangeEstatus()
  }



  return (<>
    <ConfirmDialog
      isOpenDialog={showConfirmDialog}
      onCloseDialog={() => setShowConfirmDialog(false)}
      onConfimDialog={handleConfirmDialog}
      message={`¿Estas seguro/a de ${form.nIdEstatus === 1 ? 'deshabilitar' : 'habilitar'} al usuario?`}
      textButtonConfirm="Sí, si estoy seguro/a."
    />
    <ModalBase open={isOpenModal} title="" style={{ width: '850px', height: 'auto' }}>
      <Box
        sx={{ width: '100%', height: '100%', display: 'flex', flexDirection: 'column' }}
      >
        <TitleModal title={modalType === "add" ? "Usuario Externo" : form.sNombreComercial} typeOperation={modalType} extraButton={showTitleButton()} />

        <Grid container spacing={2} sx={{ mt: 1, mb: 2, maxHeight: "calc(100vh - 250px)", overflow: 'auto' }}>
          <Grid item xs={12}>
            <Typography variant="h6" color={"primary"}>Datos del usuario</Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextInput
              id="sNombre"
              name="sNombre"
              label="Nombre"
              value={form.sNombre}
              onChangeRoot={onChange}
              disabled={modalType === 'view'}
              require
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextInput
              id="sAPaterno"
              name="sAPaterno"
              label="Apellido paterno"
              value={form.sAPaterno}
              onChangeRoot={onChange}
              disabled={modalType === 'view'}
              require
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextInput
              id="sAMaterno"
              name="sAMaterno"
              label="Apellido materno"
              value={form.sAMaterno}
              onChangeRoot={onChange}
              disabled={modalType === 'view'}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextInput
              id="sTelefono"
              name="sTelefono"
              label="Teléfono"
              type='number'
              caracteres="10"
              value={form.sTelefono}
              disabled={modalType === 'view'}
              expresionRegular={regexTelefono}
              erroMsg="Teléfono inválido"
              onChangeRoot={onChange}
              require
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextInput
              id="sArea"
              name="sArea"
              label="Área"
              value={form.sArea}
              onChangeRoot={onChange}
              disabled={modalType === 'view'}
              caracteres={50}
              require
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextInput
              id="sPuesto"
              name="sPuesto"
              label="Puesto"
              value={form.sPuesto}
              onChangeRoot={onChange}
              disabled={modalType === 'view'}
              caracteres={50}
              require
            />
          </Grid>
          <Grid item xs={12}>
            <TextInput
              id="sComentario"
              name="sComentario"
              label="Comentarios"
              value={form.sComentario}
              onChangeRoot={onChange}
              disabled={modalType === 'view'}
              caracteres={45}
            />
          </Grid>
          
          <Grid item xs={12}>
            <Typography variant="h6" color={"primary"}>Datos de acceso</Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextInput
              id="sCorreo"
              name="sCorreo"
              label="Correo"
              value={form.sCorreo}
              onChangeRoot={onChange}
              expresionRegular={regexEmail}
              disabled={modalType === 'view'}
              erroMsg="Correo inválido"
              require
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextInput
              id="sUsuario"
              name="sUsuario"
              label="Usuario"
              value={form.sUsuario}
              disabled={modalType !== 'add'}
              onChangeRoot={onChange}
              require
            />
          </Grid>
          <Grid item xs={12}>
            <SelectInput
              id="nIdCadena"
              name="nIdCadena"
              onChange={(e) => onChange({ nIdCadena: e.target.value })}
              label="Socio Comercial"
              data={cadenas}
              value={form.nIdCadena}
              disabled={modalType === 'view'}
              text="sNombreComercial"
              idOption={"nIdCadena"}
            />
          </Grid>




        </Grid>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            width: '100%',
            justifyContent: 'end'
          }}
        >
          <Button onClick={modalType === "edit" ? disableEditModal : handleCloseModal} color="primary">
            {modalType === "edit" ? "Cancelar" : "Cerrar"}
          </Button>
          {modalType !== 'view' &&
            <Button
              onClick={handleSubmit}
              color="primary"
              variant='contained'
            >
              Guardar
            </Button>
          }
        </div>
      </Box>
    </ModalBase>
  </>
  );
};

const TitleModal = ({ title, typeOperation, extraButton }) => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        width: '100%',
        marginBottom: '10px',
        justifyContent: 'space-between'
      }}
    >
      <div style={{ display: 'flex', justifyContent: "space-between" }}>
        {typeOperation === 'add' ?
          <PersonAdd fontSize={'large'} sx={{ color: '#00CD68', fontSize: '45px' }} />
          : <Person fontSize={'large'} sx={{ color: '#00CD68', fontSize: '45px' }} />}

        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <Typography
            variant="p"
            sx={{
              marginLeft: '15px',
              fontSize: '14px',
              marginBottom: '4px'
            }}
          >
            {typeOperation === 'add' ? 'Agregar' : typeOperation === 'edit' ? 'Editar usuario externo' : 'Ver usuario externo'}
          </Typography>
          <Typography
            variant="p"
            sx={{
              marginLeft: '15px',
              color: '#00609C',
              fontSize: '20px'
            }}
          >
            {title}
          </Typography>
        </div>
      </div>
      <div>
        {extraButton}
      </div>
    </Box>
  );
};

