import { Box } from "@mui/system";
import { useModal } from "../../hooks/useModal";
import { useAuthPage } from "../../hooks/useAuthPage";
import { ModalBase } from "./ModalBase";
import {
  Button,
  Grid,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import moment from "moment";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { SearchInput } from "../form/SearchInput";
import { IvaInput } from "../form/IvaInput";

import FactCheckIcon from "@mui/icons-material/FactCheck";
import { useState } from "react";
import { useEffect } from "react";
import { useSelector } from "react-redux";

export const ModalPermission = (props) => {
  const {
    form,
    onChange,
    rutas = [],
    onChangeInput,
    emisores = [],
    cadenas = [],
    onSave,
    onChangeForm,
    productosEmisores,
    productosCadena
  } = props;
  const { isOpenPermission, closeModalPermission, setOpenToast } = useModal();
  const { setAllowComponent } = useAuthPage();
  const [innerRutas, setInnerRutas] = useState(rutas);
  const [cadena, setCadena] = useState({});
  const [loaded, setLoaded] = useState(false);
  const [isRouteChanged, setRouteChanged] = useState(false);
  const [innerRuta, setInnerRuta] = useState({});

  const [originalData, setOriginalData] = useState({});
  const [dateIntervals, setDateIntervals] = useState([])

  const { permissions } = useSelector((state) => state.cadena);

  useEffect(() => {
    if (cadenas.length) {
      let innerCadena = cadenas.filter(
        (item) => item.nIdCadena === form.nIdCadena
      )[0];
      if (innerCadena) {
        setCadena({ ...cadena, ...innerCadena });
      }
    }
  }, [form.nIdCadena]);

  useEffect(() => {
    if (isOpenPermission) {

      handleInnerRutas(rutas);
      handleEmisorChange(form.nIdEmisor);
      setOriginalData(form)
      setDateIntervals([])
      if (form.nIdPermiso) {
        let innerRoute = rutas.find((ruta) => ruta.nIdRuta === form.nIdRuta);
        console.log(innerRoute, form.nIdRuta)
        if (innerRoute) {
          setInnerRuta(innerRoute)
          onChangeForm({
            ...form,
            nIdRuta: innerRoute.nIdRuta
          });
        }
      }
      setLoaded(true);
    } else {
      setInnerRuta({})
      setLoaded(false);
      setRouteChanged(false)
    }
  }, [form.nIdPermiso]);

  useEffect(() => {
    if (loaded || form.modalType === "add") {
      let innerRoute = rutas.find((ruta) => ruta.nIdRuta === form.nIdRuta);
      if (innerRoute) {
        if (form.modalType !== "view") updateDateIntervals(innerRoute)
        setInnerRuta(innerRoute)
        onChangeForm({
          ...form,
          nImpComCliente: innerRoute.nImpComProducto,
          nImpComEspecial: Math.abs(
            innerRoute.nImpComProducto - form.nImpComCadena
          ),
          dFEVPermiso: moment(getCorrectDate(innerRoute.dFEVRuta)).isBefore(moment()) ? moment().format('YYYY-MM-DD') :  moment(getCorrectDate(innerRoute.dFEVRuta)).format('YYYY-MM-DD'),
          nTipoContrato:
            Math.abs(parseFloat(innerRoute.nImpComProducto) - parseFloat(form.nImpComCadena)) === 0
              ? 0
              : innerRoute.nImpComProducto > form.nImpComCadena
                ? 2
                : 1,
        });

      }
    }

  }, [form.nIdRuta]);


  useEffect(() => {
    if (loaded || form.modalType === "add") {
      onChangeForm({
        ...form,
        nImpComEspecial: Math.abs(form.nImpComCliente - form.nImpComCadena),
        nTipoContrato:
          Math.abs(parseFloat(form.nImpComCliente) - parseFloat(form.nImpComCadena)) === 0
            ? 0
            : parseFloat(form.nImpComCliente) > parseFloat(form.nImpComCadena)
              ? 2
              : 1,
      });
    }
  }, [form.nImpComCliente, form.nImpComCadena]);


  const updateDateIntervals = (ruta) => {
    const producto = ruta.sClaveProducto
    let data = []

    //validacion de otros permisos (que no se empate con las fechas de otros permisos con el mismo producto)
    if (form.modalType === "add") {
      if (permissions.rows) permissions.rows.map(per => {
        if (per.sClaveProducto === producto) {
          data.push({
            id: 1,
            dFecInicial: new Date(getCorrectDate(per.dFEVPermiso))
          })
        }
      })
    } else {
      const currentDate = new Date(getCorrectDate(form.dFEVPermiso))
      if (permissions.rows) permissions.rows.map(per => {
        if (per.sClaveProducto === producto && per.nIdPermiso !== form.nIdPermiso) {
          let perDate = new Date(getCorrectDate(per.dFEVPermiso))
          data.push({
            id: perDate > currentDate ? 3 : 1,
            dFecInicial: perDate,
            dFecFinal: perDate
          })
        }
      })
    }

    //validacion de la ruta (que la fecha no sea menor)
    data.push({
      id: 1,
      dFecInicial: new Date(getCorrectDate(ruta.dFEVRuta))
    })

    //validacion con las fechas de la cadena del producto de la ruta
    let fechaBajaCadena = null
    const productosCadenaFiltered = productosCadena?.filter(pe => pe.sClaveProducto === producto).sort(function (a, b) { return new Date(a.dFecAlta) - new Date(b.dFecAlta) })
    if (productosCadenaFiltered) productosCadenaFiltered.map((pe, key) => {
      if (fechaBajaCadena) {
        data.push({
          id: 2,
          dFecInicial: new Date(getCorrectDate(fechaBajaCadena)),
          dFecFinal: new Date(getCorrectDate(pe.dFecAlta))
        })
      } else {
        data.push({
          id: 1,
          dFecInicial: new Date(getCorrectDate(pe.dFecAlta))
        })
      }
      fechaBajaCadena = pe.dFecBaja ? pe.dFecBaja : null
      if (!productosCadenaFiltered[key + 1] && fechaBajaCadena) {
        data.push({
          id: 3,
          dFecFinal: new Date(getCorrectDate(fechaBajaCadena))
        })
      }
    })

    //validacion con las fechas de la cadena producto de la ruta 
    let fechaBaja = null
    const productosEmisoresFiltered = productosEmisores?.filter(pe => pe.sClaveProducto === producto && pe.nIdEmisor === ruta.nIdEmisor).sort(function (a, b) { return new Date(a.dFecAlta) - new Date(b.dFecAlta) })
    if (productosEmisoresFiltered) productosEmisoresFiltered.map((pe, key) => {
      if (fechaBaja) {
        data.push({
          id: 2,
          dFecInicial: new Date(getCorrectDate(fechaBaja)),
          dFecFinal: new Date(getCorrectDate(pe.dFecAlta))
        })
      } else {
        data.push({
          id: 1,
          dFecInicial: new Date(getCorrectDate(pe.dFecAlta))
        })
      }
      fechaBaja = pe.dFecBaja ? pe.dFecBaja : null
      if (!productosEmisoresFiltered[key + 1] && fechaBaja) {
        data.push({
          id: 3,
          dFecFinal: new Date(getCorrectDate(fechaBaja))
        })
      }
    })
    console.log(data)
    setDateIntervals(data)
  }

  function disableDate(date) {

    let flag = false

    if (dateIntervals) {
      const currentDate = new Date(date)

      dateIntervals.map(di => {
        if (!flag) {
          //1. dFecInicial < value    2. (Intervalo a deshabilitar) dFecInicial < value < dFecFinal    3. value < dFecFinal
          if (di.id === 1) flag = currentDate < di.dFecInicial
          if (di.id === 2) flag = di.dFecInicial < currentDate && currentDate < di.dFecFinal
          if (di.id === 3) flag = currentDate >= di.dFecFinal

        }
      })

    }
    return flag
  }

  const handleSubmit = () => {
    let permissionsArray = []
    if (permissions.rows) permissions.rows.map(per => {
      if (per.nIdPermiso !== form.nIdPermiso) permissionsArray.push(per.nIdRuta)
    })

    if (!form.nIdRuta) {
      setOpenToast(true, "Seleccione una ruta para continuar")
      return
    }
    if (form.modalType === "add" && permissionsArray.includes(form.nIdRuta)) {
      setOpenToast(true, "Ya existe un permiso asociado con esta ruta y comisionista")
      return
    }
    if (innerRuta.nImpComProducto < form.nImpComCliente) {
      setOpenToast(true, "La comisión al cliente final debe de ser menor o igual al definido en la ruta: " + innerRuta.nImpComProducto?.toLocaleString("en-US", {
        style: "currency",
        currency: "USD",
      }))
      return
    }
    if (moment(getCorrectDate(innerRuta.dFEVRuta)).diff(moment(getCorrectDate(form.dFEVPermiso))) > 0) {
      setOpenToast(true, "La fecha de entrada en vigor no debe de ser menor a la entrada en vigor de la ruta: " + moment(getCorrectDate(innerRuta.dFEVRuta)).format('YYYY-MM-DD'))
      return
    }
    if (disableDate(getCorrectDate(moment(form.dFEVPermiso).add(1, "day").format("YYYY-MM-DD")))) {
      setOpenToast(true, "La fecha de entrada en vigor seleccionada esta deshabilitada. Seleccione otra.")
      return
    }

    onSave()
  }

  const getPermissionModal = () => {
    if (
      isOpenPermission &&
      ((["edit", "view"].includes(form.modalType) && form.nIdPermiso) ||
        form.modalType === "add")
    ) {
      if (form.modalType === "add" && setAllowComponent("agregarPermisos"))
        return true;
      if (form.modalType === "edit" && setAllowComponent("editarPermisos"))
        return true;
      if (form.modalType === "view" && setAllowComponent("verPermisos"))
        return true;
    }
    return false;
  };

  const handleEmisorChange = (emisor) => {
    if (rutas.length) {
      let innerRutas = rutas.filter((ruta) => ruta.nIdEmisor === emisor);
      onChange(null, "nIdRuta", 0);
      if (originalData.nIdEmisor !== emisor) setRouteChanged(true)
      handleInnerRutas(innerRutas);
    }
  };

  const getCorrectDate = (date) => {
    return date && date[date?.length - 1] === "Z" ? date.slice(0, -1) : date
  }

  const handleInnerRutas = (rutas) => {
    let data = []
    let permissionsArray = []

    if (permissions.rows) permissions.rows.map(per => {
      if (per.nIdPermiso !== form.nIdPermiso) permissionsArray.push(per.nIdRuta)
    })
    if (rutas) rutas.map(ruta => {
      if (!permissionsArray.includes(ruta.nIdRuta) || (form.modalType !== "add" && ruta.nIdRuta === originalData.nIdRuta)) data.push({
        ...ruta,
        sDescripcion: `(${moment(getCorrectDate(ruta.dFEVRuta)).format('YYYY-MM-DD')}) ${ruta.sDescripcion}`
      })
    })
    console.log(data)
    setInnerRutas(data)
  }



  return getPermissionModal() ? (
    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={"es-mx"}>
      <ModalBase
        open={isOpenPermission}
        style={{ width: "600px", height: "650px" }}
      >
        <Box
          sx={{
            width: "100%",
            height: "100%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
          }}
        >
          <Grid container style={{ alignItems: "space-between" }}>
            <Grid container item sm={8} style={{ alignItems: "center" }}>
              <Grid
                item
                style={{
                  color: "#00CD68",
                  padding: 15,
                  fontWeight: "bold",
                }}
              >
                <FactCheckIcon fontSize={"large"} />
              </Grid>
              <Grid item>
                <Typography variant="p" color={"neutral"}>
                  {form.modalType === "add"
                    ? "Agregar"
                    : form.modalType === "edit"
                      ? "Editar"
                      : "Ver"}{" "}
                  permiso (Comisión al frente)
                </Typography>
                <Typography variant="h5" color={"primary"}>
                  {cadena && cadena.sNombreComercial}
                </Typography>
              </Grid>
            </Grid>
            <Grid
              container
              item
              sm={4}
              style={{ justifyContent: "flex-end", alignItems: "center" }}
            >
              <Grid item style={{ textAlign: "right" }}>
                <Typography variant="p" color={"neutral"}>
                  IVA
                </Typography>
                <Typography variant="h5" color={"primary"}>
                  {cadena && cadena.nIVA * 100}%
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <div>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12}>
                <SearchInput
                  options={emisores}
                  value={form.nIdEmisor}
                  name={"nIdEmisor"}
                  label={"Emisor"}
                  getOptionLabel={"sNombreComercial"}
                  onChange={(e) => handleEmisorChange(e.target.value)}
                  getIndexLabel={"nIdEmisor"}
                  disabled={form.modalType === "view"}
                />
              </Grid>
              <Grid item xs={12} sm={12} style={{ minHeight: 72 }}>
                {
                  form.modalType === 'add' || form.nIdRuta === innerRuta.nIdRuta || (isRouteChanged && !form.nIdRuta)
                    ?
                    <SearchInput
                      options={innerRutas.filter(ruta => form.modalType !== "add" || ruta.nActivo > 0)}
                      value={form.nIdRuta}
                      name={"nIdRuta"}
                      label={"Ruta"}
                      getOptionLabel={"sDescripcion"}
                      getIndexLabel={"nIdRuta"}
                      onChangeInnerInput={onChange}
                      onChange={onChange}
                      disabled={form.modalType === "view"}
                    />
                    : <></>
                }
              </Grid>
              <Grid item xs={12} sm={12}>
                <IvaInput
                  id="nImpComCliente"
                  name="nImpComCliente"
                  label="Comisión cliente final"
                  value={form.nImpComCliente}
                  onChangeRoot={onChangeInput}
                  variant="outlined"
                  fullWidth
                  type="number"
                  size="medium"
                  ivaValue={cadena ? cadena.nIVA * 100 : 0}
                  disabled={form.modalType === "view"}
                  startAdornment={
                    <InputAdornment position="start">$</InputAdornment>
                  }
                />
              </Grid>

              <Grid item xs={12} sm={12}>
                <IvaInput
                  id="nImpComCadena"
                  name="nImpComCadena"
                  label="Comisión comisionista"
                  value={form.nImpComCadena}
                  onChangeRoot={onChangeInput}
                  variant="outlined"
                  fullWidth
                  type="number"
                  size="medium"
                  ivaValue={cadena ? cadena.nIVA * 100 : 0}
                  disabled={form.modalType === "view"}
                  startAdornment={
                    <InputAdornment position="start">$</InputAdornment>
                  }
                />
              </Grid>


              <Grid item xs={12} sm={12}>
                <IvaInput
                  id="nImpComEspecial"
                  name="nImpComEspecial"
                  label="Importe SofiPay"
                  value={form.nImpComEspecial}
                  onChangeRoot={onChangeInput}
                  variant="outlined"
                  fullWidth
                  type="number"
                  size="medium"
                  ivaValue={cadena ? cadena.nIVA * 100 : 0}
                  disabled={true}
                  startAdornment={
                    <InputAdornment position="start">$</InputAdornment>
                  }
                />
              </Grid>

              <Grid item sm={6} xs={12}>
                <SearchInput
                  options={[
                    {
                      nTipoContrato: 0,
                      name: "No cobramos, no pagamos",
                    },
                    {
                      nTipoContrato: 1,
                      name: "SofiPay paga",
                    },
                    {
                      nTipoContrato: 2,
                      name: "SofiPay cobra",
                    },
                  ]}
                  value={form.nTipoContrato}
                  name={"nTipoContrato"}
                  label={"Tipo de contrato"}
                  getOptionLabel={"name"}
                  getIndexLabel={"nTipoContrato"}
                  onChange={onChange}
                  disabled={true}
                />
              </Grid>
              <Grid item sm={6} xs={12}>
                <DesktopDatePicker
                  label="Entrada en vigor"
                  inputFormat="YYYY-MM-DD"
                  renderInput={(params) => (
                    <TextField {...params} style={{ width: "100%" }} />
                  )}
                  value={
                    form.dFEVPermiso[form.dFEVPermiso.length - 1] === "Z"
                      ? form.dFEVPermiso.slice(0, -1)
                      : form.dFEVPermiso
                  }
                  disabled={form.modalType === "view"}
                  disablePast={form.modalType === "add"}
                  shouldDisableDate={disableDate}
                  onChange={(e) =>
                    onChange(
                      null,
                      "dFEVPermiso",
                      moment(e["$d"]).format("YYYY-MM-DD")
                    )
                  }
                />
              </Grid>

            </Grid>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              width: "100%",
              justifyContent: "end",
            }}
          >

            {form.modalType !== "view" ? (
              <Button onClick={closeModalPermission} sx={{ color: "#00609C" }}>
                cancelar
              </Button>
            ) : (
              <Button onClick={closeModalPermission} sx={{ color: "#00609C" }}>
                cerrar
              </Button>
            )}

            {form.modalType !== "view" ? (
              <Button onClick={handleSubmit} variant="contained">
                Guardar
              </Button>
            ) : (
              <></>
            )}
          </div>
        </Box>
      </ModalBase>
    </LocalizationProvider>
  ) : (
    <></>
  );
};
