import { API, Storage } from "aws-amplify";
import { loading, tableLoading, tableModalLoading } from "../ui/uiSlice";
import {
  setDataTable,
  setDiferencias,
  setDiferenciasConciliadas,
  setOperaciones,
} from "./conciliacionSlice";

export const getArchivosConciliacion = (form) => {
  return async (dispatch) => {
    const params = {
      headers: {},
      response: false,
      queryStringParameters: form,
    };

    try {
      dispatch(tableLoading(true));
      const resp = await API.get(
        "conciliacion",
        "/conciliacion/archivos",
        params
      );
      let { rows = [], columns = [] } = resp.data;
      rows = rows.map((row) => {
        const sDetalle = JSON.parse(row.oDetalles ?? "{}");
        let subrows = row.subrows ? JSON.parse(row.subrows) : [];
        subrows = subrows.map((subrow) => {
          const sDetalle = JSON.parse(subrow.oDetalles ?? "{}");
          return {
            ...subrow,
            ...sDetalle,
          };
        });
        return {
          ...row,
          ...sDetalle,
          subrows,
        };
      });

      console.log({
        rows,
        columns,
        details: form,
      });
      dispatch(
        setDataTable({
          rows,
          columns,
          details: form,
        })
      );
    } catch (error) {
      console.log("ocurrio un error " + error);
    } finally {
      dispatch(tableLoading(false));
    }
  };
};

export const getOperacionesArchivosConciliacion = (form) => {
  return async (dispatch) => {
    const params = {
      headers: {},
      response: false,
      queryStringParameters: form,
    };

    try {
      dispatch(tableModalLoading(true));
      const resp = await API.get(
        "conciliacion",
        "/conciliacion/archivos-operaciones",
        params
      );
      console.log(resp.data);
      return resp?.data;
    } catch (error) {
      console.log("ocurrio un error " + error);
    } finally {
      dispatch(tableModalLoading(false));
    }
  };
};

export const getBitacoraConciliacion = (form) => {
  return async (dispatch) => {
    const params = {
      headers: {},
      response: false,
      queryStringParameters: form,
    };
    let data = [];
    try {
      dispatch(tableModalLoading(true));
      const resp = await API.get(
        "conciliacion",
        "/conciliacion/bitacora",
        params
      );
      console.log(resp.data);
      data = [...resp.data];
    } catch (error) {
      console.log("ocurrio un error " + error);
    } finally {
      dispatch(tableModalLoading(false));
    }
    return data;
  };
};

export const getDiferenciasConciliadas = (form) => {
  return async (dispatch) => {
    const params = {
      headers: {},
      response: false,
      queryStringParameters: form,
    };

    try {
      dispatch(tableLoading(true));
      const resp = await API.get(
        "conciliacion",
        "/conciliacion/autorizacion-diferencias",
        params
      );
      console.log(resp.data);
      dispatch(setDiferenciasConciliadas(resp.data));
    } catch (error) {
      console.log("ocurrio un error " + error);
    } finally {
      dispatch(tableLoading(false));
    }
  };
};

export const validateConciliacionFile = (S3Key, filename) => {
  return async (dispatch) => {
    const params = {
      headers: {},
      response: false,
      body: { S3Key, filename },
    };
    let res = { nCodigo: 1, sMensaje: "" };
    try {
      dispatch(loading(true));
      const resp = await API.post(
        "conciliacion",
        "/conciliacion/validacion",
        params
      );
      console.log(resp);
      res = resp;
    } catch (error) {
      console.log("ocurrio un error " + error);
    } finally {
      dispatch(loading(false));
      return res;
    }
  };
};

export const borrarUltimaCarga = (nIdArchivo) => {
  return async (dispatch) => {
    const params = {
      headers: {},
      response: false,
      body: { nIdArchivo },
    };
    let res = { nCodigo: 1, sMensaje: "" };
    try {
      dispatch(loading(true));
      const resp = await API.put(
        "conciliacion",
        "/conciliacion/borrar-carga",
        params
      );
      console.log(resp);
      res = resp;
    } catch (error) {
      console.log("ocurrio un error " + error);
    } finally {
      dispatch(loading(false));
      return res;
    }
  };
};

export const getNumberFilesS3Key = (key = "") => {
  return async (dispatch) => {
    let nArchivos = 0;
    try {
      dispatch(loading(true));
      const res = await Storage.list(key);
      nArchivos = res ? res.length : 0;
    } catch (error) {
      console.log("error al obtener la imagen", error);
    } finally {
      dispatch(loading(false));
      return nArchivos;
    }
  };
};

export const subirArchivoS3 = (file, path, fileName) => {
  return async (dispatch) => {
    let result = { ok: false, msg: "" };

    if (!Boolean(file.type) || !Boolean(file.size))
      return {
        ok: false,
        msg: "No se recibio un archivo o tiene formato no valido",
      };

    try {
      const resp = await Storage.put(`${path}/${fileName}`, file, {
        contentType: file.type,
      });
      result = {
        ok: Boolean(resp.key),
        msg: "Archivo cargado exitosamente",
        key: resp.key,
      };
    } catch (error) {
      console.log("ocurrio un error" + error);
      result = {
        ok: false,
        msg:
          `Error al subir el documento. Error: ${error.message}` ??
          "Error al subir el documento",
      };
    } finally {
      console.log("finally", result);
      return result;
    }
  };
};

export const getConciliacionManualData = (form = {}) => {
  return async (dispatch) => {
    const params = {
      headers: {},
      response: false,
      queryStringParameters: {
        nActor: form.nActor,
        nIdCadena: form.nIdCadena,
        nIdEmisor: form.nIdEmisor,
        sArchivo: form.sArchivo,
      },
    };
    let resp = [];
    try {
      dispatch(loading(true));
      resp = await API.get(
        "conciliacion",
        "/conciliacion/conciliacion-manual",
        params
      );
      const { operaciones = "[]", diferencias = "[]" } = JSON.parse(
        resp.data[0].data ?? "{}"
      );
      dispatch(setOperaciones(JSON.parse(operaciones)));
      dispatch(setDiferencias(JSON.parse(diferencias)));
    } catch (error) {
      console.log("ocurrio un error " + error);
    } finally {
      dispatch(loading(false));
      return resp;
    }
  };
};

export const postConciliacionManualData = (sArchivosDiferencias) => {
  return async (dispatch) => {
    const params = {
      headers: {},
      response: false,
      body: { sArchivosDiferencias },
    };
    let resp = {
      nCodigo: 1,
      sMensaje: "Error al conciliar manualmente. Intente más tarde.",
    };
    try {
      dispatch(loading(true));
      resp = await API.post(
        "conciliacion",
        "/conciliacion/conciliacion-manual",
        params
      );
      console.log(resp);
    } catch (error) {
      console.log("ocurrio un error " + error);
    } finally {
      dispatch(loading(false));
      return resp;
    }
  };
};

export const conciliarArchivo = (
  nIdArchivo = 0,
  sRuta = "",
  dFechaConciliacion = ""
) => {
  return async (dispatch) => {
    const params = {
      headers: {},
      response: false,
      body: { nIdArchivo, sRuta, dFechaConciliacion },
    };
    let resp = {
      nCodigo: 1,
      sMensaje: "Error al conciliar el archivo. Intente más tarde.",
    };
    try {
      dispatch(loading(true));
      resp = await API.post("conciliacion", "/conciliacion/conciliar", params);
      console.log(resp);
    } catch (error) {
      console.log("ocurrio un error " + error);
    } finally {
      dispatch(loading(false));
      return resp;
    }
  };
};

export const deleteDiferencia = (nIdDiferencia, sComentario) => {
  return async (dispatch) => {
    const params = {
      headers: {},
      response: false,
      body: { nIdDiferencia, sComentario },
    };
    let resp = {
      nCodigo: 1,
      sMensaje: "Error al eliminar la diferencia. Intente más tarde.",
    };
    try {
      dispatch(loading(true));
      resp = await API.put(
        "conciliacion",
        "/conciliacion/rechazar-diferencia",
        params
      );
      console.log(resp);
    } catch (error) {
      console.log("ocurrio un error " + error);
    } finally {
      dispatch(loading(false));
      return resp;
    }
  };
};

export const autorizarOperaciones = (sDiferencias = []) => {
  return async (dispatch) => {
    const params = {
      headers: {},
      response: false,
      body: { sDiferencias },
    };
    let resp = {
      nCodigo: 1,
      sMensaje: "Error al autorizar las operaciones. Intente más tarde.",
    };
    try {
      dispatch(loading(true));
      resp = await API.put(
        "conciliacion",
        "/conciliacion/autorizar-diferencias",
        params
      );
      console.log(resp);
    } catch (error) {
      console.log("ocurrio un error " + error);
    } finally {
      dispatch(loading(false));
      return resp;
    }
  };
};
