import { useDispatch } from 'react-redux';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { openConciliacion } from '../slices/ui/uiSlice';
import {
  setConfigId,
  setConfigTipo
} from '../slices/configConciliacion/configConciliacionSlice';
import { useModal } from './useModal';
import { putEnvioOperaciones } from '../slices/cadena/cadenaThunk';
import { putEnvioOperacionesEmisores } from '../slices/emisor/emisorThunk';
import { startLoadCadenas, startLoadEmisores } from '../slices/catalogo/catalgoThunk';
import { deleteConexion, getArchivos, getConexiones, getConexionesSofiPay, insertConexion, updateArchivo, updateConexion, verPassword } from '../slices/conexionesSFTP/conexionesSFTPThunk';
import { resetConexionesSFTP } from '../slices/conexionesSFTP/conexionesSFTPSlice';
import { conexionSFTPForm } from '../helpers/forms';




export const useModalConexionesSFTP = () => {
  const { cadenas, emisores } = useSelector((state) => state.catalogo);
  const { archivos, conexiones, conexionesSofiPay } = useSelector((state) => state.conexionesSFTP);
  const { isOpenConexionesSFTP } = useSelector(
    (state) => state.ui
  );
  const { setOpenToast, closeModalConexionesSFTP } = useModal();
  const dispatch = useDispatch();

  const [form, setForm] = useState({});
  const [selectedEntity, setSelectedEntity] = useState({})
  const [type, setType] = useState("view")
  const [cgfSFTP, setCgfSFTP] = useState({})

  const [archivoEditing, setArchivoEditing] = useState({})

  const [conexionSelected, setConexionSelected] = useState(conexionSFTPForm)

  const [view, setView] = useState("archivos")
  const [typeBitacora, setTypeBitacora] = useState("")

  useEffect(() => {
    if (isOpenConexionesSFTP.open) {
      searchEntity()
      startLoadConexiones()
      setView("archivos")
    } else {
      dispatch(resetConexionesSFTP());
      setArchivoEditing({})
      setForm({})
    }
  }, [isOpenConexionesSFTP.open])

  useEffect(() => {
    if (
      (isOpenConexionesSFTP.nIdCadena && cadenas.rows.length)
      || (isOpenConexionesSFTP.nIdEmisor && emisores.rows.length)
    ) {
      searchEntity()
    }
  }, [emisores, cadenas])

  const startLoadConexiones = async () => {
    const {
      nIdCadena,
      nIdEmisor
    } = isOpenConexionesSFTP
    const archivos = await dispatch(getArchivos({ nIdCadena, nIdEmisor }, !!isOpenConexionesSFTP.nIdEmisor));
    const conexiones = await dispatch(getConexiones({ nIdCadena, nIdEmisor }, !!isOpenConexionesSFTP.nIdEmisor));
    if (!conexionesSofiPay.length) {
      await dispatch(getConexionesSofiPay());
    }

    return {archivos, conexiones}
  }

  const getConexionesDisponibles = () => {
    const idsConexiones = conexiones.map(con => parseInt(con.nIdCfgConexion))
    return ([
      { nIdCfgConexion: 0, sNombreConexion: "Ninguna", nIdTipoActor: 0 },
      ...conexionesSofiPay.filter(con => !idsConexiones.includes(parseInt(con.nIdCfgConexion))),
      ...conexiones
    ].map(con => ({ ...con, nIdCfgConexion: con.nIdCfgConexion ?? con.nIdConfConexion })))
  }

  const getOpcionesConexiones = () => {
    return (getConexionesDisponibles().map(con => ({ text: con.sNombreConexion, value: con.nIdCfgConexion })))
  }

  const showConexiones = (isSofiPay = false) => {
    setView(isSofiPay ? "conexionesSofiPay" : "conexiones")
    setConexionSelected(conexiones.filter(con => parseInt(con.nIdTipoActor) === (isSofiPay ? 2 : 1))[0] ?? conexionSFTPForm)
  }

  const showBitacora = (typeBitacora) => {
    setView("bitacora")
    setTypeBitacora(typeBitacora)
  }

  const closeBitacora = () => {
    setView(typeBitacora)
  }

  const returnArchivos = async (action) => {
    setView("archivos")
    setConexionSelected({})
    if(action === "reload") await startLoadConexiones()
  }

  const onChangeConexion = (nIdCfgConexion) => {
    const nIdCfg = parseInt(nIdCfgConexion)
    if (nIdCfg) {
      setConexionSelected(conexiones.find(con => parseInt(con.nIdCfgConexion) === nIdCfg))
    } else {
      setConexionSelected(conexionSFTPForm)
    }
  }

  const handleEditConexion = (flag) => {
    setConexionSelected({
      ...conexionSelected,
      sInnerPassword: conexionSelected.sPassword,
      isEditing: true
    })
  }

  const handleEditArchivo = (archivo) => {
    setArchivoEditing({ ...archivo })
  }

  const cancelEditArchivo = () => {
    setArchivoEditing({})
  }

  const searchEntity = async () => {
    if (isOpenConexionesSFTP.nIdCadena) {
      const dataCadena = cadenas.rows.find(
        (cadena) => cadena.nIdCadena === isOpenConexionesSFTP.nIdCadena
      );
      setSelectedEntity(dataCadena)

    } else {
      const dataEmisor = emisores.rows.find(
        (emisor) => emisor.nIdEmisor === isOpenConexionesSFTP.nIdEmisor
      );
      setSelectedEntity(dataEmisor)
    }
  };

  const onChangeConexionSelected = async (e) => {

    const switchInputsName = []

    const inputName = Object.keys(e).includes("target") ? e.target.name : Object.keys(e)[0]

    let innerForm = {
      ...conexionSelected, ...Object.keys(e).includes("target") ?
        { [e.target.name]: switchInputsName.includes(e.target.name) ? e.target.checked : e.target.value } :
        e,
    }

    if (inputName === "showPassword" && !conexionSelected.hasDecrypted) {
      const {
        ok,
        msg,
        sPassword
      } = await dispatch(verPassword(conexionSelected.sPassword, !!isOpenConexionesSFTP.nIdEmisor))

      if (!ok) {
        setOpenToast(true, msg)
        return
      }

      innerForm = {
        ...innerForm,
        hasDecrypted: true,
        sPassword
      }
    }

    if (inputName === "sInnerPassword" ) {
      innerForm = {
        ...innerForm,
        isPasswordChanged: true
      }
    }

    setConexionSelected(innerForm)
  };

  const onChangeArchivoEditing = (e) => {
    console.log(e)
    const inputName = e?.target?.name ?? Object.keys(e)[0]
    const inputValue = e?.target?.value ?? e[Object.keys(e)[0]]

    setArchivoEditing({
      ...archivoEditing,
      [inputName]: inputValue,
      ...(inputName === "nIdCfgConexion" ? { ...getConexionesDisponibles().find(con => con.nIdCfgConexion === parseInt(inputValue)), sCarpeta: "" } : {}),
      ...(inputValue === 0 ? conexionSFTPForm : {})
    })
  }

  const onSubmitArchivo = async () => {
    const resp = await dispatch(updateArchivo({...archivoEditing, nIdCadena: isOpenConexionesSFTP.nIdCadena, nIdEmisor: isOpenConexionesSFTP.nIdEmisor}, !!isOpenConexionesSFTP.nIdEmisor))
    setOpenToast(!resp.ok, resp.msg)
    if (resp.ok) {
      await startLoadConexiones();
      setArchivoEditing({})
    }
  };

  const onSubmitConexion = async () => {
    const resp = await dispatch(
      !conexionSelected.nIdCfgConexion ?
      insertConexion({ ...conexionSelected, nIdCadena: isOpenConexionesSFTP.nIdCadena, nIdEmisor: isOpenConexionesSFTP.nIdEmisor }, !!isOpenConexionesSFTP.nIdEmisor)
      : updateConexion({ ...conexionSelected, sPassword: conexionSelected.sInnerPassword ? conexionSelected.sInnerPassword : conexionSelected.sPassword }, !!isOpenConexionesSFTP.nIdEmisor)

    )
    setOpenToast(!resp.ok, resp.msg)
    if (resp.ok) {
      //console.log(conexiones.find((con) => parseInt(resp.nIdCfgConexion) === parseInt(con.nIdCfgConexion)), conexiones, resp.nIdCfgConexion)
      if (!conexionSelected.nIdCfgConexion) {
        returnArchivos()
      } {
        setConexionSelected({...conexionSelected, isEditing: false, hasDecrypted: conexionSelected.hasDecrypted || conexionSelected.isPasswordChanged, sPassword: conexionSelected.sInnerPassword, showPassword: false})
      }
      
      await startLoadConexiones();
    }
  };

  const onSubmitDeleteConexion = async () => {
    console.log(conexionSelected)
    const resp = await dispatch(deleteConexion({ ...conexionSelected }, !!isOpenConexionesSFTP.nIdEmisor))
    setOpenToast(!resp.ok, resp.msg)
    if (resp.ok) {
      returnArchivos()
      await startLoadConexiones();
    }
  };

  const getContactos = () => {
    let contactos = []
    if (selectedEntity.sNombreComercial) {
      contactos = JSON.parse(selectedEntity?.oContactos)?.filter((contacto) => {
        const procesos = JSON.parse(contacto?.sProcesos).map((proceso) => proceso.nIdProceso)
        return procesos.includes(5)
      })
    }

    return contactos
  }



  return {
    form,
    setForm,
    searchEntity,
    onSubmitArchivo,
    selectedEntity,
    handleEditConexion,
    type,
    getContactos,
    cgfSFTP,

    archivos,
    conexiones,
    handleEditArchivo,
    archivoEditing,
    cancelEditArchivo,

    view,
    conexionSelected,
    showConexiones,
    returnArchivos,
    onChangeConexion,
    onChangeConexionSelected,

    showBitacora,
    closeBitacora,
    typeBitacora,
    getOpcionesConexiones,
    onChangeArchivoEditing,
    onSubmitConexion,
    onSubmitDeleteConexion,
  };
};
