import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getToBase64 } from '../helpers/base64';
import { cadenasForm } from '../helpers/forms';
import { regexNums } from '../helpers/regexs';
import {
  startAltaCadenasDatosGenrales,
  startLoadCuentasContablesCadena,
  subirArchivoS3
} from '../slices/cadena/cadenaThunk';
import { useMenu } from './useMenu';
import { useModal } from './useModal';
import { useNavigate } from 'react-router-dom';
import {
  startLoadBancos,
  startLoadDocumentos,
  startLoadGiros,
  startLoadProcesos,
  startLoadRetenciones,
  startLoadIntegracion,
  startLoadTiposLiquidacion,
  startLoadColonias,
  startLoadDatosFacturacion,
  startLoadIdentificadorCNBV,
} from "../slices/catalogo/catalgoThunk";
import { cleanCadena, cleanEscalonado, cleanEscCantidadForm, setBancoSearched, setIvaOperativoSelected } from "../slices/cadena/cadenaSlice";
import { routes } from '../routes/routes';
import { typesSave } from '../helpers/types';
import { isValidDataEFR, isValidDatosGeneralesCadena } from '../helpers/forms-validate';
import { ivas } from '../helpers/data-iva';
import { startAltaEmisores } from '../slices/efr/efrThunk';
import { setCatalogoLoaded } from '../slices/catalogo/catalagoSlice';

import moment from 'moment';

const initialValuesBlock = {
  datosGenerales  : false,
  direcionContacto: true,
  datosBancarios  : true,
  datosOperativos : true,
  documentos      : true,
  notificacion    : true,
  liquidacion     : true,
};

const switchOptions = [
  'isEscaloando',
  'isActvieEmail',
  'isActiveCashOut',
  'isActiveCashIn',
  'isEscCantidad',
  'isActiveMGracia',
  'isIndirecto'
];
const escaloandosSwtich = [
  'isEscaloando',
  'isEscCantidad'
]
const facturacionOptions = [
  'cfdi',
  'claveProducto',
  'claveUnidad',
  'diasLiquidacion',
  'emails',
  'formaPago',
  'metodoPago',
  'periocidad',
];

const moveScroll = ( id ) => {
  document
    .getElementById(id)
    ?.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'nearest' });
}

export const useFormPage = ( { saveIn } ) => {

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { setNameSection } = useMenu();
  const [form, setForm] = useState(cadenasForm);
  const [cardBlock, setCardBlock] = useState(initialValuesBlock);
  const [completeDatosGeneral, setCompleteDatosGeneral] = useState(false);
  const [errorInputs, setErrorInputs] = useState({
    clabeInterBanc: false,
    razonSocial: false,
    refNumerica: false,
    refAlfaNumerica: false,
  })
  const { 
    contactos, 
    direccionSearched, 
    bancoSearched,
    escalonadoForms,
    emailsFactura,
    cuentaContableForm,
    facturacionForm,
    escCantidadForms,
    contablesCash,
  } = useSelector((state) => state.cadena);
  const { 
    giros, 
    documentos, 
    bancos = [], 
    retenciones, 
    comisiones, 
    integraciones,
    ciudades,
    estados,
    colonias,
    cfdis, 
    formasPago, 
    metodosPago, 
    regimensFiscal,
    unidades,
    productoServicios,
    emisores,
    identificadores = [],
    procesos
  } = useSelector((state) => state.catalogo);
  const { 
    openModalContact, 
    openModalListContact, 
    openMoldaViewPdf,
    setOpenToast,
    openModalFacturacion
  } = useModal();

  const onChangeInput = async (value) => {
    const inputName = Object.keys(value)[0]
    if ( 'clabeInterBanc' in value ) {
      const inputValue = value.clabeInterBanc;
      if ( inputValue !== '' && !regexNums.test(inputValue) ) return;
      setForm({ ...form, ...value });

      if ( inputValue !== '' && inputValue.length === 3 ) {
        await dispatch(startLoadBancos(inputValue));
      }

    } else if ( 'codigoPostal' in value ) {
      const inputValue = value.codigoPostal;
      if ( inputValue !== '' && !regexNums.test(inputValue) ) return;
      setForm({ ...form, ...value });

      if ( inputValue !== '' && inputValue.length === 5 ) {
        await dispatch(startLoadColonias(inputValue));
      }
    
    } else if ( 'diasLiquidacion' in value ) {
      setForm({ ...form, factura: { ...form.factura, ...value } });
      
    } else if ( 'razonSocial' in value ) {
      setForm({ 
        ...form, 
        ...value,
        beneficiario: value.razonSocial 
      });

    } else if (['refNumerica', 'refAlfaNumerica'].includes(inputName)) {
      const maxValue = inputName == 'refNumerica' ? 10 : 15;
      setErrorInputs({
        ...errorInputs,
        [inputName]: value[inputName].length > maxValue
      });
      
      if (value[inputName].length > maxValue) return;
      setForm({ ...form, ...value });

    } else if ( 'regimenSocietario' in value ) {
      const beneficiario = `${form.razonSocial} ${value.regimenSocietario}`; 
      setForm({ 
        ...form, 
        ...value,
        beneficiario: beneficiario 
      });

      // spaceAdded.current = true;

    } else {
      setForm({ ...form, ...value });
    }
  };

  const onChange = async (e) => {
    const inputName = e.target.name;
    const inputValue = e.target.value;

    if ( switchOptions.includes(inputName) ) {
      if (
        escaloandosSwtich.includes(inputName) &&
        (!e.target.checked || e.target.checked)
      ) {
        setForm({ ...form, [inputName]: e.target.checked });

        if ( saveIn === typesSave.EFR && e.target.checked ) {
          if ( inputName === 'isEscaloando' ) {
            setForm({ 
              ...form,
              [inputName]: e.target.checked, 
              isEscCantidad: false 
            });
            dispatch(cleanEscCantidadForm());
            
          } else if ( inputName === 'isEscCantidad' ) {
            setForm({ 
              ...form,
              [inputName]: e.target.checked, 
              isEscaloando: false 
            });
            dispatch(cleanEscalonado());
          }

        }
        
      }

      if ( inputName === 'isEscaloando' && !e.target.checked ) {
        dispatch(cleanEscalonado());

      } else if ( inputName === 'isActiveCashOut' || inputName === 'isActiveCashIn' ) {
        if ( form.isActiveCashOut && inputName === 'isActiveCashIn' && e.target.checked ) {
          setOpenToast(true, 'Se requiere alguna programación de liquidación');
          return;

        }
        if ( form.isActiveCashIn && inputName === 'isActiveCashOut' && e.target.checked ) {
          setOpenToast(true, 'Se requiere alguna programación de liquidación');
          return;

        }
        setForm({ ...form, [inputName]: e.target.checked });

      } else if ( inputName === 'isEscCantidad' && !e.target.checked ) {
        dispatch(cleanEscCantidadForm());

      } else if ( inputName === 'isActiveMGracia') {
        setForm({ ...form, nMesesGracia : moment().endOf('month'), [inputName]: e.target.checked });
      } else if(inputName === 'isIndirecto'){
        setForm({ ...form, nIdIntegrador :0, nPorcentajeComision: "", [inputName]: e.target.checked });
      }

    } else if ( !!documentos.find((doc) => doc.value === inputName) ) {
      if ( e.target.files && e.target.files[0].type !== 'application/pdf' ) return;
      setForm({
        ...form,
        documentos: {
          ...form.documentos,
          [inputName]: e.target.files ? e.target.files[0] : ''
        }
      });

    } else if ( facturacionOptions.includes(inputName) ) {
      setForm({ ...form, factura: { ...form.factura, [inputName]: inputValue } });

    } else if ( inputName === 'clabeInterBanc' ) {
      if (inputValue.length > 18) return;
      if (inputValue !== '' && !regexNums.test(inputValue)) return;
      const clabe = inputValue.length >= 3 ? inputValue.substring(0, 3) : ''
      if ( clabe !== '' ) {
        setBankSearch(clabe)
      }
      setForm({ ...form, [inputName]: inputValue });

      setErrorInputs({
        ...errorInputs,
        clabeInterBanc: inputValue.length !== 18 ? true : false
      });

    } else if ( inputName === 'ivaOperativo' ) {
      const valueIva = ivas.find((val) => val.value === inputValue)
      setForm({ 
        ...form, 
        [inputName]: inputValue,
        ivaOperativoVal:  valueIva ? valueIva.valDouble : 0
      });
      dispatch(setIvaOperativoSelected(valueIva ? valueIva.valDouble : 0))

    } else if ( inputName === 'razonSocial' ) {
      
      setErrorInputs({
        ...errorInputs,
        razonSocial: inputValue === ''
      });

      setForm({ ...form, [inputName]: inputValue });
    } else {
      setForm({ ...form, [inputName]: inputValue });
      
    }
  };

  const onChageDate = ( inputName, value ) => {
    setForm({ ...form, [inputName]: inputName == 'nMesesGracia' ? moment(new Date(value)).endOf('month').format('YYYY-MM-DD') : moment(new Date(value)).format('YYYY-MM-DD') });
  }

  const onBlur = ( e ) => { 
    const inputName = e.target.name;
    const inputValue = e.target.value;

    if ( inputName === 'clabeInterBanc' ) {
      if ( inputValue.length > 18 ) return;
      const clabe = inputValue.length >= 3 ? inputValue.substring(0, 3) : ''
      if ( clabe !== '' ) {
        setBankSearch(clabe)
      }

      setErrorInputs({
        ...errorInputs,
        clabeInterBanc: inputValue.length !== 18 ? true : false
      });
      
    }
  }

  const onBlurIdentificador = async ( e ) => { 
    const inputName = e.target.name;
    const inputValue = e.target.value;

    if ( inputName === 'razonSocial' ) {

      if ( inputValue !== '' ) {
        const { data } = await dispatch(
          startLoadIdentificadorCNBV({ sDescripcion: inputValue.toUpperCase().trim() })
        );
        const nIdIdentificador = data.length === 1 ? data[0].value : '';
        setForm({ ...form, nIdIdentificador });
      }
      setErrorInputs({
        ...errorInputs,
        razonSocial: inputValue === ''
      });
    }

   

  }

  const loadProcesos = async () => {
    await dispatch(startLoadProcesos(0, 1));
  };

  const clickAddContact = async () => {
    openModalContact({});
  };

  const clickOpenListContact = async () => {
    openModalListContact();
  };

  const clikcOpenViewPdf = async ( objetName ) => {
    const objectValue = form.documentos[objetName]
    if ( objectValue !== '' ) {
      const data = await getToBase64(objectValue);
      openMoldaViewPdf(data);
    }
  };

  const setDireccion = () => {
    setForm({
      ...form,
      colonia: direccionSearched.colonia,
      ciudad : direccionSearched.ciudad,
      estado : direccionSearched.estado
    })
  }

  const setBanco = () => {
    setForm({
      ...form,
      banco: bancoSearched
    })
  }

  const desBlockCardByName = (card) => {
    setCardBlock({
      ...cardBlock,
      ...card
    });
  };


  const blockCards = () => {
    setCardBlock(initialValuesBlock);
  };

  const onLoadDatosOperativos = async () => {
    await dispatch(startLoadRetenciones());
    await dispatch(startLoadIntegracion());
  }

  const isDataCorrect = async () => {
    const formResult = { 
      ...form, 
      contactos,
      escalonadoForms : form.isEscaloando ? escalonadoForms : [],
      factura         : { ...form.factura, emails: emailsFactura['cadena'] },
      cuentasContables: { ...cuentaContableForm }
    }
    const { 
      ok, 
      error, 
      idElement 
    } = isValidDatosGeneralesCadena({ form: formResult, documentos, procesos});

    if ( !ok ) {
      setOpenToast(true, error);
      moveScroll(`${idElement}-cadenas`);
    }

    return ok
    
  };

  const onSumbmit = async () => {
    const formResult = { 
      ...form, 
      contactos,
      escalonadoForms : form.isEscaloando ? escalonadoForms : [],
      factura         : { ...form.factura, emails: emailsFactura['cadena'] },
      cuentasContables: { ...cuentaContableForm }
    }
    const { 
      ok, 
      error, 
      idElement 
    } = isValidDatosGeneralesCadena({ form: formResult, documentos, procesos});

    if ( !ok ) {
      setOpenToast(true, error);
      moveScroll(`${idElement}-cadenas`);
      return;
    }
    const documents = [];
    
    let flag = false;
    for ( const [key, value] of Object.entries(formResult.documentos) ) {
      if( flag ) continue;
      const documentCat =  documentos.find(doc => doc.nIdDocumento === parseInt(key))
      let resp = await dispatch(subirArchivoS3(value,key,form.rfc,documentCat));
      if ( !resp.ok ) {
        setOpenToast(!resp.ok, resp.msg);
        flag = true;
      } else {
        documents.push({id: key, key : resp.key, name: documentCat ? documentCat.sAbrevDoc : value.name});
      }
    }
    if(flag) return;
    
    await saveForm({...formResult,documentos : documents});
    return;
  };

  const isDataCorrectEFR = async () => {
    const formResult = {
      ...form,
      contactos,
      escalonadoForms : form.isEscaloando ? escalonadoForms : [],
      escCantidadForms: form.isEscCantidad ? escCantidadForms: [],
      cuentasContables: { ...contablesCash },
      facturas: {
        iva          : facturacionForm.iva,
        regimenFiscal: facturacionForm.regimenFiscal,
        comision     : { ...facturacionForm.comision, emails: emailsFactura['comision'] },
        transferencia: {
          ...facturacionForm.transferencia,
          emails: emailsFactura['transferencia']
        },
        minimo: { ...facturacionForm.minimo, emails: emailsFactura['minimo'] }
      },
    };
    const { 
      ok, 
      error, 
      idElement
    } = isValidDataEFR({ form: formResult, documentos, procesos});

    if ( !ok ) {
      setOpenToast(true, error);
      moveScroll(`${idElement}-efr`);
    }
    
    return ok;
  };

  const onSubmitEFR = async () => { 
    const formResult = {
      ...form,
      contactos,
      escalonadoForms : form.isEscaloando ? escalonadoForms : [],
      escCantidadForms: form.isEscCantidad ? escCantidadForms: [],
      cuentasContables: { ...contablesCash },
      facturas: {
        iva          : facturacionForm.iva,
        regimenFiscal: facturacionForm.regimenFiscal,
        comision     : { ...facturacionForm.comision, emails: emailsFactura['comision'] },
        transferencia: {
          ...facturacionForm.transferencia,
          emails: emailsFactura['transferencia']
        },
        minimo: { ...facturacionForm.minimo, emails: emailsFactura['minimo'] }
      },
    };
    const { 
      ok, 
      error, 
      idElement
    } = isValidDataEFR({ form: formResult, documentos});

    if ( !ok ) {
      setOpenToast(true, error);
      moveScroll(`${idElement}-efr`);
      return;
    }

    const documents = [];
    
    let flag = false;
    for ( const [key, value] of Object.entries(formResult.documentos) ) {
      if( flag ) continue;
      const documentCat =  documentos.find(doc => doc.nIdDocumento === parseInt(key))
      let resp = await dispatch(subirArchivoS3(value,key,form.rfc,documentCat));
      if ( !resp.ok ) {
        setOpenToast(!resp.ok, resp.msg);
        flag = true;

      } else {
        documents.push({id: key, key : resp.key, name: documentCat ? documentCat.sAbrevDoc : value.name});

      }

    }
    if( flag ) return;
    await saveForm({ ...formResult, documentos: documents });

  }

  const saveForm = async ( formResult ) => {
    console.log("Formulario a enviar:",formResult)
    let succes = { navigateTo: '', msg: '' };

    if ( typesSave.CADENA === saveIn ) {
      const resp = await dispatch(startAltaCadenasDatosGenrales(formResult));
      if ( !resp.ok ) {
        setOpenToast(true, resp.msg);
        return;
      }
      succes.navigateTo = '/' + routes.VerCadenas;
      succes.msg = resp.msg;

    } else {  
      const resp = await dispatch(startAltaEmisores(formResult));
      if ( !resp.ok ) {
        setOpenToast(true, resp.msg);
        return;
      }
      succes.navigateTo = '/' + routes.VerEFR;
      succes.msg = resp.msg;

    }
    dispatch(setCatalogoLoaded(false))
    cleanForms();
    setForm(cadenasForm);
    setOpenToast(false, succes.msg);
    navigate(succes.navigateTo);


  }

  const cleanForms = () => { 
    dispatch(cleanCadena());
  }

  const loadIninit = async () => {
    await dispatch(startLoadGiros());
  };

  const loadFacturaTransferencia = async () => {
    await dispatch(startLoadDatosFacturacion({}));
  }

  const loadBancos = async ( idBanco = '000' ) => {
    await dispatch(startLoadBancos(idBanco));
  }

  const setBankSearch = ( idBanco ) => { 

    const bancoSearch = bancos.find(
      (banco) => banco.nIdBanco === parseInt(idBanco.toString())
    );

    dispatch(setBancoSearched(
      bancos.length > 0 && bancoSearch ? bancoSearch.value : '',
    ));

    if ( !!!bancoSearch ) {
      setOpenToast(true, 'No se encontro banco');
      return;
    }
    setBanco();
  
  }



  return {
    bancos,
    bancoSearched,
    cardBlock,
    cfdis, 
    ciudades,
    colonias,
    comisiones,
    completeDatosGeneral,
    contactos,
    direccionSearched,
    documentos,
    estados,
    emisores,
    errorInputs,
    form,
    formasPago, 
    giros,
    integraciones,
    identificadores,
    metodosPago, 
    regimensFiscal,
    retenciones,
    unidades,
    productoServicios,
    blockCards,
    clickAddContact,
    clickOpenListContact,
    clikcOpenViewPdf,
    cleanForms,
    desBlockCardByName,
    dispatch,
    loadIninit,
    loadFacturaTransferencia,
    loadBancos,
    loadProcesos,
    onBlur,
    onBlurIdentificador,
    onChange,
    onChangeInput,
    onChageDate,
    onSumbmit,
    isDataCorrect,
    isDataCorrectEFR,
    setBanco,
    setCompleteDatosGeneral,
    setDireccion,
    setNameSection,
    startLoadDocumentos,
    onLoadDatosOperativos,
    startLoadTiposLiquidacion,
    setFormCadena: setForm,
    startLoadCuentasContablesCadena,
    openModalFacturacion,
    onSubmitEFR,
  };
};
