import {
  Button,
  Collapse,
  Grid,
  Grow,
  InputAdornment,
  TextField,
  Tooltip,
  Typography,
  IconButton
} from '@mui/material';
import { Box } from '@mui/system';
import { useEffect } from 'react';
import { ModalBase } from './ModalBase';
import { useModalConfirmacion } from '../../hooks/useModalConfirmacion';
import { formatMoney } from '../../helpers/formatMoney';

import { cleanCadena, cleanContactosCadena } from '../../slices/cadena/cadenaSlice';
import { startLoadProcesos } from '../../slices/catalogo/catalgoThunk';
import { SearchInput } from '../form/SearchInput';
import { InfoText } from './modalDetail/InfoText';
import { CardConfirmationEFR } from './modalDetail/CardConfirmation';
import { ConfirmationTitle } from './modalDetail/ConfirmationTitle';
import { Loading } from '../Loading';
import { TextInput } from '../form/TextInput';
import { regexEmail, regexNumChart, regexNums, regexRFC, regexTelefono } from '../../helpers/regexs';
import {
  BlueSwitch,
  buttonContactStyle,
  buttonFileStyle,
  buttonSmallContactStyle,
  buttonViewPDF
} from '../../helpers/stylesMaterial';
import { SelectInput } from '../form/SelectInput';
import { ivas } from '../../helpers/data-iva';
import { IvaInput } from '../form/IvaInput';
import { EscalonadoForms } from '../cadena/escalonado/EscalonadoForms';
import { typesSave } from '../../helpers/types';
import { Liquidacion } from '../cadena/Liquidacion';

import { v4 as uuidv4 } from 'uuid';
import { Person, PersonAddAlt1, RemoveRedEye } from '@mui/icons-material';
import { onFormatCuentaInit } from '../../helpers/formatCuenta';
import { periodicidades } from '../../helpers/getData';
import { EmailsFacturaCadena } from '../cadena/emailFactura/EmailsFacturaCadena';
import moment from "moment";
import {
  DatePicker,
  DesktopDatePicker,
  LocalizationProvider,
} from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { Edit } from '@mui/icons-material';

const getTypeText = (id) => `${id}-confirmation`;

const gridStyle = () => ({
  width: '100%',
  padding: '3px',
  marginBottom: '10px'
});

const tipos = [
  { value: '1', text: 'Fiscal' },
  { value: '2', text: 'Oficina' },
  { value: '3', text: 'Sucursal' }
];

export const ModalConfirmacion = () => {
  const {
    bancos,
    bancoSearched,
    cadena,
    cfdis,
    ciudades,
    colonias,
    contactosStore,
    direccionSearched,
    documentos,
    errorInputs,
    estados,
    formasPago,
    giros,
    initialValuesEditSection,
    initialValuesErroInput,
    integraciones,
    isLoading,
    isOnEditSection,
    isOpenListContact,
    isOpenContact,
    metodosPago,
    modulesChanged,
    open,
    openEscalonado,
    productoServicios,
    regimensFiscal,
    retenciones,
    unidades,

    clikcOpenViewPdf,
    closeModal,
    dispatch,
    editSection,
    loadBancos,
    onBlur,
    onChange,
    onChangeInput,
    onLoadDatosFact,
    onLoadDatosOperativos,
    onLoadGiros,
    onLoadTiposLiquidacion,
    onSubmitCancelEdit,
    searchCadena,
    setBanco,
    setCadena,
    setDireccionSearched,
    setErrorInputs,
    setIsOnEditSection,
    setModulesChanged,
    setOpenEscalonado,
    startLoadDocumentos,
    clickAddContact,
    clickOpenListContact,
    isConfirmedAllSections,
    isConfirmed,
    handleConfirmSection,
    cadenaBackup
  } = useModalConfirmacion();

  useEffect(() => {
    setDireccionSearched();
    // eslint-disable-next-line
  }, [direccionSearched.colonia]);

  useEffect(() => {
    if (open) {
      searchCadena();
    }
    if (!open) {
      dispatch(cleanContactosCadena());
      dispatch(cleanCadena());
      setModulesChanged([]);
      setIsOnEditSection(initialValuesEditSection);
      setErrorInputs(initialValuesErroInput);
    }
    // eslint-disable-next-line
  }, [open]);

  useEffect(() => {
    setBanco();
    // eslint-disable-next-line
  }, [bancoSearched]);

  useEffect(() => {
    if (!isOpenListContact || !isOpenContact.open) {
      setCadena({
        ...cadena,
        oContactos: contactosStore
      });
    }
    // eslint-disable-next-line
  }, [isOpenListContact]);

  const formatDate = (date) => {
    if (date && date[date.length - 1] === "Z") {
      return date.slice(0, -1);
    } else {
      return date;
    }
  };
  const validateEstatus = (id) => {
    return id === 3;
  }
  const isBeforeDate = (currentDate, dateInit) => {
    return currentDate < dateInit;
  }
  const getCorrectDate = (date) => {
    return date[date.length - 1] === "Z" ? date.slice(0, -1) : date
  }
  const showWarningMessage = () => {
    const isChange = cadenaBackup.dInicioOperaciones != cadena.dInicioOperaciones || cadenaBackup.dPeriodoGracia != cadena.dPeriodoGracia;

    const diffDays = moment(getCorrectDate(cadena.dPeriodoGracia)).endOf('month').diff(moment(getCorrectDate(cadena.dInicioOperaciones)), 'days');

    const diffDaysBackup = moment(getCorrectDate(cadenaBackup.dPeriodoGracia)).endOf('month').diff(moment(), 'days');
    let result = { type: 'error', message: '' }


    if (diffDaysBackup <= 0 && !isChange) {
      result.message = 'El periodo de gracia ya venció.';
    } else if (diffDaysBackup > 0 && !isChange) {
      result.message = `Quedan ${diffDaysBackup} días para finalizar el periodo de gracia.`;
      result.type = 'warning';
    }


    if (diffDays <= 0 && isChange) {
      result.message = 'El periodo de gracia debe ser mayor a la fecha de inicio de operaciones.';
    } else if (diffDays < 30 && isChange) {
      result.type = 'warning';
      result.message = `Solo se asignarán ${diffDays} días de gracia.`;
    }
    return (<p style={{ fontSize: '12px', color: result.type == 'warning' ? "#E7B10A" : "#e53e3e" }}>{result.message}</p>)

  }
  return (
    <ModalBase open={open} disableLoading title="" style={{ width: '62vw', maxHeight: '90vh' }}>

      <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={"es-mx"}>
        <Box
          sx={{
            width: '100%',
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between'
          }}
        >
          {isLoading && <Loading inModal={true} />}
          <ConfirmationTitle
            title={cadena.sNombreComercial}
            typeView="Cadenas"
            id={cadena.nIdCadena}
            entity={cadena}
            changeStatusFunction={editSection}
          />
          <Box
            sx={{
              whiteSpace: 'nonwrap'
            }}
            className="confimacion-container"
          >
            <Grid container spacing={3} sx={{ ...gridStyle() }}>
              {/* ===================== DATOS GENERALES ================================= */}
              <CardConfirmationEFR
                title="Datos generales"
                id={getTypeText('datosGenerales')}
                editName="datosGenerales"
                isEditSection={isOnEditSection.datosGenerales}
                setIsEditSection={editSection}
                tipoPermisoEditar="Cadenas"
                viewBttnConfirm={validateEstatus(cadena.nIdEstatusCadena)}
                isConfirmSection={isConfirmed.datosGenerales}
                setIsConfirmSection={handleConfirmSection}
              >
                {!isOnEditSection.datosGenerales ? (
                  <Grid container spacing={3} item sx={{ ...gridStyle() }}>
                    <InfoText title="Razón Social" text={cadena.sRazonSocial} />
                    <InfoText title="Nombre Comercial" text={cadena.sNombreComercial} />
                    <InfoText title="RFC" text={cadena.sRFC} />
                    <InfoText title="Régimen de capital" text={cadena.sRegimenSocietario} />
                    <InfoText
                      title="Actividad económica"
                      text={cadena.sActividadEconomica}
                    />
                    <InfoText
                      title="Correo"
                      text={cadena.sMail}
                    />
                    <InfoText
                      title="Teléfono"
                      text={cadena.sTelefono}
                    />
                    <InfoText title="Giro" text={cadena.sGiro} />
                  </Grid>
                ) : (
                  <Grow in={isOnEditSection.datosGenerales}>
                    <Grid container spacing={3} item sx={{ ...gridStyle() }}>
                      <Grid item md={12} sm={12} xs={12}>
                        <TextInput
                          id="sRazonSocial"
                          name="sRazonSocial"
                          label="Razón Social"
                          value={cadena.sRazonSocial}
                          onChangeRoot={onChangeInput}
                          require
                          size="small"
                        />
                      </Grid>
                      <Grid item md={6} sm={6} xs={12}>
                        <TextInput
                          id="sNombreComercial"
                          name="sNombreComercial"
                          label="Nombre Comercial"
                          value={cadena.sNombreComercial}
                          onChangeRoot={onChangeInput}
                          require
                          size="small"
                        />
                      </Grid>
                      <Grid item md={6} sm={6} xs={12}>
                        <TextInput
                          id="sRFC"
                          name="sRFC"
                          label="RFC"
                          value={cadena.sRFC}
                          onChangeRoot={onChangeInput}
                          require
                          size="small"
                          expresionRegular={regexRFC}
                          caracteres="13"
                          erroMsg="RFC inválido"
                          disabled
                        />
                      </Grid>
                      <Grid item md={6} sm={6} xs={12}>
                        <TextInput
                          id="sRegimenSocietario"
                          name="sRegimenSocietario"
                          label="Régimen de capital"
                          value={cadena.sRegimenSocietario}
                          onChangeRoot={onChangeInput}
                          require
                          size="small"
                        />
                      </Grid>
                      <Grid item md={6} sm={6} xs={12}>
                        <TextInput
                          id="sActividadEconomica"
                          name="sActividadEconomica"
                          label="Actividad económica"
                          value={cadena.sActividadEconomica}
                          onChangeRoot={onChangeInput}
                          require
                          size="small"
                        />
                      </Grid>
                      <Grid item md={6} sm={6} xs={12}>
                        <TextInput
                          id="sMail"
                          name="sMail"
                          label="Correo"
                          value={cadena.sMail}
                          onChangeRoot={onChangeInput}
                          expresionRegular={regexEmail}
                          erroMsg="Correo inválido"
                          require
                          size="small"
                        />
                      </Grid>

                      <Grid item md={6} sm={6} xs={12}>
                        <TextInput
                          id="sTelefono"
                          name="sTelefono"
                          label="Teléfono"
                          value={cadena.sTelefono}
                          onChangeRoot={onChangeInput}
                          caracteres="10"
                          expresionRegular={regexTelefono}
                          erroMsg="Teléfono inválido"
                          require
                          size="small"
                        />
                      </Grid>
                      <Grid item md={6} sm={6} xs={12}>
                        <SearchInput
                          focused
                          size="small"
                          options={giros}
                          value={giros.length > 0 ? `${cadena.nIdGiro}` : ''}
                          name="nIdGiro"
                          label="Giro"
                          getOptionLabel="text"
                          getIndexLabel="value"
                          onChange={onChange}
                        />
                      </Grid>
                    </Grid>
                  </Grow>
                )}
              </CardConfirmationEFR>

              {/* ===================== DIRECIION FISCAL ================================= */}
              <CardConfirmationEFR
                title="Dirección fiscal y contactos"
                id={getTypeText('direccion')}
                editName="direccion"
                isEditSection={isOnEditSection.direccion}
                setIsEditSection={editSection}
                tipoPermisoEditar="Cadenas"
                viewBttnConfirm={validateEstatus(cadena.nIdEstatusCadena)}
                isConfirmSection={isConfirmed.direccion}
                setIsConfirmSection={handleConfirmSection}
              >
                {!isOnEditSection.direccion ? (
                  <Grid container spacing={3} item sx={{ ...gridStyle() }}>
                    <InfoText title="Calle" text={cadena.sCalle} />
                    <InfoText title="Número Exterior" text={cadena.nNumExterno} />
                    <InfoText title="Número Interior" text={cadena.sNumInterno ? cadena.sNumInterno : '--'} />
                    <InfoText title="Código Postal" text={cadena.nCodigoPostal} />
                    <InfoText title="Estado" text={cadena.sNombreEntidad} />
                    <InfoText title="Municipio" text={cadena.sNombreMunicipio} />
                    <InfoText title="Colonia" text={cadena.sNombreColonia} />
                    <InfoText title="Tipo" text={cadena.sTipoDireccion} />
                    <InfoText
                      title="Contactos: "
                      text={
                        cadena.oContactos.length > 0
                          ? ' ' + cadena.oContactos.length
                          : 'No hay contactos...'
                      }
                      display={cadena.oContactos.length > 0 ? 'row' : 'column'}
                      contacts={cadena.oContactos}
                      onClickFunction={clickOpenListContact}
                    />
                  </Grid>
                ) : (
                  <Grow in={isOnEditSection.direccion}>
                    <Grid container spacing={3} item sx={{ ...gridStyle() }}>
                      <Grid item md={4} sm={6} xs={6}>
                        <TextInput
                          id="sCalle"
                          name="sCalle"
                          label="Calle"
                          value={cadena.sCalle}
                          onChangeRoot={onChangeInput}
                          require
                          size="small"
                        />
                      </Grid>
                      <Grid item md={4} sm={6} xs={6}>
                        <TextInput
                          id="nNumExterno"
                          name="nNumExterno"
                          label="Número Exterior"
                          value={`${cadena.nNumExterno}`}
                          onChangeRoot={onChangeInput}
                          expresionRegular={regexNums}
                          size="small"
                          caracteres="6"
                          erroMsg="Número inválido"
                          require
                        />
                      </Grid>
                      <Grid item md={4} sm={6} xs={6}>
                        <TextInput
                          id="sNumInterno"
                          name="sNumInterno"
                          label="Número Interior"
                          value={cadena.sNumInterno}
                          onChangeRoot={onChangeInput}
                          expresionRegular={regexNumChart}
                          caracteres="6"
                          size="small"
                        />
                      </Grid>
                      <Grid item md={4} sm={6} xs={6}>
                        <TextInput
                          id="nCodigoPostal"
                          name="nCodigoPostal"
                          label="Código Postal"
                          value={`${cadena.nCodigoPostal}`}
                          onChangeRoot={onChangeInput}
                          expresionRegular={regexNums}
                          require
                          caracteres="5"
                          size="small"
                        />
                      </Grid>
                      <Grid item md={4} sm={6} xs={6}>
                        <SelectInput
                          id="sNombreEntidad"
                          name="sNombreEntidad"
                          onChange={onChange}
                          label="Estado"
                          data={estados}
                          value={`${cadena.sNombreEntidad}`}
                          size="small"
                        />
                      </Grid>
                      <Grid item md={4} sm={6} xs={6}>
                        <SelectInput
                          id="sNombreMunicipio"
                          name="sNombreMunicipio"
                          onChange={onChange}
                          label="Municipio"
                          data={ciudades}
                          value={`${cadena.sNombreMunicipio}`}
                          size="small"
                        />
                      </Grid>
                      <Grid item md={4} sm={6} xs={6}>
                        <SelectInput
                          id="nIdColonia"
                          name="nIdColonia"
                          onChange={onChange}
                          label="Colonia"
                          data={colonias}
                          value={`${cadena.nIdColonia}`}
                          size="small"
                        />
                      </Grid>
                      <Grid item md={4} sm={6} xs={6}>
                        <SelectInput
                          id="nIdTipoDireccion"
                          name="nIdTipoDireccion"
                          onChange={onChange}
                          label="Tipo"
                          data={tipos}
                          value={`${cadena.nIdTipoDireccion}`}
                          size="small"
                        />
                      </Grid>
                      <Grid item md={4} sm={12} xs={12}>
                        <div
                          style={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'space-between'
                          }}
                        >
                          <div style={{ width: '48%' }}>
                            <Tooltip title="Contactos" placement="bottom" arrow>
                              <Button
                                variant="outlined"
                                size="large"
                                sx={{ ...buttonContactStyle, height: '40px' }}
                                onClick={clickOpenListContact}
                              >
                                <Person />
                                {contactosStore.length > 0 && (
                                  <p className="cadenas__button-index">
                                    {contactosStore.length <= 10
                                      ? contactosStore.length
                                      : '10+'}
                                  </p>
                                )}
                              </Button>
                            </Tooltip>
                          </div>
                          <div style={{ width: '48%' }}>
                            <Tooltip title="Nuevo Contacto" placement="bottom" arrow>
                              <Button
                                variant="outlined"
                                size="large"
                                sx={{ ...buttonContactStyle, height: '40px' }}
                                onClick={clickAddContact}
                              >
                                <PersonAddAlt1 />
                              </Button>
                            </Tooltip>
                          </div>
                        </div>
                      </Grid>
                    </Grid>
                  </Grow>
                )}
              </CardConfirmationEFR>
              {/* ===================== DATOS BANCARIOS ================================= */}
              <CardConfirmationEFR
                title="Datos Bancarios"
                id={getTypeText('datosBancarios')}
                editName="datosBancarios"
                isEditSection={isOnEditSection.datosBancarios}
                setIsEditSection={editSection}
                tipoPermisoEditar="Cadenas"
                viewBttnConfirm={validateEstatus(cadena.nIdEstatusCadena)}
                isConfirmSection={isConfirmed.datosBancarios}
                setIsConfirmSection={handleConfirmSection}
              >
                {!isOnEditSection.datosBancarios ? (
                  <Grid container spacing={3} item sx={{ ...gridStyle() }}>
                    <InfoText title="Beneficiario" text={cadena.sBeneficiario} />
                    <InfoText
                      title="CLABE Interbancaria para depósitos de pagos"
                      text={cadena.sClabe}
                    />
                    <InfoText title="Banco" text={cadena.sNombreBanco} />
                    <InfoText title="Referencia numérica" text={cadena.sRefNumerica} />
                    <InfoText
                      title="Referencia alfanumérica"
                      text={cadena.sRefAlfanumerica}
                    />
                  </Grid>
                ) : (
                  <Grow in={isOnEditSection.datosBancarios}>
                    <Grid container spacing={3} item sx={{ ...gridStyle() }}>
                      <Grid item md={12} sm={12} xs={12}>
                        <TextInput
                          id="sBeneficiario"
                          name="sBeneficiario"
                          label="Beneficiario"
                          value={cadena.sBeneficiario}
                          onChangeRoot={onChangeInput}
                          require
                          size="small"
                        />
                      </Grid>
                      <Grid item md={6} sm={6} xs={12}>
                        <TextField
                          id="sClabe"
                          name="sClabe"
                          label="CLABE Interbancaria para depósitos de pagos"
                          value={cadena.sClabe}
                          onChange={onChange}
                          size="small"
                          variant="outlined"
                          onBlur={onBlur}
                          fullWidth
                          error={errorInputs.sClabe}
                          helperText={
                            errorInputs.sClabe ? 'CLABE Interbancaria inválida' : ''
                          }
                        />
                      </Grid>
                      <Grid item md={6} sm={6} xs={12}>
                        <SelectInput
                          id="nIdBanco"
                          name="nIdBanco"
                          onChange={onChange}
                          label="Bancos"
                          data={bancos}
                          value={cadena.nIdBanco ? `${cadena.nIdBanco}` : ''}
                          // require
                          size="small"
                        />
                      </Grid>
                      <Grid item md={6} sm={6} xs={12}>
                        <TextInput
                          id="sRefNumerica"
                          name="sRefNumerica"
                          label="Referencia numérica"
                          value={cadena.sRefNumerica}
                          onChangeRoot={onChangeInput}
                          size="small"
                          require
                        />
                      </Grid>
                      <Grid item md={6} sm={6} xs={12}>
                        <TextInput
                          id="sRefAlfanumerica"
                          name="sRefAlfanumerica"
                          label="Referencia alfanumérica"
                          value={cadena.sRefAlfanumerica}
                          onChangeRoot={onChangeInput}
                          size="small"
                          require
                        />
                      </Grid>
                    </Grid>
                  </Grow>
                )}
              </CardConfirmationEFR>
              {/* ===================== DATOS OPERATIVOS ===============================*/}
              <CardConfirmationEFR
                title="Datos operativos"
                id={getTypeText('datosOperativos')}
                editName="datosOperativos"
                isEditSection={isOnEditSection.datosOperativos}
                setIsEditSection={editSection}
                tipoPermisoEditar="Cadenas"
                viewBttnConfirm={validateEstatus(cadena.nIdEstatusCadena)}
                isConfirmSection={isConfirmed.datosOperativos}
                setIsConfirmSection={handleConfirmSection}
              >
                {!isOnEditSection.datosOperativos ? (
                  <Grid container spacing={3} item sx={{ ...gridStyle() }}>
                    <InfoText title="Retención" text={cadena.sRetencion} />
                    <InfoText
                      title="Monto máximo de recepción"
                      text={formatMoney(cadena.nMontoMaximo)}
                    />
                    <InfoText title="Integración" text={cadena.sIntegracion} />
                    <InfoText title="IVA" text={`${cadena.nIVA * 100}%`} />
                    <InfoText
                      title="Costo por transferencia"
                      text={formatMoney(cadena.nCostoTransferencia)}
                    />
                    {/* <InfoText
                      title="Pago escalonado"
                      text={
                        cadena.oEscalonadas.length > 0
                          ? 'Ver detalles...'
                          : 'No hay pagos escalonados'
                      }
                      escalonados={cadena.oEscalonadas.length > 0}
                      openEscalonados={() => setOpenEscalonado(!openEscalonado)}
                    />
 */}
                    {/* <InfoText
                      title="Periodo de gracia"
                      text={cadena.dPeriodoGracia && cadena.dPeriodoGracia !== '0000-00-00' ? moment(formatDate(cadena.dPeriodoGracia))
                        .locale("es")
                        .format("MMMM YYYY") : 'Sin periodo de gracia'}
                    /> */}
                    <InfoText
                      title="Fecha de inicio de operaciones"
                      text={moment(formatDate(cadena.dInicioOperaciones)).locale("es").format(
                        "YYYY-MM-DD"
                      )}
                    />
                    <Grid item md={12} sm={12} xs={12}>
                      <Escalonados
                        escalonados={cadena.oEscalonadas}
                        open={openEscalonado}
                      />
                    </Grid>
                  </Grid>
                ) : (
                  <Grow in={isOnEditSection.datosOperativos}>
                    <Grid container spacing={3} item sx={{ ...gridStyle() }}>
                      <Grid item md={6} sm={6} xs={12}>
                        <SelectInput
                          id="nIdRetencion"
                          name="nIdRetencion"
                          onChange={onChange}
                          label="Retención"
                          data={retenciones}
                          value={retenciones.length > 0 ? `${cadena.nIdRetencion}` : ''}
                          size="small"
                        />
                      </Grid>
                      <Grid item md={6} sm={6} xs={12}>
                        <TextInput
                          id="nMontoMaximo"
                          name="nMontoMaximo"
                          label="Monto máximo de recepción"
                          value={`${cadena.nMontoMaximo}`}
                          onChangeRoot={onChangeInput}
                          type="number"
                          size="small"
                        />
                      </Grid>
                      <Grid item md={6} sm={6} xs={12}>
                        <SelectInput
                          id="nIdIntegracion"
                          name="nIdIntegracion"
                          onChange={onChange}
                          label="Integración"
                          data={integraciones}
                          value={integraciones.length > 0 ? `${cadena.nIdIntegracion}` : ''}
                          size="small"
                        />
                      </Grid>
                      <Grid item md={6} sm={6} xs={6}>
                        <IvaInput
                          id="nCostoTransferencia"
                          name="nCostoTransferencia"
                          label="Costo por transferencia"
                          value={`${cadena.nCostoTransferencia}`}
                          onChangeRoot={onChangeInput}
                          variant="outlined"
                          fullWidth
                          type="number"
                          size="small"
                          ivaValue={cadena.nIVA !== '' ? cadena.nIVA * 100 : 0.16}
                          startAdornment={
                            <InputAdornment position="start">$</InputAdornment>
                          }
                        />
                      </Grid>
                      <Grid item md={6} sm={6} xs={12}>
                        <SelectInput
                          id="nIdIVA"
                          name="nIdIVA"
                          onChange={onChange}
                          label="IVA"
                          data={ivas}
                          value={`${cadena.nIdIVA}`}
                          size="small"
                        />
                      </Grid>
                      <Grid item md={6} sm={6} xs={12}>
                        <DesktopDatePicker
                          shouldDisableDate={(date) => isBeforeDate(new Date(date), new Date(cadenaBackup.dInicioOperaciones))}
                          label="Fecha de inicio de operaciones"
                          inputFormat="YYYY-MM-DD"
                          size="small"
                          renderInput={(params) => <TextField {...params} size="small" fullWidth />}
                          value={formatDate(cadena.dInicioOperaciones)}
                          disabled={moment(formatDate(cadenaBackup.dInicioOperaciones)).isBefore(moment())}
                          onChange={(e) =>
                            onChange({
                              target: {
                                name: "dInicioOperaciones",
                                value: moment(e['$d']).format('YYYY-MM-DD')
                              }
                            })
                          }
                        />
                      </Grid>


                      {false && <Grid item md={12} sm={12} xs={12}>
                        <div
                          className="cadenas__blue-switch"
                          style={{ marginTop: "15px" }}
                        >
                          <BlueSwitch
                            onChange={onChange}
                            inputProps={{ "aria-label": "controlled" }}
                            name="isActiveMGracia"
                            checked={cadena.isActiveMGracia}
                            value={cadena.isActiveMGracia}
                          />
                          <p>¿Se activará periodo de gracia?</p>
                        </div>
                        <Collapse in={cadena.isActiveMGracia}>
                          <div
                            className="cadenas__inputs-container"
                            style={{ marginTop: "15px" }}
                          >
                            <div style={{ display: 'flex', flexDirection: 'column' }} >
                              <DatePicker
                                shouldDisableYear={(date) => {
                                  const currentDate = new Date(date);
                                  return isBeforeDate(currentDate.setDate(currentDate.getDate() + 365), new Date(cadenaBackup.dPeriodoGracia))
                                }
                                }
                                shouldDisableMonth={(date) => {
                                  const currentDate = new Date(date);
                                  return isBeforeDate(currentDate.setDate(currentDate.getDate() + 31), new Date(cadenaBackup.dPeriodoGracia))
                                }}
                                label="Periodo de gracia"
                                openTo="year"
                                views={["year", "month"]}
                                value={cadena.dPeriodoGracia}
                                onChange={(e) =>
                                  onChange({
                                    target: {
                                      name: "dPeriodoGracia",
                                      value: moment(e['$d']).endOf('month').format('YYYY-MM-DD')
                                    }
                                  })
                                }
                                renderInput={(params) => (
                                  <TextField {...params} fullWidth />
                                )}
                              />
                              {showWarningMessage()}
                            </div>
                          </div>
                        </Collapse>
                      </Grid>}
                      {false &&
                        <Grid item md={12} sm={12} xs={12}>
                          <div className="cadenas__blue-switch" style={{ marginTop: '15px' }}>
                            <BlueSwitch
                              onChange={onChange}
                              inputProps={{ 'aria-label': 'controlled' }}
                              name="isActiveEscalonado"
                              checked={cadena.isActiveEscalonado}
                              value={cadena.isActiveEscalonado}
                            />
                            <p>¿Se pagará escalonado por operaciones?</p>
                          </div>
                          <Collapse in={cadena.isActiveEscalonado}>
                            <EscalonadoForms saveIn={typesSave.CADENA} />
                          </Collapse>
                        </Grid>
                      }
                    </Grid>
                  </Grow>
                )}
              </CardConfirmationEFR>
              {/* ===================== LIQUIDACION CASHIN ===============================*/}
              <CardConfirmationEFR
                title="Programación liquidación (CASH IN)"
                id={getTypeText('cashIn')}
                editName="cashIn"
                isEditSection={isOnEditSection.cashIn}
                setIsEditSection={editSection}
                tipoPermisoEditar="Cadenas"
                viewBttnConfirm={validateEstatus(cadena.nIdEstatusCadena)}
                isConfirmSection={isConfirmed.cashIn}
                setIsConfirmSection={handleConfirmSection}
              >
                {!isOnEditSection.cashIn ? (
                  <Grid container spacing={3} item sx={{ ...gridStyle() }}>
                    {cadena.oLiquidacionCashIn.length > 0 ? (
                      <LiquidacionDias
                        liquidaciones={cadena.oLiquidacionCashIn}
                        key={'liquidacion-cashIn-2'}
                        type="cashIn"
                      />
                    ) : (
                      <InfoText
                        title=""
                        text="No hay liquidaciones programadas..."
                        md={12}
                        sm={12}
                      />
                    )}
                  </Grid>
                ) : (
                  <Grow in={isOnEditSection.cashIn}>
                    <Grid container spacing={3} item sx={{ ...gridStyle() }}>
                      <Grid item md={12} sm={12} xs={12}>
                        <div className="cadenas__blue-switch" style={{ marginTop: '15px' }}>
                          <BlueSwitch
                            onChange={onChange}
                            inputProps={{ 'aria-label': 'controlled' }}
                            name="isActiveCashIn"
                            checked={cadena.isActiveCashIn}
                            value={cadena.isActiveCashIn}
                          />
                          <p>{cadena.isActiveCashIn ? 'Sin CASH IN' : 'Con CASH IN'}</p>
                        </div>
                        <Collapse in={!cadena.isActiveCashIn}>
                          <Liquidacion
                            onCadenaChange={setCadena}
                            cadena={cadena}
                            tipoMovimiento="liquidacionCashIn"
                            key="liquidacionCashInCadenaDetail"
                            defaultValues={cadena.liquidacionCashIn}
                            isCadena={true}
                          />
                        </Collapse>
                      </Grid>
                    </Grid>
                  </Grow>
                )}
              </CardConfirmationEFR>
              {/* ===================== LIQUIDACION CASHOUT ===============================*/}
              <CardConfirmationEFR
                title="Programación liquidación (CASH OUT)"
                id={getTypeText('cashOut')}
                editName="cashOut"
                isEditSection={isOnEditSection.cashOut}
                setIsEditSection={editSection}
                tipoPermisoEditar="Cadenas"

                viewBttnConfirm={validateEstatus(cadena.nIdEstatusCadena)}
                isConfirmSection={isConfirmed.cashOut}
                setIsConfirmSection={handleConfirmSection}
              >
                {!isOnEditSection.cashOut ? (
                  <Grid container spacing={3} item sx={{ ...gridStyle() }}>
                    {cadena.oLiquidacionCashOut.length > 0 ? (
                      <LiquidacionDias
                        liquidaciones={cadena.oLiquidacionCashOut}
                        key={'liquidacion-cashOut-1'}
                        type="cashOut"
                      />
                    ) : (
                      <InfoText
                        title=""
                        text="No hay liquidaciones programadas..."
                        md={12}
                        sm={12}
                      />
                    )}
                  </Grid>
                ) : (
                  <Grow in={isOnEditSection.cashOut}>
                    <Grid container spacing={3} item sx={{ ...gridStyle() }}>
                      <Grid item md={12} sm={12} xs={12}>
                        <div className="cadenas__blue-switch" style={{ marginTop: '15px' }}>
                          <BlueSwitch
                            onChange={onChange}
                            inputProps={{ 'aria-label': 'controlled' }}
                            name="isActiveCashOut"
                            checked={cadena.isActiveCashOut}
                            value={cadena.isActiveCashOut}
                          />
                          <p>{cadena.isActiveCashOut ? 'Sin CASH OUT' : 'Con CASH OUT'}</p>
                        </div>
                        <Collapse in={!cadena.isActiveCashOut}>
                          <Liquidacion
                            onCadenaChange={setCadena}
                            cadena={cadena}
                            tipoMovimiento="liquidacionCashOut"
                            key="liquidacionCashOutCadenaDetail"
                            defaultValues={cadena.liquidacionCashOut}
                            isCadena={true}
                          />
                        </Collapse>
                      </Grid>
                    </Grid>
                  </Grow>
                )}
              </CardConfirmationEFR>
              {/* ===================== DOCUMENTOS ===============================*/}
              <CardConfirmationEFR
                title="Documentos"
                id={getTypeText('documentos')}
                editName="documentos"
                isEditSection={isOnEditSection.documentos}
                setIsEditSection={editSection}
                tipoPermisoEditar="Cadenas"
                viewBttnConfirm={validateEstatus(cadena.nIdEstatusCadena)}
                isConfirmSection={isConfirmed.documentos}
                setIsConfirmSection={handleConfirmSection}
              >
                {!isOnEditSection.documentos ? (
                  <Grid container spacing={3} item sx={{ ...gridStyle() }}>
                    {cadena.oDocumentos.length > 0 ? (
                      cadena.oDocumentos.map((documento) => (
                        <InfoText
                          title={documento.sNombreDocumento}
                          text={documento.name}
                          keyURL={documento.key}
                          customText={<>
                            Ver archivo...
                          </>}
                          key={uuidv4()}
                        />
                      ))
                    ) : (
                      <InfoText title="" text="No hay documentos..." md={12} sm={12} />
                    )}
                  </Grid>
                ) : (
                  <Grow in={isOnEditSection.documentos}>
                    <Grid container spacing={3} item sx={{ ...gridStyle() }}>
                      <Grid item md={12} sm={12} xs={12}>
                        {documentos.filter(doc => doc.nIdEstatus === 1).map((doc) => (
                          <RenderDocumento
                            key={doc.value}
                            clickViewPdf={clikcOpenViewPdf}
                            form={cadena}
                            onChange={onChange}
                            documento={doc.text}
                            name={`${doc.value}`}
                            oDocumentos={cadena.oDocumentos}
                          />
                        ))}
                      </Grid>
                    </Grid>
                  </Grow>
                )}
              </CardConfirmationEFR>
              {/* ===================== FACTURA ===============================*/}
              <CardConfirmationEFR
                title="Factura comisión por transferencia"
                id={getTypeText('datosFacturacion')}
                editName="datosFacturacion"
                isEditSection={isOnEditSection.datosFacturacion}
                setIsEditSection={editSection}
                tipoPermisoEditar="Cadenas"
                viewBttnConfirm={validateEstatus(cadena.nIdEstatusCadena)}
                isConfirmSection={isConfirmed.datosFacturacion}
                setIsConfirmSection={handleConfirmSection}
              >
                {!isOnEditSection.datosFacturacion ? (
                  <Grid container spacing={3} item sx={{ ...gridStyle() }}>
                    <InfoText title="IVA" text={`${cadena.nIVA * 100}%`} />
                    <InfoText title="Régimen fiscal" text={cadena.sNombreRegimenFiscal} />
                    <InfoText title="Uso del CFDI" text={cadena.sNombreUsoCFDI} />
                    <InfoText title="Forma de pago" text={cadena.sNombreFormaPago} />
                    <InfoText title="Método de pago" text={cadena.sNombreMetodoPago} />
                    <InfoText
                      title="Clave del producto"
                      text={cadena.sNombreClaveProductoServicio}
                    />
                    <InfoText title="Clave de unidad" text={cadena.sNombreUnidad} />
                    <InfoText
                      title="Periodicidad de facturación"
                      text={cadena.sNombrePeriodoFacturacion}
                    />
                    <InfoText title="Días para liquidar factura" text={cadena.nDiaPago} />
                  </Grid>
                ) : (
                  <Grow in={isOnEditSection.datosFacturacion}>
                    <Grid container spacing={2} item sx={{ ...gridStyle() }}>
                      <Grid item md={6} sm={6} xs={6}>
                        <SelectInput
                          id="nIdIVA"
                          name="nIdIVA"
                          onChange={onChange}
                          label="IVA"
                          data={ivas}
                          value={`${cadena.nIdIVA}`}
                          size="small"
                          disabled
                        />
                      </Grid>
                      <Grid item md={6} sm={6} xs={6}>
                        <SelectInput
                          id="sRegimenFiscal"
                          name="sRegimenFiscal"
                          onChange={onChange}
                          label="Régimen fiscal"
                          data={regimensFiscal}
                          value={
                            regimensFiscal.length > 0 ? `${cadena.sRegimenFiscal}` : ''
                          }
                          size="small"
                        />
                      </Grid>
                      <Grid item md={4} sm={6} xs={12}>
                        <SelectInput
                          id="sUsoCFDI"
                          name="sUsoCFDI"
                          onChange={onChange}
                          label="Uso del CFDI"
                          data={cfdis}
                          value={cfdis.length > 0 ? cadena.sUsoCFDI : ''}
                          size="small"
                        />
                      </Grid>
                      <Grid item md={4} sm={6} xs={12}>
                        <SelectInput
                          id="sFormaPago"
                          name="sFormaPago"
                          onChange={onChange}
                          label="Forma de pago"
                          data={formasPago}
                          value={formasPago.length > 0 ? `${cadena.sFormaPago}` : ''}
                          size="small"
                        />
                      </Grid>
                      <Grid item md={4} sm={6} xs={12}>
                        <SelectInput
                          id="sMetodoPago"
                          name="sMetodoPago"
                          onChange={onChange}
                          label="Método de pago"
                          data={metodosPago}
                          value={metodosPago.length > 0 ? cadena.sMetodoPago : ''}
                          size="small"
                        />
                      </Grid>
                      <Grid item md={6} sm={6} xs={12}>
                        <SearchInput
                          options={productoServicios}
                          value={cadena.sClaveProductoServicio}
                          name="sClaveProductoServicio"
                          label="Clave del producto"
                          getOptionLabel="text"
                          getIndexLabel="value"
                          onChange={onChange}
                          size="small"
                        />
                      </Grid>
                      <Grid item md={6} sm={6} xs={12}>
                        <SearchInput
                          options={unidades}
                          value={cadena.sUnidad}
                          name="sUnidad"
                          label="Clave de unidad"
                          getOptionLabel="text"
                          getIndexLabel="value"
                          onChange={onChange}
                          size="small"
                        />
                      </Grid>
                      <Grid item md={6} sm={6} xs={12}>
                        <SelectInput
                          id="nPeriodoFacturacion"
                          name="nPeriodoFacturacion"
                          onChange={onChange}
                          label="Periodicidad de facturación"
                          data={periodicidades}
                          value={cadena.nPeriodoFacturacion}
                          disabled
                          size="small"
                        />
                      </Grid>

                      <Grid item md={6} sm={6} xs={12}>
                        <TextInput
                          id="nDiaPago"
                          name="nDiaPago"
                          label="Días para liquidar factura"
                          value={`${cadena.nDiaPago}`}
                          onChangeRoot={onChangeInput}
                          type="number"
                          size="small"
                          require
                        />
                      </Grid>
                      <Grid item md={12} sm={12} xs={12}>
                        <EmailsFacturaCadena reference={'cadena'} sizeSmall={true} />
                      </Grid>
                    </Grid>
                  </Grow>
                )}
              </CardConfirmationEFR>
              {/* ===================== CUENTAS CONTABLES ===============================*/}
              {!cadena.isActiveCashIn &&
                <CardConfirmationEFR
                  title="Cuentas contables (CASH IN)"
                  id={getTypeText('cuentaContableIn-cadena')}
                  editName="cuentaContableIn"
                  isEditSection={isOnEditSection.cuentaContableIn}
                  setIsEditSection={editSection}
                  tipoPermisoEditar="Cadenas"
                  viewBttnEdit={false}
                  viewBttnConfirm={validateEstatus(cadena.nIdEstatusCadena)}
                  isConfirmSection={isConfirmed.cuentaContableIn}
                  setIsConfirmSection={handleConfirmSection}
                >
                  <Grid container spacing={3} item sx={{ ...gridStyle() }}>
                    {cadena?.oCuentasContables?.map(cuenta => {
                      if (cuenta.nFlujoImporte === 1) {
                        return (
                          <InfoText title={cuenta.sNombre} text={onFormatCuentaInit(cuenta.nCuenta)} />
                        )
                      }
                    })}

                  </Grid>
                </CardConfirmationEFR>
              }
              {!cadena.isActiveCashOut &&
                <CardConfirmationEFR
                  title="Cuentas contables (CASH OUT)"
                  id={getTypeText('cuentaContableOut-cadena')}
                  editName="cuentaContableOut"
                  isEditSection={isOnEditSection.cuentaContableOut}
                  setIsEditSection={editSection}
                  tipoPermisoEditar="Cadenas"
                  viewBttnEdit={false}
                  viewBttnConfirm={validateEstatus(cadena.nIdEstatusCadena)}
                  isConfirmSection={isConfirmed.cuentaContableOut}
                  setIsConfirmSection={handleConfirmSection}
                >
                  <Grid container spacing={3} item sx={{ ...gridStyle() }}>
                    {cadena?.oCuentasContables?.map(cuenta => {
                      if (cuenta.nFlujoImporte === 0) {
                        return (
                          <InfoText title={cuenta.sNombre} text={onFormatCuentaInit(cuenta.nCuenta)} />
                        )
                      }
                    })}

                  </Grid>
                </CardConfirmationEFR>
              }
            </Grid>
          </Box>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              width: '99%',
              justifyContent: 'end',
              alignItems: 'center',
              height: '10%'
            }}
          >
            <Button
              onClick={() =>
                modulesChanged.length > 0 ? onSubmitCancelEdit() : closeModal()
              }
              sx={{
                ...buttonSmallContactStyle,
                border: '1px solid transparent',
                width: '15%',
                height: '30px',
                transition: '0.3 ease all',
                '&:hover': {
                  color: '#004F80',
                  border: '1px solid transparent'
                }
              }}
            >
              {modulesChanged.length > 0 ? 'cancelar ' : 'cerrar'}
            </Button>
            {cadena.nIdEstatusCadena === 3 &&
              <Button
                onClick={() => editSection({ section: "estatusCadena", saveSection: true })}
                variant="contained"
                disabled={!isConfirmedAllSections()}
              >
                Activar comisionista
              </Button>
            }
          </Box>
        </Box>
      </LocalizationProvider>
    </ModalBase>
  );
};

const LiquidacionDias = ({ liquidaciones = [], type }) => {
  return (
    <Box sx={{ width: '90%', margin: '0px auto' }}>
      <table className="dashboard-table modal">
        <thead>
          <tr>
            <th style={{ color: '#00609C', fontSize: '15px' }}>Día de corte</th>
            <th style={{ color: '#00609C', fontSize: '15px' }}>Se paga el día</th>
          </tr>
        </thead>
        <tbody>
          {liquidaciones.map((liq, i) => (
            <tr key={i + type + liq.nDiaPago + liq.nDiaCorte}>
              <td style={{ color: '#828282', fontSize: '14px' }}>{liq.corte}</td>
              <td style={{ color: '#828282', fontSize: '14px' }}>{liq.pago}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </Box>
  );
};
const Escalonados = ({ escalonados = [], open = false }) => {
  const styleTh = {
    color: '#00609C',
    fontSize: '15px',
    fontWeight: '400'
  };
  return (
    <Collapse in={open}>
      <table className="dashboard-table modal">
        <thead>
          <tr>
            <th style={styleTh}>Monto mínimo</th>
            <th style={styleTh}>Monto máximo</th>
            <th style={styleTh}>Comisión monto fijo</th>
            <th style={styleTh}>Comisión porcentaje %</th>
          </tr>
        </thead>
        <tbody>
          {escalonados.map((esc, i) => (
            <tr key={i}>
              <td style={{ color: '#828282', fontSize: '14px' }}>
                {formatMoney(esc.nMontoMin.toString())}
              </td>
              <td style={{ color: '#828282', fontSize: '14px' }}>
                {formatMoney(esc.nMontoMax.toString())}
              </td>
              <td style={{ color: '#828282', fontSize: '14px' }}>
                {formatMoney(esc.nCosto.toString())}
              </td>
              <td style={{ color: '#828282', fontSize: '14px' }}>
                {formatMoney(esc.nPorcentajeCom.toString())}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </Collapse>
  );
};

const RenderDocumento = ({ documento, onChange, name, form, clickViewPdf, oDocumentos = [] }) => {

  const currentDocument = oDocumentos.find(document => parseInt(document.id) === parseInt(name))
  const uploadedDocument = form.documentos[name]
  const documentName = uploadedDocument ? uploadedDocument?.name : currentDocument?.name

  return (
    <div className="flex-row space-between">
      <div
        className="cadenas__inputs-container cadenas__inputs-container-doc"
        style={{ width: "100%", marginBottom: "10px" }}
      >
        <Button
          variant="contained"
          component="label"
          sx={buttonFileStyle}
          className="card__btn-file"
        >
          {documento}
        </Button>
        <Typography
          variant="p"
          component="p"
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            overflow: "hidden",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
          }}
        >
          {documentName ? (documentName + (!documentName.includes(".") ? ".pdf" : "")) : "Archivo no cargado"}
        </Typography>

        <div className="flex-row center">
          <RenderButtonPDF
            openPdf={clickViewPdf}
            value={uploadedDocument ? uploadedDocument : currentDocument?.key}
            disabled={!documentName}
          />
          <IconButton
            color={"primary"}
            component="label"
          >
            <Edit />
            <input
              hidden
              accept="application/pdf"
              multiple
              type="file"
              name={name}
              onChange={onChange}
            />
          </IconButton>

        </div>
      </div>
    </div>
  );
};

const RenderButtonPDF = ({ openPdf, value, disabled = false }) => {
  const onClick = async () => {
    await openPdf(value);
  };
  return (
    <>
      {value && value !== "" && (
        <Button
          variant="outlined"
          component="label"
          onClick={onClick}
          sx={buttonViewPDF}
          disabled={disabled}
        >
          <RemoveRedEye />
        </Button>
      )}
    </>
  );
};
