import { Box, Button, Grid, IconButton, Typography } from '@mui/material';

// import { BlueSwitch } from '../helpers/stylesMaterial';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { CircularProgress } from '@mui/material';
import { Add, Cable, Cancel, CompareArrows, Delete, Edit, History, Visibility, VisibilityOff } from '@mui/icons-material';
import { useModal } from '../../hooks/useModal';
import { useModalConciliacion } from '../../hooks/useModalConciliacion';
import { SelectInput } from '../form/SelectInput';
import { TextInput } from '../form/TextInput';
import { useAuthPage } from '../../hooks/useAuthPage';
import { ModalBase } from './ModalBase';
import CustomizedTabs from '../CustomizedTabs';
import moment from 'moment';
import { ConfirmDialog } from './ConfirmDialog';


export const ModalConexionSFTP = (props) => {

  const {
    isOpen,
    onClose,
    selectedEntity,
    showBitacora,
    conexiones,
    conexionSelected,
    onChangeConexion,
    handleEditConexion,
    onChangeInput,
    onSubmit,
    onDelete
  } = props

  const { setAllowComponent } = useAuthPage();
  const [confirmDialog, setConfirmDialog] = useState(false)

  const handleOpenDialog = () => {
    setConfirmDialog(true)
  }

  const handleConfirmDialog = () => {
    handleCloseDialog()
    onDelete()
  }

  const handleCloseDialog = () => {
    setConfirmDialog(false)
  }

  const isActiveSave = () => {
    if (
      (
        conexionSelected.sHost &&
        conexionSelected.nPort &&
        conexionSelected.sUser &&
        conexionSelected.sPassword &&
        conexionSelected.sNombreConexion &&
        (conexionSelected.isEditing || !conexionSelected.nIdCfgConexion))
    ) {
      return false;
    } else {
      return true;
    }
  };

  return (
    <>
      <ConfirmDialog
        isOpenDialog={confirmDialog}
        onCloseDialog={handleCloseDialog}
        onConfimDialog={handleConfirmDialog}
        message={"La conexión del socio comercial se eliminará. ¿Estas seguro/a que quieres continuar?"}
        textButtonConfirm="Sí, si estoy seguro/a."
      />
      <ModalBase open={isOpen} onClose={onClose} style={{ width: "700px" }}>
        <TitleModal title={selectedEntity?.sNombreComercial} extraButton={<Button
          variant="contained"
          color="primary"
          onClick={() => showBitacora("conexiones")}
          startIcon={<History />}
        >
          Bitácora de conexiones
        </Button>} />
        <Grid container >
          <Grid item xs={12} container justifyContent={"space-between"} paddingLeft={1}>
            <CustomizedTabs
              tabs={[
                ...conexiones.filter(con => parseInt(con.nIdTipoActor) === 1).map(con => ({ label: con.sNombreConexion, value: con.nIdCfgConexion })),
                ...(setAllowComponent("modalConexionesSFTP", 'altaConexion') ? [{ label: "Agregar conexión SFTP", value: 0, icon: <Add /> }] : [])

              ]}
              value={conexionSelected.nIdCfgConexion ?? 0}
              onChange={onChangeConexion}
            />
          </Grid>
          <Grid item xs={12}>
            <Grid container padding={2} sx={{ background: "#f9f9f9", borderRadius: 2, marginLeft: 0, width: "100%", marginTop: 0, paddingLeft: 0 }} spacing={2}>
              {conexionSelected.nIdCfgConexion ?
                <Grid item xs={12} container justifyContent={"space-between"} mb={2} alignItems={"center"}>
                  <Grid item xs={6} >
                    <div>
                      <Typography variant="h6" fontSize={14} >{moment(conexionSelected.dFecMovimiento).isSame(moment(conexionSelected.dFecRegistro)) ? "Conexión creada" : "Última modificación"}</Typography>
                      <Typography variant="p" fontSize={14} >Por <b>{conexionSelected.sUsuario}</b> ({moment(conexionSelected.dFecMovimiento).format("YYYY-MM-DD HH:mm:ss")})</Typography>
                    </div>
                  </Grid>
                  <Grid item xs={6} container justifyContent={"right"}>
                    {conexionSelected.isEditing ?
                      <Button
                        color="primary"
                        onClick={() => { onChangeInput({ isEditing: false }) }}
                        startIcon={<Cancel />}
                        size='small'
                      >
                        Cancelar
                      </Button>
                      : <>
                        {setAllowComponent("modalConexionesSFTP", "editarConexion") ?
                          <Button
                            variant="contained"
                            color="primary"
                            onClick={handleEditConexion}
                            startIcon={<Edit />}
                          >
                            Editar
                          </Button>
                          : <></>}
                        {setAllowComponent("modalConexionesSFTP", "eliminarConexion") ?
                          <Button
                            variant="contained"
                            color="error"
                            onClick={handleOpenDialog}
                            startIcon={<Delete />}
                            sx={{ ml: 1 }}
                          >
                            Eliminar
                          </Button>
                          : <></>}

                      </>}
                  </Grid>
                </Grid>
                : <></>}
              <Grid item xs={12}>
                <TextInput
                  disabled={!(conexionSelected.isEditing || !conexionSelected.nIdCfgConexion)}
                  id="sNombreConexion"
                  name="sNombreConexion"
                  label="Nombre de la conexión"
                  value={conexionSelected.sNombreConexion}
                  onChangeRoot={onChangeInput}
                  fullWidth
                  require
                />
              </Grid>
              <Grid item sm={6} xs={12}>
                <TextInput
                  disabled={!(conexionSelected.isEditing || !conexionSelected.nIdCfgConexion)}
                  id="sHost"
                  name="sHost"
                  label="Host"
                  value={conexionSelected.sHost}
                  onChangeRoot={onChangeInput}
                  fullWidth
                  require
                />
              </Grid>
              <Grid item sm={6} xs={12}>
                <TextInput
                  disabled={!(conexionSelected.isEditing || !conexionSelected.nIdCfgConexion)}
                  id="nPort"
                  name="nPort"
                  label="Puerto"
                  type='number'
                  value={`${conexionSelected.nPort}`}
                  onChangeRoot={onChangeInput}
                  fullWidth
                  require
                />
              </Grid>
              <Grid item sm={6} xs={12}>
                <TextInput
                  disabled={!(conexionSelected.isEditing || !conexionSelected.nIdCfgConexion)}
                  id="sUser"
                  name="sUser"
                  label="Usuario"
                  value={conexionSelected.sUser}
                  onChangeRoot={onChangeInput}
                  fullWidth
                  require
                />
              </Grid>
              <Grid item sm={6} xs={12}>
                <TextInput
                  disabled={!(conexionSelected.isEditing || !conexionSelected.nIdCfgConexion)}
                  id="sPassword"
                  name={!conexionSelected.isEditing ? "sPassword" : "sInnerPassword"}
                  label="Contraseña"
                  value={!conexionSelected.isEditing ? conexionSelected.sPassword : conexionSelected.sInnerPassword}
                  type={conexionSelected.showPassword && !conexionSelected.isEditing ? "text" : "password"}
                  onChangeRoot={onChangeInput}
                  fullWidth
                  InputProps={{
                    endAdornment: (
                      setAllowComponent("modalConexionesSFTP","verPassword") &&
                      !conexionSelected.isEditing && conexionSelected.nIdCfgConexion ?
                      <IconButton
                        onClick={() => onChangeInput({ showPassword: !conexionSelected.showPassword })}
                      >
                        {conexionSelected.showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    : <></>)
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <TextInput
                  disabled={!(conexionSelected.isEditing || !conexionSelected.nIdCfgConexion)}
                  id="sComentario"
                  name="sComentario"
                  label="Comentarios"
                  value={conexionSelected.sComentario}
                  onChangeRoot={onChangeInput}
                  fullWidth
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Box
          sx={{
            textAlign: 'end',
            marginTop: '15px'
          }}
        >
          <Button
            color="primary"
            onClick={onClose}
            sx={{ marginRight: '10px', display: '' }}
          >
            Volver
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={onSubmit}
            disabled={isActiveSave()}
          >
            Guardar
          </Button>

        </Box>
      </ModalBase>
    </>
  );
};


const TitleModal = ({ title, typeOperation, extraButton }) => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        width: '100%',
        marginBottom: '10px',
        justifyContent: 'space-between', mb: 4
      }}
    >
      <div style={{ display: 'flex', justifyContent: "space-between" }}>

        <Cable fontSize={'large'} sx={{ color: '#00CD68', fontSize: '45px' }} />
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <Typography
            variant="p"
            sx={{
              marginLeft: '15px',
              fontSize: '14px',
              marginBottom: '4px'
            }}
          >
            {'Conexiones SFTP del socio comercial'}
          </Typography>
          <Typography
            variant="p"
            sx={{
              marginLeft: '15px',
              color: '#00609C',
              fontSize: '20px'
            }}
          >
            {title}
          </Typography>
        </div>
      </div>

      <div>
        {extraButton}
      </div>
    </Box>
  );
};