import { Menu, MenuItem, IconButton, Divider } from '@mui/material';
import { useState } from 'react';
import { useAuthUser } from '../../../hooks/useAuthUser';

import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import LogoutTwoToneIcon from '@mui/icons-material/LogoutTwoTone';

export const SubMenu = () => {
  const { signOut } = useAuthUser();

  // const onLogOut = () => {
  //   dispatch(logOut());
  //   dispatch(reset());
  //   navigate('/login');
  // };

  const onLogOut = () => {
    signOut();
  };

  const [anchor, setAnchor] = useState(null);
  const open = Boolean(anchor);

  const handleClick = (event) => {
    setAnchor(event.currentTarget);
  };

  const handleClose = () => {
    setAnchor(null);
  };

  return (
    <>
      <IconButton
        id="subMenuButton"
        aria-controls={open ? 'subMenu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
      >
        <AccountCircleOutlinedIcon fontSize="large" color="neutral" />
      </IconButton>
      <Menu
        id="subMenu"
        anchorEl={anchor}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'subMenuButton'
        }}
      >
        {/* <MenuItem onClick={handleClose}>Opcion 1</MenuItem>
        <MenuItem onClick={handleClose}>Opcion 2</MenuItem> */}
        <Divider />
        <MenuItem onClick={onLogOut}>
          <LogoutTwoToneIcon />
          Cerrar Sesión
        </MenuItem>
      </Menu>
    </>
  );
};
