import { API } from "aws-amplify";
import { closeOrdenPago, loading, openToast, tableLoading, tableModalLoading } from "../ui/uiSlice";
import { setBitacoraDataTable, setDataTable } from "./suspensionesSlice";

export const getSuspensionesData = (nIdCadena = 0, nIdEstatus = 1, nBitacora = false) => {
  return async (dispatch) => {
    const params = {
      headers: {},
      response: false,
      queryStringParameters: {nIdCadena, nIdEstatus, nBitacora: nBitacora ? 1 : 0},
    };

    let suspensiones = {}
    try {
      dispatch(nBitacora ? tableModalLoading(true) : tableLoading(true));
      suspensiones = await API.get("suspensiones", "/suspensiones", params);
      dispatch(nBitacora ?
        setBitacoraDataTable({
            ...suspensiones
          }) :
          setDataTable({
            ...suspensiones,
          })
        );
    } catch (error) {
      console.log("ocurrio un error " + error);
    } finally {
      dispatch(nBitacora ? tableModalLoading(false) :tableLoading(false));
    }
    return suspensiones
  };
};

export const insertSuspension = (form) => {
  return async (dispatch) => {
    const params = {
      headers: {},
      response: false,
      body: {...form},
    };
    let resp = {ok: false, msg: "Error inesperado. Intente más tarde."}
    try {
      dispatch(loading(true));
      const res = await API.post("suspensiones", "/suspensiones", params);
      resp = {
        ok: res.nCodigo === 0,
        msg: res.sMensaje
      }
    } catch (error) {
      console.log("ocurrio un error " + error);
    } finally {
      dispatch(loading(false));
      return resp;
    }
  };
};

export const updateSuspension = (form) => {
  return async (dispatch) => {
    const params = {
      headers: {},
      response: false,
      body: form,
    };
    let resp = {ok: false, msg: "Error inesperado. Intente más tarde."}
    try {
      dispatch(loading(true));
      const res = await API.put("suspensiones", `/suspensiones`, params);
      resp = {
        ok: res.nCodigo === 0,
        msg: res.sMensaje
      }
    } catch (error) {
      console.log("ocurrio un error " + error);
    } finally {
      dispatch(loading(false));
      return resp;
    }
  };
};

export const deleteSuspension = (form) => {
  return async (dispatch) => {
    const params = {
      headers: {},
      response: false,
      body: form,
    };
    let resp = {ok: false, msg: "Error inesperado. Intente más tarde."}
    try {
      dispatch(loading(true));
      const res = await API.del("suspensiones", `/suspensiones`, params);
      resp = {
        ok: res.nCodigo === 0,
        msg: res.sMensaje
      }
    } catch (error) {
      console.log("ocurrio un error " + error);
    } finally {
      dispatch(loading(false));
      return resp;
    }
  };
};