import { useModal } from "../../hooks/useModal";
import { ModalBase } from "./ModalBase";
import { History, Key } from "@mui/icons-material";
import { Box, Typography, Button, Grid, Checkbox, Alert } from "@mui/material";
import { TextInput } from "../form/TextInput";
import { useEffect, useState } from "react";
import { useAuthPage } from "../../hooks/useAuthPage";
import awsExports from "../../aws-exports";
import moment from "moment";
import { ConfirmDialog } from "./ConfirmDialog";
import { useDispatch } from "react-redux";
import { getEnvioCredencialesBitacora, postEnviarCredenciales, postGenerarCredenciales } from "../../slices/efr/efrThunk";
import { startLoadCadenas, startLoadEmisores } from "../../slices/catalogo/catalgoThunk";
import { ModalBitacoraEnvioCredenciales } from "./ModalBitacoraEnvioCredenciales";
import { useExportFile } from "../../hooks/useExportFile";
import CustomizedTabs from "../CustomizedTabs";
import { regexUsuarioSFTP } from "../../helpers/regexs";

const URL_ENV = awsExports?.aws_cloud_logic_custom[0].endpoint.split("/")
const ENV = URL_ENV[URL_ENV.length - 1]


export const ModalConexionSofiPay = (props) => {
  const {
    isOpen,
    onClose,
    selectedEntity,
    conexiones,
    conexionSelected,
    showBitacora
  } = props

  const { setOpenToast } = useModal();
  const { setAllowComponent } = useAuthPage();
  const dispatch = useDispatch();


  const [emisor, setEmisor] = useState({})
  const [form, setForm] = useState({
    sUser: "",
    sContactos: []
  })

  const [openConfirmDialog, setOpenConfirmDialog] = useState(false)


  useEffect(() => {
    setEmisor(selectedEntity)
    setForm({
      sUser: selectedEntity.nIdCredencialSFTP ? selectedEntity.sUsuarioCredencialSFTP : getUserFormat(selectedEntity.sNombreComercial),
      sContactos: []
    })
  }, [selectedEntity, isOpen])

  const handleConfirmSubmit = () => {
    if (!regexUsuarioSFTP.test(form.sUser)) {
      setOpenToast(true, "El usuario no tiene el formato correcto, no se admiten carácteres especiales.")
      return
    }
    setOpenConfirmDialog(true)
  }

  const handleSubmit = async () => {
    
    setOpenConfirmDialog(false)
    const innerForm = {
      nIdEmisor: emisor.nIdCNBV ?? 0,
      nIdCadena: emisor.nIdCadena ?? 0,
      sUser: form.sUser,
      aContactos: form.sContactos
    }
    const res = await dispatch(emisor.nIdCredencialSFTP ? postEnviarCredenciales(innerForm) : postGenerarCredenciales(innerForm))

    setOpenToast(!res.ok, res.msg)
    if (res.ok) {
      await dispatch(emisor.nIdCadena ? startLoadCadenas() : startLoadEmisores())

    }
  }

  const onChangeInput = async (value) => {
    setForm({ ...form, ...value });
  };

  const getUserFormat = (sNombreComercial) => {
    return sNombreComercial?.replace(" ", "_").normalize('NFD').replace(/[\u0300-\u036f]/g, "").toLowerCase()
  }

  const isActiveSave = () => {
    if (emisor.nIdCredencialSFTP && form.sContactos.length === 0) return false;
    if (!emisor.nIdCredencialSFTP && !form.sUser) return false
    return true
  };

  const showTitleButton = () => {
    if ((setAllowComponent("modalConexionesSFTP") || true) && emisor.nIdCredencialSFTP) return <Button variant='contained' onClick={() => showBitacora("conexionesSofiPay")}
      startIcon={<History />}>Bitácora de envío de credenciales</Button>;

    return <></>
  }

  const getContactos = () => {
    return emisor.oContactos ? JSON.parse(emisor?.oContactos)?.filter((contacto) => !contacto.nPrincipal) : []
  }

  const handleContactos = (flag, contacto) => {
    let innerContactos = [...form.sContactos]

    if (flag) {
      innerContactos.push(contacto)
    } else {
      innerContactos = innerContactos.filter(con => con.nIdContacto !== contacto.nIdContacto)
    }
    setForm({
      ...form,
      sContactos: innerContactos
    })
  }



  return (
    <>
      <ConfirmDialog
        isOpenDialog={openConfirmDialog}
        onCloseDialog={() => setOpenConfirmDialog(false)}
        onConfimDialog={handleSubmit}
        message={`${!emisor.nIdCredencialSFTP ? "Se generarán las credenciales de acceso para el socio comercial." : "Se enviarán las credenciales a los contactos seleccionados."} ¿Estas seguro/a que quieres continuar?`}
        textButtonConfirm="Sí, si estoy seguro/a."
      />
      <ModalBase open={isOpen && !showBitacora.open} onClose={onClose}>
        <TitleModal title={emisor?.sNombreComercial} extraButton={showTitleButton()} />

        <Grid item xs={12} container justifyContent={"space-between"} paddingLeft={1}>
          <CustomizedTabs
            tabs={[
              ...conexiones.filter(con => parseInt(con.nIdTipoActor) === 2).map(con => ({ label: con.sNombreConexion, value: con.nIdCfgConexion })),
            ]}
            value={conexionSelected.nIdCfgConexion ?? 0}
          //onChange={onChangeConexion}
          />
        </Grid>
        <Grid container spacing={2} padding={2} sx={{ background: "#f9f9f9", borderRadius: 2, marginLeft: 0, width: "100%", marginTop: 0, paddingLeft: 0 }}>
          <Grid item xs={12} container alignItems={"center"} sx={{ pt: 0 }}>
            <Grid item xs={6}>
              <Typography variant="h6" color={"primary"}>Generación de credenciales</Typography>
            </Grid>
            {emisor.nIdCredencialSFTP &&
              <Grid item xs={6} sx={{ textAlign: "right" }}>
                <Typography variant="h6" color={"primary"} fontSize={15}>Credenciales generadas por: </Typography>
                <Typography variant="b">{emisor.sNombreUsuarioCredencialSFTP} ({moment(emisor.dFecRegistroCredencialSFTP.slice(0, -1)).format("YYYY-MM-DD HH:mm:ss")})</Typography>
              </Grid>
            }
          </Grid>
          {!emisor.nIdCredencialSFTP ?
            <Grid item xs={12}>
              <Alert severity="warning">La generación de credenciales SFTP se realiza una única vez y no admite modificaciones posteriores.</Alert>
            </Grid>
            : <></>}
          <Grid item xs={12} >
            <TextInput
              disabled={emisor.nIdCredencialSFTP || !(setAllowComponent("modalConexionesSFTP", "generarCredenciales") || true)}
              id="sUser"
              name="sUser"
              label="Usuario"
              value={form.sUser}
              onChangeRoot={onChangeInput}
              startAdornment={emisor.nIdCredencialSFTP ? null : "sp_"}
              endAdornment={emisor.nIdCredencialSFTP ? null : `_${ENV}`}
              fullWidth
              require
            />
          </Grid>
          {emisor.nIdCredencialSFTP && <>

            <Grid item xs={12}>
              <Typography variant="h6" color={"primary"}>Envío de credenciales</Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="p">Seleccione los contactos de{emisor.nIdEmisor ? " la EFR" : "l comisionista"} a quienes se le enviarán las credenciales generadas: </Typography>
            </Grid>

            <Grid item xs={12}>
              <table style={{ width: "100%", borderCollapse: "collapse" }}>
                <tbody>
                  {getContactos().map(contacto => {
                    const isChecked = !!form.sContactos.find(con => parseInt(con.nIdContacto) === parseInt(contacto.nIdContacto))
                    console.log(contacto.sMail, isChecked, contacto.nIdContacto, form.sContactos, contacto)
                    return <tr style={{ height: 50 }}>
                      <td style={{ borderBottom: "1px solid #f1f1f1", width: 30 }}>
                        <Checkbox
                          style={{ margin: 0, padding: 0 }}
                          checked={isChecked}
                          onChange={(e) => handleContactos(e.target.checked, contacto)}
                          disabled={!(setAllowComponent("modalConexionesSFTP", "enviarCredenciales") || true)} />
                      </td>
                      <td style={{ textAlign: 'left', borderBottom: "1px solid #f1f1f1", paddingLeft: 10 }}>{contacto.sNombre} {contacto.sPaterno} {contacto.sMaterno}- ({contacto.sMail})</td>
                    </tr>
                  })}
                </tbody>
              </table>
            </Grid>
          </>}

        </Grid>

        <Box
          sx={{
            textAlign: 'end',
            marginTop: '15px'
          }}
        >
          <Button
            onClick={() => onClose(emisor.nIdCredencialSFTP ? 'reload' : 'none')}
            sx={{ marginRight: '10px', display: '' }}
          >
            VOLVER
          </Button>
          {setAllowComponent("modalConexionesSFTP", emisor.nIdCredencialSFTP ? "enviarCredenciales" : "generarCredenciales") || true ?
            <Button
              variant="contained"
              color="primary"
              onClick={handleConfirmSubmit}
              disabled={!isActiveSave()}
            >
              {emisor.nIdCredencialSFTP ? "Enviar credenciales" : "Generar credenciales"}
            </Button>
            : <></>}

        </Box>
      </ModalBase >

    </>
  );
};


const TitleModal = ({ title, typeOperation, extraButton }) => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        width: '100%',
        marginBottom: '10px',
        justifyContent: 'space-between', mb: 4
      }}
    >
      <div style={{ display: 'flex', justifyContent: "space-between" }}>

        <Key fontSize={'large'} sx={{ color: '#00CD68', fontSize: '45px' }} />
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <Typography
            variant="p"
            sx={{
              marginLeft: '15px',
              fontSize: '14px',
              marginBottom: '4px'
            }}
          >
            {'Credenciales de acceso a SFTP '}
          </Typography>
          <Typography
            variant="p"
            sx={{
              marginLeft: '15px',
              color: '#00609C',
              fontSize: '20px'
            }}
          >
            {title}
          </Typography>
        </div>
      </div>
      <div>
        {extraButton}
      </div>
    </Box>
  );
};