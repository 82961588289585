import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  // cleanContactosCadena,
  cleanEscalonado,
  cleanEscCantidadForm,
  setBancoSearched,
  setContactosList,
  setEmails,
  setFacturacionForm,
  setIvaOperativoSelected,
  setListEscalonadoForm,
  setListEscCantidadForm
} from '../slices/cadena/cadenaSlice';
import {
  getDaysOfWeekPayList,
  getFacturaByType,
  getFormatEscalonados,
  getFormatEscCantidad,
  periodicidades,
  validateEmpty as validateLiqEmpty
} from '../helpers/getData';
import { initalStateEmisorDetail, getDatosFacturacion, facturaSelectedValues, nIdTipoLiquidacion24Hrs } from '../helpers/forms';
import { ivas } from '../helpers/data-iva';
import { regexNums } from '../helpers/regexs';
import {
  startLoadBancos,
  startLoadColonias,
  startLoadDatosFacturacion,
  startLoadDocumentos,
  startLoadEmisores,
  startLoadIntegracion,
  startLoadProcesos,
  startLoadRetenciones,
  startLoadTiposLiquidacion
} from '../slices/catalogo/catalgoThunk';
import { useModal } from './useModal';
import {
  escaloandosSwitch,
  getFacturaFormValuesByList,
  getFormatContactosEdit,
  getFormatLiquidacion,
  getLiquidacionJSON,
  liquidacionSwitch,
  parseEscCantidadToOperacion,
  parseEscFormToTicket,
  parseEscOperacionToCantidad,
  parseEscTicketToForm,
  parseJsonLiquidacion,
  parseLiquidacion,
  searchItemById,
  switches
} from '../helpers/modalConfirmHelper';
import { loading } from '../slices/ui/uiSlice';
import { getToBase64 } from '../helpers/base64';
// import { subirArchivoS3 } from '../slices/cadena/cadenaThunk';
import { useUploadDocuments } from './useUploadDocuments';
import { startLoadEditEmisor } from '../slices/efr/efrThunk';
import { validateAddressContactEdit, validateCashInEdit, validateCashOutEdit, validateDataBankEdit, validateDataGeneralEdit, validateDocuments, validateOperationDataEditEFR } from '../helpers/forms-validate';
import { signedURL } from '../slices/cadena/cadenaThunk';
import { useAuthPage } from './useAuthPage';


const initialValuesEditSection = {
  datosGenerales: false,
  direccion: false,
  datosBancarios: false,
  datosOperativos: false,
  cashIn: false,
  cashOut: false,
  documentos: false,
  datosFacturacion: false,
  cuentaContableIn: false,
  cuentaContableOut: false,
  ticket: false,
  estatus: false,
}

export const useModalConfirmationEFR = () => {

  const dispatch = useDispatch();
  const {
    isOpenConfirmation,
    isOpenContact,
    isOpenListContact,
    closeModalConfirmacion,
    setOpenToast,
    openMoldaViewPdf,
    openModalContact,
    openModalListContact,
    disableModalListContactButtons,
  } = useModal();
  const { open, idCadena } = isOpenConfirmation;
  // const { emisores: emisoresList, documentos } = useSelector((state) => state.catalogo);
  const [isOnEditSection, setIsOnEditSection] = useState(initialValuesEditSection);
  const [viewEscaloandos, setViewEscaloandos] = useState({
    ticket: false,
    operacion: false
  });
  const [viewFacturacion, setViewFacturacion] = useState({
    comision: false,
    transferencia: false,
    minimo: false,
  });
  const [facturaSelected, setFacturaSelected] = useState(facturaSelectedValues);
  const [confirmEscalonadosChange, setConfirmEscalonadosChange] = useState(false)
  const [confirmMensajeTicketChange, setConfirmMensajeTicketChange] = useState(false)
  const bancoEncontrado = useRef();
  const facturacionMinima = useRef();
  const documentosModify = useRef();
  const { setAllowComponent } = useAuthPage();


  //formularios
  const [emisor, setEmisor] = useState(initalStateEmisorDetail);
  const [emisorBackup, setEmisorBackup] = useState(initalStateEmisorDetail)

  // Subir docuementos 
  const { uploadDocument } = useUploadDocuments();

  // modulos que cambiaron 
  const [modulesChanged, setModulesChanged] = useState([]);

  const {
    //giros, 
    bancos = [],
    retenciones,
    // comisiones, 
    integraciones,
    ciudades = [],
    estados = [],
    colonias = [],
    tiposLiquidacion = [],
    emisores: emisoresList,
    documentos,
    cfdis,
    formasPago,
    metodosPago,
    regimensFiscal,
    unidades,
    productoServicios,
    procesos,
  } = useSelector((state) => state.catalogo);

  const {
    direccionSearched,
    bancoSearched,
    escalonadoForms,
    escCantidadForms,
    emailsFactura,
    facturacionForm,
    contactos: contactosStore,
    facturacionForm: formFacturacion
  } = useSelector((state) => state.cadena);

  const { isLoading } = useSelector((state) => state.ui);

  const [isDocumentosLoaded, setIsDocumentosLoaded] = useState(false)

  const searchEmisor = async () => {
    dispatch(loading(true));
    let innerDocumentos = documentos

    if (!documentos.length || !isDocumentosLoaded) {
      innerDocumentos = await dispatch(startLoadDocumentos(0, 0, false, -1));
    }

    const facturacionData = await onLoadDatosFact();
    console.log(facturacionData)
    const {
      regimensFiscal = [],
    } = facturacionData

    const searchedEmisor = emisoresList.rows.find(
      (val) => val.nIdEmisor.toString() === idCadena?.toString()
    );
    const documentosTemp = searchedEmisor.oDocumentos
      ? JSON.parse(searchedEmisor.oDocumentos)
      : [];

    const direccionesTemp = searchedEmisor.oDirecciones
      ? JSON.parse(searchedEmisor.oDirecciones)
      : [];

    const contactosTemp = searchedEmisor.oContactos
      ? JSON.parse(searchedEmisor.oContactos)
      : [];

    const liquidacionInTemp = searchedEmisor.oLiquidacionCashIn
      ? JSON.parse(searchedEmisor.oLiquidacionCashIn)
      : [];

    const liquidacionOutTemp = searchedEmisor.oLiquidacionCashOut
      ? JSON.parse(searchedEmisor.oLiquidacionCashOut)
      : [];

    const oCuentasContables = searchedEmisor.oCuentasContables
      ? JSON.parse(searchedEmisor.oCuentasContables)
      : []

    const oCuentasContablesCashOut = searchedEmisor.oCuentasContablesCashOut
      ? JSON.parse(searchedEmisor.oCuentasContablesCashOut)
      : []

    const oCuentasContablesCashIn = searchedEmisor.oCuentasContablesCashIn
      ? JSON.parse(searchedEmisor.oCuentasContablesCashIn)
      : []

    const tempEslonadosOperacion = searchedEmisor.oEslonadosOperacion
      ? JSON.parse(searchedEmisor.oEslonadosOperacion).sort(
        (a, b) => a.nNumOpeMin - b.nNumOpeMin
      )
      : [];

    const tempEscalonadosTicket = searchedEmisor.oEscalonadosTicket
      ? JSON.parse(searchedEmisor.oEscalonadosTicket).sort(
        (a, b) => a.nMontoMin - b.nMontoMin
      )
      : [];

    const oDocumentos = documentosTemp.map((doc) => {
      const documentType = searchItemById(doc.id, innerDocumentos);
      return {
        ...doc,
        sNombreDocumento: documentType ? documentType.text : '',
        sAbrevDocumento: documentType ? documentType.sAbrevDoc : ''
      };
    });

    const facturacionTemp = searchedEmisor.oFacturas
      ? JSON.parse(searchedEmisor.oFacturas)
      : [];

    const oContactos = contactosTemp.map((contacto, i) => {
      return {
        ...contacto,
        nombre: contacto.sNombre,
        apellido: contacto.sPaterno,
        segundoApellido: contacto.sMaterno,
        telefono: contacto.sTelefono,
        email: contacto.sMail,
        proceso: JSON.parse(contacto.sProcesos),
        area: contacto.sArea,
        puesto: contacto.sPuesto,
        comentarios: contacto.sComentario,
        id: i
      };
    });

    dispatch(setContactosList(oContactos));

    // Ordena ascendentemente
    const oDirecciones = direccionesTemp.sort((a, b) => a.nIdTipo - b.nIdTipo);

    const oLiquidacionCashIn = parseLiquidacion(liquidacionInTemp);
    const oLiquidacionCashOut = parseLiquidacion(liquidacionOutTemp);

    const oEslonadosOperacion = parseEscOperacionToCantidad(tempEslonadosOperacion);
    const oEscalonadosTicket = parseEscTicketToForm(tempEscalonadosTicket);

    dispatch(setIvaOperativoSelected(searchedEmisor.nIVA));

    oEslonadosOperacion.length > 0 &&
      dispatch(setListEscCantidadForm(oEslonadosOperacion));

    oEscalonadosTicket.length > 0 && dispatch(setListEscalonadoForm(oEscalonadosTicket));

    const liquidacionCashIn = getLiquidacionJSON(oLiquidacionCashIn, tiposLiquidacion);
    const liquidacionCashOut = getLiquidacionJSON(oLiquidacionCashOut, tiposLiquidacion);

    const oFacturas = facturacionTemp.map((fact) => {
      facturacionMinima.current = fact.nIdTipoFactura === 3 && `${fact.nComisionMinima}`;
      return {
        ...fact,
        ...getDataFacturacion({
          idCdfi: fact.sUsoCFDI,
          idFormaPago: fact.sFormaPago,
          idMetodoPago: fact.sMetodoPago,
          idRegimen: fact.sRegimenFiscal,
          idClaveProd: fact.sClaveProductoServicio,
          idUnidad: fact.sUnidad,
          idPeriodicidad: fact.nPeriodoFacturacion,
        })
      }
    });

    const factStore = getFacturaFormValuesByList(oFacturas, searchedEmisor.nIdIVA);
    dispatch(setEmails({
      comision: factStore.comision.emails,
      transferencia: factStore.transferencia.emails,
      minimo: factStore.minimo.emails,
    }));
    dispatch(setFacturacionForm(factStore));

    const regimen = regimensFiscal.find((reg) => reg.value.toString() === factStore.regimenFiscal);
    if (searchedEmisor) {

      const emsisorTemp = {
        ...initalStateEmisorDetail,
        ...searchedEmisor,
        oDocumentos,
        oContactos,
        oLiquidacionCashIn,
        oLiquidacionCashOut,
        oDirecciones,
        sRegimenFiscal: regimen?.value + ' - ' + regimen?.text,
        oEslonadosOperacion,
        oEscalonadosTicket,
        liquidacionCashIn,
        liquidacionCashOut,
        oCuentasContables,
        oCuentasContablesCashIn,
        oCuentasContablesCashOut,
        oFacturas,
        documentos: {},
        oDocsUpdated: [],
        nFacturacionMinima: facturacionMinima.current,
        isActiveCashIn: oLiquidacionCashIn.length > 0 ? false : true,
        isActiveCashOut: oLiquidacionCashOut.length > 0 ? false : true,
        isActiveEscTicket: oEscalonadosTicket.length > 0,
        isActiveEscOperacion: oEslonadosOperacion.length > 0,
        isActiveMGracia: searchedEmisor.dPeriodoGracia !== '',
        isIndirecto: !!searchedEmisor.nIdIntegrador,
        oDireccion: oDirecciones.length > 0
          ? oDirecciones[0]
          : initalStateEmisorDetail.oDireccion,

      };

      setEmisor(emsisorTemp);
      setEmisorBackup(emsisorTemp);

    }

    dispatch(loading(false));

  };

  const getDataFacturacion = ({
    idCdfi,
    idFormaPago,
    idMetodoPago,
    idRegimen,
    idClaveProd,
    idUnidad,
    idPeriodicidad
  }) => {
    const datosFacturacion = getDatosFacturacion();

    const cdfi = searchItemById(idCdfi, datosFacturacion.cdfis);
    const formaPago = searchItemById(idFormaPago, datosFacturacion.formasPago);
    const metodoPago = searchItemById(idMetodoPago, datosFacturacion.metodosPago);
    const regimenFiscal = searchItemById(idRegimen, datosFacturacion.regimensFiscal);
    const productoServ = searchItemById(idClaveProd, datosFacturacion.productoServicios);
    const unidad = searchItemById(idUnidad, datosFacturacion.unidades);
    const periodicidad = searchItemById(idPeriodicidad, periodicidades);

    return {
      sNombreRegimenFiscal: regimenFiscal ? regimenFiscal.text : '',
      sNombreUsoCFDI: cdfi ? cdfi.text : '',
      sNombreFormaPago: formaPago ? formaPago.text : '',
      sNombreMetodoPago: metodoPago ? metodoPago.text : '',
      sNombreClaveProductoServicio: productoServ ? productoServ.text : '',
      sNombreUnidad: unidad ? unidad.value + '-' + unidad.strNombre : '',
      sNombrePeriodoFacturacion: periodicidad ? periodicidad.text : ''
    };
  };

  const closeModal = () => {
    setIsOnEditSection(initialValuesEditSection);
    closeModalConfirmacion();
  }

  // * --- --- --- --- --- ---- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --
  // * --- --- --- --- --- ----
  // * --- ACTIVA EL EDITAR ---
  // * --- --- --- --- --- ----
  const editSection = async ({ section, saveSection = false }) => {

    if (section === 'datosGenerales' && saveSection) {
      const { error, ok } = validateDataGeneralEdit(emisor, false);
      if (!ok) {
        setOpenToast(true, error)
        return;
      }

    } else if (section === 'direccion' && saveSection) {
      const { error, ok } = validateAddressContactEdit({
        ...emisor.oDireccion,
        contactos: contactosStore,
        nIdTipoDireccion: emisor.oDireccion.nIdTipo.toString()
      },
        procesos);
      if (!ok) {
        setOpenToast(true, error)
        return;
      }

    } else if (section === 'datosBancarios' && saveSection) {
      const { error, ok } = validateDataBankEdit({
        ...emisor,
        nIdBanco: emisor.nIdBanco.toString()
      });
      if (!ok) {
        setOpenToast(true, error)
        return;
      }
    } else if (section === 'datosOperativos' && saveSection) {

      const { ok, error } = validateOperationDataEditEFR({
        ...emisor,
        isActiveEscalonado: emisor.isActiveEscTicket,
        isEscCantidad: emisor.isActiveEscOperacion,
        nCostoTransferencia: emisor.nCostoTransferencia.toString(),
        escalonadoForms: emisor.isActiveEscTicket ? escalonadoForms : [],
        escCantidadForms: emisor.isActiveEscOperacion ? escCantidadForms : [],
      });
      if (!ok) {
        setOpenToast(true, error)
        return;
      }

      const innerEmisor = {
        ...emisor,
        oEslonadosOperacion: emisor.isActiveEscOperacion
          ? parseEscCantidadToOperacion(escCantidadForms)
          : [],
        oEscalonadosTicket: emisor.isActiveEscTicket
          ? parseEscFormToTicket(escalonadoForms)
          : [],
        isEscalonadosChanged: confirmEscalonadosChange
      }

      setEmisor(innerEmisor);

      const hasChanged = escalonadoHasChanged(innerEmisor.oEslonadosOperacion)

      if (hasChanged) {
        if (confirmEscalonadosChange) {
          handleCloseConfirmEscalonado()
        } else {
          setConfirmEscalonadosChange(true)
          return;
        }
      }

    } else if (section === 'cashIn' && saveSection) {

      const { ok, error } = validateCashInEdit(emisor);
      if (!ok) {
        setOpenToast(true, error);
        return
      }

      const isEmpty = emisor.isActiveCashIn || validateLiqEmpty(emisor.liquidacionCashIn);
      let liquidaciones = [];

      if (!isEmpty) {

        if (emisor.oLiquidacionCashIn.length === 0) {
          const daysLiq = getDaysOfWeekPayList(emisor.liquidacionCashIn);
          const liqsTemp = parseLiquidacion(daysLiq);
          liquidaciones = parseJsonLiquidacion(liqsTemp, emisor.liquidacionCashIn);

        } else {
          liquidaciones = parseJsonLiquidacion(
            emisor.oLiquidacionCashIn,
            emisor.liquidacionCashIn
          );

        }
      }

      setEmisor({
        ...emisor,
        oLiquidacionCashIn: liquidaciones
      });

    } else if (section === 'cashOut' && saveSection) {

      const { ok, error } = validateCashOutEdit(emisor);
      if (!ok) {
        setOpenToast(true, error);
        return
      }

      const isEmpty = emisor.isActiveCashOut || validateLiqEmpty(emisor.liquidacionCashOut);
      let liquidaciones = [];

      if (!isEmpty) {

        if (emisor.oLiquidacionCashOut.length === 0) {
          const daysLiq = getDaysOfWeekPayList(emisor.liquidacionCashOut);
          const liqsTemp = parseLiquidacion(daysLiq)
          liquidaciones = parseJsonLiquidacion(
            liqsTemp,
            emisor.liquidacionCashOut
          );

        } else {
          liquidaciones = parseJsonLiquidacion(
            emisor.oLiquidacionCashOut,
            emisor.liquidacionCashOut
          );
        }
      }

      setEmisor({
        ...emisor,
        oLiquidacionCashOut: liquidaciones
      });

    } else if (section === 'datosFacturacion' && saveSection) {
      const oFacturas = emisor.oFacturas.map((fact) => {
        let factura;
        let type;

        // Comison
        if (fact.nIdTipoFactura.toString() === '1') {
          factura = facturacionForm.comision;
          type = 'comision';

          // Transferencia
        } else if (fact.nIdTipoFactura.toString() === '2') {
          factura = facturacionForm.transferencia;
          type = 'transferencia';

          // mínima
        } else {
          factura = facturacionForm.minimo;
          type = 'minimo';

        }

        return {
          ...fact,
          sUsoCFDI: factura.cfdi,
          sFormaPago: factura.formaPago,
          sMetodoPago: factura.metodoPago,
          sRegimenFiscal: factura.regimenFiscal,
          sClaveProductoServicio: factura.claveProducto,
          sUnidad: factura.claveUnidad,
          nPeriodoFacturacion: factura.periocidad,
          nDiasLiquidaFactura: factura.diasLiquidacion,
          sCorreoDestino: emailsFactura[type][0].email,
          ...getDataFacturacion({
            idCdfi: factura.cfdi,
            idFormaPago: factura.formaPago,
            idMetodoPago: factura.metodoPago,
            idRegimen: facturacionForm.regimenFiscal,
            idClaveProd: factura.claveProducto,
            idUnidad: factura.claveUnidad,
            idPeriodicidad: factura.periocidad,
          })
        }
      });

      setEmisor({ ...emisor, oFacturas });

    } else if (section === 'documentos' && saveSection) {

      if (emisor.oDocumentos.length === 0) {
        const { ok, error } = validateDocuments(emisor, documentos);
        if (!ok) {
          setOpenToast(true, error);
          setIsOnEditSection({ ...isOnEditSection, [section]: !saveSection });
          return;
        }
      }

      const respUpload = await uploadDocument(emisor.documentos, emisor.sRFC, documentos);
      if (respUpload.ok && Object.keys(emisor.documentos).length > 0) {

        const newOdocuments = emisor.oDocumentos.map((doc) => {
          const docSearch = respUpload.documents.find(
            (docRes) => docRes.id.toString() === doc.id.toString()
          );
          return {
            ...doc,
            key: docSearch ? docSearch.key : doc.key,
            name: docSearch ? docSearch.name : doc.name,
          }
        });
        const temporal = {
          ...emisor,
          oDocumentos: newOdocuments,
          oDocsUpdated: respUpload.documents, // Enviar este al backend 
          documentos: {}
        }
        setEmisor(temporal);
        documentosModify.current = respUpload.documents;

      }

    } else if (section === 'ticket' && saveSection) {
      if (!emisor.sMensajeTicket) {
        setOpenToast(true, "La leyenda del ticket es obligatoria.")
        return;
      }

      if (emisor.sMensajeTicket !== emisorBackup.sMensajeTicket) {
        if (confirmMensajeTicketChange) {
          handleCloseConfirmMensajeTicket()
        } else {
          setConfirmMensajeTicketChange(true)
          return;
        }
      } else {
        setOpenToast(true, "No se detectaron cambios en la leyenda del ticket.")
        return;
      }

    }

    if (!saveSection) {
      await loadInitialValues(section)
    }

    if (section === 'direccion' && !saveSection) {
      if (emisor.oDireccion.nCodigoPostal !== '') {
        await dispatch(startLoadColonias(`${emisor.oDireccion.nCodigoPostal}`, emisor.oDireccion.nIdColonia));
      }
    }

    setIsOnEditSection({ ...isOnEditSection, [section]: !saveSection });
    addNewModuleChanged(section);

    if (saveSection) {
      await onSaveEdit(section);
    }
  };

  const loadInitialValues = async (section) => {
    switch (section) {
      case 'direccion':
        if (emisor.oDireccion.nCodigoPostal !== '') await dispatch(startLoadColonias(`${emisor.oDireccion.nCodigoPostal}`, emisor.oDireccion.nIdColonia));
        await dispatch(startLoadProcesos(0, 1));
        break;
      case 'datosBancarios':
        await loadBancos();
        break;
      case 'datosOperativos':
        await onLoadDatosOperativos();
        break;
      case 'cashIn':
      case 'cashOut':
        await onLoadTiposLiquidacion();
        break;
      default:
        break;
    }
  }

  // * --- --- --- --- --- ---- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --
  const addNewModuleChanged = (section) => {
    const sectionSearched = modulesChanged.find((sec) => sec === section);
    if (sectionSearched) return;
    setModulesChanged([...modulesChanged, section]);
  }

  const deleteModuleChanged = (section) => {
    setModulesChanged(modulesChanged.filter((sec) => section !== sec))
  }

  // * --- --- --- --- --- ---- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --
  // * --- --- --- --- --- ----
  // * --- ON SUBMIT       ----
  // * --- --- --- --- --- ----

  const onSaveEdit = async (section) => {
    let formResult = {
      nIdEmisor: emisor.nIdEmisor
    };

    modulesChanged.forEach((module) => {

      if (section === module) {

        if (module === 'datosGenerales') {
          // **** DATOS GENERALES ****

          formResult = {
            ...formResult,
            datosGenerales: {
              sActividadEconomica: emisor.sActividadEconomica,
              sNombreComercial: emisor.sNombreComercial,
              sRazonSocial: emisor.sRazonSocial,
              sRegimenSocietario: emisor.sRegimenSocietario,
              sRFC: emisor.sRFC,
              nIdIntegrador: emisor.isIndirecto ? emisor.nIdIntegrador : 0,
              nPorcentajeComision: emisor.isIndirecto ? emisor.nPorcentajeComision : 0,
              sMensajeTicket: emisor.sMensajeTicket,
              sTelefono: emisor.sTelefono,
              sMail: emisor.sMail
            }
          }

        } else if (module === 'direccion') {
          // **** DIRECCION Y CONTACTOS ****
          formResult = {
            ...formResult,
            direccion: {
              sCalle: emisor.oDireccion.sCalle,
              sNumInterno: emisor.oDireccion.sNumInterno,
              nNumExterno: emisor.oDireccion.nNumExterno,
              nCodigoPostal: emisor.oDireccion.nCodigoPostal,
              nIdColonia: emisor.oDireccion.nIdColonia,
              nIdTipoDireccion: emisor.oDireccion.nIdTipo,
              contactos: getFormatContactosEdit(contactosStore), // Devolver nIdcontactos
              nIdDireccion: emisor.oDireccion.nIdDireccion
            }
          }

        } else if (module === 'datosBancarios') {
          // **** BANCARIOS ****
          formResult = {
            ...formResult,
            datosBancarios: {
              sBeneficiario: emisor.sBeneficiario,
              sClabe: emisor.sClabe,
              nIdBanco: emisor.nIdBanco,
              sRefNumerica: emisor.sRefNumerica,
              sRefAlfaNumerica: emisor.sRefAlfanumerica,
              nIdCuenta: emisor.nIdCuentaBanco
            }
          }

        } else if (module === 'datosOperativos') {
          // **** OPERATIVOS ****

          formResult = {
            ...formResult,
            datosOperativos: {
              nIdRetencion: emisor.nIdRetencion,
              nIdIntegracion: emisor.nIdIntegracion,
              nIVAOperacion: emisor.nIdIVA,
              nCostoTransferencia: `${emisor.nCostoTransferencia}`,
              dPeriodoGracia: emisor.isActiveMGracia ? emisor.dPeriodoGracia : null,
              dInicioOperaciones: emisor.dInicioOperaciones,
              nIdConfiguracion: emisor.nIdConfiguracion,
              nFacturacionMinima: emisor.nFacturacionMinima,
              escalonadoTicket: getFormatEscalonados(
                emisor.isActiveEscTicket ? escalonadoForms : []
              ),
              escalonadoOperaciones: getFormatEscCantidad(
                emisor.isActiveEscOperacion && setAllowComponent('editarEscalonadaVigente') ? escCantidadForms : []
              ),
              isEscalonadosChanged: escalonadoHasChanged(getFormatEscCantidad(
                emisor.isActiveEscOperacion ? escCantidadForms : []
              )) && setAllowComponent('editarEscalonadaVigente')
            }
          }

        } else if (module === 'cashIn') {
          // **** LIQUIDACION CASH IN ****
          console.log("Datos en emisor",emisor)
          formResult = {
            ...formResult,
            liquidacionCashIn: {
              nIdTipo: parseInt(emisor.liquidacionCashIn.tipo),
              nDia: emisor.liquidacionCashIn?.tipoSelected?.nPorDia === 1
                ? parseInt(emisor.liquidacionCashIn.pagoMes)
                : 0,
              nMontoBase: parseInt(emisor.liquidacionCashIn.tipo) === nIdTipoLiquidacion24Hrs ? emisor.liquidacionCashIn?.nMontoBase : 0,
              nPerTransaccion: parseInt(emisor.liquidacionCashIn.tipo) === nIdTipoLiquidacion24Hrs ? emisor.liquidacionCashIn?.nPerTransaccion : 0,
              semana: emisor.liquidacionCashIn?.tipoSelected?.nPorDia === 0
                ? getFormatLiquidacion(
                  getDaysOfWeekPayList(emisor.liquidacionCashIn),
                  emisor.oLiquidacionCashIn
                ) // Enviar el id de la semana
                : [],
            }

          }

        } else if (module === 'cashOut') {
          // **** LIQUIDACION CASH OUT ****

          formResult = {
            ...formResult,
            liquidacionCashOut: {
              nIdTipo: parseInt(emisor.liquidacionCashOut.tipo),
              nDia: emisor.liquidacionCashOut?.tipoSelected?.nPorDia === 1
                ? parseInt(emisor.liquidacionCashOut.pagoMes)
                : 0,
              nMontoBase: parseInt(emisor.liquidacionCashOut.tipo) === nIdTipoLiquidacion24Hrs ? emisor.liquidacionCashOut?.nMontoBase : 0,
              nPerTransaccion: parseInt(emisor.liquidacionCashOut.tipo) === nIdTipoLiquidacion24Hrs ? emisor.liquidacionCashOut?.nPerTransaccion : 0,
              semana: emisor.liquidacionCashOut?.tipoSelected.nPorDia === 0
                ? getFormatLiquidacion(
                  getDaysOfWeekPayList(emisor.liquidacionCashOut),
                  emisor.oLiquidacionCashOut
                )
                : [],
            }

          }

        } else if (module === 'datosFacturacion') {
          // **** FACTURACION ****
          formResult = {
            ...formResult,
            datosFacturacion: {
              nIdIVA: facturacionForm.iva,
              sRegimenFiscal: facturacionForm.regimenFiscal, //Mandar en el nIdFactura
              facturacion: [
                {
                  ...getFacturaByType(
                    {
                      ...facturacionForm.comision,
                      emails: emailsFactura['comision']
                    },
                    1
                  ),
                  nIdFacturaEmisor: facturacionForm.comision.nIdFacturaEmisor
                },
                {
                  ...getFacturaByType(
                    {
                      ...facturacionForm.transferencia,
                      emails: emailsFactura['transferencia']
                    },
                    2
                  ),
                  nIdFacturaEmisor: facturacionForm.transferencia.nIdFacturaEmisor
                },
                {
                  ...getFacturaByType(
                    {
                      ...facturacionForm.minimo,
                      emails: emailsFactura['minimo']
                    },
                    3
                  ),
                  nIdFacturaEmisor: facturacionForm.minimo.nIdFacturaEmisor
                }

              ]
            }
          }

        } else if (module === 'documentos') {
          // **** DOCUMENTACION ****
          formResult = {
            ...formResult,
            documentos: documentosModify.current
          }
          documentosModify.current = null;

        } else if (module === 'ticket') {
          // **** TICKET ****

          formResult = {
            ...formResult,
            datosTicket: {
              sMensajeTicket: emisor.sMensajeTicket
            }
          }

        }

      }

    })

    if (section === 'estatus') {
      // **** ESTATUS EMISOR ****
      formResult = {
        ...formResult,
        nIdEstatusEFR: emisor.nIdEstatusEFR ? 0 : 1
      }
    }

    deleteModuleChanged(section);
    let sectionTemp = section;

    // cashIn
    // cashOut
    if (section === 'cashIn') {
      sectionTemp = 'liquidacionCashIn';

    } else if (section === 'cashOut') {
      sectionTemp = 'liquidacionCashOut';
    }

    const resp = await dispatch(startLoadEditEmisor(formResult, sectionTemp));
    setOpenToast(!resp.ok, resp.msg);

    if (resp.ok) {
      if (section === 'estatus') {
        setEmisorBackup({
          ...emisor,
          nIdEstatusEFR: emisor.nIdEstatusEFR ? 0 : 1
        });
        setEmisor({
          ...emisor,
          nIdEstatusEFR: emisor.nIdEstatusEFR ? 0 : 1
        })
        closeModal()
      } else {
        const oDocumentos = formResult.documentos ?
          emisor.oDocumentos.map(document => {
            const doc = formResult.documentos.find(d => parseInt(d.id) === parseInt(document.id))
            return {
              ...document,
              ...(doc ? doc : {})
            }
          })
          : emisor.oDocumentos;

        console.log(emisor)

        const oLiquidacionCashIn = parseJsonLiquidacion(
          emisor.oLiquidacionCashIn,
          emisor.liquidacionCashIn
        );
        let oLiquidacionCashOut = []
        if (emisor.oLiquidacionCashOut.length === 0) {
          const daysLiq = getDaysOfWeekPayList(emisor.liquidacionCashOut);
          const liqsTemp = parseLiquidacion(daysLiq)
          oLiquidacionCashOut = parseJsonLiquidacion(
            liqsTemp,
            emisor.liquidacionCashOut
          );

        } else {
          oLiquidacionCashOut = parseJsonLiquidacion(
            emisor.oLiquidacionCashOut,
            emisor.liquidacionCashOut
          );
        }

        if (emisor.isActiveEscOperacion) {
          dispatch(setListEscCantidadForm(escCantidadForms))
        }

        setEmisorBackup({
          ...emisor,
          oLiquidacionCashIn,
          oLiquidacionCashOut,
          oEslonadosOperacion: emisor.isActiveEscOperacion
            ? parseEscCantidadToOperacion(escCantidadForms)
            : [],
          oContactos: contactosStore,
          oDocumentos,
          documentos: {}
        });
        setEmisor({
          ...emisor,
          oLiquidacionCashIn,
          oLiquidacionCashOut,
          oEslonadosOperacion: emisor.isActiveEscOperacion
            ? parseEscCantidadToOperacion(escCantidadForms)
            : [],
          oContactos: contactosStore,
          oDocumentos,
          documentos: {}
        })
      }
      await dispatch(startLoadEmisores())
    } else {
      setEmisor(emisorBackup);
    }

  }

  // * --- --- --- --- --- ----
  // * --- ON CANCEL       ----
  // * --- --- --- --- --- ----
  const onSubmitCancelEdit = async () => {
    setModulesChanged([]);
    setIsOnEditSection(initialValuesEditSection);
    setEmisor(emisorBackup);
  }


  // * --- --- --- --- --- ---- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --


  const onChange = async (e) => {
    const inputName = e.target.name;
    const inputValue = e.target.value;

    if (Object.keys(initalStateEmisorDetail.oDireccion).includes(inputName)) {

      let colonia;
      let ciudad;
      let estado;

      if (inputName === 'nIdColonia') {
        const colSearcehd = colonias.find(
          (col) => col.value === `${inputValue}`
        );
        colonia = colSearcehd ? colSearcehd.text : undefined

      } else if (inputName === 'nIdMunicipio') {
        const ciudaSearched = ciudades.find((city) => city.value === `${inputValue}`);
        ciudad = ciudaSearched ? ciudaSearched.text : undefined

      } else if (inputName === 'nIdEntidad') {
        const estadoSearched = estados.find((est) => est.value === `${inputValue}`);
        estado = estadoSearched ? estadoSearched.text : undefined
      }

      setEmisor({
        ...emisor,
        oDireccion: {
          ...emisor.oDireccion,
          [inputName]: inputValue,
          sNombreColonia: colonia ? colonia : emisor.oDireccion.sNombreColonia,
          sNombreMunicipio: ciudad ? ciudad : emisor.oDireccion.sNombreMunicipio,
          sNombreEntidad: estado ? estado : emisor.oDireccion.sNombreEntidad,
        }
      });

    } else if (inputName === 'sClabe') {

      if (inputValue.length > 18) return;
      if (inputValue !== '' && !regexNums.test(inputValue)) return;
      const clabe = inputValue.length >= 3 ? inputValue.substring(0, 3) : ''
      if (clabe !== '') {
        setBankSearch(clabe);
      }
      setEmisor({ ...emisor, [inputName]: inputValue });

    } else if (inputName === 'nIdIntegracion') {
      const integracionSearch = integraciones.find((inte) => inte.value === inputValue);
      setEmisor({
        ...emisor,
        [inputName]: inputValue,
        sIntegracion: integracionSearch ? integracionSearch.text : emisor.sIntegracion
      });
    } else if (inputName === 'nIdRetencion') {
      const retecionSearched = retenciones.find((rete) => rete.value === inputValue);
      setEmisor({
        ...emisor,
        [inputName]: inputValue,
        sRetencion: retecionSearched ? retecionSearched.text : emisor.sRetencion
      });
    } else if (inputName === 'nIdIVA') {
      const ivaSearched = ivas.find((iv) => iv.value === inputValue);
      setEmisor({
        ...emisor,
        [inputName]: inputValue,
        nIVA: ivaSearched.valDouble
      });
      dispatch(setIvaOperativoSelected(ivaSearched.valDouble));

    } else if (escaloandosSwitch.includes(inputName)) {
      setEmisor({
        ...emisor,
        [inputName]: e.target.checked
      })

      if (e.target.checked) {
        if (inputName === 'isActiveEscTicket') {

          setEmisor({
            ...emisor,
            [inputName]: e.target.checked,
            isActiveEscOperacion: false,
          });

          dispatch(cleanEscCantidadForm());

        } else if (inputName === 'isActiveEscOperacion') {

          setEmisor({
            ...emisor,
            [inputName]: e.target.checked,
            isActiveEscTicket: false,
          });

          dispatch(cleanEscalonado());

        } else if (inputName === 'isIndirecto') {
          setEmisor({
            ...emisor,
            [inputName]: e.target.checked,
            nIdIntegrador: 0,
            nPorcentajeComision: ""
          });
        }
      }

      if (inputName === 'isActiveEscTicket') {
        dispatch(cleanEscalonado());

      } else if (inputName === 'isActiveEscOperacion') {
        dispatch(cleanEscCantidadForm());
      }

    } else if (liquidacionSwitch.includes(inputName)) {
      if (
        emisor.isActiveCashOut &&
        inputName === 'isActiveCashIn' &&
        e.target.checked
      ) {
        setOpenToast(true, 'Se requiere alguna programación de liquidación');
        return;

      }
      if (
        emisor.isActiveCashIn &&
        inputName === 'isActiveCashOut' &&
        e.target.checked
      ) {
        setOpenToast(true, 'Se requiere alguna programación de liquidación');
        return;

      }
      setEmisor({ ...emisor, [inputName]: e.target.checked });

    } else if (switches.includes(inputName)) {

      setEmisor({ ...emisor, [inputName]: e.target.checked });

    } else if (!!documentos.find((doc) => doc.value === inputName)) {

      if (e.target.files && e.target.files[0].type !== 'application/pdf') return;
      console.log(inputName)
      setEmisor({
        ...emisor,
        documentos: {
          ...emisor.documentos,
          [inputName]: e.target.files ? e.target.files[0] : ''
        }
      });

    } else {

      setEmisor({ ...emisor, [inputName]: inputValue });
    }

  };

  const onChangeInput = async (value) => {
    console.log(value)
    if ('nCodigoPostal' in value) {
      console.log(1)
      const inputValue = value.nCodigoPostal;
      if (inputValue !== '' && !regexNums.test(inputValue)) return;
      setEmisor({
        ...emisor,
        oDireccion: {
          ...emisor.oDireccion,
          ...value
        }
      });

      if (inputValue !== '' && inputValue.length === 5) {
        await dispatch(startLoadColonias(inputValue));
        //setDireccionSearched();
      }
    } else if (
      Object.keys(initalStateEmisorDetail.oDireccion).includes(Object.keys(value)[0])
    ) {
      console.log(2)
      setEmisor({
        ...emisor,
        oDireccion: {
          ...emisor.oDireccion,
          ...value
        }
      });

    } else {
      console.log(3)
      setEmisor({ ...emisor, ...value });
    }
  }

  const escalonadoHasChanged = (oEslonadosOperacion = []) => {

    let hasChanged = false;
    if (!setAllowComponent('editarEscalonadaVigente')) return hasChanged
    emisorBackup.oEslonadosOperacion.map((itemBack) => {
      if (!hasChanged) {

        const comision = oEslonadosOperacion.find(item => {
          return (
            parseInt(item.nNumOpeMin) === parseInt(itemBack.nNumOpeMin)
            && parseInt(item.nNumOpeMax) === parseInt(itemBack.nNumOpeMax)
            && parseFloat(item.nCosto) === parseFloat(itemBack.nCosto)
          )
        })

        if (!comision) hasChanged = true
      }
    })

    return hasChanged
  }

  const setDireccionSearched = () => {
    const colSearcehd = colonias.find(
      (col) => col.value === `${direccionSearched.colonia}`
    );
    setEmisor({
      ...emisor,
      oDireccion: {
        ...emisor.oDireccion,
        nIdColonia: direccionSearched.colonia,
        nIdEntidad: direccionSearched.estado,
        sNombreEntidad: direccionSearched.estado,
        nIdMunicipio: direccionSearched.ciudad,
        sNombreMunicipio: direccionSearched.ciudad,
        sNombreColonia: colSearcehd ? colSearcehd.text : ''
      }
    });
  }

  const setBankSearch = (idBanco) => {
    const bancoSearch = bancos.find(
      (banco) => banco.nIdBanco === parseInt(idBanco.toString())
    );

    dispatch(setBancoSearched(
      bancos.length > 0 && bancoSearch ? bancoSearch.value : '',
    ));

    if (!!!bancoSearch) {
      setOpenToast(true, 'No se encontro banco');
      return;
    }
    bancoEncontrado.current = bancoSearch ? bancoSearch.text : '';
    setBanco();


  }

  const setBanco = () => {
    setEmisor({
      ...emisor,
      nIdBanco: bancoSearched,
      sNombreBanco: bancoEncontrado.current
    })
  }

  const onBlur = (e) => {
    const inputName = e.target.name;
    const inputValue = e.target.value;

    if (inputName === 'sClabe') {
      if (inputValue.length > 18) return;
      const clabe = inputValue.length >= 3 ? inputValue.substring(0, 3) : ''
      if (clabe !== '') {
        setBankSearch(clabe)
      }

    }
  }

  const clikcOpenViewPdf = async (objectValue) => {
    if (objectValue !== '' && objectValue instanceof Blob) {
      const data = await getToBase64(objectValue);
      openMoldaViewPdf(data);
    } else {
      const { ok, msj, url } = await dispatch(signedURL(objectValue));
      let name = objectValue.split('/')
      name = name[name.length - 1]
      if (ok) {
        fetch(url)
          .then(res => res.blob())
          .then(async (blob) => {
            await getToBase64(blob).then((resp) => {
              openMoldaViewPdf(resp, name);
            })
          })
      } else {
        setOpenToast(!ok, msj);
      }
    }
  };

  const loadBancos = async (idBanco = '000') => {
    if (bancos.length > 0) return;
    await dispatch(startLoadBancos(idBanco));
  }

  const onLoadDatosOperativos = async () => {
    if (retenciones.length === 0) await dispatch(startLoadRetenciones());
    if (integraciones.length === 0) await dispatch(startLoadIntegracion());
  }

  const onLoadTiposLiquidacion = async () => {
    if (tiposLiquidacion.length === 0) await dispatch(startLoadTiposLiquidacion());
  }

  const onLoadDatosFact = async () => {
    if (!cfdis.length || !formasPago.length || !metodosPago.length || !regimensFiscal.length || !productoServicios.length || !unidades.length) return (await dispatch(startLoadDatosFacturacion({})));
    return {
      cfdis,
      formasPago,
      metodosPago,
      regimensFiscal,
      unidades,
      productoServicios,
    }
  };

  const clickAddContact = async () => {
    openModalContact({});
  };

  const clickOpenListContact = async () => {
    openModalListContact();
    if (!isOnEditSection.direccion) {
      disableModalListContactButtons()
    }
  };

  const handleCloseConfirmEscalonado = () => {
    setConfirmEscalonadosChange(false)
  }

  const handleCloseConfirmMensajeTicket = () => {
    setConfirmMensajeTicketChange(false)
  }


  return {
    bancos,
    bancoSearched,
    ciudades,
    colonias,
    contactosStore,
    direccionSearched,
    documentos,
    emisor,
    estados,
    facturaSelected,
    formFacturacion,
    initialValuesEditSection,
    integraciones,
    isLoading,
    isOnEditSection,
    isOpenContact,
    isOpenListContact,
    modulesChanged,
    open,
    retenciones,
    viewEscaloandos,
    viewFacturacion,
    emisoresList,
    confirmEscalonadosChange,


    clikcOpenViewPdf,
    closeModal,
    dispatch,
    editSection,
    loadBancos,
    onBlur,
    onChange,
    onChangeInput,
    onLoadDatosFact,
    onLoadDatosOperativos,
    onLoadTiposLiquidacion,
    onSaveEdit,
    onSubmitCancelEdit,
    searchEmisor,
    setBanco,
    setDireccionSearched,
    setEmisor,
    setFacturaSelected,
    setIsOnEditSection,
    setModulesChanged,
    setViewEscaloandos,
    setViewFacturacion,
    clickAddContact,
    clickOpenListContact,
    handleCloseConfirmEscalonado,
    handleCloseConfirmMensajeTicket,
    confirmMensajeTicketChange
  };
};
