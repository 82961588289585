import { useEffect, useState } from "react";
import { useMenu } from "../hooks/useMenu";
import {
  Button,
  Grid,
  IconButton,
  Paper,
  Skeleton,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { Stack } from "@mui/system";
import ReactApexChart from "react-apexcharts";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import es from "apexcharts/dist/locales/es.json";
import moment from "moment";

import "../styles/dashboardTable.css";
import { useDashboard } from "../hooks/useDashboard";
import { DashboardTable } from "../components/table/DashboardTable";
import { Visibility } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { useAuthPage } from "../hooks/useAuthPage";

export const DashboardPage = () => {
  const {
    loadInInit,
    comisionistaChart,
    EFRChart,
    operacionesTable,
    archivosConciliacionTable,
    procesosConciliacionTable,
    depositosTable,
    pagosTable,
    getOperaciones,
    onChange,
    date,
    getDashboard,
  } = useDashboard();

  const { setNameSection } = useMenu();
  const [loaded, setLoaded] = useState(false);

  const navigate = useNavigate();

  const { isAllowedPage } = useAuthPage();

  useEffect(() => {
    setNameSection("Dashboard");
  }, [setNameSection]);

  useEffect(() => {
    if (!loaded) {
      getDashboard();
      setLoaded(false);
    }
  }, [loaded]);

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={"es-mx"}>
      <Grid container spacing={2} sx={{ alignItems: "center" }}>
        <Grid item sm={6} xs={12}>
          <h1>
            ¡Buen día
            {localStorage.getItem("userAuth") &&
              JSON.parse(localStorage.getItem("userAuth"))?.name?.split(" ")[0]
              ? ", " +
              JSON.parse(localStorage.getItem("userAuth"))?.name?.split(
                " "
              )[0]
              : ""}
            !
          </h1>
        </Grid>
        <Grid
          item
          sm={6}
          xs={12}
          sx={{ justifyItems: "flex-end", textAlign: "right" }}
        >
          {/* <Button variant="contained" onClick={testConection}>Test</Button> */}
          <DesktopDatePicker
            label="Fecha"
            inputFormat="YYYY-MM-DD"
            renderInput={(params) => <TextField {...params} />}
            value={date}
            onChange={(e) =>
              onChange(null, "date", moment(e["$d"]).format("YYYY-MM-DD"))
            }
            disableFuture
          />
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid
          container
          item
          spacing={2}
          sx={{ alignItems: { sm: "stretch", xs: "flex-start" } }}
        >
          <Grid item sm={4} xs={12}>
            <Stack spacing={2}>
              <Stack>
                <Paper
                  elevation={3}
                  style={{
                    background: "#ffffff",
                    padding: 10,
                    minHeight: 150,
                  }}
                >
                  <Grid container sx={{
                    width: "100%",
                    borderBottom: "1px solid #eaeaea",
                    margin: 0,
                    padding: 0
                  }} justifyContent={"space-between"} alignItems={"center"}>

                    <Typography
                      variant="h6"
                      color="#00609C"
                      style={{
                        fontWeight: 500,
                        fontSize: 14,
                      }}
                    >
                      Archivos de conciliación
                    </Typography>
                    {isAllowedPage('conciliacionArchivos') &&
                      <Tooltip title={"Ver detalles"}>
                        <IconButton size={"small"} onClick={()=>navigate("/conciliacion/archivos")} sx={{height: 20}}><Visibility fontSize="14"/></IconButton>
                      </Tooltip>
                    }
                  </Grid>
                  <DashboardTable
                    rows={archivosConciliacionTable}
                    totalType={"substraction"}
                    tableName={"ArchivosTable"}
                    columns={[
                      {
                        headerName: "",
                        field: "name",
                        type: "text",
                      },
                      {
                        headerName: "Comisionistas",
                        field: "comisionistasCount",
                        type: "number",
                      },
                      {
                        headerName: "Entidades financieras",
                        field: "EFRCount",
                        type: "number",
                      },
                    ]}
                  />
                </Paper>
              </Stack>
              <Stack>
                <Paper
                  elevation={3}
                  style={{
                    background: "#ffffff",
                    padding: 10,
                    minHeight: 150,
                  }}
                >
                  <Typography
                    variant="h6"
                    color="#00609C"
                    style={{
                      fontWeight: 500,
                      fontSize: 14,
                      borderBottom: "1px solid #eaeaea",
                    }}
                  >
                    Liquidaciones (Depósitos)
                  </Typography>
                  <DashboardTable
                    rows={depositosTable}
                    totalType={"substraction"}
                    tableName={"DepositosTable"}
                    columns={[
                      {
                        headerName: "",
                        field: "name",
                        type: "text",
                      },
                      {
                        headerName: "Comisionistas",
                        field: "comisionistasCount",
                        type: "number",
                      },
                      {
                        headerName: "Importe",
                        field: "comisionistasSum",
                        type: "price",
                      },
                      {
                        headerName: "Entidades financieras",
                        field: "EFRCount",
                        type: "number",
                      },
                      {
                        headerName: "Importe",
                        field: "EFRSum",
                        type: "price",
                      },
                    ]}
                  />
                </Paper>
              </Stack>
            </Stack>
          </Grid>
          <Grid item sm={4} xs={12}>
            <Stack spacing={2}>
              <Stack>
                <Paper
                  elevation={3}
                  style={{
                    background: "#ffffff",
                    padding: 10,
                    minHeight: 150,
                  }}
                >
                  <Typography
                    variant="h6"
                    color="#00609C"
                    style={{
                      fontWeight: 500,
                      fontSize: 14,
                      borderBottom: "1px solid #eaeaea",
                    }}
                  >
                    Procesos de conciliación
                  </Typography>
                  <DashboardTable
                    rows={procesosConciliacionTable}
                    totalType={"substraction"}
                    tableName={"ArchivosTable"}
                    columns={[
                      {
                        headerName: "",
                        field: "name",
                        type: "text",
                      },
                      {
                        headerName: "Comisionistas",
                        field: "comisionistasCount",
                        type: "number",
                      },
                      {
                        headerName: "Entidades financieras",
                        field: "EFRCount",
                        type: "number",
                      },
                    ]}
                  />
                </Paper>
              </Stack>
              <Stack>
                <Paper
                  elevation={3}
                  style={{
                    background: "#ffffff",
                    padding: 10,
                    minHeight: 150,
                  }}
                >
                  <Typography
                    variant="h6"
                    color="#00609C"
                    style={{
                      fontWeight: 500,
                      fontSize: 14,
                      borderBottom: "1px solid #eaeaea",
                    }}
                  >
                    Liquidaciones (Pagos)
                  </Typography>
                  <DashboardTable
                    rows={pagosTable}
                    totalType={"substraction"}
                    tableName={"PagosTable"}
                    columns={[
                      {
                        headerName: "",
                        field: "name",
                        type: "text",
                      },
                      {
                        headerName: "Comisionistas",
                        field: "comisionistasCount",
                        type: "number",
                      },
                      {
                        headerName: "Importe",
                        field: "comisionistasSum",
                        type: "price",
                      },
                      {
                        headerName: "Entidades financieras",
                        field: "EFRCount",
                        type: "number",
                      },
                      {
                        headerName: "Importe",
                        field: "EFRSum",
                        type: "price",
                      },
                    ]}
                  />
                </Paper>
              </Stack>
            </Stack>
          </Grid>
          <Grid item sm={4} xs={12}>
            <Paper
              elevation={3}
              style={{
                background: "#ffffff",
                padding: 10,
                height: "calc(100% - 20px)",
              }}
            >
              <Typography
                variant="h6"
                color="#00609C"
                style={{
                  fontWeight: 500,
                  fontSize: 14,
                  borderBottom: "1px solid #eaeaea",
                }}
              >
                Operaciones
              </Typography>
              <DashboardTable
                rows={operacionesTable.filter(pro => pro.nFlujoImporte === 1)}
                tableName={"OperacionesTable"}
                columns={[
                  {
                    headerName: "Productos Cash In",
                    field: "name",
                    type: "text",
                    style: { width: 200 }
                  },
                  {
                    headerName: "Cantidad",
                    field: "cantidad",
                    type: "number",
                  },
                  {
                    headerName: "Volumen operado",
                    field: "volumen",
                    type: "price",
                  },
                ]}
              />

              <DashboardTable
                rows={operacionesTable.filter(pro => pro.nFlujoImporte === 0)}
                tableName={"OperacionesTable"}
                columns={[
                  {
                    headerName: "Productos Cash Out",
                    field: "name",
                    type: "text",
                    style: { width: 200 }
                  },
                  {
                    headerName: "Cantidad",
                    field: "cantidad",
                    type: "number",
                  },
                  {
                    headerName: "Volumen operado",
                    field: "volumen",
                    type: "price",
                  },
                ]}
              />
            </Paper>
          </Grid>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Paper
            elevation={3}
            style={{
              background: "#ffffff",
              padding: 10,
              minHeight: 150,
            }}
          >
            <Typography
              variant="h6"
              color="#00609C"
              style={{
                fontWeight: 500,
                fontSize: 14,
                borderBottom: "1px solid #eaeaea",
              }}
            >
              Operaciones comisionistas
            </Typography>
            <ReactApexChart
              options={chartsOptions(EFRChart.categories, "Operaciones comisionistas" + " " + moment(date).format("YYYY-MM-DD"))}
              series={EFRChart.series}
              type="bar"
            />
          </Paper>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Paper
            elevation={3}
            style={{
              background: "#ffffff",
              padding: 10,
              minHeight: 150,
            }}
          >
            <Typography
              variant="h6"
              color="#00609C"
              style={{
                fontWeight: 500,
                fontSize: 14,
                borderBottom: "1px solid #eaeaea",
              }}
            >
              Operaciones Entidades Financieras Reguladas
            </Typography>
            <ReactApexChart
              options={chartsOptions(comisionistaChart.categories, "Operaciones EFR" + " " + moment(date).format("YYYY-MM-DD"))}
              series={comisionistaChart.series}
              type="bar"
            />
          </Paper>
        </Grid>
      </Grid>
    </LocalizationProvider>
  );
};

const chartsOptions = (categories, filename) => {
  return {
    colors: [
      "#00609C",
      "#007C8F",
      "#009782",
      "#00B275",
      "#00CD68",
      "#00E091",
      "#69C2A0",
      "#B4D576",
      "#EDE357",
      "#FFE74C",
    ],
    chart: {
      type: "bar",
      stacked: true,
      locales: [es],
      defaultLocale: "es",
      toolbar: {
        export: {
          csv: { filename, headerCategory: 'Socio comercial' },
          svg: { filename },
          png: { filename }
        },
      }
    },
    plotOptions: {
      bar: {
        horizontal: true,
        dataLabels: {
          total: {
            enabled: true,
            offsetX: 0,
            style: {
              fontSize: '13px',
              fontWeight: 900
            }
          }
        }
      },
    },
    stroke: {
      width: 0,
      colors: ["#fff"],
    },
    xaxis: {
      categories,
      labels: {
        formatter: function (val) {
          return parseInt(val);
        },
      },
    },
    yaxis: {
      title: {
        text: undefined,
      },
    },
    tooltip: {
      y: {
        formatter: function (val) {
          return parseInt(val);
        },
      },
    },
    fill: {
      opacity: 1,
    },
    legend: {
      position: "bottom",
      horizontalAlign: "left",
    },
  };
};
