import { Close, PersonAdd, Person } from '@mui/icons-material';
import { Alert, Button, Collapse, Grid, IconButton, Typography, createTheme } from '@mui/material';
import { Box } from '@mui/system';

import { TextInput } from '../form/TextInput';
import { ModalBase } from './ModalBase';
import { regexEmail, regexTelefono } from '../../helpers/regexs';
import { SelectInput } from '../form/SelectInput';

import PropTypes from 'prop-types';
import { useModalContact } from '../../hooks/useModalContact';
import { useEffect } from 'react';
import { SearchInput } from '../form/SearchInput';
import { ThemeProvider } from 'styled-components';
import { BlueSwitch } from '../../helpers/stylesMaterial';

const iconTheme = createTheme({
  status: {
    danger: "#e53e3e",
  },
  palette: {
    primary: {
      main: "#00609C",
      dark: "#053e85",
    },
    neutral: {
      main: "#00CD68",
      light: "#053e85",
      dark: "#053e85",
      contrastText: "#fff",
    },
  },
});

export const ModalContact = ({ saveIn }) => {

  const {
    error,
    errorInitialValues,
    formContact,
    isOpenContact,
    procesos,

    onChange,
    onChangeSelect,
    onChangeSwitch,
    onClose,
    onSave,
    setError,
    setEditContact
  } = useModalContact({ saveIn });

  useEffect(() => {
    if (isOpenContact.open && isOpenContact.edit) {
      setEditContact(isOpenContact.contact);
    }
    // eslint-disable-next-line
  }, [isOpenContact.open])


  return (
    <ThemeProvider theme={iconTheme}>
      <ModalBase open={isOpenContact.open} title="" style={{ width: '750px', height: 'auto' }}>
        <Box
          sx={{ width: '100%', height: '100%', display: 'flex', flexDirection: 'column' }}
        >
          <TitleModal title="Contacto" typeOperation={isOpenContact.edit ? (isOpenContact.view ? 'view' : 'edit') : 'add'} />
          <Collapse in={error.open}>
            <Alert
              severity="error"
              sx={{ width: '96%', margin: '0px auto', marginBottom: '10px' }}
              action={
                <IconButton
                  aria-label="close"
                  color="inherit"
                  size="small"
                  onClick={() => setError(errorInitialValues)}
                >
                  <Close fontSize="inherit" />
                </IconButton>
              }
            >
              {error.msg}
            </Alert>
          </Collapse>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography variant="h6" sx={{ color: '#00609C' }}>Información del contacto</Typography>
            </Grid>
            <>
              <Grid item xs={12} sm={6}>
                <TextInput
                  id="nombre"
                  name="nombre"
                  label="Nombre"
                  value={formContact.nombre}
                  onChangeRoot={onChange}
                  disabled={isOpenContact.view}
                  require
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextInput
                  id="apellido"
                  name="apellido"
                  label="Apellido paterno"
                  value={formContact.apellido}
                  onChangeRoot={onChange}
                  disabled={isOpenContact.view}
                  require
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextInput
                  id="segundoApellido"
                  name="segundoApellido"
                  label="Apellido materno"
                  value={formContact.segundoApellido}
                  disabled={isOpenContact.view}
                  onChangeRoot={onChange}
                />
              </Grid>
              <Grid item xs={12} sm={6}>

                <TextInput
                  id="area"
                  name="area"
                  label="Área"
                  value={formContact.area}
                  onChangeRoot={onChange}
                  disabled={isOpenContact.view}
                  caracteres={50}
                  require
                />
              </Grid>
              <Grid item xs={12} sm={6}>

                <TextInput
                  id="puesto"
                  name="puesto"
                  label="Puesto"
                  value={formContact.puesto}
                  onChangeRoot={onChange}
                  disabled={isOpenContact.view}
                  caracteres={50}
                  require
                />
              </Grid>
              <Grid item xs={12} sm={6}>

                <TextInput
                  id="comentarios"
                  name="comentarios"
                  label="Comentarios"
                  value={formContact.comentarios}
                  disabled={isOpenContact.view}
                  onChangeRoot={onChange}
                  caracteres={255}
                />
              </Grid>
            </>

            <Grid item xs={12}>
              <Typography variant="h6" sx={{ color: '#00609C' }}>Datos de contacto</Typography>
            </Grid>

            <Grid item xs={12} sm={6}>

              <TextInput
                id="email"
                name="email"
                label="Correo"
                value={formContact.email}
                onChangeRoot={onChange}
                disabled={isOpenContact.view}
                expresionRegular={regexEmail}
                erroMsg="Correo inválido"
                require
              />
            </Grid>
            <Grid item xs={12} sm={6}>

              <TextInput
                id="telefono"
                name="telefono"
                label="Teléfono"
                value={formContact.telefono}
                onChangeRoot={onChange}
                caracteres="10"
                expresionRegular={regexTelefono}
                erroMsg="Teléfono inválido"
                require
                disabled={isOpenContact.view}
              />
            </Grid>
            <Grid item xs={12} sm={12}>
              <SearchInput
                id="proceso"
                name="proceso"
                onChange={onChangeSelect}
                label="Proceso(s) a notificar"
                getOptionLabel={"sDescripcion"}
                getExtraData={"sEventoNotificacion"}
                getIndexLabel={"nIdProceso"}
                multiple
                options={procesos}
                value={formContact.proceso}
                disabled={isOpenContact.view}
              />
            </Grid>
          </Grid>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              width: '100%',
              justifyContent: 'end',
              marginTop: 15
            }}
          >
            <Button onClick={onClose} sx={{ color: '#00609C' }}>
              cerrar
            </Button>
            {!isOpenContact.view &&
              <Button
                onClick={onSave}
                sx={{
                  width: '20%',
                  // backgroundColor: '#93cf47',
                  backgroundColor: '#00CD68',
                  color: '#FFFFFF',
                  padding: '8px 16px',
                  marginLeft: '10px',
                  '&:hover': {
                    background: '#00CD68'
                  }
                }}
              >
                Guardar
              </Button>}
          </div>
        </Box>
      </ModalBase>
    </ThemeProvider>
  );
};

const TitleModal = ({ title, typeOperation }) => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        width: '100%',
        marginBottom: '10px',
        justifyContent: 'space-between'
      }}
    >
      <div style={{ display: 'flex', flexDirection: 'row' }}>
        {typeOperation === 'add' ?
          <PersonAdd fontSize={'large'} sx={{ color: '#00CD68', fontSize: '45px' }} />
          : <Person fontSize={'large'} sx={{ color: '#00CD68', fontSize: '45px' }} />}

        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <Typography
            variant="p"
            sx={{
              marginLeft: '15px',
              fontSize: '14px',
              marginBottom: '4px'
            }}
          >
            {typeOperation === 'add' ? 'Agregar' : typeOperation === 'edit' ? 'Editar' : 'Ver'}
          </Typography>
          <Typography
            variant="p"
            sx={{
              marginLeft: '15px',
              color: '#00609C',
              fontSize: '20px'
            }}
          >
            {title}
          </Typography>
        </div>
      </div>
    </Box>

  );
};

ModalContact.propTypes = {
  saveIn: PropTypes.string
};
