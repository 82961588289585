import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  notMatchPassword: false,
  incorrectFormat: false
};

export const newPasswordSlice = createSlice({
  name: 'newPassword',
  initialState,
  reducers: {
    setMatchPassword: (state, action) => {
      state.notMatchPassword = action.payload;
    },
    setIncorrectFormat: (state, action) => {
      state.incorrectFormat = action.payload;
    }
  }
});

export const { setMatchPassword, setIncorrectFormat } = newPasswordSlice.actions;
