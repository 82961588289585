import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  registrosBancariosTable:  {
    columns: [],
    rows   : []
  },
  polizasTable:  {
    columns: [],
    rows   : []
  },
  cortesCadenas: [],
  cortesEmisores: [],
  ordenesPagoCadenas: [],
  ordenesPagoEmisores: [],
  cortesEscalonadosEmisores: [],
  registrosBancariosCadena: [],
  registrosBancariosEmisor: [],
  registrosBancariosCargosCadena: [],
  registrosBancariosCargosEmisor: [],
  registrosBancariosBadge: 0,
  registrosBancariosCargosBadge: 0
};

export const contabilidadSlice = createSlice({
  name: 'contabilidad',
  initialState,
  reducers: {
    setPolizasTable: (state, action) => {
      state.polizasTable = {...action.payload};
    },
    setRegistrosBancarios: (state, action) => {
      state.registrosBancariosTable = {...action.payload};
    },
    setCortesCadenas: (state, action) => {
      state.cortesCadenas = [...action.payload];
    },
    setCortesEmisores: (state, action) => {
      state.cortesEmisores = [...action.payload];
    },
    setOrdenesPagoCadenas: (state, action) => {
      state.ordenesPagoCadenas = [...action.payload];
    },
    setOrdenesPagoEmisores: (state, action) => {
      state.ordenesPagoEmisores = [...action.payload];
    },
    setCortesEscalonadosEmisores: (state, action) => {
      state.cortesEscalonadosEmisores = [...action.payload];
    },
    setRegistrosBancariosCadena: (state, action) => {
      state.registrosBancariosCadena = [...action.payload];
    },
    setRegistrosBancariosEmisor: (state, action) => {
      state.registrosBancariosEmisor = [...action.payload];
    },
    setRegistrosBancariosCargosCadena: (state, action) => {
      state.registrosBancariosCargosCadena = [...action.payload];
    },
    setRegistrosBancariosCargosEmisor: (state, action) => {
      state.registrosBancariosCargosEmisor = [...action.payload];
    },
    setRegistrosBancariosBadge: (state, action) => {
      state.registrosBancariosBadge = action.payload;
    },
    setRegistrosBancariosCargosBadge: (state, action) => {
      state.registrosBancariosCargosBadge = action.payload;
    },
  }
});

export const {
  setRegistrosBancarios,
  setPolizasTable,
  setCortesCadenas,
  setCortesEmisores,
  setCortesEscalonadosEmisores,
  setRegistrosBancariosCadena,
  setRegistrosBancariosEmisor,
  setRegistrosBancariosBadge,
  setOrdenesPagoCadenas,
  setOrdenesPagoEmisores,
  setRegistrosBancariosCargosCadena,
  setRegistrosBancariosCargosEmisor,
  setRegistrosBancariosCargosBadge
} = contabilidadSlice.actions;
