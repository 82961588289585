import DashboardTwoToneIcon from '@mui/icons-material/DashboardTwoTone';
import StoreMallDirectoryTwoToneIcon from '@mui/icons-material/StoreMallDirectoryTwoTone';
import ListIcon from '@mui/icons-material/List';
import AccountBalanceTwoToneIcon from '@mui/icons-material/AccountBalanceTwoTone';
import AssessmentTwoToneIcon from '@mui/icons-material/AssessmentTwoTone';
import CachedIcon from '@mui/icons-material/Cached';
import RequestQuoteOutlinedIcon from '@mui/icons-material/RequestQuoteOutlined';
import StairsOutlinedIcon from '@mui/icons-material/StairsOutlined';
import AccessAlarmRoundedIcon from '@mui/icons-material/AccessAlarmRounded';
import CalculateTwoToneIcon from '@mui/icons-material/CalculateTwoTone';
import SummarizeOutlinedIcon from '@mui/icons-material/SummarizeOutlined';
import { AccountBalanceSharp, AddBusiness, AdminPanelSettings, Badge, BadgeTwoTone, DvrTwoTone, GroupTwoTone, Https, HttpsTwoTone, MonitorTwoTone, PersonTwoTone, ReceiptLongOutlined } from '@mui/icons-material';
import PaymentsOutlinedIcon from '@mui/icons-material/PaymentsOutlined';
import DescriptionTwoToneIcon from '@mui/icons-material/DescriptionTwoTone';
import FileCopyOutlinedIcon from '@mui/icons-material/FileCopyOutlined';
import AccountBalanceWalletOutlinedIcon from '@mui/icons-material/AccountBalanceWalletOutlined';
import FactCheckTwoToneIcon from '@mui/icons-material/FactCheckTwoTone';
import FactCheckOutlinedIcon from '@mui/icons-material/FactCheckOutlined';
import ApprovalOutlinedIcon from '@mui/icons-material/ApprovalOutlined';
import AssignmentTurnedInOutlinedIcon from '@mui/icons-material/AssignmentTurnedInOutlined';
import FeedOutlinedIcon from '@mui/icons-material/FeedOutlined';
import FolderOpenOutlinedIcon from '@mui/icons-material/FolderOpenOutlined';
import CompareArrowsOutlinedIcon from '@mui/icons-material/CompareArrowsOutlined';
import DomainVerificationIcon from '@mui/icons-material/DomainVerification';
import GroupOutlinedIcon from '@mui/icons-material/GroupOutlined';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import HolidayVillageIcon from '@mui/icons-material/HolidayVillage';
import StorefrontIcon from '@mui/icons-material/Storefront';
import PercentOutlinedIcon from '@mui/icons-material/PercentOutlined';
import SettingsApplicationsTwoToneIcon from '@mui/icons-material/SettingsApplicationsTwoTone';
import LocationOnTwoToneIcon from '@mui/icons-material/LocationOnOutlined';
import BlockIcon from '@mui/icons-material/Block';

export const menu = [
    {
        key: 0,
        title: 'Dashboard',
        icon: <DashboardTwoToneIcon color="primary" sx={{ marginRight: '5px' }} />,
        sectionName: 'dashboard',
        route: '/dashboard',
        children: []
    },
    {
        key: 1,
        title: 'Comisionistas',
        icon: <StoreMallDirectoryTwoToneIcon color="primary" sx={{ marginRight: '5px' }} />,
        sectionName: '',
        route: '',
        children: [
            {
                key: 2,
                title: 'Agregar',
                icon: <AddBusiness color="neutral" />,
                sectionName: 'agregarCadenas',
                route: '/altas-cadenas'
            },
            {
                key: 4,
                title: 'Ver',
                icon: <ListIcon color="neutral" />,
                sectionName: 'cadenas',
                route: '/ver-cadenas'
            },
            {
                key: 27,
                title: 'Sucursales',
                icon: <StorefrontIcon color="neutral" />,
                sectionName: 'sucursales',
                route: '/sucursales'
            },
        ]
    },
    {
        key: 5,
        title: 'EFR',
        icon: <AccountBalanceTwoToneIcon color="primary" sx={{ marginRight: '5px' }} />,
        sectionName: '',
        route: '',
        children: [
            {
                key: 3,
                title: 'Agregar',
                icon: <AccountBalanceSharp color="neutral" />,
                sectionName: 'agregarEFR',
                route: '/altas-efr'
            },
            {
                key: 6,
                title: 'Ver',
                icon: <ListIcon color="neutral" />,
                sectionName: 'EFR',
                route: '/ver-efr'
            },
        ]
    },
    {
        key: 7,
        title: 'Reportes',
        icon: <AssessmentTwoToneIcon color="primary" sx={{ marginRight: '5px' }} />,
        sectionName: '',
        route: '',
        children: [
            {
                key: 8,
                title: 'Operaciones',
                icon: <CachedIcon color="neutral" />,
                sectionName: 'reporteOperaciones',
                route: '/reporte/operaciones'
            },
            {
                key: 34,
                title: 'Corte operaciones',
                icon: <ReceiptLongOutlined color="neutral" />,
                sectionName: 'reporteCortes',
                route: '/reporte/cortes'
            },
            {
                key: 28,
                title: 'Transacciones',
                icon: <ReceiptLongOutlined color="neutral" />,
                sectionName: 'reporteTransacciones',
                route: '/reporte/transacciones'
            },
            {
                key: 9,
                title: 'Ordenes de pago',
                icon: <RequestQuoteOutlinedIcon color="neutral" />,
                sectionName: 'reporteOrdenesPago',
                route: '/reporte/ordenes-pago'
            },
            {
                key: 10,
                title: 'Comisiones Escalonadas',
                icon: <StairsOutlinedIcon color="neutral" />,
                sectionName: 'reporteEscalonado',
                route: '/reporte/escalonado'
            },
            {
                key: 11,
                title: 'Pagos y cobros',
                icon: <PaymentsOutlinedIcon color="neutral" />,
                sectionName: 'reportePagosCobro',
                route: '/reporte/pagos-cobros'
            },
            {
                key: 12,
                title: 'Estado de cuenta',
                icon: <AccountBalanceWalletOutlinedIcon color="neutral" />,
                sectionName: 'reporteEstadoCuenta',
                route: '/reporte/estado-cuenta'
            },
            {
                key: 30,
                title: 'Comisiones Integrador',
                icon: <PercentOutlinedIcon color="neutral" />,
                sectionName: 'reporteComisionesIntegrador',
                route: '/reporte/comisiones-integrador'
            },
            {
                key: 31,
                title: 'Geolocalización',
                icon: <LocationOnTwoToneIcon color="neutral" />,
                sectionName: 'reporteGeolocalizacion',
                route: '/reporte/geolocalizacion'
            },
            {
                key: 32,
                title: 'Sucursales sin registrar',
                icon: <StorefrontIcon color="neutral" />,
                sectionName: 'reporteSucursalesNoRegistradas',
                route: '/reporte/sucursales-no-registradas'
            },
            {
                key: 33,
                title: '24 horas',
                icon: <AccessAlarmRoundedIcon color="neutral" />,
                sectionName: 'reporte24Horas',
                route: '/reporte/24-horas'
            },
        ]
    },
    {
        key: 13,
        title: 'Contabilidad',
        icon: <CalculateTwoToneIcon color="primary" sx={{ marginRight: '5px' }} />,
        sectionName: '',
        route: '',
        children: [
            {
                key: 14,
                title: 'Registros bancarios',
                icon: <RequestQuoteOutlinedIcon color="neutral" />,
                sectionName: 'registrosBancarios',
                route: '/registrosBancarios'
            },
            {
                key: 15,
                title: 'Pólizas',
                icon: <SummarizeOutlinedIcon color="neutral" />,
                sectionName: 'polizas',
                route: '/polizas'
            },
        ]
    },
    {
        key: 16,
        title: 'Facturación',
        icon: <DescriptionTwoToneIcon color="primary" sx={{ marginRight: '5px' }} />,
        sectionName: '',
        route: '',
        children: [
            {
                key: 17,
                title: 'Facturas',
                icon: <FileCopyOutlinedIcon color="neutral" />,
                sectionName: 'facturas',
                route: '/facturas'
            },
            {
                key: 19,
                title: 'Validación',
                icon: <AssignmentTurnedInOutlinedIcon color="neutral" />,
                sectionName: 'facturasValidacion',
                route: '/facturas-validacion'
            },
        ]
    },
    {
        key: 20,
        title: 'Conciliación',
        icon: <FactCheckTwoToneIcon color="primary" sx={{ marginRight: '5px' }} />,
        sectionName: '',
        route: '',
        children: [
            {
                key: 21,
                title: 'Archivos',
                icon: <FolderOpenOutlinedIcon color="neutral" />,
                sectionName: 'conciliacionArchivos',
                route: '/conciliacion/archivos'
            },
            {
                key: 22,
                title: 'Conciliación manual',
                icon: <CompareArrowsOutlinedIcon color="neutral" />,
                sectionName: 'conciliacionManual',
                route: '/conciliacion/manual'
            },
            {
                key: 23,
                title: 'Pendientes autorizar',
                icon: <DomainVerificationIcon color="neutral" />,
                sectionName: 'conciliacionPendientesAutorizar',
                route: '/conciliacion/pendientes-autorizar'
            },
            {
                key: 24,
                title: 'Reporte general',
                icon: <FeedOutlinedIcon color="neutral" />,
                sectionName: 'conciliacionReporteGeneral',
                route: '/conciliacion/reporte-general'
            },
            {
                key: 25,
                title: 'Reporte Operaciones',
                icon: <ApprovalOutlinedIcon color="neutral" />,
                sectionName: 'conciliacionReporteOperaciones',
                route: '/conciliacion/reporte-operaciones'
            },
        ]
    },
    {
        key: 33,
        title: 'Monitoreo',
        icon: <MonitorTwoTone color="primary" sx={{ marginRight: '5px' }} />,
        sectionName: '',
        route: '',
        children: [
            {
                key: 27,
                title: 'Monitor ERP',
                icon: <DvrTwoTone color="neutral" />,
                sectionName: 'monitorERP',
                route: '/monitoreo/erp'
            },
        ]
    },
    {
        key: 31,
        title: 'Seguridad',
        icon: <HttpsTwoTone color="primary" sx={{ marginRight: '5px' }} />,
        sectionName: '',
        route: '',
        children: [
            {
                key: 27,
                title: 'Usuarios',
                icon: <PersonTwoTone color="neutral" />,
                sectionName: 'usuarios',
                route: '/usuarios/ver'
            },
            {
                key: 28,
                title: 'Usuarios Externos',
                icon: <GroupTwoTone color="neutral" />,
                sectionName: 'usuariosExternos',
                route: '/usuarios-externos/ver'
            },
            {
                key: 32,
                title: 'Perfiles Usuarios',
                icon: <BadgeTwoTone color="neutral" />,
                sectionName: 'perfilesUsuario',
                route: '/perfiles-usuario/ver'
            },
        ]
    },
    {
        key: 26,
        title: 'Administración',
        icon: <SettingsApplicationsTwoToneIcon color="primary" sx={{ marginRight: '5px' }} />,
        sectionName: '',
        route: '',
        children: [
            {
                key: 29,
                title: 'Catálogos',
                icon: <ListIcon color="neutral" />,
                sectionName: 'catalogos',
                route: '/catalogos/ver'
            },
            {
                key: 30,
                title: 'Suspensiones',
                icon: <BlockIcon color="neutral" />,
                sectionName: 'suspensiones',
                route: '/suspensiones/ver'
            },
        ]
    },
]