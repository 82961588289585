import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';

import { Outlet } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useEffect } from 'react';
import MenuDrawer from './components/MenuDrawer';
import { useNavigate } from 'react-router-dom';
import { SubMenu } from './components/SubMenu';
// import { Loading } from '../Loading';

import { createTheme, ThemeProvider } from '@mui/material';
import '../../styles/drawerMenuResponsive.css';
import { useAuthUser } from '../../hooks/useAuthUser';
import { useAuthPage } from '../../hooks/useAuthPage';

const iconTheme = createTheme({
  status: {
    danger: '#e53e3e'
  },
  palette: {
    primary: {
      main: '#00609C',
      darker: '#053e85'
    },
    neutral: {
      main: '#00CD68',
      contrastText: '#fff'
    }
  }
});

const drawerWidth = 180;

function DrawerMenuResponsive(props) {
  const navigate = useNavigate();
  const { user } = useSelector((state) => state.user);
  // const [loaded, setLoaded] = useState(false)
  const { api } = useAuthUser();
  const { loaded } = useSelector((state) => state.catalogo);
  const { setLoaded } = useAuthPage();

  // startPages();
  useEffect(() => {
    if (!loaded) {
      let data = localStorage.getItem('userAuth');
      if (data) {
        data = JSON.parse(data);
        api(data.username, data.email, data.token);
      }
      setLoaded(true);
    } else {
      if (!user?.token) {
        navigate('/login');
      }
    }
    // eslint-disable-next-line
  }, [user, navigate, loaded]);

  const { activeSection } = useSelector((state) => state.menu);

  const { window } = props;
  const [mobileOpen, setMobileOpen] = React.useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const drawer = (
    <ThemeProvider theme={iconTheme}>
      <Toolbar sx={{ justifyContent: 'center' }}>
        <img src="/assets/SofipayLogo.png" alt="Sofipay Logo" width={'100%'} />
      </Toolbar>
      <MenuDrawer handleDrawerToggle={handleDrawerToggle} />
    </ThemeProvider>
  );

  const container = window !== undefined ? () => window().document.body : undefined;

  return (
    <ThemeProvider theme={iconTheme}>
      <Box sx={{ display: 'flex', background: '#f1f1f1' }}>
        {/* <CssBaseline /> */}
        {/* {isLoading && <Loading />} */}
        {/* <Toast /> */}
        <AppBar
          position="fixed"
          sx={{
            width: { sm: `calc(100% - ${drawerWidth}px)` },
            ml: { sm: `${drawerWidth}px` }
          }}
        >
          <Box sx={{ display: 'flex', width: '100%' }}>
            <Toolbar sx={{ display: 'flex', width: '100%' }}>
              <IconButton
                color="inherit"
                aria-label="open drawer"
                edge="start"
                onClick={handleDrawerToggle}
                sx={{ mr: 2, display: { sm: 'none' } }}
              >
                <MenuIcon />
              </IconButton>
              <Typography
                variant="h6"
                noWrap
                component="div"
                sx={{ width: '90%', marginLeft: '5px' }}
              >
                {activeSection}
              </Typography>
              <Box
                sx={{
                  display: 'flex',
                  width: '10%',
                  textAlign: 'left',
                  justifyContent: 'right',
                  textJustify: 'center',
                  alignItems: 'center'
                }}
              >
                <SubMenu />
              </Box>
            </Toolbar>
          </Box>
        </AppBar>
        <Box
          component="nav"
          sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
          aria-label="mailbox folders"
        >
          {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
          <Drawer
            container={container}
            variant="temporary"
            open={mobileOpen}
            onClose={handleDrawerToggle}
            ModalProps={{
              keepMounted: true // Better open performance on mobile.
            }}
            sx={{
              display: { xs: 'block', sm: 'none' },
              '& .MuiDrawer-paper': {
                boxSizing: 'border-box',
                width: drawerWidth,
                borderRadius: 0
              }
            }}
          >
            {drawer}
          </Drawer>
          <Drawer
            variant="permanent"
            sx={{
              display: { xs: 'none', sm: 'block' },
              '& .MuiDrawer-paper': {
                boxSizing: 'border-box',
                width: drawerWidth,
                borderRadius: '0 15px 15px 0',
                paddingTop: 1,
                paddingBottom: 1
              }
            }}
            open
          >
            {drawer}
          </Drawer>
        </Box>
        <Box
          className="main-box"
          component="main"
          sx={{
            flexGrow: 1,
            p: 3,
            width: { sm: `calc(100% - ${drawerWidth}px)` },
            height: 'calc(100vh - 100px)',
            overflowY: 'auto',
            // border: 1,
            marginTop: '65px'
            // width: { sm: `calc(100% - ${drawerWidth}px)` }
          }}
        >
          <Outlet />
        </Box>
      </Box>
    </ThemeProvider>
  );
}

export default DrawerMenuResponsive;
