import { Box, Card, Typography } from '@mui/material';
import { useSelector } from 'react-redux';

export const NotAllowPage = () => {
  const { isLoading } = useSelector((state) => state.ui);
  return ( !isLoading &&
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
        width: '100%',
        height: '100 vh',
        alignItems: 'center',
        justifyContent: 'center',
        verticalAlign: 'center'
      }}
    >
      <Card
        className="card-login"
        elevation={24}
        sx={{
          height: '70%',
          textAlign: 'center',
          verticalAlign: 'center'
        }}
      >
        <img
          src="/assets/SofipayLogo.png"
          alt="LogoSofipay - Deny Access"
          style={{ paddingTop: '20px', paddingBottom: '20px' }}
        />
        <Typography variant="h4" sx={{ fontWeight: 'bold' }}>
          Acceso Denegado
        </Typography>
        <Box
          sx={{
            display: 'flex',
            width: '100%',
            justifyContent: 'center',
            paddingTop: '20px',
            paddingBottom: '20px'
          }}
        >
          <section>
            <Typography>No cuentas con permisos asignados. </Typography>
            <Typography>Comunícate con tu supervisor para obtener permisos.</Typography>
          </section>
        </Box>
      </Card>
    </Box>
  );
};