import {
  Box,
  Typography,
  Button,
  createTheme,
  ThemeProvider,
  TextField,
  Divider,
  IconButton
} from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import '../styles/forgotPassword.css';
import { useForgotPassword } from '../hooks/useForgotPasswordPage';
import LoginIcon from '@mui/icons-material/Login';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useState } from 'react';
import { Loading } from '../components/Loading';

export const ForgotPasswordPage = () => {
  const {
    sendConfirmationCode,
    setNewPassword,
    sendCodeDisplay,
    changePasswordDisplay,
    resetState
  } = useForgotPassword();
  const navigate = useNavigate();
  const { isLoading } = useSelector((state) => state.ui);

  const [formData, setFormData] = useState({
    username: '',
    password: '',
    code: ''
  });

  const { username, password, code } = formData;

  const onChange = (e) => {
    setFormData((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value
    }));
  };

  const iconTheme = createTheme({
    status: {
      danger: '#e53e3e'
    },
    palette: {
      primary: {
        main: '#00609C',
        darker: '#053e85'
      },
      neutral: {
        main: '#00D487',
        contrastText: '#fff'
      }
    }
  });

  const [values, setValues] = useState({
    showPassword: false
  });

  const handleClickShowPassword = () => {
    setValues({
      ...values,
      showPassword: !values.showPassword
    });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const onClickBackToLogin = () => {
    navigate('/login');
    resetState();
  };

  return (
    <ThemeProvider theme={iconTheme}>
      {isLoading && <Loading />}

      <Box
        sx={{
          position: 'relative',
          display: 'flex',
          flexDirection: 'column',
          width: '100%',
          height: '96.8vh',
          alignItems: 'center',
          justifyContent: 'center',
          verticalAlign: 'center',
          background: "#f9f9f9"
        }}
      >
        <Box
          sx={{
            width: '350px',
            height: '90%',
            textAlign: 'center',
            display: sendCodeDisplay, //se muestra primero este
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center'
          }}
        >
          <img
            src="/assets/SofipayLogo.png"
            alt="LogoSofipay"
            width={'250px'}
            style={{ marginBottom: 30 }}
          />
          <Typography variant="h5" color="primary">
            Código de confirmación
          </Typography>
          <Typography variant="subtitle1" marginBottom={1} width="100%">
            Se enviará un código de verificación al correo electrónico vinculado a tu
            usuario, ingresar a continuación tu nombre de usuario:
          </Typography>
          <TextField
            label="Usuario"
            color="neutral"
            focused
            id="username"
            name="username"
            onChange={onChange}
            variant="outlined"
            sx={{ margin: 2, width: '100%' }}
          />
          <Button
            variant="contained"
            size="large"
            color="neutral"
            onClick={() => sendConfirmationCode(username)}
            sx={{ width: '90%' }}
          >
            Enviar código
          </Button>
          <Button size="small" color="neutral" onClick={onClickBackToLogin} style={{ marginTop: 10 }}>
            Cancelar
          </Button>
        </Box>
        <Box
          sx={{
            width: '350px',
            height: '90%',
            textAlign: 'center',
            display: changePasswordDisplay, //se muestra luego este
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center'
          }}
        >
          <img
            src="/assets/SofipayLogo.png"
            alt="LogoSofipay"
            width={'250px'}
            style={{ marginBottom: 30 }}
          />
          <Typography variant="h5" color="primary">
            Nueva contraseña
          </Typography>
          <Typography variant="subtitle1">
            Ingresa el código enviado a tu correo y tu nueva contraseña:
          </Typography>
          <TextField
            // error
            // helperText="mensaje de error"
            label="Código de confirmación"
            color="neutral"
            focused
            id="code"
            name="code"
            value={code}
            placeholder="Ingresa el código de verificación"
            onChange={onChange}
            sx={{ marginTop: 2, width: '100%' }}
          />
          <TextField
            label="Contraseña"
            color="neutral"
            focused
            type={values.showPassword ? 'text' : 'password'}
            id="password"
            name="password"
            value={password}
            placeholder="Ingresa tu contraseña"
            onChange={onChange}
            sx={{ margin: 2, width: '100%' }}
            InputProps={{
              endAdornment: (
                <IconButton
                  onClick={handleClickShowPassword}
                  onMouseDown={handleMouseDownPassword}
                >
                  {values.showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              )
            }}
          />
          <Typography variant="caption" sx={{mb: 2}}>
            La contraseña debe tener de 8 a 20 caracteres incluyendo letras mayúsculas y minúsculas, un número y un caracter especial.
          </Typography>
          <Button
            variant="contained"
            size="large"
            color="neutral"
            onClick={() => setNewPassword(username, code, password)}
            sx={{ width: '90%' }}
          >
            Confirmar contraseña
          </Button>
          <Button size="small" color="neutral" onClick={onClickBackToLogin} style={{ marginTop: 10 }}>
            Cancelar
          </Button>
        </Box>
      </Box>
    </ThemeProvider>
  );
};
