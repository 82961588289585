import { forwardRef } from 'react';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Slide,
  Typography
} from '@mui/material';
import { Info } from '@mui/icons-material';
import { useModal } from '../../hooks/useModal';

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export const DialogHelp = () => {
  const { isOpenDialogHelp, closeHelpDialog } = useModal();
  const { open, title, text } = isOpenDialogHelp;

  const handleClose = () => {
    closeHelpDialog();
  };

  return (
    <Dialog
      open={open}
      TransitionComponent={Transition}
      keepMounted
      onClose={handleClose}
      aria-describedby="alert-dialog-slide-description"
      // fullScreen={fullScreen}
      maxWidth="md"

      // sx={{ width: '300px' }}
    >
      <DialogContent>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            minWidth: '500px',
            maxWidth: '100%',
            justifyContent: 'center'
          }}
        >
          <Title title={title} typeOperation="Infomación" />
          <Box sx={{ padding: '10px' }}>
            <Typography
              variant="p"
              sx={{
                fontSize: '14px',
                textAlign: 'center'
              }}
            >
              {text}
            </Typography>
          </Box>
        </Box>
      </DialogContent>

      <DialogActions>
        <Button onClick={handleClose} sx={{ color: '#00609C' }}>
          Cerrar
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const Title = ({ title, typeOperation }) => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        width: '100%',
        marginBottom: '10px',
        justifyContent: 'space-between'
      }}
    >
      <div style={{ display: 'flex', flexDirection: 'row' }}>
        <Info fontSize={'large'} sx={{ color: '#00CD68', fontSize: '45px' }} />
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <Typography
            variant="p"
            sx={{
              marginLeft: '15px',
              fontSize: '14px',
              marginBottom: '4px'
            }}
          >
            {typeOperation}
          </Typography>
          <Typography
            variant="p"
            sx={{
              marginLeft: '15px',
              color: '#00609C',
              fontSize: '20px',
              textTransform: 'capitalize'

            }}
          >
            {title}
          </Typography>
        </div>
      </div>
    </Box>
  );
};
