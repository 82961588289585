import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import moment from "moment";
import { useModal } from './useModal';
import { getComisionesEscalonadas, insertComisionProgramacion, updateComisionProgramacion, updateComisionProgramacionEstatus, updateComisionVigente } from '../slices/emisor/emisorThunk';
import { useExportFile } from './useExportFile';
import { parseEscCantidadToOperacion, parseEscOperacionToCantidadEnhanced } from '../helpers/modalConfirmHelper';
import { setListEscCantidadForm } from '../slices/cadena/cadenaSlice';
import { validListEscCantidad } from '../helpers/forms-validate';

export const useModalComisionesEscalonadas = () => {
  const dispatch = useDispatch();
  const { isOpenComisionesEscalonadas, closeModalComisionesEscalonadas, setOpenToast } = useModal();
  const { comisionesEscalonadas } = useSelector((state) => state.emisor);
  const { escCantidadForms } = useSelector((state) => state.cadena);
  const [screen, setScreen] = useState('inicio')
  const [bitacoraForm, setBitacoraForm] = useState({
    dFecInicial: moment().subtract(1, 'month').format("YYYY-MM-DD"),
    dFecFinal: moment().format("YYYY-MM-DD"),
    filteredData: false
  })
  const [bitacora, setBitacora] = useState({
    rows: [],
    columns: bitacoraColumns
  })
  const [vencidas, setVencidas] = useState({
    rows: [],
    columns: vencidosColumns
  })
  const [proxima, setProxima] = useState({})
  const [detalle, setDetalle] = useState({})
  const [selectedTab, setSelectedTab] = useState("vigentes")

  const [modalLoading, setModalLoading] = useState(false)
  const { downloadFile } = useExportFile()
  const [confirmDialog, setConfirmDialog] = useState({
    open: false,
    message: "",
    type: ""
  })
  const [enableEdit, setEnableEdit] = useState({
    vigentes: false,
    programacion: false
  })

  useEffect(() => {
    if (isOpenComisionesEscalonadas.open) {
      startLoadData(isOpenComisionesEscalonadas.nIdEmisor)
    } else {
      setProxima({
        dFechaVigencia: moment().add(1, 'month').format("YYYY-MM-DD")
      })
      setSelectedTab("vigentes")
      setBitacoraForm({
        dFecInicial: moment().subtract(1, 'month').format("YYYY-MM-DD"),
        dFecFinal: moment().format("YYYY-MM-DD"),
        filteredData: false
      })
      setBitacora({
        rows: [],
        columns: bitacoraColumns
      })
      setVencidas({
        rows: [],
        columns: vencidosColumns
      })
    }
  }, [isOpenComisionesEscalonadas])

  useEffect(() => {
    if (comisionesEscalonadas.sBitacora.length) {
      const innerBitacora = [...comisionesEscalonadas.sBitacora]
      setBitacora({ columns: bitacoraColumns, rows: innerBitacora })
      const dFecInicial = innerBitacora.sort((a, b) => new Date(a.dFecRegistro) - new Date(b.dFecRegistro))[0].dFecRegistro

      setBitacoraForm({
        ...bitacoraForm,
        dFecInicial
      })
    }
  }, [comisionesEscalonadas.sBitacora])

  const startLoadData = async (nIdEmisor) => {
    setModalLoading(true)
    const response = await dispatch(getComisionesEscalonadas(nIdEmisor))
    let proxima = null
    if (response?.sProximas?.length) {
      const sProximas = [...response.sProximas].map(row => {
        const strDate = moment(row.dFechaVigencia).locale("es").format("MMMM YYYY")
        return {
          ...row,
          sNombre: strDate.charAt(0).toUpperCase() + strDate.slice(1)
        }
      })
      console.log(sProximas)
      proxima = sProximas.find(item => [0, 1].includes(parseInt(item.nEstatusAutorizacion)))
      setProxima(proxima ?? {
        dFechaVigencia: moment().add(1, 'month').format("YYYY-MM-DD")
      })
      setVencidas({
        rows: sProximas.filter(item => ![0, 1].includes(parseInt(item.nEstatusAutorizacion))),
        columns: vencidosColumns
      })
    }

    if (response?.sVigente?.length && (selectedTab === "vigentes" || (selectedTab === "programacion" && !proxima))) {
      const vigentes = [...response.sVigente].sort(
        (a, b) => a.nNumOpeMin - b.nNumOpeMin
      )
      dispatch(setListEscCantidadForm(parseEscOperacionToCantidadEnhanced(vigentes)));
    } 
    setEnableEdit({
      vigentes: false,
      programacion: false
    })
    setModalLoading(false)
  }

  const filterBitacora = () => {
    const dFecInicial = moment(bitacoraForm.dFecInicial);
    const dFecFinal = moment(bitacoraForm.dFecFinal)
    if (dFecInicial.isAfter(dFecFinal, 'date')) {
      setOpenToast(true, "La fecha inicial debe de ser menor o igual a la fecha final.");
      return;
    }

    if (comisionesEscalonadas.sBitacora.length) {
      const innerBitacora = [...comisionesEscalonadas.sBitacora]
      setBitacora({ columns: bitacoraColumns, rows: innerBitacora.filter(item => formatDate(item.dFecRegistro).isBetween(dFecInicial, dFecFinal, 'days', '[]')) })
      setBitacoraForm({
        ...bitacoraForm,
        filteredData: true,
      });
    }
  }

  const handleSelectedTab = (selected = "vigentes", escalonada = null) => {

    let escalones = []

    if (selected === "programacion" && proxima.nIdEmisorComEscProg && !proxima.isReprogramada) {
      escalones = JSON.parse(proxima.sEscalones)
    } else if (escalonada && escalonada.length) {
      escalones = escalonada
    } else {
      escalones = [...comisionesEscalonadas.sVigente]
    }
    escalones = escalones.sort(
      (a, b) => a.nNumOpeMin - b.nNumOpeMin
    )
    dispatch(setListEscCantidadForm(parseEscOperacionToCantidadEnhanced(escalones)));
    setSelectedTab(selected)
  }


  const formatDate = (date) => {
    return moment(date[date.length - 1] === "Z" ? date.slice(0, -1) : date)
  }

  const onChange = (e, name = null, value = null) => {
    setBitacoraForm({
      ...bitacoraForm,
      [e?.target?.name ?? name]: e?.target?.value ?? value,
    });
  };

  const onChangeProxima = (e, name = null, value = null) => {
    setProxima({
      ...proxima,
      [e?.target?.name ?? name]: e?.target?.value ?? value,
    });
  };

  const openScreen = (screen, tab = "vigentes", escalonadas = []) => {
    if (screen === "inicio") {
      handleSelectedTab(tab, escalonadas)
    }
    setScreen(screen);
  }

  const goBack = () => {
    openScreen(screen === 'detalle' ? "vencidos" : "inicio");
  }

  const rescheduleProgramacion = () => {
    setProxima({
      ...detalle,
      dFechaVigencia: moment().add(1, 'month').format("YYYY-MM-DD"),
      nIdEmisorComEscProg: null,
      isReprogramada: true
    })
    openScreen("inicio", "programacion", JSON.parse(detalle.sEscalones))
  }

  const openDetails = (row) => {
    console.log(row)
    setDetalle(row)
    const escalones = JSON.parse(row.sEscalones)?.sort(
      (a, b) => a.nNumOpeMin - b.nNumOpeMin
    )
    dispatch(setListEscCantidadForm(parseEscOperacionToCantidadEnhanced(escalones)));
    openScreen("detalle")
  }
  const exportBitacoraData = (format) => {
    const dFecInicial = moment(bitacoraForm.dFecInicial).format("YYYY-MM-DD")
    const dFecFinal = moment(bitacoraForm.dFecFinal).format("YYYY-MM-DD")

    const fileName = `Bitacora comisiones escalonadas${bitacoraForm.filteredData ? `(${dFecInicial} a ${dFecFinal})` : ""}`
    const title = "Bitacora comisiones escalonadas"
    const subtitle = bitacoraForm.filteredData ? `${dFecInicial} a ${dFecFinal}` : ""
    downloadFile(bitacora.rows, bitacora.columns, format, fileName, title, subtitle)
  }

  const handleEdit = (isEditing = false, name = "vigentes") => {
    console.log(isEditing, name)
    setEnableEdit({
      ...enableEdit,
      [name]: isEditing
    })
    if (!isEditing) handleSelectedTab(selectedTab)
  }

  const validateEditVigente = () => {
    if (escalonadoHasChanged(parseEscCantidadToOperacion(escCantidadForms), comisionesEscalonadas.sVigente)) {
      setConfirmDialog({
        open: true,
        message: "Se hará un cambio directo a la comisión vigente, esto tiene impacto en algunos procesos. ¿Estas seguro/a de que quieres continuar?",
        type: "editVigente"
      })
    } else {
      setOpenToast(true, "No se detectaron cambios en las comisiones vigentes.")
    }
  }

  const validateProgramacion = (isEditing = true) => {
    let msgError = ""
    if (moment().isSameOrAfter(moment(proxima.dFechaVigencia))) msgError = "Ingrese un mes de entrada en vigor mayor al mes actual."
    
    const { ok, error } = validListEscCantidad(escCantidadForms)
    if (!ok) msgError = error

    if (msgError) {
      setOpenToast(true, msgError)
      return
    }

    const strDate = moment(proxima.dFechaVigencia).locale("es").format("MMMM [del] YYYY")

    setConfirmDialog({
      open: true,
      message: `Se ${isEditing ? "editará" : "registrará"} una programación de comisión escalonada que entrará en vigor el mes de ${strDate} . ¿Estas seguro/a de que quieres continuar?`,
      type: isEditing ? "editProgramacion" : "insertProgramacion"
    })
  }

  const escalonadoHasChanged = (changedEscalonados = [], escalonados = []) => {
    console.log(changedEscalonados, escalonados)
    let hasChanged = false;

    changedEscalonados.map((changedItem) => {
      if (!hasChanged) {

        const comision = escalonados.find(item => {
          return (
            parseInt(item.nNumOpeMin) === parseInt(changedItem.nNumOpeMin)
            && parseInt(item.nNumOpeMax) === parseInt(changedItem.nNumOpeMax)
            && parseFloat(item.nCosto) === parseFloat(changedItem.nCosto)
          )
        })

        if (!comision) hasChanged = true
      }
    })

    return hasChanged
  }

  const validateChangeEstatusProgramacion = (autorizar = true) => {

    const strDate = moment(proxima.dFechaVigencia).locale("es").format("MMMM [del] YYYY")

    setConfirmDialog({
      open: true,
      message: `Se ${autorizar ? "autorizará" : "rechazará"} una programación de comisión escalonada que entrará en vigor el mes de ${strDate} . ¿Estas seguro/a de que quieres continuar?`,
      type: autorizar ? "authorizeProgramacion" : "rejectProgramacion"
    })
  }

  const handleSubmit = async () => {
    const type = confirmDialog.type
    closeConfirmDialog()

    let resp = {}
    switch (type) {
      case "editVigente":
        resp = await dispatch(updateComisionVigente(isOpenComisionesEscalonadas.nIdEmisor, parseEscCantidadToOperacion(escCantidadForms)))
        break;

      case "editProgramacion":
        resp = await dispatch(updateComisionProgramacion(proxima.nIdEmisorComEscProg, proxima.dFechaVigencia, parseEscCantidadToOperacion(escCantidadForms)))
        break;

      case "insertProgramacion":
        resp = await dispatch(insertComisionProgramacion(isOpenComisionesEscalonadas.nIdEmisor, proxima.dFechaVigencia, parseEscCantidadToOperacion(escCantidadForms)))
        break;

      case "authorizeProgramacion":
        resp = await dispatch(updateComisionProgramacionEstatus(proxima.nIdEmisorComEscProg, 1))
        break;

      case "rejectProgramacion":
        resp = await dispatch(updateComisionProgramacionEstatus(proxima.nIdEmisorComEscProg, 2))
        break;
      default:
        break;
    }

    setOpenToast(!resp.ok, resp.msg)
    if (resp.ok) startLoadData(isOpenComisionesEscalonadas.nIdEmisor)
  }

  const closeConfirmDialog = () => {
    setConfirmDialog({
      open: false,
      message: "",
      type: ""
    })
  }


  return {
    modalLoading,
    comisionesEscalonadas,
    isOpenComisionesEscalonadas,
    closeModalComisionesEscalonadas,
    screen,
    openScreen,
    goBack,
    onChange,
    bitacoraForm,
    bitacora,
    vencidas,
    filterBitacora,
    exportBitacoraData,
    handleSelectedTab,
    selectedTab,
    proxima,
    detalle,
    confirmDialog,
    enableEdit,
    onChangeProxima,
    openDetails,
    handleEdit,
    handleSubmit,
    validateEditVigente,
    closeConfirmDialog,
    validateProgramacion,
    rescheduleProgramacion,
    escCantidadForms,
    validateChangeEstatusProgramacion
  };
};


const bitacoraColumns = [
  { field: 'nIdBitacora', headerName: 'ID', flex: 1 },
  { field: 'dFecRegistro', headerName: 'Fecha movimiento', flex: 3, format: 'datetime' },
  { field: 'nNumOpeMin', headerName: 'Cantidad mínima', flex: 1 },
  { field: 'nNumOpeMax', headerName: 'Cantidad máxima', flex: 1 },
  { field: 'nComision', headerName: 'Comisión', flex: 1, format: 'price' },
  { field: 'sUsuario', headerName: 'Usuario', flex: 5 },
]

const vencidosColumns = [
  { field: 'nIdEmisorComEscProg', headerName: 'ID', flex: 1 },
  { field: 'sNombre', headerName: 'Nombre', flex: 2 },
  { field: 'dFechaVigencia', headerName: 'Fecha programada', flex: 2, format: 'date' },
  { field: 'sEstatusAutorizacion', headerName: 'Estatus', flex: 2 },
  { field: 'dFecMovimiento', headerName: 'Fecha estatus', flex: 2, format: 'date' },
  { field: 'sUsuarioCrea', headerName: 'Usuario', flex: 3 }
]