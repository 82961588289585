import { useEffect, useState } from "react";
import { useMenu } from "../hooks/useMenu";
import { useAuthPage } from "../hooks/useAuthPage";
import {
  createTheme,
  IconButton,
  ThemeProvider,
  Tooltip,
} from "@mui/material";
import {
  Visibility,
  Delete
} from "@mui/icons-material";
import { useModal } from "../hooks/useModal";

import EnhancedTable from "../components/table/EnhancedTable";
import { useUsuariosTable } from "../hooks/useUsuariosTable";
import { ModalUsuario } from "../components/modal/ModalUsuario";
import { ConfirmDialog } from "../components/modal/ConfirmDialog";
import { usePerfilesUsuariosTable } from "../hooks/usePerfilesUsuariosTable";
import { ModalPerfilUsuario } from "../components/modal/ModalPerfilUsuario";

const iconTheme = createTheme({
  status: {
    danger: "#e53e3e",
  },
  palette: {
    primary: {
      main: "#00609C",
      dark: "#053e85",
    },
    neutral: {
      main: "#00CD68",
      light: "#053e85",
      dark: "#053e85",
      contrastText: "#fff",
    },
  },
});


export const VerPerfilesUsuariosPage = () => {
  const { setNameSection } = useMenu();
  const { setAllowComponent } = useAuthPage();
  const { isTableLoading } = useModal();

  const colorsTab = ["#E7B10A", "#00CD68", "#e53e3e", "#053e85"]
  const {
    loadInInit,
    dataTable,
    onClickAdd,
    showModal,
    form,
    onChangeInput,
    handleCloseModal,
    handleSubmit,
    opciones,
    handlePermisos,
    secciones,
    openModal,
    enableEditModal,
    disableEditModal,
    handleSubmitChangeEstatus,
    procesos,
    onChange
  } = usePerfilesUsuariosTable();

  const [showConfirmDialog, setShowConfirmDialog] = useState(false)



  const triggerDeleteDialog = (row) => {
    setShowConfirmDialog(row);
  };

  const handleConfirmDialog = async () => {
    await handleSubmitChangeEstatus(showConfirmDialog)
    setShowConfirmDialog(false)
  }

  useEffect(() => {
    setNameSection("perfilesUsuario");
  }, [setNameSection]);

  useEffect(() => {
    loadInInit();
  }, []);

  return (
    <ThemeProvider theme={iconTheme}>
      <ConfirmDialog
        isOpenDialog={!!showConfirmDialog}
        onCloseDialog={() => setShowConfirmDialog(false)}
        onConfimDialog={handleConfirmDialog}
        message={`¿Estas seguro/a de dar de baja el perfil de usuario?`}
        textButtonConfirm="Sí, si estoy seguro/a."
      />
      <ModalPerfilUsuario
        isOpenModal={showModal.open}
        modalType={showModal.type}
        procesos={procesos}
        form={form}
        onChange={onChangeInput}
        onChangeSearch={onChange}
        handleSubmit={handleSubmit}
        handleCloseModal={handleCloseModal}
        opciones={opciones}
        secciones={secciones}
        handlePermisos={handlePermisos}
        enableEditModal={enableEditModal}
        disableEditModal={disableEditModal}
        handleSubmitChangeEstatus={handleSubmitChangeEstatus}
      />
      <EnhancedTable
        table={{
          ...dataTable,
          columns: [
            { field: 'nIdPerfil', headerName: 'ID', flex: 1 },
            { field: 'sNombre', headerName: 'Nombre', flex: 2 },
            { field: 'sDescripcion', headerName: 'Descripción', flex: 10 },
            { field: 'nNumUsuarios', headerName: 'Núm. usuarios', flex: 1 }
          ]
        }}
        add={setAllowComponent("altaPerfilUsuario")}
        onAddFunction={onClickAdd}
        buttons={[
          {
            label: "Ver",
            icon: <Visibility fontSize={"small"} />,
            onClick: (id,row) => openModal("view", row),
            showButton: setAllowComponent('verPerfilUsuario')
          },
          {
            label: "Eliminar",
            icon: <Delete fontSize={"small"} />,
            onClick: (id,row) => triggerDeleteDialog(row),
            disabled: (id,row) => row.nNumUsuarios > 0 || row.nIdPerfil === 1,
            showButton: setAllowComponent('eliminarPerfilUsuario')
          }
        ]}
        loading={isTableLoading}
        rowId={"nIdPerfil"}
      />
    </ThemeProvider>
  );
};
