import { useEffect, useState } from "react";
import { useModal } from "./useModal";
import { useDispatch } from "react-redux";
import { getCadenaCredentials, insertPublicKey } from "../slices/usuarioExterno/usuarioExternoThunk";
import { type } from "@testing-library/user-event/dist/type";

export const useGetCredentialsPage = () => {

    const dispatch = useDispatch();

    const [step, setStep] = useState(0)
    const [fileData, setFileData] = useState(false)
    const [selectedFile, setSelectedFile] = useState(false)
    const [credentials, setCredentials] = useState({
        secret: "",
        keyId: "",
        clientId : ""
    })
    const [error, setError] = useState(false)
    const [showSecret, setShowSecret] = useState(false)
    const [showClient, setShowClient] = useState(false)
    const [loaded, setLoaded] = useState(false)

    const { setOpenToast } = useModal();

    useEffect(() => {
        setError(false)
    }, [fileData])

    const loadInInit = async () => {
        const res = await dispatch(getCadenaCredentials())
        if (res.ok) {
            setCredentials(res.data)
            setStep(1)
        }
        setLoaded(true)
    }


    const handleChangeStep = async (action) => {
        let innerStep = action === 'next' ? step + 1 : step - 1;

        if (innerStep < 0) innerStep = 0
        if (innerStep > 1) innerStep = 1

        if (innerStep === 1 && action === 'next') {
            const changeStep = await getCredentials();
            if (changeStep) setStep(innerStep)
        } else {
            setStep(innerStep)
        }

    }

    const getCredentials = async () => {
        const res = await dispatch(insertPublicKey(fileData))

        if (res.ok) {
            setCredentials(res.data)
        } else {
            setError(res.msg)
        }

        return res.ok
    }

    const handleDrop = (e) => {
        let file = e.dataTransfer ? e.dataTransfer.files[0] : e.target.files[0];
        if (file) {
            if (isExtensionAllowed(file.name)) {

                const reader = new FileReader();
                reader.readAsText(file);
                reader.onload = (e) => {
                    let data = e.target.result;
                    const dataValidated = validateData(data.trim(), file.name)

                    console.log(dataValidated)

                    setFileData(dataValidated)
                    setSelectedFile(file)
                }

            } else {
                setOpenToast(true, "Extensión de archivo no compatible");
            }

        }
    };

    const isExtensionAllowed = (fileName) => {
        const extension = fileName.split(".").pop();
        return ["pem", "PEM"].includes(extension);
    };

    const validateData = (data) => {
        const innerData = data
            .replace('-----BEGIN PUBLIC KEY-----', '')
            .replace('-----END PUBLIC KEY-----', '')
            .replace(/\s/g, '');

        return innerData
    }

    const saveClipboard = (key) => {
        navigator.clipboard.writeText(credentials[key])
        setOpenToast(false, "Guardado con éxito en el portapapeles");
    }

    const handleShowSecret = () => {
        setShowSecret(!showSecret)
    }
    const handleShowClient = () => {
        setShowClient(!showClient)        
    }


    return {
        step,
        handleChangeStep,
        handleDrop,
        fileData,
        setFileData,
        selectedFile,
        setSelectedFile,
        credentials,
        error,
        saveClipboard,
        showSecret,
        showClient,
        handleShowClient,
        handleShowSecret,
        loadInInit,
        loaded
    }
}