import { useEffect, useState } from 'react';
import { useMenu } from '../hooks/useMenu';
import { useAuthPage } from '../hooks/useAuthPage';
import { Button, Card, Typography } from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material';
import { ReportForm } from '../components/ReportForm';
import { useReportsPage } from '../hooks/useReportsPage';
import { ReportOrdenesPagoTable } from '../components/table/ReportOrdenesPagoTable';
import { ModalOrdenPago } from '../components/modal/ModalOrdenPago';
import SummarizeIcon from "@mui/icons-material/Summarize";

const iconTheme = createTheme({
  status: {
    danger: '#e53e3e'
  },
  palette: {
    primary: {
      main: '#00609C',
      dark: '#053e85'
    },
    neutral: {
      main: '#00CD68',
      light: '#053e85',
      dark: '#053e85',
      contrastText: '#fff'
    }
  }
});

export const OrdenesPagoPage = () => {

  const { loadInInit, getOrdenesPagoLayout } = useReportsPage();
  const { setNameSection } = useMenu();
  const { isAllowed, setAllowComponent } = useAuthPage();

  useEffect(() => {
    loadInInit();
  }, []);

  if (isAllowed) {
    return (
      <ThemeProvider theme={iconTheme}>
        <ModalOrdenPago />
        <ReportForm
          extraButtons={
            setAllowComponent("generarLayout") ? <Button
              startIcon={<SummarizeIcon />}
              style={{ background: "white" }}
              onClick={getOrdenesPagoLayout}
            >
              Generar layout
            </Button> : <></>
          }
        />
        <ReportOrdenesPagoTable />
      </ThemeProvider>
    );
  } else {
    setNameSection('');
    return (
      <Card>
        <Typography variant="h3">Contenido no disponible</Typography>
      </Card>
    );
  }
};
