
import {
  getDashboardData,
} from "../slices/dashboard/dashboardThunk";
import { useSelector, useDispatch } from "react-redux";

import { useEffect, useState } from "react";
import moment from "moment";
import {
  setArchivosConciliacionTable,
  setComisionistaChart,
  setDepositosTable,
  setEFRChart,
  setOperacionesTable,
  setPagosTable,
  setProcesosConciliacionTable,
} from "../slices/dashboard/dashboardSlice";

export const useDashboard = () => {
  const [cadenas, setCadenas] = useState([])
  const [productos, setProductos] = useState([])
  const [emisores, setEmisores] = useState([])
  const {
    comisionistaChart,
    EFRChart,
    operacionesTable,
    archivosConciliacionTable,
    procesosConciliacionTable,
    depositosTable,
    pagosTable,
  } = useSelector((state) => state.dashboard);
  const [date, setDate] = useState(moment().format("YYYY-MM-DD"));
  const [loaded, setLoaded] = useState(false);

  const dispatch = useDispatch();

  useEffect(() => {
    if (loaded && moment(date, "YYYY-MM-DD").isValid()) {
      getDashboard();
    }
  }, [date]);

  const getDashboard = async () => {
    const data = await dispatch(getDashboardData(date))
    console.log(data)
    const {
      emisores = [],
      cadenas = [],
      productos = [],
      operaciones = [],
      ordenesPagoEmisor = [],
      ordenesPagoCadena = [],
      registrosBancariosEmisor = [],
      registrosBancariosCadena = [],
      corteEmisor = [],
      corteCadena = [],
      archivosConciliacion = [],
    } = data;
    setEmisores(emisores ?? [])
    setCadenas(cadenas ?? [])
    setProductos(productos ?? [])
    formatOperacionesData(operaciones ?? [], emisores ?? [], cadenas ?? [], productos ?? []);
    formatLiquidacionesData(ordenesPagoEmisor ?? [], ordenesPagoCadena ?? []);
    formatDepositosData(registrosBancariosEmisor ?? [], registrosBancariosCadena ?? [], corteEmisor ?? [], corteCadena ?? []);
    formatArchivosConciliacionData(archivosConciliacion ?? []);
    formatProcesosConciliacionData(archivosConciliacion ?? []);
    setLoaded(true);
  };

  const formatArchivosConciliacionData = (archivosConciliacion) => {
    const {
      cadCumplidos = 0,
      cadRecibidos = 0,
      comisionistas = 0,
      efrCumplidos = 0,
      efrRecibidos = 0,
      emisores = 0
    } = archivosConciliacion[0].archivos

    let esperados = {
      name: "Esperados",
      comisionistasCount: comisionistas,
      EFRCount: emisores,
    };
    let recibidos = {
      name: "Recibidos",
      comisionistasCount: parseInt(cadRecibidos) > parseInt(cadCumplidos) ? { value: cadCumplidos, tooltip: `Se subieron ${cadRecibidos - cadCumplidos} archivos más` } : cadCumplidos,
      EFRCount: parseInt(efrRecibidos) > parseInt(efrCumplidos) ? { value: efrCumplidos, tooltip: `Se subieron ${efrRecibidos - efrCumplidos} archivos más` } : efrCumplidos,
    };
    dispatch(setArchivosConciliacionTable([esperados, recibidos]));
  }

  const formatProcesosConciliacionData = (archivosConciliacion) => {
    console.log(archivosConciliacion)
    const {
      cadCumplidos = 0,
      cadRecibidos = 0,
      comisionistas = 0,
      efrCumplidos = 0,
      efrRecibidos = 0,
      emisores = 0
    } = archivosConciliacion[0].conciliados

    let conciliados = {
      name: "No conciliados",
      comisionistasCount: comisionistas,
      EFRCount: emisores,
    };
    let noConciliados = {
      name: "Conciliados",
      comisionistasCount: cadCumplidos,
      EFRCount: efrCumplidos,
    };
    dispatch(setProcesosConciliacionTable([conciliados, noConciliados]));
  }

  const formatDepositosData = async (registrosEmisor, registrosCadena, corteEmisor, corteCadena) => {
    let registrosData = []
    let corteData = []
    if (registrosEmisor) {
      registrosEmisor = registrosEmisor?.map((item) => {
        return { ...item, actor: 1 };
      })
      registrosData = [...registrosData, ...registrosEmisor]
    }
    if (registrosCadena) {
      registrosCadena = registrosCadena?.map((item) => {
        return { ...item, actor: 2 };
      })
      registrosData = [...registrosData, ...registrosCadena]
    }
    if (corteEmisor) {
      corteEmisor = corteEmisor?.map((item) => {
        return { ...item, actor: 1 };
      })
      corteData = [...corteData, ...corteEmisor]
    }
    if (corteCadena) {
      corteCadena = corteCadena?.map((item) => {
        return { ...item, actor: 2 };
      })
      corteData = [...corteData, ...corteCadena]
    }
    let esperados = {
      name: "Esperados",
      comisionistasCount: 0,
      comisionistasSum: 0,
      EFRCount: 0,
      EFRSum: 0,
    };
    let recibidos = {
      name: "Recibidos",
      comisionistasCount: 0,
      comisionistasSum: 0,
      EFRCount: 0,
      EFRSum: 0,
    };
    console.log(corteData)
    corteData?.map((item) => {
      if (item.nFlujoImporte === 1) {
        esperados.comisionistasCount += item.actor === 2 ? 1 : 0;
        esperados.comisionistasSum += item.actor === 2 ? item.nTotalPago : 0;
      } else {
        esperados.EFRCount += item.actor === 1 ? 1 : 0;
        esperados.EFRSum += item.actor === 1 ? item.nTotalPago : 0;
      }
    });
    registrosData?.map((item) => {
      recibidos.comisionistasCount += item.actor === 2 ? 1 : 0;
      recibidos.comisionistasSum += item.actor === 2 ? item.nImporte : 0;
      recibidos.EFRCount += item.actor === 1 ? 1 : 0;
      recibidos.EFRSum += item.actor === 1 ? item.nImporte : 0;
    });
    dispatch(setDepositosTable([esperados, recibidos]));
  };

  const formatLiquidacionesData = async (dataEmisor, dataCadena) => {
    let data = []
    if (dataEmisor) {
      dataEmisor = dataEmisor?.map((item) => {
        return { ...item, actor: 1 };
      })
      data = [...data, ...dataEmisor]
    }
    if (dataCadena) {
      dataCadena = dataCadena?.map((item) => {
        return { ...item, actor: 2 };
      })
      data = [...data, ...dataCadena]
    }
    let generados = {
      name: "Generado",
      comisionistasCount: 0,
      comisionistasSum: 0,
      EFRCount: 0,
      EFRSum: 0,
    };
    let enviados = {
      name: "Enviado",
      comisionistasCount: 0,
      comisionistasSum: 0,
      EFRCount: 0,
      EFRSum: 0,
    };
    data?.map((item) => {
        generados.EFRCount += item.actor === 1 ? 1 : 0;
        generados.EFRSum += item.actor === 1 ? item.nTotal : 0;
        generados.comisionistasCount += item.actor === 2 ? 1 : 0;
        generados.comisionistasSum += item.actor === 2 ? item.nTotal : 0;
      if (item.nIdEstatusPago >= 2) {
          enviados.EFRCount += item.actor === 1 ? 1 : 0;
          enviados.EFRSum += item.actor === 1 ? item.nTotal : 0;
          enviados.comisionistasCount += item.actor === 2 ? 1 : 0;
          enviados.comisionistasSum += item.actor === 2 ? item.nTotal : 0;
      }
    });
    dispatch(setPagosTable([generados, enviados]));
  };

  const formatOperacionesData = (operacionesData, emisores, cadenas, productos) => {
    let data = operacionesData ? operacionesData : [];
    console.log(data)
    //COMISIONISTA CHART
    let comisionistaChart = {
      categories: [],
      series: [],
    };
    emisores?.map((emisor) =>
      comisionistaChart.categories.push(emisor.sNombreComercial)
    );
    cadenas?.map((cadena) => {
      let chartData = [];
      emisores.map((emisor) => {
        chartData.push(
          data.filter(
            (item) =>
              cadena.nIdCadena == item.nIdCadena &&
              emisor.nIdEmisor == item.nIdEmisor
          ).length
        );
      });
      comisionistaChart.series.push({
        name: cadena.sNombreComercial,
        data: chartData,
      });
    });
    dispatch(setComisionistaChart(comisionistaChart));
    console.log(comisionistaChart);

    let EFRChart = {
      categories: [],
      series: [],
    };
    cadenas.map((cadena) =>
      EFRChart.categories.push(cadena.sNombreComercial)
    );
    emisores.map((emisor) => {
      let chartData = [];
      cadenas.map((cadena) => {
        chartData.push(
          data.filter(
            (item) =>
              cadena.nIdCadena == item.nIdCadena &&
              emisor.nIdEmisor == item.nIdEmisor
          ).length
        );
      });
      EFRChart.series.push({
        name: emisor.sNombreComercial,
        data: chartData,
      });
    });
    dispatch(setEFRChart(EFRChart));
    console.log(EFRChart);

    //OPERACIONES TABLE
    let operacionesTable = [];
    let sum = 0;
    productos?.map((producto) => {
      sum = 0;
      let filtered = data.filter(
        (item) => producto.sClaveProducto == item.sClaveProducto
      );

      filtered?.map((item) => (sum += item.sumImporte));

      operacionesTable.push({
        id: producto.sClaveProducto,
        name: producto.sDescripcion,
        nFlujoImporte: producto.nFlujoImporte,
        cantidad: filtered.length,
        volumen: sum,
      });
    });
    dispatch(setOperacionesTable(operacionesTable));
    console.log(operacionesTable);
  };

  const onChange = (e, name = null, value = null) => {
    const inputName = name !== null ? name : e.target.name;
    const inputValue = value !== null ? value : e.target.value;

    if (inputName === "date") {
      setDate(inputValue);
    }
  };

  return {
    comisionistaChart,
    EFRChart,
    operacionesTable,
    archivosConciliacionTable,
    procesosConciliacionTable,
    depositosTable,
    pagosTable,
    onChange,
    date,
    getDashboard
  };
};
