import { useEffect } from 'react';
import { useMenu } from '../hooks/useMenu';
import { useAuthPage } from '../hooks/useAuthPage';
import { ConciliacionPage } from '../pages/ConciliacionPage';
import { NotAllowPage } from '../pages/NotAllowPage';
import {
  useParams
} from "react-router-dom";
import { ArchivosConciliacionPage } from '../pages/ArchivosConciliacionPage';
import { ConciliacionManualPage } from '../pages/ConciliacionManualPage';
import { AutorizacionPage } from '../pages/AutorizacionPage';

export const ConciliacionModule = () => {
  const { type } = useParams();
  const { setNameSection } = useMenu();
  const { setNewPage, isAllowed } = useAuthPage();

  useEffect(() => {
    switch (type) {
      case 'reporte-operaciones':
        setNewPage('conciliacionReporteOperaciones');
        setNameSection('conciliacionReporteOperaciones');
        break;
      case 'reporte-general':
        setNewPage('conciliacionReporteGeneral');
        setNameSection('conciliacionReporteGeneral');
        break;
      case 'archivos':
        setNewPage('conciliacionArchivos');
        setNameSection('conciliacionArchivos');
        break;
      case 'manual':
        setNewPage('conciliacionManual');
        setNameSection('conciliacionManual');
        break;
      case 'pendientes-autorizar':
        setNewPage('conciliacionPendientesAutorizar');
        setNameSection('conciliacionPendientesAutorizar');
        break;
      default:
        setNewPage('');
        setNameSection('');
        break;
    }
  }, [type]);

  if (isAllowed) {
    switch (type) {
      case 'reporte-general':
      case 'reporte-operaciones':
        return <ConciliacionPage />;
      case 'pendientes-autorizar':
        return <AutorizacionPage />;
      case 'archivos':
        return <ArchivosConciliacionPage />;
      case 'manual':
        return <ConciliacionManualPage />;
      default:
        return <NotAllowPage />;
    }
  } else {
    return <NotAllowPage />;
  }
};
