import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  archivos: [],
  conexiones: [],
  conexionesSofiPay: []
};

export const conexionesSFTPSlice = createSlice({
  name: 'conexionesSFTP',
  initialState,
  reducers: {
    setArchivos: (state, action) => {
      state.archivos = [...action.payload];
    },
    setConexiones: (state, action) => {
      state.conexiones = [...action.payload];
    },
    setConexionesSofiPay: (state, action) => {
      state.conexionesSofiPay = [...action.payload];
    },
    resetConexionesSFTP: (state) => {
      state.archivos = [];
      state.conexiones = [];
    },
  }
});

export const {
  setArchivos,
  setConexiones,
  setConexionesSofiPay,
  resetConexionesSFTP,
} = conexionesSFTPSlice.actions;
