import { CompareArrows, Exposure, Receipt } from '@mui/icons-material';
import { Box, Button, Grid, Typography } from '@mui/material';
import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { validateFactEFRByType } from '../../helpers/forms-validate';
import { periodicidades } from '../../helpers/getData';
import { useModal } from '../../hooks/useModal';
import { cleanEmailsByRef, setFacturacionForm as setFacturacionFormStore } from '../../slices/cadena/cadenaSlice';
import { EmailsFacturaCadena } from '../cadena/emailFactura/EmailsFacturaCadena';
import { SearchInput } from '../form/SearchInput';
import { SelectInput } from '../form/SelectInput';
import { TextInput } from '../form/TextInput';
import { ModalBase } from './ModalBase';

const getIconTransferencia = (type) => {
  switch (type) {
    case 'comision':
      return <Receipt fontSize={'large'} sx={{ color: '#00CD68', fontSize: '45px' }} />;

    case 'transferencia':
      return (
        <CompareArrows fontSize={'large'} sx={{ color: '#00CD68', fontSize: '45px' }} />
      );

    case 'minimo':
      return <Exposure fontSize={'large'} sx={{ color: '#00CD68', fontSize: '45px' }} />;

    default:
      return <Exposure fontSize={'large'} sx={{ color: '#00CD68', fontSize: '45px' }} />;
  }
};

const initialState = {
  cfdi           : '',
  claveProducto  : '',
  claveUnidad    : '',
  periocidad     : '',
  diasLiquidacion: 0,
  formaPago      : '',
  metodoPago     : ''
};

export const ModalFacturacion = ({ onChangeEdit, editForm }) => {
  const { 
    cfdis, 
    formasPago, 
    metodosPago, 
    productoServicios, 
    unidades 
  } = useSelector((state) => state.catalogo);

  const { 
    facturacionForm: facturacionStore, 
    emailsFactura 
  } = useSelector((state) => state.cadena);
  const { isOpenFacturacion, closeModalFacturacion, setOpenToast } = useModal();
  const { open, title, refForm, type, iva } = isOpenFacturacion;

  const [formFacturacion, setFormFacturacion] = useState(initialState);

  const updateStore = useCallback(() => {
    if (type === 'Editar' && open) {
      setFormFacturacion({ ...facturacionStore[refForm] });
    }
    // eslint-disable-next-line
  },[open]);
  

  const dispatch = useDispatch();

  const onChange = (e) => {
    const inputName = e.target.name;
    const inputValue = e.target.value;

    setFormFacturacion({ ...formFacturacion, [inputName]: inputValue });
  };

  const onChangeInput = (value) => {
    setFormFacturacion({ ...formFacturacion, ...value });
  };

  const onSave = () => {
    const { ok, error } = validateFactEFRByType({
      ...formFacturacion,
      emails: emailsFactura[refForm]
    });
    if ( !ok ) {
      setOpenToast(true, error);
      return;
    }
    onChangeEdit({
      ...editForm,
      [refForm]: true
    });
    dispatch(
      setFacturacionFormStore({
        ...facturacionStore,
        [refForm]: {
          ...facturacionStore[refForm],
          ...formFacturacion
        }
      })
    );
    setFormFacturacion(initialState);
    closeModalFacturacion();
  };

  const onClose = () => {
    if ( type === 'Agregar' ) {
      dispatch(cleanEmailsByRef({
        ref: refForm
      }));
    }
    setFormFacturacion(initialState);
    closeModalFacturacion();
  };

  useEffect(() => {
    updateStore();
  }, [updateStore]);

  return (
    <ModalBase open={open} title="" style={{ width: '850px', height: 'auto' }}>
      <Box
        sx={{
          width: '100%',
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between'
        }}
      >
        <TitleFacturacion
          title={title}
          iva={iva}
          typeOperation={type}
          typeTransferencia={refForm}
        />
        <Box
          sx={{
            width: '100%',
            height: '100%',
            margin: '10px auto'
          }}
        >
          <Grid
            container
            spacing={2}
            sx={{
              width: '100%',
              margin: '0px auto',
              marginBottom: '10px'
            }}
          >
            <Grid item md={4} sm={6} xs={12}>
              <SelectInput
                id="cfdi"
                name="cfdi"
                onChange={onChange}
                label="Uso del CFDI"
                data={cfdis}
                value={formFacturacion.cfdi}
              />
            </Grid>
            <Grid item md={4} sm={6} xs={12}>
              <SelectInput
                id="formaPago"
                name="formaPago"
                onChange={onChange}
                label="Forma de pago"
                data={formasPago}
                value={formFacturacion.formaPago}
              />
            </Grid>
            <Grid item md={4} sm={12} xs={12}>
              <SelectInput
                id="metodoPago"
                name="metodoPago"
                onChange={onChange}
                label="Método de pago"
                data={metodosPago}
                value={formFacturacion.metodoPago}
              />
            </Grid>
            <Grid item md={6} sm={6} xs={12}>
              <SearchInput
                options={productoServicios}
                value={formFacturacion.claveProducto}
                name="claveProducto"
                label="Clave del producto"
                getOptionLabel="text"
                getIndexLabel="value"
                onChange={onChange}
              />
            </Grid>
            <Grid item md={6} sm={6} xs={12}>
              <SearchInput
                options={unidades}
                value={formFacturacion.claveUnidad}
                name="claveUnidad"
                label="Clave de unidad"
                getOptionLabel="text"
                getIndexLabel="value"
                onChange={onChange}
              />
            </Grid>
            <Grid item md={6} sm={6} xs={12}>
              <SelectInput
                id="periocidad"
                name="periocidad"
                onChange={onChange}
                label="Periodicidad de facturación"
                data={periodicidades}
                value={formFacturacion.periocidad}
              />
            </Grid>
            <Grid item md={6} sm={6} xs={12}>
              <TextInput
                id="diasLiquidacion"
                name="diasLiquidacion"
                label="Días para liquidar factura"
                value={formFacturacion.diasLiquidacion}
                onChangeRoot={onChangeInput}
                type="number"
                require
              />
            </Grid>
            <Grid item md={12} sm={12} xs={12}>
              <EmailsFacturaCadena reference={refForm} />
            </Grid>
          </Grid>
        </Box>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            width: '100%',
            justifyContent: 'end',
            height: '10%'
          }}
        >
          <Button onClick={onClose} sx={{ color: '#00609C', marginRight: '10px' }}>
            cancelar
          </Button>
          <Button onClick={onSave} variant="contained">
            Guardar
          </Button>
        </Box>
      </Box>
    </ModalBase>
  );
};

const TitleFacturacion = ({ title, typeOperation, iva, typeTransferencia }) => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        width: '100%',
        marginBottom: '10px',
        justifyContent: 'space-between'
      }}
    >
      <div style={{ display: 'flex', flexDirection: 'row' }}>
        {getIconTransferencia(typeTransferencia)}
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <Typography
            variant="p"
            sx={{
              marginLeft: '15px',
              fontSize: '14px',
              marginBottom: '4px'
            }}
          >
            {typeOperation}
          </Typography>
          <Typography
            variant="p"
            sx={{
              marginLeft: '15px',
              color: '#00609C',
              fontSize: '20px'
            }}
          >
            {title}
          </Typography>
        </div>
      </div>
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <Typography
          variant="p"
          sx={{
            marginLeft: '15px',
            fontSize: '14px',
            marginBottom: '4px'
          }}
        >
          IVA
        </Typography>
        <Typography
          variant="p"
          sx={{
            marginLeft: '15px',
            color: '#00609C',
            fontSize: '20px'
          }}
        >
          {iva}
        </Typography>
      </div>
    </Box>
  );
};
