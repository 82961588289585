import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  Check,
  CompareArrows,
  Exposure,
  Receipt,
  ReceiptLongOutlined
} from '@mui/icons-material';

import { Card } from './Card';
import { SelectInput } from './form/SelectInput';
import { startLoadDatosFacturacion } from '../slices/catalogo/catalgoThunk';
import { setFacturacionForm as setFormFacturacion } from '../slices/cadena/cadenaSlice';

import { ivas } from '../helpers/data-iva';
import { Button, Tooltip } from '@mui/material';
import { ModalFacturacion } from './modal/ModalFacturacion';
import { useModal } from '../hooks/useModal';
import { buttonContactStyle, bttnFactStyle, buttonSmallContactStyle, bttnSmallFactStyle } from '../helpers/stylesMaterial';
import '../styles/cadenas.css';

const tiposFactura = [
  {
    id: 1,
    title: 'Factura por comisión',
    referenciaForm: 'comision',
    icon: <Receipt sx={{ marginRight: '10px' }} />
  },
  {
    id: 2,
    title: 'Factura comisión por transferencia',
    referenciaForm: 'transferencia',
    icon: <CompareArrows sx={{ marginRight: '10px' }} />
  },
  {
    id: 3,
    title: 'Factura comisión mínima o escalonamiento',
    referenciaForm: 'minimo',
    icon: <Exposure sx={{ marginRight: '10px' }} />
  }
];

export const FacturasDetalle = ({ 
  idElement, 
  viewModal = true, 
  disabledIVA = false,
  isActiveEdit = false
}) => {
  const { regimensFiscal } = useSelector((state) => state.catalogo);
  const { facturacionForm: formFacturacion } = useSelector((state) => state.cadena);
  const { openModalFacturacion } = useModal();
  const [editForm, setEditForm] = useState({
    comision     : false,
    transferencia: false,
    minimo       : false
  });

  const dispatch = useDispatch();

  const onChange = (e, refForm) => {
    const inputName = e.target.name;
    const inputValue = e.target.value;

    if (refForm) {
      dispatch(
        setFormFacturacion({
          ...formFacturacion,
          [refForm]: {
            ...formFacturacion[refForm],
            [inputName]: inputValue
          }
        })
      );
      return;
    }
    dispatch(
      setFormFacturacion({
        ...formFacturacion,
        [inputName]: inputValue
      })
    );
  };

  const onOpenFactura = ( refForm, title ) => {
    const typeOpera = !editForm[refForm] ? 'Agregar' : 'Editar';
    const ivaFind = ivas.find((item) => item.value === formFacturacion.iva);
    openModalFacturacion({
      title,
      refForm,
      iva : ivaFind ? ivaFind.text : '16%',
      type: isActiveEdit ? 'Editar':  typeOpera
    });
  };


  return (
    <div style={{ width: '100%', margin: '0px auto' }}>
      <ModalFacturacion editForm={editForm} onChangeEdit={setEditForm}/>
      {viewModal 
        ? (
          <Card
            title="Datos Facturación"
            icon={<ReceiptLongOutlined sx={{ color: 'white', fontSize: '23px' }} />}
            openCollapse={false}
            idElement={idElement}
            loadEvent={async () => await dispatch(startLoadDatosFacturacion({}))}
          >
            <div
              className="cadenas__inputs-container cadenas__grid-2"
              style={{ marginBottom: '10px' }}
            >
              <SelectInput
                id="iva"
                name="iva"
                onChange={(e) => onChange(e)}
                label="IVA"
                data={ivas}
                value={formFacturacion.iva}
              />
    
              <SelectInput
                id="regimenFiscal"
                name="regimenFiscal"
                onChange={(e) => onChange(e)}
                label="Régimen fiscal"
                data={regimensFiscal}
                value={formFacturacion.regimenFiscal}
              />
            </div>
            <div
              className="cadenas__inputs-container cadenas__grid-3"
              style={{ marginBottom: '10px' }}
            >
              {tiposFactura.map((fac) => (
                <Button
                  onClick={() => onOpenFactura(fac.referenciaForm, fac.title)}
                  key={fac.id}
                  variant="outlined"
                  size="large"
                  sx={!editForm[fac.referenciaForm] ? buttonContactStyle : bttnFactStyle}
                >
                  {editForm[fac.referenciaForm] ? (
                    <Check sx={{ marginRight: '10px' }} />
                  ) : (
                    <p style={{ display: 'flex', alignItems: 'center' }}>{fac.icon}</p>
                  )}
                  <Tooltip title={fac.title} placement="bottom" arrow>
                    <p 
                      style={{
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        whiteSpace: 'nowrap',
                        textAlign: 'center'
                      }}
                    >
                      {fac.title}
                    </p>
                  </Tooltip>
                </Button>
              ))}
            </div>
          </Card>
        )
        : (
          <>
            <div
              className="cadenas__inputs-container cadenas__grid-2"
              style={{ marginBottom: '10px' }}
            >
              <SelectInput
                id="iva"
                name="iva"
                onChange={(e) => onChange(e)}
                label="IVA"
                data={ivas}
                value={formFacturacion.iva}
                size="small"
                disabled={disabledIVA}
              />
    
              <SelectInput
                id="regimenFiscal"
                name="regimenFiscal"
                onChange={(e) => onChange(e)}
                label="Régimen fiscal"
                data={regimensFiscal}
                value={formFacturacion.regimenFiscal}
                size="small"
              />
            </div>
            <div
              className="cadenas__inputs-container cadenas__grid-3"
              style={{ marginBottom: '10px' }}
            >
              {tiposFactura.map((fac) => (
                <Button
                  onClick={() => onOpenFactura(fac.referenciaForm, fac.title)}
                  key={fac.id}
                  variant="outlined"
                  size="large"
                  sx={!editForm[fac.referenciaForm] ? buttonSmallContactStyle : bttnSmallFactStyle}
                >
                  {editForm[fac.referenciaForm] ? (
                    <Check sx={{ marginRight: '10px' }} />
                  ) : (
                    <p style={{ display: 'flex', alignItems: 'center' }}>{fac.icon}</p>
                  )}
                  <Tooltip title={fac.title} placement="bottom" arrow>
                    <p 
                      style={{
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        whiteSpace: 'nowrap',
                        textAlign: 'center'
                      }}
                    >
                      {fac.title}
                    </p>
                  </Tooltip>
                </Button>
              ))}
            </div>
          </>
        )
      }

    </div>
  );
};
