import { API } from "aws-amplify";
import { setProductos } from "../catalogo/catalagoSlice";
import { loading, openListRoute } from "../ui/uiSlice";
import { setComisionesEscalonadas, setComisionistas, setRutas } from "./emisorSlice";

export const getRoutesData = (nIdRuta, nIdEmisor, nIdEstatus, returnFlag = false) => {
  return async (dispatch) => {
    const params = {
      headers: {},
      response: false,
      queryStringParameters: {
        nIdRuta,
        nIdEmisor,
        nIdEstatus,
      },
    };

    try {
      dispatch(loading(true));
      const resp = await API.get("emisores", "/emisores/rutas", params);
      console.log({
        ...resp
      });
      if (returnFlag) {
        return (resp)
      }
      dispatch(
        setRutas({
          ...resp
        })
      );
      dispatch(openListRoute());
    } catch (error) {
      console.log("ocurrio un error " + error);
    } finally {
      dispatch(loading(false));
    }
  };
};

export const insertRoute = (form) => {
  return async (dispatch) => {
    const params = {
      headers: {},
      response: false, // OPTIONAL
      body: {
        nIdEmisor: form.nIdEmisor,
        sDescripcion: form.sDescripcion,
        sClaveProducto: form.sClaveProducto,
        nImpMinRuta: form.nImpMinRuta,
        nImpMaxRuta: form.nImpMaxRuta,
        dFEVRuta: form.dFEVRuta,
        dFSVRuta: form.dFSVRuta,
        nImpComProducto: form.nImpComProducto,
        nPerComProducto: form.nPerComProducto,

      },
    }
    let result = { ok: false, msg: '' }
    try {
      dispatch(loading(true));
      const resp = await API.post('emisores', '/emisores/ruta', params);
      console.log(resp)
      result = { ok: resp.nCodigo === 0, msg: resp.sMensaje }
      console.log(result)
    } catch (error) {
      console.log('ocurrio un error' + error)
      result = { ok: false, msg: error ?? 'Error al procesar los datos.' }

    } finally {
      dispatch(loading(false));
      console.log('finally', result)
      return result;
    }
  }
};

export const updateRoute = (form, type = 'edit') => {
  console.log(form)
  return async (dispatch) => {
    const params = {
      headers: {},
      response: false, // OPTIONAL
      body: {
        nIdRuta: form.nIdRuta,
        nIdEmisor: form.nIdEmisor,
        sDescripcion: form.sDescripcion,
        sClaveProducto: form.sClaveProducto,
        nImpMinRuta: form.nImpMinRuta,
        nImpMaxRuta: form.nImpMaxRuta,
        dFEVRuta: form.dFEVRuta,
        dFSVRuta: form.dFSVRuta,
        nImpComProducto: form.nImpComProducto,
        nPerComProducto: form.nPerComProducto,
        nIdEstatus: type === 'edit' ? 1 : 0
      },
    }
    let result = { ok: false, msg: '' }
    try {
      dispatch(loading(true));
      const resp = await API.put('emisores', '/emisores/ruta', params);
      console.log(resp)
      result = { ok: resp.nCodigo === 0, msg: resp.sMensaje }
      console.log(result)
    } catch (error) {
      console.log('ocurrio un error' + error)
      result = { ok: false, msg: error ?? 'Error al procesar los datos.' }

    } finally {
      dispatch(loading(false));
      console.log('finally', result)
      return result;
    }
  }
};

export const insertBaja = (body) => {
  return async (dispatch) => {
    const params = {
      headers: {},
      response: false,
      body,
    }
    let result = { ok: false, msg: '' }
    try {
      dispatch(loading(true));
      const resp = await API.del('emisores', '/emisores/baja', params);
      console.log(resp)
      result = { ok: resp.nCodigo === 0, msg: resp.sMensaje }
      console.log(result)
    } catch (error) {
      console.log('ocurrio un error' + error)
      result = { ok: false, msg: error ?? 'Error al procesar los datos.' }

    } finally {
      dispatch(loading(false));
      console.log('finally', result)
      return result;
    }
  }
};

export const getProducts = (nIdEmisor, nDetalle = 1) => {
  return async (dispatch) => {
    const params = {
      headers: {},
      response: false,
      queryStringParameters: {
        sClaveProducto: 0,
        nIdFlujoImporte: 2,
        nIdEmisor,
        nIdEstatus: 1,
        nDetalle
      }
    }
    let productos = []
    try {
      dispatch(loading(true));
      const resp = await API.get('catalogos', '/catalogo/productos', params);
      productos = resp?.map((producto) => ({
        ...producto,
        value: producto.sClaveProducto + '',
        text: producto.sDescripcion
      }));

      if (!nDetalle) dispatch(setProductos(productos));

    } catch (error) {
      console.log('ocurrio un error' + error)
    } finally {
      dispatch(loading(false));
      return productos
    }

  }
};

export const postProducts = (sProductos = []) => {
  return async (dispatch) => {
    const params = {
      headers: {},
      response: false,
      body: {
        //1. Emisor  2.Cadena
        nActor: 1,
        sProductos
      },
    }
    try {
      dispatch(loading(true));
      const resp = await API.post('catalogos', '/catalogo/productos', params);
      console.log(resp)
      return {
        ok: resp?.nCodigo === 0,
        msg: resp?.sMensaje
      }
    } catch (error) {
      console.log('ocurrio un error' + error)
    } finally {
      dispatch(loading(false));
    }

  }
};

export const getComisionistas = (dFecha = "", nIdEmisor = 0) => {
  return async (dispatch) => {
    const params = {
      headers: {},
      response: false,
      queryStringParameters: { dFecha, nIdEmisor },
    }
    try {
      dispatch(loading(true));
      const resp = await API.get('emisores', '/emisores/comisionistas', params);
      console.log(resp)
      dispatch(setComisionistas(resp))
    } catch (error) {
      console.log('ocurrio un error' + error)
    } finally {
      dispatch(loading(false));
    }

  }
};

export const getComisionesEscalonadas = (nIdEmisor) => {
  return async (dispatch) => {
    const params = {
      headers: {},
      response: false,
      queryStringParameters: { nIdEmisor },
    }
    let data = {
      sBitacora: [],
      sProximas: [],
      sVigente: []
    }
    try {
      dispatch(loading(true));
      const resp = await API.get('emisores', '/emisores/comisiones-escalonadas', params);
      const {
        sBitacora = "[]",
        sProximas = "[]",
        sVigente = "[]",
      } = resp

      data = {
        sBitacora: JSON.parse(sBitacora),
        sProximas: JSON.parse(sProximas),
        sVigente: JSON.parse(sVigente)
      }
      dispatch(setComisionesEscalonadas(data))
    } catch (error) {
      console.log('ocurrio un error' + error)
    } finally {
      dispatch(loading(false));
    }
    return data
  }
};


export const updateComisionVigente = (nIdEmisor = 0, escalonadoOperaciones = []) => {
  return async (dispatch) => {
    const params = {
      headers: {},
      response: false,
      body: {
        nIdEmisor,
        escalonadoOperaciones
      },
    }
    let response = {ok: false, msg: "Ocurrió un error. Intente más tarde."}
    try {
      dispatch(loading(true));
      const resp = await API.put('emisores', '/comision-escalonada/vigente', params);
      console.log(resp)
      response = {
        ok: resp?.nCodigo === 0,
        msg: resp?.sMensaje
      }
    } catch (error) {
      console.log('ocurrio un error' + error)
    } finally {
      dispatch(loading(false));
    }
    return response
  }
};



export const updateComisionProgramacion = (nIdEmisorComEscProg = 0, dFechaVigencia,escalonadoOperaciones = []) => {
  return async (dispatch) => {
    const params = {
      headers: {},
      response: false,
      body: {
        nIdEmisorComEscProg,
        dFechaVigencia,
        escalonadoOperaciones
      },
    }
    let response = {ok: false, msg: "Ocurrió un error. Intente más tarde."}
    try {
      dispatch(loading(true));
      const resp = await API.put('emisores', '/comision-escalonada/programacion', params);
      console.log(resp)
      response = {
        ok: resp?.nCodigo === 0,
        msg: resp?.sMensaje
      }
    } catch (error) {
      console.log('ocurrio un error' + error)
    } finally {
      dispatch(loading(false));
    }
    return response
  }
};

export const insertComisionProgramacion = (nIdEmisor = 0, dFechaVigencia,escalonadoOperaciones = []) => {
  return async (dispatch) => {
    const params = {
      headers: {},
      response: false,
      body: {
        nIdEmisor,
        dFechaVigencia,
        escalonadoOperaciones
      },
    }
    let response = {ok: false, msg: "Ocurrió un error. Intente más tarde."}
    try {
      dispatch(loading(true));
      const resp = await API.post('emisores', '/comision-escalonada/programacion', params);
      console.log(resp)
      response = {
        ok: resp?.nCodigo === 0,
        msg: resp?.sMensaje
      }
    } catch (error) {
      console.log('ocurrio un error' + error)
    } finally {
      dispatch(loading(false));
    }
    return response
  }
};

export const updateComisionProgramacionEstatus = (nIdEmisorComEscProg = 0, nEstatusAutorizacion = 0) => {
  return async (dispatch) => {
    const params = {
      headers: {},
      response: false,
      body: {
        nIdEmisorComEscProg,
        nEstatusAutorizacion
      },
    }
    let response = {ok: false, msg: "Ocurrió un error. Intente más tarde."}
    try {
      dispatch(loading(true));
      const resp = await API.put('emisores', '/comision-escalonada/estatus', params);
      console.log(resp)
      response = {
        ok: resp?.nCodigo === 0,
        msg: resp?.sMensaje
      }
    } catch (error) {
      console.log('ocurrio un error' + error)
    } finally {
      dispatch(loading(false));
    }
    return response
  }
};

export const putEnvioOperacionesEmisores = (form) => {
  return async (dispatch) => {
    const params = {
      headers: {},
      response: false,
      body: form,
    }
    try {
      dispatch(loading(true));
      const data = await API.put('emisores', '/emisores/envio-operaciones', params);
      return {
        ok: data?.nCodigo === 0,
        msg: data?.sMensaje
      }
    } catch (error) {
      console.log('ocurrio un error' + error)
    } finally {
      dispatch(loading(false));
    }

  }
};