import { Download, History, PictureAsPdf, Summarize } from '@mui/icons-material';
import { Button} from '@mui/material';
import { Box } from '@mui/system';
import { ModalBase } from './ModalBase';
import EnhancedTable from '../table/EnhancedTable';
import ButtonDropdown from '../ButtonDropdown';
import { useExportFile } from '../../hooks/useExportFile';
import { useEffect, useState } from 'react';
import moment from 'moment';



export const ModalBitacoraInterfazERP = (props) => {

  const {
    isOpen,
    onClose,
    data
  } = props;


  const { downloadFile } = useExportFile()

  const [dataTable, setDataTable] = useState({
    rows: [],
    columns: []
  })

  useEffect(() => {
    if (isOpen) {
      setDataTable({
        rows: data.sRegistros,
        columns: getColumns(),
        nId: getRowId()
      })
    } else {
      setDataTable({
        rows: [],
        columns: []
      })
    }
  }, [isOpen])

  const getColumns = () => {
    return data.nIdTipoInterfaz === 1 ? [
      { field: 'nIdInterfazEntidad', headerName: 'ID interfaz', flex: 1, style: { width: 50 } },
      { field: 'sTipoActor', headerName: 'Tipo entidad', flex: 3 },
      { field: 'sNombreComercial', headerName: 'Entidad', flex: 3 },
      { field: 'bError', headerName: 'Interfazado', flex: 2, type: "render", renderFunction:(row) => row.bError ? "No" : "Sí" },
      { field: 'sResultado', headerName: 'Comentario', flex: 5 }
    ] : [
      { field: 'nIdInterfazCortes', headerName: 'ID interfaz', flex: 1, style: { width: 50 } },
      { field: 'nIdCorte', headerName: 'ID corte', flex: 1, style: { width: 50 } },
      { field: 'dFechaCorte', headerName: 'Fecha corte', flex: 1, type: "date", style: { width: 80 } },
      { field: 'sTipoActor', headerName: 'Tipo entidad', flex: 3 },
      { field: 'sNombreComercial', headerName: 'Entidad', flex: 3 },
      { field: 'sTipoOperacion', headerName: 'Tipo de operación', flex: 3 },
      { field: 'nTotalOperaciones', headerName: 'Total operaciones', flex: 1, type: "number" },
      { field: 'nImporteTotal', headerName: 'Importe total', flex: 1, type: "price" },
      { field: 'bError', headerName: 'Interfazado', flex: 2, type: "render", renderFunction: (row) => row.bError ? "No" : "Sí" },
      { field: 'sResultado', headerName: 'Comentario', flex: 5 }
    ]
  }

  const getRowId = () => {
    return data.nIdTipoInterfaz === 1 ? 'nIdInterfazEntidad' : "nIdInterfazCortes"
  }


  const downloadBitacora = (format) => {
    let {
      rows = [],
      columns = [],
      nId
    } = dataTable
    const dFecha = moment().format("YYYY-MM-DD")
    downloadFile(
      rows.sort((a, b) => parseFloat(a[nId]) - parseFloat(b[nId])),
      columns.map(c => ({ ...c, format: c.type })),
      format,
      `Bitácora de la interfaz de ${data.sTipoInterfaz} (${data.dFechaInterfaz} ${moment(data.dHoraInterfaz, "HH:mm:ss").format("HH:mm:ss")})`,
      `Bitácora de la interfaz de ${data.sTipoInterfaz} (${data.dFechaInterfaz} ${moment(data.dHoraInterfaz, "HH:mm:ss").format("HH:mm:ss")})`,
      `Fecha: ${dFecha}`)
  }

  return (<>
    <ModalBase open={isOpen} title="" style={{ width: "1150px", height: 'auto' }}>
      <Box
        sx={{ width: '100%', height: '100%', display: 'flex', flexDirection: 'column' }}
      >
        <EnhancedTable
          rowId={dataTable.nId}
          table={dataTable}
          disableButtons
          isModalTable
          disablePathParameters
          icon={<History fontSize={'large'} sx={{ color: '#00CD68', fontSize: '45px' }} />}
          subtitle={`Bitácora de interfaz al ERP`}
          title={`${data.sTipoInterfaz} (${data.dFechaInterfaz} ${moment(data.dHoraInterfaz, "HH:mm:ss").format("HH:mm:ss")})`}
          extraButtons={<ButtonDropdown
            startIcon={<Download />} 
            label="Exportar"
            options={[
              { startIcon: <PictureAsPdf />, label: "PDF", action: () => downloadBitacora("pdf") },
              { startIcon: <Summarize />, label: "Excel", action: () => downloadBitacora("excel") },
            ]}
          />}
        />
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            width: '100%',
            justifyContent: 'end'
          }}
        >
          <Button onClick={onClose} color="primary">
            Volver
          </Button>
        </div>
      </Box>
    </ModalBase>
  </>
  );
};

