import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { autorizacionForm } from '../helpers/forms';
import {
  startLoadCadenas,
  startLoadEmisores,
} from "../slices/catalogo/catalgoThunk";
import { setDiferenciasConciliadas } from '../slices/conciliacion/conciliacionSlice';
import { autorizarOperaciones, getDiferenciasConciliadas } from '../slices/conciliacion/conciliacionThunk';
import { useModal } from './useModal';

export const useAutorizacionPage = () => {

  const [form, setForm] = useState(autorizacionForm);
  const [filtersLoaded, setFiltersLoaded] = useState(false)
  const [selected, setSelected] = useState([])
  const [observacion, setObservacion] = useState("")
  const [isOpenConfirmDialog, setIsOpenConfirmDialog] = useState(false)
  const [innerSelected, setInnerSelected] = useState([])

  const dispatch = useDispatch();

  const {
    emisores,
    cadenas
  } = useSelector((state) => state.catalogo);


  const { setOpenToast } = useModal();

  const {
    diferenciasConciliadas,
  } = useSelector((state) => state.conciliacion);

  const { isTableLoading } = useSelector((state) => state.ui);

  const loadInInit = async () => {
    await getDiferenciasConciliadasData();
  }

  const getFilters = async () => {
    if (!filtersLoaded) {
      await dispatch(startLoadCadenas());
      await dispatch(startLoadEmisores());
      
      setFiltersLoaded(true)
    }
  }

  const handleOpenConfirmDialog = (items) => {
    setInnerSelected(items)
    setObservacion("")
    setIsOpenConfirmDialog(true)
  }

  const handleCloseConfirmDialog = () => {
    setIsOpenConfirmDialog(false)
    setInnerSelected([])
    setObservacion("")
  }

  const handleSelected = (items) => {
    setSelected(items)
  }

  const getDiferenciasConciliadasData = async () => {
    await dispatch(getDiferenciasConciliadas(form));
  }

  const onChange = (e, name = null, value = null) => {
    const inputName = name !== null ? name : e.target.name;
    const inputValue = value !== null ? value : e.target.value;

    let valueForm = { ...form, [inputName]: inputValue };

    if (inputName === "nActor" && parseInt(inputValue) === 0) {
      valueForm = { ...valueForm, nIdCadena: 0, nIdEmisor: 0 };
    }

    setForm(valueForm);
  };

  const authorizeItems = () => {
    let innerDataTableRows = diferenciasConciliadas.map(row => {
      const isRowSelected = row.authorized || innerSelected.some(sel => sel === row.nIdDiferencia)
      return {
        ...row,
        sObservacion: isRowSelected ? observacion : row.sObservacion,
        authorized: isRowSelected,
        disableCheckbox:  row.disableCheckbox || isRowSelected
      }
    })
    dispatch(setDiferenciasConciliadas(innerDataTableRows))
    handleCloseConfirmDialog()
  } 

  const unauthorizeItems = (items) => {
    let innerDataTableRows = diferenciasConciliadas.map(row => {
      const isRowSelected = items.some(sel => sel === row.nIdDiferencia)
      return {
        ...row,
        sObservacion: "",
        authorized: isRowSelected ? false : row.authorized,
        disableCheckbox:  isRowSelected ? false : row.disableCheckbox
      }
    })
    dispatch(setDiferenciasConciliadas(innerDataTableRows))
  }

  const saveAuthorizedOperations = async () => {
    let innerDataTableRows = diferenciasConciliadas.filter(dif => dif.authorized && dif.sObservacion)
    const res = await dispatch(autorizarOperaciones(innerDataTableRows.map(row => {
      return ({
        nIdDiferencia: row.nIdDiferencia,
        sObservacion: row.sObservacion
      })
    })))
    setOpenToast(res.nCodigo !== 0, res.sMensaje)
    if (res.nCodigo === 0) await getDiferenciasConciliadasData()
  }

  return {
    diferenciasConciliadas,
    loadInInit,
    getDiferenciasConciliadasData,
    form,
    onChange,
    emisores,
    cadenas,
    getFilters,
    isTableLoading,
    selected,
    handleSelected,
    authorizeItems,
    unauthorizeItems,
    observacion,
    setObservacion,
    isOpenConfirmDialog,
    handleCloseConfirmDialog,
    handleOpenConfirmDialog,
    observacion,
    setObservacion,
    innerSelected,
    saveAuthorizedOperations
  };
};
