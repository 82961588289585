import { Close, PersonAdd, Person, Visibility, VisibilityOff, KeyboardArrowDown, KeyboardArrowUp } from '@mui/icons-material';
import { Alert, Button, Checkbox, Collapse, FormControlLabel, Grid, IconButton, TextField, Typography } from '@mui/material';
import { Box } from '@mui/system';
import { TextInput } from '../form/TextInput';
import { ModalBase } from './ModalBase';
import { regexEmail, regexPassword, regexTelefono } from '../../helpers/regexs';
import { SelectInput } from '../form/SelectInput';

import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { BlueSwitch } from '../../helpers/stylesMaterial';
import { useAuthPage } from '../../hooks/useAuthPage';
import { ConfirmDialog } from './ConfirmDialog';
import UnpublishedIcon from '@mui/icons-material/Unpublished';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import EditIcon from '@mui/icons-material/Edit';
import { SearchInput } from '../form/SearchInput';

export const ModalUsuario = (props) => {

  const {
    isOpenModal = false,
    modalType = "add",
    form = {},
    onChange,
    handleSubmit,
    handleSubmitChangeEstatus,
    handleCloseModal,
    perfiles = [],
    opciones = [],
    secciones = [],
    handlePermisos,
    enableEditModal,
    disableEditModal,
    currentUser,
    procesos = [],
    onChangeSearch
  } = props;

  const [showPermisos, setShowPermisos] = useState(false)
  const [showConfirmDialog, setShowConfirmDialog] = useState(false)
  const { setAllowComponent } = useAuthPage();

  useEffect(() => {
    setShowPermisos(false)
  }, [isOpenModal])

  useEffect(() => {
    setShowPermisos(form.nPersonalizado)
  }, [form.nPersonalizado])

  const showTitleButton = () => {
    if (modalType === 'view' && setAllowComponent("editarUsuario") && form.nIdPerfil != 1 && form.nIdEstatus !== 2 && currentUser !== form.nIdUsuario) return <Button variant='contained' onClick={enableEditModal}
      startIcon={<EditIcon />}>Editar</Button>;
    if (modalType === 'edit' && setAllowComponent("cambiarEstatusUsuario") && form.nIdEstatus !== 2) {
      return <Button
        onClick={() => setShowConfirmDialog(true)}
        variant={"contained"}
        color={form.nIdEstatus !== 1 ? "neutral" : "error"}
        startIcon={form.nIdEstatus !== 1 ? <CheckCircleIcon /> : <UnpublishedIcon />}
      >
        {form.nIdEstatus !== 1 ? 'Habilitar usuario' : 'Deshabilitar usuario'}
      </Button>;
    }
  }

  const handleConfirmDialog = async () => {
    setShowConfirmDialog(false)
    await handleSubmitChangeEstatus()
  }

  const showSectionPermisos = (nIdSeccion) => {
    let innerOpciones = opciones.filter(opcion => opcion.nIdSeccion === nIdSeccion)
    return (
      <div style={{ borderLeft: "2px solid #00CD6850", paddingLeft: 20, marginLeft: 10 }}>{
        innerOpciones.sort((a, b) => (a.nOrden < b.nOrden ? -1 : 1)).map(opcion => {
          const isChecked = form.sPermisos.length > 0 ? form.sPermisos.some(op => op.nIdOpcion === opcion.nIdOpcion) : false
          let children = [...opcion.children];
          return (<>
            <FormControlLabel
              control={<Checkbox />}
              checked={isChecked}
              disabled={!form.nPersonalizado || form.nIdEstatus === 2 || modalType === 'view'}
              label={`${opcion.sTitulo} (${opcion.sDescripcion})`}
              onChange={(e) => handlePermisos(e.target.checked, opcion)}
            />
            <br></br>
            {children.length > 0 && <div style={{ borderLeft: "2px solid #00609C40", paddingLeft: 25, marginLeft: 10 }}>
              {
                children.sort((a, b) => (a.nOrden < b.nOrden ? -1 : 1)).map(child => {

                  const isCheckedChild = form.sPermisos.length > 0 ? form.sPermisos.some(op => op.nIdOpcion === child.nIdOpcion) : false
                  const subChildren = JSON.parse(child.children);

                  return (<>
                    <FormControlLabel
                      control={<Checkbox />}
                      checked={isCheckedChild}
                      disabled={!form.nPersonalizado || form.nIdEstatus === 2 || modalType === 'view'}
                      label={`${child.sTitulo} (${child.sDescripcion})`}
                      onChange={(e) => handlePermisos(e.target.checked, child)}
                    />
                    <br></br>
                    {subChildren.length > 0 && <div style={{ borderLeft: "2px solid #B7B7B780", paddingLeft: 30, marginLeft: 10 }}>
                      {
                        subChildren.sort((a, b) => (a.nOrden < b.nOrden ? -1 : 1)).map(subchild => {

                          const isCheckedSubChild = form.sPermisos.length > 0 ? form.sPermisos.some(op => op.nIdOpcion === subchild.nIdOpcion) : false

                          return (<>
                            <FormControlLabel
                              control={<Checkbox />}
                              checked={isCheckedSubChild}
                              disabled={!form.nPersonalizado || form.nIdEstatus === 2 || modalType === 'view'}
                              label={`${subchild.sTitulo} (${subchild.sDescripcion})`}
                              onChange={(e) => handlePermisos(e.target.checked, subchild)}
                            />
                            <br></br>
                          </>)
                        })
                      }
                    </div>}
                  </>)
                })
              }
            </div>}
          </>)
        })
      }</div>)
  }

  return (<>
    <ConfirmDialog
      isOpenDialog={showConfirmDialog}
      onCloseDialog={() => setShowConfirmDialog(false)}
      onConfimDialog={handleConfirmDialog}
      message={`¿Estas seguro/a de ${form.nIdEstatus === 1 ? 'deshabilitar' : 'habilitar'} al usuario?`}
      textButtonConfirm="Sí, si estoy seguro/a."
    />
    <ModalBase open={isOpenModal} title="" style={{ width: '1050px', height: 'auto' }}>
      <Box
        sx={{ width: '100%', height: '100%', display: 'flex', flexDirection: 'column' }}
      >
        <TitleModal title={modalType === "add" ? "Usuario" : form.sNombreCompleto} typeOperation={modalType} extraButton={showTitleButton()} />

        <Grid container spacing={2} sx={{ mt: 1, mb: 2, maxHeight: "calc(100vh - 250px)", overflow: 'auto' }}>
          <Grid item xs={12}>
            <Typography variant="h6" color={"primary"}>Datos del usuario</Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextInput
              id="sNombre"
              name="sNombre"
              label="Nombre"
              value={form.sNombre}
              onChangeRoot={onChange}
              disabled={modalType === 'view'}
              require
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextInput
              id="sAPaterno"
              name="sAPaterno"
              label="Apellido paterno"
              value={form.sAPaterno}
              onChangeRoot={onChange}
              disabled={modalType === 'view'}
              require
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextInput
              id="sAMaterno"
              name="sAMaterno"
              label="Apellido materno"
              value={form.sAMaterno}
              onChangeRoot={onChange}
              disabled={modalType === 'view'}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <SearchInput
              options={[...procesos ?? []]}
              value={form.sProcesos}
              name={"sProcesos"}
              label={"Proceso(s) asignado(s)"}
              getOptionLabel={"sDescripcion"}
              getExtraData={"sEventoNotificacion"}
              getIndexLabel={"nIdProceso"}
              disabled={modalType === 'view'}
              onChange={onChangeSearch}
              multiple
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextInput
              id="sCorreo"
              name="sCorreo"
              label="Correo"
              value={form.sCorreo}
              onChangeRoot={onChange}
              expresionRegular={regexEmail}
              disabled={modalType === 'view'}
              erroMsg="Correo inválido"
              require
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextInput
              id="sUsuario"
              name="sUsuario"
              label="Usuario"
              value={form.sUsuario}
              autoComplete="new-password"
              disabled={modalType !== 'add'}
              onChangeRoot={onChange}
              require
            />
          </Grid>
          <Grid item xs={12}>
            <Typography variant="h6" color={"primary"}>Perfil del usuario</Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <SelectInput
              id="nIdPerfil"
              name="nIdPerfil"
              onChange={(e) => onChange({ nIdPerfil: e.target.value })}
              label="Perfil"
              data={perfiles}
              value={form.nIdPerfil}
              disabled={modalType === 'view'}
              text="sNombre"
              idOption={"nIdPerfil"}
              extraData={"sDescripcion"}
            />
          </Grid>
          <Grid item container xs={12} sm={6} alignItems={"center"} >
            <Button disabled={!form.nIdPerfil} onClick={() => setShowPermisos(!showPermisos)} startIcon={showPermisos ? <KeyboardArrowUp /> : <KeyboardArrowDown />} color="primary">
              {showPermisos ? "Ocultar" : "Mostrar"} detalles del perfil
            </Button>
          </Grid>


          {showPermisos ? <Grid item xs={12} container sx={{ background: "#f7f7f7", borderRadius: 2, margin: 2, padding: 2 }}><Grid item xs={12} container alignItems={"center"} sx={{ mb: 2 }}>
            <Grid item sm={7} xs={12}>
              <Typography variant="h6" color={"primary"}>Permisos BackOffice</Typography></Grid>
            {(form.nPersonalizado && modalType !== "view") ?
              <Grid item sm={5} xs={12}><SelectInput
                id="nIdPerfil"
                name="nIdPerfil"
                onChange={(e) => onChange({ nIdPerfilPlantilla: e.target.value })}
                label="Usa perfil como plantilla"
                data={perfiles.slice(0, -1)}
                value={form.nIdPerfilPlantilla}
                text="sNombre"
                idOption={"nIdPerfil"}
              /></Grid> : <></>}

          </Grid>
            <Grid item xs={12}>
              {
                secciones.filter(seccion => seccion.nServicio === 0).map(seccion => {
                  return (
                    <>
                      <Typography variant="h6" fontSize={17} color={"#00CD68"}>{seccion.sNombre}</Typography>
                      {showSectionPermisos(seccion.nIdSeccion)}
                    </>)
                })
              }
            </Grid>
            <Grid item xs={12} container alignItems={"center"} sx={{ mb: 2, mt: 4 }}>
              <Typography variant="h6" color={"primary"}>Permisos Webservices</Typography>
            </Grid>
            <Grid item xs={12}>
              {
                secciones.filter(seccion => seccion.nServicio === 1).map(seccion => {
                  return (
                    <>
                      <Typography variant="h6" fontSize={17} color={"#00CD68"}>{seccion.sNombre}</Typography>

                      {showSectionPermisos(seccion.nIdSeccion)}
                    </>)
                })
              }
            </Grid>
            {((form.nPersonalizado && modalType === "add") || (form.nPersonalizado && form.nIdEstatus !== 2 && modalType === "edit")) ?
              <Grid item xs={12} container alignItems={"center"}>
                <Grid item xs={3} container alignItems={"center"}>
                  <p>¿Guardar perfil?</p>
                  <BlueSwitch
                    onChange={(e) => onChange({ savePerfil: e.target.checked })}
                    inputProps={{ "aria-label": "controlled" }}
                    name="savePerfil"
                    checked={form.savePerfil}
                    value={form.savePerfil}
                  />
                  <p>{form.savePerfil ? "Sí" : "No"}</p>
                </Grid>
                {form.savePerfil && <Grid item xs={9} container spacing={1}>
                  <Grid item xs={5}>
                    <TextInput
                      id="sNombrePerfil"
                      name="sNombrePerfil"
                      label="Nombre del perfil"
                      value={form.sNombrePerfil}
                      autoComplete="new-password"
                      onChangeRoot={onChange}
                      require
                    />
                  </Grid>
                  <Grid item xs={7}>
                    <TextInput
                      id="sDescripcionPerfil"
                      name="sDescripcionPerfil"
                      label="Descripción del perfil"
                      value={form.sDescripcionPerfil}
                      onChangeRoot={onChange}
                      require
                    />
                  </Grid>
                </Grid>}
              </Grid> : <></>}
          </Grid> : <></>}
        </Grid>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            width: '100%',
            justifyContent: 'end'
          }}
        >
          <Button onClick={modalType === "edit" ? disableEditModal : handleCloseModal} color="primary">
            {modalType === "edit" ? "Cancelar" : "Cerrar"}
          </Button>
          {modalType !== 'view' &&
            <Button
              onClick={handleSubmit}
              color="primary"
              variant='contained'
            >
              Guardar
            </Button>
          }
        </div>
      </Box>
    </ModalBase>
  </>
  );
};

const TitleModal = ({ title, typeOperation, extraButton }) => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        width: '100%',
        marginBottom: '10px',
        justifyContent: 'space-between'
      }}
    >
      <div style={{ display: 'flex', justifyContent: "space-between" }}>
        {typeOperation === 'add' ?
          <PersonAdd fontSize={'large'} sx={{ color: '#00CD68', fontSize: '45px' }} />
          : <Person fontSize={'large'} sx={{ color: '#00CD68', fontSize: '45px' }} />}

        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <Typography
            variant="p"
            sx={{
              marginLeft: '15px',
              fontSize: '14px',
              marginBottom: '4px'
            }}
          >
            {typeOperation === 'add' ? 'Agregar' : typeOperation === 'edit' ? 'Editar usuario' : 'Ver usuario'}
          </Typography>
          <Typography
            variant="p"
            sx={{
              marginLeft: '15px',
              color: '#00609C',
              fontSize: '20px'
            }}
          >
            {title}
          </Typography>
        </div>
      </div>
      <div>
        {extraButton}
      </div>
    </Box>
  );
};

