import { configureStore } from '@reduxjs/toolkit';
import { userSlice } from './user/userSlice';
import { todosSlice } from './todos/todosSlice';
import { menuSlice } from './menu/menuSlice';
import { authSlice } from '../features/auth/authSlice';
import { uiSlice } from './ui/uiSlice';
import { cadenaSlice } from './cadena/cadenaSlice';
import { authPageSlice } from './authPage/authPageSlice';
import { catalagoSlice } from './catalogo/catalagoSlice';
import { reportsSlice } from './reports/reportsSlice';
import { newPasswordSlice } from './newPassword/newPasswordSlice';
import { loginErrorSlice } from './loginError/loginErrorSlice';
import { efrSlice } from './efr/efrSlice';
import { forgotPasswordSlice } from './forgotPassword/forgotPasswordSlice';
import { emisorSlice } from './emisor/emisorSlice';
import { dashboardSlice } from './dashboard/dashboardSlice';
import { configConciliacionSlice } from './configConciliacion/configConciliacionSlice';
import { contabilidadSlice } from './contabilidad/contabilidadSlice';
import { facturacionSlice } from './facturacion/facturacionSlice';
import { conciliacionSlice } from './conciliacion/conciliacionSlice';
import { usuariosSlice } from './usuarios/usuariosSlice';
import { sucursalesSlice } from './sucursales/sucursalesSlice';
import { suspensionesSlice } from './suspensiones/suspensionesSlice';
import { conexionesSFTPSlice } from './conexionesSFTP/conexionesSFTPSlice';
import { monitoreoSlice } from './monitoreo/monitoreoSlice';

export const store = configureStore({
  reducer: {
    user: userSlice.reducer,
    todo: todosSlice.reducer,
    menu: menuSlice.reducer,
    auth: authSlice.reducer,
    ui: uiSlice.reducer,
    cadena: cadenaSlice.reducer,
    authPage: authPageSlice.reducer,
    catalogo: catalagoSlice.reducer,
    reports: reportsSlice.reducer,
    newPassword: newPasswordSlice.reducer,
    login: loginErrorSlice.reducer,
    efr: efrSlice.reducer,
    forgotPassword: forgotPasswordSlice.reducer,
    emisor: emisorSlice.reducer,
    dashboard: dashboardSlice.reducer,
    contabilidad: contabilidadSlice.reducer,
    facturacion: facturacionSlice.reducer,
    conciliacion: conciliacionSlice.reducer,
    usuarios: usuariosSlice.reducer,
    sucursales: sucursalesSlice.reducer,
    configConciliacion: configConciliacionSlice.reducer,
    suspensiones: suspensionesSlice.reducer,
    conexionesSFTP: conexionesSFTPSlice.reducer,
    monitoreo: monitoreoSlice.reducer,
  }
});
