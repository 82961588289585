import { useSelector, useDispatch } from "react-redux";
import { useEffect, useState } from "react";
import { usuarioPerfilForm } from "../helpers/forms";
import { deletePerfilUsuario, getUsuariosData, insertPerfilUsuario, insertUsuario, updatePerfilUsuario, updateUsuario, updateUsuarioBaja, updateUsuarioEstatus } from "../slices/usuarios/usuariosThunk";
import { startLoadPermisosData, startLoadProcesos } from "../slices/catalogo/catalgoThunk";
import { useModal } from "./useModal";

export const usePerfilesUsuariosTable = () => {
  const [form, setForm] = useState(usuarioPerfilForm);
  const { perfiles, opciones, secciones, procesos } = useSelector((state) => state.catalogo);
  const dispatch = useDispatch();
  const [showModal, setShowModal] = useState({
    open: false,
    type: "add"
  })
  const { setOpenToast } = useModal();
  const [permisosBackUp, setPermisosBackUp] = useState([])

  const [dataTable, setDataTable] = useState({
    rows: []
  })

  const onClickAdd = async () => {
    await openModal("add", usuarioPerfilForm)
  }

  const openModal = async (type, form = {}) => {
    setShowModal({
      open: true,
      type
    })
    setForm({
      ...usuarioPerfilForm,
      ...form
    })
    setPermisosBackUp(form.sPermisos ?? [])
  }

  const enableEditModal = () => {
    setShowModal({
      open: true,
      type: "edit"
    })
  }

  const disableEditModal = () => {
    setShowModal({
      open: true,
      type: "view"
    })
  }

  const loadInInit = async () => {
    const rows = await dispatch(startLoadPermisosData(0, 1));
    setDataTable({
      rows,
    })
  };

  const onChangeInput = async (value) => {
    setForm({ ...form, ...value });
  };

  const onChange = (e, name = null, value = null) => {
    const inputName = name ?? e.target.name;
    const inputValue = value ?? e.target.value;

    setForm({
      ...form,
      [inputName]: inputValue,
    });
  }

  const handlePermisos = (checked, opcion) => {
    let innerPermisos = [...form.sPermisos]
    if (checked) innerPermisos.push(opcion);
    else innerPermisos = innerPermisos.filter(permiso => parseInt(permiso.nIdOpcion) !== parseInt(opcion.nIdOpcion))
    setForm({ ...form, sPermisos: innerPermisos, isPermisosChanged: true })
  }

  const handleSubmit = async () => {
    const validatedData = validateData()
    if (validatedData.ok) {
      let innerForm = { ...form, nIdEstatus: 1 }

      if (showModal.type !== "add") {
        const innerPermisosBackUp = [...permisosBackUp].map(per => (parseInt(per.nIdOpcion)))
        const innerPermisos = [...form.sPermisos].map(per => (parseInt(per.nIdOpcion)))

        const sPermisosAdded = innerPermisos.filter(per => !innerPermisosBackUp.includes(per)).map(per => ({ nIdOpcion: per }))
        const sPermisosRemoved = innerPermisosBackUp.filter(per => !innerPermisos.includes(per)).map(per => ({ nIdOpcion: per }))

        innerForm = { ...innerForm, sPermisosAdded, sPermisosRemoved }
  
      }
      const resp = await dispatch(showModal.type === "add" ? insertPerfilUsuario(innerForm) : updatePerfilUsuario(innerForm))
      setOpenToast(!resp.ok, resp.msg);
      if (resp.ok) {
        handleCloseModal()
        await loadInInit();
      }
    } else {
      setOpenToast(true, validatedData.error);
    }
  }

  const handleSubmitChangeEstatus = async (inrForm = false) => {
    let innerForm = inrForm ? inrForm : form
    
    innerForm = {
      ...innerForm,
      nIdEstatus: 0
    }

    const resp = await dispatch(deletePerfilUsuario(innerForm))
    setOpenToast(!resp.ok, resp.msg);
    if (resp.ok) {
      handleCloseModal()
      await loadInInit();
    }
  }

  const handleCloseModal = () => {
    setShowModal({
      open: false,
      type: showModal.type
    })
  }

  const validateData = () => {
    if (form.sNombre === '')
      return { ok: false, error: 'Ingrese el nombre del perfil' }
    if (form.sDescripcion === '')
      return { ok: false, error: 'Ingrese la descripción del perfil' }
    if (form.sPermisos.length < 1)
      return { ok: false, error: 'Seleccione permisos para asignarle al perfil.' }


    return { ok: true, error: '' }
  }


  return {
    dataTable,
    loadInInit,
    onClickAdd,
    showModal,
    handleCloseModal,
    handleSubmit,
    form,
    onChangeInput,
    perfiles,
    opciones,
    handlePermisos,
    openModal,
    enableEditModal,
    handleSubmitChangeEstatus,
    disableEditModal,
    secciones,
    procesos,
    onChange
  };
};
