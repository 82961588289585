import { Visibility, VisibilityOff } from '@mui/icons-material';
import {
  createTheme,
  Typography,
  Button,
  Card,
  ThemeProvider,
  Box,
  TextField,
  FormGroup,
  IconButton
} from '@mui/material';
import { useState } from 'react';

import { useNewPassword } from '../hooks/useNewPassword';

export const NewPasswordPage = () => {
  const { matchPassword, notMatchPassword, incorrectFormat } = useNewPassword();
  const iconTheme = createTheme({
    status: {
      danger: '#e53e3e'
    },
    palette: {
      primary: {
        main: '#00609C',
        darker: '#053e85'
      },
      neutral: {
        main: '#00D487',
        contrastText: '#fff'
      }
    }
  });

  const [formData, setFormData] = useState({
    password1: '',
    password2: ''
  });

  const [requirements, setRequirements] = useState({
    display: 'none'
  });

  const { password1, password2 } = formData;

  const onChange = (e) => {
    setFormData((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value
    }));
    setRequirements('flex');
  };

  const onSubmit = (e) => {
    e.preventDefault();
    matchPassword(password1, password2);
    console.log(password1, password2);
  };

  const [values, setValues] = useState({
    notMatch: false,
    showPassword: false,
    showPassword2: false
  });

  const handleClickShowPassword = () => {
    setValues({
      ...values,
      showPassword: !values.showPassword
    });
  };

  const handleClickShowPassword2 = () => {
    setValues({
      ...values,
      showPassword2: !values.showPassword2
    });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  return (
    <ThemeProvider theme={iconTheme}>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          width: '100%',
          height: '100vh',
          alignItems: 'center',
          justifyContent: 'center',
          verticalAlign: 'center',
          background: '#f9f9f9'
        }}
      >
        <Box
          sx={{
            width: '350px',
            textAlign: 'center',
            verticalAlign: 'center'
          }}
        >
          <img
            src="/assets/SofipayLogo.png"
            alt="LogoSofipay"
            width={'250px'}
            style={{ marginBottom: 30 }}
          />
          <Typography variant="h5" color="primary" sx={{ marginBottom: 3 }}>
            Cambio de contraseña
          </Typography>
          <section className="form">
            <form onSubmit={onSubmit}>
              <Box
                sx={{
                  display: 'flex',
                  width: '100%',
                  justifyContent: 'center'
                }}
              >
                <FormGroup sx={{ gap: 2 }}>
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center'
                    }}
                  >

                    <TextField
                      label="Nueva contraseña"
                      color="neutral"
                      focused
                      id="password1"
                      name="password1"
                      value={password1}
                      placeholder="Nueva contraseña"
                      onChange={onChange}
                      sx={{ width: '100%' }}
                      type={values.showPassword ? 'text' : 'password'}
                      InputProps={{
                        endAdornment: (
                          <IconButton
                            onClick={handleClickShowPassword}
                            onMouseDown={handleMouseDownPassword}
                          >
                            {values.showPassword ? <VisibilityOff /> : <Visibility />}
                          </IconButton>
                        )
                      }}
                    />
                  </Box>
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center'
                    }}
                  >
                    <TextField
                      label="Confirmar contraseña"
                      color="neutral"
                      focused
                      type={values.showPassword2 ? 'text' : 'password'}
                      id="password2"
                      name="password2"
                      value={password2}
                      sx={{ width: '100%' }}
                      placeholder="Ingresa de nuevo tu contraseña"
                      onChange={onChange}
                      InputProps={{
                        endAdornment: (
                          <IconButton
                            onClick={handleClickShowPassword2}
                            onMouseDown={handleMouseDownPassword}
                          >
                            {values.showPassword2 ? <VisibilityOff /> : <Visibility />}
                          </IconButton>
                        )
                      }}
                    />
                  </Box>
                  <Typography variant="caption">
                    La contraseña debe tener de 8 a 20 caracteres incluyendo letras mayúsculas y minúsculas, un número y un caracter especial.
                  </Typography>
                  <Button variant="contained" type="submit" color="neutral">
                    Guardar
                  </Button>

                  {(notMatchPassword || incorrectFormat) && <Typography
                    variant="caption"
                    sx={{
                      margingBottom: '10px',
                      color: 'red'
                    }}
                  >
                    {notMatchPassword ? "Las contraseñas no coinciden." : "La contraseña tiene formato incorrecto"}
                  </Typography>}

                </FormGroup>
              </Box>
            </form>
          </section>
        </Box>
      </Box>
    </ThemeProvider>
  );
};
