import { useEffect, useState } from "react";
import { useMenu } from "../hooks/useMenu";
import { useAuthPage } from "../hooks/useAuthPage";
import { Button, Card, Dialog, DialogActions, DialogContent, DialogTitle, Grid, IconButton, TextField, Tooltip, Typography } from "@mui/material";
import { createTheme, ThemeProvider } from "@mui/material";
import { esES } from "@mui/material/locale";
import { useAutorizacionPage } from "../hooks/useAutorizacionPage";
import EnhancedTable from "../components/table/EnhancedTable";
import { SearchInput } from "../components/form/SearchInput";

import { FilterAltOutlined, Check, Save } from '@mui/icons-material';
import moment from 'moment';

const iconTheme = createTheme(
  {
    status: {
      danger: "#e53e3e",
    },
    palette: {
      primary: {
        main: "#00609C",
        dark: "#053e85",
      },
      neutral: {
        main: "#00CD68",
        light: "#053e85",
        dark: "#053e85",
        contrastText: "#fff",
      },
    },
  },
  esES
);

const columnsOperaciones = [
  {
    name: "Referencia",
    key: "sReferencia",
    format: "text",
  },
  {
    name: "Autorización",
    key: "sAutorizacion",
    format: "text",
  },
  {
    name: "Monto",
    key: "nMonto",
    format: "price",
  },
  {
    name: "Comisión",
    key: "nComision",
    format: "price",
  },
  {
    name: "Fecha",
    key: "dFecha",
    format: "date",
  },
]

const formatValue = (value, type) => {
  if (type && value !== '----') {
    switch (type) {
      case "date":
        return moment(value[value.length - 1] === "Z" ? value.slice(0, -1) : value).format("YYYY-MM-DD");
      case "datetime":
        return moment(value[value.length - 1] === "Z" ? value.slice(0, -1) : value).format("YYYY-MM-DD hh:mm:ss");
      case "price":
        return parseFloat(value)?.toLocaleString("en-US", {
          style: "currency",
          currency: "USD",
        });
    }
  }
  return value;
};

const columns = [
  { field: 'sNombreComercial', headerName: 'Nombre comercial', type: 'text', flex: 1 },
  { field: 'sNombre', headerName: 'Nombre archivo', type: 'text', flex: 1 },
  { field: 'dFechaConciliacion', headerName: 'Fecha conciliación', type: 'date', flex: 1 },
  {
    field: 'valoresDiferencias', headerName: 'Valores esperados en la operación', type: 'render', flex: 3, renderFunction: (row) => {
      const valoresDiferencias = JSON.parse(row.valoresDiferencias)
      const operaciones = JSON.parse(row.operaciones)
      return (
        <Grid>
          {columnsOperaciones.map(column => {
            const value = valoresDiferencias.find(valor => valor.sCampo === column.key)
            return (
              <>
                <p className={value ? "red" : ""} style={{ margin: 0 }}>{column.name}: <b>{formatValue(value ? value.sValorEsperado : operaciones ? operaciones[column.key] : '----', column.format)}</b> </p>
              </>
            )
          })}
        </Grid>
      )
    }
  },
  {
    field: 'valoresDiferencias', headerName: 'Valores recibidos en la operación', type: 'render', flex: 3, renderFunction: (row) => {
      const valoresDiferencias = JSON.parse(row.valoresDiferencias)
      const diferenciasOperaciones = JSON.parse(row.diferenciasOperaciones)
      return (
        <Grid>
          {columnsOperaciones.map(column => {
            const value = valoresDiferencias.find(valor => valor.sCampo === column.key)
            return (
              <>
                <p className={value ? "red" : ""} style={{ margin: 0 }}>{column.name}: <b>{formatValue(value ? value.sValorRecibido : diferenciasOperaciones ? diferenciasOperaciones[column.key] : '----', column.format)}</b> </p>
              </>
            )
          })}
        </Grid>
      )
    }
  },
  {
    field: 'sComentario', headerName: 'Comentario', type: 'render', flex: 1, renderFunction: (row) => (
      <Grid style={{ whiteSpace: "break-spaces" }}>
        <p style={{ display: "inline-block", color: row.nIdEstatusProceso === 2 ? "#00CD68" : "red", background: row.nIdEstatusProceso === 2 ? "#00CD6815" : "#FF000015", padding: "4px 8px", borderRadius: "4px", fontWeight: 500, margin: 0}} >{row.nIdEstatusProceso === 2 ? "Autorizar conciliación" : "Autorizar rechazo"}</p> <br></br>
        {row.sComentario}
      </Grid>
    )
  },
]

export const AutorizacionPage = () => {
  const {
    diferenciasConciliadas,
    loadInInit,
    getDiferenciasConciliadasData,
    form,
    onChange,
    emisores,
    cadenas,
    getFilters,
    isTableLoading,
    selected,
    handleSelected,
    authorizeItems,
    unauthorizeItems,
    isOpenConfirmDialog,
    handleCloseConfirmDialog,
    handleOpenConfirmDialog,
    observacion,
    setObservacion,
    innerSelected,
    saveAuthorizedOperations
  } = useAutorizacionPage();

  const { setNameSection } = useMenu();
  const { setNewPage, isAllowed, setAllowComponent } = useAuthPage();
  const [loaded, setLoaded] = useState(false);



  useEffect(() => {
    if (!loaded) {
      loadInInit();
      setNewPage("conciliacionPendientesAutorizar");
      setNameSection("conciliacionPendientesAutorizar");
      setLoaded(true);
    }
  }, []);

  if (isAllowed) {
    return (
      <ThemeProvider theme={iconTheme}>
        <Dialog
          open={isOpenConfirmDialog}
          onClose={handleCloseConfirmDialog}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title"
            style={{ minWidth: 450 }}>
            {innerSelected.length === 1 ? "Se autorizará 1 operación" : `Se autorizarán ${innerSelected.length} operaciones`}
          </DialogTitle>
          <DialogContent>
            <TextField
              label="Observación"
              multiline
              fullWidth
              rows={2}
              sx={{ mt: 2 }}
              value={observacion}
              onChange={(e) =>
                setObservacion(e.target.value)}
              required
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseConfirmDialog}>Cancelar</Button>
            <Button onClick={authorizeItems} disabled={!observacion} variant="contained">
              Confirmar autorización
            </Button>
          </DialogActions>
        </Dialog>
        <EnhancedTable
          table={{
            columns,
            rows: diferenciasConciliadas
          }}
          showCheckboxes={setAllowComponent("guardarOperacionesAutorizadas")}
          loading={isTableLoading}
          getFilters={getFilters}
          selected={selected}
          setSelected={handleSelected}
          selectedItemsButtons={
            <Button
              variant="contained"
              startIcon={<Check />}
              onClick={() => {
                handleOpenConfirmDialog(selected)
                handleSelected([])
              }}
            >
              Autorizar
            </Button>
          }
          extraFilters={(resetPagination)=> (
            <Grid container justifyContent={"space-between"} alignItems={"center"} sx={{ padding: 2 }}>

              <Grid item sm={1} xs={12}>
                Filtros:
              </Grid>
              <Grid item sm={9} xs={12}>
                <Grid container spacing={2} alignItems={"center"}>
                  <Grid item xs={12} sm={3}>
                    <SearchInput
                      options={[
                        {
                          id: 0,
                          name: "Todos",
                        },
                        {
                          id: 1,
                          name: "EFR",
                        },
                        {
                          id: 2,
                          name: "Comisionista",
                        },
                      ]}
                      value={form.nActor}
                      name={"nActor"}
                      label={"Entidad"}
                      getOptionLabel={"name"}
                      getIndexLabel={"id"}
                      onChange={onChange}
                    />
                  </Grid>
                  {form.nActor !== 0 &&
                    <Grid item xs={12} sm={4}>
                      {
                        form.nActor === 1 || form.nActor === '1' ?
                          <SearchInput
                            options={[{ nIdEmisor: 0, sNombreComercial: 'Todos' }, ...emisores?.rows]}
                            value={form.nIdEmisor}
                            name={"nIdEmisor"}
                            label={form.nActor ? "Entidad financiera" : ""}
                            getOptionLabel={"sNombreComercial"}
                            getIndexLabel={"nIdEmisor"}
                            onChange={onChange}
                          />
                          :
                          <SearchInput
                            options={[{ nIdCadena: 0, sNombreComercial: 'Todos' }, ...cadenas?.rows]}
                            value={form.nIdCadena}
                            name={"nIdCadena"}
                            label={form.nActor ? "Comisionista" : ""}
                            getOptionLabel={"sNombreComercial"}
                            getIndexLabel={"nIdCadena"}
                            onChange={onChange}
                          />
                      }

                    </Grid>
                  }
                </Grid>
              </Grid>
              <Grid item sm={2} xs={12} style={{ textAlign: "right" }}>
                <Button
                  variant="contained"
                  startIcon={<FilterAltOutlined />}
                  onClick={() => {
                    getDiferenciasConciliadasData()
                    resetPagination()
                  }}
                >
                  Filtrar
                </Button>
              </Grid>
            </Grid>)
          }
          extraRowButtons={setAllowComponent("guardarOperacionesAutorizadas")  ?
            (id, row) => {
              return (row.authorized ?
                <Button size="small" onClick={() => unauthorizeItems([id])}>
                  Cancelar
                </Button>
                :
                <Button size="small" color="neutral" variant="contained" onClick={() => handleOpenConfirmDialog([id])} startIcon={<Check />}>
                  Autorizar
                </Button>
              ) 
            }
          : null}
          extraButtons={setAllowComponent("guardarOperacionesAutorizadas")  ?
            <>
              <Button
                variant="contained"
                startIcon={<Save />}
                disabled={diferenciasConciliadas.filter((dif) => dif.authorized && dif.sObservacion).length === 0}
                onClick={saveAuthorizedOperations}
              >
                Guardar {diferenciasConciliadas.filter((dif) => dif.authorized && dif.sObservacion).length} operacion(es) autorizada(s)
              </Button>
            </> : <></>
          }
          rowId={'nIdDiferencia'}
        />
      </ThemeProvider>
    );
  } else {
    setNameSection("");
    return (
      <Card>
        <Typography variant="h3">Contenido no disponible</Typography>
      </Card>
    );
  }
};
