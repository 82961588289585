import { useSelector } from 'react-redux';
import { getFormatEmailFactura, getPayWeekList } from './getData';
import { regexEmail, regexNums, regexRFC, regexTelefono, regexNumChart } from './regexs'
import moment from 'moment';
import { nIdTipoLiquidacion24Hrs } from './forms';

const getStatus = (error, ok = false, idElement = '') => ({ ok, error, idElement });

export const isValidContactoCadena = (form) => {
  if (form.nombre === '')
    return { ok: false, error: 'Ingrese el nombre del contacto' }

  if (form.apellido === '')
    return { ok: false, error: 'Ingrese el apellido del contacto' }

  if (form.telefono === '')
    return { ok: false, error: 'Ingrese el teléfono' }

  if (form.email === '')
    return { ok: false, error: 'Ingrese el correo' }

  if (form.area === '')
    return { ok: false, error: 'Ingrese el área del contacto' }

  if (form.puesto === '')
    return { ok: false, error: 'Ingrese el puesto del contacto' }

  if (!regexTelefono.test(form.telefono))
    return { ok: false, error: 'Número teléfono inválido' }

  if (form.proceso === '')
    return { ok: false, error: 'Seleccione el proceso' }

  if (!regexEmail.test(form.email))
    return { ok: false, error: 'Correo inválido' }

  return { ok: true, error: '' }

}

export const isValidDatosGeneralesCadena = ({
  form,
  isCadena = true,
  documentos,
  procesos = []
}) => {

  // ****************** DATOS GENERALES ******************
  const isValidGeneral = validateDataGenral(form);
  if (!isValidGeneral.ok)
    return getStatus(isValidGeneral.error, false, 'datosGenerales');

  // ****************** DIRECCION Y CONTACTO ******************
  const isValidAddressContact = validateAddressContact(form, procesos);
  if (!isValidAddressContact.ok)
    return getStatus(isValidAddressContact.error, false, 'direccion');


  // ****************** DATOS BANCARIOS ******************
  const isValidDataBank = validateDataBank(form)
  if (!isValidDataBank.ok)
    return getStatus(isValidDataBank.error, false, 'datosBancarios');

  // ****************** DATOS OPERATIVOS ******************
  const isValidDataOperational = validateOperationData(form);
  if (!isValidDataOperational.ok)
    return getStatus(isValidDataOperational.error, false, 'datosOperativos');

  // ****************** LIQUIDACION ******************

  // if ( !isCadena && form.liquidacion.tipo === '' ) 
  //   return getStatus('Seleccione el tipo de liquidación');
  // if (
  //   !isCadena &&
  //   form.liquidacion.tipoSelected?.nPorDia === 1 &&
  //   form.liquidacion.pagoMes === ''
  // )
  //   return getStatus('Seleccione un día.');

  // if ( !isCadena && form.liquidacion.tipoSelected?.nPorDia === 0 ) {
  //   const days = getPayWeekList(form.liquidacion);
  //   const { ok, error } = validPayWeek(days);
  //   if ( !ok ) return getStatus(error);
  // }

  // ****************** CASH IN || CASH OUT ******************
  const isValidCashInAndOut = validateCashInAndOut(form, isCadena);
  if (!isValidCashInAndOut.ok)
    return getStatus(isValidCashInAndOut.error, false, isValidCashInAndOut.idElement);


  // ***************** DOCUMENTOS **************************
  const isValidDocuments = validateDocuments(form, documentos);
  if (!isValidDocuments.ok)
    return getStatus(isValidDocuments.error, false, 'documentos');

  // ****************** FACTURA COMISION POR TRANFERENCIA ******************
  const isValidFac = validateFac(form);
  if (!isValidFac.ok)
    return getStatus(isValidFac.error, false, 'facturaTransferencia');




  // ? ****************** SUCCESS ******************
  return getStatus('', true);
}



export const validEmailFacutra = (form, emails = []) => {

  if (form.email === '') return getStatus('Ingrese el correo');
  if (!regexEmail.test(form.email)) return getStatus('Correo inválido')

  if (!!emails.find((em) => (em.email === form.email && em.id !== form.id)))
    return getStatus('El correo ya existe');

  return getStatus('', true);
}

const validEmails = (emails) => {

  let isOk = true;
  emails.forEach((email) => {
    if (!regexEmail.test(email)) {
      isOk = false;
      return;
    }
  });
  return isOk
    ? getStatus('', true)
    : getStatus('Ingrese un correo valido');

}


export const isValidEscalonadoForm = (form, validMontoMaximo = false) => {
  if (form.montoMinimo === '')
    return { ok: false, error: 'Ingrese el monto mínimo' }

  if (form.montoMaximo === '')
    return { ok: false, error: 'Ingrese el monto máximo' }

  if (
    validMontoMaximo &&
    form.montoMaximo !== '0' &&
    parseFloat(form.montoMaximo) <= parseFloat(form.montoMinimo)
  )
    return getStatus(
      `El monto máximo(${form.montoMaximo}) debe ser mayor al monto mínimo(${form.montoMinimo})`
    );

  if (form.comisionMontoFijo === '')
    return { ok: false, error: 'Ingrese la comisión monto fijo' }

  // if ( form.comisionPorcentaj === '' )
  //   return { ok: false, error: 'Ingrese la comisión porcentaje' }

  return { ok: true, error: '' }
}

export const isValidEscCantidadForm = (form, validMontoMaximo = false) => {
  if (form.cantidadMinimo === '')
    return { ok: false, error: 'Ingrese la cantidad mínima' }

  if (form.cantidadMaxima === '')
    return { ok: false, error: 'Ingrese la cantidad máximo' }

  if (
    validMontoMaximo &&
    parseInt(form.cantidadMaxima) !== 0 &&
    parseFloat(form.cantidadMaxima) <= parseFloat(form.cantidadMinimo)
  )
    return getStatus(
      `La cantidad máxima(${form.cantidadMaxima}) debe ser mayor a la cantidad mínima(${form.cantidadMinimo})`
    );

  if (form.comisionMontoFijo === '')
    return { ok: false, error: 'Ingrese la comisión monto fijo' }

  // if ( form.comisionPorcentaj === '' )
  //   return { ok: false, error: 'Ingrese la comisión porcentaje' }

  return { ok: true, error: '' }
}

const validListEscalonado = (escalonados) => {
  let flag = getStatus('', true);
  escalonados.forEach((esc) => {
    const isOk = isValidEscalonadoForm(esc, true);
    if (!isOk.ok) {
      flag = isOk;
      return;
    }
  });
  return flag;
}

export const validListEscCantidad = (escalonados) => {
  let flag = getStatus('', true);
  const hasFinalEsc = escalonados.find(esc => parseInt(esc.cantidadMaxima) === 0)
  if (!hasFinalEsc) {
    return { ok: false, error: 'No cuenta con un escalón final. La cantidad máxima del escalón final debe de ser 0.' }
  }
  escalonados.forEach((esc) => {
    const isOk = isValidEscCantidadForm(esc, true);
    if (!isOk.ok) {
      flag = isOk;
      return;
    };
  });
  return flag;
}

const validPayWeek = (daysWeek) => {

  let isOk = true;
  daysWeek.forEach((day) => {
    if (day.nDiaPago === '') {
      isOk = false
      return
    }
  });

  return isOk
    ? getStatus('', true)
    : getStatus('Complete los días a pagar de la liquidación');

}

const validateContactoNotifacion = (contactos = [], procesos = []) => {

  let res = {
    notFound: false,
    value: ""
  }
  procesos.filter(proceso => proceso.nRequerido)?.map(proceso => {
    if (!res.notFound) {
      let existsProceso = false
      contactos.forEach((contact) => {
        console.log(contact)
        if (!existsProceso) {
          const procesoFound = contact.proceso.find(con => parseInt(con.nIdProceso) === parseInt(proceso.nIdProceso))
          existsProceso = !!procesoFound
        }
      });
      if (!existsProceso) {
        res = {
          notFound: true,
          value: proceso.sDescripcion
        }
      }
    }
  })

  return !res.notFound
    ? getStatus('', true)
    : getStatus(`Ingrese o modifique un contacto que su proceso sea "${res.value}"`);
}

// * =====================================================================================
// * VALIDAR DATOS GENERALES
// * =====================================================================================

const validateDataGenral = (form) => {
  if (form.razonSocial === '') return getStatus('Ingrese la razón social');
  if (form.nombreComercial === '') return getStatus('Ingrese el nombre comercial');
  if (form.rfc === '') return getStatus('Ingrese el RFC');
  if (!regexRFC.test(form.rfc)) return getStatus('RFC inválido');
  if (form.regimenSocietario === '') return getStatus('Ingrese el régimen de capital');
  if (form.sActividadEconomica === '') return getStatus('Ingrese la actividad económica');
  if (form.sMail === '') return getStatus('Ingrese el correo');
  if (form.sTelefono === '') return getStatus('Ingrese el teléfono');
  if (!regexEmail.test(form.sMail)) return getStatus('Correo inválido');
  if (!regexTelefono.test(form.sTelefono)) return getStatus('Número teléfono inválido');
  if (form.giro === '') return getStatus('Seleccione el giro');
  return getStatus('', true);
}

// * ----------------------
// * DATOS GENERALES - EFR
// * ----------------------
const validateDataGeneralEFR = (form) => {
  if (form.isIndirecto && !form.nIdIntegrador) return getStatus('Ingrese el integrador de la EFR');
  if (form.isIndirecto && form.nIdIntegrador && (form.nPorcentajeComision > 100 || form.nPorcentajeComision == "")) return getStatus(form.nPorcentajeComision > 100 ? 'Ingrese un porcentaje de comisión de integrador menor a 100' : 'Ingrese un porcentaje de comisión de integrador');
  if (form.razonSocial === '') return getStatus('Ingrese la razón social');
  if (form.nombreComercial === '') return getStatus('Ingrese el nombre comercial');
  if (form.rfc === '') return getStatus('Ingrese el RFC');
  if (!regexRFC.test(form.rfc)) return getStatus('RFC inválido');
  if (form.regimenSocietario === '') return getStatus('Ingrese el régimen de capital');
  if (form.sActividadEconomica === '') return getStatus('Ingrese la actividad económica');
  if (form.sMail === '') return getStatus('Ingrese el correo');
  if (form.sTelefono === '') return getStatus('Ingrese el teléfono');
  if (!regexEmail.test(form.sMail)) return getStatus('Correo inválido');
  if (!regexTelefono.test(form.sTelefono)) return getStatus('Número teléfono inválido');
  if (!form.nIdIdentificador || form.nIdIdentificador === 0 || form.nIdIdentificador.trim() === '') return getStatus('Seleccione el identificador de la CNBV');

  return getStatus('', true);
}

// * =====================================================================================
// * VALIDAR DIRECCION Y CONTACTO
// * =====================================================================================

const validateAddressContact = (form, procesos = []) => {

  if (form.calle === '') return getStatus('Ingrese la calle'); // Preguntar si solo son numeros
  if (form.noExterior === '') return getStatus('Ingrese el número exterior');
  if (!regexNums.test(form.noExterior))
    return getStatus('El número de exterior tiene un formato inválido');
  if (form.noInterior !== '' && !regexNumChart.test(form.noInterior))
    return getStatus('El número de interior tiene formato inválido');
  if (form.codigoPostal === '') return getStatus('Ingrese el código postal');
  if (form.codigoPostal.length < 5) return getStatus('Ingrese un código postal válido');
  if (form.estado === '' || form.ciudad === '' || form.colonia === '')
    return getStatus('Ingrese un código postal válido');
  if (form.tipo === '') return getStatus('Ingrese el tipo de direccion');
  if (form.contactos.length === 0) return getStatus('Ingrese un contacto.');

  const isValidNotificaction = validateContactoNotifacion(form.contactos, procesos);
  if (!isValidNotificaction.ok)
    return getStatus(isValidNotificaction.error);

  return getStatus('', true);
}

// * =====================================================================================
// * VALIDAR DATOS BANCARIOS
// * =====================================================================================

const validateDataBank = (form) => {

  if (form.beneficiario === '') return getStatus('Ingrese el beneficiario');
  if (form.clabeInterBanc === '') {
    return getStatus(
      'Ingrese la CLABE interbancaria para depósitos de pagos',
    );
  }
  if (form.clabeInterBanc.length !== 18) {
    return getStatus('CLABE interbancaria inválida, debe tener una longitud de 18 caracteres')
  }
  if (form.refNumerica === '') return getStatus('Ingrese la referencia numérica');
  if (form.refAlfaNumerica === '') return getStatus('Ingrese la referencia alfanumérica');
  if (form.banco === '') return getStatus('Seleccione un banco');
  return getStatus('', true);
}

// * =====================================================================================
// * VALIDAR DATOS OPERATIVOS
// * =====================================================================================

const validateOperationData = (form, validateMontoMaximo = true) => {

  if (form.retencion === '')
    return getStatus('Seleccione la retención');
  /* if (validateMontoMaximo && form.montoRecepcion === '')
    return getStatus('Ingrese el monto máximo de recepción');
  if (validateMontoMaximo && form.montoRecepcion < 0)
    return getStatus('Ingrese un monto positivo en el monto máximo de recepción'); */
  if (form.costoTransferencia === '')
    return getStatus('Ingrese el costo por transferencia')
  if (form.ingragracion === '') return getStatus('Seleccione la integración');
  if (form.ivaOperativo === '')
    return getStatus('Seleccione el IVA de datos operativos');

  if (form.dInicioOperaciones === '') {
    return getStatus('Ingrese la fecha de inicio de operaciones');
  }
  if (!isValidDate(form.dInicioOperaciones)) {
    return getStatus('Ingrese la fecha de inicio de operaciones no tiene un formato valido');
  }

  if (form.isActiveMGracia) {

    if (form.nMesesGracia) {
      const end = moment(form.nMesesGracia, 'YYYY-MM-DD')
      const now = moment(form.dInicioOperaciones, 'YYYY-MM-DD')
      const diff = end.diff(now, 'days');

      if (diff <= 0) {
        return getStatus('El periodo de gracia debe ser mayor a la fecha de inicio de operaciones');
      }
    }

  }

  if (form.isEscaloando) {
    const { ok, error } = validListEscalonado(form.escalonadoForms);
    if (!ok) return getStatus(error);
  }
  return getStatus('', true);

}

// * ----------------------
// * DATOS OPERATIVOS - EFR
// * ----------------------
const validateOperationDataEFR = (form) => {

  const { ok: okOpe, error: errorOpe } = validateOperationData(form, false);
  if (!okOpe) return getStatus(errorOpe);

  // if ( form.montoBase === '' ) return getStatus('Ingrese el monto base');

  if (form.facturacionMinima === '')
    return getStatus('Ingrese el valor de la facturacion mínima');

  if (form.isEscCantidad) {
    const { ok, error } = validListEscCantidad(form.escCantidadForms);
    if (!ok) return getStatus(error);
  }

  if ([form.liquidacionCashIn.tipoSelected?.nIdTipoLiquidacion, form.liquidacionCashOut.tipoSelected?.nIdTipoLiquidacion].includes(nIdTipoLiquidacion24Hrs)
    && form.escCantidadForms.length > 1) 
    return getStatus('El máximo número de escalones es 1 cuando el tipo de liquidación es "24 horas".');

  if (form.dInicioOperaciones === '') {
    return getStatus('Ingrese la fecha de inicio de operaciones');
  }

  if (!isValidDate(form.dInicioOperaciones)) {
    return getStatus('Ingrese la fecha de inicio de operaciones no tiene un formato valido');
  }

  if (form.isActiveMGracia) {

    if (form.nMesesGracia) {
      const end = moment(form.nMesesGracia, 'YYYY-MM-DD')
      const now = moment(form.dInicioOperaciones, 'YYYY-MM-DD')
      const diff = end.diff(now, 'days');

      if (diff <= 0) {
        return getStatus('El periodo de gracia debe ser mayor a la fecha de inicio de operaciones');
      }
    }

  }

  return getStatus('', true);
}

// * =====================================================================================
// * VALIDAR CASH IN - OUT
// * =====================================================================================

const validateCashInAndOut = (form, isCadena) => {

  if (isCadena && form.isActiveCashIn && form.isActiveCashOut)
    return getStatus('Seleccione el método de entrega', false, 'cashIn');

  if (isCadena && !form.isActiveCashIn && form.liquidacionCashIn.tipo === '')
    return getStatus('Seleccione el tipo de liquidación', false, 'cashIn');

  // * CASH IN
  console.log("Validate cashin", isCadena, form.isActiveCashIn, form.liquidacionCashIn)
  if (
    !form.isActiveCashIn &&
    form.liquidacionCashIn.tipoSelected?.nIdTipoLiquidacion.toString()  === nIdTipoLiquidacion24Hrs.toString()  
  ) {

    console.log("Hey")
    if(!form.liquidacionCashIn.nMontoBase) return getStatus('Ingrese el monto base.', false, 'cashIn');
    if(!form.liquidacionCashIn.nPerTransaccion) return getStatus('Ingrese el porcentaje por transacción.', false, 'cashIn');
  }
  
  if (
    isCadena &&
    !form.isActiveCashIn &&
    form.liquidacionCashIn.tipoSelected?.nPorDia === 1 &&
    form.liquidacionCashIn.pagoMes === ''
  )
    return getStatus('Seleccione un día.', false, 'cashIn');

  if (
    isCadena &&
    !form.isActiveCashIn &&
    (form.liquidacionCashIn.tipoSelected?.nPorDia === 0 ||
      form.liquidacionCashIn.tipoSelected === null)
  ) {
    const days = getPayWeekList(form.liquidacionCashIn);
    const { ok, error } = validPayWeek(days);
    if (!ok) return getStatus(error + ' CASH IN', false, 'cashIn');
  }

  // * CASH OUT

  if (
    !form.isActiveCashOut &&
    form.liquidacionCashOut.tipoSelected?.nIdTipoLiquidacion.toString() === nIdTipoLiquidacion24Hrs.toString()  
  ) {
    if(!form.liquidacionCashOut.nMontoBase) return getStatus('Ingrese el monto base.', false, 'cashOut');
    if(!form.liquidacionCashOut.nPerTransaccion) return getStatus('Ingrese el porcentaje por transacción.', false, 'cashOut');
  }

  if (
    isCadena &&
    !form.isActiveCashOut &&
    form.liquidacionCashOut.tipoSelected?.nPorDia === 1 &&
    form.liquidacionCashOut.pagoMes === ''
  )
    return getStatus('Seleccione un día.', false, 'cashOut');

  if (
    isCadena &&
    !form.isActiveCashOut &&
    (form.liquidacionCashOut.tipoSelected?.nPorDia === 0 ||
      form.liquidacionCashOut.tipoSelected === null)
  ) {
    const days = getPayWeekList(form.liquidacionCashOut);
    const { ok, error } = validPayWeek(days);
    if (!ok) return getStatus(error + ' CASH OUT', false, 'cashOut');
  }

  
  return getStatus('', true);
}

// * =====================================================================================
// * VALIDAR DOCUMENTOS
// * =====================================================================================
export const validateDocuments = (form, documentos) => {

  if (
    documentos.length === 0 ||
    documentos.length !== Object.keys(form.documentos).length
  ) return getStatus('Suba todos los documentos');

  return getStatus('', true);
}
// * =====================================================================================
// * VALIDAR FACTURAS
// * =====================================================================================

export const validateFac = (form) => {

  if (form.ivaFac === '') {
    return getStatus('Seleccione el IVA de factura comisión por transferencia');
  }

  if (form.regimenFiscalFac === '')
    return getStatus('Seleccione el régimen fiscal');

  if (form.factura.cfdi === '')
    return getStatus('Seleccion el CDFI');

  if (form.factura.formaPago === '')
    return getStatus('Seleccione la forma de pago');

  if (form.factura.metodoPago === '')
    return getStatus('Seleccione el metodo de pago');

  if (form.factura.claveProducto === '')
    return getStatus('Seleccione la clave del producto');

  if (form.factura.claveUnidad === '')
    return getStatus('Seleccione la clave de unidad');

  if (form.factura.diasLiquidacion === '') {
    return getStatus('Ingrese los días para liquidar factura');
  }

  const emailsFactura = getFormatEmailFactura(form.factura.emails);

  if (emailsFactura.length === 0) {
    return getStatus('Ingrese un correo a enviar la factura');
  }

  const { ok, error } = validEmails(emailsFactura);
  if (!ok) return getStatus(error);

  return getStatus('', true);
}

// * ----------------------
// * FACTURACION EFR
// * ----------------------
export const validateFactEFRByType = (form) => {

  if (form.cfdi === '') return getStatus('Seleccion el CDFI');
  if (form.formaPago === '') return getStatus('Seleccione la forma de pago');
  if (form.metodoPago === '') return getStatus('Seleccione el metodo de pago');
  if (form.claveProducto === '') return getStatus('Seleccione la clave del producto');
  if (form.claveUnidad === '') return getStatus('Seleccione la clave de la unidad');
  if (form.diasLiquidacion === '') {
    return getStatus('Ingrese los días para liquidar factura');
  }

  const emailsFactura = getFormatEmailFactura(form.emails);
  if (emailsFactura.length === 0) {
    return getStatus('Ingrese un correo a enviar la factura');
  }

  const { ok, error } = validEmails(emailsFactura);
  if (!ok) return getStatus(error);

  return getStatus('', true);

}

const validateFactEFR = (form) => {

  if (form.iva === '') {
    return getStatus('Seleccione el IVA de la facturación');
  }

  if (form.regimenFiscal === '') {
    return getStatus('Seleccione el régimen fiscal')
  }

  const { ok: okComision, error: errorComision } = validateFactEFRByType(form.comision);
  if (!okComision) return getStatus(errorComision + ' en factura por comisión');

  const { ok: okTrans, error: errorTrans } = validateFactEFRByType(form.transferencia);
  if (!okTrans)
    return getStatus(errorTrans + ' en factura comisión por transferencia');

  const { ok: okMin, error: errorMin } = validateFactEFRByType(form.minimo);
  if (!okMin) return getStatus(errorMin + ' en factura comisión por monto mínimo');

  return getStatus('', true);

}


// * =====================================================================================
// * Validacion de Emisores
// * =====================================================================================

export const isValidDataEFR = ({
  form,
  isCadena = true,
  documentos,
  procesos = []
}) => {

  // ****************** DATOS GENERALES ******************
  const isValidGeneral = validateDataGeneralEFR(form);
  if (!isValidGeneral.ok)
    return getStatus(isValidGeneral.error, false, 'datosGenerales');

  // ****************** DIRECCION Y CONTACTO ******************
  const isValidAddressContact = validateAddressContact(form, procesos);
  if (!isValidAddressContact.ok)
    return getStatus(isValidAddressContact.error, false, 'direccion');


  // ****************** DATOS BANCARIOS ******************
  const isValidDataBank = validateDataBank(form)
  if (!isValidDataBank.ok)
    return getStatus(isValidDataBank.error, false, 'datosBancarios');

  // ****************** DATOS OPERATIVOS ******************
  const isValidDataOperational = validateOperationDataEFR(form);
  if (!isValidDataOperational.ok)
    return getStatus(isValidDataOperational.error, false, 'datosOperativos');


  // ****************** CASH IN || CASH OUT ******************
  const isValidCashInAndOut = validateCashInAndOut(form, isCadena);
  if (!isValidCashInAndOut.ok)
    return getStatus(isValidCashInAndOut.error, false, isValidCashInAndOut.idElement);


  // ***************** DOCUMENTOS **************************
  const isValidDocuments = validateDocuments(form, documentos);
  if (!isValidDocuments.ok)
    return getStatus(isValidDocuments.error, false, 'documentos');

  // ****************** FACTURA  ******************
  const isValidFac = validateFactEFR(form.facturas);
  if (!isValidFac.ok)
    return getStatus(isValidFac.error, false, 'facturaciones');

  // ? ****************** SUCCESS ******************
  return getStatus('', true);
}

export const isValidDate = (date, format = 'YYYY-MM-DD') => {
  return moment(date, format, true).isValid();
}

// * =====================================================================================
// * Validacion de cadenas - editar 
// * =====================================================================================

export const validateDataGeneralEdit = (form, validateCadena = true) => {
  if (form.sRazonSocial === '') return getStatus('Ingrese la razón social');
  if (form.sNombreComercial === '') return getStatus('Ingrese el nombre comercial');
  if (form.sRFC === '') return getStatus('Ingrese el RFC');
  if (!regexRFC.test(form.sRFC)) return getStatus('RFC inválido');
  if (form.sRegimenSocietario === '') return getStatus('Ingrese el régimen de capital');
  if (form.sActividadEconomica === '') return getStatus('Ingrese la actividad económica');
  if (form.sMail === '') return getStatus('Ingrese el correo');
  if (form.sTelefono === '') return getStatus('Ingrese el teléfono');
  if (!regexEmail.test(form.sMail)) return getStatus('Correo inválido');
  if (!regexTelefono.test(form.sTelefono)) return getStatus('Número teléfono inválido');
  if (form.isIndirecto && !form.nIdIntegrador) return getStatus('Seleccione un integrador');
  if (form.isIndirecto && !(form.nPorcentajeComision < 100 && form.nPorcentajeComision > 0)) return getStatus('Ingrese un porcentaje para la comisión válido');
  if (validateCadena) {
    if (form.nIdGiro === '') return getStatus('Seleccione el giro');
  }
  return getStatus('', true);
}

export const validateAddressContactEdit = (form, procesos = []) => {

  if (form.sCalle === '') return getStatus('Ingrese la calle'); // Preguntar si solo son numeros
  if (form.nNumExterno === '') return getStatus('Ingrese el número exterior');
  if (!regexNums.test(form.nNumExterno))
    return getStatus('El número de exterior tiene un formato inválido');
  if (form.sNumInterno !== '' && !regexNumChart.test(form.sNumInterno))
    return getStatus('El número de interior tiene formato inválido');
  if (form.nCodigoPostal === '') return getStatus('Ingrese el código postal');
  if (form.nCodigoPostal.length < 5) return getStatus('Ingrese un código postal válido');
  if (form.sNombreEntidad === '' || form.nIdMunicipio === '' || form.nIdColonia === '')
    return getStatus('Ingrese un código postal válido');
  if (form.nIdTipoDireccion === '') return getStatus('Ingrese el tipo de direccion');
  if (form.contactos.length === 0) return getStatus('Ingrese un contacto.');

  const isValidNotificaction = validateContactoNotifacion(form.contactos, procesos);
  if (!isValidNotificaction.ok)
    return getStatus(isValidNotificaction.error);

  return getStatus('', true);
}

export const validateDataBankEdit = (form) => {

  if (form.sBeneficiario === '') return getStatus('Ingrese el beneficiario');
  if (form.sClabe === '') {
    return getStatus(
      'Ingrese la CLABE interbancaria para depósitos de pagos',
    );
  }
  if (form.sClabe.length !== 18) {
    return getStatus('CLABE interbancaria inválida, debe tener una longitud de 18 caracteres')
  }
  if (!form.sRefNumerica) return getStatus('Ingrese la referencia numérica');
  if (form.sRefAlfanumerica === '') return getStatus('Ingrese la referencia alfanumérica');
  if (form.nIdBanco === '') return getStatus('Seleccione un banco');
  return getStatus('', true);
}

export const validateOperationDataEdit = (form, validateMontoMaximo = true) => {

  if (form.nIdRetencion === '')
    return getStatus('Seleccione la retención');
  /* if (validateMontoMaximo && form.nMontoMaximo === '')
    return getStatus('Ingrese el monto máximo de recepción'); */
  if (form.nCostoTransferencia === '')
    return getStatus('Ingrese el costo por transferencia')
  if (form.nIdIntegracion === '') return getStatus('Seleccione la integración');
  if (form.nIdIVA === '')
    return getStatus('Seleccione el IVA de datos operativos');

  /*
  if ( form.dInicioOperaciones === '' ) {
    return getStatus('Ingrese la fecha de inicio de operaciones');
  }
  if ( !isValidDate(form.dInicioOperaciones) ) {
    return getStatus('Ingrese la fecha de inicio de operaciones no tiene un formato valido');
  }
  */

  if (form.isActiveEscalonado) {
    const { ok, error } = validListEscalonado(form.escalonadoForms);
    if (!ok) return getStatus(error);
  }
  return getStatus('', true);

}

export const validateOperationDataEditEFR = (form) => {

  const { ok: okOpe, error: errorOpe } = validateOperationDataEdit(form, false);
  if (!okOpe) return getStatus(errorOpe);

  // if ( form.montoBase === '' ) return getStatus('Ingrese el monto base');

  if (form.nFacturacionMinima === '')
    return getStatus('Ingrese el valor de la facturacion mínima');

  if (form.isEscCantidad) {
    const { ok, error } = validListEscCantidad(form.escCantidadForms);
    if (!ok) return getStatus(error);
  }

  // if ( form.dInicioOperaciones === '' ) {
  //   return getStatus('Ingrese la fecha de inicio de operaciones');
  // }

  // if ( !isValidDate(form.dInicioOperaciones) ) {
  //   return getStatus('Ingrese la fecha de inicio de operaciones no tiene un formato valido');
  // }

  // if ( form.isActiveMGracia ) {

  //   if ( form.nMesesGracia ) {
  //     const end = moment(form.nMesesGracia, 'YYYY-MM-DD')
  //     const now = moment(form.dInicioOperaciones, 'YYYY-MM-DD')
  //     const diff = end.diff(now, 'days');

  //     if ( diff <= 0 ) {
  //       return getStatus('El periodo de gracia debe ser mayor a la fecha de inicio operacion');
  //     }
  //   }

  // }

  return getStatus('', true);
}


export const validateCashInEdit = (form, isCadena) => {
  console.log(form)
  if (isCadena && !form.isActiveCashIn && form.liquidacionCashIn.tipo === '')
    return getStatus('Seleccione el tipo de liquidación', false, 'cashIn');

  // * CASH IN

  if (
    isCadena &&
    !form.isActiveCashIn &&
    form.liquidacionCashIn.tipoSelected?.nPorDia === 1 &&
    form.liquidacionCashIn.pagoMes === ''
  )
    return getStatus('Seleccione un día.', false, 'cashIn');

  if (
    isCadena &&
    !form.isActiveCashIn &&
    (form.liquidacionCashIn.tipoSelected?.nPorDia === 0 ||
      form.liquidacionCashIn.tipoSelected === null)
  ) {
    const days = getPayWeekList(form.liquidacionCashIn);
    const { ok, error } = validPayWeek(days);
    if (!ok) return getStatus(error + ' CASH IN', false, 'cashIn');
  }

  if (
    !isCadena &&
    !form.isActiveCashIn &&
    form.liquidacionCashIn.tipoSelected?.nIdTipoLiquidacion === nIdTipoLiquidacion24Hrs 
  ) {
    if(!form.liquidacionCashIn.nMontoBase) return getStatus('Ingrese el monto base.', false, 'cashIn');
    if(!form.liquidacionCashIn.nPerTransaccion) return getStatus('Ingrese el porcentaje por transacción.', false, 'cashIn');
    if(form.oEslonadosOperacion.length > 1) return getStatus('El tipo de liquidación "24 horas" sólo admite 1 escalón.', false, 'cashIn');
  }

  return getStatus('', true);
}

export const validateCashOutEdit = (form, isCadena) => {

  // * CASH OUT
  if (
    isCadena &&
    !form.isActiveCashOut &&
    form.liquidacionCashOut.tipoSelected?.nPorDia === 1 &&
    form.liquidacionCashOut.pagoMes === ''
  )
    return getStatus('Seleccione un día.', false, 'cashOut');

  if (
    isCadena &&
    !form.isActiveCashOut &&
    (form.liquidacionCashOut.tipoSelected?.nPorDia === 0 ||
      form.liquidacionCashOut.tipoSelected === null)
  ) {
    const days = getPayWeekList(form.liquidacionCashOut);
    const { ok, error } = validPayWeek(days);
    if (!ok) return getStatus(error + ' CASH OUT', false, 'cashOut');
  }

  if (
    !isCadena &&
    !form.isActiveCashOut &&
    form.liquidacionCashOut.tipoSelected?.nIdTipoLiquidacion === nIdTipoLiquidacion24Hrs 
  ) {
    if(!form.liquidacionCashOut.nMontoBase) return getStatus('Ingrese el monto base.', false, 'cashOut');
    if(!form.liquidacionCashOut.nPerTransaccion) return getStatus('Ingrese el porcentaje por transacción.', false, 'cashOut');
    if(form.oEslonadosOperacion.length > 1) return getStatus('El tipo de liquidación "24 horas" sólo admite 1 escalón.', false, 'cashOut');
  }

  return getStatus('', true);

}

export const validateFacturaTransferenciaEdit = (form) => {

  if (form.sRegimenFiscal === '')
    return getStatus('Seleccione el régimen fiscal');

  if (form.sUsoCFDI === '')
    return getStatus('Seleccion el CDFI');

  if (form.sFormaPago === '')
    return getStatus('Seleccione la forma de pago');

  if (form.sMetodoPago === '')
    return getStatus('Seleccione el metodo de pago');

  if (form.sClaveProductoServicio === '')
    return getStatus('Seleccione la clave del producto');

  if (form.sUnidad === '')
    return getStatus('Seleccione la clave de unidad');

  if (form.nDiaPago === '') {
    return getStatus('Ingrese los días para liquidar factura');
  }

  const emailsFactura = getFormatEmailFactura(form.emails);

  if (emailsFactura.length === 0) {
    return getStatus('Ingrese un correo a enviar la factura');
  }

  const { ok, error } = validEmails(emailsFactura);
  if (!ok) return getStatus(error);

  return getStatus('', true);
}