import * as React from "react";
import MobileStepper from "@mui/material/MobileStepper";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { Box } from "@mui/system";
import { ModalBase } from "./ModalBase";
import {
  Button,
  Grid,
  Typography,
  Dialog,
  DialogActions,
  DialogTitle,
  styled,
  Paper,
  Alert,
  AlertTitle,
} from "@mui/material";
import { DropFileInput } from "../form/DropFileInput";
import LinearProgress, {
  linearProgressClasses,
} from "@mui/material/LinearProgress";
import PublishIcon from '@mui/icons-material/Publish';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import { useConciliacionPage } from "../../hooks/useConciliacionPage";
import { logIn } from "../../features/auth/authSlice";

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 20,
  borderRadius: 10,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: "#f8f8f8",
    border: "1px solid #979797",
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 10,
    backgroundColor: "#00CD68",
  },
}));

export const ModalImportConciliacion = (props) => {
  const {
    isOpenConfirmDialog,
    setIsOpenConfirmDialog,
    fileData,
    importMethod,
    isOpenImportConciliacion,
    activeStep,
    handleDrop,
    deleteFile,
    progress,
    log,
    scrollDownRef,
    handleBack,
    confirmDelete,
    handleNext,
    handleCloseImport,
    processMessage,

    selectedFile,
    setSelectedFile,
    setFileData,
  } = useConciliacionPage();


  return (
    <>
      <Dialog
        open={isOpenConfirmDialog}
        onClose={() => setIsOpenConfirmDialog(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          Se cargará el archivo ¿Estas seguro/a?
        </DialogTitle>
        <DialogActions>
          <Button onClick={() => setIsOpenConfirmDialog(false)}>
            Cancelar
          </Button>
          <Button onClick={() => importMethod()} variant="contained">
            Estoy seguro/a
          </Button>
        </DialogActions>
      </Dialog>
      <ModalBase
        open={isOpenImportConciliacion}
        style={{ width: "1150px" /*, height: "450px" */ }}
      >
        <Box
          sx={{
            width: "100%",
            height: "100%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
          }}
        >
          <Grid container style={{ alignItems: "space-between" }}>
            <Grid container item sm={8} style={{ alignItems: "center" }}>
              <Grid
                item
                style={{
                  color: "#00CD68",
                  padding: 15,
                  fontWeight: "bold",
                }}
              >
                <PublishIcon fontSize={"large"} />
              </Grid>
              <Grid item>
                <Typography variant="p" color={"neutral"}>
                  Cargar archivo manual
                </Typography>
                <Typography variant="h5" color={"primary"}>
                  Conciliación
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          {activeStep === 0 && (
            <DropFileInput
              handleDrop={handleDrop}
              fileData={fileData}
              setFileData={setFileData}
              selectedFile={selectedFile}
              setSelectedFile={setSelectedFile}
              acceptedFormat={".txt"}
            />
          )}
          {activeStep === 1 && (
            <Grid container spacing={2}>
              <Grid item container xs={12} style={{ padding: 20 }}>

                <Grid item xs={4} style={{
                  borderRadius: 8,
                  padding: "0px 15px",
                  minHeight: 300,
                  maxHeight: 600,
                  overflowY: "auto"
                }}>
                  {fileData.nError !== 0 ?
                    <Grid style={{
                      borderRadius: 8,
                      background: "#D32E2E",
                      overflowX: "hidden",
                      position: "relative",
                      color: "white"
                    }}>
                      <Grid style={{ background: "#A7232390", padding: "10px 15px", fontWeight: 500, position: "sticky", top: 0, right: 0, backdropFilter: "blur(5px)" }}>El archivo tiene errores</Grid>
                      <Grid style={{ padding: "10px 15px" }}>{fileData.sMensaje}</Grid>
                    </Grid>
                    :
                    <>
                      <Typography variant="h6" fontSize={24} color="primary">Resumen</Typography>

                      <Typography variant="h6" sx={{ mt: 2 }}>Encabezado</Typography> <br />
                      <Typography variant="p">Fecha de conciliacion: <b>{fileData.header.dFecConciliacion}</b></Typography><br />
                      {fileData.header.nIdEmisor && <Typography variant="p">Identificador de la EFR: <b>{fileData.header.nIdEmisor}</b></Typography>}
                      {fileData.header.nIdCadena && <Typography variant="p">Identificador del Comisionista: <b>{fileData.header.nIdCadena}</b></Typography>}<br />

                      <Typography variant="h6" sx={{ mt: 2 }}>Registros</Typography> <br />
                      <Typography variant="p">Total de registros: <b>{fileData.nTotalRegistros}</b></Typography><br />
                      <Typography variant="p">Registros válidos: <b>{fileData.nRegistrosCargados}</b></Typography><br />

                      <Typography variant="h6" sx={{ mt: 2 }}>Pie</Typography> <br />
                      <Typography variant="p">Operaciones recibidas: <b>{fileData.footer.nOperacionesRecibidas}</b></Typography><br />
                      <Typography variant="p">Importe total: <b>{(fileData.footer.nImporteTotal / 100).toLocaleString("en-US", {
                        style: "currency",
                        currency: "USD",
                      })}</b></Typography><br />
                      <Typography variant="p">Importe comisiones: <b>{(fileData.footer.nComisionTotal / 100).toLocaleString("en-US", {
                        style: "currency",
                        currency: "USD",
                      })}</b></Typography><br />
                    </>
                  }

                </Grid>
                <Grid item xs={8} style={{
                  background: "#f8f8f8",
                  // border: "1px solid #979797",
                  borderRadius: 8,
                  alignItems: "flex-start", justifyContent: "flex-start",
                  minHeight: 300,
                  maxHeight: 600,
                  overflowY: "auto",
                  overflowX: "hidden",
                  position: "relative"
                }}>
                  <Grid style={{ background: "#d1d1d190", padding: "10px 15px", fontWeight: 500, position: "sticky", top: 0, right: 0, backdropFilter: "blur(5px)" }}>{fileData.name}</Grid>
                  <Grid style={{ padding: "10px 15px" }}>{fileData.data?.map(row => {
                    return (<>
                      {row}
                      <br />
                    </>)
                  })}</Grid>
                </Grid>
              </Grid>
            </Grid>
          )}
          {activeStep === 2 && (
            <Grid
              container
              justifyContent={"center"}
              flexDirection={"column"}
              spacing={2}
              style={{ padding: 20, width: "100%", textAlign: "center" }}
            >
              <Grid item xs={12} sm={12}>
                <Typography variant="p" color={"primary"}>
                  {processMessage} ({`${Math.round(progress)}%`})...
                </Typography>
              </Grid>
              <Grid item xs={12} sm={12}>
                <BorderLinearProgress
                  variant="determinate"
                  value={progress}
                />
              </Grid>
            </Grid>
          )}
          {activeStep === 3 &&
            <Grid
              container
              flexDirection={"column"}
              spacing={2}
              style={{ padding: 20, width: "100%" }}
            >
              <Grid item xs={12} sm={12}>
                <Alert severity={log.nCodigo === 0 ? "success" :"error"}>
                  <AlertTitle>{log.nCodigo === 0 ? "Carga manual terminada" :"Hubo un error procesando el archivo"}</AlertTitle>
                  {log.sMensaje}
                </Alert>
              </Grid>
            </Grid>
          }
          <MobileStepper
            variant="dots"
            steps={4}
            position="static"
            activeStep={activeStep}
            nextButton={<div style={{ width: 1, height: 1 }}></div>}
            backButton={<div style={{ width: 1, height: 1 }}></div>}
            sx={{
              "& .MuiMobileStepper-dot": {
                backgroundColor: "#00CD68",
                width: 12,
                height: 12,
              },
              "& .MuiMobileStepper-dotActive ~ .MuiMobileStepper-dot": {
                backgroundColor: "#D8D8D8",
                width: 12,
                height: 12,
              },
            }}
          />
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              width: "100%",
              justifyContent: "end",
            }}
          >
            {activeStep !== 3 && (
              <Button
                onClick={activeStep === 0 ? () => handleCloseImport(false) : handleBack}
                sx={{ color: "#00609C" }}
              >
                {[0, 2].includes(activeStep) ? "Cancelar" : "Anterior"}
              </Button>
            )}
            {![3, 2].includes(activeStep) && (
              <Button
                onClick={activeStep === 1 ? confirmDelete : handleNext}
                variant="contained"
                disabled={(activeStep === 0 && !fileData) || (activeStep === 1 && fileData.nError !== 0)}
              >
                Siguiente
              </Button>
            )}
            {activeStep === 3 && (
              <Button
                onClick={() => handleCloseImport(true)}
                variant="contained"
              >
                Finalizar
              </Button>
            )}
          </div>
        </Box>
      </ModalBase>
    </>
  );
};
