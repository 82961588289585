import { Snackbar, Alert } from '@mui/material';
import { useModal } from '../../../hooks/useModal';

export const Toast = () => {
  const { isOpenToast, setCloseToast } = useModal();

  return (
    <Snackbar
      open={isOpenToast.open}
      autoHideDuration={6000}
      onClose={setCloseToast}
      anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
    >
      <Alert
        onClose={setCloseToast}
        severity={isOpenToast.error ? 'error' : 'success'}
        sx={{ width: '100%' }}
        variant="filled"
      >
        {isOpenToast.message}
      </Alert>
    </Snackbar>
  );
};
