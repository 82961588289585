import { useSelector } from 'react-redux';
import { EscCantidadForm } from './EscCantidadForm';
import { EscCantidadEnhancedForm } from './EscCantidadEnhancedForm';
import { IconButton } from '@mui/material';
import { Add } from '@mui/icons-material';
import '../../styles/escalonesTable.css'

export const EscCantidadEnhancedForms = ({ isComisionesVigentes, onlyView, disableAddEscalon }) => {
  const { escCantidadForms = [] } = useSelector((state) => state.cadena);

  const isEditingRow = escCantidadForms.find(esc => esc.isEditing)
  return (
    <div style={{ display: 'flex', flexDirection: 'column', gap: '10px'}}>
      <table style={{ width: "99%", borderCollapse: "collapse", marginBottom: 10 }} className='escalonesTable'>
        <thead>
          <tr style={{ height: 50 }}>
            <th style={{ fontSize: 14 }}></th>
            <th style={{ fontSize: 14 }}>Cantidad<br></br>minima</th>
            <th style={{ fontSize: 14 }}>Cantidad<br></br>máxima</th>
            <th style={{ textAlign: 'left', fontSize: 14, width: 120, paddingLeft: 5 }}>&nbsp;&nbsp;&nbsp;&nbsp;Comisión</th>
            <th style={{ textAlign: 'left', fontSize: 14, width: 120, paddingLeft: 5 }}>&nbsp;&nbsp;&nbsp;&nbsp;IVA</th>
            <th style={{ textAlign: 'left', fontSize: 14, width: 120, paddingLeft: 5 }}>&nbsp;&nbsp;&nbsp;&nbsp;Subtotal</th>
            <th></th>
            <th></th>
          </tr>
        </thead>
        <tbody>

          {escCantidadForms.map((form, i) => {
            return (<EscCantidadEnhancedForm
              escalonado={form}
              key={form.id}
              id={form.id}
              isFirst={i === 0}
              isLast={i === escCantidadForms.length - 1 && !parseInt(form.cantidadMaxima)}
              index={i}
              isComisionesVigentes={isComisionesVigentes}
              onlyView={onlyView}
              disabledButtons={isEditingRow && isEditingRow.id !== form.id}
              disableAddEscalon={disableAddEscalon}
              arrayEscalonados={escCantidadForms}
            />)
          })}
        </tbody></table>
    </div>
  )
}
