import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  dataTable:  {
    columns: [],
    rows   : []
  },
  selectedRows: []
};

export const reportsSlice = createSlice({
  name: 'reports',
  initialState,
  reducers: {
    setDataTable: (state, action) => {
      state.dataTable = {...action.payload};
    },
    setSelectedRows: (state, action) => {
      state.selectedRows = [...action.payload];
    },
    addSelectedRow: (state, action) => {
      state.selectedRows = [...state.selectedRows, action.payload];
    },
    deleteSelectedRow: (state, action) => {
      state.selectedRows = state.selectedRows.filter(row => row.nIdOrdenPago !== action.payload.nIdOrdenPago);
    },
  }
});

export const {
  setDataTable,
  setSelectedRows,
  addSelectedRow,
  deleteSelectedRow
} = reportsSlice.actions;
