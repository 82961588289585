import { useEffect, useState } from "react";
import {
  AssignmentInd,
  LocationOn,
  AccountBalance,
  Settings,
  PersonAddAlt1,
  Person,
  RemoveRedEye,
  CreditScore,
  AddCard,
  Receipt,
  DriveFolderUpload,
} from "@mui/icons-material";
import {
  Box,
  Button,
  Collapse,
  Grid,
  InputAdornment,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";

import { TextInput } from "../components/form/TextInput";
import { IvaInput } from "../components/form/IvaInput";

import { Card } from "../components/Card";
import { SelectInput } from "../components/form/SelectInput";
import { AddButton } from "../components/AddButton";
import { useFormPage } from "../hooks/useFormPage";
import { regexNums, regexRFC, regexNumChart, regexEmail, regexTelefono } from "../helpers/regexs";
import { EscalonadoForms } from "../components/cadena/escalonado/EscalonadoForms";
import { Liquidacion } from "../components/cadena/Liquidacion";
import {
  BlueSwitch,
  buttonContactStyle,
  buttonFileStyle,
  buttonViewPDF,
} from "../helpers/stylesMaterial";

import { typesSave } from "../helpers/types";
import { EmailsFacturaCadena } from "../components/cadena/emailFactura/EmailsFacturaCadena";
import { ivas } from "../helpers/data-iva";
import { SearchInput } from "../components/form/SearchInput";
import { CuentaContable } from "../components/CuentaContable";

import "../styles/cadenas.css";
import { periodicidades } from "../helpers/getData";
import {
  DatePicker,
  DesktopDatePicker,
  LocalizationProvider,
} from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { CuentaContableCash } from "../components/cuentaContables/CuentaContableCash";
import { ConfirmDialog } from "../components/modal/ConfirmDialog";
import moment from "moment";
// import { ModalConfirmacion } from '../components/modal/ModalConfirmacion';

const tipos = [
  { value: "1", text: "Fiscal" },
  /* { value: "2", text: "Oficina" },
  { value: "3", text: "Sucursal" }, */
];

const gridSpan = (span = 2) => ({
  gridColumn: `span ${span}`,
});

export const CadenasPage = () => {
  const {
    bancos = [],
    bancoSearched,
    cardBlock,
    ciudades = [],
    colonias = [],
    contactos,
    direccionSearched,
    errorInputs,
    documentos = [],
    estados = [],
    form,
    giros = [],
    integraciones,
    retenciones,
    cfdis,
    formasPago,
    metodosPago,
    regimensFiscal,
    unidades,
    productoServicios,
    startLoadCuentasContablesCadena,
    clickAddContact,
    clickOpenListContact,
    clikcOpenViewPdf,
    loadIninit,
    loadFacturaTransferencia,
    loadBancos,
    loadProcesos,
    onBlur,
    onChange,
    onChangeInput,
    onSumbmit,
    isDataCorrect,
    setDireccion,
    setBanco,
    startLoadTiposLiquidacion,
    dispatch,
    onLoadDatosOperativos,
    startLoadDocumentos,
    setFormCadena,
    cleanForms,
    onChageDate,
  } = useFormPage({ saveIn: typesSave.CADENA });

  const [isOpenConfirmDialog, setIsOpenConfirmDialog] = useState(false)

  const handleOpenConfirmDialog = async () => {
    setIsOpenConfirmDialog(await isDataCorrect());
  }

  const handleCloseConfirmDialog = () => {
    setIsOpenConfirmDialog(false);
  }

  const handleSubmit = async () => {
    setIsOpenConfirmDialog(false);
    await onSumbmit()
  }

  useEffect(() => {
    loadIninit();
    return () => {
      cleanForms();
    };
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    setDireccion();
    // eslint-disable-next-line
  }, [direccionSearched.colonia]);

  useEffect(() => {
    setBanco();
    // eslint-disable-next-line
  }, [bancoSearched]);
  const showWarningMessage = () => {
    const diffDays = moment(form.nMesesGracia).endOf('month').diff(moment(form.dInicioOperaciones), 'days');
    let result = { type: 'error', message: '' }
    if (diffDays <= 0) {
      result.message = 'El periodo de gracia debe ser mayor a la fecha de inicio de operaciones.';
    } else if (diffDays < 30) {
      result.type = 'warning';
      result.message = `Solo se asignarán ${diffDays} dias de gracia.`;
    }
    return (<p style={{ fontSize: '12px', color: result.type == 'warning' ? "#E7B10A" : "#e53e3e" }}>{result.message}</p>)

  }
  return (
    <div style={{ width: "100%", margin: "0px auto" }}>
      <ConfirmDialog
        isOpenDialog={isOpenConfirmDialog}
        onCloseDialog={handleCloseConfirmDialog}
        onConfimDialog={handleSubmit}
        message="¿Seguro que deseas dar de alta al comisionista?"
        textButtonConfirm="Sí, estoy seguro"
      />
      <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={"es-mx"}>
        <AddButton onSubmit={() => handleOpenConfirmDialog()} />
        {/* <ModalConfirmacion /> */}
        {/* ========================== === DATOS GENERALES === ========================= */}
        <Card
          title="datos generales"
          icon={<AssignmentInd sx={{ color: "white", fontSize: "23px" }} />}
          openCollapse={true}
          block={cardBlock.datosGenerales}
          idElement="datosGenerales-cadenas"
        >
          <div className="cadenas__inputs-container cadenas__grid-2">
            <div style={{ ...gridSpan() }}>
              <TextInput
                id="razonSocial"
                name="razonSocial"
                label="Razón Social"
                value={form.razonSocial}
                onChangeRoot={onChangeInput}
                require
              />
            </div>

            <TextInput
              id="nombreComercial"
              name="nombreComercial"
              label="Nombre Comercial"
              value={form.nombreComercial}
              onChangeRoot={onChangeInput}
              require
            />

            <TextInput
              id="rfc"
              name="rfc"
              label="RFC"
              value={form.rfc.toUpperCase()}
              onChangeRoot={onChangeInput}
              require
              expresionRegular={regexRFC}
              caracteres="13"
              erroMsg="RFC inválido"
            />

            <TextInput
              id="regimenSocietario"
              name="regimenSocietario"
              label="Régimen de capital"
              value={form.regimenSocietario}
              onChangeRoot={onChangeInput}
              require
            />

            <TextInput
              id="sActividadEconomica"
              name="sActividadEconomica"
              label="Actividad económica"
              value={form.sActividadEconomica}
              onChangeRoot={onChangeInput}
              require
            />

            <TextInput
              id="sMail"
              name="sMail"
              label="Correo"
              value={form.sMail}
              onChangeRoot={onChangeInput}
              expresionRegular={regexEmail}
              erroMsg="Correo inválido"
              require
            />

            <TextInput
              id="sTelefono"
              name="sTelefono"
              label="Teléfono"
              value={form.sTelefono}
              onChangeRoot={onChangeInput}
              caracteres="10"
              expresionRegular={regexTelefono}
              erroMsg="Teléfono inválido"
              require
            />

            <SearchInput
              options={giros}
              value={form.giro}
              name="giro"
              label="Giro"
              getOptionLabel="text"
              getIndexLabel="value"
              onChange={onChange}
            />
          </div>
        </Card>
        {/* ========================== === DIRECCION === ========================= */}
        <Card
          title="dirección fiscal y contactos"
          icon={<LocationOn sx={{ color: "white", fontSize: "23px" }} />}
          // block={cardBlock.direcionContacto}
          openCollapse={cardBlock.direcionContacto ? false : true}
          loadEvent={async () => await loadProcesos()}
          idElement="direccion-cadenas"
        >
          <div className="cadenas__inputs-container">
            <TextInput
              id="calle"
              name="calle"
              label="Calle"
              value={form.calle}
              onChangeRoot={onChangeInput}
              require
            />

            <TextInput
              id="noExterior"
              name="noExterior"
              label="Número Exterior"
              value={form.noExterior}
              onChangeRoot={onChangeInput}
              expresionRegular={regexNums}
              erroMsg="Número inválido"
              caracteres="6"
              require
            />

            <TextInput
              id="noInterior"
              name="noInterior"
              label="Número Interior"
              value={form.noInterior}
              onChangeRoot={onChangeInput}
              expresionRegular={regexNumChart}
              caracteres="6"
            />

            <TextInput
              id="codigoPostal"
              name="codigoPostal"
              label="Código Postal"
              value={form.codigoPostal}
              onChangeRoot={onChangeInput}
              expresionRegular={regexNums}
              require
              caracteres="5"
            />

            <SelectInput
              id="estado"
              name="estado"
              onChange={onChange}
              label="Estado"
              data={estados}
              value={form.estado}
            />

            <SelectInput
              id="ciudad"
              name="ciudad"
              onChange={onChange}
              label="Municipio"
              data={ciudades}
              value={form.ciudad}
            />

            <SelectInput
              id="colonia"
              name="colonia"
              onChange={onChange}
              label="Colonia"
              data={colonias}
              value={form.colonia}
            />

            <SelectInput
              id="tipo"
              name="tipo"
              onChange={onChange}
              label="Tipo"
              data={tipos}
              value={form.tipo}
            />
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
              }}
            >
              <div style={{ width: "48%" }}>
                <Tooltip title="Contactos" placement="bottom" arrow>
                  <Button
                    variant="outlined"
                    size="large"
                    sx={buttonContactStyle}
                    onClick={clickOpenListContact}
                  >
                    <Person />
                    {contactos.length > 0 && (
                      <p className="cadenas__button-index">
                        {contactos.length <= 10 ? contactos.length : "10+"}
                      </p>
                    )}
                  </Button>
                </Tooltip>
              </div>
              <div style={{ width: "48%" }}>
                <Tooltip title="Nuevo Contacto" placement="bottom" arrow>
                  <Button
                    variant="outlined"
                    size="large"
                    sx={buttonContactStyle}
                    onClick={clickAddContact}
                  >
                    <PersonAddAlt1 />
                  </Button>
                </Tooltip>
              </div>
            </div>
          </div>
        </Card>
        {/* ========================== === DATOS BANCARIOS === ========================= */}
        <Card
          title="Datos Bancarios"
          icon={<AccountBalance sx={{ color: "white", fontSize: "23px" }} />}
          // block={cardBlock.datosBancarios}
          openCollapse={cardBlock.datosBancarios ? false : true}
          loadEvent={async () => await loadBancos()}
          idElement="datosBancarios-cadenas"
        >
          <div
            className="cadenas__inputs-container"
            style={{ gridTemplateColumns: "repeat(2, 1fr)" }}
          >
            <div style={{ ...gridSpan() }}>
              <TextInput
                id="beneficiario"
                name="beneficiario"
                label="Beneficiario"
                value={form.beneficiario}
                onChangeRoot={onChangeInput}
                require
              />
            </div>
            <TextField
              id="clabeInterBanc"
              name="clabeInterBanc"
              label="CLABE Interbancaria para depósitos de pagos"
              value={form.clabeInterBanc}
              onChange={onChange}
              variant="outlined"
              onBlur={onBlur}
              error={errorInputs.clabeInterBanc}
              helperText={
                errorInputs.clabeInterBanc ? "CLABE Interbancaria inválida" : ""
              }
            />

            <SelectInput
              id="banco"
              name="banco"
              onChange={onChange}
              label="Bancos"
              data={bancos}
              value={form.banco ? form.banco : ""}
            // require
            />

            <TextInput
              id="refNumerica"
              name="refNumerica"
              label="Referencia numérica"
              value={form.refNumerica}
              onChangeRoot={onChangeInput}
              type='number'
              error={errorInputs.refNumerica}
              require
              helperText={
                errorInputs.refNumerica ? "Longitud máxima: 10 carácteres" : ""
              }
            />

            <TextInput
              id="refAlfaNumerica"
              name="refAlfaNumerica"
              label="Referencia alfanumérica"
              value={form.refAlfaNumerica}
              onChangeRoot={onChangeInput}
              require
              error={errorInputs.refAlfaNumerica}
              helperText={
                errorInputs.refAlfaNumerica ? "Longitud máxima: 15 carácteres" : ""
              }
            />
          </div>
        </Card>
        {/* ========================== === DATOS OPERATIVOS === ========================= */}
        <Card
          title="datos operativos"
          icon={<Settings sx={{ color: "white", fontSize: "23px" }} />}
          // block={cardBlock.datosOperativos}
          openCollapse={cardBlock.datosOperativos ? false : true}
          loadEvent={async () => await onLoadDatosOperativos()}
          idElement="datosOperativos-cadenas"
        >
          <div className="cadenas__inputs-container">
            <SelectInput
              id="retencion"
              name="retencion"
              onChange={onChange}
              label="Retención"
              data={retenciones}
              value={form.retencion}
            />

            <TextInput
              id="montoRecepcion"
              name="montoRecepcion"
              label="Monto máximo de recepción"
              value={form.montoRecepcion}
              onChangeRoot={onChangeInput}
              type="number"
              startAdornment={
                <InputAdornment position="start">$</InputAdornment>
              }
              InputProps={{
                inputProps: { min: 0 }
              }}
            />

            <SelectInput
              id="ingragracion"
              name="ingragracion"
              onChange={onChange}
              label="Integración"
              data={integraciones}
              value={form.ingragracion}
            />

            <SelectInput
              id="ivaOperativo"
              name="ivaOperativo"
              onChange={(e) => onChange(e)}
              label="IVA"
              data={ivas}
              value={form.ivaOperativo}
            />

            {/* <TextInput
            id="costoTransferencia"
            name="costoTransferencia"
            label="Costo por transferencia"
            type="number"
            value={form.costoTransferencia}
            onChangeRoot={onChangeInput}
            require
          /> */}

            <IvaInput
              id="costoTransferencia"
              name="costoTransferencia"
              label="Costo por transferencia"
              value={form.costoTransferencia}
              onChangeRoot={onChangeInput}
              variant="outlined"
              fullWidth
              type="number"
              size="medium"
              ivaValue={
                form.ivaOperativo !== "" ? form.ivaOperativoVal * 100 : 0.16
              }
              startAdornment={
                <InputAdornment position="start">$</InputAdornment>
              }
            />

            <DesktopDatePicker
              disablePast
              label="Fecha de inicio de operaciones"
              inputFormat="YYYY-MM-DD"
              renderInput={(params) => <TextField {...params} />}
              value={form.dInicioOperaciones}
              onChange={(e) => onChageDate("dInicioOperaciones", e["$d"])}
            />
          </div>

          {/* Temportalmente no se aplica el periodo de gracia para comisionistas */}
          {/* <div className="cadenas__blue-switch" style={{ marginTop: "15px" }}>
            <BlueSwitch
              onChange={onChange}
              inputProps={{ "aria-label": "controlled" }}
              name="isActiveMGracia"
              checked={form.isActiveMGracia}
              value={form.isActiveMGracia}
            />
            <p>¿Se activará periodo de gracia?</p>
          </div>
          <Collapse in={form.isActiveMGracia}>
            <div
              className="cadenas__inputs-container"
              style={{ marginTop: "15px" }}
            >
              <div style={{ display: 'flex', flexDirection: 'column' }} >
                <DatePicker
                  disablePast
                  label="Periodo de gracia"
                  openTo="year"
                  views={["year", "month"]}
                  value={form.nMesesGracia}
                  onChange={(newValue) => onChageDate("nMesesGracia", newValue)}
                  renderInput={(params) => <TextField {...params} fullWidth />}
                />
                {
                  showWarningMessage()
                }
              </div>

            </div>
          </Collapse> */}
          {/* Temportalmente no se aplica la comisión escalonada para comisionistas */}
          {false &&
            <>
              <div className="cadenas__blue-switch" style={{ marginTop: "15px" }}>
                <BlueSwitch
                  onChange={onChange}
                  inputProps={{ "aria-label": "controlled" }}
                  name="isEscaloando"
                  checked={form.isEscaloando}
                  value={form.isEscaloando}
                />
                <p>¿Se pagará escalonado?</p>
              </div>
              <Collapse in={form.isEscaloando}>
                <EscalonadoForms saveIn={typesSave.CADENA} />
              </Collapse>
            </>
          }
        </Card>

        {/* ========================== === LIQUIDACION CASH IN === ========================= */}
        <Card
          title="Programación liquidación (CASH IN)"
          icon={<CreditScore sx={{ color: "white", fontSize: "23px" }} />}
          // block={cardBlock.liquidacion}
          openCollapse={cardBlock.liquidacion ? false : true}
          loadEvent={async () => await dispatch(startLoadTiposLiquidacion())}
          idElement="cashIn-cadenas"
        >
          <div className="cadenas__blue-switch" style={{ marginTop: "15px" }}>
            <p>¿Deshabilitar CASH IN?</p>
            <BlueSwitch
              onChange={onChange}
              inputProps={{ "aria-label": "controlled" }}
              name="isActiveCashIn"
              checked={form.isActiveCashIn}
              value={form.isActiveCashIn}
            />
            <p>{form.isActiveCashIn ? "Si" : "No"}</p>
          </div>
          <Collapse in={!form.isActiveCashIn}>
            <Liquidacion
              onCadenaChange={setFormCadena}
              cadena={form}
              tipoMovimiento="liquidacionCashIn"
              key="liquidacionCashIn"
              isCadena={true}
            />
          </Collapse>
        </Card>

        {/* ========================== === LIQUIDACION CASH OUT === ========================= */}
        <Card
          title="Programación liquidación (CASH OUT)"
          icon={<AddCard sx={{ color: "white", fontSize: "23px" }} />}
          // block={cardBlock.liquidacion}
          openCollapse={cardBlock.liquidacion ? false : true}
          loadEvent={async () => await dispatch(startLoadTiposLiquidacion())}
          idElement="cashOut-cadenas"
        >
          <div className="cadenas__blue-switch" style={{ marginTop: "15px" }}>
            <p>¿Deshabilitar CASH OUT?</p>
            <BlueSwitch
              onChange={onChange}
              inputProps={{ "aria-label": "controlled" }}
              name="isActiveCashOut"
              checked={form.isActiveCashOut}
              value={form.isActiveCashOut}
            />
            <p>{form.isActiveCashOut ? "Si" : "No"}</p>
          </div>
          <Collapse in={!form.isActiveCashOut}>
            <Liquidacion
              onCadenaChange={setFormCadena}
              cadena={form}
              tipoMovimiento="liquidacionCashOut"
              key="liquidacionCashOut"
              isCadena={true}
            />
          </Collapse>
        </Card>
        {/* ========================== === DOCUMENTOS === ========================= */}
        <Card
          title="Documentos"
          icon={<DriveFolderUpload sx={{ color: "white", fontSize: "23px" }} />}
          // block={cardBlock.documentos}
          openCollapse={cardBlock.documentos ? false : true}
          loadEvent={async () => await dispatch(startLoadDocumentos(0, 2))}
          idElement="documentos-cadenas"
        >
          {documentos.map((doc) => (
            <RenderDocumento
              key={doc.value}
              clickViewPdf={() => clikcOpenViewPdf(`${doc.value}`)}
              form={form}
              onChange={onChange}
              documento={doc.text}
              name={`${doc.value}`}
            />
          ))}
        </Card>
        {/* ========================== === FACTURA === ========================= */}
        <Card
          title={"Factura comisión por transferencia"}
          icon={<Receipt sx={{ color: "white", fontSize: "23px" }} />}
          openCollapse={false}
          loadEvent={async () => await loadFacturaTransferencia()}
          idElement="facturaTransferencia-cadenas"
        >
          <Grid container spacing={1} style={{ marginBottom: "10px" }}>
            <Grid item md={6} sm={6} xs={12}>
              <SelectInput
                id="ivaFac"
                name="ivaFac"
                onChange={(e) => onChange(e)}
                label="IVA"
                data={ivas}
                value={form.ivaFac}
              />
            </Grid>
            <Grid item md={6} sm={6} xs={12}>
              <SelectInput
                id="regimenFiscalFac"
                name="regimenFiscalFac"
                onChange={(e) => onChange(e)}
                label="Régimen fiscal"
                data={regimensFiscal}
                value={regimensFiscal.length > 0 ? form.regimenFiscalFac : ""}
              />
            </Grid>

            <Grid item md={4} sm={6} xs={12}>
              <SelectInput
                id="cfdiFac"
                name="cfdi"
                onChange={onChange}
                label="Uso del CFDI"
                data={cfdis}
                value={cfdis.length > 0 ? form.factura.cfdi : ""}
              />
            </Grid>
            <Grid item md={4} sm={6} xs={12}>
              <SelectInput
                id="formaPagoFac"
                name="formaPago"
                onChange={onChange}
                label="Forma de pago"
                data={formasPago}
                value={formasPago.length > 0 ? form.factura.formaPago : ""}
              />
            </Grid>
            <Grid item md={4} sm={12} xs={12}>
              <SelectInput
                id="metodoPagoFac"
                name="metodoPago"
                onChange={onChange}
                label="Método de pago"
                data={metodosPago}
                value={metodosPago.length > 0 ? form.factura.metodoPago : ""}
              />
            </Grid>

            <Grid item md={6} sm={6} xs={12}>
              <SearchInput
                options={productoServicios}
                value={form.factura.claveProducto}
                name="claveProducto"
                label="Clave del producto"
                getOptionLabel="text"
                getIndexLabel="value"
                onChange={onChange}
              />
            </Grid>
            <Grid item md={6} sm={6} xs={12}>
              <SearchInput
                options={unidades}
                value={form.factura.claveUnidad}
                name="claveUnidad"
                label="Clave de unidad"
                getOptionLabel="text"
                getIndexLabel="value"
                onChange={onChange}
              />
            </Grid>

            <Grid item md={6} sm={6} xs={12}>
              <SelectInput
                id="periocidadFac"
                name="periocidad"
                onChange={onChange}
                label="Periodicidad de facturación"
                data={periodicidades}
                value={form.factura.periocidad}
                disabled
              />
            </Grid>

            <Grid item md={6} sm={6} xs={12}>
              <TextInput
                id="diasLiquidacionFac"
                name="diasLiquidacion"
                label="Días para liquidar factura"
                value={form.factura.diasLiquidacion}
                onChangeRoot={onChangeInput}
                type="number"
                require
              />
            </Grid>
          </Grid>
          <EmailsFacturaCadena reference={"cadena"} />
        </Card>
        {/* ========================== === CUENTA CONTABL === ========================= */}
        {/* <CuentaContable
          idElement="cuentaContable-cadenas"
          loadCuentas={() => dispatch(startLoadCuentasContablesCadena())}
        /> */}
        {/* ===================== === CUENTA CONTABLE CASH - IN === ==================== */}
        {!form.isActiveCashIn && (
          <CuentaContableCash
            idElement="cuentaContableCashIn-cadena"
            title="Cuentas contables (CASH IN)"
            referencia="cashIn"
            retencion={form.retencion}
            nFlujo={1}
            loadCuentas={() => dispatch(startLoadCuentasContablesCadena())}
            key={"cashInCuentaContable-1"}
          />
        )}
        {/* ===================== === CUENTA CONTABLE CASH - OUT === ==================== */}
        {!form.isActiveCashOut && (
          <CuentaContableCash
            idElement="cuentaContableCashOut-cadena"
            title="Cuentas contables (CASH OUT)"
            referencia="cashOut"
            retencion={form.retencion}
            nFlujo={0}
            loadCuentas={() => dispatch(startLoadCuentasContablesCadena())}
            key={"cashOutCuentaContable-1"}
          />
        )}
      </LocalizationProvider>
    </div>
  );
};

const RenderButtonPDF = ({ openPdf, value }) => {
  const onClick = async () => {
    await openPdf();
  };
  return (
    <>
      {value && value !== "" && (
        <div className="flex-row center">
          <Button
            variant="outlined"
            component="label"
            onClick={onClick}
            sx={buttonViewPDF}
          >
            <RemoveRedEye />
          </Button>
        </div>
      )}
    </>
  );
};

const RenderDocumento = ({ documento, onChange, name, form, clickViewPdf }) => {
  return (
    <div className="flex-row space-between">
      <div
        className="cadenas__inputs-container cadenas__inputs-container-doc"
        style={{ width: "100%", marginBottom: "10px" }}
      >
        <Button
          variant="contained"
          component="label"
          sx={buttonFileStyle}
          className="card__btn-file"
        >
          {documento}
          <input
            hidden
            accept="application/pdf"
            multiple
            type="file"
            name={name}
            onChange={onChange}
          />
        </Button>
        <Typography variant="p" component="p" sx={{ textAlign: "center" }}>
          {form.documentos[name] &&
            form.documentos[name] !== "" &&
            form.documentos[name].name}
        </Typography>
        <RenderButtonPDF openPdf={clickViewPdf} value={form.documentos[name]} />
      </div>
    </div>
  );
};
