import { API } from "aws-amplify";
import { closeOrdenPago, loading, openToast } from "../ui/uiSlice";
import { setDataTable, setSelectedRows } from "./reportsSlice";
import moment from "moment";

export const getOperacionesData = (form) => {
  return async (dispatch) => {
    const params = {
      headers: {},
      response: false,
      queryStringParameters: {
        nIdCadena: form.nIdCadena,
        nIdEmisor: form.nIdEmisor,
        sClaveProducto: form.sClaveProducto ? form.sClaveProducto : 0,
        dFecInicial: form.dFecInicial,
        dFecFinal: form.dFecFinal,
        nDetalle: form.nDetalle ? 1 : 0,
        nIdFlujoImporte: form.productType,
        nTipoAgrupacion: form.nTipoAgrupacion
      },
    };

    try {
      dispatch(loading(true));
      const resp = await API.get("reportes", "/reportes/operaciones", params);
      console.log({
        ...resp.data,
        details: form,
      });
      dispatch(
        setDataTable({
          ...resp.data,
          details: form,
        })
      );
    } catch (error) {
      console.log("ocurrio un error " + error);
    } finally {
      dispatch(loading(false));
    }
  };
};

export const getOrdenesPagoData = (form) => {
  return async (dispatch) => {
    const params = {
      headers: {},
      response: false,
      queryStringParameters: {
        type: form.type,
        nIdCadena: form.nIdCadena,
        nIdEmisor: form.nIdEmisor,
        nIdEstatusPago: form.nIdEstatusPago,
        nIdTipoPago: form.nIdTipoPago,
        dFecInicial: form.dFecInicial,
        dFecFinal: form.dFecFinal,
      },
    };

    try {
      dispatch(loading(true));
      const resp = await API.get("reportes", "/reportes/ordenesPago", params);
      dispatch(
        setDataTable({
          ...resp.data,
          details: form,
        })
      );

    } catch (error) {
      console.log("ocurrio un error " + error);
    } finally {
      dispatch(loading(false));
    }
  };
};

export const getEscalonadoData = (form) => {
  return async (dispatch) => {
    const params = {
      headers: {},
      response: false,
      queryStringParameters: {
        nIdEmisor: form.nIdEmisor,
        dMonth: form.dMonth,
        dYear: form.dYear,
      },
    };

    try {
      dispatch(loading(true));
      const resp = await API.get("reportes", "/reporte/escalonado", params);
      console.log({
        ...resp,
        details: form,
      });
      if (resp.error?.length) {
        dispatch(
          setDataTable({
            rows: [],
            columns: [],
          })
        );
      } else {
        dispatch(
          setDataTable({
            ...resp.data,
            details: form,
          })
        );
      }
    } catch (error) {
      console.log("ocurrio un error " + error);
    } finally {
      dispatch(loading(false));
    }
  };
};

export const get24HorasData = (form) => {
  return async (dispatch) => {
    const params = {
      headers: {},
      response: false,
      queryStringParameters: {
        nIdEmisor: form.nIdEmisor,
        dFecInicial: form.dFecInicial,
        dFecFinal: form.dFecFinal,
      },
    };

    try {
      dispatch(loading(true));
      const resp = await API.get("reportes", "/reportes/corte24Horas", params);
      if (resp.error?.length) {
        dispatch(
          setDataTable({
            rows: [],
            columns: [],
          })
        );
      } else {
        dispatch(
          setDataTable({
            ...resp.data,
            details: form,
          })
        );
      }
    } catch (error) {
      console.log("ocurrio un error " + error);
    } finally {
      dispatch(loading(false));
    }
  };
};

export const getCortesData = (form) => {
  return async (dispatch) => {
    const params = {
      headers: {},
      response: false,
      queryStringParameters: {
        type: form.type,
        nIdEmisor: form.nIdEmisor,
        nIdCadena: form.nIdCadena,
        dFecInicial: form.dFecInicial,
        dFecFinal: form.dFecFinal,
      },
    };
    try {
      dispatch(loading(true));
      const resp = await API.get("reportes", "/reportes/cortes", params);
      
      if (resp.error?.length) {
        dispatch(
          setDataTable({
            rows: [],
            columns: [],
          })
        );
      } else {
        dispatch(
          setDataTable({
            ...resp.data,
            details: form,
          })
        );
      }
    } catch (error) {
      console.log("ocurrio un error " + error);
    } finally {
      dispatch(loading(false));
    }
  };
};

export const getTransaccionesData = (form) => {
  return async (dispatch) => {
    const params = {
      headers: {},
      response: false,
      queryStringParameters: {
        sReferencia: form.sReferencia,
        sTicket: form.sTicket,
        sAutorizacion: form.sAutorizacion,
        dFecInicial: form.nFiltradoFechas ? form.dFecInicial : "",
        dFecFinal: form.nFiltradoFechas ? form.dFecFinal : "",
      },
    };

    try {
      dispatch(loading(true));
      const resp = await API.get("reportes", "/reportes/referencia", params);
      console.log({
        ...resp.data,
        details: form,
      });
      dispatch(
        setDataTable({
          ...resp.data,
          details: form,
        })
      );
    } catch (error) {
      console.log("ocurrio un error " + error);
    } finally {
      dispatch(loading(false));
    }
  };
};

export const getPagosCobrosData = (form) => {
  return async (dispatch) => {
    const params = {
      headers: {},
      response: false,
      queryStringParameters: form,
    };

    try {
      dispatch(loading(true));
      const resp = await API.get("reportes", "/reportes/pagos-cobros", params);
      console.log({
        ...resp,
        details: form,
      });
      if (resp.error?.length) {
        dispatch(
          setDataTable({
            rows: [],
            columns: [],
          })
        );
      } else {
        dispatch(
          setDataTable({
            ...resp.data,
            details: form,
          })
        );
      }
    } catch (error) {
      console.log("ocurrio un error " + error);
    } finally {
      dispatch(loading(false));
    }
  };
};

export const getReporteOperacionesData = (form) => {
  return async (dispatch) => {
    const params = {
      headers: {},
      response: false,
      queryStringParameters: form,
    };

    try {
      dispatch(loading(true));
      const resp = await API.get("reportes", "/reportes/conciliacion-operaciones", params);
      console.log({
        ...resp,
        details: form,
      });
      if (resp.error?.length) {
        dispatch(
          setDataTable({
            rows: [],
            columns: [],
          })
        );
      } else {
        dispatch(
          setDataTable({
            ...resp.data,
            details: form,
          })
        );
      }
    } catch (error) {
      console.log("ocurrio un error " + error);
    } finally {
      dispatch(loading(false));
    }
  };
};

export const getReporteGeneralData = (form) => {
  return async (dispatch) => {
    const params = {
      headers: {},
      response: false,
      queryStringParameters: form,
    };

    try {
      dispatch(loading(true));
      const resp = await API.get("reportes", "/reportes/conciliacion-general", params);
      console.log({
        ...resp,
        details: form,
      });
      if (resp.error?.length) {
        dispatch(
          setDataTable({
            rows: [],
            columns: [],
          })
        );
      } else {
        dispatch(
          setDataTable({
            ...resp.data,
            details: form,
          })
        );
      }
    } catch (error) {
      console.log("ocurrio un error " + error);
    } finally {
      dispatch(loading(false));
    }
  };
};

export const updateOrdenesPagoEstatus = (form, selectedRows, type, dataTable) => {
  return async (dispatch) => {
    const params = {
      headers: {},
      response: false,
      body: {
        actor: dataTable.details.type,
        sIdsOrdenes: selectedRows,
        nIdEstatus: type === 'pagado' ? 3 : 4,
        dFecha: form.dFechaPago,
        sDescripcion: form.sMotivoCancelacion,
        //sCodTransaccion: form.sCodTransaccion
      },
    };
    let flag = true;
    try {
      dispatch(loading(true));
      const resp = await API.put("reportes", "/reportes/ordenesPago", params);
      console.log('La respuesta es...', {
        ...resp,
        details: form,
      });
      let nError = 0
      let sMensaje = '';

      [nError, sMensaje] = JSON.parse(resp.sMensaje).map((message, key) => {
        return [nError * message.nCodigo, sMensaje + message.sMensaje]
      })
      if (resp.nCodigo !== 0) {
        dispatch(openToast({ error: true, message: resp.sMensaje }));
      } else {
        dispatch(closeOrdenPago())
        dispatch(openToast({ message: 'Orden de pago actualizada exitosamente' }));
        dispatch(setSelectedRows([]))
      }
    } catch (error) {
      flag = false;
      console.log("ocurrio un error " + error);
    } finally {
      dispatch(loading(false));
      return flag
    }
  };
};

export const postLayoutOrdenesPago = (dFecha) => {
  return async (dispatch) => {
    const params = {
      headers: {},
      response: false,
      body: { dFecha },
    };
    let response = { nCodigo: 1, sMensaje: "Ocurrió un error inesperado. Intente más tarde." };
    try {
      dispatch(loading(true));
      const resp = await API.post("reportes", "/reportes/layout-ordenes-pago", params);
      console.log(resp)

      response = { ...response, ...resp }
    } catch (error) {
      console.log("ocurrio un error " + error);
    } finally {
      dispatch(loading(false));
      return response
    }
  };
};


export const getComisionesIntegradorData = (form) => {
  return async (dispatch) => {
    const params = {
      headers: {},
      response: false,
      queryStringParameters: {
        nIdIntegrador: form.nIdIntegrador,
        dMonth: form.dMonth,
        dYear: form.dYear,
      },
    };

    try {
      dispatch(loading(true));
      const resp = await API.get("reportes", "/reportes/comisiones-integrador", params);
      console.log({
        ...resp.data,
        details: form,
      });
      if (resp.error?.length) {
        dispatch(
          setDataTable({
            rows: [],
            columns: [],
          })
        );
      } else {
        dispatch(
          setDataTable({
            ...resp.data,
            details: form,
          })
        );
      }
    } catch (error) {
      console.log("ocurrio un error " + error);
    } finally {
      dispatch(loading(false));
    }
  };
};

export const getContactosReporte = (form) => {
  return async (dispatch) => {
    const params = {
      headers: {},
      response: false,
      queryStringParameters: {
        nIdIntegrador: form.nIdIntegrador,
        dMonth: form.dMonth,
        dYear: form.dYear,
      },
    };
    let response = {
      sContactos: [],
      sUsuarios: [],
      sCorreosEnviados: []
    }
    try {
      dispatch(loading(true));
      const resp = await API.get("reportes", "/reportes/envio-reporte-comision", params);

      response = {
        sContactos: JSON.parse(resp.sContactos),
        sUsuarios: JSON.parse(resp.sUsuarios),
        sCorreosEnviados: JSON.parse(resp.sCorreosEnviados)
      }
    } catch (error) {
      console.log("ocurrio un error " + error);
    } finally {
      dispatch(loading(false));
      return response
    }
  };
};

export const enviarReporte = (form) => {
  return async (dispatch) => {
    const params = {
      headers: {},
      response: false,
      body: form,
    };
    let response = {ok: false, msg: "Error al enviar el reporte. Intente más tarde."}
    try {
      dispatch(loading(true));
      const resp = await API.post("reportes", "/reportes/enviar", params);

      response = {
        ok: resp.nCodigo === 0,
        msg: resp.sMensaje
      }
    } catch (error) {
      console.log("ocurrio un error " + error);
    } finally {
      dispatch(loading(false));
      return response
    }
  };
};

export const getReporteGeolocalizacionData = (form) => {
  return async (dispatch) => {
    const dFecInicial = moment().year(form.dYear).month(form.dMonth ? form.dMonth - 1 : 0).date(1)
    const dFecFinal = dFecInicial.clone().endOf(form.dMonth ? 'month' : 'year')
    const params = {
      headers: {},
      response: false,
      queryStringParameters: {
        nIdEmisor: form.nIdEmisor,
        dFecInicial: dFecInicial.format("YYYY-MM-DD"),
        dFecFinal: dFecFinal.format("YYYY-MM-DD"),
      },
    };
    let dataTable = {}
    try {
      dispatch(loading(true));
      const resp = await API.get("reportes", "/reportes/geolocalizacion", params);
      dataTable = resp.data;
      if (resp.error?.length) {
        dispatch(
          setDataTable({
            rows: [],
            columns: [],
          })
        );
      } else {
        dispatch(
          setDataTable({
            ...resp.data,
            details: form,
          })
        );
      }
    } catch (error) {
      console.log("ocurrio un error " + error);
    } finally {
      dispatch(loading(false));
    }
    return dataTable
  };
};

export const getReporteSucursalesNoRegistradasData = (form) => {
  return async (dispatch) => {
    const params = {
      headers: {},
      response: false,
      queryStringParameters: {
        nIdCadena: form.nIdCadena,
      },
    };
    let dataTable = {}
    try {
      dispatch(loading(true));
      const resp = await API.get("reportes", "/reportes/sucursales-no-registradas", params);
      dataTable = resp.data;
      if (resp.error?.length) {
        dispatch(
          setDataTable({
            rows: [],
            columns: [],
          })
        );
      } else {
        dispatch(
          setDataTable({
            ...resp.data,
            details: form,
          })
        );
      }
    } catch (error) {
      console.log("ocurrio un error " + error);
    } finally {
      dispatch(loading(false));
    }
    return dataTable
  };
};


export const getOperacionReferencia = (form) => {
  return async (dispatch) => {
    const params = {
      headers: {},
      response: false,
      queryStringParameters: form,
    };
    let data = []
    try {
      dispatch(loading(true));
      const resp = await API.get("reportes", "/reportes/referencia", params);
      data = resp;
    } catch (error) {
      console.log("ocurrio un error " + error);
    } finally {
      dispatch(loading(false));
    }
    return data
  };
};
