import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  activeSection: ''
};

export const menuSlice = createSlice({
  name: 'menu',
  initialState,
  reducers: {
    setActiveSection: (state, action) => {
      state.activeSection = action.payload;
    }
  }
});

export const { openDrawer, closeDrawer, setActiveSection } = menuSlice.actions;
