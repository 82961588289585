import { ModalBase } from './modal/ModalBase';
import { useModal } from '../hooks/useModal';
import { useSelector } from 'react-redux';
import { useAuthPage } from '../hooks/useAuthPage';
import { useEffect, useState } from 'react';


import { Box, Button, Checkbox, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControlLabel, Grid, Switch, Tooltip, Typography } from '@mui/material';
import { SelectInput } from './form/SelectInput';
import { TextInput } from './form/TextInput';
import '../styles/cadenas.css';
import { CompareArrows, Edit, Help, Hub, Visibility } from '@mui/icons-material';
import { BlueSwitch } from '../helpers/stylesMaterial';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';

import { styled } from '@mui/material/styles';
import { DashboardTable } from './table/DashboardTable';
import { CamposConciliacionForm } from './CamposConciliacionForm';
import CustomizedTabs from './CustomizedTabs';

const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  '&:not(:last-child)': {
    borderBottom: 0,
  },
  '&:before': {
    display: 'none',
  },
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    {...props}
  />
))(({ theme }) => ({
  border: "1px solid rgba(0, 0, 0, .125)",
  backgroundColor:
    theme.palette.mode === 'dark'
      ? 'rgba(255, 255, 255, .05)'
      : 'rgba(0, 0, 0, .03)',
  flexDirection: 'row-reverse',
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(90deg)',
  },
  '& .MuiAccordionSummary-content': {
    marginLeft: theme.spacing(1),
  },
  '&.Mui-disabled': {
    backgroundColor: "#f1f1f1"
  }
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  paddingLeft: "40px",
  border: "1px solid rgba(0, 0, 0, .125)"
}));

export const ConciliacionModal = (props) => {
  const {
    form,
    campos,
    onChange,
    onChangeCampos,
    handleSubmit,
    backUp
  } = props;
  const { isOpenConciliacion, closeModalConciliacion, openModalConexionesSFTP } = useModal();
  const { isLoading } = useSelector((state) => state.ui);
  const {
    layoutConciliacion,
    formatosArchivos
  } = useSelector((state) => state.catalogo);
  const [type, setType] = useState("view")
  const [isOpenDetailsNombre, setIsOpenDetailsNombre] = useState(false)
  const [isOpenFormatosFecha, setIsOpenFormatosFecha] = useState(false)
  const [selectedTab, setSelectedTab] = useState("datos")
  const [isAllowdEdition, setIsAllowdEdition] = useState(false)


  const { setAllowComponent } = useAuthPage();

  useEffect(() => {
    if (isOpenConciliacion) {
      setType("view")
      setSelectedTab("datos")
    }
  }, [isOpenConciliacion]);

  useEffect(() => {
    setIsAllowdEdition(setAllowComponent("guardarConciliacion"))
  }, []);

  const handleSelectFormatos = (id) => {
    let format = formatosArchivos.find((format) => format.nIdFormatoArchivo === id)
    if (format) {
      onChange({
        nIdFormatoArchivo: id,
        bRegistrosSeparados: format.bRegistrosSeparados,
        nIdTipoLayoutConciliacion: format.bRegistrosSeparados ? 1 : 0,
        bRango: 0
      })
    }
  }

  const handleSelectLayout = (id) => {
    let layout = layoutConciliacion.find((layout) => layout.nIdTipoLayoutConciliacion === id)
    if (layout) {
      onChange({ nIdTipoLayoutConciliacion: id, bRango: layout.bRango })
    }
  }

  const openConexionesSFTP = () => {
    closeModalConciliacion()
    openModalConexionesSFTP({ [form.tipoConciliacion === "cadena" ? "nIdCadena" : "nIdEmisor"]: form.nIdActor })
  }

  const showStep = () => {
    switch (selectedTab) {
      case "datos":
        return <>
          <div className="cadenas__blue-switch" style={{ marginTop: "0" }}>
            <BlueSwitch
              onChange={(e) => onChange({ bConciliacionPersonalizada: e.target.checked ? 1 : 0 })}
              inputProps={{ 'aria-label': 'controlled' }}
              name="bConciliacionPersonalizada"
              checked={form.bConciliacionPersonalizada}
              value={form.bConciliacionPersonalizada}
              disabled={type === "view"}
            />
            <p>Habilitar layout de conciliación personalizada</p>
          </div>
          {form.bConciliacionPersonalizada ? <>
            <Grid container spacing={1}>

              <Grid item xs={12} sm={4}>
                <SelectInput
                  id="nIdFormatoArchivo"
                  name="nIdFormatoArchivo"
                  onChange={(e) => handleSelectFormatos(e.target.value)}
                  label="Formato del archivo"
                  data={formatosArchivos}
                  value={form.nIdFormatoArchivo}
                  disabled={type === 'view'}
                  text="sFormato"
                  idOption={"nIdFormatoArchivo"}
                />
              </Grid>
              {
                !form.bRegistrosSeparados ? <>
                  <Grid item xs={12} sm={4}>
                    <SelectInput
                      id="nIdTipoLayoutConciliacion"
                      name="nIdTipoLayoutConciliacion"
                      onChange={(e) => handleSelectLayout(e.target.value)}
                      label="Tipo de layout"
                      data={layoutConciliacion}
                      value={form.nIdTipoLayoutConciliacion}
                      disabled={type === 'view'}
                      text="sNombre"
                      idOption={"nIdTipoLayoutConciliacion"}
                    />
                  </Grid>
                  {
                    !form.bRango ?
                      <Grid item xs={12} sm={4}>
                        <TextInput
                          disabled={type === "view"}
                          id="sCaracter"
                          name="sCaracter"
                          label="Cáracter separador"
                          value={`${form.sCaracter}`}
                          onChangeRoot={onChange}
                          fullWidth
                          require
                        />
                      </Grid>
                      : <></>
                  }
                </> : <></>
              }

              <Grid item xs={12}>
                <div className="cadenas__blue-switch" style={{ marginTop: "0" }}>
                  <BlueSwitch
                    onChange={(e) => onChange({ bConDecimales: e.target.checked ? 1 : 0 })}
                    inputProps={{ 'aria-label': 'controlled' }}
                    name="bConDecimales"
                    checked={form.bConDecimales}
                    value={form.bConDecimales}
                    disabled={type === "view"}
                  />
                  <p>¿Los importes contienen decimales? <HelperTooltip text="Seleccione si los importes del archivo incluyen punto decimal, de lo contrario se entiende que los importes serán números enteros donde los últimos dos dígitos son los decimales." /></p>
                </div>
                <div className="cadenas__blue-switch" style={{ marginTop: "0" }}>
                  <BlueSwitch
                    onChange={(e) => onChange({ bArchivosSeparados: e.target.checked ? 1 : 0 })}
                    inputProps={{ 'aria-label': 'controlled' }}
                    name="bArchivosSeparados"
                    checked={form.bArchivosSeparados}
                    value={form.bArchivosSeparados}
                    disabled={type === "view"}
                  />
                  <p>¿Serán archivos separados por productos? <HelperTooltip text="Seleccione si las operaciones se enviarán en distintos archivos separados por productos." /></p>
                </div>
                <div className="cadenas__blue-switch" style={{ marginTop: "0" }}>
                  <BlueSwitch
                    onChange={(e) => onChange({ bNombrePersonalizado: e.target.checked ? 1 : 0 })}
                    inputProps={{ 'aria-label': 'controlled' }}
                    name="bNombrePersonalizado"
                    checked={form.bNombrePersonalizado}
                    value={form.bNombrePersonalizado}
                    disabled={type === "view"}
                  />
                  <p>¿El nombre del archivo es personalizado? <HelperTooltip text="Seleccione si el nombre del archivo es diferente al formato proporcionado por SofiPay." /></p>
                </div>
              </Grid>
              {form.bNombrePersonalizado ?
                <Grid item xs={12}>
                  <Grid container spacing={2} alignContent={"center"} justifyContent={"center"}>
                    <Grid item xs={9}>
                      <TextInput
                        disabled={type === "view"}
                        id="sNombreArchivo"
                        name="sNombreArchivo"
                        label="Nombre del archivo"
                        value={`${form.sNombreArchivo}`}
                        onChangeRoot={onChange}
                        fullWidth
                        require
                      />
                    </Grid>
                    <Grid item xs={3}>
                      <Button
                        fullWidth
                        onClick={() => setIsOpenDetailsNombre(true)}
                      >Ejemplo de nombre personalizado</Button>
                    </Grid>
                  </Grid>
                </Grid>
                : <></>}

            </Grid>
          </> : <></>}
        </>
      case "cuerpo":
        return <Grid container>
          <Grid item xs={12}>
            <Typography variant="h6" color={"primary"}>Cuerpo del archivo</Typography>
          </Grid>
          <Grid item xs={12}>
            <CamposConciliacionForm
              campos={campos}
              onChange={onChangeCampos}
              nTipo={1}
              bRango={form.bRango && !form.bRegistrosSeparados}
              isEdit={type === "edit"}
              openModalFecha={() => setIsOpenFormatosFecha(true)}
            />
          </Grid>
        </Grid>
      case "encabezado":
        return <Grid container>
          <Grid item xs={12}>
            <Typography variant="h6" color={"primary"}>Encabezado del archivo</Typography>
          </Grid>
          <Grid item xs={12}>
            <Grid item xs={12}>
              <div className="cadenas__blue-switch" style={{ marginTop: "0" }}>
                <BlueSwitch
                  onChange={(e) => onChange({ bIncluyeEncabezado: e.target.checked ? 1 : 0 })}
                  inputProps={{ 'aria-label': 'controlled' }}
                  name="bIncluyeEncabezado"
                  checked={form.bIncluyeEncabezado}
                  value={form.bIncluyeEncabezado}
                  disabled={type === "view"}
                />
                <p>¿El archivo cuenta con línea de encabezado? <HelperTooltip text="Seleccione si la primera línea del archivo es el encabezado." /></p>
              </div>
              {form.bIncluyeEncabezado ?
                <div className="cadenas__blue-switch" style={{ marginTop: "0" }}>
                  <BlueSwitch
                    onChange={(e) => onChange({ bIgnorarEncabezado: e.target.checked ? 1 : 0 })}
                    inputProps={{ 'aria-label': 'controlled' }}
                    name="bIgnorarEncabezado"
                    checked={form.bIgnorarEncabezado}
                    value={form.bIgnorarEncabezado}
                    disabled={type === "view"}
                  />
                  <p>Ignorar encabezado <HelperTooltip text="Seleccione si no se requiere validar la información del encabezado." /></p>
                </div>
                : <></>}

            </Grid>
          </Grid>
          {form.bIncluyeEncabezado && !form.bIgnorarEncabezado ?
            <Grid item xs={12}>
              <CamposConciliacionForm
                campos={campos}
                onChange={onChangeCampos}
                nTipo={2}
                bRango={form.bRango && !form.bRegistrosSeparados}
                isEdit={type === "edit"}
                openModalFecha={() => setIsOpenFormatosFecha(true)}
              />
            </Grid>
            : <></>}
        </Grid>
      case "footer":
        return <Grid container>
          <Grid item xs={12}>
            <Typography variant="h6" color={"primary"}>Footer del archivo</Typography>
          </Grid>
          <Grid item xs={12}>
            <Grid item xs={12}>
              <div className="cadenas__blue-switch" style={{ marginTop: "0" }}>
                <BlueSwitch
                  onChange={(e) => onChange({ bIncluyeFooter: e.target.checked ? 1 : 0 })}
                  inputProps={{ 'aria-label': 'controlled' }}
                  name="bIncluyeFooter"
                  checked={form.bIncluyeFooter}
                  value={form.bIncluyeFooter}
                  disabled={type === "view"}
                />
                <p>¿El archivo cuenta con línea de footer? <HelperTooltip text="Seleccione si la última línea del archivo es el footer." /></p>
              </div>
              {form.bIncluyeFooter ?
                <div className="cadenas__blue-switch" style={{ marginTop: "0" }}>
                  <BlueSwitch
                    onChange={(e) => onChange({ bIgnorarFooter: e.target.checked ? 1 : 0 })}
                    inputProps={{ 'aria-label': 'controlled' }}
                    name="bIgnorarEncabezado"
                    checked={form.bIgnorarFooter}
                    value={form.bIgnorarFooter}
                    disabled={type === "view"}
                  />
                  <p>Ignorar footer  <HelperTooltip text="Seleccione si no se requiere validar la información del footer." /></p>
                </div>
                : <></>}

            </Grid>
          </Grid>
          {form.bIncluyeFooter && !form.bIgnorarFooter ?
            <Grid item xs={12}>
              <CamposConciliacionForm
                campos={campos}
                onChange={onChangeCampos}
                nTipo={3}
                bRango={form.bRango && !form.bRegistrosSeparados}
                isEdit={type === "edit"}
                openModalFecha={() => setIsOpenFormatosFecha(true)}
              />
            </Grid>
            : <></>}
        </Grid>
      default:
        break;
    }
  }

  return (<>

    <ModalBase open={isOpenConciliacion} onClose={closeModalConciliacion} style={{ width: '1000px' }}>
      <>
        <TitleModal title={form?.sNombreComercial} extraButton={type === "view" ?
          <Grid container>
            {setAllowComponent("guardarConciliacion") ?
              <Button
                variant="contained"
                onClick={() => setType("edit")}
                startIcon={<Edit />}
                sx={{ marginRight: '10px' }}
              >
                Editar
              </Button>
              : <></>}

            {setAllowComponent("modalConexionesSFTP") ?
              <Button
                disabled={false}
                variant="contained"
                onClick={openConexionesSFTP}
                startIcon={<Hub />}
                sx={{ marginRight: '10px' }}
              >
                Ver conexiones SFTP
              </Button>
              : <></>}

          </Grid>
          : <></>} />
        <Grid container>
          <Grid item xs={12} container paddingLeft={1}>

            <CustomizedTabs
              tabs={[
                { label: "Datos generales", value: "datos" },
                ...(form.bConciliacionPersonalizada ? [
                  { label: "Registros", value: "cuerpo" },
                  { label: "Encabezado", value: "encabezado" },
                  { label: "Footer", value: "footer" },
                ] : [])

              ]}
              value={selectedTab}
              onChange={(selectedTab) => setSelectedTab(selectedTab)}
            />
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Grid container padding={2} sx={{ background: "#f9f9f9", borderRadius: 2, minHeight: form.bConciliacionPersonalizada ? "400px" : "auto" }} alignItems={"flex-start"}>
            {showStep()}
          </Grid>
        </Grid>
        <Box
          sx={{
            textAlign: 'end',
            marginTop: '15px'
          }}
        >

          {type === "edit" ?
            <>
              <Button
                onClick={() => {
                  onChange(backUp.form)
                  onChangeCampos(backUp.campos)
                  setType("view")
                }}
                sx={{ marginRight: '10px', display: '' }}
              >
                cancelar
              </Button>
              <Button
                variant="contained"
                color="primary"
                onClick={handleSubmit}
              >
                Guardar
              </Button>
            </>

            : <><Button
              onClick={() => closeModalConciliacion()}
              sx={{ marginRight: '10px', display: '' }}
            >
              CERRAR
            </Button></>}


        </Box>
      </>
    </ModalBase >
    <Dialog
      open={isOpenDetailsNombre}
      onClose={() => setIsOpenDetailsNombre(false)}
      aria-labelledby="alert-dialog-title2"
      aria-describedby="alert-dialog-description2"
    >
      <DialogContent>
        <DialogContentText id="alert-dialog-description">

          <TitleModal title={"Detalles de nombre personalizado"} />
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <Typography variant="h6" color={"primary"}>Ejemplo:</Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="b" textAlign={"center"} style={{ textAlign: "center", width: "100%", background: "#f4f4f4", display: "inline-block", padding: 5, paddingTop: 15, paddingBottom: 15, borderRadius: 3 }}>CEN<b style={{ background: "#cecece", display: "inline-block", padding: 3, borderRadius: 4, margin: 1 }}>$&#123;sClaveProductoSocio&#125;</b><b style={{ background: "#cecece", display: "inline-block", padding: 3, borderRadius: 4, margin: 1 }}>$&#123;dFecConciliacion:YYMMDD&#125;</b>%</Typography>
            </Grid>
            <Grid item xs={12}>
              <ul>
                <li><b>"CEN"</b> - Texto fijo</li>
                <li><b>$&#123;sClaveProductoSocio&#125;</b> - Campo dinámico para la clave del producto del socio comercial.</li>
                <li><b>$&#123;dFecConciliacion:YYMMDD&#125;</b> - Campo dinámico para la fecha de conciliación, al ser de tipo fecha se puede especificar el formato, en este ejemplo es "YYMMDD". <Button onClick={() => {
                  setIsOpenDetailsNombre(false)
                  setIsOpenFormatosFecha(true)
                }}>Ver formatos de fecha</Button></li>
                <li><b>Símbolo "%"</b> - El símbolo <b>%</b> al final indica que encontrará cualquier archivo cuyo nombre comience con ese patrón. Sí el símbolo se encuentra el principio indica que intentará encontrar cualquier archivo cuyo nombre termine con ese patrón.</li>
              </ul>
            </Grid>
          </Grid>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => setIsOpenDetailsNombre(false)}>CERRAR</Button>
      </DialogActions>
    </Dialog>
    <Dialog
      open={isOpenFormatosFecha}
      onClose={() => setIsOpenFormatosFecha(false)}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogContent>
        <DialogContentText id="alert-dialog-description">

          <TitleModal title={"Formatos de fecha disponibles"} />
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <DashboardTable
                rows={[
                  { sFormato: "YYYY-MM-DD", sEjemploFecha: "2025-05-03" },
                  { sFormato: "DD-MM-YYYY", sEjemploFecha: "03-05-2025" },
                  { sFormato: "YYYY/MM/DD", sEjemploFecha: "2025/05/03" },
                  { sFormato: "YYYYMMDD", sEjemploFecha: "20250503" },
                  { sFormato: "YYMMDD", sEjemploFecha: "250503" },
                  { sFormato: "hh:mm:ss", sEjemploFecha: "15:09:23" },
                  { sFormato: "hhmmss", sEjemploFecha: "150923" },
                  { sFormato: "hh:mm", sEjemploFecha: "15:09" },
                  { sFormato: "YYYY-MM-DD hh:mm:ss", sEjemploFecha: "2025-05-03 15:09:23" },
                ]}
                disableFooter
                tableName={"formatosFecha"}
                columns={[
                  {
                    headerName: "Formato de fecha",
                    field: "sFormato",
                    type: "text",
                  },
                  {
                    headerName: "Ejemplo para 3 de mayo 2025 15:09:23",
                    field: "sEjemploFecha",
                    type: "text",
                  },
                ]}
              />
            </Grid>
          </Grid>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => setIsOpenFormatosFecha(false)}>CERRAR</Button>
      </DialogActions>
    </Dialog>
  </>
  );
};

const TitleModal = ({ title, typeOperation, extraButton }) => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        width: '100%',
        marginBottom: '10px',
        justifyContent: 'space-between', mb: 4
      }}
    >
      <div style={{ display: 'flex', justifyContent: "space-between" }}>

        <CompareArrows fontSize={'large'} sx={{ color: '#00CD68', fontSize: '45px' }} />
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <Typography
            variant="p"
            sx={{
              marginLeft: '15px',
              fontSize: '14px',
              marginBottom: '4px'
            }}
          >
            {'Configuración de conciliación'}
          </Typography>
          <Typography
            variant="p"
            sx={{
              marginLeft: '15px',
              color: '#00609C',
              fontSize: '20px'
            }}
          >
            {title}
          </Typography>
        </div>
      </div>
      <div>

        {extraButton}
      </div>
    </Box>
  );
};

const HelperTooltip = ({ text }) => {
  return <Tooltip title={text} placement="right" arrow>
    <Help fontSize="14" color="neutral" />
  </Tooltip>
}