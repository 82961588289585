import "../../styles/dashboardTable.css";
import React, { useEffect } from "react";
import moment from "moment";
import { Grid, Skeleton, Tooltip } from "@mui/material";

export const DashboardTable = (props) => {
  const {
    rows = [],
    columns = [],
    totalType = "addition",
    tableName = "table",
    parentColumns = [],
    disableFooter = false
  } = props;

  const getColumnTotal = (key) => {
    var total = 0;
    if (totalType === "addition") {
      rows.map((row) => (total += parseFloat(row[key])));
    } else {
      let x1 = rows[0][key]?.value ? rows[0][key]?.value : rows[0][key];
      let x2 = rows[1][key]?.value ? rows[1][key]?.value : rows[1][key];
      total = x1 - x2;
    }
    return total;
  };

  const printTd = (value, type) => {
    switch (type) {
      case "price":
        return value?.toLocaleString("en-US", {
          style: "currency",
          currency: "USD",
        });
      case "date":
        return moment(value).format("YYYY-MM-DD");
      case "time":
        return moment(value, "HH:mm:ss").format("HH:mm:ss");

      default:
        return value;
    }
  };

  const tableComponent = () => {
    if (rows.length) {
      return (
        <div
          className="table-container "
          style={{ width: "100%", overflowX: "auto" }}
        >
          <table className={`dashboard-table ${disableFooter ? "normal-footer": ""}`}>
            <thead>
              {
                parentColumns.length ?
                  <tr>
                    {parentColumns.map((column, key) => (
                      <th key={tableName + "parent-header-" + key} style={column.style ?? {}} colSpan={column.colSpan}>
                        {column.headerName}
                      </th>
                    ))}
                  </tr> : <></>
              }
              <tr>
                {columns.map((column, key) => (
                  <th key={tableName + "header-" + key + column.headerName} style={column.style ?? {}}>
                    {column.headerName}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {rows.length ? (
                rows.map((row, key) => (
                  <tr key={tableName + "row-" + key}>
                    {columns.map((column) => {
                      const tooltip = row[column.field]?.tooltip
                      return (tooltip ?
                        <td
                          key={
                            tableName +
                            key +
                            "-" +
                            column.field +
                            row[column.field].value
                          }
                        >
                          <Tooltip arrow title={tooltip}>
                            <Grid>
                              {printTd(row[column.field].value, column.type)} <span style={{ width: 8, height: 8, borderRadius: "50%", background: "red", display: "inline-block" }}></span>
                            </Grid>
                          </Tooltip>
                        </td>
                        :
                        <td
                          key={
                            tableName +
                            key +
                            "-" +
                            column.field +
                            row[column.field]
                          }
                          style={{ textAlign: column.type == 'price' ? 'right' : (column.type == 'text' ? 'left' : 'center'), ...(column.style ?? {}) }}
                        >
                          {column.renderFunction ? column.renderFunction(row) : printTd(row[column.field], column.type)}
                        </td>
                      )
                    })}
                  </tr>
                ))
              ) : (
                <tr key={tableName + "no-data"}>
                  <td
                    colSpan={columns.length}
                    style={{
                      textAlign: "center",
                    }}
                    key={tableName + "no-data-td"}
                  >
                    No se encontraron resultados
                  </td>
                </tr>
              )}
              {!disableFooter && <tr key={tableName + "footer"}>
                {columns.map((column, key) =>
                  column.type === "number" || column.type === "price" ? (
                    <td key={tableName + "footer-" + column.field + key}
                      style={{ textAlign: column.type == 'price' ? 'right' : 'center' }}
                    >
                      {printTd(getColumnTotal(column.field), column.type)}
                    </td>
                  ) : (
                    <td key={tableName + "footer-" + key}
                      style={{ textAlign: 'left' }}
                    >
                      {totalType === "addition" ? "Total" : "Diferencias"}
                    </td>
                  )
                )}
              </tr>}

            </tbody>
          </table>
        </div>
      );
    } else {
      return (
        <div style={{ padding: 10 }}>
          <Skeleton height={40} style={{ marginBottom: 5 }} />
          <Skeleton height={30} style={{ marginBottom: 5 }} />
          <Skeleton height={30} style={{ marginBottom: 5 }} />
        </div>
      );
    }
  };

  return tableComponent();
};
