import { API } from 'aws-amplify';
import { useDispatch } from 'react-redux';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { openConciliacion } from '../slices/ui/uiSlice';
import {
  setConfigId,
  setConfigTipo
} from '../slices/configConciliacion/configConciliacionSlice';
import { useModal } from './useModal';
import { useAuthPage } from './useAuthPage';
//import { startConfigConexion } from '../slices/configConciliacion/conciliacionThunk';
import { loading } from '../slices/ui/uiSlice';
import { startLoadCadenas, startLoadCfgGeneral, startLoadEmisores } from '../slices/catalogo/catalgoThunk';
import { putEnvioOperaciones } from '../slices/cadena/cadenaThunk';
import { putEnvioOperacionesEmisores } from '../slices/emisor/emisorThunk';

let initialForm = {
  nHabilitarEnvio: 0,
  nTipoEnvio: 0,
  nSFTPSofipay: 0,
  sHostEnvio: '',
  nPortEnvio: '',
  sPasswordEnvio: '',
  sFolderEnvio: '',
  sUserEnvio: '',
};


export const useModalEnvioOperaciones = () => {
  const { cadenas, emisores } = useSelector((state) => state.catalogo);
  const { isOpenEnvioOperaciones } = useSelector(
    (state) => state.ui
  );
  const { setOpenToast, closeModalEnvioOperaciones } = useModal();
  const dispatch = useDispatch();

  const [form, setForm] = useState(initialForm);
  const [selectedEntity, setSelectedEntity] = useState({})
  const [type, setType] = useState("view")
  const [cgfSFTP, setCgfSFTP] = useState({})

  useEffect(() => {
    if (isOpenEnvioOperaciones.open) {
      searchConciliacion()
      if (Object.keys(cgfSFTP).length === 0) {
        getCfgSFTP()
      }
    } else {
      setForm(initialForm)
    }
  }, [isOpenEnvioOperaciones.open])

  const getCfgSFTP = async () => {
    const nProceso = 5;
    const cfg = await dispatch(startLoadCfgGeneral(nProceso))
    const oCfg = cfg.reduce((a, row) => ({ ...a, [row.sKey]: row.sValor }), {})
    console.log(oCfg)
    setCgfSFTP(oCfg)
  }

  const handleEdit = (flag) => {
    setType(flag ? "edit" : "view")
  }

  const openModalConciliacion = (id, tipo) => {
    dispatch(setConfigId(id));
    dispatch(setConfigTipo(tipo));
    dispatch(openConciliacion());
  };
  const searchConciliacion = async () => {
    switch (isOpenEnvioOperaciones.type) {
      case 'cadena':
        const dataCadena = cadenas.rows.find(
          (cadena) => cadena.nIdCadena === isOpenEnvioOperaciones.nIdActor
        );
        console.log(dataCadena)
        setSelectedEntity(dataCadena)
        handleEdit(dataCadena.nIdCfgEnvioOperaciones === 0)
        setForm({ ...dataCadena, nHabilitarEnvio: dataCadena.nIdCfgEnvioOperaciones > 0 });
        break;
      case 'emisor':
        const dataEmisor = emisores.rows.find(
          (emisor) => emisor.nIdEmisor === isOpenEnvioOperaciones.nIdActor
        );
        setSelectedEntity(dataEmisor)
        handleEdit(dataEmisor.nIdCfgEnvioOperaciones === 0)
        setForm({ ...dataEmisor, nHabilitarEnvio: dataEmisor.nIdCfgEnvioOperaciones > 0 });
        break;
      default:
        break;
    }
  };

  const onChange = async (e) => {

    const switchInputsName = ["nHabilitarEnvio", "nSFTPSofipay"]


    setForm({
      ...form, ...Object.keys(e).includes("target") ?
        { [e.target.name]: switchInputsName.includes(e.target.name) ? e.target.checked : e.target.value } :
        e,
      nTipoEnvio: e.target.name === "nHabilitarEnvio" ? 0 : form.nTipoEnvio
    })
  };

  const onSubmit = async () => {
    console.log(isOpenEnvioOperaciones.type, form.nIdEmisor, form)
    const innerForm = {
      nIdCadena: isOpenEnvioOperaciones.type === 'cadena' ? form.nIdCadena : 0,
      nIdEmisor: isOpenEnvioOperaciones.type === 'emisor' ? form.nIdEmisor : 0,
      nHabilitarEnvio: form.nHabilitarEnvio ? 1 : 0,
      nTipoEnvio: form.nTipoEnvio,
      nSFTPSofipay: form.nSFTPSofipay ? 1 : 0,
      sHostEnvio: form.sHostEnvio,
      nPortEnvio: form.nPortEnvio,
      sPasswordEnvio: form.sPasswordEnvio,
      sFolderEnvio: form.sFolderEnvio,
      sUserEnvio: form.sUserEnvio,
      nIdEstatus: form.nIdCfgEnvioOperaciones === 0 || (form.nIdCfgEnvioOperaciones !== 0 && form.nHabilitarEnvio) ? 1 : 0
    }
    switch (isOpenEnvioOperaciones.type) {
      case 'cadena':
        const resp = await dispatch(putEnvioOperaciones(innerForm))
        setOpenToast(!resp.ok, resp.msg)
        if (resp.ok) {
          closeModalEnvioOperaciones()
          await dispatch(startLoadCadenas());
        }
        break;
      case 'emisor':
        const res = await dispatch(putEnvioOperacionesEmisores(innerForm))
        setOpenToast(!res.ok, res.msg)
        if (res.ok) {
          closeModalEnvioOperaciones()
          await dispatch(startLoadEmisores());
        }
        break;
      default:
        break;
    }
  };

  const getContactos = () => {
    let contactos = []
    if (selectedEntity.sNombreComercial) {
      contactos = JSON.parse(selectedEntity?.oContactos)?.filter((contacto) => {
        const procesos = JSON.parse(contacto?.sProcesos).map((proceso) => proceso.nIdProceso)
        return procesos.includes(5)
      })
    }

    return contactos
  }


  return {
    openModalConciliacion,
    form,
    setForm,
    searchConciliacion,
    onChange,
    onSubmit,
    selectedEntity,
    handleEdit,
    type,
    getContactos,
    cgfSFTP
  };
};
