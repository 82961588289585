import { Alert, AlertTitle, Button } from '@mui/material';
import { Box } from '@mui/system';
import { Worker } from '@react-pdf-viewer/core';
import { Viewer } from '@react-pdf-viewer/core';
//import { defaultLayoutPlugin } from '@react-pdf-viewer/default-layout';

import { useModal } from '../../hooks/useModal';
import { ModalBase } from './ModalBase';
import { saveAs } from 'file-saver';

import '@react-pdf-viewer/core/lib/styles/index.css';
import { useEffect, useState } from 'react';

export const ModalViewPdf = () => {
  const { isOpenViewPdf, closeModalViewPdf } = useModal();
  const [fileHasError, setFileHasError] = useState(false)

  useEffect(() => {
    setFileHasError(false)
  }, [isOpenViewPdf])

  const renderError = () => {
    setFileHasError(true)
    return(
      <Alert severity="error" sx={{mt: 2, mb: 2}}>
        <AlertTitle>Error al cargar el archivo</AlertTitle>
        Hubo un error tratando de abrir el archivo, intente más tarde.
      </Alert>
    )
  }
  
  return (
    <ModalBase
      open={isOpenViewPdf.open}
      title="PDF"
      style={{ width: '950px' }}
    >
      <Box
        sx={{
          width: '100%',
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
        }}
      >
        <div
          style={{
            maxHeight: '650px',
            display: 'block',
            overflowY: 'auto',
            whiteSpace: 'nonwrap',
          }}
        >
          {isOpenViewPdf.file64 !== '' && (
            <>
              <Worker
                workerUrl="https://unpkg.com/pdfjs-dist@3.11.174/build/pdf.worker.min.js"
              >
                <Viewer fileUrl={isOpenViewPdf.file64}  renderError={renderError}/>
              </Worker>
            </>
          )}

        </div>

        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            width: '100%',
            justifyContent: 'end',
            padding: "10px 0"
          }}
        >
          <Button onClick={closeModalViewPdf} sx={{ color: '#00609C' }}>
            cerrar
          </Button>
          {!fileHasError && 
            <Button onClick={()=>saveAs(isOpenViewPdf.file64, isOpenViewPdf.fileName)} variant={"contained"}>
              Descargar
            </Button>
          }
        </div>
      </Box>
    </ModalBase>
  );
};
