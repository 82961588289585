import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  comisionistaChart:  {
    categories: [],
    series    : []
  },
  EFRChart:  {
    categories: [],
    series    : []
  },
  operacionesTable: [],
  archivosConciliacionTable: [],
  procesosConciliacionTable: [],
  depositosTable: [],
  pagosTable: [],
};

export const dashboardSlice = createSlice({
  name: 'dashboard',
  initialState,
  reducers: {
    setComisionistaChart: (state, action) => {
      state.comisionistaChart = {...action.payload};
    },
    setEFRChart: (state, action) => {
      state.EFRChart = {...action.payload};
    },
    setOperacionesTable: (state, action) => {
      state.operacionesTable = [...action.payload];
    },
    setArchivosConciliacionTable: (state, action) => {
      state.archivosConciliacionTable = [...action.payload];
    },
    setProcesosConciliacionTable: (state, action) => {
      state.procesosConciliacionTable = [...action.payload];
    },
    setDepositosTable: (state, action) => {
      state.depositosTable = [...action.payload];
    },
    setPagosTable: (state, action) => {
      state.pagosTable = [...action.payload];
    },
  }
});

export const {
  setComisionistaChart,
  setEFRChart,
  setOperacionesTable,
  setArchivosConciliacionTable,
  setProcesosConciliacionTable,
  setDepositosTable,
  setPagosTable
} = dashboardSlice.actions;
