export const routes = {
  index: 'index',
  login: 'login',
  register: 'register',
  Cadenas: 'altas-cadenas',
  Sucursales: 'sucursales',
  VerCadenas: 'ver-cadenas',
  EFR: 'altas-efr',
  VerEFR: 'ver-efr',
  Dashboard: 'dashboard',
  Reporte: 'reporte/:type',
  facturaCadena: 'factura-cadena',
  pagos: 'pagos',
  Polizas: 'polizas',
  registrosBancarios: 'registrosBancarios',
  facturas: 'facturas',
  Conciliacion: 'conciliacion/:type',
  facturasValidacion: 'facturas-validacion',
  usuarios: 'usuarios/ver',
  usuariosExternos: 'usuarios-externos/ver',
  catalogos: 'catalogos/ver',
  suspensiones: 'suspensiones/ver',
  perfilesUsuarios: 'perfiles-usuario/ver',
  monitorERP: '/monitoreo/erp',
};
