export const ivas = [
  {
    value: '1',   
    text: '16%',
    valDouble: 0.16,
  },
  {
    value: '2',   
    text: '8%',
    valDouble: 0.08
  }
]