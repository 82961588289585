import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { isValidEscCantidadForm } from "../../helpers/forms-validate";
import { useModal } from "../../hooks/useModal";
import { cleanEscCantidadForm, deleteEscCantidadForm, setEscCantidadForm, setListEscCantidadForm, updateEscCantidadForm } from "../../slices/cadena/cadenaSlice";

import { v4 as uuidv4 } from 'uuid';
import { escCantidadForm } from "../../helpers/forms";
import { TextInput } from "../form/TextInput";
import { Button, Grid, IconButton, InputAdornment, Tooltip } from "@mui/material";
import { Add, Cancel, Delete, Edit, Save, TextDecrease } from "@mui/icons-material";
import { buttonContactStyle, buttonContactStyleError } from "../../helpers/stylesMaterial";
import { IvaInput } from "../form/IvaInput";
import { useAuthPage } from "../../hooks/useAuthPage";

export const EscCantidadEnhancedForm = ({ escalonado, id, isFirst, onlyView, index, disabledButtons, arrayEscalonados = [], isLast, isComisionesVigentes, disableAddEscalon }) => {
  const { escCantidadForms = [] } = useSelector((state) => state.cadena);
  const [form, setForm] = useState({ ...escalonado, id, isFirst });
  const { ivaOperativoSelect } = useSelector((state) => state.cadena);

  const dispatch = useDispatch();
  const { setOpenToast } = useModal();

  useEffect(() => {
    setForm(escalonado)
  }, [escalonado])


  const onChangeInput = (value) => {
    dispatch(updateEscCantidadForm({ ...form, ...value }));
  };

  const validateEscalon = () => {

    if (form.cantidadMaxima === '')
      return { ok: false, error: 'Ingrese la cantidad máxima.' }

    if (parseInt(form.cantidadMaxima) === 0 && index !== arrayEscalonados.length - 1) {
      return { ok: false, error: 'La cantidad máxima debe de ser mayor a la cantidad mínima.' }
    }

    if ((parseInt(form.cantidadMaxima) < parseInt(form.cantidadMinimo)) && parseInt(form.cantidadMaxima) !== 0)
      return { ok: false, error: 'La cantidad máxima debe de ser mayor a la cantidad mínima.' }

    if (!parseInt(form.comisionMontoFijo))
      return { ok: false, error: 'Ingrese una comisión válida.' }

    if (index !== arrayEscalonados.length - 1) {
      const nextCantidadMaxima = parseInt(arrayEscalonados[index + 1].cantidadMaxima)
      if (parseInt(form.cantidadMaxima) >= nextCantidadMaxima && nextCantidadMaxima != 0)
        return { ok: false, error: `El rango del escalón se superpone al siguiente escalón. Ingrese una cantidad máxima que sea menor a ${nextCantidadMaxima}.` }
    }

    return { ok: true, error: "" }
  }

  const insertEscalon = (position = "prev") => {
    const idNuevoEscalon = uuidv4()
    let newEscalon = {
      ...escCantidadForm,
      disableInputs: true,
      isEditing: true,
      isNew: true,
      id: idNuevoEscalon
    }

    let escalonados = [...escCantidadForms.filter(esc => !esc.isEditing).map(esc => ({ ...esc }))]
    let innerIndex = escalonados.findIndex(esc => esc.id === form.id)
    //console.log(innerIndex)

    innerIndex = position === "prev" ? innerIndex - 1 : innerIndex + 1
    console.log(innerIndex)
    if (innerIndex < 0) {
      //se asigna al nuevo la cantidad minima de 1
      newEscalon.cantidadMinimo = 1
      escalonados[0].cantidadMinimo = "..."
    } else if (innerIndex >= escalonados.length) {
      newEscalon.cantidadMinimo = parseInt(escalonados[innerIndex - 1].cantidadMaxima) + 1
    } else {
      //se le asigna al nuevo la cantidad maxima del anterior escalon
      if (position === "prev") {
        newEscalon.cantidadMinimo = parseInt(escalonados[innerIndex].cantidadMaxima) + 1
        escalonados[innerIndex+1].cantidadMinimo = "..."
      } else {

        newEscalon.cantidadMinimo = parseInt(escalonados[innerIndex-1].cantidadMaxima) + 1
        escalonados[innerIndex].cantidadMinimo = "..."
      }
    }

    escalonados.splice(position === "prev" ? innerIndex + 1 : innerIndex, 0, newEscalon);

    dispatch(setListEscCantidadForm(escalonados));
  }

  const handleEditForm = (enable = false) => {
    if (enable) {
      dispatch(updateEscCantidadForm({ ...form, isEditing: enable }));
    } else {
      if (form.isNew) {
        onDeleteForm()
        return
      }
      dispatch(updateEscCantidadForm({ ...form, cantidadMaxima: form.nNumOpeMax, comisionMontoFijo: form.nCosto, isEditing: enable }));
    }
  }

  const onSave = () => {
    const { ok, error } = validateEscalon();
    if (!ok) {
      setOpenToast(true, error);
      return;
    }

    if (index !== arrayEscalonados.length - 1) {
      const nextEscalon = arrayEscalonados[index + 1]
      dispatch(updateEscCantidadForm({ ...nextEscalon, cantidadMinimo: parseInt(form.cantidadMaxima) + 1 }));
    }

    dispatch(updateEscCantidadForm({
      ...form,
      nNumOpeMin: form.cantidadMinimo,
      nNumOpeMax: form.cantidadMaxima,
      nCosto: form.comisionMontoFijo,
      isNew: false,
      isEditing: false
    }));
  }

  const onDeleteForm = () => {
    let escalonados = [...escCantidadForms.map(esc => ({ ...esc }))]
    if (isFirst) {
      //se asigna al que sigue la cantidad minima de 1
      escalonados[index + 1].cantidadMinimo = 1
    } else if (isLast) {
      //se le asigna al anterior la cantidad maxima de 0
      escalonados[index - 1].cantidadMaxima = 0
    } else {
      //se le asinga al siguiente la cantidad minima que tenia el escalon
      escalonados[index + 1].cantidadMinimo = parseInt(escalonados[index - 1].cantidadMaxima) + 1
    }

    escalonados.splice(index, 1)

    dispatch(setListEscCantidadForm(escalonados));
  }


  return (
    <tr
      style={{ height: 70 }}
      className={onlyView || disabledButtons || escalonado.isEditing || isComisionesVigentes || disableAddEscalon ? (escalonado.isEditing ? "editing" : "") : (!isLast ? "enabled-add-step" : "enabled-add-step disabled-add-next-step")}>
      <td>
        {index + 1}.
      </td>
      <td>
        <TextInput
          id="cantidadMinimo"
          name="cantidadMinimo"
          value={form.cantidadMinimo}
          onChangeRoot={onChangeInput}
          fullWidth
          disabled
        />
      </td>
      <td>
        <TextInput
          id="cantidadMaxima"
          name="cantidadMaxima"
          type="number"
          value={form.cantidadMaxima}
          onChangeRoot={onChangeInput}
          fullWidth
          disabled={onlyView || (!escalonado.isEditing && escalonado.disableInputs) || isComisionesVigentes}
        />
      </td>
      <td colSpan={3}>
        <IvaInput
          id="comisionMontoFijo"
          name="comisionMontoFijo"
          label="Comisión"
          value={form.comisionMontoFijo}
          onChangeRoot={onChangeInput}
          variant="outlined"
          fullWidth
          type="number"
          size="medium"
          disableLabels
          ivaValue={ivaOperativoSelect * 100}
          startAdornment={<InputAdornment position="start">$</InputAdornment>}
          disabled={onlyView || (!isComisionesVigentes && !escalonado.isEditing && escalonado.disableInputs)}
        />

      </td>
      <td>
        {!onlyView && !isComisionesVigentes &&
          (escalonado.isEditing ?
            <Grid container style={{ width: "100%" }}>
              <Grid item xs={6}>
                <Tooltip title="Cancelar" placement="bottom" arrow>
                  <IconButton
                    variant="outlined"
                    size="large"
                    onClick={() => handleEditForm(false)}
                    disabled={disabledButtons}
                  >
                    <Cancel />
                  </IconButton>
                </Tooltip>
              </Grid>
              <Grid item xs={6}>
                <Tooltip title="Guardar" placement="bottom" arrow>
                  <IconButton
                    variant="outlined"
                    size="large"
                    onClick={onSave}
                    disabled={disabledButtons}
                    color="primary"
                    sx={{ ml: 1 }}
                  >
                    <Save />
                  </IconButton>
                </Tooltip>
              </Grid>
            </Grid>
            : <>
              <Grid container style={{ width: "100%" }}>
                <Grid item xs={6}>
                  <Tooltip title="Editar" placement="bottom" arrow>
                    <IconButton
                      variant="outlined"
                      size="large"
                      onClick={() => handleEditForm(true)}
                      disabled={disabledButtons}
                    >
                      <Edit />
                    </IconButton>
                  </Tooltip>
                </Grid>
                {arrayEscalonados.length > 1 &&
                  <Grid item xs={6}>
                    <Tooltip title="Eliminar" placement="bottom" arrow>
                      <IconButton
                        variant="outlined"
                        size="large"
                        onClick={onDeleteForm}
                        disabled={disabledButtons}
                        sx={{ ml: 1 }}
                      >
                        <Delete />
                      </IconButton>
                    </Tooltip>
                  </Grid>
                }
              </Grid>
            </>)}
      </td>
      <td style={{ position: "relative" }}>
        {!onlyView && !disabledButtons && !escalonado.isEditing && !isComisionesVigentes && !disableAddEscalon && <>
          <IconButton
            variant="outlined"
            size="small"
            // type="submit"
            onClick={() => insertEscalon("prev")}
            className='addEscalonBtn'
            sx={{ background: "#00609c", color: "white", position: "absolute", top: "-16px", left: "0px", width: 30, height: 30 }}
          >
            <Add />
          </IconButton>
          {!onlyView && !disabledButtons && !escalonado.isEditing && !isLast && !isComisionesVigentes && !disableAddEscalon &&
            <IconButton
              variant="outlined"
              size="small"
              // type="submit"
              onClick={() => insertEscalon("next")}
              className='addEscalonBtn'
              sx={{ background: "#00609c", color: "white", position: "absolute", bottom: "-16px", left: "0px", width: 30, height: 30 }}
            >
              <Add />
            </IconButton>
          }
        </>}
      </td>
    </tr>
  );
};
