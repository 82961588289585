import { useEffect, useState } from "react";
import { useMenu } from "../hooks/useMenu";
import { useAuthPage } from "../hooks/useAuthPage";
import { Badge, Button, Card, Grid, Typography } from "@mui/material";
import { createTheme, ThemeProvider } from "@mui/material";
import { esES } from "@mui/material/locale";
import EnhancedTable from "../components/table/EnhancedTable";
import { AssignmentTurnedIn } from '@mui/icons-material';
import { useSelector } from "react-redux";
import { ModalImportInvoices } from "../components/modal/ModalImportInvoices";
import { useValidacionFacturasPage } from "../hooks/useValidacionFacturasPage";
import { SearchInput } from "../components/form/SearchInput";
import { FilterAltOutlined } from '@mui/icons-material';

import CompareArrowsIcon from '@mui/icons-material/CompareArrows';
import { ModaFacturasOrdenes } from "../components/modal/ModalFacturasOrdenes";

const iconTheme = createTheme(
  {
    status: {
      danger: "#e53e3e",
    },
    palette: {
      primary: {
        main: "#00609C",
        dark: "#053e85",
      },
      neutral: {
        main: "#00CD68",
        light: "#053e85",
        dark: "#053e85",
        contrastText: "#fff",
      },
    },
  },
  esES
);

export const FacturasValidacionPage = () => {
  const {
    onChange,
    form,
    registrosBancariosTable,
    onChangeInput,
    handleOpenImport,
    closeImportModal,
    loadInInit,
    importRegistrosBancarios,
    openModalRegistrosCortesFromImport,
    dataTable,
    emisores,
    cadenas,
    getFacturasData,
    getFilters,
    openXMLFile,
    facturasBadge,
    handleOpenFacturasOrdenes
  } = useValidacionFacturasPage();

  const { isTableLoading, isOpenImport } = useSelector((state) => state.ui);
  const { setNameSection } = useMenu();
  const { setNewPage, isAllowed, setAllowComponent } = useAuthPage();
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    if (!loaded) {
      loadInInit();
      setNewPage("registrosBancarios");
      setNameSection("registrosBancarios");
      setLoaded(true);
    }
  }, []);

  if (isAllowed) {
    return (
      <ThemeProvider theme={iconTheme}>
        <ModalImportInvoices />
        <ModaFacturasOrdenes />
        <EnhancedTable
          table={dataTable}
          rowId={'nId'}
          loading={isTableLoading}
          getFilters={getFilters}
          download={true}
          onDownloadFunction={(id, row) => openXMLFile(row.sRutaArchivo)}
          extraButtons={
            <>
              {setAllowComponent("asociarFacturas") &&
                <Badge badgeContent={facturasBadge} color="neutral"
                  sx={{ mr: 2 }}>
                  <Button
                    variant="contained"
                    startIcon={<CompareArrowsIcon />}
                    onClick={handleOpenFacturasOrdenes}
                  >
                    Asociar facturas a ordenes de pago
                  </Button>
                </Badge>
              }
              {setAllowComponent("verificacionFacturas") &&
                <Button
                  variant="contained"
                  startIcon={<AssignmentTurnedIn />}
                  onClick={handleOpenImport}
                >
                  Verificar facturas
                </Button>
              }
            </>
          }
          extraFilters={(resetPagination) => (
            <Grid container justifyContent={"space-between"} alignItems={"center"} sx={{ padding: 2 }}>

              <Grid item sm={1} xs={12}>
                Filtros:
              </Grid>
              <Grid item sm={9} xs={12}>
                <Grid container spacing={2} alignItems={"center"}>
                  <Grid item xs={12} sm={3}>
                    <SearchInput
                      options={[
                        {
                          id: 0,
                          name: "Todos",
                        },
                        {
                          id: 1,
                          name: "EFR",
                        },
                        {
                          id: 2,
                          name: "Comisionista",
                        },
                      ]}
                      value={form.nActor}
                      name={"nActor"}
                      label={"Entidad"}
                      getOptionLabel={"name"}
                      getIndexLabel={"id"}
                      onChange={onChange}
                    />
                  </Grid>
                  {form.nActor !== 0 &&
                    <Grid item xs={12} sm={4}>
                      {
                        form.nActor === 1 || form.nActor === '1' ?
                          <SearchInput
                            options={[{ nIdEmisor: 0, sNombreComercial: 'Todos' }, ...emisores?.rows]}
                            value={form.nIdEmisor}
                            name={"nIdEmisor"}
                            label={form.nActor ? "Entidad financiera" : ""}
                            getOptionLabel={"sNombreComercial"}
                            getIndexLabel={"nIdEmisor"}
                            onChange={onChange}
                          />
                          :
                          <SearchInput
                            options={[{ nIdCadena: 0, sNombreComercial: 'Todos' }, ...cadenas?.rows]}
                            value={form.nIdCadena}
                            name={"nIdCadena"}
                            label={form.nActor ? "Comisionista" : ""}
                            getOptionLabel={"sNombreComercial"}
                            getIndexLabel={"nIdCadena"}
                            onChange={onChange}
                          />
                      }

                    </Grid>
                  }
                </Grid>
              </Grid>
              <Grid item sm={2} xs={12} style={{ textAlign: "right" }}>
                <Button
                  variant="contained"
                  startIcon={<FilterAltOutlined />}
                  onClick={() => {
                    getFacturasData()
                    resetPagination()
                  }}
                >
                  Filtrar
                </Button>
              </Grid>
            </Grid>)
          }
        />
      </ThemeProvider>
    );
  } else {
    setNameSection("");
    return (
      <Card>
        <Typography variant="h3">Contenido no disponible</Typography>
      </Card>
    );
  }
};
