import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  rutas:  {
    columns: [],
    rows   : []
  },
  comisionistas: {
    columns: [],
    rows   : []
  },
  comisionesEscalonadas: {
    sBitacora: [],
    sProximas: [],
    sVigente: []
  }
};

export const emisorSlice = createSlice({
  name: 'emisor',
  initialState,
  reducers: {
    setRutas: (state, action) => {
      state.rutas = {...action.payload};
    },
    setComisionistas: (state, action) => {
      state.comisionistas = {...action.payload};
    },
    setComisionesEscalonadas: (state, action) => {
      state.comisionesEscalonadas = {...action.payload};
    },
  }
});

export const {
  setRutas,
  setComisionistas,
  setComisionesEscalonadas,
} = emisorSlice.actions;
