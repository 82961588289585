import { Button, Grid } from "@mui/material";
import { Box } from "@mui/system";
import { useModal } from "../../hooks/useModal";
import { ModalBase } from "./ModalBase";
import { useEffect, useState, useCallback } from "react";
import { read, utils } from "xlsx";

export default function ModalViewTxt() {
  const { isOpenViewDoc, closeModalViewDoc } = useModal();
  const [txtData, setTxtData] = useState("");
  const [fileType, setFileType] = useState("");
  const [tableData, setTableData] = useState([]);

  const fetchFile = useCallback(async () => {
    const extension = isOpenViewDoc.fileName.split(".").pop().toLowerCase();
    setFileType(extension);

    const response = await fetch(isOpenViewDoc.path);

    if (extension === "xlsx") {
      const arrayBuffer = await response.arrayBuffer();
      const workbook = read(arrayBuffer, { type: "array" });
      const sheetName = workbook.SheetNames[0];
      const worksheet = workbook.Sheets[sheetName];
      const jsonData = utils.sheet_to_json(worksheet, { header: 1 });
      setTableData(jsonData);
    } else {
      response.text().then((d) => setTxtData(d));
    }
  }, [isOpenViewDoc]);

  useEffect(() => {
    if (isOpenViewDoc.open) {
      fetchFile();
    } else {
      setTxtData("");
      setTableData([]);
    }
  }, [isOpenViewDoc, fetchFile]);

  const renderXlsxFile = () => {
    return (
      <div
        style={{
          overflowX: "auto",
          maxWidth: "100%",
          tableLayout: "fixed",
        }}
      >
        <table border="1" style={{ borderCollapse: "collapse", width: "100%" }}>
          <thead>
            <tr>
              {tableData[0].map((header, index) => (
                <th
                  key={index}
                  style={{
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                  }}
                >
                  {header}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {tableData.slice(1).map((row, rowIndex) => (
              <tr key={rowIndex}>
                {row.map((cell, cellIndex) => (
                  <td
                    key={cellIndex}
                    style={{
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                    }}
                  >
                    {cell}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    );
  };

  const renderTextFile = () => {
    return txtData.split("\n")?.map((row) => {
      return (
        <>
          {row}
          <br />
        </>
      );
    });
  };

  return (
    <ModalBase open={isOpenViewDoc.open} style={{ width: "1100px" }}>
      <Box
        sx={{
          width: "100%",
          height: "100%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
        }}
      >
        <div
          style={{
            height: "90%",
            display: "block",
            overflowY: "hidden",
            whiteSpace: "nonwrap",
          }}
        >
          {(txtData !== "" || tableData.length > 0) && (
            <Grid
              item
              xs={12}
              style={{
                background: "#f8f8f8",
                borderRadius: 8,
                minHeight: 300,
                maxHeight: 600,
                overflowY: "auto",
                overflowX: "hidden",
                position: "relative",
              }}
            >
              <Grid
                style={{
                  background: "#d1d1d190",
                  padding: "10px 15px",
                  fontWeight: 500,
                  position: "sticky",
                  top: 0,
                  right: 0,
                  backdropFilter: "blur(5px)",
                }}
              >
                {isOpenViewDoc.fileName}
              </Grid>
              <Grid style={{ padding: "10px 15px" }}>
                {fileType === "xlsx" && tableData.length > 0
                  ? renderXlsxFile()
                  : renderTextFile()}
              </Grid>
            </Grid>
          )}
        </div>

        <div
          style={{
            display: "flex",
            flexDirection: "row",
            width: "100%",
            justifyContent: "end",
          }}
        >
          <Button onClick={closeModalViewDoc} sx={{ color: "#00609C" }}>
            cerrar
          </Button>
        </div>
      </Box>
    </ModalBase>
  );
}
