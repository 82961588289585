import * as React from "react";
import MobileStepper from "@mui/material/MobileStepper";
import AccountBalanceIcon from "@mui/icons-material/AccountBalanceOutlined";
import {
  DesktopDateTimePicker,
  LocalizationProvider,
} from "@mui/x-date-pickers";
import moment from "moment";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { Box } from "@mui/system";
import { ModalBase } from "./ModalBase";
import { SearchInput } from "../form/SearchInput";
import { useEffect, useState } from "react";
import {
  Button,
  Grid,
  TextField,
  Typography,
  Dialog,
  DialogActions,
  DialogTitle,
  styled,
} from "@mui/material";
import { useModal } from "../../hooks/useModal";

import * as XLSX from "xlsx/xlsx.mjs";
import { DropFileInput } from "../form/DropFileInput";
import { TextInput } from "../form/TextInput";
import { importForm } from "../../helpers/forms";
import LinearProgress, {
  linearProgressClasses,
} from "@mui/material/LinearProgress";
import Spreadsheet from "react-spreadsheet";
import { useSelector } from "react-redux";

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 20,
  borderRadius: 10,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: "#f8f8f8",
    border: "1px solid #979797",
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 10,
    backgroundColor: "#00CD68",
  },
}));

export const ModalImport = (props) => {
  const { closeImport, openImport, importFunction, openModalRegistrosCortesFromImport } = props;
  const { isOpenImport } = useSelector((state) => state.ui);
  const [form, setForm] = useState(importForm);
  const [activeStep, setActiveStep] = useState(0);
  const [selectedFile, setSelectedFile] = useState(false);
  const [fileData, setFileData] = useState(false);
  const [filteredData, setFilteredData] = useState(false);
  const [isOpenConfirmDialog, setIsOpenConfirmDialog] = useState(false);
  const [options, setOptions] = useState([]);
  const [showErrorsView, setShowErrorsView] = useState(false);
  const [sheets, setSheets] = useState([]);
  const [columns, setColumns] = useState([])
  const [selectedSheet, setSelectedSheet] = useState(0)
  const { setOpenToast } = useModal();

  const scrollDownRef = React.useRef(null);

  //---- UPLOADING
  const [progress, setProgress] = useState(0);
  const [log, setLog] = useState([]);

  const [xlsData, setXlsData] = useState([]);
  const [filterXlsData, setFilterXlsData] = useState(0);

  useEffect(() => {
    if (isOpenImport) {
      setActiveStep(0);
      setSelectedFile(false);
      setFilteredData(false);
      setIsOpenConfirmDialog(false);
      setOptions([]);
      setSheets([])
      setColumns([])
      setShowErrorsView(false);
      setProgress(0);
      setLog([]);
      setXlsData([]);
      setForm(importForm);
      setFileData(false);
      setFilterXlsData(0);
    }
  }, [isOpenImport]);

  useEffect(() => {
    showErrors();
  }, [filterXlsData]);

  useEffect(() => {
    if (fileData && sheets.length) {
      let innerOptions = [];
      fileData[sheets[selectedSheet]]?.map((row) => {
        if (!row["Cargo/Abono"] || row["Cargo/Abono"] === form.sTipo) {
          if (row.Concepto) {
            if (!innerOptions.includes(String(row.Concepto).replace("'", "").trim()))
              innerOptions.push(String(row.Concepto).replace("'", "").trim());
          }
        }
      });
      setOptions(innerOptions);
      setForm({
        ...form,
        sConcepto: 0,
      })
    }
  }, [fileData, selectedSheet, form.sTipo]);

  useEffect(() => {
    if (fileData && sheets.length) {
      let innerFilteredData = formatJson(fileData[sheets[selectedSheet]]);

      if (form.sConcepto) {
        innerFilteredData = innerFilteredData.filter(
          (item) => String(item.Concepto).replace("'", "").trim() === form.sConcepto
        );
      }
      if (parseInt(form.setInitialDatetime) === 1) {
        let arr2 = [];
        innerFilteredData.map((item) => {
          if (moment(form.dInicial).isSameOrBefore(moment(item.dFechaHora)))
            arr2.push(item);
        });
        innerFilteredData = arr2;
        console.log(innerFilteredData);
      }
      setFilteredData(innerFilteredData);
    }
  }, [form, selectedSheet]);

  useEffect(() => {
    if (fileData && sheets.length) {
      setInnerColumns(Object.keys(fileData[sheets[selectedSheet]][0]))
      setForm({
        ...form,
        sDescripcion: "Descripción",
      })
    }
  }, [selectedSheet]);

  const handleCloseImport = () => {
    setOpenToast(false, "Registros bancarios cargados exitosamente");
    closeImport();
  };

  const confirmDelete = () => {
    setLog([]);
    setIsOpenConfirmDialog(true);
  };

  const importMethod = async () => {
    setIsOpenConfirmDialog(false);

    setShowErrorsView(false);
    setActiveStep(2);

    let innerProgress = 0;
    let innerLog = [];
    const loteLength = 10;
    let cantidadLotes = Math.ceil(filteredData.length / loteLength);
    const stepValue = 100 / cantidadLotes;
    setProgress(0);

    //Se recorren los lotes
    for (let i = 0; i < cantidadLotes; i++) {
      scrollToBottom();

      //Se obtiene el array del lote
      let loteArray = [];
      let initialIndex = i * loteLength;
      for (let j = 0; j < loteLength; j++) {
        let index = initialIndex + j;

        if (index < filteredData.length)
          loteArray.push({
            row: index,
            data: filteredData[index],
          });
      }

      await importRequest(initialIndex, loteArray).then((res) => {
        innerLog = [...innerLog, ...res];
        innerProgress += stepValue;
        //console.log(innerLog);
        setProgress(innerProgress);
        setLog(innerLog);

        scrollToBottom();
      });
    }
    setActiveStep(3);
    scrollToBottom();
  };

  const scrollToBottom = () => {
    scrollDownRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  const importRequest = async (initialIndex, loteArray) => {
    return new Promise(async (resolve, reject) => {
      await importFunction(initialIndex, loteArray, form.sTipo === "+").then((res) => {
        resolve(res);
      });
    });
  };

  const handleDrop = (e) => {
    let file = e.dataTransfer ? e.dataTransfer.files[0] : e.target.files[0];
    if (file) {
      if (isExtensionAllowed(file.name)) {
        const reader = new FileReader();
        let finalData = {};
        let finalSheets = [];
        let flagNoData = false;
        let flagHeaders = false;

        reader.onload = (e) => {
          const data = e.target.result;
          const workbook = XLSX.read(data, { type: "array" });

          workbook.SheetNames.map((sheetName) => {
            const worksheet = workbook.Sheets[sheetName];
            const json = XLSX.utils.sheet_to_json(worksheet);
            if (json.length && isHeadersAllowed(json[0])) {
              finalSheets.push(sheetName);
              finalData = {
                ...finalData,
                [sheetName]: json,
              }
            } else {
              if (!json.length) flagNoData = true;
              else flagHeaders = true;
            }
          });

          if (finalSheets.length) {
            console.log(finalData)
            setSheets(finalSheets);
            setSelectedFile(file);
            setFileData(finalData);
            setFilteredData(formatJson(finalData[finalSheets[0]]));
            setSelectedSheet(0)
            setInnerColumns(Object.keys(finalData[finalSheets[0]][0]))
          } else {
            if (flagNoData) setOpenToast(true, "Archivo sin registros");
            if (flagHeaders)
              setOpenToast(
                true,
                "El archivo no tiene los encabezados correctos"
              );
          }
        };
        reader.readAsArrayBuffer(file);
      } else {
        setOpenToast(true, "Extensión de archivo no compatible");
      }
    }
  };

  const setInnerColumns = (arr) => {
    const headers = [
      "Cuenta",
      "Fecha",
      "Hora",
      "Importe",
      "Referencia",
      "Concepto",
      "Cargo/Abono",
      "Descripción",
    ];
    let innerColumns = []
    arr.map(item => {
      if (!headers.includes(item)) innerColumns.push(item)
    })
    console.log(innerColumns)
    setColumns(innerColumns)
  }

  const showErrors = () => {
    if (filteredData) {
      const headers = [
        {
          value: "Cuenta",
          name: "sCuenta",
          disableColumn: false,
          readOnly: true,
        },
        {
          value: "Fecha",
          name: "dFecha",
          disableColumn: false,
          readOnly: true,
        },
        {
          value: "Hora",
          name: "dHora",
          disableColumn: false,
          readOnly: true,
        },
        {
          value: "Importe",
          name: "nImporte",
          disableColumn: true,
          readOnly: true,
        },
        {
          value: "Referencia",
          name: "sReferencia",
          disableColumn: false,
          readOnly: true,
        },
        {
          value: "Descripción",
          name: "sDescripcion",
          disableColumn: false,
          readOnly: true,
        },
        {
          value: "Concepto",
          name: "sConcepto",
          disableColumn: false,
          readOnly: true,
        },
        {
          value: "Mensaje",
          name: "sError",
          disableColumn: true,
          readOnly: true,
        },
      ];
      let xlsxDataInner = [headers];
      filteredData.map((row, key) => {
        let error = log.find((l) => parseInt(l.nFila) === key);
        if (
          parseInt(filterXlsData) === 1 ||
          (parseInt(filterXlsData) === 0 && error && error.nCodigo !== 0) ||
          (parseInt(filterXlsData) === 2 && error && error.nCodigo === 0)
        ) {
          let innerArray = [];
          headers.map((header) => {
            let innerObject = {};
            if (header.name === "sError") {
              innerObject = {
                value: error?.sMensaje,
                readOnly: header.disableColumn,
              };
            } else {
              innerObject = {
                value: row[header.name],
                readOnly: header.disableColumn,
              };
            }
            innerArray.push(innerObject);
          });
          xlsxDataInner.push(innerArray);
        }
      });

      setXlsData(xlsxDataInner);
      setShowErrorsView(true);
    }
  };

  const downloadErrorsFile = () => {
    const data = [];

    xlsData.map((row, key) => {
      let innerData = [];
      row.map((r, j) => {
        if (key) {
          if (xlsData[0][j].name === "dFecha") {
            const dFecha = moment(r.value, "YYYY-MM-DD").format("DDMMYYYY");
            innerData.push(dFecha);
          } else {
            innerData.push(r.value);
          }
        } else {
          innerData.push(r.value);
        }
      });
      data.push(innerData);
    });

    const workbook = XLSX.utils.book_new();
    var worksheet1 = XLSX.utils.aoa_to_sheet(data);
    XLSX.utils.book_append_sheet(workbook, worksheet1, "Datos");
    XLSX.writeFile(workbook, `resultado.xlsx`);
  };

  const processErrors = () => {
    const data = [];

    xlsData.map((row, key) => {
      if (key) {
        let item = {};
        row.map((r, j) => {
          item = {
            ...item,
            [xlsData[0][j].name]: r.value,
          };
        });
        data.push(item);
      }
    });

    console.log(data);
    setFilteredData(data);
    confirmDelete();
  };

  const formatJson = (json) => {
    let finalData = [];
    json.map((row) => {
      if (!row["Cargo/Abono"] || row["Cargo/Abono"] === form.sTipo) {
        let dHora = row.Hora;
        if (typeof row.Hora === "string") {
          dHora = moment(row.Hora, "HH:mm:ss");
          dHora = dHora.format("HH:mm:ss");
        } else {
          let time = new Date(Math.round((row.Hora - 25569) * 86400 * 1000));
          //dHora = moment().utc(seconds).format("HH:mm:ss");
          dHora = time.toISOString().slice(0, -1).split("T")[1].split(".")[0];
        }
        let dFecha = row.Fecha
        if (typeof row.Fecha === "string") {
          dFecha = moment(row.Fecha, form.dateFormat).format("YYYY-MM-DD");
        } else {
          dFecha = moment(String(row.Fecha), form.dateFormat);
          if (dFecha.isValid()) {
            dFecha = dFecha.format("YYYY-MM-DD");
          } else {
            const date = new Date(Math.round((row.Fecha - 25569) * 86400 * 1000));
            //dHora = moment().utc(seconds).format("HH:mm:ss");
            dFecha = date.toISOString().slice(0, -1).split("T")[0];
          }
        }

        const dFechaHora = dFecha + " " + dHora;
        finalData.push({
          ...row,
          dFechaHora,
          sDescripcion: String(row[form.sDescripcion]).replace("'", "").trim(),
          sConcepto: String(row.Concepto).replace("'", "").trim(),
          sReferencia: String(row.Referencia).replace("'", "").trim(),
          sCuenta: String(row.Cuenta).replace("'", "").trim(),
          nImporte: row.Importe,
          dFecha,
          dHora,
        });
      }
    });
    console.log(finalData)
    return finalData;
  };

  const isHeadersAllowed = (array1) => {
    const keysAr = Object.keys(array1);
    const headers = [
      "Cuenta",
      "Fecha",
      "Hora",
      "Importe",
      "Referencia",
      "Concepto",
      "Cargo/Abono",
    ];
    let flag = true;

    headers.map((header) => {
      if (!keysAr.includes(header)) {
        flag = false;
      }
    });

    return flag;
  };

  const downloadPlantilla = () => {
    const data = [
      [
        "Cuenta",
        "Fecha",
        "Hora",
        "Importe",
        "Referencia",
        "Concepto",
        "Cargo/Abono",
        "Descripción",
      ],
      [
        "6550762907",
        "17112022",
        "11:13:00",
        "2350.30",
        "002365449",
        "1145585097274",
        "+",
        "DEP EN EFECTIV",
      ],
      [
        "6550762907",
        "19112022",
        "18:25:00",
        "6100.50",
        "000164540",
        "1145586306377-COM",
        "+",
        "DEP EN EFECTIV",
      ],
      [
        "6550762907",
        "22112022",
        "14:54:00",
        "150.80",
        "000164540",
        "EKU9003173C9",
        "-",
        "PAGO TRAN SPEI",
      ],
    ];
    const workbook = XLSX.utils.book_new();
    var worksheet1 = XLSX.utils.aoa_to_sheet(data);
    XLSX.utils.book_append_sheet(workbook, worksheet1, "Datos");
    XLSX.writeFile(workbook, `plantilla_registros_bancarios.xlsx`);
  };

  const isExtensionAllowed = (fileName) => {
    const extension = fileName.split(".").pop();
    return ["xls", "xlsx", "csv"].includes(extension);
  };

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  return (
    <>
      <Dialog
        open={isOpenConfirmDialog}
        onClose={() => setIsOpenConfirmDialog(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          Se importarán {filteredData.length} registro
          {filteredData.length > 1 ? "s" : ""} ¿Estas seguro/a?
        </DialogTitle>
        <DialogActions>
          <Button onClick={() => setIsOpenConfirmDialog(false)}>
            Cancelar
          </Button>
          <Button onClick={() => importMethod()} variant="contained">
            Estoy seguro/a
          </Button>
        </DialogActions>
      </Dialog>
      <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={"es-mx"}>
        <ModalBase
          open={isOpenImport}
          style={{ width: "1050px" /*, height: "450px" */ }}
        >
          <Box
            sx={{
              width: "100%",
              height: "100%",
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
            }}
          >
            <Grid container style={{ alignItems: "space-between" }}>
              <Grid container item sm={8} style={{ alignItems: "center" }}>
                <Grid
                  item
                  style={{
                    color: "#00CD68",
                    padding: 15,
                    fontWeight: "bold",
                  }}
                >
                  <AccountBalanceIcon fontSize={"large"} />
                </Grid>
                <Grid item>
                  <Typography variant="p" color={"neutral"}>
                    Importar
                  </Typography>
                  <Typography variant="h5" color={"primary"}>
                    Registros bancarios
                  </Typography>
                </Grid>
              </Grid>
              <Grid
                item
                sm={4}
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "flex-end",
                }}
              >
                {activeStep === 0 && (
                  <Button
                    onClick={() => downloadPlantilla()}
                    variant="contained"
                    size="small"
                  >
                    Descargar plantilla
                  </Button>
                )}
              </Grid>
            </Grid>
            {activeStep === 0 && (
              <DropFileInput
                handleDrop={handleDrop}
                selectedFile={selectedFile}
                setSelectedFile={setSelectedFile}
                setFileData={setFileData}
              />
            )}
            {activeStep === 1 && (
              <Grid container spacing={2} style={{ padding: 20 }}>
                <Grid
                  item
                  container
                  xs={12}
                  sm={12}
                  alignItems={"center"}
                  justifyContent={"space-between"}
                >
                  <Typography variant="h6" color={"primary"}>
                    Configuración
                  </Typography>
                  <Typography variant="p" color={"primary"}>
                    Se importarán{" "}
                    <b>
                      {filteredData.length} registro
                      {filteredData.length > 1 ? "s" : ""}
                    </b>
                  </Typography>
                </Grid>
                {sheets.length > 1 &&
                  <Grid item xs={12} sm={6}>
                    <SearchInput
                      options={sheets.map((sheet, key) => {
                        return ({
                          id: key,
                          sNombre: sheet
                        })
                      })}
                      name={"sConcepto"}
                      label={"Hoja a importar"}
                      getOptionLabel={"sNombre"}
                      getIndexLabel={"id"}
                      value={selectedSheet}
                      onChange={(e) =>
                        setSelectedSheet(e.target.value)
                      }
                    />
                  </Grid>
                }
                <Grid item xs={12} sm={6}>
                  <TextInput
                    id="dateFormat"
                    name="dateFormat"
                    label="Formato de fecha"
                    value={form.dateFormat}
                    onChangeRoot={(e) =>
                      setForm({
                        ...form,
                        [e.target.name]: e.target.value,
                      })
                    }
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <SearchInput
                    options={[
                      {
                        id: "+",
                        sNombre: "Abonos (+)"
                      },
                      {
                        id: "-",
                        sNombre: "Cargos (-)"
                      }
                    ]}
                    name={"sTipo"}
                    label={"Tipo de registro"}
                    getOptionLabel={"sNombre"}
                    getIndexLabel={"id"}
                    value={form.sTipo}
                    onChange={(e) =>
                      setForm({
                        ...form,
                        [e.target.name]: e.target.value,
                      })
                    }
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <SearchInput
                    options={[
                      {
                        id: "Descripción",
                        sNombre: "Descripción"
                      },
                      ...columns.map(col => {
                        return {
                          id: col,
                          sNombre: col
                        }
                      })
                    ]}
                    name={"sDescripcion"}
                    label={"Columna para valor de descripción"}
                    getOptionLabel={"sNombre"}
                    getIndexLabel={"id"}
                    value={form.sDescripcion}
                    onChange={(e) =>
                      setForm({
                        ...form,
                        [e.target.name]: e.target.value,
                      })
                    }
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <SearchInput
                    options={[
                      { id: 0, sNombre: "Todos" },
                      ...options.map((option) => {
                        return {
                          id: option,
                          sNombre: option,
                        };
                      }),
                    ]}
                    name={"sConcepto"}
                    label={"Concepto"}
                    getOptionLabel={"sNombre"}
                    getIndexLabel={"id"}
                    value={form.sConcepto}
                    onChange={(e) =>
                      setForm({
                        ...form,
                        [e.target.name]: e.target.value,
                      })
                    }
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <SearchInput
                    options={[
                      { id: 0, sNombre: "Todos" },
                      { id: 1, sNombre: "Personalizada" },
                    ]}
                    name={"setInitialDatetime"}
                    label={"Filtrar por fecha"}
                    getOptionLabel={"sNombre"}
                    getIndexLabel={"id"}
                    value={form.setInitialDatetime}
                    onChange={(e) =>
                      setForm({
                        ...form,
                        [e.target.name]: e.target.value,
                      })
                    }
                  />
                </Grid>
                {parseInt(form.setInitialDatetime) === 1 && (
                  <Grid item xs={12} sm={6}>
                    <DesktopDateTimePicker
                      label="Importar a partir de"
                      inputFormat="YYYY-MM-DD HH:mm"
                      renderInput={(params) => (
                        <TextField {...params} fullWidth />
                      )}
                      value={form.dInicial}
                      onChange={(e) =>
                        setForm({
                          ...form,
                          dInicial: moment(e["$d"]).format("YYYY-MM-DD HH:mm"),
                        })
                      }
                    />
                  </Grid>
                )}
              </Grid>
            )}
            {activeStep === 2 && (
              <Grid
                container
                justifyContent={"center"}
                flexDirection={"column"}
                spacing={2}
                style={{ padding: 20, width: "100%", textAlign: "center" }}
              >
                <Grid item xs={12} sm={12}>
                  <Typography variant="p" color={"primary"}>
                    Procesando archivo ({`${Math.round(progress)}%`})...
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={12}>
                  <BorderLinearProgress
                    variant="determinate"
                    value={progress}
                  />
                </Grid>
                <Grid item xs={12} sm={12} style={{ textAlign: "right" }}>
                  <Typography variant="p" color={"primary"}>
                    {log.length}/{filteredData.length} registros
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={12} style={{ textAlign: "left" }}>
                  <Typography variant="p" color={"primary"}>
                    {log.length} registros:{" "}
                    <span style={{ color: "#00CD68" }}>
                      {log.filter((l) => l.nCodigo === 0).length} exitosos
                    </span>{" "}
                    /{" "}
                    <span style={{ color: "#DD0000" }}>
                      {log.filter((l) => l.nCodigo !== 0).length} con error
                    </span>
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={12}
                  style={{
                    backgroundColor: "#f8f8f8",
                    border: "1px solid #979797",
                    padding: "10px 15px 25px",
                    borderRadius: 8,
                    maxHeight: "200px",
                    overflowY: "auto",
                    textAlign: "left",
                  }}
                >
                  {log
                    .sort((a, b) => parseFloat(a.nFila) - parseFloat(b.nFila))
                    .map((l) => (
                      <p
                        style={{
                          color: l.nCodigo === 0 ? "#00609C" : "#DD0000",
                          padding: 0,
                          margin: "0 0 5px 0",
                        }}
                      >
                        Fila [{parseInt(l.nFila) + 2}]: {l.sMensaje}
                      </p>
                    ))}
                  <div
                    style={{ float: "left", clear: "both" }}
                    ref={scrollDownRef}
                  ></div>
                </Grid>
                <Grid item xs={12} sm={12}></Grid>
              </Grid>
            )}
            {activeStep === 3 &&
              (showErrorsView ? (
                <>
                  <Grid
                    container
                    justifyContent={"space-between"}
                    alignItems={"center"}
                  >
                    <Grid item xs={4}>
                      <SearchInput
                        options={[
                          { id: 0, sNombre: "Registros con error" },
                          { id: 1, sNombre: "Todos" },
                          { id: 2, sNombre: "Registros exitosos" },
                        ]}
                        label={"Filtro"}
                        getOptionLabel={"sNombre"}
                        getIndexLabel={"id"}
                        value={filterXlsData}
                        style={{ width: 500 }}
                        onChange={(e) => setFilterXlsData(e.target.value)}
                      />
                    </Grid>

                    <Button onClick={downloadErrorsFile} variant="contained">
                      Descargar Excel
                    </Button>
                  </Grid>
                  <Grid
                    container
                    style={{
                      padding: 10,
                      width: "100%",
                      height: "450px",
                      overflow: "auto",
                    }}
                  >
                    <Spreadsheet data={xlsData} onChange={setXlsData} />
                  </Grid>
                </>
              ) : (
                <Grid
                  container
                  flexDirection={"column"}
                  spacing={2}
                  style={{ padding: 20, width: "100%" }}
                >
                  <Grid item xs={12} sm={12}>
                    <Typography variant="h6" color={"primary"}>
                      Archivo procesado
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={12} style={{ textAlign: "left" }}>
                    <Typography variant="p" color={"primary"}>
                      {log.length} registros:{" "}
                      <span style={{ color: "#00CD68" }}>
                        {log.filter((l) => l.nCodigo === 0).length} exitosos
                      </span>{" "}
                      /{" "}
                      <span style={{ color: "#DD0000" }}>
                        {log.filter((l) => l.nCodigo !== 0).length} con error
                      </span>
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    style={{
                      backgroundColor: "#f8f8f8",
                      border: "1px solid #979797",
                      padding: "10px 15px 15px",
                      borderRadius: 8,
                      maxHeight: "250px",
                      overflowY: "auto",
                      textAlign: "left",
                    }}
                  >
                    {log
                      .sort((a, b) => parseFloat(a.nFila) - parseFloat(b.nFila))
                      .map((l) => (
                        <p
                          style={{
                            color: l.nCodigo === 0 ? "#00609C" : "#DD0000",
                            padding: 0,
                            margin: "0 0 5px 0",
                          }}
                        >
                          Fila [{parseInt(l.nFila) + 2}]: {l.sMensaje}
                        </p>
                      ))}
                  </Grid>
                  <Grid
                    item
                    container
                    xs={12}
                    sm={12}
                    justifyContent={"flex-end"}
                  >
                    {log.filter((l) => l.nCodigo !== 0).length ? (

                      <Button onClick={showErrors} variant="contained">
                        Resolver errores
                      </Button>
                    ) : (
                      <></>
                    )}

                  </Grid>
                </Grid>
              ))}
            <MobileStepper
              variant="dots"
              steps={4}
              position="static"
              activeStep={activeStep}
              nextButton={<div style={{ width: 1, height: 1 }}></div>}
              backButton={<div style={{ width: 1, height: 1 }}></div>}
              sx={{
                "& .MuiMobileStepper-dot": {
                  backgroundColor: "#00CD68",
                  width: 12,
                  height: 12,
                },
                "& .MuiMobileStepper-dotActive ~ .MuiMobileStepper-dot": {
                  backgroundColor: "#D8D8D8",
                  width: 12,
                  height: 12,
                },
              }}
            />
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                width: "100%",
                justifyContent: "end",
              }}
            >
              {activeStep !== 3 && (
                <Button
                  onClick={activeStep === 0 ? closeImport : handleBack}
                  sx={{ color: "#00609C" }}
                >
                  {[0, 2].includes(activeStep) ? "Cancelar" : "Anterior"}
                </Button>
              )}
              {![3, 2].includes(activeStep) && (
                <Button
                  onClick={activeStep === 1 ? confirmDelete : handleNext}
                  variant="contained"
                  disabled={activeStep === 0 && !selectedFile}
                >
                  Siguiente
                </Button>
              )}
              {activeStep === 3 && showErrorsView && (
                <Button
                  onClick={() => setShowErrorsView(false)}
                  sx={{ color: "#00609C" }}
                >
                  Cancelar
                </Button>
              )}
              {activeStep === 3 && (
                <Button
                  onClick={showErrorsView ? processErrors : handleCloseImport}
                  variant="contained"
                >
                  {showErrorsView ? "Procesar" : "Finalizar"}
                </Button>
              )}
            </div>
          </Box>
        </ModalBase>
      </LocalizationProvider>
    </>
  );
};
