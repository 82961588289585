import { useDispatch, useSelector } from "react-redux";
import { ModalBase } from "./ModalBase";
import { Box, Button, Typography } from "@mui/material";
import {
  Download,
  History,
  PictureAsPdf,
  Summarize,
} from "@mui/icons-material";
import ButtonDropdown from "../ButtonDropdown";
import { useModal } from "../../hooks/useModal";
import EnhancedTable from "../table/EnhancedTable";
import { useEffect, useState } from "react";
import moment from "moment";
import { useExportFile } from "../../hooks/useExportFile";

export const ModalBitacora = (props) => {
  const {
    title,
    subtitle,
    download,
    formats = ["pdf", "excel"],
    expandableTable = false,
    rowId,
    expandedItems,
    getDataFn,
    getDataParams,
  } = props;

  const [dataTable, setDataTable] = useState({
    title: "",
    columns: [],
    rows: [],
  });

  const { isOpenBitacora, isLoading } = useSelector((state) => state.ui);
  const dispatch = useDispatch();
  const { closeModalBitacora } = useModal();
  const { downloadFile } = useExportFile();

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    const response = await dispatch(getDataFn(getDataParams));
    setDataTable(response);
  };

  const downloadBitacora = (format) => {
    const dFecha = moment().format("YYYY-MM-DD");
    downloadFile(
      dataTable.rows,
      dataTable.columns.map((c) => ({ ...c, format: c.type })),
      format,
      `${title} (${dFecha})`,
      title,
      `${subtitle} (${dFecha})`
    );
  };

  const TitleModal = () => {
    const startIconByFormat = {
      pdf: <PictureAsPdf />,
      excel: <Summarize />,
    };
    const downloadOptions = download
      ? formats.map((format) => {
          return {
            startIcon: startIconByFormat[format],
            label: format.toUpperCase(),
            action: () => downloadBitacora(format),
          };
        })
      : [];
    return (
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          width: "100%",
          marginBottom: "10px",
          justifyContent: "space-between",
        }}
      >
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <History
            fontSize={"large"}
            sx={{ color: "#00CD68", fontSize: "45px" }}
          />

          <div style={{ display: "flex", flexDirection: "column" }}>
            <Typography
              variant="p"
              sx={{
                marginLeft: "15px",
                fontSize: "14px",
                marginBottom: "4px",
              }}
            >
              {subtitle}
            </Typography>
            <Typography
              variant="p"
              sx={{
                marginLeft: "15px",
                color: "#00609C",
                fontSize: "20px",
              }}
            >
              {title}
            </Typography>
          </div>
        </div>
        {download && (
          <ButtonDropdown
            startIcon={<Download />}
            label="Exportar"
            options={downloadOptions}
          />
        )}
      </Box>
    );
  };

  return (
    <>
      <ModalBase
        open={isOpenBitacora}
        style={{ width: "1050px", height: "auto" }}
      >
        <Box
          sx={{
            width: "100%",
            height: "100%",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <TitleModal />
          <EnhancedTable
            table={dataTable}
            disableButtons
            isModalTable
            disablePathParameters
            showExpandableTable={expandableTable}
            rowId={rowId}
            isLoading={isLoading}
            expendedItems={expandedItems}
          />
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              width: "100%",
              justifyContent: "end",
            }}
          >
            <Button onClick={closeModalBitacora} color="primary">
              Volver
            </Button>
          </div>
        </Box>
      </ModalBase>
    </>
  );
};
