import { useSelector, useDispatch } from "react-redux";
import { useEffect, useState } from "react";
import { usuarioPerfilForm } from "../helpers/forms";
import { deletePerfilUsuario, getUsuariosData, insertPerfilUsuario, insertUsuario, updatePerfilUsuario, updateUsuario, updateUsuarioBaja, updateUsuarioEstatus } from "../slices/usuarios/usuariosThunk";
import { startLoadPermisosData, startLoadProcesos } from "../slices/catalogo/catalgoThunk";
import { useModal } from "./useModal";
import moment from "moment";
import { getMonitoreoERP, postMonitoreoERP } from "../slices/monitoreo/monitoreoThunk";
import { loading } from "../slices/ui/uiSlice";

export const useMonitoreoERP = () => {
  const { monitoreoERP } = useSelector((state) => state.monitoreo);
  const dispatch = useDispatch();
  const [showModal, setShowModal] = useState({
    open: false,
    data: {},
    tab: "resumen"
  })
  const [showModalBitacora, setShowModalBitacora] = useState({
    open: false,
    data: {},
  })
  const [fecha, setFecha] = useState(moment().format("YYYY-MM-DD"))
  const { setOpenToast } = useModal();

  useEffect(() => {
    getMonitoreoERPData()
  }, [fecha])


  const handleFecha = (dFecha) => {
    setFecha(dFecha)
  }
  const openModal = async (data) => {
    setShowModal({
      open: true,
      data,
      tab: "resumen"
    })
  }
  const handleChangeTab = (tab) => {
    setShowModal({...showModal, tab})
  }
  const loadInInit = async () => {
    await getMonitoreoERPData();
  };

  const getMonitoreoERPData = async () => {
    await dispatch(getMonitoreoERP(fecha));
  }

  const runInterfazERP = async (nIdTipoInterfaz) => {
    const result = await dispatch(postMonitoreoERP(nIdTipoInterfaz));
    
    setOpenToast(!result.ok, result.msg)
    if (result.ok) {
      await loadInInit();
    }
  }

  const handleCloseModal = () => {
    setShowModal({
      open: false,
      data: {},
      tab: "resumen"
    })
  }

  const handleOpenModalBitacora = (data) => {
    console.log(data)
    setShowModal({...showModal, open: false})
    setShowModalBitacora({
      open: true,
      data
    })
  }

  const handleCloseModalBitacora = () => {
    setShowModalBitacora({
      open: false,
      data: {},
    })
    setShowModal({...showModal, open: true})
  }


  return {
    monitoreoERP,
    loadInInit,
    showModal,
    handleCloseModal,
    openModal,
    handleFecha,
    fecha,
    handleChangeTab,
    runInterfazERP,
    showModalBitacora,
    handleOpenModalBitacora,
    handleCloseModalBitacora
  };
};
