import { v4 as uuidv4 } from 'uuid';
import { liquidacion } from './forms';
import { daysOfWeek } from './getData';
import { getNormalizeWord } from './word';

export const escaloandosSwitch = [
  'isActiveEscOperacion',
  'isActiveEscTicket',
  'isIndirecto'
];

export const liquidacionSwitch = [
  'isActiveCashIn',
  'isActiveCashOut',
]

export const switches = [
  'isActiveMGracia',
]

export const parseLiquidacion = ( list ) => {
  return list.map((liquidacion) => {
    const corte = daysOfWeek.find(
      (day) => day.value.toString() === liquidacion.nDiaCorte.toString()
    );
    const pago = daysOfWeek.find(
      (day) => day.value.toString() === liquidacion.nDiaPago.toString()
    );
    return {
      ...liquidacion,
      pago : pago ? pago.text : '',
      corte: corte ? corte.text : '',
    }
  });
  
};

export const parseJsonLiquidacion = ( liquidaciones, liqJson ) => {
  return liquidaciones.map((liq) => {
    const idDiaPago = liqJson[getNormalizeWord(liq.corte)]
    const pago = daysOfWeek.find(
      (day) => day.value.toString() === idDiaPago.toString()
    );
    return {
      ...liq,
      pago    : pago ? pago.text: '',
      nDiaPago: idDiaPago
    }
  })
}

export const parseEscOperacionToCantidad = ( escList ) => {
  return escList.map((esc, i) => {
    return {
      ...esc,
      id               : uuidv4(),
      cantidadMinimo   : `${esc.nNumOpeMin}`,
      cantidadMaxima   : `${esc.nNumOpeMax}`,
      comisionMontoFijo: `${esc.nCosto}`,
      isActiveDelete   : i !== escList.length - 1,
      disableInputs    : i !== escList.length - 1,
      isFirst          : i === 0,
    }
  });
};

export const parseEscOperacionToCantidadEnhanced = ( escList ) => {
  return escList.map((esc, i) => {
    return {
      ...esc,
      id               : uuidv4(),
      cantidadMinimo   : `${esc.nNumOpeMin}`,
      cantidadMaxima   : `${esc.nNumOpeMax}`,
      comisionMontoFijo: `${esc.nCosto}`,
      isActiveDelete   : true,
      disableInputs    : true,
      isFirst: i === 0,
      isLast: i === escList.length - 1 && !parseInt(esc.nNumOpeMax)
    }
  });
};

export const parseEscTicketToForm = ( escList ) => {
  return escList.map((esc, i) => {
    return {
      ...esc,
      id               : uuidv4(),
      montoMinimo      : `${esc.nMontoMin}`,
      montoMaximo      : `${esc.nMontoMax}`,
      comisionMontoFijo: `${esc?.nComision ?? esc?.nCosto ?? ''}`,
      comisionPorcentaj: `${esc.nPorcentajeCom}`,
      isActiveDelete   : i !== escList.length - 1,
      disableInputs    : i !== escList.length - 1,
      isFirst          : i === 0,
    }
  });
};

export const parseEscFormToTicket = ( escList ) => {
  return escList.map((esc) => ({
    ...esc,
    nMontoMin     : `${esc.montoMinimo}`,
    nMontoMax     : `${esc.montoMaximo}`,
    nComision     : `${esc.comisionMontoFijo}`,
    nPorcentajeCom: `${esc.comisionPorcentaj}`,
  }));
};

export const parseEscalonadoCadnea = ( escList ) => {
  return escList.map((esc) => ({
    ...esc,
    nMontoMin     : `${esc.montoMinimo}`,
    nMontoMax     : `${esc.montoMaximo}`,
    nCosto        : `${esc.comisionMontoFijo}`,
    nPorcentajeCom: `${esc.comisionPorcentaj}`,
  }));
};

export const parseEscCantidadToOperacion = ( escList ) => {
  return escList.map((esc) => ({
    ...esc,
    nNumOpeMin: `${esc.cantidadMinimo}`,
    nNumOpeMax: `${esc.cantidadMaxima}`,
    nCosto    : `${esc.comisionMontoFijo}`,
  }));
}

export const searchItemById = (id = '', list = []) => {
  return list.find((item) => item.value.toString() === id.toString());
};

export const getLiquidacionJSON = ( liquidaciones = [], tiposLiquidacion ) => {
  if ( liquidaciones.length === 0 ) {
    return {
      ...liquidacion,
      tipoSelected: tiposLiquidacion.find((liq) => liq.value.toString() === '1'),
    };
  }
  const {
    nIdTipoLiquidacion = 1,
    nMontoBase = 0,
    nPerTransaccion = 0,
    sTipoLiquidacion = ""
  } = liquidaciones[0]
  return {
    pagoMes: '',
    nMontoBase,
    nPerTransaccion,
    tipo: nIdTipoLiquidacion.toString(),
    sTipoLiquidacion,
    tipoSelected: tiposLiquidacion.find((liq) => liq.value.toString() === nIdTipoLiquidacion.toString()),
    lunes       : `${liquidaciones[0]?.nDiaPago}`,
    martes      : `${liquidaciones[1]?.nDiaPago}`,
    miercoles   : `${liquidaciones[2]?.nDiaPago}`,
    jueves      : `${liquidaciones[3]?.nDiaPago}`,
    viernes     : `${liquidaciones[4]?.nDiaPago}`,
    sabado      : `${liquidaciones[5]?.nDiaPago}`,
    domingo     : `${liquidaciones[6]?.nDiaPago}`,
  }
};

export const getFacturaFormValuesByList = ( factList = [], iva ) => {
  const comisionTemp = factList.find((fac) => fac.nIdTipoFactura.toString() === '1');
  const transTemp = factList.find((fac) => fac.nIdTipoFactura.toString() === '2')
  const minimaTemp = factList.find((fac) => fac.nIdTipoFactura.toString() === '3')
  return {
    iva,
    minimo       : getFormatFactura(minimaTemp),
    comision     : getFormatFactura(comisionTemp),
    regimenFiscal: comisionTemp ? comisionTemp.sRegimenFiscal : '',
    transferencia: getFormatFactura(transTemp),
  }
}

export const getEmailsStrFormat = ( emailsTxt ) => {

  const correosDestino = emailsTxt.split(',')
  return correosDestino.map(( emailTxt, i ) => ({
    id            : uuidv4(),
    email         : emailTxt,
    isActiveDelete: i !== correosDestino.length - 1,
    isAciveDisable: false
  }));
}


const getFormatFactura = ( facturaTemp ) => {
  
  const factura = {
    cfdi           : facturaTemp.sUsoCFDI,
    claveProducto  : facturaTemp.sClaveProductoServicio,
    claveUnidad    : facturaTemp.sUnidad,
    periocidad     : `${facturaTemp.nPeriodoFacturacion}`,
    diasLiquidacion: `${facturaTemp.nDiasLiquidaFactura}`,
    formaPago      : facturaTemp.sFormaPago,
    metodoPago     : facturaTemp.sMetodoPago,
    emails         : getEmailsStrFormat(facturaTemp.sCorreoDestino),
    nIdFacturaEmisor: facturaTemp.nIdFacturaEmisor
  }

  return factura
}

export const getFormatContactosEdit = ( contactos = [] ) => {
  return contactos.map((contact) => {
    const newContact = {
      nIdContacto: contact?.nIdContacto ?? '0',
      sNombre    : contact.nombre,
      sAPaterno  : contact.apellido,
      sAMaterno  : contact.segundoApellido,
      sTelefono  : contact.telefono,
      sProcesos  : contact.isPrincipal ? [] : contact.proceso.map(con => ({nIdProceso: con.nIdProceso})),
      sCorreo    : contact.email,
      sArea      : contact.area,
      sPuesto    : contact.puesto,
      sComentario: contact.comentarios,
    }
    if ( !('nIdContacto' in contact) ) {
      delete newContact.nIdContacto
    }
    return newContact;

  })
}

export const getFormatLiquidacion = ( semana = [], liquidaciones = [] ) => {
  // nIdConfiguracionPago
  return semana.map((day) => {
    const searched = liquidaciones.find(
      (liq) => day.nDiaCorte.toString() === liq.nDiaCorte.toString()
    );
    return {
      ...day,
      nIdConfiguracionPago: searched?.nIdLiquidacion ? searched?.nIdLiquidacion : 0
    }
  })
}