import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  dataTable:  {
    columns: [],
    rows   : []
  },
  diferencias: [],
  operaciones: [],
  diferenciasConciliadas: []
};

export const conciliacionSlice = createSlice({
  name: 'conciliacion',
  initialState,
  reducers: {
    setDataTable: (state, action) => {
      state.dataTable = {...action.payload};
    },
    setDiferencias: (state, action) => {
      state.diferencias = [...action.payload];
    },
    setOperaciones: (state, action) => {
      state.operaciones = [...action.payload];
    },
    setDiferenciasConciliadas: (state, action) => {
      state.diferenciasConciliadas = [...action.payload];
    },
  }
});

export const {
  setDataTable,
  setDiferencias,
  setOperaciones,
  setDiferenciasConciliadas,
} = conciliacionSlice.actions;
