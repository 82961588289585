import {
  Box,
  Typography,
  FormGroup,
  TextField,
  Button,
  ThemeProvider,
  IconButton,
  CardActions
} from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { createTheme, Card } from '@mui/material';
import '../styles/login.css';
import { useAuthUser } from '../hooks/useAuthUser';
import { reset } from '../slices/forgotPassword/forgotPasswordSlice';

export const LoginPage = () => {
  const { signIn } = useAuthUser();
  const iconTheme = createTheme({
    status: {
      danger: '#e53e3e'
    },
    palette: {
      primary: {
        main: '#00609C',
        darker: '#053e85'
      },
      neutral: {
        main: '#00D487',
        contrastText: '#fff'
      }
    }
  });

  const [formData, setFormData] = useState({
    email: '',
    password: ''
  });

  const { email, password } = formData;

  const navigate = useNavigate();
  const dispatch = useDispatch();

  // const { user, isLoading, isError, isSuccess, message } = useSelector(
  //   (state) => state.auth
  // );

  const { user } = useSelector((state) => state.user);
  const login = useSelector((state) => state.login);

  // useEffect(() => {
  //   if (isError) {
  //     alert(message);
  //   }

  //   if (isSuccess || user) {
  //     navigate('/');
  //   }

  //   dispatch(reset());
  // }, [user, isError, isSuccess, message, navigate, dispatch]);

  useEffect(() => {
    if (user?.token) {
      navigate('/');
    }

    if (user?.temporalPassword) {
      console.log(user.temporalPassword);
      navigate('/new-password');
    }
  }, [user, navigate]);

  const onChange = (e) => {
    setFormData((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value
    }));
  };

  // const onSubmit = (e) => {
  //   e.preventDefault();

  //   const userData = {
  //     email,
  //     password
  //   };

  //   dispatch(logIn(userData));
  // };

  //COGNITO AUTENTICATION:
  const onSubmit = (e) => {
    e.preventDefault();
    signIn(email, password);
    // newPassword();
  };
  //Show Password
  const [values, setValues] = useState({
    showPassword: false
  });

  const handleClickShowPassword = () => {
    setValues({
      ...values,
      showPassword: !values.showPassword
    });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const onClickForgotPassword = () => {
    dispatch(reset())
    navigate('/forgot-password');
  };

  return (
    <ThemeProvider theme={iconTheme}>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          width: '100%',
          height: '100vh',
          alignItems: 'center',
          justifyContent: 'center',
          verticalAlign: 'center',
          background: '#f9f9f9'
        }}
      >
       
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              width: '350px',
              height: '90%',
              justifyContent: 'center',
              alignItems:'center',
            }}
          >
            
            <img
              src="/assets/SofipayLogo.png"
              alt="LogoSofipay"
              width={'250px'}
              style={{ marginBottom: 30 }}
            />
            {/* <Typography variant="h4" gutterBottom color="primary" sx={{textAlign: 'center'}}>
              Iniciar Sesion
            </Typography> */}
            <Typography variant="h5" gutterBottom color="primary">
            Iniciar Sesión
            </Typography>
            <form onSubmit={onSubmit} style={{width: '100%'}}>
                <FormGroup sx={{ gap: 3 }}>
                  <TextField
                    error={login.userError ? true : false}
                    helperText={login.userError ? login.helperText : ''}
                    label="Usuario"
                    color="neutral"
                    focused
                    id="email"
                    name="email"
                    value={email}
                    placeholder="Ingresa tu usuario"
                    onChange={onChange}
                  />
                  <TextField
                    error={login.passwordError ? true : false}
                    helperText={login.passwordError ? login.helperText : ''}
                    label="Contraseña"
                    color="neutral"
                    focused
                    type={values.showPassword ? 'text' : 'password'}
                    id="password"
                    name="password"
                    value={password}
                    placeholder="Ingresa tu contraseña"
                    onChange={onChange}
                    InputProps={{
                      endAdornment: (
                        <IconButton
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                        >
                          {values.showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      )
                    }}
                  />
                  <Button
                    variant="contained"
                    type="submit"
                    color="neutral"
                    sx={{ width: '90%', translate: '5%' }}
                  >
                    Ingresar
                  </Button>
                </FormGroup>
            </form>
            <Button size="small" color="neutral" onClick={onClickForgotPassword} style={{marginTop: 10}}>
              Olvidé mi contraseña
            </Button>
          </Box>
          
      </Box>
    </ThemeProvider>
  );
};
