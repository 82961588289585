import { API } from "aws-amplify";
import { loading, tableLoading } from "../ui/uiSlice";
import { setMonitoreoERP } from "./monitoreoSlice";

export const getMonitoreoERP = (dFecha = "", nIdTipoInterfaz = 0) => {
  return async (dispatch) => {
    const params = {
      headers: {},
      response: false,
      queryStringParameters: {dFecha, nIdTipoInterfaz},
    };

    let monitoreoERP = []
    try {
      dispatch(tableLoading(true));
      monitoreoERP = await API.get("monitoreo", "/monitoreo/interfaz-erp", params);
        dispatch(
          setMonitoreoERP([...monitoreoERP])
        );
    } catch (error) {
      console.log("ocurrio un error " + error);
    } finally {
      dispatch(tableLoading(false));
    }
    return monitoreoERP
  };
};

export const postMonitoreoERP = (nIdTipoInterfaz = 0) => {
  return async (dispatch) => {
    const params = {
      headers: {},
      response: false,
      queryStringParameters: {nIdTipoInterfaz},
    };

    let data = {ok: false, msg: "Ocurrió un error inesperado"}
    try {
      dispatch(loading(true));
      const response = await API.post("monitoreo", "/monitoreo/interfaz-erp", params);
      console.log(response)
      data = { ok: response.nCodigo === 0, msg: response.sMensaje}  
    } catch (error) {
      console.log("ocurrio un error " + error);
    } finally {
      dispatch(loading(false));
    }
    return data
  };
};
