import { DeleteOutline, ModeEditOutlineOutlined, PersonSearch, RemoveRedEyeOutlined } from '@mui/icons-material';
import {
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tooltip,
  Typography
} from '@mui/material';
import { Box } from '@mui/system';
import { useDispatch, useSelector } from 'react-redux';
import { getDataById } from '../../helpers/getData';
import { useModal } from '../../hooks/useModal';
import { usePaginacion } from '../../hooks/usePaginacion';
import { deleteContactoCadena } from '../../slices/cadena/cadenaSlice';
import { ModalBase } from './ModalBase';

import PropTypes from 'prop-types';
import { typesSave } from '../../helpers/types';
import { openContact } from '../../slices/ui/uiSlice';
import { useState } from 'react';

export const ModalListContact = ({ saveIn }) => {
  const dispatch = useDispatch();
  const { isOpenListContact, closeModalListContact, isOpenListContactButtons } = useModal();
  const { page, rowsPerPage, getPaginacionTable } = usePaginacion();
  const [showConfirmDialog, setShowConfirmDialog] = useState({
    open: false,
    selectedItem: 0
  })
  const { procesos } = useSelector((state) => state.catalogo);
  const { contactos = [] } = useSelector((state) =>
    typesSave.CADENA === saveIn ? state.cadena : state.efr
  );

  const handleOpenConfirmDialog = (selectedItem) => {
    setShowConfirmDialog({
      open: true,
      selectedItem
    })
  }

  const handleCloseConfirmDialog = () => {
    setShowConfirmDialog({
      open: false,
      selectedItem: 0
    })
  }

  return (<>
    <Dialog
      open={showConfirmDialog.open}
      onClose={handleCloseConfirmDialog}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        {"¿Estas seguro/a de eliminar este registro? "}
      </DialogTitle>
      <DialogActions>
        <Button onClick={handleCloseConfirmDialog}>Cancelar</Button>
        <Button onClick={() => {
          dispatch(deleteContactoCadena(showConfirmDialog.selectedItem))
          handleCloseConfirmDialog()
        }} variant="contained">
          Quiero eliminar este registro
        </Button>
      </DialogActions>
    </Dialog>
    <ModalBase
      open={isOpenListContact}
      title=""
      style={{ width: '1050px', height: '450px' }}
    >
      <Box
        sx={{
          width: '100%',
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between'
        }}
      >
        <TitleModal title="Contactos" typeOperation="Ver" />
        <div
          style={{
            height: '60%',
            display: 'block',
            overflowY: 'auto',
            whiteSpace: 'nonwrap'
          }}
        >
          <Table sx={{ width: '100%' }} aria-label="simple table">
            <TableHead>
              <TableRow
                sx={{
                  '&:last-child td, &:last-child th': {
                    borderBottom: '2px solid #f1f1f1'
                  }
                }}
              >
                <TableCell align="left" style={{ fontSize: '16px', fontWeight: '500', color: '#5F5F5F' }}>
                  Nombre
                </TableCell>
                <TableCell align="left" style={{ fontSize: '16px', fontWeight: '500', color: '#5F5F5F' }}>
                  Apellido paterno
                </TableCell>
                <TableCell align="left" style={{ fontSize: '16px', fontWeight: '500', color: '#5F5F5F' }}>
                  Apellido materno
                </TableCell>
                <TableCell align="left" style={{ fontSize: '16px', fontWeight: '500', color: '#5F5F5F' }}>
                  Teléfono
                </TableCell>
                <TableCell align="center" style={{ fontSize: '16px', fontWeight: '500', color: '#5F5F5F' }}>
                  Correo
                </TableCell>
                <TableCell align="left" style={{ fontSize: '16px', fontWeight: '500', color: '#5F5F5F' }}>
                  Proceso(s)
                </TableCell>
                <TableCell align="left"></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {contactos
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((contacto, i) => (
                  <TableRow
                    key={i + contacto.email}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  >
                    <TableCell align="left" style={{ fontSize: '14px' }}>
                      {contacto.nombre}
                    </TableCell>
                    <TableCell align="left" style={{ fontSize: '14px' }}>
                      {contacto.apellido}
                    </TableCell>
                    <TableCell align="left" style={{ fontSize: '14px' }}>
                      {contacto.segundoApellido}
                    </TableCell>
                    <TableCell align="left" style={{ fontSize: '14px' }}>
                      {contacto.telefono}
                    </TableCell>
                    <TableCell align="left" style={{ fontSize: '14px' }}>
                      {contacto.email}
                    </TableCell>
                    <TableCell align="left" style={{ fontSize: '14px' }}>
                      {contacto.proceso?.map((item) => item.sDescripcion).join(", ")}
                      {/* {getDataById(contacto.proceso, procesos)} */}
                    </TableCell>
                    <TableCell align="left">
                      <div style={{ display: 'flex' }}>
                        
                        {isOpenListContactButtons ?
                          <>
                            <RenderUpdate contact={contacto} />
                            <RenderDelete contact={contacto} handleOpenConfirmDialog={handleOpenConfirmDialog} />
                          </>
                          :
                          <RenderView contact={contacto} />
                        }
                      </div>
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </div>
        {getPaginacionTable(contactos.length)}
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            width: '100%',
            justifyContent: 'end'
          }}
        >
          <Button onClick={closeModalListContact} sx={{ color: '#00609C' }}>
            cerrar
          </Button>
        </div>
      </Box>
    </ModalBase>
  </>);
};

const RenderDelete = ({ contact, handleOpenConfirmDialog }) => {
  const onClick = () => {
    handleOpenConfirmDialog(contact.email);
  };
  return (
    <>
      <Tooltip title="Eliminar" arrow>
        <IconButton onClick={onClick}>
          <DeleteOutline sx={{ color: '#828282' }} />
        </IconButton>
      </Tooltip>
    </>
  );
};

const RenderView = ({ contact }) => {

  const dispatch = useDispatch();

  const onClick = () => {
    dispatch(openContact({ edit: true, view: true, contact }));
  }

  return (
    <Tooltip title="Ver" arrow>
      <IconButton onClick={onClick}>
        <RemoveRedEyeOutlined sx={{ color: '#828282' }} />
      </IconButton>
    </Tooltip>
  );
}

const RenderUpdate = ({ contact }) => {

  const dispatch = useDispatch();

  const onClick = () => {
    dispatch(openContact({ edit: true, contact }));
  }

  return (
    <Tooltip title="Editar" arrow>
      <IconButton onClick={onClick}>
        <ModeEditOutlineOutlined sx={{ color: '#828282' }} />
      </IconButton>
    </Tooltip>
  );
}

const TitleModal = ({ title, typeOperation }) => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        width: '100%',
        marginBottom: '10px',
        justifyContent: 'space-between'
      }}
    >
      <div style={{ display: 'flex', flexDirection: 'row' }}>
        <PersonSearch fontSize={'large'} sx={{ color: '#00CD68', fontSize: '45px' }} />
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <Typography
            variant="p"
            sx={{
              marginLeft: '16px',
              fontSize: '14px',
              marginBottom: '4px'
            }}
          >
            {typeOperation}
          </Typography>
          <Typography
            variant="p"
            sx={{
              marginLeft: '16px',
              color: '#00609C',
              fontSize: '20px'
            }}
          >
            {title}
          </Typography>
        </div>
      </div>
    </Box>
  );
};

ModalListContact.propTypes = {
  saveIn: PropTypes.string
};
