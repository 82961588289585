import { Edit, Save, Check, Close } from '@mui/icons-material';

import {
  Box,
  Grid,
  IconButton,
  Paper,
  Tooltip,
  Typography
} from '@mui/material';
import { useEffect, useState } from 'react';
import { useAuthPage } from '../../../hooks/useAuthPage';

export const CardConfirmation = ({
  title,
  md = 12,
  sm = 12,
  xs = 12,
  children,
  spacingChildre = 1,
  id,
  // cadena,
  isOnEdit,
  name,
  setSeccionEdit,
  setSectionCompleted,
  onEditSection,
  isOnEditSection
}) => {
  return (
    <Grid item md={md} sm={sm} xs={xs}>
      <Paper elevation={2} style={{ padding: '10px', borderRadius: '7px' }} id={id}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            borderBottom: '2px solid #f1f1f1',
            justifyContent: 'space-between',
            alignItems: 'center',
            marginBottom: '5px'
          }}
        >
          <Typography
            variant="p"
            color="#00CD68"
            style={{
              fontWeight: '400',
              fontSize: '18px',
              width: '80%'
              // textTransform: 'capitalize'
            }}
          >
            {title}
          </Typography>
          <Box
            sx={{
              display: 'flex',
              width: '5%',
              justifyContent: 'right',
              alignItems: 'center'
            }}
            className={!isOnEditSection && 'opacity'}
          >
            <Tooltip title={isOnEditSection ? 'Guardar' : 'Editar'} arrow>
              <IconButton
                size="small"
                sx={{ display: isOnEdit ? '' : 'none' }}
                onClick={
                  isOnEditSection
                    ? () => setSectionCompleted(name)
                    : () => setSeccionEdit(name)
                }
              >
                {isOnEditSection ? <Save sx={{ color: '#00609C' }} /> : <Edit />}
              </IconButton>
            </Tooltip>
          </Box>
        </Box>
        {/* <Divider /> */}
        <Box
          sx={{
            width: '100%',
            padding: '2px',
            overflow: 'hidden'
          }}
        >
          <Grid
            container
            spacing={spacingChildre}
            style={{
              width: '99%',
              height: '99%',
              margin: '0px auto'
            }}
          >
            {children}
          </Grid>
        </Box>
      </Paper>
    </Grid>
  );
};

export const CardConfirmationEFR = ({
  title,
  md = 12,
  sm = 12,
  xs = 12,
  children,
  spacingChildre = 1,
  id,
  editName,
  isEditSection,
  setIsEditSection,
  viewBttnEdit = true,
  viewBttnConfirm = false,
  tipoPermisoEditar = 'EFR',
  isConfirmSection,
  setIsConfirmSection
}) => {
  const onClick = () => {
    setIsEditSection &&
      setIsEditSection({ section: editName, saveSection: isEditSection });
  }

  const confirm = () => {
    setIsConfirmSection &&
      setIsConfirmSection(editName);
  }
  const { setAllowComponent } = useAuthPage();
  const [activeEditar, setActiveEditar] = useState(false);
  const [activeConfirmar, setActiveConfirmar] = useState(true);

  useEffect(() => {
    setActiveEditar((setAllowComponent(`editar${tipoPermisoEditar}`)))
    setActiveConfirmar(viewBttnConfirm)
    // eslint-disable-next-line
  }, [])



  return (
    <Grid item md={md} sm={sm} xs={xs}>
      <Paper elevation={2} style={{ padding: '10px', borderRadius: '7px' }} id={id}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            borderBottom: '2px solid #f1f1f1',
            justifyContent: 'space-between',
            alignItems: 'center',
            marginBottom: '5px'
          }}
        >
          <Typography
            variant="p"
            color="#00CD68"
            style={{
              fontWeight: '400',
              fontSize: '18px',
              width: '80%'
              // textTransform: 'capitalize'
            }}
          >
            {title}
          </Typography>
          <Box
            sx={{
              display: activeEditar || activeConfirmar ? 'flex' : 'none',
              width: '50%',
              justifyContent: 'right',
              alignItems: 'center',
            }}
          >
            {(viewBttnEdit && (!viewBttnConfirm || viewBttnConfirm && !isConfirmSection)) && (
              <Tooltip arrow title={!isEditSection ? 'Editar' : 'Guardar'}>
                <IconButton size="small" onClick={onClick}>
                  {isEditSection ? <Save sx={{ color: '#00609C' }} /> : <Edit />}
                </IconButton>
              </Tooltip>
            )}
            {(viewBttnConfirm && isConfirmSection) && (<Grid sx={{background: "#00609C", color: "white", fontSize: 13, fontWeight: 500, padding: "5px", borderRadius: "5px"}}>Confirmado</Grid>)}
            {(viewBttnConfirm && !isEditSection) && (
              <Tooltip arrow title={isConfirmSection ? 'Cancelar' : 'Confirmar datos'}>
                <IconButton size="small" onClick={confirm}>
                  {isConfirmSection ? <Close sx={{ color: '#00609C' }} /> : <Check />}
                </IconButton>
              </Tooltip>
            )}
          </Box>
        </Box>
        <Box
          sx={{
            width: '100%',
            padding: '2px',
            overflow: 'hidden'
          }}
        >
          <Grid
            container
            spacing={spacingChildre}
            style={{
              width: '99%',
              height: '99%',
              margin: '0px auto'
            }}
          >
            {children}
          </Grid>
        </Box>
      </Paper>
    </Grid>
  );
};
