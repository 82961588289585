import * as React from 'react';
import { styled } from '@mui/material/styles';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import { Add } from '@mui/icons-material';
import { Badge, Grid, Tooltip } from '@mui/material';

const AntTabs = styled((props) => (
  <Tabs
    {...props}
    TabIndicatorProps={{ children: <span className="MuiTabs-indicatorSpan" /> }}
  />
))({
  position: "relative",
  '& .MuiTabs-indicator': {
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: 'transparent',
  },
  '& .MuiTabs-indicatorSpan': {
    position: "absolute",
    top: 0,
    height: 20,
    maxWidth: 40,
    width: '100%',
  },
});

const AntTab = styled((props) => <Tab disableRipple {...props} />)(({ theme }) => ({
  textTransform: 'none',
  minWidth: 0,
  minHeight: 50,
  borderTopLeftRadius: 5,
  borderTopRightRadius: 5,
  paddingLeft: 20,
  paddingRight: 20,
  marginRight: 3,
  borderTop: `2px solid #f4f4f4`,
  boxShadow: "inset 1px -5px 10px -5px rgba(0,0,0,0.2)",
  transitionDuration: "200ms",
  background: "#f4f4f4",
  ' span.innerText': {
    display: "none"
  },
  '&.Mui-selected': {
    background: "#F9F9F9",
    borderTop: `2px solid #00609C`,
    paddingLeft: 20,
    paddingRight: 20,
    boxShadow: "none",
  },
  '&.Mui-selected span': {
    marginLeft: 5,
    display: "flex"
  },
  ' .MuiBadge-badge': {
    top: "-3px",
    right: "-3px",
  }
  /* '&.Mui-selected .circle': {
    display: "none"
  } */
}));


export default function CustomizedTabs({ tabs = [], onChange, value }) {
  const handleChange = (event, newValue) => {
    onChange(newValue);
  };

  return (
    <Box sx={{ width: '100%' }}>
      <AntTabs value={value} onChange={handleChange}>
        {tabs.map(tab => (
          <AntTab
            label={<Badge badgeContent={tab.count ? tab.count : 0} color="primary">{
              tab.icon ?


                <Tooltip title={tab.label}>
                  <Grid container alignItems={"center"}>

                    {tab.hasNotification && <div style={{ width: 20, height: 20, borderRadius: "50%", background: "#00609C" }}>d</div>}
                    {tab.icon}

                    <span className='innerText'>{tab.label}</span>
                  </Grid>
                </Tooltip>
                :
                <Grid container alignItems={"center"}>

                  {tab.hasNotification && <div style={{ width: 10, height: 10, borderRadius: "50%", background: "#00609C", marginRight: 5 }} className='circle'></div>}
                  <div>{tab.label}</div>
                </Grid>
            }

            </Badge>}
            value={tab.value} />
        ))}


      </AntTabs>
    </Box>
  );
}