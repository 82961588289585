import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { setPage, allowPage, denyPage } from '../slices/authPage/authPageSlice';
import { setCatalogoLoaded } from '../slices/catalogo/catalagoSlice';

export const useAuthPage = () => {
  const dispatch = useDispatch();
  const { permisos } = useSelector((state) => state.user);
  const { isAllowed, page } = useSelector((state) => state.authPage);
  useEffect(() => {
    setAllowPage(page);
  }, [permisos]);

  const setNewPage = (page) => {
    dispatch(setPage(page));
    setAllowPage(page);
  };

  const setAllowPage = (page) => {
    let parentPermisos = permisos?.find((permiso) => permiso.nombre === page);
    
    parentPermisos ? dispatch(allowPage()) : dispatch(denyPage());
  };

  const setAllowComponent = (section, subsection = null) => {
    let parentPermisos = permisos?.find((permiso) => permiso.nombre === page);

    if (parentPermisos) {
      let childrenPermisos = parentPermisos.children?.find(
        (permiso) => permiso.nombre === section
      );
      
      if (subsection && childrenPermisos) {
        const subPermisos = JSON.parse(childrenPermisos.children)?.find(
          (permiso) => permiso.nombre === subsection
        )
        return !!subPermisos;
      }
      return !!childrenPermisos;
    } else {
      return false;
    }
  };

  const isAllowedPage = (page) => {
    let parentPermisos = permisos?.find((permiso) => permiso.nombre === page);

    return !!parentPermisos;
  };

  const getChildrenPermissions = () => {
    let parentPermisos = permisos?.find((permiso) => permiso.nombre === page);

    return parentPermisos ? parentPermisos.children : []
  };

  const setDisplaySection = (section) => {
    let parentPermisos = permisos?.filter((permiso) => permiso.nombre === section);
    if (parentPermisos.length) {
      return 'flex';
    }
    return 'none';
  };

  const setDisplayAccordion = (secciones) => {
    let parentPermisos = [];
    let flag = false;
    secciones?.map((seccion) => {
      parentPermisos = permisos?.filter((permiso) => permiso.nombre === seccion);
      if (!flag) flag = parentPermisos.length > 0;
    });
    return flag ? 'flex' : 'none';
  };

  const setLoaded = (value) => {
    dispatch(setCatalogoLoaded(value));
  };

  return {
    setNewPage,
    setAllowPage,
    isAllowed,
    permisos,
    page,
    setDisplaySection,
    setDisplayAccordion,
    setAllowComponent,
    setLoaded,
    getChildrenPermissions,
    isAllowedPage
  };
};
