import { useNavigate, useLocation } from 'react-router-dom';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  List,
  ListItem,
  ListItemButton,
  Typography
} from '@mui/material';
import { createTheme } from '@mui/material';
import { ThemeProvider } from '@mui/material';

import { useAuthPage } from '../../../hooks/useAuthPage';

import { menu } from './menu.js';
import '../../../styles/accordion.css';
import { useState } from 'react';
import { useSelector } from 'react-redux';

const iconTheme = createTheme({
  status: {
    danger: '#e53e3e'
  },
  palette: {
    primary: {
      main: '#00609C',
      darker: '#053e85'
    },
    neutral: {
      main: '#00CD68',
      contrastText: '#fff'
    }
  }
});

export default function MenuDrawer(props) {
  const { handleDrawerToggle } = props;
  const location = useLocation();
  const { setDisplaySection, setDisplayAccordion, setLoaded } = useAuthPage();
  const { page } = useSelector((state) => state.authPage);
  let navigate = useNavigate();
  const [expandedAccordion, setExpandedAccordion] = useState('');
  const [closeCurrentAccordion, setCloseCurrentAccordion] = useState(false);

  const redirect = (path) => {
    navigate(path);
  };

  const onClick = (item) => {
    setExpandedAccordion('');
    if (location.pathname === item.route) {
    } else {
      redirect(item.route);
      setLoaded(false);
    }
    handleDrawerToggle();
  };

  const getChildrenSectionName = (item) => {
    let innerArray = [];

    item.children.forEach((child) => {
      innerArray.push(child.sectionName);
    });
    return innerArray;
  };

  const accordionIsExpanded = (item) => {
    let children = getChildrenSectionName(item);
    if (!closeCurrentAccordion) {
      if (children.includes(page)) return true;
    }
    if (expandedAccordion === item.title) return true;

    return false;
  };

  const setAccordionIsExpanded = (item) => {
    let children = getChildrenSectionName(item);
    if (children.includes(page) && !closeCurrentAccordion) {
      setCloseCurrentAccordion(true);
    } else if (expandedAccordion === item.title) {
      setExpandedAccordion('');
    } else {
      setExpandedAccordion(item.title);
    }
  };

  return (

    <ThemeProvider theme={iconTheme}>
      <List sx={{ width: '160px', marginTop: 1, justifyContent: "flex-start", pr: 0, pl: 1 }}>
        {menu.map((item) =>
          item.children && item.children.length > 0 ? (
            <ListItem
              sx={{
                display: `${setDisplayAccordion(getChildrenSectionName(item))}`,
                width: '100%',
                flexDirection: 'column',
                pr: 0
              }}
              key={item.key}
            >
              <Accordion
                variant="outlined"
                sx={{
                  flexDirection: 'column',
                  width: '100%'
                }}
                expanded={accordionIsExpanded(item)}
                onChange={() => setAccordionIsExpanded(item)}
              >
                <AccordionSummary
                  sx={{
                    padding: '0px 5px'
                  }}
                >
                  {item.icon}
                  <Typography color="primary" sx={{ fontWeight: 500 }}>
                    {item.title}
                  </Typography>
                </AccordionSummary>
                <AccordionDetails sx={{ padding: 0, marginLeft: 1 }}>
                  {item.children.map((child) => (
                    <ListItemButton
                      sx={{
                        display: `${setDisplaySection(child.sectionName)}`,
                        background: page === child.sectionName ? '#f1f1f1' : 'none',
                        pl: 1,
                      }}
                      onClick={() => onClick(child)}
                      key={child.key}
                      className={
                        page === child.sectionName
                          ? 'menu-item-btn selected'
                          : 'menu-item-btn'
                      }
                    >
                      {child.icon}
                      <Typography
                        color="primary"
                        sx={{ marginLeft: '5px', fontWeight: 500, fontSize: 15 }}
                      >
                        {child.title}
                      </Typography>
                    </ListItemButton>
                  ))}
                </AccordionDetails>
              </Accordion>
            </ListItem>
          ) : (
            <ListItem
              sx={{
                  display: `${setDisplaySection(item.sectionName)} `,
                  pl: "5px",
                  pr: 0
              }}
              key={item.key}
            >
              <ListItemButton
                onClick={() => onClick(item)}
                className={
                  page === item.sectionName ? 'menu-item-btn selected' : 'menu-item-btn'
                }
              >
                {item.icon}
                <Typography color="primary" sx={{ fontWeight: 500 }}>
                  {item.title}
                </Typography>
              </ListItemButton>
            </ListItem>
          )
        )}
      </List>
    </ThemeProvider>
  );
}
