import { useEffect, useState } from "react";
import { Button, Grid, InputAdornment, TextField, Tooltip, Typography } from "@mui/material";
import moment from "moment";
import { SearchInput } from "../../../components/form/SearchInput";
import { SelectInput } from "../../../components/form/SelectInput";
import { Add, Delete, Edit, Check} from '@mui/icons-material';
import DescriptionIcon from '@mui/icons-material/Description';
import { regexEmail } from "../../../helpers/regexs";
import { TextInput } from "../../../components/form/TextInput";
import { useModal } from "../../../hooks/useModal";



export const ExpandedItemFacturas = (props) => {
  const {
    row,
    id,
    cfdis,
    formasPago,
    metodosPago,
    regimensFiscal,
    unidades,
    productoServicios,
    authorizeItem,
    disableAuthorizeButton,
    allowedEditAuthorize
  } = props

  const [edit, setEdit] = useState(false)
  const [changedRow, setChangedRow] = useState(row)
  const [email, setEmail] = useState("")
  const [isChanged, setIsChanged] = useState(false)

  const {
    setOpenToast
  } = useModal()

  useEffect(() => {
    setChangedRow(row)
  }, [row])


  const onChange = (e) => {
    console.log(e)
    const inputName = e.target.name;
    const inputValue = e.target.value;

    const priceValues = ["nImporte", "nIva", "nTotal"]

    let valueForm = { ...changedRow };
    if (priceValues.includes(inputName)) {
      const ivaDecimal = 0.16
      let nImporte = 0
      let nIva = 0
      let nTotal = 0

      switch (inputName) {
        case "nTotal":
          nTotal = parseFloat(inputValue);
          nIva = (roundNumber(inputValue - (inputValue / (1 + ivaDecimal))))
          nImporte = (roundNumber(inputValue / (1 + ivaDecimal)));
          break;

        case "nIva":
          nTotal = roundNumber((inputValue / ivaDecimal) + parseFloat(inputValue));
          nIva = (parseFloat(inputValue));
          nImporte = (roundNumber(inputValue / ivaDecimal));
          break;

        case "nImporte":
          nTotal = roundNumber(inputValue * (1 + ivaDecimal));
          nIva = (roundNumber(nTotal - inputValue));
          nImporte = (parseFloat(inputValue));
          break;
        default:
          break;
      }

      valueForm = { ...valueForm, nTotal, nIva, nImporte, changed: true }
    } else {
      valueForm = { ...valueForm, [inputName]: inputValue, changed: true }
    }

    setChangedRow(valueForm);
    setIsChanged(true)
    console.log(valueForm);
  };

  const roundNumber = (number) => {
    return (Number((number ? parseFloat(number) : 0).toFixed(2)))
  }

  const addEmail = () => {
    let currentEmails = changedRow.sCorreoDestino.split(",").map((mail) => { return (mail.trim()) })
    const emailExists = currentEmails.some((mail) => mail === email.trim())
    if (!emailExists) {
      currentEmails.push(email)
      setEmail("")
      setChangedRow({ ...changedRow, sCorreoDestino: currentEmails.join(","), changed: true });
      setIsChanged(true)
    } else {
      setOpenToast(true, "El correo ya existe")
    }
  }

  const deleteEmail = (email) => {
    let currentEmails = changedRow.sCorreoDestino.split(",").filter((mail) => mail.trim() !== email.trim())

    setChangedRow({ ...changedRow, sCorreoDestino: currentEmails.join(","), changed: true });
    setIsChanged(true)
  }

  return (
    <Grid container spacing={3} alignItems={"flex-start"} sx={{ py: 2, width: "100%" }}>
      <Grid item sm={12} xs={12} container justifyContent={"space-between"}>

        <Grid item >
          <Typography
            color="inherit"
            variant="h6"
            component="div"
          >
            <DescriptionIcon color="neutral" style={{ fontSize: 30 }} />
            {row.sNombreComercial} <b style={{ fontSize: 14 }}>({moment(row.dFechaInicial).format("YYYY-MM-DD") + " a " + moment(row.dFechaFinal).format("YYYY-MM-DD")})</b>
          </Typography>
        </Grid>
        {row.nIdEstatusFacturacion !== 2 && allowedEditAuthorize ?
          <Grid item style={{ textAlign: "right" }}>
            {!disableAuthorizeButton ?
              <>
                <Button
                  //size="small"
                  color={!edit ? "neutral" : "primary"}
                  variant={!edit ? "contained" : "text"}
                  sx={{ mr: 1 }}
                  startIcon={!edit ? <Edit /> : <></>}
                  onClick={() => {
                    if (edit) {
                      setChangedRow(row)
                    }
                    setEdit(!edit)
                    setIsChanged(false)
                  }}
                >
                  {!edit ? "Editar" : "Cancelar"}
                </Button>
                {
                  !changedRow.authorized || isChanged ?
                    <Button
                      //size="small"
                      variant="contained"
                      startIcon={<Check />}
                      onClick={() => {
                        authorizeItem(changedRow)
                        setIsChanged(false)
                      }}
                    >
                      {!isChanged ? "Autorizar" : "Guardar cambios y autorizar"}
                    </Button>
                    :
                    <></>
                }
              </>
              :
              <Grid style={{ display: "inline-block", background: '#EDE35790', padding: "4px 8px", borderRadius: "4px" }}>Guarde las facturas para continuar</Grid>
            }
          </Grid>
          : <Grid item></Grid>}
      </Grid>

      <Grid item xs={12} container spacing={3} alignItems={"flex-start"} sx={{ py: 2, width: "50%" }}>
        <Grid item sm={4} xs={12} container spacing={2}>
          <Grid item sm={12} xs={12}>
            <b style={{ fontWeight: 500 }}>Datos fiscales:</b>
          </Grid>
          <Grid item sm={12} xs={12}>
            <SelectInput
              id="sUsoCFDI"
              name="sUsoCFDI"
              onChange={onChange}
              label="Uso del CFDI"
              data={cfdis}
              size={"small"}
              value={changedRow.sUsoCFDI}
              sxSelect={{ fontSize: 14 }}
              disabled={!edit}
            />

          </Grid>
          <Grid item sm={12} xs={12}>
            <SelectInput
              id="sFormaPago"
              name="sFormaPago"
              onChange={onChange}
              label="Forma de pago"
              data={formasPago}
              size={"small"}
              value={changedRow.sFormaPago}
              sxSelect={{ fontSize: 14 }}
              disabled={!edit}
            />
          </Grid>
          <Grid item sm={12} xs={12}>
            <SelectInput
              id="sMetodoPago"
              name="sMetodoPago"
              onChange={onChange}
              label="Método de pago"
              data={metodosPago}
              size={"small"}
              value={changedRow.sMetodoPago}
              sxSelect={{ fontSize: 14 }}
              disabled={!edit}
            />
          </Grid>
          <Grid item sm={12} xs={12}>
            <SearchInput
              options={productoServicios}
              value={changedRow.sClaveProductoServicio}
              name="sClaveProductoServicio"
              label="Clave del producto"
              getOptionLabel="text"
              getIndexLabel="value"
              size={"small"}
              onChange={onChange}
              sx={{ fontSize: 14 }}
              disabled={!edit}
            />
          </Grid>
          <Grid item sm={12} xs={12}>
            <SearchInput
              options={unidades}
              value={changedRow.sUnidad}
              name="sUnidad"
              label="Clave de unidad"
              getOptionLabel="text"
              getIndexLabel="value"
              size={"small"}
              onChange={onChange}
              sx={{ fontSize: 14 }}
              disabled={!edit}
            />
          </Grid>
        </Grid>
        <Grid item sm={3} xs={12} container spacing={2}>
          <Grid item sm={12} xs={12}>
            <b style={{ fontWeight: 500 }}>Datos del corte:</b>
          </Grid>
          <Grid item sm={12} xs={12}>
            <TextField
              id={"nNumOperaciones"}
              name={"nNumOperaciones"}
              label={"Núm. de operaciones"}
              variant={"outlined"}
              size={"small"}
              value={changedRow.nNumOperaciones}
              type={"number"}
              onChange={(e) => onChange(e, "value")}
              fullWidth
              disabled={!edit}
              InputProps={{
                inputProps: { min: 0 },
                style: { fontSize: 14 },
              }}
            />
          </Grid>
          <Grid item sm={12} xs={12}>
            <TextField
              id={"nImporte"}
              name={"nImporte"}
              label={"Importe"}
              variant={"outlined"}
              size={"small"}
              value={changedRow.nImporte}
              type={"number"}
              onChange={(e) => onChange(e, "value")}
              fullWidth
              disabled={!edit}
              InputProps={{
                inputProps: { min: 0 },
                style: { fontSize: 14 },
                startAdornment: <InputAdornment position="start">$</InputAdornment>
              }}
            />
          </Grid>
          <Grid item sm={12} xs={12}>
            <TextField
              id={"nIva"}
              name={"nIva"}
              label={"IVA"}
              variant={"outlined"}
              size={"small"}
              value={changedRow.nIva}
              type={"number"}
              onChange={(e) => onChange(e, "value")}
              fullWidth
              disabled={!edit}
              InputProps={{
                inputProps: { min: 0 },
                style: { fontSize: 14 },
                startAdornment: <InputAdornment position="start">$</InputAdornment>
              }}
            />
          </Grid>
          <Grid item sm={12} xs={12}>
            <TextField
              id={"nTotal"}
              name={"nTotal"}
              label={"Total"}
              variant={"outlined"}
              size={"small"}
              value={parseFloat(changedRow.nTotal?.toString().replace("$", "").replace(",", ""))}
              type={"number"}
              onChange={(e) => onChange(e, "value")}
              fullWidth
              disabled={!edit}
              InputProps={{
                inputProps: { min: 0 },
                style: { fontSize: 14 },
                startAdornment: <InputAdornment position="start">$</InputAdornment>
              }}
            />
          </Grid>
        </Grid>
        <Grid item sm={4} xs={12} container spacing={2}>
          <Grid item sm={12} xs={12}>
            <b style={{ fontWeight: 500 }}>Correo(s) destino:</b>
          </Grid>
          {edit &&
            <Grid item sm={12} xs={12} container>
              <Grid item sm={10} xs={12}>
                <TextInput
                  label={"Agregar correo:"}
                  variant={"outlined"}
                  size={"small"}
                  value={email}
                  name={"email"}
                  type={"email"}
                  disabled={changedRow.sCorreoDestino.split(",").length >= 3}
                  onChangeRoot={(e) => {
                    setEmail(e.email)
                  }}
                  InputProps={{
                    style: { fontSize: 14 },
                  }}
                  expresionRegular={regexEmail}
                  erroMsg={"Correo inválido"}
                  fullWidth
                />
              </Grid>
              <Grid item sm={2} xs={12}>
                <Tooltip title="Agregar" placement="bottom" arrow>
                  <Button
                    variant="outlined"
                    fullWidth
                    disabled={changedRow.sCorreoDestino.split(",").length >= 3}
                    onClick={addEmail}
                  >
                    <Add />
                  </Button>
                </Tooltip>
              </Grid>
            </Grid>
          }
          {changedRow.sCorreoDestino.split(",").map((correo, key) => {
            return (

              <Grid item sm={12} xs={12} container>
                <Grid item sm={edit ? 10 : 12} xs={12}>
                  <TextField
                    label={"Correo " + (key + 1) + ":"}
                    variant={"outlined"}
                    size={"small"}
                    disabled
                    value={correo.trim()}
                    onChange={(e) => onChange(e, "value")}
                    InputProps={{
                      style: { fontSize: 14 },
                    }}
                    fullWidth
                  />
                </Grid>
                {edit &&
                  <Grid item sm={2} xs={12}>
                    <Tooltip title="Eliminar" placement="bottom" arrow>
                      <Button
                        variant="outlined"
                        fullWidth
                        onClick={() => deleteEmail(correo)}
                        sx={{
                          border: '1px solid #EF4236',
                          color: '#EF4236',
                          '&:hover': {
                            background: '#FFF2F2',
                            border: '1px solid #EF4236'
                          }
                        }}
                      >
                        <Delete />
                      </Button>
                    </Tooltip>
                  </Grid>
                }
              </Grid>
            )
          })}
        </Grid>
      </Grid>
    </Grid>
  )
}
