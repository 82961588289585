import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import authService from './authService';

//user desde localstorage
// const user = JSON.parse(localStorage.getItem('user'));
//user prueba
const user = localStorage.getItem('user');

const initialState = {
  user: user ? JSON.parse(localStorage.getItem('user')) : null,
  isError: false,
  isSuccess: false,
  isLoading: false,
  message: ''
};

//registrar usuario
export const register = createAsyncThunk('auth/register', async (user, thunkAPI) => {
  try {
    return await authService.register(user);
  } catch (error) {
    const message =
      (error.response && error.response.data && error.response.data.message) ||
      error.message ||
      error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

//iniciar sesion
export const logIn = createAsyncThunk('auth/logIn', async (user, thunkAPI) => {
  try {
    return await authService.logIn(user);
  } catch (error) {
    const message =
      (error.response && error.response.data && error.response.data.message) ||
      error.message ||
      error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

export const logOut = createAsyncThunk('auth/logOut', async () => {
  await authService.logOut();
});

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    reset: (state) => {
      state.isLoading = false;
      state.isError = false;
      state.isSuccess = false;
      state.message = '';
    },
    prueba: (state, action) => {
      state.user = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(logIn.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(logIn.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.user = action.payload;
      })
      .addCase(logIn.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        state.user = null;
      })
      .addCase(logOut.fulfilled, (state) => {
        state.user = null;
      });
  }
});

export const { reset, prueba } = authSlice.actions;
