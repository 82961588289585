import * as React from 'react';
import IconButton from '@mui/material/IconButton';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';

import { Outlet } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useEffect } from 'react';
import MenuDrawer from './components/MenuDrawer';
import { useNavigate } from 'react-router-dom';

import { createTheme, Grid, ThemeProvider, Tooltip } from '@mui/material';
import '../../styles/drawerMenuResponsive.css';
import { useAuthUser } from '../../hooks/useAuthUser';
import { useAuthPage } from '../../hooks/useAuthPage';
import { LogoutTwoTone } from '@mui/icons-material';

const iconTheme = createTheme({
  status: {
    danger: '#e53e3e'
  },
  palette: {
    primary: {
      main: '#00609C',
      darker: '#053e85'
    },
    neutral: {
      main: '#00CD68',
      contrastText: '#fff'
    }
  }
});


function Redirect(props) {
  const navigate = useNavigate();
  const { user } = useSelector((state) => state.user);
  // const [loaded, setLoaded] = useState(false)
  const { api } = useAuthUser();
  const { loaded } = useSelector((state) => state.catalogo);
  const { setLoaded } = useAuthPage();

  const { signOut } = useAuthUser();


  // startPages();
  useEffect(() => {
    if (!loaded) {
      let data = localStorage.getItem('userAuth');
      if (data) {
        data = JSON.parse(data);
        api(data.username, data.email, data.token);
      }
      setLoaded(true);
    } else {
      if (!user?.token) {
        navigate('/login');
      }
    }
    // eslint-disable-next-line
  }, [user, navigate, loaded]);


  return (
    <></>
  );
}

export default Redirect;
