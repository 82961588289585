import * as React from "react";
import MobileStepper from "@mui/material/MobileStepper";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { Box } from "@mui/system";
import { ModalBase } from "./ModalBase";
import {
  Button,
  Grid,
  Typography,
  Dialog,
  DialogActions,
  DialogTitle,
  styled,
  Paper,
} from "@mui/material";
import { DropFileInput } from "../form/DropFileInput";
import LinearProgress, {
  linearProgressClasses,
} from "@mui/material/LinearProgress";
import AssignmentTurnedInIcon from '@mui/icons-material/AssignmentTurnedIn';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import { useValidacionFacturasPage } from "../../hooks/useValidacionFacturasPage";

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 20,
  borderRadius: 10,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: "#f8f8f8",
    border: "1px solid #979797",
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 10,
    backgroundColor: "#00CD68",
  },
}));

export const ModalImportInvoices = (props) => {
  const {
    isOpenConfirmDialog,
    setIsOpenConfirmDialog,
    fileData,
    importMethod,
    isOpenImport,
    activeStep,
    handleDrop,
    deleteFile,
    progress,
    log,
    scrollDownRef,
    handleBack,
    confirmDelete,
    handleNext,
    handleCloseImport,
    processMessage,
  } = useValidacionFacturasPage();


  return (
    <>
      <Dialog
        open={isOpenConfirmDialog}
        onClose={() => setIsOpenConfirmDialog(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          Se importarán {fileData.filter(file => !file.nError).length} registro
          {fileData.length > 1 ? "s" : ""} ¿Estas seguro/a?
        </DialogTitle>
        <DialogActions>
          <Button onClick={() => setIsOpenConfirmDialog(false)}>
            Cancelar
          </Button>
          <Button onClick={() => importMethod()} variant="contained">
            Estoy seguro/a
          </Button>
        </DialogActions>
      </Dialog>
      <ModalBase
        open={isOpenImport}
        style={{ width: "1050px" /*, height: "450px" */ }}
      >
        <Box
          sx={{
            width: "100%",
            height: "100%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
          }}
        >
          <Grid container style={{ alignItems: "space-between" }}>
            <Grid container item sm={8} style={{ alignItems: "center" }}>
              <Grid
                item
                style={{
                  color: "#00CD68",
                  padding: 15,
                  fontWeight: "bold",
                }}
              >
                <AssignmentTurnedInIcon fontSize={"large"} />
              </Grid>
              <Grid item>
                <Typography variant="p" color={"neutral"}>
                  Verificación
                </Typography>
                <Typography variant="h5" color={"primary"}>
                  Facturas
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          {activeStep === 0 && (
            <DropFileInput
              handleDrop={handleDrop}
              fileData={fileData}
              deleteFileData={deleteFile}
              acceptedFormat={".xml"}
              multiple
            />
          )}
          {activeStep === 1 && (
            <Grid container spacing={2}>
              <Grid item xs={12} style={{ padding: 20 }}>
                <Typography variant={"h6"} fontSize={18}>Archivos listos para validación:</Typography>
                
                <Grid container style={{
                  width: "100%",
                  background: "#f8f8f8",
                  // border: "1px solid #979797",
                  borderRadius: 8,
                  padding: "20px 15px", alignItems: "flex-start", justifyContent: "flex-start",
                  minHeight: 300,
                  maxHeight: 600,
                  overflowY: "auto"
                }}>
                  {fileData?.filter(file => !file.nError).map(file => {
                    return (
                        <Grid item sm={4} sx={{ padding: 1 }}>
                          <Paper elevation={2}>
                            <Grid container alignItems="center" sx={{ padding: 1 }}>

                              <Grid item style={{ width: 30 }}>
                                <InsertDriveFileIcon color={"neutral"} sx={{ pr: 1 }} />
                              </Grid>
                              <Grid item style={{ width: "calc(100% - 30px)" }}>
                                <Typography variant="p" color={"primary"} style={{ margin: 0, padding: 0, display: "-webkit-box", WebkitLineClamp: 2, WebkitBoxOrient: "vertical", overflow: "hidden", textOverflow: "ellipsis" }}>
                                  <b> {file.sNombre}</b>
                                </Typography>
                              </Grid>
                            </Grid>
                          </Paper>
                        </Grid>
                    )
                  })}
                </Grid>
              </Grid>
            </Grid>
          )}
          {activeStep === 2 && (
            <Grid
              container
              justifyContent={"center"}
              flexDirection={"column"}
              spacing={2}
              style={{ padding: 20, width: "100%", textAlign: "center" }}
            >
              <Grid item xs={12} sm={12}>
                <Typography variant="p" color={"primary"}>
                  {processMessage} ({`${Math.round(progress)}%`})...
                </Typography>
              </Grid>
              <Grid item xs={12} sm={12}>
                <BorderLinearProgress
                  variant="determinate"
                  value={progress}
                />
              </Grid>
              <Grid item xs={12} sm={12} style={{ textAlign: "right" }}>
                <Typography variant="p" color={"primary"}>
                  {log.length}/{fileData?.filter(file => !file.nError).length} facturas
                </Typography>
              </Grid>
              <Grid item xs={12} sm={12} style={{ textAlign: "left" }}>
                <Typography variant="p" color={"primary"}>
                  {log.length} facturas:{" "}
                  <span style={{ color: "#00CD68" }}>
                    {log.filter((l) => l.nCodigo === 0).length} exitosas
                  </span>{" "}
                  /{" "}
                  <span style={{ color: "#DD0000" }}>
                    {log.filter((l) => l.nCodigo !== 0).length} con error
                  </span>
                </Typography>
              </Grid>
              <Grid
                item
                xs={12}
                style={{
                  backgroundColor: "#f8f8f8",
                  border: "1px solid #979797",
                  padding: "10px 15px 25px",
                  borderRadius: 8,
                  maxHeight: "200px",
                  overflowY: "auto",
                  textAlign: "left",
                }}
              >
                {log
                  .sort((a, b) => parseFloat(a.sUUID) - parseFloat(b.nFila))
                  .map((l) => (
                    <p
                      style={{
                        color: l.nCodigo === 0 ? "#00609C" : "#DD0000",
                        padding: 0,
                        margin: "0 0 5px 0",
                      }}
                    >
                      Factura [{l.sNombre}]: {l.sMensaje}
                    </p>
                  ))}
                <div
                  style={{ float: "left", clear: "both" }}
                  ref={scrollDownRef}
                ></div>
              </Grid>
              <Grid item xs={12} sm={12}></Grid>
            </Grid>
          )}
          {activeStep === 3 &&
            <Grid
              container
              flexDirection={"column"}
              spacing={2}
              style={{ padding: 20, width: "100%" }}
            >
              <Grid item xs={12} sm={12}>
                <Typography variant="h6" color={"primary"}>
                  Facturas procesadas
                </Typography>
              </Grid>
              <Grid item xs={12} sm={12} style={{ textAlign: "left" }}>
                <Typography variant="p" color={"primary"}>
                  {log.length} facturas:{" "}
                  <span style={{ color: "#00CD68" }}>
                    {log.filter((l) => l.nCodigo === 0).length} exitosas
                  </span>{" "}
                  /{" "}
                  <span style={{ color: "#DD0000" }}>
                    {log.filter((l) => l.nCodigo !== 0).length} con error
                  </span>
                </Typography>
              </Grid>
              <Grid
                item
                xs={12}
                style={{
                  backgroundColor: "#f8f8f8",
                  border: "1px solid #979797",
                  padding: "10px 15px 15px",
                  borderRadius: 8,
                  maxHeight: "250px",
                  overflowY: "auto",
                  textAlign: "left",
                }}
              >
                {log
                  .sort((a, b) => parseFloat(a.nFila) - parseFloat(b.nFila))
                  .map((l) => (
                    <p
                      style={{
                        color: l.nCodigo === 0 ? "#00609C" : "#DD0000",
                        padding: 0,
                        margin: "0 0 5px 0",
                      }}
                    >
                      Factura [{l.sNombre}]: {l.sMensaje}
                    </p>
                  ))}
              </Grid>
            </Grid>
          }
          <MobileStepper
            variant="dots"
            steps={4}
            position="static"
            activeStep={activeStep}
            nextButton={<div style={{ width: 1, height: 1 }}></div>}
            backButton={<div style={{ width: 1, height: 1 }}></div>}
            sx={{
              "& .MuiMobileStepper-dot": {
                backgroundColor: "#00CD68",
                width: 12,
                height: 12,
              },
              "& .MuiMobileStepper-dotActive ~ .MuiMobileStepper-dot": {
                backgroundColor: "#D8D8D8",
                width: 12,
                height: 12,
              },
            }}
          />
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              width: "100%",
              justifyContent: "end",
            }}
          >
            {activeStep !== 3 && (
              <Button
                onClick={activeStep === 0 ? () => handleCloseImport(false) : handleBack}
                sx={{ color: "#00609C" }}
              >
                {[0, 2].includes(activeStep) ? "Cancelar" : "Anterior"}
              </Button>
            )}
            {![3, 2].includes(activeStep) && (
              <Button
                onClick={activeStep === 1 ? confirmDelete : handleNext}
                variant="contained"
                disabled={activeStep === 0 && !fileData?.some(file => !file.nError)}
              >
                Siguiente
              </Button>
            )}
            {activeStep === 3 && (
              <Button
                onClick={() => handleCloseImport(true)}
                variant="contained"
              >
                Finalizar
              </Button>
            )}
          </div>
        </Box>
      </ModalBase>
    </>
  );
};
