import { Table } from "../../components/table/Table";
import { Box } from "@mui/system";
import { useSelector } from "react-redux";
import { useModal } from "../../hooks/useModal";
import { useAuthPage } from "../../hooks/useAuthPage";
import { ModalBase } from "./ModalBase";
import { Button, IconButton, Tooltip } from "@mui/material";
import { useEffect, useState } from "react";
import RouteIcon from "@mui/icons-material/Route";
import moment from "moment";
import { SearchInput } from "../form/SearchInput";
import EnhancedTable from "../table/EnhancedTable";
import { ConfirmDialog } from "./ConfirmDialog";
import VisibilityIcon from "@mui/icons-material/Visibility";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from '@mui/icons-material/Delete';

const colorsTab = ["#e53e3e", "#00CD68", "#053e85", "#E7B10A"]

export const ModalListRoute = (props) => {
  const { openModalRoute, deleteRoute, emisores, form } = props;
  const { isOpenListRoute, closeModalListRoute } = useModal();
  const { setAllowComponent } = useAuthPage();
  const { rutas } = useSelector((state) => state.emisor);

  const [emisor, setEmisor] = useState({});
  const [filter, setFilter] = useState(3)

  useEffect(() => {
    if (emisores.length) {
      let innerEmisor = emisores.filter(
        (item) => item.nIdEmisor === form.nIdEmisor
      )[0];
      console.log(innerEmisor);
      if (innerEmisor) {
        setEmisor({ ...emisor, ...innerEmisor });
      }
      setFilter(3)
    }
  }, [form.nIdEmisor]);

  const estatus = {
    inactivo: 0,
    activo: 1,
    pendiente: 2
  }

  const [showConfirmDialog, setShowConfirmDialog] = useState(false)

  const triggerDeleteDialog = (row) => {
    setShowConfirmDialog(row);
  };

  const handleConfirmDialog = async () => {
    setShowConfirmDialog(false)
    await deleteRoute(showConfirmDialog.nIdRuta)
  }

  const filteredTable = (table) => {
    let { rows, columns } = table;
    if (rows) {
      if (parseInt(filter) !== 3) rows = rows.filter(row => row.nActivo === parseInt(filter))
      else rows = rows.filter(row => [1, 2].includes(row.nActivo))
    }

    if (columns) {
      columns = [...columns, {
        field: 'nActivo', headerName: 'Estado', type: 'render', flex: 1, style: {width: "40px"}, renderFunction: (row) => (
          <p style={{ display: "inline-block", color: colorsTab[row.nActivo], background: `${colorsTab[row.nActivo]}15`, padding: "4px 8px", borderRadius: "4px", fontWeight: 500, margin: 0, fontSize: 13 }} >{row.nActivo === 1 ? 'Activo' : row.nActivo === 2 ? 'Pendiente' : 'Inactivo'}</p>
        )
      }]
    }
    return {
      rows,
      columns,
    };
  };

  return (
    <>
      <ConfirmDialog
        isOpenDialog={!!showConfirmDialog}
        onCloseDialog={() => setShowConfirmDialog(false)}
        onConfimDialog={handleConfirmDialog}
        message={`¿Estas seguro/a de eliminar este registro?`}
        textButtonConfirm="Sí, si estoy seguro/a."
      />
      <ModalBase
        open={isOpenListRoute}
        style={{ width: "1350px", maxHeight: "700px" }}
      >
        <Box
          sx={{
            width: "100%",
            height: "100%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
          }}
        >
          <EnhancedTable
            isModalTable
            icon={<RouteIcon fontSize={"large"} />}
            subtitle={"Listado de rutas"}
            title={emisor?.sNombreComercial}
            table={filteredTable(rutas)}
            add={setAllowComponent("agregarRutas")}
            onAddFunction={openModalRoute}
            rowId={"nIdRuta"}
            disablePathParameters
            buttons={
              [
                {
                  label: "Ver",
                  icon: <VisibilityIcon fontSize={"small"} />,
                  onClick: (id) => openModalRoute("view", id),
                  showButton: setAllowComponent("verRutas")
                },
                {
                  label: "Editar",
                  icon: <EditIcon fontSize={"small"} />,
                  onClick: (id) => openModalRoute("edit", id),
                  disabled: (id, row) => row.nActivo === estatus.inactivo,
                  showButton: setAllowComponent("editarRutas")
                },
                {
                  label: "Eliminar",
                  icon: <DeleteIcon fontSize={"small"} />,
                  onClick: async (id) => await triggerDeleteDialog(id),
                  disabled: (id,row) => row.nActivo === estatus.inactivo,
                  showButton: setAllowComponent("eliminarRutas")
                }
              ]
            }
            extraButtons={
              <SearchInput
                size="small"
                onChange={(e) => setFilter(e.target.value)}
                label="Filtro"
                options={[
                  {
                    id: 3,
                    name: "Todos",
                  },
                  {
                    id: 1,
                    name: "Activos",
                  },
                  {
                    id: 2,
                    name: "Pendientes",
                  },
                  {
                    id: 0,
                    name: "Inactivos",
                  },
                ]}
                value={filter}
                sx={{ width: 150 }}
                getOptionLabel={"name"}
                getIndexLabel={"id"}
              />
            }
          />
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              width: "100%",
              justifyContent: "end",
            }}
          >
            <Button onClick={closeModalListRoute} sx={{ color: "#00609C" }}>
              cerrar
            </Button>
          </div>
        </Box>
      </ModalBase>

    </>
  );
};
