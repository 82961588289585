import { useEffect, useState } from "react";
import { useMenu } from "../hooks/useMenu";
import { useAuthPage } from "../hooks/useAuthPage";
import {
  createTheme,
  IconButton,
  ThemeProvider,
  Tooltip,
} from "@mui/material";
import {
  Visibility,
  Delete
} from "@mui/icons-material";
import { useModal } from "../hooks/useModal";

import EnhancedTable from "../components/table/EnhancedTable";
import { useUsuariosExternosTable } from "../hooks/useUsuariosExternosTable";
import { ConfirmDialog } from "../components/modal/ConfirmDialog";
import { ModalUsuarioExterno } from "../components/modal/ModalUsuarioExterno";

const iconTheme = createTheme({
  status: {
    danger: "#e53e3e",
  },
  palette: {
    primary: {
      main: "#00609C",
      dark: "#053e85",
    },
    neutral: {
      main: "#00CD68",
      light: "#053e85",
      dark: "#053e85",
      contrastText: "#fff",
    },
  },
});


export const VerUsuariosExternosPage = () => {
  const { setNameSection } = useMenu();
  const { setAllowComponent } = useAuthPage();
  const { isTableLoading } = useModal();

  const colorsTab = ["#E7B10A", "#00CD68", "#e53e3e", "#053e85"]
  const {
    dataTable,
    loadInInit,
    onClickAdd,
    showUsuarioModal,
    handleCloseModal,
    handleSubmit,
    form,
    onChangeInput,
    openUsuarioModal,
    enableEditModal,
    handleSubmitChangeEstatus,
    disableEditModal,
    onChange,
    cadenas,
  } = useUsuariosExternosTable();

  const [showConfirmDialog, setShowConfirmDialog] = useState(false)

  const estatusUsuario = {
    inactivo: 0,
    activo: 1,
    baja: 2,
    pendiente: 3
  }

  const triggerDeleteDialog = (row) => {
    setShowConfirmDialog(row);
  };

  const handleConfirmDialog = async () => {
    await handleSubmitChangeEstatus(showConfirmDialog, true)
    setShowConfirmDialog(false)
  }

  useEffect(() => {
    setNameSection("usuariosExternos");
  }, [setNameSection]);

  useEffect(() => {
    loadInInit();
  }, []);

  return (
    <ThemeProvider theme={iconTheme}>
      <ConfirmDialog
        isOpenDialog={!!showConfirmDialog}
        onCloseDialog={() => setShowConfirmDialog(false)}
        onConfimDialog={handleConfirmDialog}
        message={`¿Estas seguro/a de dar de baja al usuario externo?`}
        textButtonConfirm="Sí, si estoy seguro/a."
      />
      <ModalUsuarioExterno
        isOpenModal={showUsuarioModal.open}
        modalType={showUsuarioModal.type}
        cadenas={cadenas.rows}
        form={form}
        onChange={onChangeInput}
        onChangeSearch={onChange}
        handleSubmit={handleSubmit}
        handleCloseModal={handleCloseModal}
        enableEditModal={enableEditModal}
        disableEditModal={disableEditModal}
        handleSubmitChangeEstatus={handleSubmitChangeEstatus}
      />
      <EnhancedTable
        table={{
          ...dataTable,
          columns: [
            ...dataTable.columns,
            {
              field: 'sEstatus', headerName: 'Estado', type: 'render', flex: 1, renderFunction: (row) => (
                <p style={{ display: "inline-block", color: colorsTab[row.nIdEstatus], background: `${colorsTab[row.nIdEstatus]}15`, padding: "4px 8px", borderRadius: "4px", fontWeight: 500, margin: 0, fontSize: 13 }} >{row.sEstatus}</p>
              )
            },
          ]
        }}
        add={setAllowComponent("altaUsuarioExterno")}
        onAddFunction={onClickAdd}
        buttons={[
          {
            label: "Ver",
            icon: <Visibility fontSize={"small"} />,
            onClick: (id,row) => openUsuarioModal("view", row),
            showButton: setAllowComponent('verUsuarioExterno')
          },
          {
            label: "Eliminar",
            icon: <Delete fontSize={"small"} />,
            onClick: (id,row) => triggerDeleteDialog(row),
            disabled: (id,row) => row.nIdEstatus === estatusUsuario.baja,
            showButton: setAllowComponent('eliminarUsuarioExterno')
          }
        ]}
        loading={isTableLoading}
        rowId={"nIdUsuario"}
      />
    </ThemeProvider>
  );
};
