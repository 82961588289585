import { Block, History } from '@mui/icons-material';
import { Alert, AlertTitle, Button, Collapse,Grid,  Typography } from '@mui/material';
import { Box } from '@mui/system';
import { TextInput } from '../form/TextInput';
import { ModalBase } from './ModalBase';
import { useEffect, useState } from 'react';
import { BlueSwitch } from '../../helpers/stylesMaterial';
import { useAuthPage } from '../../hooks/useAuthPage';
import EditIcon from '@mui/icons-material/Edit';
import { SearchInput } from '../form/SearchInput';
import moment from 'moment';
import EnhancedTable from '../table/EnhancedTable';

export const ModalSuspension = (props) => {

  const {
    isOpenModal = false,
    modalType = "add",
    form = {},
    onChange,
    handleSubmit,
    handleCloseModal,
    cadenas = [],
    productos = [],
    sucursales = [],
    enableEditModal,
    disableEditModal
  } = props;

  const { setAllowComponent } = useAuthPage();

  const [isOpenHistorial, setIsOpenHistorial] = useState(false)

  useEffect(() => {
    setIsOpenHistorial(false)
  }, [isOpenModal])


  const showTitleButton = () => {
    if (modalType === 'view') return (
      <>
        {setAllowComponent("editarSuspension") && <Button variant='contained' onClick={enableEditModal}
          startIcon={<EditIcon />} sx={{ ml: 1 }}>Editar</Button>}

        <Button variant='contained' onClick={() => setIsOpenHistorial(true)}
          startIcon={<History />} sx={{ ml: 1 }}>Historial de cambios</Button>
      </>);
  }



  return (<>
    <ModalBase open={isOpenModal} title="" style={{ width: isOpenHistorial ? "1050px" : '850px', height: 'auto' }}>
      <Box
        sx={{ width: '100%', height: '100%', display: 'flex', flexDirection: 'column' }}
      >
        {!isOpenHistorial ?
          <>
            <TitleModal
              title={modalType === "add" ? "Suspensión" : form.sNombreComercial}
              subtitle={modalType === 'add' ? 'Agregar' : modalType === 'edit' ? 'Editar suspensión' : 'Ver suspensión'}
              extraButton={showTitleButton()}
            />
            <Grid container spacing={2} sx={{ mt: 1, mb: 2, maxHeight: "calc(100vh - 250px)", overflow: 'auto' }}>
              <Grid item xs={12}>
                <Typography variant="h6" color={"primary"}>Comisionista</Typography>
              </Grid>
              <Grid item xs={12}>
                <SearchInput
                  options={[...cadenas?.rows ?? []]}
                  value={form.nIdCadena}
                  name={"nIdCadena"}
                  label={"Comisionista"}
                  getOptionLabel={"sNombreComercial"}
                  getIndexLabel={"nIdCadena"}
                  disabled={modalType !== 'add'}
                  onChange={onChange}
                />
              </Grid>
              <Grid item xs={12}>
                <Typography variant="h6" color={"primary"}>Productos</Typography>
              </Grid>
              <Grid item xs={12} container style={{ alignItems: "center" }}>
                <BlueSwitch
                  onChange={onChange}
                  inputProps={{ 'aria-label': 'controlled' }}
                  name='nTodosProductos'
                  checked={form.nTodosProductos}
                  value={form.nTodosProductos}
                  disabled={modalType === 'view' || !form.nIdCadena}
                />
                <p>Todos los productos</p>
              </Grid>
              <Grid item xs={12}>
                <Collapse in={!form.nTodosProductos}>
                  <SearchInput
                    options={[...productos ?? []]}
                    value={form.sProductos}
                    name={"sProductos"}
                    label={"Productos seleccionados"}
                    renderOption={(row) => `(${row.sClaveProducto}) ${row.sDescripcion}`}
                    getIndexLabel={"nIdProducto"}
                    disabled={modalType === 'view'}
                    onChange={onChange}
                    multiple
                  />
                </Collapse>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="h6" color={"primary"}>Sucursales</Typography>
              </Grid>
              <Grid item xs={12} container style={{ alignItems: "center" }}>
                <BlueSwitch
                  onChange={onChange}
                  inputProps={{ 'aria-label': 'controlled' }}
                  name='nTodasSucursales'
                  checked={form.nTodasSucursales}
                  value={form.nTodasSucursales}
                  disabled={modalType === 'view' || !form.nIdCadena}
                />
                <p>Todas los sucursales</p>
              </Grid>
              <Grid item xs={12}>
                <Collapse in={!form.nTodasSucursales}>
                  {sucursales?.rows.length ?

                    <SearchInput
                      options={[...sucursales?.rows ?? []]}
                      value={form.sSucursales}
                      name={"sSucursales"}
                      label={"Sucursales seleccionadas"}
                      renderOption={(row) => `(${row.sClaveSucursal}) ${row.sNombre}`}
                      getIndexLabel={"nIdSucursal"}
                      disabled={modalType === 'view'}
                      onChange={onChange}
                      multiple
                    />
                    :
                    <Alert severity="warning">
                      <AlertTitle>Sin sucursales activas para el comisionista seleccionado.</AlertTitle>
                    </Alert>
                  }
                </Collapse>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="h6" color={"primary"}>Comentario</Typography>
              </Grid>
              <Grid item xs={12}>
                <TextInput
                  id="sComentario"
                  name="sComentario"
                  label="Motivo o comentarios de suspensión"
                  value={form.sComentario}
                  onChangeRoot={onChange}
                  disabled={modalType === 'view'}
                  caracteres={250}
                  require
                  multiline
                  minRows={2}
                />
              </Grid>
            </Grid>

            <Grid item xs={12} container justifyContent={"space-between"} alignItems={"center"} mt={2}>
              <Grid item xs={6}>
                {modalType === "view" && <>
                  <Typography fontSize={14} color={"primary"}> {form.sBitacora?.length > 1 ? "Última modificación por:" : "Suspensión creada por:"}</Typography>
                  <Typography fontWeight={500} > {form.sUsuario} <span style={{ fontSize: 14, fontWeight: 400 }}>({moment(form.dFecMovimiento[form.dFecMovimiento.length - 1] === "Z" ? form.dFecMovimiento.slice(0, -1) : form.dFecMovimiento).format("YYYY-MM-DD HH:mm:ss")})</span></Typography>

                </>}</Grid>

              <Grid item xs={6}>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    width: '100%',
                    justifyContent: 'end'
                  }}
                >
                  <Button onClick={modalType === "edit" ? disableEditModal : handleCloseModal} color="primary">
                    {modalType === "edit" ? "Cancelar" : "Cerrar"}
                  </Button>
                  {modalType !== 'view' &&
                    <Button
                      onClick={handleSubmit}
                      color="primary"
                      variant='contained'
                    >
                      Guardar
                    </Button>
                  }
                </div>
              </Grid>
            </Grid>
          </>
          :
          <>
            <TitleModal
              title={form.sNombreComercial}
              subtitle={'Historial de cambios'}
            />
            <EnhancedTable
              table={{
                rows: form.sBitacora,
                columns: [
                  { field: 'sTipoBitacora', headerName: 'Acción', flex: 1, style: { width: 40 } },
                  { field: 'sUsuario', headerName: 'Usuario', flex: 1 },
                  { field: 'dFecMovimiento', headerName: 'Fecha movimiento', flex: 2, type: "datetime" },
                  { field: 'sComentario', headerName: 'Comentario', flex: 3 },
                  { field: 'nTodosProductos', headerName: 'Productos suspendidos', type: 'render', flex: 2, renderFunction: (row) => row.nTodosProductos ? "Todos los productos" : `${JSON.parse(row.sProductos).length} producto(s)` },
                  { field: 'nTodasSucursales', headerName: 'Sucursales suspendidas', type: 'render', flex: 2, renderFunction: (row) => row.nTodasSucursales ? "Todas las sucursales" : `${JSON.parse(row.sSucursales).length} sucursal(es)` }

                ]
              }}
              disableButtons
              isModalTable
              disablePathParameters
              showExpandableTable
              rowId={"nIdSuspensionBitacora"}
              expandedItems={(id, row) => {
                let {
                  sSucursales = "[]",
                  sProductos = "[]",
                  nTodosProductos,
                  nTodasSucursales
                } = row

                sSucursales = JSON.parse(sSucursales)
                sProductos = JSON.parse(sProductos)
                return (
                  <Grid container spacing={2}>
                    <Grid item xs={4}>
                      <Typography variant="h6" color={"primary"} fontSize={15}>Productos suspendidos:</Typography>
                      <Grid container>
                        {nTodosProductos ?
                          <TagItem text={"Todos los productos"} />
                          :
                          sProductos.map((item) => <TagItem text={`(${item.sClaveProducto}) ${item.sDescripcion}`} />)
                        }
                      </Grid>
                    </Grid>
                    <Grid item xs={4}>
                      <Typography variant="h6" color={"primary"} fontSize={15}>Sucursales suspendidas:</Typography>
                      <Grid container>
                        {nTodasSucursales ?
                          <TagItem text={"Todas las sucursales"} />
                          :
                          sSucursales.map((item) => <TagItem text={`(${item.sClaveSucursal}) ${item.sNombre}`} />)
                        }
                      </Grid>
                    </Grid>
                    <Grid item xs={4}>
                      <Typography variant="h6" color={"primary"} fontSize={15}>Comentario</Typography>
                      <Grid container>
                        <TagItem text={row.sComentario} />
                      </Grid>
                    </Grid>
                  </Grid>
                )
              }}
            />
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                width: '100%',
                justifyContent: 'end'
              }}
            >
              <Button onClick={() => setIsOpenHistorial(false)} color="primary">
                Volver
              </Button>
            </div>
          </>
        }



      </Box>
    </ModalBase>
  </>
  );
};

const TagItem = ({ text }) => {
  return <span style={{ padding: "3px 10px", background: "#f0f0f0", borderRadius: 8, margin: "3px", fontWeight: 500, fontSize: 13 }}>{text}</span>
}

const TitleModal = ({ title, subtitle, extraButton }) => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        width: '100%',
        marginBottom: '10px',
        justifyContent: 'space-between'
      }}
    >
      <div style={{ display: 'flex', justifyContent: "space-between" }}>
        <Block fontSize={'large'} sx={{ color: '#00CD68', fontSize: '45px' }} />

        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <Typography
            variant="p"
            sx={{
              marginLeft: '15px',
              fontSize: '14px',
              marginBottom: '4px'
            }}
          >
            {subtitle}
          </Typography>
          <Typography
            variant="p"
            sx={{
              marginLeft: '15px',
              color: '#00609C',
              fontSize: '20px'
            }}
          >
            {title}
          </Typography>
        </div>
      </div>
      <div>
        {extraButton}
      </div>
    </Box>
  );
};

