import * as React from "react";
import PublishIcon from "@mui/icons-material/Publish";
import { Button, Grid, IconButton, Paper, Tooltip, Typography } from "@mui/material";
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import DeleteIcon from '@mui/icons-material/Delete';
import AddCircleIcon from '@mui/icons-material/AddCircle';

export const DropFileInput = (props) => {
  const {
    handleDrop,
    selectedFile,
    setSelectedFile = null,
    setFileData = null,
    acceptedFormat = ".xls,.xlsx,.csv",
    fileData = [],
    multiple = false,
    minHeight = 300,
    deleteFileData
  } = props

  const inputRef = React.useRef(null);

  const [dragActive, setDragActive] = React.useState(false);
  const [innerFileData, setInnerFileData] = React.useState(fileData)

  React.useEffect(() => {
    if (fileData && fileData.length) {
      setInnerFileData(fileData)
    }
  }, [fileData])


  const handleDrag = (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (e.type === "dragenter" || e.type === "dragover") {
      setDragActive(true);
    } else if (e.type === "dragleave") {
      setDragActive(false);
    }
  };

  const innerHandleDrop = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setDragActive(false);

    handleDrop(e)
  };

  const showWhenIsSelected = () => {
    return (
      multiple ?
        <>
          <Grid item sm={4} sx={{ padding: 1, cursor: "pointer" }}>
            <Paper elevation={2} onClick={() => inputRef.current.click()}>
              <Grid container justifyContent={"center"} alignItems={"center"} style={{ height: 80 }}>
                <IconButton aria-label="add">
                  <AddCircleIcon fontSize="large" />
                </IconButton>
              </Grid>
            </Paper>
          </Grid>
          {innerFileData?.map(file => {
            return (
              <Grid item sm={4} sx={{ padding: 1 }}>
                <Paper elevation={2} style={{ height: 80 }}>
                  <Grid container sx={{ padding: 1 }}>
                    <Grid item style={{ width: 35 }}><InsertDriveFileIcon color={file.nError ? "error" : "neutral"} /></Grid>
                    <Grid item style={{ width: "calc(100% - 35px)" }}>
                      <Grid container justifyContent={"space-between"}>
                        <Grid item style={{ width: "calc(100% - 30px)" }}>
                          <Typography variant="p" color={"primary"} style={{ margin: 0, padding: 0, display: "-webkit-box", WebkitLineClamp: 2, WebkitBoxOrient: "vertical", overflow: "hidden", textOverflow: "ellipsis" }}>
                            <b> {file.sNombre}</b>
                          </Typography>
                        </Grid>
                        <IconButton aria-label="delete" size={"small"} onClick={() => deleteFileData(file)} sx={{ margin: 0, padding: 0 }}>
                          <DeleteIcon />
                        </IconButton>
                      </Grid>
                      <Typography variant="p" color={"primary"}>
                        {file.nError ?
                          <Tooltip title={file.sMensaje} placement="top">
                            <p style={{ margin: 0, padding: 0, display: "-webkit-box", WebkitLineClamp: 2, WebkitBoxOrient: "vertical", overflow: "hidden", textOverflow: "ellipsis" }}>
                              {file.sMensaje}</p>
                          </Tooltip>
                          :
                          file.sMensaje
                        }
                      </Typography>
                    </Grid>
                  </Grid>
                </Paper>
              </Grid>
            )
          })}
        </>
        :
        <>
          <Typography variant="p" color={"primary"}>
            Archivo seleccionado: <b> {(selectedFile ? selectedFile : fileData).name }</b>
          </Typography>
          <br />
          <Button
            onClick={() => {
              if(setSelectedFile !== null) setSelectedFile(false);
              if(setFileData !== null) setFileData(false);
            }}
            variant="contained"
            size="small"
          >
            Cancelar
          </Button>
        </>
    )
  }

  return (
    <>
      <Grid container spacing={2} sx={{ padding: "20px 10px"}}>
        <input
          ref={inputRef}
          type="file"
          multiple={multiple}
          onChange={innerHandleDrop}
          style={{ display: "none" }}
          accept={acceptedFormat}
        />
        <Grid
          container
          style={{
            width: "100%",
            background: "#f8f8f8",
            border: dragActive ? "2px solid #00609C" : "1px dashed #979797",
            borderRadius: 8,
            padding: "25px 15px",
            ...(!(multiple && fileData.length) ?
              {
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              } : { alignItems: "flex-start", justifyContent: "flex-start", }),

            minHeight,
            maxHeight: 600,
            overflowY: "auto"
          }}
          onDragEnter={handleDrag}
          onDragLeave={handleDrag}
          onDragOver={handleDrag}
          onDrop={innerHandleDrop}
          key={1}
        >
          {selectedFile || fileData.length ?
            showWhenIsSelected()
            : (
              <>
                <Grid
                  item
                  style={{
                    color: "#00CD68",
                    padding: 15,
                    fontWeight: "bold",
                  }}
                >
                  <PublishIcon
                    style={{
                    /*transform: 'rotate(180deg)',*/ fontSize: 60,
                    }}
                  />
                </Grid>
                <Typography variant="h6" color={"primary"}>
                  {multiple ? "Arrastre los archivos aquí" : "Arrastre el archivo aquí"}
                </Typography>
                <Grid
                  container
                  style={{
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "center",
                    padding: 10,
                  }}
                >
                  <div
                    style={{ width: 40, height: 1, background: "#979797" }}
                  ></div>
                  <div style={{ padding: "0 8px", color: "#979797" }}>ó</div>
                  <div
                    style={{ width: 40, height: 1, background: "#979797" }}
                  ></div>
                </Grid>
                <Button
                  onClick={() => inputRef.current.click()}
                  variant="contained"
                >
                  Seleccione archivo{multiple && "s"}
                </Button>
              </>
            )}
        </Grid>
      </Grid>
    </>
  );
};
