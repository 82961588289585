import { useEffect } from 'react';
import { useMenu } from '../hooks/useMenu';
import { useAuthPage } from '../hooks/useAuthPage';
import { NotAllowPage } from '../pages/NotAllowPage';
import { RegistrosBancariosPage } from '../pages/RegistrosBancariosPage';

export const RegistrosBancariosModule = () => {
  let name = '';
  const { setNameSection } = useMenu();
  const { setNewPage, isAllowed } = useAuthPage();
  useEffect(() => {
    setNewPage('registrosBancarios');
    setNameSection(name);
  }, [setNewPage, setNameSection, name]);

  if (isAllowed) {
    name = 'registrosBancarios';
    return <RegistrosBancariosPage />;
  } else {
    name = 'NO DISPONIBLE';
    return <NotAllowPage />;
  }
};