import { useDispatch } from "react-redux";
import { subirArchivoS3 } from "../slices/cadena/cadenaThunk";
import { openToast } from "../slices/ui/uiSlice";

export const useUploadDocuments = () => {

  const dispatch = useDispatch();

  const uploadDocument = async ( documentsTemp, rfc, documentos = [] ) => {
    const documents = [];
    let flag = false;
    for ( const [key, value] of Object.entries(documentsTemp) ) {
      if( flag ) continue;
      const documentCat =  documentos.find(doc => doc.nIdDocumento === parseInt(key))
      let resp = await dispatch(subirArchivoS3(value, key, rfc, documentCat));
      if ( !resp.ok ) {
        dispatch(openToast({ error: true, message: resp.msg }));
        flag = true;
  
      } else {
        documents.push({id: key, key : resp.key, name: documentCat ? documentCat.sAbrevDoc : value.name});
  
      }
  
    }

    return flag
      ? {
          ok       : false,
          documents: []
        }
      : {
          ok: true,
          documents
        };
  }

  return {
    uploadDocument 
  }
}
