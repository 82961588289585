import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { facturasForm } from '../helpers/forms';
import {
  startLoadCadenas,
  startLoadDatosFacturacion,
  startLoadEmisores,
  startLoadEstatusFacturacion,
} from "../slices/catalogo/catalgoThunk";
import { setDataTable } from '../slices/facturacion/facturacionSlice';
import { getFacturas, postFacturas } from '../slices/facturacion/facturacionThunk';
import { useModal } from './useModal';

export const useFacturasPage = () => {

  const [form, setForm] = useState(facturasForm);
  const [filtersLoaded, setFiltersLoaded] = useState(false)

  const dispatch = useDispatch();

  const {
    cfdis,
    formasPago,
    metodosPago,
    regimensFiscal,
    unidades,
    productoServicios,
    emisores,
    cadenas,
    estatusFacturacion
  } = useSelector((state) => state.catalogo);


  const { setOpenToast } = useModal();

  const {
    dataTable,
  } = useSelector((state) => state.facturacion);

  const loadInInit = async () => {
    await dispatch(startLoadDatosFacturacion({}));
    await getFacturasData();
  }

  const getFilters = async () => {
    if (!filtersLoaded) {
      await dispatch(startLoadCadenas());
      await dispatch(startLoadEmisores());
      await dispatch(startLoadEstatusFacturacion());
      
      setFiltersLoaded(true)
    }
  }

  const getFacturasData = async () => {
    await dispatch(getFacturas(form));
  }

  const authorizeItem = (changedRow) => {
    let innerDataTableRows = dataTable.rows.map(row => {
      return changedRow.nId === row.nId ? {...changedRow, authorized: true, disableCheckbox: true} : row
    })
    dispatch(setDataTable({
      ...dataTable,
      rows: innerDataTableRows
    }))
  }

  const authorizeSelected = (selected) => {
    let innerDataTableRows = dataTable.rows.map(row => {
      const isRowSelected = row.authorized || selected.some(sel => sel === row.nId)
      return {
        ...row,
        authorized: isRowSelected,
        disableCheckbox:  row.disableCheckbox || isRowSelected
      }
    })
    dispatch(setDataTable({
      ...dataTable,
      rows: innerDataTableRows
    }))
  }

  const onChange = (e, name = null, value = null) => {
    const inputName = name !== null ? name : e.target.name;
    const inputValue = value !== null ? value : e.target.value;

    let valueForm = { ...form, [inputName]: inputValue };

    if (inputName === "nActor" && parseInt(inputValue) === 0) {
      valueForm = { ...valueForm, nIdCadena: 0, nIdEmisor: 0 };
    }

    setForm(valueForm);
  };

  const saveFacturas = async () => {
    const authorizedFacturas = dataTable.rows.filter((factura)=>factura.authorized)
    const res = await dispatch(postFacturas(authorizedFacturas))
    setOpenToast(res.nCodigo !== 0, res.sMensaje)
    await getFacturasData();
  }

  return {
    dataTable,
    cfdis,
    formasPago,
    metodosPago,
    regimensFiscal,
    unidades,
    productoServicios,
    loadInInit,
    getFacturas,
    form,
    onChange,
    emisores,
    cadenas,
    estatusFacturacion,
    getFacturasData,
    getFilters,
    authorizeItem,
    authorizeSelected,
    saveFacturas
  };
};
