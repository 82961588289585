import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { useModal } from './useModal';
import { isValidContactoCadena } from '../helpers/forms-validate';
import { setContactoCadena, updateConctactoCadena } from '../slices/cadena/cadenaSlice';
import { setContacto as setContactoEFR } from '../slices/efr/efrSlice';
import { typesSave } from '../helpers/types';

const initialValues = {
  nombre: '',
  apellido: '',
  segundoApellido: '',
  telefono: '',
  email: '',
  proceso: [],
  area: '',
  puesto: '',
  comentarios: ''
}

const errorInitialValues = { open: false, msg: '' }

export const useModalContact = ({ saveIn }) => {

  const { contactos } = useSelector((state) =>
    typesSave.CADENA === saveIn ? state.cadena : state.efr
  );
  const { procesos } = useSelector((state) => state.catalogo);

  const [formContact, setFormContact] = useState(initialValues);
  const [error, setError] = useState(errorInitialValues);

  const { isOpenContact, closeModalContact } = useModal();
  const dispatch = useDispatch();
  

  const onChange = (value) => {
    setFormContact({ ...formContact, ...value });
  }

  const onSave = () => {
    const { ok, error: errorMsg } = isValidContactoCadena(formContact);
    if (!ok) {
      setError({ ...error, open: true, msg: errorMsg });
      return;
    }
    setContactoInStore();
    setError(errorInitialValues);
    setFormContact(initialValues);
    closeModalContact();
  }

  const onClose = () => {
    setError(errorInitialValues);
    setFormContact(initialValues);
    closeModalContact();
  }

  const onChangeSelect = (e) => {
    console.log(e)
    const inputName = e.target.name;
    const inputValue = e.target.value;

    setFormContact({ ...formContact, [inputName]: inputValue });

  }

  const onChangeSwitch = (e) => {
    const inputName = e.target.name;
    const inputValue = e.target.checked;

    setFormContact({ ...formContact, [inputName]: inputValue });

  }

  const setContactoInStore = () => {
    if (typesSave.CADENA === saveIn) {
      if (isOpenContact.edit) {
        dispatch(updateConctactoCadena({
          ...formContact,
        }))
        return;
      }
      dispatch(
        setContactoCadena({
          ...formContact,
          id: contactos.length
        })
      );
      return;
    }
    dispatch(
      setContactoEFR({
        ...formContact,
        id: contactos.length
      })
    );

  }

  const setEditContact = (contact) => {
    setFormContact({
      ...contact
    })
  }


  return {
    error,
    errorInitialValues,
    formContact,
    isOpenContact,
    procesos,

    onChange,
    onChangeSelect,
    onChangeSwitch,
    onClose,
    onSave,
    setError,
    setEditContact,
  };
}
