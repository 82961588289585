import { useEffect, useState } from "react";
import { useMenu } from "../hooks/useMenu";
import { useAuthPage } from "../hooks/useAuthPage";
import { Card, Typography } from "@mui/material";
import { createTheme, ThemeProvider } from "@mui/material";
import { esES } from "@mui/material/locale";
import { RegistrosBancariosForm } from "../components/RegistrosBancariosForm";
import { ModalImport } from "../components/modal/ModalImport";
import { useRegistrosBancariosPage } from "../hooks/useRegistrosBancariosPage";
import { ReportTable } from "../components/table/ReportTable";
import { ModalRegistrosCortes } from "../components/modal/ModalRegistrosCortes";
import { ModalRegistrosOrdenes } from "../components/modal/ModalRegistrosOrdenes";

const iconTheme = createTheme(
  {
    status: {
      danger: "#e53e3e",
    },
    palette: {
      primary: {
        main: "#00609C",
        dark: "#053e85",
      },
      neutral: {
        main: "#00CD68",
        light: "#053e85",
        dark: "#053e85",
        contrastText: "#fff",
      },
    },
  },
  esES
);

export const RegistrosBancariosPage = () => {
  const { 
    onChange,
    form,
    registrosBancariosTable,
    onChangeInput,
    isOpenImport, 
    openImportModal,
    closeImportModal,
    loadInInit,
    importRegistrosBancarios,
    openModalRegistrosCortesFromImport
  } = useRegistrosBancariosPage();
  const { setNameSection } = useMenu();
  const { setNewPage, isAllowed } = useAuthPage();
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    if (!loaded) {
      loadInInit();
      setNewPage("registrosBancarios");
      setNameSection("registrosBancarios");
      setLoaded(true);
    }
  }, []);

  if (isAllowed) {
    return (
      <ThemeProvider theme={iconTheme}>
        <ModalImport 
          isOpenImport={isOpenImport}
          closeImport={closeImportModal}
          openImport={openImportModal}
          importFunction={importRegistrosBancarios}
          openModalRegistrosCortesFromImport={openModalRegistrosCortesFromImport}
        />
        <ModalRegistrosCortes />
        <ModalRegistrosOrdenes />
        <RegistrosBancariosForm />
        <ReportTable />{/* 
        {registrosBancariosTable.columns.length ? (
          <Table
            table={registrosBancariosTable}
            rowId={"nIdRegistroBancario"}
            hideActions
          />
        ) : (
          <></>
        )} */}
      </ThemeProvider>
    );
  } else {
    setNameSection("");
    return (
      <Card>
        <Typography variant="h3">Contenido no disponible</Typography>
      </Card>
    );
  }
};
