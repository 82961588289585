import { useState, useEffect } from 'react';

import { Collapse, Fade, Grid, Grow, InputAdornment, Typography } from '@mui/material';
import { SelectInput } from '../form/SelectInput';
import { TextInput } from '../form/TextInput';
import { useDispatch, useSelector } from 'react-redux'
import { disableCleanLiquidacion } from '../../slices/cadena/cadenaSlice';
import { daysOfWeek } from '../../helpers/getData';
import { IvaInput } from '../form/IvaInput';
import { nIdTipoLiquidacion24Hrs } from '../../helpers/forms';


const daysOfMonth = Array.from(Array(31), (_, index) => index + 1).map((val) => ({
  text: `${val}`,
  value: `${val}`
}));

const initialValues = {
  pagoMes: '',
  tipo: '1',
  tipoSelected: null,
  lunes: '',
  martes: '',
  miercoles: '',
  jueves: '',
  viernes: '',
  sabado: '',
  domingo: '',
  nMontoBase: 0,
  nPerTransaccion: 0
}


export const Liquidacion = ({
  cadena,
  onCadenaChange,
  tipoMovimiento,
  defaultValues = null,
  isCadena = false,
}) => {
  const [formLiquidacion, setFormLiquidacion] = useState(defaultValues ?? initialValues);

  const { tiposLiquidacion = [] } = useSelector((state) => state.catalogo);
  const { activeCleanLiquida } = useSelector((state) => state.cadena);
  const dispatch = useDispatch();

  const onChange = (e) => {
    const inputName = e?.target?.name ?? Object.keys(e)[0];
    const inputValue = e?.target?.value ?? e[inputName];
    if (inputName === 'tipo') {
      setFormLiquidacion({
        ...formLiquidacion,
        [inputName]: inputValue,
        tipoSelected: tiposLiquidacion.find(
          (liq) => liq.value.toString() === inputValue.toString()
        )
      });
      onCadenaChange({
        ...cadena,
        [tipoMovimiento]: {
          ...formLiquidacion,
          [inputName]: inputValue,
          tipoSelected: tiposLiquidacion.find(
            (liq) => liq.value.toString() === inputValue.toString()
          )
        }
      });
    } else {
      setFormLiquidacion({ ...formLiquidacion, [inputName]: inputValue });
      onCadenaChange({
        ...cadena,
        [tipoMovimiento]: {
          ...formLiquidacion,
          [inputName]: inputValue
        }
      });
    }
  };
  useEffect(() => {
    setFormLiquidacion({
      ...formLiquidacion,
      tipoSelected: tiposLiquidacion.find((liq) => liq.value.toString() === (formLiquidacion.tipo || '1'))
    });
    onCadenaChange({
      ...cadena,
      [tipoMovimiento]: {
        ...formLiquidacion,
        tipoSelected: tiposLiquidacion.find((liq) => liq.value.toString() === (formLiquidacion.tipo || '1'))
      }
    });
    // eslint-disable-next-line
  }, [tiposLiquidacion]);

  useEffect(() => {
    if (activeCleanLiquida) {
      setFormLiquidacion(initialValues);
      dispatch(disableCleanLiquidacion());
    }
  }, [activeCleanLiquida, dispatch]);

  useEffect(() => {
    if (tiposLiquidacion.length > 0) {
      setFormLiquidacion({
        ...formLiquidacion,
        tipoSelected: tiposLiquidacion.find((liq) => liq.value.toString() === (formLiquidacion.tipo || '1'))
      });
      onCadenaChange({
        ...cadena,
        [tipoMovimiento]: {
          ...formLiquidacion,
          tipoSelected: tiposLiquidacion.find((liq) => liq.value.toString() === (formLiquidacion.tipo || '1'))
        }
      });
    }

    // eslint-disable-next-line
  }, [])


  return (
    <div>
      <div
        className="flex-column"
        style={{ width: '80%', margin: '0px auto', alignItems: 'center', gap: '20px' }}
      >
        <SelectInput
          id="tipo"
          name="tipo"
          onChange={onChange}
          label="Tipo"
          data={tiposLiquidacion.filter((tipo => !isCadena || nIdTipoLiquidacion24Hrs !== tipo.nIdTipoLiquidacion))}
          value={tiposLiquidacion.length > 0 ? formLiquidacion.tipo : ''}
          sxFormControl={{ width: '48%' }}
        />


        <Collapse in={parseInt(formLiquidacion?.tipoSelected?.nIdTipoLiquidacion) === nIdTipoLiquidacion24Hrs}>
          <Grid container spacing={2} xs={{ width: "100%" }}>
            <Grid item xs={12}>

              <Typography variant="p" component="p" className="liquidacion__label-days" sx={{ width: "100%" }}>
                CONFIGURACIÓN
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <IvaInput
                id="nMontoBase"
                name="nMontoBase"
                label="Monto base"
                value={formLiquidacion?.nMontoBase}
                onChangeRoot={onChange}
                variant="outlined"
                fullWidth
                type="number"
                size="medium"
                ivaValue={16}
                startAdornment={<InputAdornment position="start">$</InputAdornment>}
                require
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextInput
                id={"nPerTransaccion"}
                name={"nPerTransaccion"}
                value={formLiquidacion?.nPerTransaccion}
                onChangeRoot={onChange}
                label="Porcentaje por transacción"
                endAdornment={<InputAdornment position="end">%</InputAdornment>}
                require
              />
            </Grid>
          </Grid>
        </Collapse>

        <Collapse in={formLiquidacion.tipoSelected?.nExcedente === 1 ? true : false}>
          <Typography variant="p" component="p" className="liquidacion__label-days">
            Excedente
          </Typography>
        </Collapse>

        {formLiquidacion.tipoSelected && formLiquidacion.tipoSelected.nPorDia === 1 && (
          <PayMonth
            form={formLiquidacion}
            onChange={onChange}
            tipoMovimiento={tipoMovimiento}
          />
        )}

        {formLiquidacion.tipoSelected && formLiquidacion.tipoSelected.nPorDia === 0 && (
          <PayWeek
            form={formLiquidacion}
            onChange={onChange}
            tipoMovimiento={tipoMovimiento}
          />
        )}



      </div>
    </div>
  );
};

const PayWeek = ({ form, onChange, tipoMovimiento }) => {
  return (
    <Fade in={form?.tipoSelected?.nPorDia === 0 ? true : false}>
      <div className="flex-column" style={{ width: '80%', margin: '0px auto' }}>
        <div className="flex-row space-between" style={{ marginBottom: '20px' }}>
          <Typography
            variant="p"
            component="p"
            className="liquidacion__label-days"
          >
            Día de corte
          </Typography>
          <Typography
            variant="p"
            component="p"
            className="liquidacion__label-days"
          >
            Se paga el día
          </Typography>
        </div>
        {daysOfWeek.map((day, i) => (
          <div
            className="flex-row space-between"
            key={i + day.idName + tipoMovimiento}
            style={{ marginBottom: '10px' }}
          >
            <TextInput
              id={day.idName + tipoMovimiento}
              name={day.idName}
              label="Corte"
              value={day.text}
              onChangeRoot={() => { }}
              style={{ width: '45%' }}
              disabled
            />
            <SelectInput
              id={day.idName}
              name={day.idName}
              onChange={onChange}
              label="Día"
              data={daysOfWeek.slice(0, 5)}
              value={form[day.idName] ? form[day.idName] : ''}
              sxFormControl={{ width: '48%' }}
            />
          </div>
        ))}
      </div>
    </Fade>
  );
};

const PayMonth = ({ onChange, form, tipoMovimiento }) => {
  return (
    <Grow in={form?.tipoSelected?.nPorDia === 1 ? true : false}>
      <div style={{ width: '100%', textAlign: 'center' }}>
        <SelectInput
          id={"pagoMes" + tipoMovimiento}
          name="pagoMes"
          onChange={onChange}
          label="Día"
          data={daysOfMonth}
          value={form?.pagoMes ?? ''}
          sxFormControl={{ width: '48%' }}
        />
      </div>
    </Grow>
  );
};