import { useState, useRef, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getFacturas, getFacturasOrdenes, getFacturasValidas, insertFacturasOrdenes, validarFacturas } from '../slices/facturacion/facturacionThunk';
import { useModal } from './useModal';
import { xml2js } from "xml-js";
import { closeFacturasOrdenes, closeImport, openFacturasOrdenes, openImport } from '../slices/ui/uiSlice';
import { signedURL, subirArchivoS3 } from '../slices/cadena/cadenaThunk';
import { facturasForm } from '../helpers/forms';
import { startLoadCadenas, startLoadEmisores } from '../slices/catalogo/catalgoThunk';
import { setFacturasBadge, setFacturasCadenas } from '../slices/facturacion/facturacionSlice';
import { saveAs } from 'file-saver';

export const useValidacionFacturasPage = () => {
  const dispatch = useDispatch();
  const scrollDownRef = useRef(null);
  const { isOpenImport } = useSelector((state) => state.ui);
  const [activeStep, setActiveStep] = useState(0);
  const [fileData, setFileData] = useState([])
  const [isOpenConfirmDialog, setIsOpenConfirmDialog] = useState(false);
  const { setOpenToast } = useModal();

  //---- UPLOADING
  const [progress, setProgress] = useState(0);
  const [log, setLog] = useState([]);
  const [processMessage, setProcessMessage] = useState("")

  const [form, setForm] = useState(facturasForm);
  const [filtersLoaded, setFiltersLoaded] = useState(false)

  const {
    dataTable,
    facturasCadenas,
    ordenesPagoCadenas,
    facturasBadge
  } = useSelector((state) => state.facturacion);

  const {
    emisores,
    cadenas,
  } = useSelector((state) => state.catalogo);


  useEffect(() => {
    if (isOpenImport) {
      setActiveStep(0);
      setIsOpenConfirmDialog(false);
      setProgress(0);
      setLog([]);
      setFileData([]);
      setProcessMessage("")
    }
  }, [isOpenImport]);



  const loadInInit = async () => {
    await getFacturasData();
  }

  const getFacturasData = async () => {
    let resp = await dispatch(getFacturasValidas(form));
    if (resp.rows && form === facturasForm) {
      const innerResp = resp.rows.filter(row => row.nActor === 2 && row.nEstatusAsociar === 0)
      dispatch(setFacturasCadenas(innerResp))
      dispatch(setFacturasBadge(innerResp.length))
    }
  }

  const getFilters = async () => {
    if (!filtersLoaded) {
      await dispatch(startLoadCadenas());
      await dispatch(startLoadEmisores());

      setFiltersLoaded(true)
    }
  }

  const onChange = (e, name = null, value = null) => {
    const inputName = name !== null ? name : e.target.name;
    const inputValue = value !== null ? value : e.target.value;

    let valueForm = { ...form, [inputName]: inputValue };

    if (inputName === "nActor" && parseInt(inputValue) === 0) {
      valueForm = { ...valueForm, nIdCadena: 0, nIdEmisor: 0 };
    }

    setForm(valueForm);
  };

  const handleOpenImport = () => {
    dispatch(openImport())
  };

  const handleOpenFacturasOrdenes = async () => {
    await getFilters()
    await dispatch(getFacturasOrdenes())
    dispatch(openFacturasOrdenes())
  };

  const handleCloseImport = async(isProcessEnded = false) => {
    dispatch(closeImport())
    if (isProcessEnded) {
      await getFacturasData();
    }
  };

  const openXMLFile = async (file) => {
    const res = await dispatch(signedURL(file))
    if (res.ok) {
      fetch(res.url)
        .then(res => res.blob())
        .then((blob) => {
          const name = file.split("/")
          saveAs(blob, name[name.length-1]);
        })
    } else {
      setOpenToast(true, res.msg)
    }
  }

  const confirmDelete = () => {
    setLog([]);
    setIsOpenConfirmDialog(true);
  };

  const postFacturasOrdenes = async (data) =>{
    await dispatch(insertFacturasOrdenes(data)).then(async(resp) => {
      if(resp.nCodigo === 0){
        setOpenToast(false, resp.sMensaje);
        dispatch(closeFacturasOrdenes());
        await getFacturasData();
      }else{
        setOpenToast(true, resp.sMensaje);
      }
    })
  }

  const importMethod = async () => {
    setIsOpenConfirmDialog(false);
    setActiveStep(2);

    let innerProgress = 0;
    let innerLog = [];
    const loteLength = 10;
    const innerFileData = fileData.filter(file => !file.nError)
    let cantidadLotes = Math.ceil(innerFileData.length / loteLength);
    const stepValue = (100 / cantidadLotes)/2;

    setProgress(0);
    setProcessMessage("Subiendo archivos: ")
    //Se recorren los lotes (Subida de archivos)
    const modifiedFileData = await Promise.all(innerFileData.map(async (factura, key) => {
      const response = await uploadDocument(factura)
      innerProgress += stepValue;
      setProgress(innerProgress);
      return ({
        ...factura,
        ...response
      })
    }))

    console.log(modifiedFileData)


    //Se recorren los lotes (Validación de facturas)
    for (let i = 0; i < cantidadLotes; i++) {
      scrollToBottom();

      //Se obtiene el array del lote
      let loteArray = [];
      let initialIndex = i * loteLength;
      for (let j = 0; j < loteLength; j++) {
        let index = initialIndex + j;

        if (index < modifiedFileData.length) loteArray.push(modifiedFileData[index]);
      }

      await importRequest(initialIndex, loteArray).then((res) => {

        res = res.map(item => {
          const sNombre = loteArray.find(lote => lote.UUID === item.sUUID)?.sNombre
          return ({
            ...item,
            sNombre
          })
        })

        innerLog = [...innerLog, ...res];
        innerProgress += stepValue;
        setProgress(innerProgress);
        setLog(innerLog);

        scrollToBottom();
      });
    }
    setActiveStep(3);
    scrollToBottom();
  }

  const uploadDocument = async (document) => {
    return new Promise(async (resolve, reject) => {
      const sAbrevDoc = `${document.sRFC}_${document.UUID}.xml`
      await dispatch(subirArchivoS3(document.file, "facturas", document.sRFC, { sAbrevDoc })).then(resp => {
        resolve({
          nError: resp.ok ? 0 : 1,
          sMensaje: resp.msg,
          sRuta: resp.key
        })
      })
    })
  }

  const importRequest = async (initialIndex, loteArray) => {
    return new Promise(async (resolve, reject) => {
      await dispatch(validarFacturas(loteArray)).then((res) => {
        console.log(res)
        resolve(res);
      });
    });
  };

  const handleDrop = (e) => {
    let files = e.dataTransfer ? e.dataTransfer.files : e.target.files;
    let finalData = [...fileData]
    const nId = fileData.length + 1
    for (let i = 0; i < files.length; i++) {
      const file = files[i]
      if (isExtensionAllowed(file.name)) {

        const reader = new FileReader();
        reader.readAsText(file);
        reader.onload = (e) => {
          let data = e.target.result;
          data = xml2js(data, { compact: true, ignoreComment: true, alwaysChildren: true });
          const dataValidated = validateData(data)
          console.log(finalData.some(item => item.UUID === dataValidated.UUID))
          if (!finalData.some(item => (item.UUID === dataValidated.data.UUID) && item.UUID)) {
            finalData.push({
              nId: nId + i,
              sNombre: file.name,
              nError: dataValidated.nError,
              sMensaje: dataValidated.sMensaje,
              file,
              dataValidated: dataValidated.data,
              data,
              UUID: dataValidated.data.UUID,
              sRFC: dataValidated.data.sRFC
            })
          } else {
            finalData.push({
              nId: nId + i,
              sNombre: file.name,
              nError: 1,
              sMensaje: "La factura está repetida",
              file,
              dataValidated: dataValidated.data,
              data: [],
              UUID: null,
              sRFC: null
            })
          }
          if (i + 1 === files.length) {
            console.log(finalData)
            setFileData(finalData)
          }
        }

      } else {
        finalData.push({
          nId: nId + i,
          sNombre: file.name,
          nError: 1,
          sMensaje: "El Archivo XML no se puede leer o está corrupto",
          file,
          dataValidated: {},
          data: [],
          UUID: null,
          sRFC: null
        })
        if (i + 1 === files.length) {
          console.log(finalData)
          setFileData(finalData)
        }
      }

    }
  };

  const validateData = (data) => {
    let sMensajeError = []
    const cfdiComprobante = data["cfdi:Comprobante"]["_attributes"];
    const version = cfdiComprobante["Version"];
    let cfdi = {}
    if (version === '4.0') {
      cfdi = formatCfdiData(data)
      const { comprobante, emisor, receptor, conceptos, impuestosTrasladado, impuestosRetencion, UUID } = cfdi

      //Validación de pago
      if (comprobante.formaPago != "99" && comprobante.formaPago != "03") sMensajeError.push(" La forma de pago es incorrecta, debe seleccionar 99  o 03.");
      if (comprobante.formaPago == "99" && comprobante.metodoPago != "PPD") sMensajeError.push(" El método de pago es incorrecto, debe seleccionar PPD.");
      if (comprobante.formaPago == "03" && comprobante.metodoPago != "PUE") sMensajeError.push(" El método de pago es incorrecto, debe seleccionar PUE.");

      //Encabezado
      if (comprobante.tipoComprobante != "I") sMensajeError.push(" El tipo de comprobante es incorrecto, debe seleccionar I.");
      if (receptor.usoCfdi != "G03") sMensajeError.push(" El Uso del CFDI es incorrecto, debe seleccionar  G03.");

      //Conceptos
      const clavesPermitidas = ["80141628", "84121500"]
      if (!clavesPermitidas.includes(conceptos.claveProdServ)) sMensajeError.push(" La clave del producto o servicio es incorrecta, debe seleccionar \"80141628\" o \"84121500\".");
      if (conceptos.cantidad != 1) sMensajeError.push(" La cantidad en el concepto es incorrecta, debe seleccionar 1.");
      if (conceptos.claveUnidad != "E48") sMensajeError.push(" La clave de unidad es incorrecta, debe seleccionar E48.");

      //Impuestos
      if (impuestosTrasladado.impuesto != "002") sMensajeError.push(" El tipo de  impuesto trasladado es incorrecto, debe seleccionar 002.");
      if (impuestosTrasladado.tipoFactor != "Tasa") sMensajeError.push(" El tipo de factor del impuesto trasladado es incorrecto, debe seleccionar Tasa.");

    } else {
      sMensajeError.push(" La Version del XML no es Admitida, utilize la version 4.0")
    }
    console.log(sMensajeError, cfdi)
    return ({
      nError: sMensajeError.length > 0 ? 1 : 0,
      sMensaje: sMensajeError.length > 0 ? sMensajeError.join(" ") : "Listo para validación",
      data: cfdi
    })
  }

  const formatCfdiData = (data) => {
    const cfdiComprobante = data["cfdi:Comprobante"]["_attributes"];
    const cfdiEmisor = data["cfdi:Comprobante"]["cfdi:Emisor"]["_attributes"];
    const cfdiReceptor = data["cfdi:Comprobante"]["cfdi:Receptor"]["_attributes"];
    const cfdiConceptos = data["cfdi:Comprobante"]["cfdi:Conceptos"]["cfdi:Concepto"]["_attributes"];
    const cfdiImpuestosTraslado = data["cfdi:Comprobante"]["cfdi:Conceptos"]["cfdi:Concepto"]["cfdi:Impuestos"]["cfdi:Traslados"]["cfdi:Traslado"]["_attributes"];
    const cfdiImpuestosRetencion = data["cfdi:Comprobante"]["cfdi:Conceptos"]["cfdi:Concepto"]["cfdi:Impuestos"]["cfdi:Retenciones"]["cfdi:Retencion"]["_attributes"];
    const cfdiComplemento = data["cfdi:Comprobante"]["cfdi:Complemento"]["tfd:TimbreFiscalDigital"]["_attributes"];

    return ({
      comprobante: {
        version: cfdiComprobante["Version"],
        lugarExpedicion: cfdiComprobante["LugarExpedicion"],
        metodoPago: cfdiComprobante["MetodoPago"],
        tipoComprobante: cfdiComprobante["TipoDeComprobante"],
        total: cfdiComprobante["Total"],
        moneda: cfdiComprobante["Moneda"],
        subtotal: cfdiComprobante["SubTotal"],
        formaPago: cfdiComprobante["FormaPago"],
        fecha: cfdiComprobante["Fecha"],
      },
      emisor: {
        rfc: cfdiEmisor["Rfc"],
        nombre: cfdiEmisor["Nombre"],
        regimenFiscal: cfdiEmisor["RegimenFiscal"],
        regimensss: cfdiEmisor["Rfc"].length === 13 ? 1 : 2
      },
      receptor: {
        rfc: cfdiReceptor["Rfc"],
        nombre: cfdiReceptor["Nombre"],
        regimenFiscal: cfdiReceptor["RegimenFiscalReceptor"],
        domicilioFiscal: cfdiReceptor["DomicilioFiscalReceptor"],
        usoCfdi: cfdiReceptor["UsoCFDI"],
      },
      conceptos: {
        claveProdServ: cfdiConceptos["ClaveProdServ"],
        cantidad: cfdiConceptos["Cantidad"],
        claveUnidad: cfdiConceptos["ClaveUnidad"],
        descripcion: cfdiConceptos["Descripcion"],
      },
      impuestosTrasladado: {
        impuesto: cfdiImpuestosTraslado["Impuesto"],
        tipoFactor: cfdiImpuestosTraslado["TipoFactor"],
        tasaCuota: cfdiImpuestosTraslado["TasaOCuota"],
        importe: cfdiImpuestosTraslado["Importe"]
      },
      ...(cfdiEmisor["Rfc"].length === 13 ? {
        impuestosRetencion: {
          impuesto: cfdiImpuestosRetencion["Impuesto"],
          tipoFactor: cfdiImpuestosRetencion["TipoFactor"],
          tasaCuota: cfdiImpuestosRetencion["TasaOCuota"],
          importe: cfdiImpuestosRetencion["Importe"]
        }
      } : {}),
      UUID: cfdiComplemento["UUID"],
      sRFC: cfdiEmisor["Rfc"]
    })
  }

  const deleteFile = (file) => {
    let finalData = fileData.filter(fileData => fileData.nId !== file.nId)
    setFileData(finalData)
  }

  const isExtensionAllowed = (fileName) => {
    const extension = fileName.split(".").pop();
    return ["xml", "XML"].includes(extension);
  };

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const scrollToBottom = () => {
    scrollDownRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  return {
    isOpenConfirmDialog,
    setIsOpenConfirmDialog,
    fileData,
    importMethod,
    isOpenImport,
    activeStep,
    handleDrop,
    deleteFile,
    progress,
    log,
    scrollDownRef,
    handleBack,
    confirmDelete,
    handleNext,
    handleCloseImport,
    handleOpenImport,
    processMessage,
    loadInInit,
    dataTable,
    onChange,
    getFacturasData,
    emisores,
    cadenas,
    getFilters,
    form,
    openXMLFile,
    facturasCadenas,
    ordenesPagoCadenas,
    facturasBadge,
    handleOpenFacturasOrdenes,
    postFacturasOrdenes
  };
};
