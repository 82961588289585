import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  nIdConfConexion: 0,
  tipoConciliacion: ''
};

export const configConciliacionSlice = createSlice({
  name: 'configConciliacion',
  initialState,
  reducers: {
    setConfigId: (state, action) => {
      state.nIdConfConexion = action.payload;
    },
    setConfigTipo: (state, action) => {
      state.tipoConciliacion = action.payload;
    }
  }
});

export const { setConfigId, setConfigTipo } = configConciliacionSlice.actions;
