import {
  startLoadCadenas,
  startLoadEmisores,
  startLoadEscenariosContables,
} from "../slices/catalogo/catalgoThunk";
import { useSelector, useDispatch } from "react-redux";

import { polizasForm } from "../helpers/forms";
import { useEffect, useState} from "react";
import { getPolizaData, getPolizasData } from "../slices/contabilidad/contabilidadThunk";
import { setPolizasTable } from "../slices/contabilidad/contabilidadSlice";
import { useLocation } from "react-router-dom";

export const usePolizasPage = () => {
  let location = useLocation();
  const { escenariosContables, cadenas, emisores } = useSelector(
    (state) => state.catalogo
  );
  const { polizasTable } = useSelector((state) => state.contabilidad);

  const [form, setForm] = useState(polizasForm);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setPolizasTable({
      columns: [],
      rows: []
    }));
  }, [location]);

  const loadInInit = async () => {
    await dispatch(startLoadEscenariosContables());
    await dispatch(startLoadCadenas());
    await dispatch(startLoadEmisores());
  };

  const getPoliza = async (nIdPoliza) => {
    return await dispatch(getPolizaData(nIdPoliza));
  };

  const getPolizas = async () => {
    dispatch(setPolizasTable({
      columns: [],
      rows: []
    }));
    await dispatch(getPolizasData(form));
  };

  const onChange = (e, name = null, value = null) => {
    const inputName = name !== null ? name : e.target.name;
    const inputValue = value !== null ? value : e.target.value;
    const switchOptions = ["nDetalle"];

    if (switchOptions.includes(inputName)) {
      setForm({ ...form, [inputName]: e.target.checked });
    } else if(inputName === "nIdEscenario"){
      setForm({ ...form, 
        nIdActor: 0,
        [inputName]: inputValue
      });
    }else {
      let valueForm = { ...form, [inputName]: inputValue };
      setForm(valueForm);
    }
  };

  const onChangeInput = async (value) => {
    setForm({ ...form, ...value });
  };

  return {
    loadInInit,
    escenariosContables,
    getPolizas,
    onChange,
    form,
    polizasTable,
    onChangeInput,
    getPoliza,
    cadenas,
    emisores
  };
};
