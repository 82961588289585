import { useEffect } from 'react';
import { useMenu } from '../hooks/useMenu';
import { useAuthPage } from '../hooks/useAuthPage';
import { CadenasPage } from '../pages/CadenasPage';
import { NotAllowPage } from '../pages/NotAllowPage';

export const CadenasModule = () => {
  let name = '';
  const { setNameSection } = useMenu();
  const { setNewPage, isAllowed } = useAuthPage();
  
  useEffect(() => {
    setNewPage('agregarCadenas');
    setNameSection(name);
  }, [setNewPage, setNameSection, name]);

  if (isAllowed) {
    name = 'agregarCadenas';
    return <CadenasPage />;
  } else {
    name = '';
    return <NotAllowPage />;
  }
};
