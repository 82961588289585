import { useSelector } from 'react-redux';
import { EscCantidadForm } from './EscCantidadForm';

export const EscCantidadForms = ({isEditModal, onlyView}) => {
  const { escCantidadForms = [] } = useSelector((state) =>  state.cadena);

  return (
    <div style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
    {escCantidadForms.map((form, i ) => (
      <EscCantidadForm 
        escalonado={form}
        key={form.id}
        id={form.id}
        isFirst={i === 0}
        isEditModal={isEditModal}
        onlyView={onlyView}
      />
    ))}
  </div>
  )
}
