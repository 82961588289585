import { createSlice } from '@reduxjs/toolkit';
import { examplePermissions } from '../../helpers/forms';

const userAuth = localStorage.getItem('userAuth');
const permisos = localStorage.getItem('permisos');
const titulos = localStorage.getItem('titulos');

const initialState = {
  user: userAuth ? JSON.parse(localStorage.getItem('userAuth')) : null,
  temporalPassword: false,
  password: '',
  isLogin: false,
  titulos: [],
  permisos: [],
  idUsuario: 0,
};

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    login: (state, action) => {
      state.isLogin = action.payload;
    },

    setUser: (state, action) => {
      state.user = action.payload;
    },
    setTemporalPassword: (state, action) => {
      state.temporalPassword = action.payload;
    },
    setPassword: (state, action) => {
      state.password = action.payload;
    },
    setPermisos: (state, action) => {
      state.permisos = action.payload;
    },
    setTitulos: (state, action) => {
      state.titulos = action.payload;
    },
    setIdUsuario: ( state, action ) => {
      state.idUsuario = action.payload
    }
  }
});

export const {
  login,
  setUser,
  setTemporalPassword,
  setPassword,
  setPermisos,
  setTitulos,
  setIdUsuario
} = userSlice.actions;
