import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isOpenContact: { open: false, edit: false, view: true, contact: null },
  isOpenListContact: false,
  isOpenListContactButtons: true,
  isOpenRoute: false,
  isOpenListRoute: false,
  isOpenPermission: false,
  isOpenListPermission: false,
  isOpenOrdenPago: false,
  ordenPagoType: "pagado",
  isOpenViewPdf: { open: false, file64: "", fileName: "" },
  isOpenViewDoc: { open: false, path: "", fileType: "", fileName: "" },
  isLoading: false,
  isTableLoading: false,
  isTableModalLoading: false,
  isOpenToast: { open: false, error: true, message: "" },
  isOpenConciliacion: false,
  isOpenConfirmation: { open: false, idCadena: null },
  isOpenEnvioOperaciones: { open: false, nIdActor: null, type: "" },
  isOpenProducts: false,
  isOpenFacturacion: {
    open: false,
    title: "",
    refForm: "transferencia",
    type: "Agregar",
    iva: "",
  },
  isOpenDialogHelp: {
    open: false,
    title: "",
    text: "",
  },
  isOpenDelete: { open: false, id: null, title: "", type: "" },
  isOpenImport: false,
  isOpenImportConciliacion: false,
  isOpenConfirmProductsDialog: false,
  isOpenRegistrosCortes: false,
  isOpenRegistrosOrdenes: false,
  isOpenFacturasOrdenes: false,
  isOpenComisionistas: false,
  isOpenEnvioEmail: false,
  isOpenComisionesEscalonadas: {
    open: false,
    nIdEmisor: 0,
    sNombreComercial: "",
    has24Horas: false,
  },
  isOpenAccesoR26: {
    open: false,
    emisor: {},
  },
  isOpenArchivoConciliacion: {
    open: false,
    archivo: {},
  },
  isOpenOpsReferencia: {
    open: false,
    transaccion: {},
  },
  isOpenConexionesSFTP: {
    open: false,
    nIdEmisor: null,
    nIdCadena: null,
    oData: {
      aArchivos: [],
      aConexiones: [],
      aConexionesSofiPay: [],
    },
  },
  isOpenBitacoraConciliacion: {
    open: false,
    nIdEmisor: 0,
    nIdCadena: 0,
    sNombreComercial: "",
    dFechaConciliacion: "",
    sClaveProducto: "",
  },
  isOpenEquivalences: false,
  isOpenBitacora: false,
};

export const uiSlice = createSlice({
  name: "ui",
  initialState,
  reducers: {
    openToast: (state, action) => {
      state.isOpenToast = {
        open: true,
        error: action.payload.error,
        message: action.payload.message,
      };
    },
    closeToast: (state) => {
      state.isOpenToast.open = false;
      state.isOpenToast.message = "";
    },
    openEnvioOperaciones: (state, action) => {
      state.isOpenEnvioOperaciones = {
        open: true,
        nIdActor: action.payload.nIdActor,
        type: action.payload.type,
      };
    },
    closeEnvioOperaciones: (state) => {
      state.isOpenEnvioOperaciones = initialState.isOpenEnvioOperaciones;
    },
    openContact: (state, { payload }) => {
      state.isOpenContact = {
        open: true,
        edit: payload.edit,
        view: payload.view,
        contact: payload.contact,
      };
    },
    closeContact: (state) => {
      state.isOpenContact = {
        ...state.isOpenContact,
        open: false,
        edit: false,
        view: false,
      };
    },
    openListContact: (state) => {
      state.isOpenListContact = true;
    },
    closeListContact: (state) => {
      state.isOpenListContact = false;
      state.isOpenListContactButtons = true;
    },
    enableListContactButtons: (state) => {
      state.isOpenListContactButtons = true;
    },
    disableListContactButtons: (state) => {
      state.isOpenListContactButtons = false;
    },
    openRegistrosCortes: (state) => {
      state.isOpenRegistrosCortes = true;
    },
    closeRegistrosCortes: (state) => {
      state.isOpenRegistrosCortes = false;
    },
    openRegistrosOrdenes: (state) => {
      state.isOpenRegistrosOrdenes = true;
    },
    closeRegistrosOrdenes: (state) => {
      state.isOpenRegistrosOrdenes = false;
    },
    openFacturasOrdenes: (state) => {
      state.isOpenFacturasOrdenes = true;
    },
    closeFacturasOrdenes: (state) => {
      state.isOpenFacturasOrdenes = false;
    },
    openEnvioEmail: (state) => {
      state.isOpenEnvioEmail = true;
    },
    closeEnvioEmail: (state) => {
      state.isOpenEnvioEmail = false;
    },
    openRoute: (state) => {
      state.isOpenRoute = true;
      state.isOpenListRoute = false;
    },
    closeRoute: (state) => {
      state.isOpenRoute = false;
      state.isOpenListRoute = true;
    },
    openImport: (state) => {
      state.isOpenImport = true;
    },
    closeImport: (state) => {
      state.isOpenImport = false;
    },
    openComisionistas: (state) => {
      state.isOpenComisionistas = true;
    },
    closeComisionistas: (state) => {
      state.isOpenComisionistas = false;
    },
    openImportConciliacion: (state) => {
      state.isOpenImportConciliacion = true;
    },
    closeImportConciliacion: (state) => {
      state.isOpenImportConciliacion = false;
    },
    openProducts: (state) => {
      state.isOpenProducts = true;
    },
    closeProducts: (state) => {
      state.isOpenProducts = false;
    },
    openConfirmProductsDialog: (state) => {
      state.isOpenConfirmProductsDialog = true;
    },
    closeConfirmProductsDialog: (state) => {
      state.isOpenConfirmProductsDialog = false;
    },
    openListRoute: (state) => {
      state.isOpenListRoute = true;
      state.isOpenRoute = false;
    },
    closeListRoute: (state) => {
      state.isOpenListRoute = false;
      state.isOpenListPermission = false;
    },
    openPermission: (state) => {
      state.isOpenPermission = true;
      state.isOpenListPermission = false;
    },
    closePermission: (state) => {
      state.isOpenPermission = false;
      state.isOpenListPermission = true;
    },
    openListPermission: (state) => {
      state.isOpenListPermission = true;
      state.isOpenPermision = false;
    },
    closeListPermission: (state) => {
      state.isOpenListPermission = false;
      state.isOpenListRoute = false;
    },
    openViewPdf: (state, { payload }) => {
      state.isOpenViewPdf = {
        open: true,
        file64: payload.file,
        fileName: payload.name,
      };
    },
    closeViewPdf: (state) => {
      state.isOpenViewPdf = { open: false, file64: "", fileName: "" };
    },
    openViewDoc: (state, { payload }) => {
      state.isOpenViewDoc = {
        open: true,
        path: payload.path,
        fileType: payload.fileType,
        fileName: payload.fileName,
      };
    },
    closeViewDoc: (state) => {
      state.isOpenViewDoc = {
        open: false,
        path: "",
        fileType: "",
        fileName: "",
      };
    },
    openConciliacion: (state) => {
      state.isOpenConciliacion = true;
    },
    closeConciliacion: (state) => {
      state.isOpenConciliacion = false;
    },
    openOrdenPago: (state) => {
      state.isOpenOrdenPago = true;
    },
    closeOrdenPago: (state) => {
      state.isOpenOrdenPago = false;
    },
    setOrdenPagoType: (state, action) => {
      state.ordenPagoType = action.payload;
    },
    loading: (state, action) => {
      state.isLoading = action.payload;
    },
    tableLoading: (state, action) => {
      state.isLoading = action.payload;
      state.isTableLoading = action.payload;
    },
    tableModalLoading: (state, action) => {
      state.isLoading = action.payload;
      state.isTableModalLoading = action.payload;
    },
    openConfirmation: (state, action) => {
      state.isOpenConfirmation = { open: true, idCadena: action.payload };
    },
    closeConfirmation: (state) => {
      state.isOpenConfirmation = { open: false, idCadena: null };
    },
    openFacturacion: (state, { payload }) => {
      state.isOpenFacturacion = {
        open: true,
        title: payload.title,
        refForm: payload.refForm,
        type: payload.type,
        iva: payload.iva,
      };
    },
    closeFacturacion: (state) => {
      state.isOpenFacturacion = {
        ...state.isOpenFacturacion,
        open: false,
        title: "",
        type: "Agregar",
        iva: "",
      };
    },
    openDialogHelp: (state, { payload }) => {
      state.isOpenDialogHelp = {
        open: true,
        title: payload.title,
        text: payload.text,
      };
    },
    closeDialogHelp: (state) => {
      state.isOpenDialogHelp.open = false;
    },
    openDelete: (state, { payload }) => {
      state.isOpenDelete = {
        open: true,
        id: payload.id,
        title: payload.title,
        type: payload.type,
      };
    },
    closeDelete: (state) => {
      state.isOpenDelete.open = false;
    },
    openComisionesEscalonadas: (state, { payload }) => {
      state.isOpenConfirmation = { open: false, idCadena: null };
      console.log(payload);
      state.isOpenComisionesEscalonadas = {
        open: true,
        nIdEmisor: payload.nIdEmisor,
        sNombreComercial: payload.sNombreComercial,
        has24Horas: payload.has24Horas,
      };
    },
    closeComisionesEscalonadas: (state) => {
      state.isOpenComisionesEscalonadas = {
        open: false,
        nIdEmisor: 0,
        sNombreComercial: "",
        has24Horas: false,
      };
    },
    openAccesoR26: (state, { payload }) => {
      state.isOpenAccesoR26 = {
        open: true,
        emisor: payload.emisor,
      };
    },
    closeAccesoR26: (state) => {
      state.isOpenAccesoR26 = {
        open: false,
        emisor: {},
      };
    },
    openArchivoConciliacion: (state, { payload }) => {
      state.isOpenArchivoConciliacion = {
        open: true,
        archivo: payload.archivo,
      };
    },
    closeArchivoConciliacion: (state) => {
      state.isOpenArchivoConciliacion = {
        open: false,
        archivo: {},
      };
    },
    openOpsReferencia: (state, { payload }) => {
      state.isOpenOpsReferencia = {
        open: true,
        transaccion: payload.transaccion,
      };
    },
    closeOpsReferencia: (state) => {
      state.isOpenOpsReferencia = {
        open: false,
        transaccion: {},
      };
    },
    openConexionesSFTP: (state, { payload }) => {
      state.isOpenConexionesSFTP = {
        open: true,
        nIdCadena: payload.nIdCadena,
        nIdEmisor: payload.nIdEmisor,
      };
    },
    closeConexionesSFTP: (state) => {
      state.isOpenConexionesSFTP = {
        open: false,
        nIdCadena: null,
        nIdEmisor: null,
      };
    },
    openBitacoraConciliacion: (state, { payload }) => {
      state.isOpenBitacoraConciliacion = {
        open: true,
        nIdCadena: payload.nIdCadena,
        nIdEmisor: payload.nIdEmisor,
        sNombreComercial: payload.sNombreComercial,
        dFechaConciliacion: payload.dFechaConciliacion,
        sClaveProducto: payload.sClaveProducto,
      };
    },
    closeBitacoraConciliacion: (state) => {
      state.isOpenBitacoraConciliacion = {
        open: false,
        nIdCadena: 0,
        nIdEmisor: 0,
        sNombreComercial: "",
        dFechaConciliacion: "",
        sClaveProducto: "",
      };
    },
    openEquivalences: (state) => {
      state.isOpenEquivalences = true;
    },
    closeEquivalences: (state) => {
      state.isOpenEquivalences = false;
    },
    openBitacora: (state) => {
      state.isOpenBitacora = true;
    },
    closeBitacora: (state) => {
      state.isOpenBitacora = false;
    },
  },
});

export const {
  openContact,
  closeContact,
  openListContact,
  closeListContact,
  openRoute,
  closeRoute,
  openListRoute,
  closeListRoute,
  openPermission,
  closePermission,
  openListPermission,
  closeListPermission,
  openViewPdf,
  closeViewPdf,
  openToast,
  closeToast,
  openConciliacion,
  closeConciliacion,
  loading,
  tableLoading,
  openConfirmation,
  closeConfirmation,
  openFacturacion,
  closeFacturacion,
  openOrdenPago,
  closeOrdenPago,
  setOrdenPagoType,
  openDialogHelp,
  closeDialogHelp,
  openDelete,
  closeDelete,
  openImport,
  closeImport,
  openProducts,
  closeProducts,
  openConfirmProductsDialog,
  closeConfirmProductsDialog,
  openRegistrosCortes,
  closeRegistrosCortes,
  openRegistrosOrdenes,
  closeRegistrosOrdenes,
  openFacturasOrdenes,
  closeFacturasOrdenes,
  openImportConciliacion,
  closeImportConciliacion,
  openViewDoc,
  closeViewDoc,
  enableListContactButtons,
  disableListContactButtons,
  openComisionistas,
  closeComisionistas,
  openEnvioEmail,
  closeEnvioEmail,
  openComisionesEscalonadas,
  closeComisionesEscalonadas,
  openArchivoConciliacion,
  closeArchivoConciliacion,
  openEnvioOperaciones,
  closeEnvioOperaciones,
  tableModalLoading,
  openOpsReferencia,
  closeOpsReferencia,
  openAccesoR26,
  closeAccesoR26,
  openConexionesSFTP,
  closeConexionesSFTP,
  openBitacoraConciliacion,
  closeBitacoraConciliacion,
  openEquivalences,
  closeEquivalences,
  openBitacora,
  closeBitacora,
} = uiSlice.actions;
