import {
  startLoadCadenas,
  startLoadProductos,
  startLoadEmisores,
  startLoadEstatusPago,
  startLoadFiltrosReportes,
  startLoadIntegradores,
} from "../slices/catalogo/catalgoThunk";
import {
  getOperacionesData,
  getOrdenesPagoData,
  get24HorasData,
  getCortesData,
  getEscalonadoData,
  updateOrdenesPagoEstatus,
  getPagosCobrosData,
  getReporteOperacionesData,
  getReporteGeneralData,
  postLayoutOrdenesPago,
  getComisionesIntegradorData,
  getReporteGeolocalizacionData,
  getReporteSucursalesNoRegistradasData,
  getTransaccionesData
} from "../slices/reports/reportsThunk";
import { useSelector, useDispatch } from "react-redux";

import * as XLSX from "xlsx/xlsx.mjs";
import { reportsForm } from "../helpers/forms";
import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import {
  addSelectedRow,
  deleteSelectedRow,
  setDataTable,
  setSelectedRows,
} from "../slices/reports/reportsSlice";
import {
  setProductos,
  setCatProductos
} from "../slices/catalogo/catalagoSlice";
import {
  closeOrdenPago,
  openOrdenPago,
  setOrdenPagoType,
} from "../slices/ui/uiSlice";
import moment from "moment";
import { useModal } from "./useModal";
import { signedURL } from "../slices/cadena/cadenaThunk";
import { saveAs } from 'file-saver';
import { subirArchivoS3 } from "../slices/conciliacion/conciliacionThunk";

export const useReportsPage = () => {
  const { cadenas, productos, catProductos, emisores, estatusPago, integradores } = useSelector(
    (state) => state.catalogo
  );
  const { dataTable, selectedRows } = useSelector((state) => state.reports);
  const { isOpenOrdenPago, ordenPagoType } = useSelector((state) => state.ui);

  const [form, setForm] = useState(reportsForm);
  const [unificar, setUnificar] = useState(false);

  const dispatch = useDispatch();
  const { type } = useParams();
  const { setOpenToast } = useModal();

  const loadInInit = async () => {

    switch (type) {

      case 'ordenes-pago':
        await dispatch(startLoadFiltrosReportes({ nEmisores: 1, nCadenas: 1, nEstatusPago: 1, nProductos: 1 }));
        break;
      case 'operaciones':
      case 'reporte-operaciones':
        await dispatch(startLoadFiltrosReportes({ nEmisores: 1, nCadenas: 1, nProductos: 1 }));
        break;
      case 'cortes':
        await dispatch(startLoadFiltrosReportes({ nEmisores: 1, nCadenas: 1 }));
        break;
      case '24-horas':
        await dispatch(startLoadFiltrosReportes({ nEmisores: 1 }));
        break;
      case 'comisiones-integrador':
        await dispatch(startLoadIntegradores());
        break;
      case 'geolocalizacion':
        await dispatch(startLoadFiltrosReportes({ nEmisores: 1 }));
        break;
      case 'sucursales-no-registradas':
        await dispatch(startLoadFiltrosReportes({ nCadenas: 1 }));
        break;
      case 'transacciones':
        break;
      default:
        await dispatch(startLoadCadenas());
        await dispatch(startLoadProductos(0, form.productType));
        await dispatch(startLoadEmisores());
        await dispatch(startLoadEstatusPago());
        break;
    }

  };

  useEffect(() => {
    dispatch(
      setDataTable({
        rows: [],
        columns: [],
        numColums: 0,
      })
    );
    dispatch(setSelectedRows([]));
    if (type === "ordenes-pago") {
      setForm({ ...reportsForm, type: 1 });
    } else if (type === "reporte-operaciones" || type === "reporte-general") {
      setForm({ ...reportsForm, dFecInicial: moment().startOf("month").format('YYYY-MM-DD') })
    } else if (type === "geolocalizacion") {
      setForm({ ...reportsForm, nIdEmisor: 0 })
    } else {
      setForm(reportsForm);
    }
  }, [type]);

  useEffect(() => {
    resetModalData();
    setUnificar(false);
  }, [isOpenOrdenPago])

  useEffect(() => {
    if (unificar) onChangeRows(null, null, 'sMotivoCancelacion', '')
  }, [unificar])

  const resetModalData = () => {
    let innerSelectedRows = selectedRows.map((row, innerKey) => {
      return {
        ...row,
        sCodTransaccion: '',
        sMotivoCancelacion: '',
        dFechaPago: ordenPagoType === "pagado" ? moment().format("YYYY-MM-DD") : null,
      }
    });

    dispatch(setSelectedRows(innerSelectedRows));
  }

  const addRow = (row) => {
    row = { ...row, dFechaPago: row.dFechaPago !== '0000-00-00 00:00:00' ? row.dFechaPago : moment().format("YYYY-MM-DD") };
    console.log(row);
    dispatch(addSelectedRow(row));
  };

  const deleteRow = (row) => {
    dispatch(deleteSelectedRow(row));
  };

  const openOrdenPagoModal = (type) => {
    dispatch(openOrdenPago());
    dispatch(setOrdenPagoType(type));
  };

  const closeOrdenPagoModal = () => {
    dispatch(closeOrdenPago());
  };

  const setRows = (rows) => {
    rows = rows.map(row => { return { ...row, dFechaPago: row.dFechaPago !== '0000-00-00 00:00:00' ? row.dFechaPago : moment().format("YYYY-MM-DD") } })
    dispatch(setSelectedRows(rows));
  };

  const changeOrdenesPagoEstatus = async () => {
    let innerSelectedRows = [];
    //selectedRows.map((item) => innerSelectedRows.push(item.nIdOrdenPago));
    selectedRows.map((item) => innerSelectedRows.push(item));
    //innerSelectedRows = innerSelectedRows.join(", ");
    console.log(innerSelectedRows);
    const flag = await dispatch(
      updateOrdenesPagoEstatus(
        form,
        innerSelectedRows,
        ordenPagoType,
        dataTable
      )
    );

    if (flag) await dispatch(getOrdenesPagoData(dataTable.details));
  };

  const getOperaciones = async () => {
    await dispatch(getOperacionesData(form));
  };

  const getTransacciones = async () => {
    await dispatch(getTransaccionesData(form));
  };

  const getOrdenesPago = async () => {
    await dispatch(getOrdenesPagoData(form));
    dispatch(setSelectedRows([]));
  };

  const getEscalonado = async () => {
    await dispatch(getEscalonadoData(form));
  };

  const get24Horas = async () => {
    await dispatch(get24HorasData(form));
  };
  const getCortes = async () => {
    await dispatch(getCortesData(form));
  };

  const getComisionesIntegrador = async () => {
    await dispatch(getComisionesIntegradorData(form));
  };

  const getPagosCobros = async () => {
    await dispatch(getPagosCobrosData(form));
  };

  const getReporteOperaciones = async () => {
    await dispatch(getReporteOperacionesData(form));
  };

  const getReporteGeneral = async () => {
    await dispatch(getReporteGeneralData(form));
  };

  const getReporteGeolocalizacion = async () => {
    await dispatch(getReporteGeolocalizacionData(form));
  };

  const getReporteSucursalesNoRegistradas = async () => {
    await dispatch(getReporteSucursalesNoRegistradasData(form));
  };

  const getOrdenesPagoLayout = async () => {
    const resp = await dispatch(postLayoutOrdenesPago(moment().format("YYYY-MM-DD")));

    if (resp.nCodigo === 0) {
      const dataResp = JSON.parse(resp.data)
      if (!dataResp.length) {
        await downloadLayout(resp.sRutaArchivo)
        return
      }
      const headers = [
        {
          header: "CUENTA DE CARGO",
          value: "sCtaOrigen"
        }, {
          header: "CUENTA DE ABONO",
          value: "sClabe"
        }, {
          header: "BANCO RECEPTOR",
          value: "sBancoBeneficiario"
        }, {
          header: "BENEFICIARIO",
          value: "sBeneficiario"
        }, {
          header: "IMPORTE",
          value: "nTotal"
        }, {
          header: "CONCEPTO",
          value: "sDetalle"
        }, {
          header: "EDO DE CUENTA",
          fixedValue: "N"
        }, {
          header: "RFC",
          value: "sRFCBeneficiaro"
        }, {
          header: "IVA",
          fixedValue: ""
        }, {
          header: "Referencia Ordenante",
          value: "sRefNumerica"
        }, {
          header: "Forma de Aplicación",
          fixedValue: "1"
        },
      ]
      let data = [[...headers.map(item => item.header)]]

      dataResp.map((row) => {
        const item = []
        headers.map(header => {
          item.push(header.fixedValue ? header.fixedValue : row[header.value])
        })
        data.push(item)
      })
      const workbook = XLSX.utils.book_new();
      var worksheet1 = XLSX.utils.aoa_to_sheet(data);
      XLSX.utils.book_append_sheet(workbook, worksheet1, "Ordenes");

      const fileName = resp.sRutaArchivo.split("/")
      const wopts = { bookType: "xlsx", bookSST: false, type: "array" };
      const wbout = XLSX.write(workbook, wopts);

      await uploadDocument(new Blob([wbout], { type: 'application/vnd.ms-excel' }), fileName[fileName.length - 1]).then(async (resp) => {
        console.log(resp)
        await downloadLayout(resp.sRuta)
      })

    } else {
      setOpenToast(true, resp.sMensaje)
    }
  }

  const uploadDocument = async (file, fileName) => {
    return new Promise(async (resolve, reject) => {

      await dispatch(subirArchivoS3(file, 'ordenes-pago', fileName)).then(resp => {
        resolve({
          nError: resp.ok ? 0 : 1,
          sMensaje: resp.msg,
          sRuta: resp.key
        })
      })
    })
  }

  const downloadLayout = async (file) => {
    console.log(file)
    const res = await dispatch(signedURL(file))
    if (res.ok) {
      fetch(res.url)
        .then(res => res.blob())
        .then((blob) => {
          const name = file.split("/")
          saveAs(blob, name[name.length - 1] + ".xlsx");
        })
    } else {
      setOpenToast(true, res.msg)
    }
  }

  const onChange = (e, name = null, value = null) => {
    const inputName = name !== null ? name : e.target.name;
    const inputValue = value !== null ? value : e.target.value;
    const switchOptions = ["nDetalle", "nPersonalizarAgrupacion", "nFiltradoFechas"];

    if (switchOptions.includes(inputName)) {
      setForm({ ...form, [inputName]: e.target.checked, nTipoAgrupacion: 0 });
    } else {
      let valueForm = { ...form, [inputName]: inputValue };
      if (["type"].includes(inputName)) {
        valueForm = {
          ...valueForm,
          nIdCadena: 0,
          nIdEmisor: 0,
        };
      } else if (["dYear"].includes(inputName)) {
        valueForm = {
          ...valueForm,
          dMonth: 0,
        };
      } else if (["productType"].includes(inputName)) {
        console.log(catProductos, 'el inicial de productos');

        if (productos.length === 0) {
          dispatch(startLoadProductos());
        }

        if (catProductos.length === 0) {
          dispatch(setCatProductos(productos));
        }

        if (value != 2) {
          dispatch(setProductos(catProductos.filter((producto) => producto.nFlujoImporte === value)));
        } else {
          dispatch(setProductos(catProductos))
        }

        valueForm = {
          ...valueForm,
          sClaveProducto: 0,
        };
      }

      setForm(valueForm);
      console.log(form);
    }
  };

  const onChangeRows = (key, e, name = null, value = null) => {
    const inputName = name !== null ? name : e.target.name;
    const inputValue = value !== null ? value : e.target.value;

    let innerSelectedRows = selectedRows.map((row, innerKey) => {
      return {
        ...row,
        [inputName]: (innerKey === key || key === null || unificar ? inputValue : row[inputName])
      }
    });
    console.log(innerSelectedRows);

    dispatch(setSelectedRows(innerSelectedRows));
  };

  const onChangeInput = async (value) => {
    setForm({ ...form, ...value });
  };

  const onChangeTextInput = (key, e, name = null) => {
    const inputName = name;
    const inputValue = e[name];

    let innerSelectedRows = selectedRows.map((row, innerKey) => {
      return {
        ...row,
        [inputName]: innerKey === key ? inputValue : row[inputName]
      }
    });

    dispatch(setSelectedRows(innerSelectedRows));
  };

  return {
    loadInInit,
    cadenas,
    productos,
    emisores,
    getOperaciones,
    getOrdenesPago,
    get24Horas,
    getCortes,
    getPagosCobros,
    onChange,
    onChangeRows,
    form,
    dataTable,
    estatusPago,
    getEscalonado,
    addRow,
    deleteRow,
    setRows,
    selectedRows,
    openOrdenPagoModal,
    closeOrdenPagoModal,
    isOpenOrdenPago,
    ordenPagoType,
    changeOrdenesPagoEstatus,
    onChangeTextInput,
    unificar,
    setUnificar,
    getReporteOperaciones,
    getReporteGeneral,
    onChangeInput,
    getOrdenesPagoLayout,
    getComisionesIntegrador,
    getReporteGeolocalizacion,
    getReporteSucursalesNoRegistradas,
    getTransacciones,
    integradores
  };
};
