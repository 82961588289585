import { useEffect, useState } from "react";
import { useMenu } from "../hooks/useMenu";
import { useAuthPage } from "../hooks/useAuthPage";
import {
  Button,
  Card,
  Dialog,
  DialogActions,
  DialogTitle,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import { createTheme, ThemeProvider } from "@mui/material";
import { esES } from "@mui/material/locale";
import EnhancedTable, {
  EllipsisTable,
} from "../components/table/EnhancedTable";
import { History, MoreHoriz, Summarize, Visibility } from "@mui/icons-material";
import { useSelector } from "react-redux";
import { SearchInput } from "../components/form/SearchInput";
import { FilterAltOutlined } from "@mui/icons-material";
import "dayjs/locale/es-mx";
import CompareArrowsIcon from "@mui/icons-material/CompareArrows";
import { useConciliacionPage } from "../hooks/useConciliacionPage";
import DownloadIcon from "@mui/icons-material/Download";
import DeleteSweepIcon from "@mui/icons-material/DeleteSweep";
import { ModalImportConciliacion } from "../components/modal/ModalImportConciliacion";
import PublishIcon from "@mui/icons-material/Publish";
import ModalViewTxt from "../components/modal/ModalViewTxt";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import moment from "moment-timezone";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import ModalArchivoConciliacion from "../components/modal/ModalArchivoConciliacion";
import { useModal } from "../hooks/useModal";
import { ModalBitacoraConciliacion } from "../components/modal/ModalBitacoraConciliacion";
import ButtonDropdown from "../components/ButtonDropdown";

const iconTheme = createTheme(
  {
    status: {
      danger: "#e53e3e",
    },
    palette: {
      primary: {
        main: "#00609C",
        dark: "#053e85",
      },
      neutral: {
        main: "#00CD68",
        light: "#053e85",
        dark: "#053e85",
        contrastText: "#fff",
      },
    },
  },
  esES
);

export const ArchivosConciliacionPage = () => {
  const colorsTab = ["#053e85", "#E7B10A", "#00CD68", "#ff9800", "#e53e3e"];
  const {
    onChange,
    form,
    handleOpenImport,
    loadInInit,
    dataTable,
    emisores,
    cadenas,
    getArchivosData,
    getFilters,
    estatusConciliacion,
    openTxtFile,
    handleOpenModalViewDoc,
    borrarCarga,
    conciliar,
  } = useConciliacionPage();
  const { isTableLoading } = useSelector((state) => state.ui);
  const { openModalArchivoConciliacion, openModalBitacoraConciliacion } =
    useModal();
  const { setNameSection } = useMenu();
  const { setNewPage, isAllowed, setAllowComponent } = useAuthPage();
  const [loaded, setLoaded] = useState(false);
  const [selectedRow, setSelectedRow] = useState(0);
  const [showConfirmDialog, setShowConfirmDialog] = useState(false);

  const handleConfirmDialog = (nIdArchivo) => {
    setSelectedRow(nIdArchivo);
    setShowConfirmDialog(true);
  };

  useEffect(() => {
    if (!loaded) {
      loadInInit();
      setNewPage("conciliacionArchivos");
      setNameSection("conciliacionArchivos");
      setLoaded(true);
    }
  }, []);

  const columnNombreComercial = {
    field: "sNombreComercial",
    headerName: "Nombre comercial",
    type: "text",
    flex: 4,
  };

  const columnClaveProducto = {
    field: "sClaveProducto",
    headerName: "Clave producto",
    type: "text",
    flex: 4,
  };

  const columns = [
    {
      field: "sEstatusConciliacion",
      headerName: "Estado conciliación",
      type: "render",
      flex: 2,
      renderFunction: (row) => (
        <Grid style={{ whiteSpace: "break-spaces" }}>
          <p
            style={{
              display: "inline-block",
              color: colorsTab[row.nIdEstatusConciliacion - 1],
              background: `${colorsTab[row.nIdEstatusConciliacion - 1]}15`,
              padding: "4px 8px",
              borderRadius: "4px",
              fontWeight: 500,
              margin: 0,
              fontSize: 13,
            }}
          >
            {row.sEstatusConciliacion}
          </p>
        </Grid>
      ),
    },
    {
      field: "sObservacion",
      headerName: "Observación",
      type: "render",
      flex: 3,
      renderFunction: (row) => (row.sObservacion ? row.sObservacion : "--"),
    },
    { field: "sTipoCarga", headerName: "Tipo de carga", type: "text", flex: 1 },
    {
      field: "nOperacionesSofiPay",
      headerName: "Operaciones SofiPay",
      type: "render",
      flex: 1,
      renderFunction: (row) => row.nOperacionesSofiPay ?? 0,
    },
    {
      field: "nOperacionesConciliadas",
      headerName: "Operaciones Conciliadas",
      type: "render",
      flex: 1,
      renderFunction: (row) =>
        row.nIdEstatusConciliacion !== 1 || row.bArchivosSeparados === 1
          ? row.nOperacionesConciliadas ?? 0
          : "--",
    },
    {
      field: "nDiferencias",
      headerName: "Diferencias",
      type: "render",
      flex: 1,
      renderFunction: (row) =>
        row.nIdEstatusConciliacion !== 1 || row.bArchivosSeparados === 1
          ? row.nDiferencias ?? 0
          : "--",
    },
  ];

  const buttons = [
    {
      label: "Ver",
      icon: <Visibility fontSize={"small"} />,
      onClick: (id, row) => handleOpenModalViewDoc(row.sRuta, row.sNombre),
      disabled: (id, row) => !row.nIdArchivo,
      showButton: true,
    },
    {
      label: "Descargar",
      icon: <DownloadIcon fontSize={"small"} />,
      onClick: (id, row) => openTxtFile(row.sRuta, row.sNombre),
      disabled: (id, row) => !row.nIdArchivo,
      showButton: true,
    },
    {
      label: "Detalles",
      icon: <Summarize fontSize={"small"} />,
      onClick: (id, row) => openModalArchivoConciliacion(row),
      disabled: (id, row) => [1, 5].includes(row.nIdEstatusConciliacion),
      showButton: true,
    },
    {
      label: "Conciliar archivo",
      icon: <CompareArrowsIcon fontSize={"small"} />,
      onClick: (id, row) =>
        conciliar(row.nIdArchivo, row.sRuta, row.dFechaConciliacion),
      disabled: (id, row) =>
        !(row.nIdEstatusConciliacion === 1 && row.nIdArchivo),
      showButton: setAllowComponent("conciliarManualmente"),
    },
    {
      label: "Ver bitacóra",
      icon: <History fontSize={"small"} />,
      onClick: (id, row) =>
        openModalBitacoraConciliacion(
          row.nIdEmisor,
          row.nIdCadena,
          row.sNombreComercial,
          form.dFechaConciliacion,
          row.bArchivosSeparados === 1 ? row.sClaveProducto : null
        ),
      showButton: true,
    },
    {
      label: "Borrar carga",
      icon: <DeleteSweepIcon fontSize={"small"} />,
      onClick: (id, row) => handleConfirmDialog(row.nIdArchivo),
      disabled: (id, row) =>
        row.nIdEstatusConciliacion !== 1 || !row.nIdArchivo,
      showButton: setAllowComponent("borrarCarga"),
    },
  ];

  if (isAllowed) {
    return (
      <ThemeProvider theme={iconTheme}>
        <Dialog
          open={showConfirmDialog}
          onClose={() => setShowConfirmDialog(false)}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            ¿Estas seguro/a de eliminar la carga?
          </DialogTitle>
          <DialogActions>
            <Button onClick={() => setShowConfirmDialog(false)}>
              Cancelar
            </Button>
            <Button
              variant="contained"
              onClick={() => {
                borrarCarga(selectedRow);
                setShowConfirmDialog(false);
              }}
            >
              Sí, estoy seguro/a
            </Button>
          </DialogActions>
        </Dialog>
        <ModalImportConciliacion />
        <ModalViewTxt />
        <ModalArchivoConciliacion />
        <ModalBitacoraConciliacion />
        <EnhancedTable
          table={{
            ...dataTable,
            columns: [columnNombreComercial, ...columns],
          }}
          rowId={"nIdActor"}
          loading={isTableLoading}
          getFilters={getFilters}
          showExpandableTable
          expandableTableCondition={(row) => row.bArchivosSeparados === 1}
          expandedItems={(id, row) => {
            return (
              <Table sx={{ minWidth: 750 }} size={"medium"}>
                <TableBody>
                  {row.subrows.map((subrow, index) => (
                    <TableRow hover role="checkbox" tabIndex={-1} key={index}>
                      {[columnClaveProducto, ...columns].map((column) => {
                        return (
                          <TableCell
                            style={{
                              flexGrow: column.flex,
                              ...(column.style ? column.style : {}),
                            }}
                          >
                            <EllipsisTable
                              disableTooltip={column.disableTooltip}
                            >
                              {column.renderFunction
                                ? column.renderFunction(subrow)
                                : subrow[column.field]}
                            </EllipsisTable>
                          </TableCell>
                        );
                      })}
                      <TableCell align="right" style={{ width: 150 }}>
                        <div>
                          {[
                            <ButtonDropdown
                              endIcon={<MoreHoriz />}
                              variant="text"
                              options={buttons
                                .filter((btn) => btn.showButton)
                                .map((btn) => ({
                                  action: () =>
                                    btn.onClick(subrow["nIdActor"], subrow),
                                  startIcon: btn.icon,
                                  label: btn.label,
                                  disabled: btn.disabled
                                    ? btn.disabled(subrow["nIdActor"], subrow)
                                    : false,
                                }))}
                            />,
                          ].map((btn) => {
                            return btn;
                          })}
                        </div>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            );
          }}
          buttons={buttons}
          extraButtons={
            setAllowComponent("cargarArchivoManual") && (
              <>
                <Button
                  variant="contained"
                  startIcon={<PublishIcon />}
                  onClick={handleOpenImport}
                >
                  Cargar archivo manual
                </Button>
              </>
            )
          }
          extraFilters={(resetPagination) => (
            <Grid
              container
              justifyContent={"space-between"}
              alignItems={"center"}
              sx={{ padding: 2 }}
            >
              <Grid item sm={1} xs={12}>
                Filtros:
              </Grid>
              <Grid item sm={9} xs={12}>
                <Grid container spacing={2} alignItems={"center"}>
                  <Grid item xs={12} sm={2}>
                    <SearchInput
                      options={[
                        {
                          id: 0,
                          name: "Todos",
                        },
                        {
                          id: 1,
                          name: "EFR",
                        },
                        {
                          id: 2,
                          name: "Comisionista",
                        },
                      ]}
                      value={form.nActor}
                      name={"nActor"}
                      label={"Entidad"}
                      getOptionLabel={"name"}
                      getIndexLabel={"id"}
                      onChange={onChange}
                    />
                  </Grid>
                  {form.nActor !== 0 && (
                    <Grid item xs={12} sm={4}>
                      {form.nActor === 1 || form.nActor === "1" ? (
                        <SearchInput
                          options={[
                            { nIdEmisor: 0, sNombreComercial: "Todos" },
                            ...emisores?.rows,
                          ]}
                          value={form.nIdEmisor}
                          name={"nIdEmisor"}
                          label={form.nActor ? "Entidad financiera" : ""}
                          getOptionLabel={"sNombreComercial"}
                          getIndexLabel={"nIdEmisor"}
                          onChange={onChange}
                        />
                      ) : (
                        <SearchInput
                          options={[
                            { nIdCadena: 0, sNombreComercial: "Todos" },
                            ...cadenas?.rows,
                          ]}
                          value={form.nIdCadena}
                          name={"nIdCadena"}
                          label={form.nActor ? "Comisionista" : ""}
                          getOptionLabel={"sNombreComercial"}
                          getIndexLabel={"nIdCadena"}
                          onChange={onChange}
                        />
                      )}
                    </Grid>
                  )}
                  <Grid item xs={12} sm={3}>
                    <SearchInput
                      options={[
                        { nIdEstatusConciliacion: 0, sDescripcion: "Todos" },
                        ...estatusConciliacion,
                      ]}
                      value={form.nIdEstatusConciliacion}
                      name={"nIdEstatusConciliacion"}
                      label={"Estado de conciliación"}
                      getOptionLabel={"sDescripcion"}
                      getIndexLabel={"nIdEstatusConciliacion"}
                      onChange={onChange}
                    />
                  </Grid>
                  <Grid item xs={12} sm={3}>
                    <LocalizationProvider
                      dateAdapter={AdapterDayjs}
                      adapterLocale={"es-mx"}
                    >
                      <DesktopDatePicker
                        label="Fecha Conciliación"
                        inputFormat="YYYY-MM-DD"
                        renderInput={(params) => (
                          <TextField {...params} fullWidth />
                        )}
                        value={form.dFechaConciliacion}
                        onChange={(e) =>
                          onChange(
                            null,
                            "dFechaConciliacion",
                            moment(e ? e["$d"] : "").format("YYYY-MM-DD")
                          )
                        }
                        disableFuture
                      />
                    </LocalizationProvider>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item sm={2} xs={12} style={{ textAlign: "right" }}>
                <Button
                  variant="contained"
                  startIcon={<FilterAltOutlined />}
                  onClick={() => {
                    getArchivosData();
                    resetPagination();
                  }}
                >
                  Filtrar
                </Button>
              </Grid>
            </Grid>
          )}
        />
      </ThemeProvider>
    );
  } else {
    setNameSection("");
    return (
      <Card>
        <Typography variant="h3">Contenido no disponible</Typography>
      </Card>
    );
  }
};
