import { Autocomplete, Box, Chip, Grid, TextField } from '@mui/material';
import { useEffect, useState } from 'react';

export const SearchInput = ({
  options,
  value,
  label,
  name,
  onChange,
  getIndexLabel,
  getOptionLabel,
  getExtraData = null,
  disabled,
  size = 'large',
  focused,
  sx,
  onChangeInnerInput = null,
  multiple = false,
  autoFocus = false,
  renderOption = null
}) => {
  const [item, setItem] = useState({});
  const [innerOptions, setInnerOptions] = useState([]);

  useEffect(() => {
    setInnerOptions(options);
    // eslint-disable-next-line
  }, [options]);

  useEffect(() => {
    setItem(!multiple
      ? (innerOptions.length ? innerOptions : options).find((i) => i[getIndexLabel ? getIndexLabel : 'id'] === value) :
      (Array.isArray(value) ? value : []));
    
    // eslint-disable-next-line
  }, [value]);


  const onChangeInput = (e, value) => {
    onChange(
      {
        target: {
          name,
          value: multiple ? value : value[getIndexLabel]
        }
      },
      name,
      value[getIndexLabel]
    );
  };
  return (
    <Autocomplete
      multiple={multiple}
      size={size}
      autoFocus={autoFocus}
      freeSolo
      disableClearable
      options={innerOptions}
      value={item ? item : (multiple ? [] : '')}
      onChange={(e, value) => onChangeInput(e, value)}
      disabled={disabled}
      sx={sx}
      renderOption={(props, option) => (
        <li {...props} sx={{
          backgroundColor: "red",
          color: "orange"
        }}>
          <Grid item>
            {renderOption ? renderOption(option) : (option[getOptionLabel] || '')}
            <div>
              {getExtraData && option[getExtraData] ? <><span style={{ fontSize: 12 }}>{option[getExtraData]}</span></> : <></>}</div>
          </Grid>
        </li>
      )}
      getOptionLabel={(option) => `${renderOption ? renderOption(option) : (option[getOptionLabel] || '')}${getExtraData && false ? ` (${option[getExtraData] || ''})` : ''}`}
      renderInput={(params) => (
        <TextField
          {...params}
          label={label}
          size={size}
          fullWidth
          onChange={(e) => {
            if (onChangeInnerInput) onChangeInnerInput({
              target: {
                name,
                value: e.target.value,
              }
            }, "inner" + name, e.target.value)
            if (e.target.value === "") onChangeInput(e, { [getIndexLabel]: 0 })
          }}
          InputProps={{
            ...params.InputProps,
            type: 'search',
            sx: sx
          }}
        />
      )}
    />
  );
};
