import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { isValidEscCantidadForm } from "../../helpers/forms-validate";
import { useModal } from "../../hooks/useModal";
import { cleanEscCantidadForm, deleteEscCantidadForm, setEscCantidadForm, updateEscCantidadForm } from "../../slices/cadena/cadenaSlice";

import { v4 as uuidv4 } from 'uuid';
import { escCantidadForm } from "../../helpers/forms";
import { TextInput } from "../form/TextInput";
import { Button, InputAdornment, Tooltip } from "@mui/material";
import { Add, Delete } from "@mui/icons-material";
import { buttonContactStyle, buttonContactStyleError } from "../../helpers/stylesMaterial";
import { IvaInput } from "../form/IvaInput";
import { useAuthPage } from "../../hooks/useAuthPage";

export const EscCantidadForm = ({ escalonado, id, isFirst, isEditModal, onlyView }) => {
  const [form, setForm] = useState({ ...escalonado, id, isFirst });
  const [isActiveDelete, setIsActiveDelete] = useState(escalonado.isActiveDelete);
  const [disableInputs, setDisableInputs] = useState(escalonado.disableInputs);
  const { ivaOperativoSelect } = useSelector((state) => state.cadena);

  const { setAllowComponent } = useAuthPage();
  const dispatch = useDispatch();
  const { setOpenToast } = useModal();

  const onChangeInput = ( value ) => {
    setForm({ ...form, ...value });
    dispatch(updateEscCantidadForm({ ...form, ...value }));
  };

  const onClick = ( e ) => {
    e.preventDefault();

    const minimo = parseFloat(
      1 + parseFloat(form.cantidadMaxima)
    ).toFixed(0);

    dispatch(updateEscCantidadForm(form));
    
    const { ok, error } = isValidEscCantidadForm(form);
    if ( !ok ) {
      setOpenToast(true, error);
      return;
    }
    if ( parseFloat(form.cantidadMaxima) === 0 ) {
      setDisableInputs(true);
      return;
    }
    if ( parseFloat(form.cantidadMaxima) <= parseFloat(form.cantidadMinimo) ) {
      setOpenToast(
        true,
        `La cantidad máxima(${form.cantidadMaxima}) es menor a la cantidad minima(${form.cantidadMinimo})`
      );
      setForm({
        ...form,
        cantidadMaxima: parseFloat(parseFloat(form.cantidadMinimo) + 1).toFixed(0)
      });
      return;
    }
    setIsActiveDelete(true);
    setDisableInputs(true);
    dispatch(
      setEscCantidadForm({
        ...escCantidadForm,
        id         : uuidv4(),
        cantidadMinimo: minimo
      })
    );
  };  

  const onDeleteForm = () => {
    if ( isFirst ) {
      dispatch(cleanEscCantidadForm());
      setForm({ ...escCantidadForm });
      setDisableInputs(false);
      setIsActiveDelete(false);
      return;
    }
    dispatch(deleteEscCantidadForm(id));
  }


  return (
    <div
      className={`cadenas__inputs-container cadenas__grid-${isEditModal || onlyView ? '4': '5'}`}
      style={{ marginTop: '15px' }}
      // onSubmit={onClick}
    >
     <TextInput
        id="cantidadMinimo"
        name="cantidadMinimo"
        label="Cantidad mínima"
        type="number"
        value={form.cantidadMinimo}
        onChangeRoot={onChangeInput}
        fullWidth
        disabled
      />
      <TextInput
        id="cantidadMaxima"
        name="cantidadMaxima"
        label="Cantidad máxima"
        type="number"
        value={form.cantidadMaxima}
        onChangeRoot={onChangeInput}
        fullWidth
        disabled={onlyView || (disableInputs || isEditModal) }
        require
      />
      {/* TIENE QUE SER MAYOR A 0 */}
      <div style={{ gridColumn: 'span 2'}}>
        <IvaInput
          id="comisionMontoFijo"
          name="comisionMontoFijo"
          label="Comisión"
          value={form.comisionMontoFijo}
          onChangeRoot={onChangeInput}
          variant="outlined"
          fullWidth
          type="number"
          size="medium"
          ivaValue={ ivaOperativoSelect * 100 }
          startAdornment={<InputAdornment position="start">$</InputAdornment>}
          disabled={onlyView || (!((isEditModal && setAllowComponent('editarEscalonadaVigente')) || (!isEditModal && !disableInputs))) }
        />

      </div>
      {!isEditModal && !onlyView &&
      <div style={{ width: '100%' }}>
        {!isActiveDelete ? (
          <Tooltip title="Agregar" placement="bottom" arrow>
            <Button
              variant="outlined"
              size="large"
              sx={buttonContactStyle}
              // type="submit"
              onClick={onClick}
            >
              <Add />
            </Button>
          </Tooltip>
        ) : (
          <Tooltip title="Eliminar" placement="bottom" arrow>
            <Button
              variant="outlined"
              size="large"
              sx={buttonContactStyleError}
              onClick={onDeleteForm}
            >
              <Delete />
            </Button>
          </Tooltip>
        )}
      </div>}
    </div>
  );
};
