import { Calculate } from '@mui/icons-material';
import { Grid, TextField } from '@mui/material';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getSizeItem, onFormatCuenta, onFormatCuentaInit, validText } from '../../helpers/formatCuenta';
import { setContablesCash } from '../../slices/cadena/cadenaSlice';
import { Card } from '../Card';

export const CuentaContableCash = ({
  referencia,
  idElement,
  loadCuentas,
  retencion,
  title,
  nFlujo
}) => {

  const {
    contablesCash: cuentaForm,
    cuentasContable
  } = useSelector((state) => state.cadena);

  const [activeInputCliente, setActiveInputCliente] = useState(true);
  const dispatch = useDispatch();

  const onChangeInput = (e) => {

    const inputValue = e.target.value;
    const inputName = e.target.name;
    const inputClean = e.target.value.replaceAll('-', '')

    if (inputValue.length > 15 || (inputValue !== '' && !validText(inputValue))) return;
    if (
      getSizeItem(cuentasContable, inputName.trim()) > 0 &&
      inputClean.length < getSizeItem(cuentasContable, inputName)
    ) return;
    dispatch(
      setContablesCash({
        ref: referencia,
        obj: { [inputName]: onFormatCuenta(inputValue.trim()) }
      })
    );

  }

  const onKeyDown = (e, inputName) => {
    const keyDown = e.key;
    const inpuValue = e.target.value;
    const inputClean = e.target.value.replaceAll('-', '')
    const size = getSizeItem(cuentasContable, inputName.trim())

    if (inpuValue.substr(-1) === '-' && size > 0 && size === inputClean.length) return;

    if (keyDown === 'Backspace' && inpuValue.substr(-1) === '-') {
      dispatch(
        setContablesCash({
          ref: referencia,
          obj: {
            [inputName]: cuentaForm[referencia][inputName].substring(
              0,
              cuentaForm[referencia][inputName].length - 1
            )
          }
        })
      );
    }

  }

  useEffect(() => {
    for (const [key, value] of Object.entries(cuentasContable)) {
      dispatch(
        setContablesCash({
          ref: referencia,
          obj: { [key]: onFormatCuentaInit(value) }
        })
      );
    }
    // eslint-disable-next-line
  }, [cuentasContable.nCuentaBanco]);

  useEffect(() => {
    if (!['cuentaContableCashOut-EFR', 'cuentaContableCashIn-EFR'].includes(idElement))
      return;
    if (retencion === '') return;
    if (retencion === '1') { // Sin retencion
      setActiveInputCliente(true);
      return;
    }
    // Con rentecion
    setActiveInputCliente(false)

  }, [retencion, idElement]);






  return (
    <Card
      title={title}
      icon={<Calculate sx={{ color: 'white', fontSize: '30px' }} />}
      openCollapse={false}
      idElement={idElement}
      loadEvent={async () => await loadCuentas()}
    >
      <Grid container spacing={1}>
        {cuentasContable.map((cuenta) => {
          if (cuenta.nFlujoImporte === nFlujo) {
            return (
              <Grid item md={3} sm={6} xs={12}>
                <TextField
                  /* id=""
                  name="nCuentaBancos" */
                  label={cuenta.sNombre}
                  value={onFormatCuentaInit(cuenta.nCuenta)}
                  disabled
                  //onChange={onChangeInput}
                  //onKeyDown={(e) => onKeyDown(e, 'nCuentaBancos')}
                  variant="outlined"
                  fullWidth
                />
              </Grid>
            )
          }
        })}
        {/* <Grid item md={3} sm={6} xs={12}>
          <TextField
            id="nCuentaBancos"
            name="nCuentaBancos"
            label="Banco"
            value={cuentaForm[referencia].nCuentaBancos}
            onChange={onChangeInput}
            onKeyDown={(e) => onKeyDown(e, 'nCuentaBancos')}
            variant="outlined"
            fullWidth
          />
        </Grid>
        {activeInputCliente && (
          <Grid item md={3} sm={6} xs={12}>
            <TextField
              id="nCuentaCliente"
              name="nCuentaCliente"
              label="Cliente"
              value={cuentaForm[referencia].nCuentaCliente}
              onChange={onChangeInput}
              onKeyDown={(e) => onKeyDown(e, 'nCuentaCliente')}
              variant="outlined"
              fullWidth
            />
          </Grid>
        )}
        <Grid item md={3} sm={6} xs={12}>
          <TextField
            id="nCuentaProveedor"
            name="nCuentaProveedor"
            label="Proveedor"
            value={cuentaForm[referencia].nCuentaProveedor}
            onChange={onChangeInput}
            onKeyDown={(e) => onKeyDown(e, 'nCuentaProveedor')}
            variant="outlined"
            fullWidth
          />
        </Grid>

        <Grid item md={3} sm={6} xs={12}>
          <TextField
            id="nCuentaIngresos"
            name="nCuentaIngresos"
            label="Ingreso"
            value={cuentaForm[referencia].nCuentaIngresos}
            onChange={onChangeInput}
            onKeyDown={(e) => onKeyDown(e, 'nCuentaIngresos')}
            variant="outlined"
            fullWidth
          />
        </Grid>

        <Grid item md={3} sm={6} xs={12}>
          <TextField
            id="nCuentaCostos"
            name="nCuentaCostos"
            label="Costos"
            value={cuentaForm[referencia].nCuentaCostos}
            onChange={onChangeInput}
            onKeyDown={(e) => onKeyDown(e, 'nCuentaCostos')}
            variant="outlined"
            fullWidth
          />
        </Grid>

        <Grid item md={3} sm={6} xs={12}>
          <TextField
            id="nIVATraslado"
            name="nIVATraslado"
            label="IVA por trasladar no cobrado"
            value={cuentaForm[referencia].nIVATraslado}
            onChange={onChangeInput}
            onKeyDown={(e) => onKeyDown(e, 'nIVATraslado')}
            variant="outlined"
            fullWidth
          />
        </Grid>

        <Grid item md={3} sm={6} xs={12}>
          <TextField
            id="nIVAAcreditable"
            name="nIVAAcreditable"
            label="IVA por acreditar"
            value={cuentaForm[referencia].nIVAAcreditable}
            onChange={onChangeInput}
            onKeyDown={(e) => onKeyDown(e, 'nIVAAcreditable')}
            variant="outlined"
            fullWidth
          />
        </Grid> */}
      </Grid>
    </Card>
  );
};
CuentaContableCash.defaultProps = {
  idElement: '',
  retencion: '',
};

CuentaContableCash.propTypes = {
  referencia: PropTypes.string.isRequired,
  idElement: PropTypes.string,
  loadCuentas: PropTypes.func.isRequired,
  retencion: PropTypes.string,
  title: PropTypes.string.isRequired
};
