import { useSelector } from "react-redux";
import { useModal } from "../../hooks/useModal";
import { FormConciliacion } from "../FormConciliacion";
import { ModalBase } from "./ModalBase";
import { useModalConciliacion } from "../../hooks/useModalConciliacion";
import { Cable, Cancel, Edit, Help, History, Hub, Key, ReceiptLong, Save, Send } from "@mui/icons-material";
import { Box, Typography, Button, Grid, FormControlLabel, Radio, Alert, AlertTitle, Tooltip, IconButton } from "@mui/material";
import { TextInput } from "../form/TextInput";
import { SelectInput } from "../form/SelectInput";
import { BlueSwitch } from "../../helpers/stylesMaterial";
import { styled } from '@mui/material/styles';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import { useModalEnvioOperaciones } from "../../hooks/useModalEnvioOperaciones";
import { useEffect } from "react";
import { useAuthPage } from "../../hooks/useAuthPage";
import { useModalConexionesSFTP } from "../../hooks/useModalConexionesSFTP";
import EnhancedTable from "../table/EnhancedTable";
import { ModalConexionSFTP } from "./ModalConexionSFTP";
import { ModalConexionSofiPay } from "./ModalConexionSofiPay";
import { ModalBitacoraConexionesSFTP } from "./ModalBitacoraConexionesSFTP";


export const ModalConexionesSFTP = () => {
  const { isOpenConexionesSFTP, closeModalConexionesSFTP } = useModal();
  const { isLoading } = useSelector((state) => state.ui);

  const { setAllowComponent } = useAuthPage();
  const {
    form,
    setForm,
    onSubmit,
    selectedEntity,
    type,
    handleEditConexion,
    archivos,
    handleEditArchivo,
    archivoEditing,
    cancelEditArchivo,
    conexiones,
    view,
    conexionSelected,
    showConexiones,
    returnArchivos,
    onChangeConexion,
    onChangeConexionSelected,

    showBitacora,
    closeBitacora,
    typeBitacora,
    getOpcionesConexiones,
    onChangeArchivoEditing,
    onSubmitArchivo,
    onSubmitConexion,
    onSubmitDeleteConexion
  } = useModalConexionesSFTP();

  console.log(archivoEditing)
  return (
    <>
      <ModalConexionSFTP
        isOpen={view === "conexiones"}
        selectedEntity={selectedEntity}
        conexionSelected={conexionSelected}
        conexiones={conexiones}
        onChangeConexion={onChangeConexion}
        handleEditConexion={handleEditConexion}
        onClose={returnArchivos}
        onChangeInput={onChangeConexionSelected}
        showBitacora={showBitacora}
        onSubmit={onSubmitConexion}
        onDelete={onSubmitDeleteConexion}
      />
      <ModalConexionSofiPay
        isOpen={view === "conexionesSofiPay"}
        selectedEntity={selectedEntity}
        conexionSelected={conexionSelected}
        conexiones={conexiones}
        onClose={returnArchivos}
        showBitacora={showBitacora}
      />
      <ModalBitacoraConexionesSFTP
        isOpen={view === "bitacora"}
        selectedEntity={selectedEntity}
        typeBitacora={typeBitacora}
        onClose={closeBitacora}
      />
      <ModalBase open={isOpenConexionesSFTP.open && view === "archivos"} onClose={closeModalConexionesSFTP} style={{ width: "1350px", maxHeight: "700px" }}>

        <EnhancedTable
          isModalTable

          table={{
            rows: archivos,
            columns: [
              { field: 'nIdArchivoSFTP', headerName: 'ID', flex: 1, style: { width: "15px" } },
              {
                field: 'bObligado', headerName: 'Obligado', flex: 1, type: "render", style: { width: "30px" }, renderFunction: (row) => (row.bObligado ? "Sí" : "No")
              },
              {
                field: 'sNombreArchivo', headerName: 'Nombre del archivo', flex: 8, type: "render", disableTooltip: true, renderFunction: (row) => (
                  <>
                    {row.sNombreArchivo + " "}
                    <Tooltip title={row.sComentarioArchivo} placement="right" arrow>
                      <Help fontSize="14" color="neutral" />
                    </Tooltip>
                  </>
                )
              },
              {
                field: 'bGeneradoPorSofiPay', headerName: 'Generado por SP', flex: 1, type: "render", style: { width: "35px" }, renderFunction: (row) => (row.bGeneradoPorSofiPay ? "Sí" : "No")
              },
              { field: 'sHost', headerName: 'Host', flex: 4, type: "render", renderFunction: (row) => (archivoEditing.nIdArchivoSFTP === row.nIdArchivoSFTP ? archivoEditing.sHost : row.sHost) },
              { field: 'nPort', headerName: 'Puerto', flex: 1, style: { width: "25px" }, type: "render", renderFunction: (row) => (archivoEditing.nIdArchivoSFTP === row.nIdArchivoSFTP ? archivoEditing.nPort : row.nPort) },
              { field: 'sUser', headerName: 'Usuario', flex: 1, type: "render", renderFunction: (row) => (archivoEditing.nIdArchivoSFTP === row.nIdArchivoSFTP ? archivoEditing.sUser : row.sUser) },
              {
                field: 'sCarpeta', headerName: 'Carpeta', flex: 5, type: "render", renderFunction: (row) => {
                  return (archivoEditing.nIdArchivoSFTP === row.nIdArchivoSFTP && archivoEditing.nIdCfgConexion ?
                    <div style={{ paddingTop: 5 }}>
                      <TextInput
                        disabled={parseInt(archivoEditing.nIdTipoActor) !== 1}
                        id="sCarpeta"
                        name="sCarpeta"
                        label="Carpeta"
                        value={parseInt(archivoEditing.nIdTipoActor) === 2 ? row.sCarpetaArchivo : archivoEditing.sCarpeta}
                        onChangeRoot={onChangeArchivoEditing}
                        size="small"
                        fullWidth
                      />
                    </div>
                    : archivoEditing.nIdArchivoSFTP === row.nIdArchivoSFTP ? "" : row.sCarpeta)
                }
              },
              {
                field: 'sNombreConexion', headerName: 'Conexión', flex: 8, type: "render", disableTooltip: true, renderFunction: (row) => (
                  archivoEditing.nIdArchivoSFTP === row.nIdArchivoSFTP ?
                    <div style={{ paddingTop: 5 }}>
                      <SelectInput
                        id="nIdCfgConexion"
                        name="nIdCfgConexion"
                        onChange={onChangeArchivoEditing}
                        label="Conexión"
                        data={getOpcionesConexiones().filter(con => !row.bObligado || parseInt(con.value) > 0).sort((a, b) => parseInt(a.nIdTipoActor) - parseInt(b.nIdTipoActor))}
                        value={archivoEditing.nIdCfgConexion}
                        fullWidth
                        size={"small"}
                        sxSelect={{ width: 160 }}
                        require
                        disableNone
                      />
                    </div>
                    : row.sNombreConexion
                )
              },
            ]
          }}
          disablePathParameters

          extraRowButtons={(id, row) => {
            return (archivoEditing.nIdArchivoSFTP === row.nIdArchivoSFTP ?
              <>
                <Button size="small" startIcon={<Save />} variant="contained" onClick={onSubmitArchivo}>
                  Guardar
                </Button>
                <Button size="small" startIcon={<Cancel />} sx={{ mt: 1 }} onClick={cancelEditArchivo}>
                  Cancelar
                </Button>
              </>
              :
              (!archivoEditing.nIdArchivoSFTP && setAllowComponent("modalConexionesSFTP","editarRelacion") ? <Tooltip title={"Editar"} placement="top">
                <IconButton
                  size="small"
                  onClick={() => handleEditArchivo(row)}
                >
                  <Edit />
                </IconButton>
              </Tooltip> :
                <></>
              )
            )
          }}
          extraButtons={<>
            {archivos.filter(con => parseInt(con.nIdTipoActor) === 2).length ?
              <Button variant="contained" startIcon={<Key />} onClick={() => showConexiones(true)}>
                Envío de credenciales
              </Button>
              : <></>}
            <Button variant="contained" startIcon={<Cable />} sx={{ ml: 1 }} onClick={() => showConexiones(false)}>
              Conexiones socio comercial
            </Button>
            <Button variant="contained" startIcon={<History />} sx={{ ml: 1 }} onClick={() => showBitacora(view)}>
              Bitácora
            </Button>
          </>}
          icon={<Hub fontSize={'large'} sx={{ color: '#00CD68', fontSize: '45px' }} />}
          subtitle={"Conexiones SFTP"}
          title={selectedEntity?.sNombreComercial}
          disableSearch
          columnButtonWidth={20}
        />

        <Box
          sx={{
            textAlign: 'end',
            marginTop: '15px'
          }}
        >
          <Button
            onClick={() => closeModalConexionesSFTP()}
            sx={{ marginRight: '10px', display: '' }}
          >
            cerrar
          </Button>
          {setAllowComponent("guardarEnvioOperacionesDiarias") ?
            <Button
              variant="contained"
              color="primary"
              onClick={onSubmit}
              sx={{ display: type === "view" ? 'none' : '' }}

            >
              Guardar
            </Button>
            : <></>}

        </Box>
      </ModalBase >
    </>
  );
};


const TitleModal = ({ title, typeOperation, extraButton }) => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        width: '100%',
        marginBottom: '10px',
        justifyContent: 'space-between', mb: 4
      }}
    >
      <div style={{ display: 'flex', justifyContent: "space-between" }}>

        <Hub fontSize={'large'} sx={{ color: '#00CD68', fontSize: '45px' }} />
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <Typography
            variant="p"
            sx={{
              marginLeft: '15px',
              fontSize: '14px',
              marginBottom: '4px'
            }}
          >
            {'Conexiones SFTP'}
          </Typography>
          <Typography
            variant="p"
            sx={{
              marginLeft: '15px',
              color: '#00609C',
              fontSize: '20px'
            }}
          >
            {title}
          </Typography>
        </div>
      </div>
      <div>
        {extraButton}
      </div>
    </Box>
  );
};